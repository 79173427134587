<div class="container-fluid" id="containerDetail" *ngIf="projectInformation">
    <!-- SIDEBAR -->
    <div class="col-sm-3" id="sidebar">
        <div>
            <!-- Category Logo -->
            <img alt="NHSBN {{projectInformation.projectCategory}} logo" class="img-title" src="../../assets/images/{{projectInformation.projectCategory.split(' ').join('')}}.png" />

            <h2>{{projectInformation.projectName}}</h2>

            <div *ngIf="projectInformation.projectPhoneNo">
                <hr />
                <p>Contact the project team on</p>
                <h4>{{ projectInformation.projectPhoneNo }}</h4>
            </div>

            <div *ngIf="projectInformation.projectEmail">
                <a href="mailto:{{ projectInformation.projectEmail }}">{{ projectInformation.projectEmail }}</a>
            </div>
        
            <div *ngIf="projectInformation.projectRoles.length > 0">
                <div *ngFor="let p of projectInformation.projectRoles">
                    <hr />
                    <h4>{{ p.role }}</h4>
                    <p>
                        {{ p.name }}<br />
                        <a href="mailto:{{ p.email }}">{{ p.email }}</a>
                    </p>
                </div>
            </div>
            
            <hr>

            <button class="btn btn-default" (click)="navHome()"><span class="fa fa-caret-left"></span> Back to Projects</button>

            <div class="mobile-hr">
                <hr>
            </div>
        </div>
    </div>

    <!-- CONTENT -->
    <div class="col-sm-9" *ngIf="projectInformation">
        <div *ngIf="projectGuidancePD" class="ribbon -danger">
            {{projectGuidancePD}}
        </div>

        <div class="info-space">
            <div class="col-lg-8 col-md-7 col-sm-7">
                <h3>Project Overview</h3>

                <!-- Live version -->
                <div *ngIf="!editingDetails" class="overview-container" [innerHTML]="projectInformation.projectDescription | safe: 'html'"></div>

                <!-- Active Admin -->
                <div *ngIf="currentUserApproved && editingDetails">
                    <form [formGroup]="detailsForm">
                        <div class="form-group">
                            <angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
                        </div>
                        <div class="form-actions">
                            <button type="button" class="btn btn-success" (click)="submitForm()" [disabled]="!detailsForm.value.description">Save</button>
                            <button type="button" class="btn btn-default" (click)="editingDetails = false">Cancel</button>
                        </div>
                    </form>
                </div>

            </div>

            <div class="col-lg-3 col-lg-offset-1 col-md-5 col-sm-5" *ngIf="projectTimetableVisible && projectId != 8">
                <h3>Project Timetable</h3>
                <div id="projectTable">
                    <p>
                        <b>Open Date:</b><br>
                        <span *ngIf="projectInformation.projectTimetable.openDateMonths == 'Y'">{{projectInformation.projectTimetable.openDate | date:'MMM y'}}</span>
                        <span *ngIf="projectInformation.projectTimetable.openDateMonths == 'N'">{{projectInformation.projectTimetable.openDate | date:'d MMM y'}}</span>
                        <span *ngIf="!projectInformation.projectTimetable.openDate">No date set</span>
                    </p>

                    <p>
                        <b>Close Date:</b><br>
                        <span *ngIf="projectInformation.projectTimetable.closeDateMonths == 'Y'">{{projectInformation.projectTimetable.closeDate | date:'MMM y'}}</span>
                        <span *ngIf="projectInformation.projectTimetable.closeDateMonths == 'N'">{{projectInformation.projectTimetable.closeDate | date:'d MMM y'}}</span>
                        <span *ngIf="!projectInformation.projectTimetable.closeDate">No date set</span>
                    </p>

                    <p>
                        <b>Event Date:</b><br>
                        <span *ngIf="projectInformation.projectTimetable.eventDateMonths == 'Y'">{{projectInformation.projectTimetable.eventDate | date:'MMM y'}}</span>
                        <span *ngIf="projectInformation.projectTimetable.eventDateMonths == 'N'">{{projectInformation.projectTimetable.eventDate | date:'d MMM y'}}</span>
                        <span *ngIf="!projectInformation.projectTimetable.eventDate">No date set</span>
                    </p>
                    
                    <p>
                        <b>Publication Date:</b><br>
                        <span *ngIf="projectInformation.projectTimetable.publicationDateMonths == 'Y'">{{projectInformation.projectTimetable.publicationDate | date:'MMM y'}}</span>
                        <span *ngIf="projectInformation.projectTimetable.publicationDateMonths == 'N'">{{projectInformation.projectTimetable.publicationDate | date:'d MMM y'}}</span>
                        <span *ngIf="!projectInformation.projectTimetable.publicationDate">No date set</span>
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-lg-offset-1 col-md-5 col-sm-5" style="margin-top: 25px;">
                <h3>
                    <a *ngIf="projectId != 34" class= "text-decoration-underline" [href]="resourceLink()">Supporting Documentation</a>
                    <a *ngIf="projectId === 34" class= "text-decoration-underline" href="https://www.nacel.nhs.uk/audit-guidance">Supporting Documentation</a>
                </h3>
            </div>
        </div>
    </div>
</div>

<!-- ADMIN -->
<div id="activeAdminBar" *ngIf="currentUserApproved">
    <button class="btn btn-default" (click)="editingDetails ? editingDetails = false : editDetails()">
        <i [class]="editingDetails ? 'fas fa-times' : 'fas fa-edit'"></i> {{ editingDetails ? 'Close' : 'Edit Description' }}
    </button>
</div>