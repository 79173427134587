import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { KenService } from '../utilities/services/ken.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../utilities/services/data.service'
import { MemberService } from 'app/utilities/services/member.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  requestId;
  currentUserLogonName;
  currentUserNotifications;
  optInMessage: boolean = false;
  request;
  comments;
  response: boolean = false;
  responseForm: FormGroup;

  // Comment threads
  parentId; parentComment;

  public navIsFixed: boolean = false;
  public width: number;

  metaTitle: string = 'Knowledge Exchange Forum | NHS Benchmarking Network';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    private kenService: KenService,
    private toastr: ToastrService,
    private dataService: DataService,
    private memberService: MemberService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.requestId = +this.route.snapshot.paramMap.get('requestId');
    this.getRequest(this.requestId);
    let currentUser = JSON.parse(sessionStorage.getItem('user'));
    this.currentUserNotifications = currentUser.notificationOptIn;
  }

  getRequest(request) {
    this.kenService.getRequest(request).subscribe(
      r => { 
        this.request = r.data;
        this.comments = this.setComments(this.request.comments);
        this.currentUserLogonName = this.dataService.getUser().logonName;
       },
      e => { 
        console.log(e);
        this.router.navigate(['/forum']);
        this.toastr.error("This request no longer exists. Please try searching the requests instead.");
      }
    );
  }

  setResponse(state) {
    this.response = state;
    if (state == true) {
      this.buildResponseForm();
    }
  }

  replyToResponse(parentId) {
    window.scrollTo(0, 0);
    this.parentId = parentId;
    this.parentComment = this.comments.filter(c => c.kenCommentId === parentId)[0];
    this.setResponse(true);
  }

  setComments(comments) {
    let cleanedComments = comments.filter(c => c.isVisible == 'Y' && c.reportCount < 2);

    cleanedComments.forEach(c => { 
      c.children = c.children.filter(cc => cc.isVisible == 'Y' &&cc.reportCount < 2);
    });

    return cleanedComments;
  }

  buildResponseForm() {
    this.responseForm = this.fb.group({    
      response: ['', [Validators.required, Validators.maxLength(200)]]
    });
  }

  submitResponseForm(requestId, parentId) {
    if (this.responseForm.value.response) {
      let response = { 
        commentBody: this.responseForm.value.response,
        parentId: parentId
      };
      this.addResponse(requestId, response);
    }
  }

  addResponse(requestId, response) {
    this.kenService.addComment(requestId, response).subscribe(
      r => {
        this.toastr.success("Your response has been successfully submitted. Thank you.")
        this.response = false;
        if (this.currentUserNotifications === 'Y') {
          this.optInMessage = true;
        }
        this.getRequest(this.requestId);
      },
      e => {
        this.toastr.error("There has been an error whilst trying to submit your response. Please try again.");
      }
    );
  }

  reportResponse(response) {
    this.kenService.reportComment({ kenCommentId: response }).subscribe(
      r => {
        this.toastr.success("You have reported this response. A response will be removed if it is reported enough times.");
        this.getRequest(this.requestId);
      },
      e => {
        this.toastr.error("There has been an error whilst trying to report this response. Please try again.");
      }
    );
  }

  hideResponse(response) {
    this.kenService.hideComment({ kenCommentId: response }).subscribe(
      r => {
        this.toastr.success("Your response has been successfully removed.");
        this.getRequest(this.requestId);
      },
      e => {
        this.toastr.error("There has been an error whilst trying to remove your response. Please try again.");
      }
    );
  }

  notificationsOptIn() {
    let user = { notificationOptIn: 'Y' };
    this.memberService.UserUpdate(user).subscribe(
      r => {
        this.toastr.success('You have successfully opted-in to receive notifications by email. You can change this preference in My Profile at any time.');
        this.currentUserNotifications = 'Y';
      },
      e => {
        this.toastr.error('There was an error whilst trying to update your profile details. Please try again.');
      }
    );
  }

  navForum() {
    this.router.navigate(['/forum']);
  }

}
