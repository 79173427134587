import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';

import { MemberService } from '../utilities/services/member.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None, // New spinner (child-elements)
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: FormGroup;
  success: boolean = false;
  error: boolean = false;
  copyDate = new Date(); // Set copyright year
  errorText: string;

  metaTitle: string = 'Reset Password | NHS Benchmarking Network';

  constructor(
    fb: FormBuilder,
    private _MemberService: MemberService,
    private router: Router, // Set page titles
    private titleService: Title, // Set page titles
    private meta: Meta,
    private toastr: ToastrService,
    public newSpinner: Angular2PromiseButtonModule // New spinner
  ) { 
    this.resetPassword = fb.group({
      "emailAddress": ["", [Validators.required, Validators.maxLength(200), <any>Validators.email]]
    });
    
    this.resetPassword.valueChanges.subscribe(
      data => this.onValueChanged(data)
    );

    this.onValueChanged();
  }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle); // Set page title
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
  }

  onSubmit() {
    // Spinner
    this.newSpinner = new Promise((resolve, reject) => {
      setTimeout(resolve, 3000);
    });
    // Reset password
    this._MemberService.ResetPassword(this.resetPassword.value.emailAddress).subscribe(
      r => {
        this.success = true;
      },
      e => {
        this.error = true;
        // Invalid user
        if(e.error.error.statusCode == '6101') {
          this.errorText = "Email address not found, please try another.";
          this.toastr.error(
            'Email address not found, please try another or contact <a href="https://www.nhsbenchmarking.nhs.uk/contact">The Support Team <i class="far fa-envelope"></i></a>',
            "Reset Password",
            { enableHtml: true }
          );
        } 
        // Disabled or Inactive user
        if(e.error.error.statusCode == '6105') {
          this.errorText = "Disabled or Inactive user detected.";
          this.toastr.error(
            'Disabled or Inactive user detected, please contact <a href="https://www.nhsbenchmarking.nhs.uk/contact">The Support Team <i class="far fa-envelope"></i></a>',
            "Disabled or Inactive user",
            { enableHtml: true }
          );
        } else {
          console.log(e)
        }
      },
      () => {}
    )
  }

  onValueChanged(data?: any) {
    if (!this.resetPassword) { return; }
    const form = this.resetPassword;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.touched && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  formErrors = {
    'emailAddress': ''
  };

  validationMessages = {
    'emailAddress': {
      'required': 'Email Address is required.',
      'maxLength': 'Email Address cannot be longer than 200 characters.',
      'email': 'Email Address is not valid.'
    }
  }
}