<div class="option-container" (clickElsewhere)="optionsOpen = false">
  <div class="form-control option-select" (click)="options.length ? optionsOpen = !optionsOpen : null" [class.disabled]="options.length == 1 || options.length == 0">
    <ng-container *ngIf="options.length">{{ selectedOptionDetails ? selectedOptionDetails.optionName : 'Select an option' }}</ng-container>
    <i class="fa fa-angle-down" style="float:right;font-weight:bold"></i>
  </div>
  <div class="option-dropdown" *ngIf="optionsOpen">
    <div *ngFor="let group of options | groupBy: 'primaryReportOptionName' | pairs">
      <div class="option-dropdown-group" (click)="group.open = !group.open">
        <div [class.active]="group[0] == selectedOptionDetails.primaryReportOptionName">
          {{ group[0] }}
          <i class="fa fa-caret-up" *ngIf="group.open"></i>
          <i class="fa fa-caret-down" *ngIf="!group.open"></i>
        </div>
      </div>
      <ng-container *ngIf="group.open">
        <div *ngFor="let opt of group[1]" (click)="onOptionChange(opt.optionId)" class="option-dropdown-item" [class.active]="opt.optionId == selectedOption">
          {{ opt.secondaryReportOptionName }}
        </div>
      </ng-container>
    </div>
  </div>
</div>