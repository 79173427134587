<div
  *ngIf="loaderService.isLoading | async"
  class="loading-spinner loading-above"
>
  <i class="fa fa-spinner fa-spin"></i>
</div>

<div *ngIf="sections" class="project-table">
  <ng-container *ngFor="let section of sections">
    <div  *ngIf="section.projects.length > 0">
      <div class="section-header">
        <img
          alt="NHSBN {{ section.title }} logo"
          src="../assets/images/{{ section.title.replaceAll(' ', '') }}.png"
        />
        <span>{{ section.title }}</span>
      </div>
      <i class="mobile-scroll">
        Scroll horizontally in table to view options
        <span class="fa fa-caret-right"></span>
      </i>
      <div class="table-responsive">
        <table class="table table-hover">
          <tbody>
            <tr *ngFor="let p of section.projects">
              <td class="col col-title">
                <h4 class="details-link" (click)="navProjectDetail(p.projectId)">
                  <i
                    class="far fa-info-circle"
                    tooltip="Learn more about the {{ p.projectName }}"
                    placement="right"
                  ></i>
                  {{ p.projectName }}
                </h4>
              </td>

              <td class="col col-sign-up">
                <div *ngIf="p.signUp == 'Y'">
                  <button
                    class="btn btn-primary"
                    (click)="
                      navProjectRegistration(p.projectId, organisationSelection)
                    "
                  >
                    <span *ngIf="!p.registrationId">Register</span>
                  </button>
                </div>

                <div *ngIf="p.signUp == 'A'">
                  <button
                    class="btn"
                    [ngClass]="
                      p.registrationLocked == 'N' ? 'btn-warning' : 'btn-success'
                    "
                    (click)="
                      navProjectRegistration(p.projectId, organisationSelection)
                    "
                  >
                    <span *ngIf="p.registrationLocked == 'N'">
                      Registration Initiated
                    </span>
                    <span *ngIf="p.registrationLocked == 'Y'">
                      Registration Complete
                    </span>
                  </button>
                </div>

                <div *ngIf="p.signUp == 'N'">
                  <button
                    class="btn btn-default"
                    disabled
                    tooltip="{{ getRegistrationClosedTooltip(p) }}"
                    placement="left"
                  >
                    Registration
                  </button>
                </div>
              </td>

              <td class="col col-sign-up">
                <button
                  *ngIf="p.accessProject == 'Y'"
                  class="btn btn-default"
                  (click)="navProjectOverview(p.projectId)"
                >
                  Data Collection
                </button>

                <button
                  *ngIf="p.accessProject != 'Y'"
                  class="btn btn-default"
                  tooltip="{{ getDataCollectionClosedTooltip(p) }}"
                  placement="left"
                  disabled
                >
                  Data Collection
                </button>
              </td>

              <td class="col col-outputs">
                <button
                  class="btn btn-default col-btns"
                  (click)="navDashboard(p.projectId)"
                  [disabled]="
                    p.outputsAvailable === 'N' ||
                    p.allowOrganisationAccess === 'N'
                  "
                >
                  Outputs
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

  <!-- Archived -->
  <div *ngIf="archived">
    <div class="section-header -historical" id="nhsi" (click)="setHideShow()">
      <img
        alt="Historical projects logo"
        src="../assets/images/nhsbn-mark.png"
      />
      <span>Historical Projects</span>
      <span class="hideShow">
        <i *ngIf="hideShow" class="fas fa-chevron-up"></i>
        <i *ngIf="!hideShow" class="fas fa-chevron-down"></i>
      </span>
    </div>

    <i class="mobile-scroll">
      Scroll horizontally in table to view options
      <span class="fa fa-caret-right"></span>
    </i>

    <div class="table-responsive" *ngIf="hideShow">
      <table class="table table-hover">
        <tbody>
          <tr *ngFor="let p of archived.projects">
            <td class="col col-title">
              <h4 class="details-link" (click)="navProjectDetail(p.projectId)">
                <i
                  class="far fa-info-circle"
                  tooltip="Learn more about the {{ p.projectName }}"
                  placement="right"
                ></i>
                <img
                  alt="NHSBN {{ p.categoryName }} logo"
                  class="historical-logo"
                  src="../assets/images/{{
                    p.categoryName.replaceAll(' ', '')
                  }}.png"
                />
                {{ p.projectName }}
              </h4>
            </td>
            <td class="col col-sign-up">
              <!-- Not required for historical projects -->
            </td>
            <td class="col col-sign-up">
              <!-- Not required for historical projects -->
            </td>
            <td class="col col-outputs">
              <button
                class="btn btn-default col-btns"
                (click)="navDashboard(p.projectId)"
                [disabled]="p.outputsAvailable === 'N'"
              >
                Outputs
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
