import { QuestionBase } from './question-base';

export class PopupQuestion extends QuestionBase<string> {
  popupQuestions: { 
    displaySequence: number, 
    questionId: number, 
    questionText: string, 
    rawQuestionText: string, 
    questionHelp: string, 
    questionType: string, 
    decimalPlaces: number, 
    questionPart: number,
    formatModifier: string, 
    headings: Array<any>,
    isAutoSum: string,
    itemGroup: number,
    noColumns: number,
    options: Array<any>,
    rangeMin: number,
    rangeMax: number,
    showHeadings: string,
    parentQuestionId: number,
    parentQuestionText: string,
    parentQuestionHelp: string,
    dataDefinition: string
  }[] = [];

  constructor(popupQuestions: {} = {}) {
    super(popupQuestions);
    this.popupQuestions = popupQuestions['popupQuestions'] || [];
  }
}