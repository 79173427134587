import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';

import { TreeModule } from 'angular-tree-component';
import { Ng2HighchartsModule } from 'ng2-highcharts';
import { ModalModule, BsDropdownModule, TooltipModule, PopoverModule } from 'ngx-bootstrap';

import { OutputsComponent } from './outputs.component';
import { OutputsRoutingModule } from './outputs-routing.module';
import { OutputsDisplayComponent } from './outputs-display/outputs-display.component'
import { OutputsDisplayOptionsComponent } from './outputs-display/outputs-display-options/outputs-display-options.component';
import { ActiveAdminComponent } from './active-admin/active-admin.component'

import { SafePipeModule } from '../utilities/pipes/safe-pipe.module';
import { OrderByPipe, UniquePipe } from 'ngx-pipes';
import { ValidationService } from 'app/utilities/services/validation.service';
import { NgPipesModule } from 'ngx-pipes';
import { Angular2CsvModule } from 'angular2-csv';
import { EditReportComponent } from './edit-report/edit-report.component';
import { EditTierComponent } from './edit-tier/edit-tier.component';
import { ClickElsewhereDirective } from 'app/utilities/directives/clickElsewhereDirective';
import { PopupModalModule } from 'app/shared/popup-modal/popup-modal.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TreeModule.forRoot(),
    OutputsRoutingModule,
    SafePipeModule,
    Ng2HighchartsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    NgPipesModule,
    Angular2CsvModule,
    PopupModalModule
  ],
  providers: [
    OrderByPipe,
    UniquePipe,
    ValidationService
  ],
  declarations: [ 
    OutputsComponent,
    OutputsDisplayComponent,
    OutputsDisplayOptionsComponent,
    ActiveAdminComponent,
    EditReportComponent,
    EditTierComponent,
    ClickElsewhereDirective
  ]
})
export class OutputsModule {}