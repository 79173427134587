export class contactInformation {
  static contact: Array<object> = [
    {
      title: "General enquiries",
      phone: "0161 521 0818",
      email: "enquiries@nhsbenchmarking.nhs.uk",
      address: `NHS Benchmarking Network
      c/o 3000 Aviator Way
      Manchester Business Park
      Manchester
      M22 5TG`,
    },
  ];

  static acute: Array<object> = [
    {
      title: "Outpatients",
      phone: "0161 521 0923",
    },
    {
      title: "Pharmacy",
      phone: "0161 521 0930",
    },
    {
      title: "Emergency Care",
      phone: "0161 521 0934",
    },
    {
      title: "Managing Frailty",
      phone: "0161 521 0937",
    },
  ];

  static acuteAndCommunity: Array<object> = [
    {
      title: "Therapies",
      phone: "0161 521 0943",
    },
    {
      title: "Virtual Wards",
      phone: "0161 521 0927",
    },
  ];

  static community: Array<object> = [

    {
      title: "Intermediate Care",
      phone: "0161 521 0936",
    },
    {
      title: "District Nursing",
      phone: "0161 528 9527",
    },
    {
      title: "Healthy Child Programme",
      phone: "0161 521 0944",
    },
    {
      title: "Email",
      email: "nhsbn.cst@nhs.net"
    }
  ];

  static mentalHealth: Array<object> = [
    {
      title: "CYP MH",
      phone: "0161 521 0949",
      email: "nhsbn.cypmh@nhs.net"
    },
    {
      title: "AOP MH",
      email: "nhsbn.mhadult@nhs.net"
    },
    {
      title: "Mental Health",
      phone: "0161 521 0952",
    },
    {
      title: "Learning Disabilities",
      phone: "0161 521 0954",
      email: "nhsbn.mhlda@nhs.net"
    },
    {
      title: "MHIndicators",
      email: "nhsbn.mhindicators@nhs.net",
    },
    {
      title: "Improvement Standards",
      phone: "0161 521 0956",
      email: "nhsbn.nhsildsupport@nhs.net"
    },
    {
      title: "Email",
      email: "nhsbn.mhindicators@nhs.net"
    }
  ];
  static nationalAudits: Array<object> = [
    {
      title: "NACEL",
      phone: "0161 521 0866",
      email: "nhsbn.nacelsupport@nhs.net"
    },
    {
      title: "CVDPREVENT",
      email: "nhsbn.cvdprevent@nhs.net"
    },
  ];

  static commissioning: Array<object> = [
    {
      title: "ICS Benchmarker",
      phone: "0161 521 0957",
    },
  ];

  static getContactInfo() {
    return this.contact;
  }
  static getAcuteInfo() {
    return this.acute;
  }
  static getCommunityInfo() {
    return this.community;
  }
  static getMentalHealthInfo() {
    return this.mentalHealth;
  }
  static getNationalAuditsInfo() {
    return this.nationalAudits;
  }
  static getCommissioningInfo() {
    return this.commissioning;
  }
  static getacuteAndCommunity() {
    return this.acuteAndCommunity;
  }
}
