import { Component, OnInit, ViewChild, HostListener, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Meta, Title, DOCUMENT } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap';
import { UniquePipe, FlattenPipe, MakePluralStringPipe, OrderByPipe } from 'ngx-pipes';

import { Event } from '../utilities/classes/event/event'
import { EventRegistration } from '../utilities/classes/event/event-registration'
import { MemberService } from '../utilities/services/member.service'
import { ErrorService } from '../utilities/services/error.service';
import { WINDOW } from "../utilities/services/window.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [ MakePluralStringPipe, OrderByPipe ]
})

export class EventsComponent implements OnInit {
  @ViewChild('lgModal') public lgModal:ModalDirective;
  eventList: Event[]; filteredEvents: Event[];
  currentEvent: number; currentOrganisation: number;
  orgList: any[];
  eventId: number;
  eventTitle: string;
  eventRegistration: FormGroup;
  girft: boolean = false;
  orgSelection: boolean = false;
  isOnline: boolean = false;
  registered: boolean = false;
  public navIsFixed: boolean = false;
  public width: number;

  filter;
  categories; regions;
  hideShowCategories: boolean = false;
  hideShowRegions: boolean = false;
  showingPastEvents: boolean = false;
  documents;
  linkedEvent;

  quotes; selectedQuotes = [];
  metaTitle: string = 'Events | NHS Benchmarking Network';
  
  constructor(
    public unique: UniquePipe,
    public flatten: FlattenPipe,
    public pluralize: MakePluralStringPipe,
    public order: OrderByPipe,
    private MemberService: MemberService,
    private errorService: ErrorService,
    private http: HttpClient,
    public fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.getUserRegions();
    this.getQuotes();
  }

  getQuotes() {
    this.http.get('/assets/static/quotes.json').subscribe(
      r => {
        this.quotes = r;
        this.selectQuotes(this.quotes);
      },
      e => {
        console.log(e);
      }
    )
  }

  selectQuotes(quotes) {
    var indices = [];
    while (indices.length < 3) {
      var r = Math.floor(Math.random() * (quotes.length - 1)) + 1;
      if (indices.indexOf(r) === -1) { indices.push(r) };
    }
    indices.forEach(ind => {
      this.selectedQuotes.push(quotes[ind]);
    });
  }

  selectOrganisation() {
    let selectedOrganisation = this.eventRegistration.get('orgSelect').value;

    this.MemberService.OrganisationProducts(selectedOrganisation).subscribe(
      r => { 
        this.girft = false;        
        let nhsbn = r.data.filter(n => n.productId === 1 && n.membershipStatusId === 5);

        if (nhsbn.length > 0) {
          this.girft = true;
        }

        this.orgSelection = true;
      }
    );
  }

  getEventList(regions?) {
    this.MemberService.Events().subscribe(
      r => { 
        this.eventList = this.formatEvents(r.data);

        // Filter events to user's regions only
        if (regions) {
          this.eventList = this.eventList.filter(e => regions.includes(e.regionId) || !e.regionId)
        }

        this.categories = this.setCategories(r.data);
        this.regions = this.setRegions(r.data);
        this.filteredEvents = this.eventList.filter(e => e.when === 'future');

        if (this.route.snapshot.queryParams['category']) {
          this.filter = this.route.snapshot.queryParams['category'];
          this.filteredEvents = this.eventList.filter(r => r.projectCategoryName.includes(this.filter));
          this.showingPastEvents = true;
        }
        if (this.route.snapshot.queryParams['region']) {
          this.filter = this.route.snapshot.queryParams['region'];
          this.filteredEvents = this.eventList.filter(r => r.regionName === this.filter);
          this.showingPastEvents = true;
        }
        if (this.route.snapshot.queryParams['tag']) {
          this.filter = this.route.snapshot.queryParams['tag'];
          this.filteredEvents = this.eventList.filter(r => r.requestTags.includes(this.filter));
          this.showingPastEvents = true;
        }
        if (this.route.snapshot.queryParams['event']) {
          this.linkedEvent = +this.route.snapshot.queryParams['event'];
          this.filteredEvents = this.eventList.filter(r => r.eventId === this.linkedEvent);
          this.showingPastEvents = true;
        }

      },
      e => {
        console.log(e);
      },
      () => {
        let params = this.route.snapshot.queryParams;
        if (params.id) {
          this.showModal(null, params.id);
        }
      }
    );
  }

  getUserRegions() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let regions = [];

    if (user) {
      Object.keys(user.roles).forEach(key => {
        user.roles[key].forEach(r => {
          regions.push(r.regionId)
        })
      });
      regions = this.unique.transform(regions);
      this.getEventList(regions);
    } else {
      this.getEventList();
    }    
  }

  getOrganisationsByUser() {
    this.MemberService.OrganisationsByUser().subscribe(
      o => { 
        this.orgList = o.data;
      }
    );
  }

  registerEvent() {
    let data = this.eventRegistration.value;

    let eventDetails: EventRegistration = {
      organisationId: data.orgSelect,
      delegateName: data.delegateName,
      delegateEmail: data.delegateEmail,
      delegateJobTitle: data.delegateJob,
      delegateContactNo: data.delegateNumber,
      twitterHandle: data.delegateTwitter,
      dietaryRequirements: data.delegateDiet,
      accessRequirements: data.delegateAccess,
      dietaryDetails: data.delegateAllergies,
      delegateList: data.delegateList
    }

    this.MemberService.RegisterEvent(this.eventId, eventDetails).subscribe(
      s => {
        this.registered = true;        
        this.orgSelection = false;
      },
      e => {
        console.log(e)
        this.toastr.error(this.errorService.getErrorMessage(e.error.error.statusCode));
        this.lgModal.hide();
        if (!e.error.error.message) {
          this.toastr.error('There has been an error whilst trying to register for this event. Please contact the Support Team.');
          this.lgModal.hide();
        }
      }
    );
  }

  cancelEvent() {
    this.lgModal.hide(); 

    this.orgSelection = false; 
    this.registered = false; 
    this.isOnline = false;
  }
  
  hideModal() {
    this.orgSelection = false;
    this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
  }

  showModal(eventName: string, eventId: number) {
    if(!eventName) {
      eventName = this.filteredEvents.filter(event => event.eventId == eventId)[0].eventName
    }

    this.buildEventForm();

    this.eventRegistration.controls['delegateDiet'].setValue('N');
    this.eventRegistration.controls['delegateList'].setValue('Y');

    if (this.eventList.filter(e => e.eventId == eventId)[0].isOnline === 'Y') {
      // Hide dietary and access requirements
      this.isOnline = true;
    };

    this.lgModal.show();
    this.eventRegistration.controls['orgSelect'].setValue(0);
    this.eventTitle = eventName;
    this.eventId = eventId;

    this.router.navigate([], { queryParams: { id: eventId }, queryParamsHandling: 'merge' });
  }

  downloadIcs(eventId) {
    let now = new Date()
    let event = this.eventList.filter(e => e.eventId === eventId)[0];
    let endDate = null;
    if (event.eventEndDt) { 
      endDate = event.eventEndDt.toString().split(/[ -.: ]+/).join('') 
    }
    let parsedEvent = {
      timestamp: now,
      start: event.eventDt.toString().split(/[ -.: ]+/).join(''),
      end: endDate,
      title: event.eventName,
      description: event.eventDescription.replace(/<[^>]*>/g, ''),
      location: event.eventLocation
    }
    let icsEvent = "BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nDTSTAMP:" + parsedEvent.timestamp + "\nUID:20190702T080149Z-1105778952@marudot.com\nDTSTART;TZID=GMT Standard Time:" + parsedEvent.start + "\nDTEND;TZID=GMT Standard Time:" + parsedEvent.end + "\nSUMMARY:" + parsedEvent.title + "\nDESCRIPTION:" + parsedEvent.description + "\nLOCATION:" + parsedEvent.location + "\nEND:VEVENT\nEND:VCALENDAR"
    window.open( "data:text/calendar;charset=utf-8," + icsEvent);
  }  
  
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    let width = document.getElementById('sidebar').clientWidth;
    if (number > 80) {
      this.navIsFixed = true;
      this.width = width - 65;
    } else if (this.navIsFixed && number < 10) {
      this.navIsFixed = false;
    }
  }

  buildEventForm() {
    this.eventRegistration = this.fb.group({
      "orgSelect":[""],
      "delegateName":["", [Validators.required, Validators.maxLength(60)]],
      "delegateEmail":["", [Validators.required, Validators.email, Validators.maxLength(60)]],
      "delegateJob":["", [Validators.required, Validators.maxLength(60)]],
      "delegateNumber":["", [Validators.required, Validators.maxLength(20)]],
      "delegateTwitter":["", Validators.maxLength(30)],
      "delegateDiet":[""],
      "delegateAllergies":["", Validators.maxLength(60)],
      "delegateAccess":["", [Validators.maxLength(60)]],
      "delegateList":[""]
    })

    this.eventRegistration.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); 
  }

  onValueChanged(data?: any) {
    if (!this.eventRegistration) { return; }
    const form = this.eventRegistration;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  formErrors = {
    'orgSelect': '',
    'delegateName': '',
    'delegateEmail': '',
    'delegateJob': '',
    'delegateNumber': '',
    'delegateTwitter': '',
    'delegateDiet': '',
    'delegateAllergies': '',
    'delegateAccess': '',
    'delegateList': ''
  }

  validationMessages = {
    'delegateName': {
      'required': 'Delegate Name is required.',
      'maxlength': 'Delegate Name cannot be longer than 60 characters.'
    },
    'delegateEmail': {
      'required': 'Delegate Email Address is required.',
      'maxlength': 'Delegate Email Address cannot be longer than 60 characters.',
      'email': 'Delegate Email Address is not valid.'
    },
    'delegateJob': {
      'required': 'Job Title is required.',
      'maxlength': 'Job Title cannot be longer than 60 characters.'
    },
    'delegateNumber': {
      'required': 'Phone Number is required.',
      'maxlength': 'Phone Number cannot be longer than 20 characters.'
    },
    'delegateTwitter': {
      'required': 'Twitter Handle is required.',
      'maxlength': 'Twitter Handle cannot be longer than 30 characters.'
    },
    'delegateDiet': {
      'required': 'Diet selection required.'
    },
    'delegateAllergies': {
      'maxlength': 'Allergy Information cannot be longer than 60 characters.'
    },
    'delegateAccess': {
      'maxlength': 'Access Information cannot be longer than 60 characters.'
    }
  }

  formatEvents(events) {
    let currentDate = new Date();

    events.forEach(ev => {
      // Format tags
      let requestTags;
      if (ev.requestTags === null) {
        requestTags = '';
      } else {
        requestTags = ev.requestTags.split(', ');
      }
      ev.requestTags = requestTags;
      // Set when: past or future
      let eventDate = new Date(ev.eventDt);
      if (eventDate >= currentDate) {
        ev.when = 'future';
      } else {
        ev.when = 'past';
      }
    });

    // Order future events in chronological order
    // and past events in reverse chronological order
    let future = this.order.transform(events.filter(e => e.when === 'future'), 'eventDt');
    let past = this.order.transform(events.filter(e => e.when === 'past'), '-eventDt');
    events = future.concat(past);

    return events;
  }

  setCategories(events) {
    let categories = events.map(r => r.projectCategoryName);
    categories = this.unique.transform(categories);
    categories = this.flatten.transform(categories);
    return categories;
  }

  setRegions(events) {
    let regions = events.map(r => r.regionName);
    regions = this.unique.transform(regions);
    regions = this.flatten.transform(regions);
    return regions;
  }

  togglePast() {
    this.showingPastEvents = !this.showingPastEvents;
    if (this.showingPastEvents) {
      this.filteredEvents = this.eventList.filter(e => e.when === 'past');
    } else {
      this.filteredEvents = this.eventList.filter(e => e.when === 'future');
      this.clearFilter();
    }
  }

  filterByCategory(filter) {
    this.filter = filter;
    this.showingPastEvents = true;
    this.filteredEvents = this.eventList.filter(r => r.projectCategoryName.includes(this.filter));
    this.router.navigate([], { relativeTo: this.route, queryParams: { category: filter }, queryParamsHandling: 'merge' });
  }

  filterByRegion(filter) {
    this.filter = filter;
    this.showingPastEvents = true;
    this.filteredEvents = this.eventList.filter(r => r.regionName === filter);
    this.router.navigate([], { relativeTo: this.route, queryParams: { region: filter }, queryParamsHandling: 'merge' });
  }

  filterByTag(filter) {
    this.filter = filter;
    this.showingPastEvents = true;
    this.filteredEvents = this.eventList.filter(r => r.requestTags.includes(filter));
    this.router.navigate([], { relativeTo: this.route, queryParams: { tag: filter }, queryParamsHandling: 'merge' });
  }

  clearFilter() {
    this.filter = null;
    this.linkedEvent = null;
    this.filteredEvents = this.eventList;
    this.hideShowCategories = false;
    this.hideShowRegions = false;
    this.router.navigate([], { relativeTo: this.route, queryParams: { category: null, event: null, tag: null, region: null }, queryParamsHandling: 'merge' });
  }

  getDocuments(event) {
    if (event.showDocuments && event.documents) {
      event.showDocuments = false;
    } else if (!event.showDocuments && event.documents) {
      event.showDocuments = true;
    } else {
      this.MemberService.eventDocuments(event.eventId).subscribe(
        r => {
          let findEvent = this.eventList.indexOf(event),
              currentEvent = this.eventList[findEvent];
          currentEvent['documents'] = this.formatDocuments(r.data)[0];
          currentEvent['showDocuments'] = true;
        },
        e => {
          console.log(e);
        }
      );
    }
  }

  formatDocuments(documents) {
    let output = [];
    Object.keys(documents).map(key => {
      Object.keys(documents[key]).map(docs => {
        output.push(documents[key][docs]);
      });
      return output;
    })
    return output;
  }

  getDocument(documentId: number) {
    this.MemberService.AWSSelectedDocument(documentId).subscribe(
      a => { window.open(a.data.documentUrl) }
    );
  }

  copyLink(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Event link copied to clipboard.');
  }

  navHome() {
    this.router.navigate(['/home']);
  }

};