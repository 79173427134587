import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NonMembersEventRegistrationFormComponent } from './non-members-event-registration-form.component';

const userCheckRoutes: Routes = [
  { path: 'nonMembersEventRegistrationForm', component: NonMembersEventRegistrationFormComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(userCheckRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class NonMembersEventRegistrationFormRoutingModule { }