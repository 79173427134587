<div class="service-container">
    <h4>Submission {{ index }}: {{submission.submissionName}}</h4>

    <div class="service">
        <div class="hint -link" (click)="serviceFormAdd(submission.submissionId)" style="margin-top:0" *ngIf="submission.services?.length == 0 && projectId === 6">
            <i class="fas fa-lightbulb-on"></i>Specify an emergency department type for this department.
        </div>
        <div class="hint -link" (click)="serviceFormAdd(submission.submissionId)" style="margin-top:0" *ngIf="submission.services?.length == 0 && projectId !== 6">
            <i class="fas fa-lightbulb-on"></i>Add {{ maxServices !== 100 ? (maxServices === 1 ? 'a' : maxServices) : 'at least one' }} {{ serviceTitle.toLowerCase() }} for this submission.
        </div>
        <table class="table" *ngIf="submission.services.length > 0">
            <thead>
                <tr>
                    <th [class]="projectId !== 6 && projectId !== 40 ? 'col-md-6' : 'col-md-12'">{{ serviceTitle }}</th>
                    <th *ngIf="projectId !== 6 && projectId !== 40" class="col-md-4">{{ serviceTitle }} Lead</th>
                    <th class="col-md-2"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let s of submission.services">
                    <td>{{s.serviceItemName}}</td>
                    <td *ngIf="projectId !== 6 && projectId !== 40">
                        <!-- If/else to make Remove button show on creation -->
                        <div *ngIf="s.serviceLeadList?.length > 0; then serviceLead else noServiceLead"></div>
                        <ng-template #serviceLead>
                            <a href="mailto:{{s.serviceLeadList[0].logonName}}">{{s.serviceLeadList[0].fullName}}</a>&nbsp;
                            <button class="btn btn-xs btn-danger" *ngIf="!registration.registration.registrationLocked && registration.organisation.allowChanges && submission.responseCount < 1" (click)="serviceUpdateLeadRemove(submission.submissionId, s.serviceItemId, s.serviceLeadList[0].userId)">Remove</button>
                        </ng-template>
                        <ng-template #noServiceLead>
                            <span class="text-muted">-</span>&nbsp;
                            <button class="btn btn-xs btn-default" *ngIf="!registration.registration.registrationLocked && registration.organisation.allowChanges" (click)="serviceUpdateLeadAdd(submission.submissionId, s.serviceItemId)">Edit</button>
                        </ng-template>
                    </td>
                    <td class="text-right">
                        <!-- If/else to make Remove button show on creation -->
                        <div *ngIf="submission.responseCount > 0 || submission.peerGroupMembershipCount > 0 || submission.submissionCaseCodeCount > 0 || submission.submissionCodeCount > 0; then cannotRemove else canRemove"></div>
                        <ng-template #cannotRemove>
                            <div *ngIf="!registration.registration.registrationLocked && registration.organisation.allowChanges">
                                <button class="btn btn-xs btn-halt" tooltip="Data collection has started. To make changes, please contact the Project Team." placement="top">Remove</button>
                            </div>
                        </ng-template>
                        <ng-template #canRemove>
                            <div *ngIf="!registration.registration.registrationLocked && registration.organisation.allowChanges">
                                <button class="btn btn-xs btn-danger" (click)="serviceFormRemove(submission.submissionId, s.serviceItemId)">Remove</button>
                            </div>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
        <button 
            type="button"
            class="btn btn-primary" 
            *ngIf="showServiceFormAdd && submission.services?.length < services.length && submission.services?.length < maxServices && !registration.registration.registrationLocked && registration.organisation.allowChanges" 
            (click)="serviceFormAdd(submission.submissionId)">
                {{ projectId === 6 ? 'Specify emergency department type' : 'Add ' + serviceTitle }}
        </button>

        <!-- New Service Form -->
        <form *ngIf="showServiceForm" [formGroup]="serviceForm" novalidate>
            <hr *ngIf="submission.services?.length > 0">
            <div class="form-group">
                <label>Add {{ serviceTitle }}</label>                        
                <select class="form-control" formControlName="serviceItemId">
                    <option [ngValue]="null" disabled>Choose a {{ serviceTitle.toLowerCase() }}...</option>
                    <option *ngFor="let s of currentServices" [ngValue]="s.serviceItemId">{{s.serviceItemName}}</option>
                </select>
            </div>
        
            <div *ngIf="formErrors.serviceItemId" class="alert alert-danger">
                {{ formErrors.serviceItemId }}
            </div>

            <button type="button" class="btn btn-success button-margin" [disabled]="!serviceForm.valid" (click)="serviceFormSubmit()">Save</button>
            <button type="button" class="btn btn-default button-margin" (click)="showServiceForm = false; showServiceFormAdd = true;">Cancel</button>
        </form>

        <!-- New Service Lead Form -->
        <form *ngIf="showServiceLeadForm" [formGroup]="serviceLeadForm" novalidate>
            <hr>
            <div class="form-group">
                <label>{{ serviceTitle }} Lead Name</label>
                <input type="text" class="form-control" formControlName="fullName">
            </div>
        
            <div *ngIf="formErrors.fullName" class="alert alert-danger">
                {{ formErrors.fullName }}
            </div>
        
            <div class="form-group">
                <label for="logonName">{{ serviceTitle }} Lead Email</label>
                <input type="email" formControlName="logonName" class="form-control">
            </div>
        
            <div *ngIf="formErrors.logonName" class="alert alert-danger">
                {{ formErrors.logonName }}
            </div>
        
            <button type="button" class="btn btn-success button-margin" [disabled]="!serviceLeadForm.valid" (click)="serviceLeadFormSubmit()">Save</button>
            <button type="button" class="btn btn-default button-margin" (click)="showServiceLeadForm = false;">Cancel</button>
        </form>
    </div>
</div>