import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { MemberService } from '../../utilities/services/member.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pr-deputy-project-lead',
  templateUrl: './pr-deputy-project-lead.component.html',
  styleUrls: ['./pr-deputy-project-lead.component.scss']
})
export class PrDeputyProjectLeadComponent implements OnInit, OnChanges {
  @Input() registration;
  @Output() deputyProjectLeadAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() deputyProjectLeadRemove: EventEmitter<any> = new EventEmitter<any>();

  deputyProjectLead; deputyProjectLeadForm: FormGroup;
  showDeputyProjectLeadForm: boolean = false; 
  showDeputyProjectLeadFormAdd: boolean = true;
  maxDeputyProjectLeads: number;

  constructor(
      private memberService: MemberService, 
      private fb: FormBuilder,
      private toastr: ToastrService,
    ) {
  }

  ngOnInit(): void {
    this.maxDeputyProjectLeads = this.registration.registration.registrationType.maxDeputyProjectLeads
    if(this.maxDeputyProjectLeads == null){
      this.maxDeputyProjectLeads = 100;
    }
  }

  ngOnChanges() {
    if (this.registration) {
      this.deputyProjectLead = this.registration.registration.deputyProjectLead.deputyProjectLeadList
      this.buildDeputyProjectLeadForm();
    }
  }

  buildDeputyProjectLeadForm() {
    this.deputyProjectLeadForm = this.fb.group({    
      logonName: ['', [Validators.required, Validators.maxLength(200), <any>Validators.email]],
      fullName: ['', [Validators.required, Validators.maxLength(150)]],
      jobTitle: ['', [Validators.required, Validators.maxLength(150)]],
      phoneNo: ['', [Validators.maxLength(40)]]
    });

    this.deputyProjectLeadForm.valueChanges.subscribe(
      data => this.onValueChanged(data)
    );

    this.onValueChanged();
  }

  deputyProjectLeadFormSubmit() {

    let logonNames = this.registration.registration.deputyProjectLead.deputyProjectLeadList.map(d => d.logonName),
        deputyProjectLead = {
          fullName: this.deputyProjectLeadForm.value.fullName,
          logonName: this.deputyProjectLeadForm.value.logonName,
          jobTitle: this.deputyProjectLeadForm.value.jobTitle,
          phoneNo: this.deputyProjectLeadForm.value.phoneNo
        };

    if (logonNames.includes(this.deputyProjectLeadForm.value.logonName)) {
      this.toastr.error('This user has already been added as a Deputy Project Lead');
    } else {
      this.addDeputyProjectLead(this.registration.registrationId, deputyProjectLead);
      this.showDeputyProjectLeadForm = !this.showDeputyProjectLeadForm;
      this.showDeputyProjectLeadFormAdd = !this.showDeputyProjectLeadFormAdd;
      this.buildDeputyProjectLeadForm();
    }

  }

  getUserDetails() {
    this.memberService.User().subscribe(
      u => { 
        let user = u.data;
        this.deputyProjectLeadForm.controls['fullName'].setValue(user.fullName);
        this.deputyProjectLeadForm.controls['logonName'].setValue(user.logonName);
        this.deputyProjectLeadForm.controls['phoneNo'].setValue(user.phoneNo);
        this.deputyProjectLeadForm.controls['jobTitle'].setValue(user.jobTitle);
      }
    )
  };

  deputyProjectLeadFormRemove(userId: number) {
    if (confirm("Are you sure you want to remove this Deputy Project Lead?")) {
      this.removeDeputyProjectLead(this.registration.registrationId, userId);
    }
  }

  addDeputyProjectLead(registrationId: number, deputyProjectLeadDetails) {
    this.memberService.AddDeputyProjectLead(registrationId, deputyProjectLeadDetails).subscribe(
      r => {
        this.deputyProjectLeadAdd.emit(r.data[0]);
      },
      e => {
        console.log('There has been an error whilst adding a deputy project lead.');
      }
    );
  }

  removeDeputyProjectLead(registrationId: number, userId: number) {
    this.memberService.RemoveDeputyProjectLead(registrationId, userId).subscribe(
      r => {        
        this.deputyProjectLeadRemove.emit(userId);
      },
      e => {
        console.log('There has been an error whilst removing a deputy project lead.');
      }
    );
  }
  
  onValueChanged(data?: any) {
    if (!this.deputyProjectLeadForm) { return; }
    const form = this.deputyProjectLeadForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  formErrors = {
    'fullName': '',
    'logonName': ''
  }

  validationMessages = {
    'fullName': {
      'required': 'Full name is required.',
      'maxlength': 'Full name cannot be longer than 150 characters.'
    },
    'logonName': {
      'required': 'Email Address is required.',
      'maxlength': 'Email Address cannot be longer than 200 characters.',
      'email': 'Email Address is not valid.'
    },
    'jobTitle': {
      'required': 'Job title is required.',
      'maxlength': 'Job title cannot be longer than 150 characters.'
    },
    'phoneNo': {
      'required': 'Phone number is required.',
      'maxlength': 'Phone number cannot be longer than 40 characters.'
    }
  }
}