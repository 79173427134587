export class Constants {
    
    public static readonly currentYear = new Date().getFullYear();
    public static readonly currentYearShort: number = +Constants.currentYear.toString().slice(-2);

    public static readonly placeholders = [
        { id: 'dym2', name: 'DOUBLE_YEAR_MINUS_2', example: (Constants.currentYear - 2) + '/' + (Constants.currentYearShort - 1) },
        { id: 'dym3', name: 'DOUBLE_YEAR_MINUS_3', example: (Constants.currentYear - 3) + '/' + (Constants.currentYearShort - 2) },
        { id: 'dyp',  name: 'DOUBLE_YEAR_PREVIOUS', example: (Constants.currentYear - 1) + '/' + (Constants.currentYearShort) },
        { id: 'dyc',  name: 'DOUBLE_YEAR_CURRENT', example: Constants.currentYear + '/' + (Constants.currentYearShort + 1) },
        { id: 'dyn',  name: 'DOUBLE_YEAR_NEXT', example: (Constants.currentYear + 1) + '/' + (Constants.currentYearShort + 2) },
        { id: 'sym3', name: 'SINGLE_YEAR_MINUS_3', example: Constants.currentYear - 3 },
        { id: 'sym2', name: 'SINGLE_YEAR_MINUS_2', example: Constants.currentYear - 2 },
        { id: 'symp', name: 'SINGLE_YEAR_PREVIOUS', example: Constants.currentYear - 1 },
        { id: 'symc', name: 'SINGLE_YEAR_CURRENT', example: Constants.currentYear },
        { id: 'symn', name: 'SINGLE_YEAR_NEXT', example: Constants.currentYear + 1 },
    ];

    public static readonly products = [
        { id: 1, name: 'NHS Benchmarking Network'},
        { id: 2, name: 'National Audit of Intermediate Care'},
        { id: 3, name: 'Patients Association Complainants Survey'},
        { id: 4, name: 'Community Hospitals Research'},
        { id: 5, name: 'National Audit for Care at the End of Life'},
        { id: 6, name: 'NHSI - Learning Disabilities'},
        { id: 7, name: 'Community Services Covid-19 Dashboard'},
        { id: 8, name: 'CVDprevent'},
        { id: 9, name: 'Same Day Emergency Care'},
        { id: 12, name: 'South East Mental Health Dashboard'},
        { id: 13, name: 'Workforce Programme'},
        { id: 14, name: 'Bespoke Mental Health'},
    ];
}