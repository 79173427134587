import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment'

@Injectable()
export class KenService {
  private URL: string = environment.apiURL;

  constructor(private http: HttpClient) { }

  public getProjects(): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'projects', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getRequests(): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'knowledgeExchange/allRequests', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getRequest(request: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http
      .get(this.URL + 'knowledgeExchange/' + request + '/details', {
        headers: new HttpHeaders().set('Authorization', token)
      });
  }

  public addRequest(request): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'knowledgeExchange/addRequest', JSON.stringify(request), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public addComment(request: number, comment): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'knowledgeExchange/' + request + '/addComment', JSON.stringify(comment), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public hideComment(comment): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'knowledgeExchange/hideComment', JSON.stringify(comment), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public reportComment(comment): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'knowledgeExchange/reportComment', JSON.stringify(comment), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public getProjectRequests(projectId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'knowledgeExchange/getProjectRequests', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('projectId', projectId.toString())
      }
    );
  }

  public submitRequestProjects(requestId: number, projectIds): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'knowledgeExchange/' + requestId + '/addRequestProjects', JSON.stringify({ projectList: projectIds }), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public removeRequestProjects(requestId: number, projectIds): Observable<any> {
    let token = localStorage.getItem('id_token');
    let options = {
      body: JSON.stringify({ projectList: projectIds }), 
      headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
    };

    return this.http.delete(this.URL + 'knowledgeExchange/' + requestId + '/removeRequestProjects', options);
  }

  public editRequest(requestId: number, requestDetails): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.patch(this.URL + 'knowledgeExchange/' + requestId + '/modifyRequest', JSON.stringify(requestDetails), {
      headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
    });
  }

}