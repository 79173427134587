<!-- SELECTOR BAR-->
<div id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back" (click)="navHome()">
            <i class="fa fa-caret-left"></i> Back to Home
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-fluid" id="containerForum" *ngIf="!request && !editingRequest">

  <!-- SIDEBAR -->
  <div class="col-lg-4 col-md-4 col-sm-12" id="sidebar">
    <div>
      <h1>Forum</h1>
      <p class="intro-p">
        We have now moved our Forum over to the NHSFutures platform. You can access our extensive range of sector forums and register for NHSFutures via the below button.      
        <a class="btn btn-primary btn-lg btn-request" href="https://future.nhs.uk/BenchmarkingNetwork" target="_blank">
          NHSBN NHSFutures Workspace
        </a>
      <p>
        The NHSBN Forum will remain accessible to members to review past entries and network with the submitters. All new discussions will take place on our NHSFutures workspace, and we encourage you to join the discussions on there.
        If you have any questions, <a [routerLink]="['/contact']">please get in touch</a>.
       </p>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="col-lg-7 col-lg-offset-1 col-md-8 col-sm-12">

    <div class="loading-overview" *ngIf="!filteredRequests">
      <p><i class="fas fa-spinner fa-spin"></i> Loading requests. Please wait...</p>
    </div>

    <div id="search" *ngIf="filteredRequests">
      <div class="form-group">
        <i class="fal fa-search" id="searchIcon"></i>
        <input type="text" class="form-control" placeholder="e.g. Cost improvement schemes" [(ngModel)]="searchText" />
      </div>

      <div class="filter" *ngIf="hideShowProject">
        <span *ngFor="let i of projects | without: [null] | unique | orderBy: i" (click)="filterByProject(i)" [class]="filter == i ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'">{{ i }}</span>
        <span (click)="clearFilter()" class="btn btn-sm btn-default btn-filter"><i class="far fa-close"></i></span>
      </div>

      <div class="filter" *ngIf="hideShowSector">
        <span *ngFor="let i of sectors | without: [null] | unique | orderBy: i" (click)="filterBySector(i)" [class]="filter == i ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'">{{ i }}</span>
        <span (click)="clearFilter()" class="btn btn-sm btn-default btn-filter"><i class="far fa-close"></i></span>
      </div>

      <div class="filter" *ngIf="hideShowTag">
          <span *ngFor="let i of tags | without: [null] | unique | orderBy: i" (click)="filterByTag(i)" [class]="filter == i ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'">{{ i }}</span>
          <span (click)="clearFilter()" class="btn btn-sm btn-default btn-filter"><i class="far fa-close"></i></span>
        </div>

      <div id="options">
        <button (click)="hideShowProject = !hideShowProject" [class]="hideShowProject ? 'btn btn-sm btn-default' : 'btn btn-sm btn-primary'">
            <i class="fas fa-filter"></i>{{ hideShowProject ? 'Close' : 'Filter by Project' }} 
        </button>
        <button (click)="hideShowSector = !hideShowSector" [class]="hideShowSector ? 'btn btn-sm btn-default' : 'btn btn-sm btn-primary'">
          <i class="fas fa-filter"></i>{{ hideShowSector ? 'Close' : 'Filter by Sector' }} 
        </button>
        <button (click)="hideShowTag = !hideShowTag" [class]="hideShowTag ? 'btn btn-sm btn-default' : 'btn btn-sm btn-primary'">
          <i class="fas fa-filter"></i>{{ hideShowTag ? 'Close' : 'Filter by Tag' }} 
        </button>
        <button (click)="setExpandCollapse()" [class]="expanded ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'">
          <i [class]="expanded ? 'far fa-bars' : 'far fa-rectangle-landscape'"></i>{{ expanded ? 'List View' : 'Card View' }}
        </button>
      </div>

      <div *ngIf="filteredRequests && (filteredRequests | filterBy: ['requestTitle', 'requestBody']: searchText).length === 0" class="no-matches">
        There are no requests matching this search.
      </div>

      <div *ngIf="filter" class="filter-showing" (click)="clearFilter()">
        Results are filtered to <strong>{{ filter }}</strong> <i class="far fa-times"></i>
      </div>

      <div *ngFor="let group of filteredRequests | orderBy: '-requestDate' | groupBy: 'requestMonth' | pairs">
        <div *ngIf="(group[1] | filterBy: ['requestTitle', 'requestBody']: searchText).length > 0" class="group-header">
          {{ group[0] }}
        </div>
        <div *ngFor="let req of group[1] | filterBy: ['requestTitle', 'requestBody']: searchText" [class]="expanded ? 'request' : 'request-collapsed'">
          <span *ngIf="currentUserApproved && expanded" class="edit" (click)="editRequest(req.kenRequestId)">
            <i class="fas fa-pen-square"></i>
          </span>
          <div class="request-header">
            <h3 innerHTML="{{ req.requestTitle | highlight: searchText }}" (click)="navRequest(req.kenRequestId)"></h3>
            <p class="request-meta" *ngIf="expanded">
              <span *ngIf="req.projectName.length">
                {{ req.projectName.join(', ') }}
                &bull;
              </span>
              {{ req.requestDate | date: 'd MMMM y' }}
              <span *ngIf="req.commentCount > 0">
                &bull;
                {{ req.commentCount }} {{ 'response' | makePluralString: req.commentCount }}
              </span>
            </p>
          </div>
          <div *ngIf="expanded" class="request-body">
            <p>
              <span innerHTML="{{ req.requestBody | shorten: 200: '...' | stripTags | highlight: searchText }}"></span>
              <span (click)="navRequest(req.kenRequestId)" class="text-primary read-more">Read&nbsp;more&nbsp;<i class="fas fa-caret-right"></i></span>
            </p>
            <p class="request-tags" *ngIf="expanded && req.requestTags.length">
              <i class="fas fa-tag"></i> Tags: <span *ngFor="let tag of req.requestTags" class="tag" (click)="filterByTag(tag)">{{ tag }}</span>
            </p>
          </div>
        </div>
      </div>

    </div> <!-- end #search -->

  </div> <!-- end row -->
</div>

<!-- ADMIN -->
<div id="activeAdminBar" [class]="request || editingRequest ? 'top' : ''" *ngIf="currentUserApproved">
  <button class="btn btn-default" (click)="setRequest(true)" *ngIf="!request && !editingRequest">
    <i class="fas fa-plus-circle"></i> Add Request
  </button>
  <button class="btn btn-default" (click)="request = false; editingRequest = false;" *ngIf="request || editingRequest">
    <i class="fas fa-caret-down"></i> Close
  </button>
</div>

<div class="container">
  <div class="col-md-8 col-md-offset-2">
    <div id="newRequest" *ngIf="(request || editingRequest) && projects">

      <h2>{{ editingRequest ? 'Edit' : 'Add a new' }} request</h2>
      <p>Use the form below to {{ editingRequest ? 'edit this' : 'add a new' }} request on the forum.</p>
      <hr>

      <form [formGroup]="requestForm">

        <div class="form-group">
          <label for="requestTitle">Title <span class="text-danger">*</span></label>
          <input class="form-control" formControlName="requestTitle">
        </div>
        <div class="form-group">
          <label for="requestBody">Body <span class="text-danger">*</span></label>
          <!-- <textarea rows="8" class="form-control" formControlName="requestBody"></textarea> -->
          <angular-editor formControlName="requestBody" [config]="editorConfig"></angular-editor>
        </div>
        <div class="form-group">
          <label for="requestTags">Tags</label>
          <input class="form-control" formControlName="requestTags">
          <p class="help-block">Add as comma separated list, e.g. workforce, activity, finance</p>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="categoryId">Category <span class="text-danger">*</span></label>
              <select class="form-control" formControlName="categoryId">
                <option value="" disabled selected>Select a category...</option>
                <option *ngFor="let c of allCategories" [value]="c.id">{{ c.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="requestDate">Date <span class="text-danger">*</span></label>
              <input class="form-control" formControlName="requestDate" placeholder="YYYY-MM-DD">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="submittedBy">User ID <span class="text-danger">*</span></label>
              <input class="form-control" formControlName="submittedBy" placeholder="23115">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="projectId1">Link to Projects (max. 3)</label>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <select class="form-control" formControlName="projectId1" (change)="selectProject(1, $event.target.value)">
                <option value="0">[No Project]</option>
                <option *ngFor="let p of allProjects | orderBy: 'name'" [value]="p.id">{{ p.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <select class="form-control" formControlName="projectId2" (change)="selectProject(2, $event.target.value)">
                <option value="0">[No Project]</option>
                <option *ngFor="let p of allProjects | orderBy: 'name'" [value]="p.id">{{ p.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <select class="form-control" formControlName="projectId3" (change)="selectProject(3, $event.target.value)">
                <option value="0">[No Project]</option>
                <option *ngFor="let p of allProjects | orderBy: 'name'" [value]="p.id">{{ p.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>
              <input type="checkbox" formControlName="linkAllProjects"> Link to all projects
            </label>
          </div>
        </div>

        <hr>
        
        <div class="form-actions">
          <button type="button" class="btn btn-success" (click)="editingRequest ? submitRequestForm(selectedRequest.kenRequestId) : submitRequestForm()" [disabled]="!requestForm.value.requestBody">Save Request</button>
          <button type="button" class="btn btn-default" (click)="request = false; editingRequest = false;">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>