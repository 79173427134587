<!-- SELECTOR BAR-->
<div id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back" (click)="navHome()">
            <i class="fa fa-caret-left"></i> Back to Home
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Navbar -->
<div *ngIf="!auth.authenticated()">
  <nav class="navbar navbar-default" data-ng-hide="contentHide">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" (click)="isCollapsed = !isCollapsed"
          aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="https://www.nhsbenchmarking.nhs.uk/" target="_blank">
          <img alt="NHSBN Logo" src="../assets/images/nhsbn-mark.png" />
        </a>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" [collapse]="isCollapsed">
        <ul class="nav navbar-nav">
          <li>
            <a [routerLink]="['/home']" routerLinkActive="active">Home</a>
          </li>
          <li>
            <a href="https://www.nhsbenchmarking.nhs.uk/contact" target="_blank">Contact</a>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li>
            <a [routerLink]="['/help']" routerLinkActive="active">Help</a>
          </li>
          <li>
            <a [routerLink]="['/landing']" routerLinkActive="active">Log in</a>
          </li>
        </ul>
      </div>
      <!-- /.navbar-collapse -->
    </div>
    <!-- /.container-fluid -->
  </nav>
  <div class="colored-border"></div>
</div>
<!-- end Navbar -->

<!-- Content -->
<div class="container-fluid" id="containerHelp">
  <div class="col-lg-6 col-lg-offset-3 col-md-12">
    <h1>Help</h1>
    <p class="intro">
      Welcome to the NHS Benchmarking Network help page. If you have a question the
      that is not answered below, please contact
      <a [routerLink]="['/contact']" routerLinkActive="active"> Support Team</a>.
    </p>

    <hr />

    <h3>Browser Compatibility</h3>
    <div class="browser-detect">
      <p *isChrome class="text-success">
        <span class="fa fa-check"></span>You are currently using Google Chrome.
      </p>
      <p *isFirefox class="text-success">
        <span class="fa fa-check"></span>You are currently using Mozilla
        Firefox.
      </p>
      <p *isSafari class="text-success">
        <span class="fa fa-check"></span>You are currently using Safari.
      </p>
      <p *isIE9 class="text-danger">
        You are currently using Internet Explorer 9.
      </p>
      <p *isIE10 class="text-danger">
        You are currently using Internet Explorer 10.
      </p>
      <p *isIE11 class="text-danger">
        You are currently using Internet Explorer 11.
      </p>
      <p *showIEVersion="['ie +12']" class="text-warning">
        You are currently using Edge.
      </p>
    </div>
    <p>
      Some important features of the Members' Area are not supported by older
      browsers, including Internet Explorer.
    </p>
    <p style="margin-bottom: 1em">
      We recommend that you use the latest version of Chrome, Firefox or Safari
      instead. You can download Chrome or Firefox by clicking the links below.
      If you have any issues with these browsers, please contact us and we will
      look into the issue.
    </p>
    <a href="https://www.google.com/chrome/" target="_blank">
      <div class="browser-link">
        <img src="../../assets/images/chrome_logo.png" alt="Google Chrome icon" />
        Google Chrome
        <p class="text-primary">Click here to download</p>
      </div>
    </a>
    <a href="https://www.mozilla.org/en-GB/firefox/new/" target="_blank">
      <div class="browser-link">
        <img src="../../assets/images/firefox_logo.png" alt="Mozilla Firefox icon" />
        Mozilla Firefox
        <p class="text-primary">Click here to download</p>
      </div>
    </a>
    <a>
      <div class="browser-link -not-allowed">
        <img src="../../assets/images/ie_logo.png" alt="Internet Explorer icon" />
        Internet Explorer
        <p class="text-danger">Not Supported</p>
      </div>
    </a>
    <a>
      <div class="browser-link -not-allowed">
        <img src="../../assets/images/edge_logo.png" alt="Edge icon" />
        Edge
        <p class="text-danger">Not Fully Supported</p>
      </div>
    </a>
    <accordion [closeOthers]="oneAtATime">
      <div class="col-md-12 col-xs-12">
        <hr />
        <h3>Tutorial Videos</h3>
        <div *ngFor="let video of videos">
          <accordion-group #group [panelClass]="customClass" (click)="!(show = show)">
            <div accordion-heading class="dp-accordion-header" (click)="stopVideos()">
              <div class="row">
                <div class="col-xs-10 col-md-10">
                  <i class="fa fa-youtube-play"></i>
                  {{ video.title }}
                </div>
                <div class="col-xs-2 col-md-2">
                  <i class="fas fa-bars"></i>
                </div>
              </div>
            </div>

            <iframe 
              [src]="video.videoURL | safe: 'resourceUrl'" 
              width="100%" 
              height="315" 
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>
            <p [innerHTML]="video.info"></p>
          </accordion-group>
        </div>
      </div>
    </accordion>

    <div class="col-md-12 col-xs-12" *ngIf="auth.authenticated()">
      <hr />
      <h3>Members' Area User Guide</h3>
      <p>
        If you are new to the NHS Benchmarking Network Members' Area, you can
        download the User Guide (as a PDF) by clicking on the button below.
      </p>
      <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Other/2024/Members%20Area%20User%20Guide%20August%202024%20FINAL.pdf"
        target="_blank" class="btn btn-lg btn-primary">Download Members' Area User Guide</a>
    </div>
  </div>
</div>
<!-- end Content -->

<!-- Footer -->
<div *ngIf="!auth.authenticated()">
  <footer id="footer">
    <div class="container-fluid">
      <div class="col-md-4">
        <img alt="NHSBN Logo" src="../assets/images/nhsbn-logo-clear.png" />
        <p>
          The Benchmarking Network is the in-house benchmarking service of the NHS. The Network is hosted by
          <a href="https://www.elft.nhs.uk/" target="_blank">East London Foundation Trust</a>.
        </p>
        <p>&copy; East London Foundation Trust {{ date | date: "yyyy" }}</p>
      </div>
    </div>
  </footer>
</div>
<!-- end Footer -->