import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';
import { CustomFormsModule } from 'ng2-validation'
import { ToastrModule } from 'ngx-toastr';
import { TabsModule, PopoverModule, AccordionModule, BsDatepickerModule } from 'ngx-bootstrap';
import { ResponsiveModule } from 'ngx-responsive';

import { DataCollectionComponent } from './data-collection.component';
import { DataCollectionRoutingModule } from './data-collection-routing.module';
import { DynamicSurveyComponent } from './dynamic-survey/dynamic-survey.component';

import { QuestionService } from '../utilities/services/question.service';
import { QuestionControlService } from '../utilities/services/question-control.service';
import { SafePipeModule } from '../utilities/pipes/safe-pipe.module'
import { OnlyNumber } from '../utilities/directives/only-number'

@NgModule({
  imports: [ 
    CommonModule,
    CustomFormsModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true
    }),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fa fa-spinner fa-spin"></span>',
      disableBtn: true, 
      btnLoadingClass: 'is-loading',
      handleCurrentBtnOnly: false
    }),
    SafePipeModule,
    DataCollectionRoutingModule,
    ResponsiveModule
  ],
  providers: [ 
    QuestionService,
    QuestionControlService 
  ],
  declarations: [ 
    DataCollectionComponent, 
    DynamicSurveyComponent,
    OnlyNumber
  ]
})
export class DataCollectionModule {}