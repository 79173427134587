import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CollectionComponent } from './collection.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const collectionRoutes: Routes = [
  { path: 'collection/:projectId', redirectTo: 'project/:projectId/collection', pathMatch: 'full' },
  { path: 'project/:projectId/collection', component: CollectionComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(collectionRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CollectionRoutingModule { }