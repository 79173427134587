<div class="container-fluid" id="containerProfile">
  <div class="container" id="containerProfile">
    <div class="row">
      <div class="col-md-12">
        <h1>Update your details</h1>
        <p>
          Please take a minute to check that the details below are up-to-date. There are two steps, and both need to be completed.
        </p>
        <p style="margin-bottom:2.5em">
          If you need any further support, please contact our <a href="mailto:enquiries@nhsbenchmarking.nhs.uk"> Support Team</a>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div>
            <p class="projects-loading" *ngIf="!user">
              <span class="fa fa-spinner fa-spin"></span> Loading user profile...
            </p>
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-12 view-card" style="margin-top:-20px">
                  <h3>Step 1 of 2: Confirm your organisation(s)</h3>
                  <p class="intro">
                    Please select all the organisations you currently work within by clicking the toggle next to the name. Leave unselected any organisations that you do not currently work within. Once complete, click <strong>Confirm Selection</strong>.
                  </p>

                  <!-- Table Start -->
                  <form [formGroup]="form" (ngSubmit)="submitOrganisation()">
                    <table class="table table-striped">
                      <tbody>
                        <tr formArrayName="organisations"
                          *ngFor="let organisation of organisationsFormArray.controls; let i = index">
                          <th scope="row" class="custom-checkbox" style="padding: 1em;">
                            {{ allOrganisations[i].organisationName }}
                          </th>
                          <th scope="row" class="custom-checkbox">
                            <input
                              (change)="organisationsSelected = false; progressBarWidth > 0 ? progressBarWidth = progressBarWidth - 50 : ''"
                              type="checkbox" [id]="i" [formControlName]="i" />
                            <label style="float: right;" [for]="i"><span class="ui"></span></label>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <hr>
                    <!-- Organisation Submit Buttons -->
                    <button type="submit" class="btn btn-primary" [disabled]="!form.valid || organisationsSelected">
                      {{ form.valid ? (organisationsSelected ? 'Updated' : 'Confirm Selection' ) : 'Confirm Selection' }}
                    </button>
                    <div style="margin-top:1em;margin-bottom:0.5em">
                      If you no longer work within any of the organisations listed above, please click <strong>Not associated with any listed organisation</strong>.
                    </div>
                    <button type="submit" class="btn btn-default" style="margin-right: 5px;" [disabled]="form.valid || organisationsSelected">
                      Not associated with any listed organisation
                    </button>
                    <!-- end Organisation Submit Buttons -->
                  </form>
                  <!-- Table end -->

                </div>
              </div>
            </div>
            <div class="row" *ngIf="user">
              <div class="col-md-12" style="margin-top:1em;">
                <div class="col-md-12 view-card">
                  <h3>Step 2 of 2: Update your profile</h3>
                  <p class="intro">
                    Please review your contact information below and click <strong>Confirm Profile Information</strong>.
                  </p>

                  <form [formGroup]="myProfile" (ngSubmit)="updateUser()" class="form-horizontal">
                    <div class="form-group">
                      <label for="logonName" class="col-md-3 col-sm-4 control-label">Email Address</label>
                      <div class="col-md-9 col-sm-8">
                        <input type="email" formControlName="logonName" class="form-control" />
                        <div class="alert alert-info">
                          If you need to change your email address, please contact the
                          <a href="mailto:enquiries@nhsbenchmarking.nhs.uk"> Support Team</a>
                          on 0161&nbsp;266&nbsp;1797.
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="fullName" class="col-md-3 col-sm-4 control-label">Full Name</label>
                      <div class="col-md-9 col-sm-8">
                        <input type="text" formControlName="fullName" class="form-control" />
                      </div>
                    </div>

                    <div *ngIf="formErrors.fullName" class="alert alert-danger">
                      {{ formErrors.fullName }}
                    </div>

                    <div class="form-group">
                      <label for="jobTitle" class="col-md-3 col-sm-4 control-label">Job Title</label>
                      <div class="col-md-9 col-sm-8">
                        <input type="text" formControlName="jobTitle" class="form-control" />
                      </div>
                    </div>

                    <div *ngIf="formErrors.jobTitle" class="alert alert-danger">
                      {{ formErrors.jobTitle }}
                    </div>

                    <div class="form-group">
                      <label for="phoneNo" class="col-md-3 col-sm-4 control-label">Phone Number</label>
                      <div class="col-md-9 col-sm-8">
                        <input type="text" formControlName="phoneNo" class="form-control" />
                      </div>
                    </div>

                    <div *ngIf="formErrors.phoneNo" class="alert alert-danger">
                      {{ formErrors.phoneNo }}
                    </div>

                    <div>
                      <div class="row">
                        <div class="col-md-12">
                          <h4>Newsletter Preferences</h4>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="mailchimpOptIn" class="col-md-3 col-sm-4 control-label">Newsletter Updates</label>
                        <div class="col-md-9 col-sm-8">
                          <select formControlName="mailchimpOptIn" type="select" id="mailchimpOptIn" [(ngModel)]="newsletterUpdates"
                            [ngStyle]="{'border-color': newsletterUpdates ? '' : '#800020'}" class="form-control">
                            <option 
                            value="null" 
                            disabled>
                            Please select
                          </option>
                            <option value="N">
                              I don't want to receive updates by email
                            </option>
                            <option value="Y">
                              I want to receive updates by email
                            </option>
                          </select>
                          <div class="help-block">
                            Select 'I want to receive updates' above to receive monthly and quarterly bulletins from the NHS Benchmarking Network.
                          </div>
                        </div>
                      </div>

                      <div *ngIf="formErrors.mailchimpOptIn" class="alert alert-danger">
                        {{ formErrors.mailchimpOptIn }}
                      </div>

                      <div class="form-group">
                        <label for="notificationOptIn" class="col-md-3 col-sm-4 control-label">Notifications</label>
                        <div class="col-md-9 col-sm-8">
                          <select formControlName="notificationOptIn" type="select" id="notificationOptIn" [(ngModel)]="notificationUpdates"
                            class="form-control">
                            <option
                            value="null"  
                            disabled>
                            Please select
                          </option>
                            <option value="N" >
                              I don't want to receive notifications by email
                            </option>
                            <option value="Y">
                              I want to receive notifications by email
                            </option>
                          </select>
                          <div class="help-block">
                            Notifications are accessible from the navigation at the
                            top of this page at any time. To receive a daily digest of
                            your new notifications, select 'I want to receive
                            notifications' above.
                          </div>
                        </div>
                      </div>

                      <div *ngIf="formErrors.notificationOptIn" class="alert alert-danger">
                        {{ formErrors.notificationOptIn }}
                      </div>
                    </div>
                    <div id="userActions">
                      <hr>
                      <div style="margin-top:1em;margin-bottom:0.5em">
                        To continue, please click <strong>Confirm Profile Information</strong> even if no information has changed.
                      </div>
                      <button type="submit" class="btn btn-primary" [disabled]="progressBarWidthProfile || !newsletterUpdates || !notificationUpdates">
                        {{ progressBarWidthProfile ? 'Updated' : 'Confirm Profile Information' }}
                      </button>
                      <small class="help-block" style="color: red;" *ngIf="newsletterUpdates && notificationUpdates == null">Must select newsletter preferences</small>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- end Column -->
        </div>
      </div>
      <!-- Completion card -->
      <div class="col-md-3 stickyCard">
        <div class="col-md-12 view-card text-left" style="margin-bottom:1em;">
          <h3 *ngIf="progressBarWidth + progressBarWidthProfile != 100">Completion...</h3>
          <h3 *ngIf="progressBarWidth + progressBarWidthProfile == 100">Completion 100%</h3>
          <div class="progress">
            <div class="progress-bar" role="progressbar" [style.width.%]="progressBarWidth + progressBarWidthProfile"
              aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div *ngIf="progressBarWidth + progressBarWidthProfile != 100">
            <h4>Organisation: <i [class]="organisationsSelected ? 'fas fa-lg fa-check-circle' : 'far fa-times'"></i>
            </h4>
            <h4>Details: <i [class]="progressBarWidthProfile ? 'fas fa-lg fa-check-circle' : 'far fa-times'"></i></h4>
          </div>

          <div id="userActions" *ngIf="progressBarWidth + progressBarWidthProfile == 100">
            <button type="submit" class="btn btn-primary" (click)="submitAll()"
              [disabled]="progressBarWidth + progressBarWidthProfile != 100">
              Submit Updated Information
            </button>
            <h4 *ngIf="countdown">Redirected in <span id="seconds">{{ seconds }}</span></h4>
          </div>
        </div>
        <small class="text-muted log-out" (click)="logout()">
          <i class="fas fa-sign-out"></i>&nbsp;Log out and complete later
        </small>
      </div>
      <!-- end Completion card -->
      <!-- end Row -->
      <!-- end Container -->
    </div>
  </div>
</div>