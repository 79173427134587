<div>

  <table class="table" *ngIf="commissioningPartners.length > 0">
    <thead>
      <tr>
        <th class="col-md-8">Organisation Name</th>
        <th class="col-md-4"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of commissioningPartners">
        <td>{{c.organisationName}}</td>
        <td class="text-right">
          <button class="btn btn-xs btn-danger" (click)="submissionGroupFormRemove(c.organisationId)" *ngIf="registration.organisation.allowChanges && !registration.registration.registrationLocked">Remove</button>
        </td>
      </tr>
    </tbody>
  </table>

  <p *ngIf="commissioningPartners.length < 1 && registration.registration.registrationLocked" class="blank">
    No Submission Groups added.
  </p>

  <div *ngIf="!submissionGroupFormVisible">
    <button 
      type="button" 
      class="btn btn-primary button-margin" 
      *ngIf="registration.organisation.allowChanges && !registration.registration.registrationLocked" 
      (click)="submissionGroupFormShow()">
        Add to Submission Group
    </button>
  </div>

  <form *ngIf="submissionGroupFormVisible" [formGroup]="submissionGroupForm">
    <hr>
    <div class="form-group">
      <label>Organisation</label>

      <select class="form-control" formControlName="organisation" (change)="commissionerCheck($event.target.value)">
        <option [ngValue]="null" disabled>Please select an organisation...</option>
        <option *ngFor="let s of organisationGroupList" [ngValue]="s.organisationId">{{s.organisationName}}</option>
      </select>
    </div>

    <div *ngIf="submissionGroupForm.controls['organisation'].hasError('used')" class="alert alert-danger">
      <p>This organisation is already in the list.</p>
    </div>

    <button type="button" class="btn btn-success button-margin" [disabled]="!submissionGroupForm.valid" (click)="submissionGroupFormSubmit()">Save</button>
    <button type="button" class="btn btn-default button-margin" (click)="submissionGroupFormHide()">Cancel</button>

  </form>

</div>