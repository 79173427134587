import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcesComponent } from './resources.component';
import { ResourcesRoutingModule } from './resources-routing.module';
import { AccordionModule, CollapseModule } from 'ngx-bootstrap';
import { AuthGuardService } from 'app/utilities/services/auth-guard.service';
import { AuthService } from 'app/utilities/services/auth.service';
import { SafePipeModule } from 'app/utilities/pipes/safe-pipe.module';
import { ResponsiveModule } from 'ngx-responsive';
import { FormsModule } from '@angular/forms';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ResourcesRoutingModule,
    ResponsiveModule,
    CollapseModule,
    SafePipeModule,
    NgPipesModule,
    AccordionModule.forRoot(),
  ],
  declarations: [ResourcesComponent],
  providers: [AuthService, AuthGuardService],
})
export class ResourcesModule {}