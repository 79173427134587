<!-- SELECTOR BAR-->
<div class="container-fluid" id="selectorBar">
  <div class="row hidden-xs">
    <div class="col-sm-6">
      <span class="btn-back" (click)="navHome()">
        <i class="fa fa-caret-left"></i> Back to Home
      </span>
    </div>
    <div class="col-sm-6">
      <div class="row" *ngIf="organisationsLength > 1">
        <div class="col-lg-6 col-md-4 col-sm-2">
          <i
            class="far fa-lg fa-info-circle pull-right"
            tooltip="Your account allows you to view the details of multiple
          organisations"
          ></i>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-10">
          <div class="form-group">
            <select
              id="organisations"
              class="form-control"
              [ngModel]="currentOrganisation"
              (ngModelChange)="onOrgChange($event)"
            >
              <option
                *ngFor="let o of organisation"
                [ngValue]="o.organisationId"
              >
                {{ o.organisationName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="organisationsLength == 1">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <p *ngFor="let o of organisation; let i = index">
            <span class="one-organisation pull-right" *ngIf="i == 1">{{
              o.organisationName
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" id="containerMembership">
  <div class="row">
    <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2">
      <div class="row">
        <div class="col-md-12">
          <h1>Membership History</h1>
          <p>
            This page shows the projects undertaken by your organisation since
            <b>{{ projectYears[projectYears.length - 1] }}</b>
          </p>
          <p class="projects-loading" *ngIf="loaderService.isLoading | async">
            <span class="fa fa-spinner fa-spin"></span> Loading projects...
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="participation">
            <div *ngFor="let projectList of projectsList">
              <div class="col-md-12 form-card">
                <div class="projects-header">
                  <h3>{{ projectList.year }}</h3>
                  <p
                    class="projects-intro"
                    *ngIf="projectList.yearParticipants > 0"
                  >
                    Your organisation is participating in the following
                    {{ projectList.yearParticipants }} projects this year.
                  </p>
                  <p *ngIf="projectList.yearParticipants == 0">
                    <i
                      >You have not yet taken part in any projects this year.</i
                    >
                  </p>

                  <p *ngIf="projectList.year == currentYear">
                    The Membership Lead for your organisation is
                    <a href="mailto:{{ projectList.productLeadEmail }}">{{
                      projectList.productLeadName
                    }}</a
                    >.
                  </p>
                </div>

                <div
                  *ngFor="let p of participation[projectList.year]"
                  class="projects show-{{ p.hasParticipated }}"
                >
                  <div class="row project-row">
                    <div class="col-sm-1 col-xs-2 vcenter">
                      <img
                        alt="NHSBN {{ p.projectCategory }} logo"
                        src="../../assets/images/{{
                          p.projectCategory.split(' ').join('')
                        }}.png"
                      />
                    </div>

                    <div class="col-sm-11 col-xs-10 vcenter">
                      <h4>
                        {{ p.projectName }}<br />
                        <small
                          >Lead:

                          <span *ngIf="!p.projectLeadName">Not specified</span>
                          <a href="mailto:{{ p.projectLeadEmail }}">
                            {{ p.projectLeadName }}
                          </a>
                        </small>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
