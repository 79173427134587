import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';

import { ResetPasswordComponent } from './reset-password.component';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { ResetPasswordConfirmComponent } from './reset-password-confirm/reset-password-confirm.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ResetPasswordRoutingModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fa fa-spinner fa-spin"></span>',
      disableBtn: true, 
      btnLoadingClass: 'is-loading',
      handleCurrentBtnOnly: false
    }),
  ],
  declarations: [
    ResetPasswordComponent,
    ResetPasswordConfirmComponent
  ]
})
export class ResetPasswordModule {}