import { Injectable } from '@angular/core';

import { User } from '../classes/user/user'
import { Organisation } from '../classes/organisation/organisation'

@Injectable()
export class DataService {
  constructor() { }

  storage() {
    let sessionCheck: boolean = true;
    if (typeof sessionStorage === null) false; 

    return sessionCheck;
  }

  setUser(user: User) {
    sessionStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(sessionStorage.getItem("user"));
  }

  setOrganisation(organisation: Organisation) {
    sessionStorage.setItem("organisation", JSON.stringify(organisation));
  }

  getOrganisation() {    
    return JSON.parse(sessionStorage.getItem("organisation"));
  }

  setSelectedOrganisation(organisationId: number) {
    sessionStorage.setItem("organisationId", organisationId.toString());
  }

  getSelectedOrganisation() {
    return +sessionStorage.getItem("organisationId");
  }
}