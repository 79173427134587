import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemberService } from 'app/utilities/services/member.service';

@Component({
  selector: 'app-sidebar-section',
  templateUrl: './sidebar-section.component.html',
  styleUrls: ['./sidebar-section.component.scss']
})
export class SidebarSectionComponent implements OnInit {
  @Input() section: any;
  public organisationId: number

  constructor(
    private router: Router,
    private memberService: MemberService
  ) { }

  ngOnInit() {
    this.organisationId = JSON.parse(sessionStorage.getItem('organisationId'));
   }

   selectedEvent(eventId: number) {
    this.router.navigate(["/events"], { queryParams: { id: eventId }, queryParamsHandling: 'merge' });
   }

   getSelectedDocument(documentId: number) {
    this.memberService.AWSSelectedDocument(documentId, this.organisationId).subscribe(
      a => { window.open(a.data.documentUrl) }
    );
  }
}
