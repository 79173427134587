import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MemberService } from 'app/utilities/services/member.service';

@Component({
  selector: 'app-edit-tier',
  templateUrl: './edit-tier.component.html',
  styleUrls: ['./edit-tier.component.scss']
})
export class EditTierComponent implements OnChanges {

  @Input() reportDetails;
  @Input() editTierModal;
  @Input() tiersEditParent;

  editTierForm: FormGroup;

  errorMessage: string;

  constructor(private memberService: MemberService,) {
  }

  @Output() valueChange = new EventEmitter();

  ngOnChanges() {
    if (this.tiersEditParent) {
      this.buildEditTierForm(this.tiersEditParent);
    }
  }

  buildEditTierForm(tier: any) {
    this.editTierForm = new FormGroup({
      tierName: new FormControl(tier.name, [Validators.required]),
      isVisible: new FormControl(tier.isVisible, [Validators.required]),
    });
  }

  updateReport(editTierForm: any) {
    let tierId = this.tiersEditParent.id;
    let responses = editTierForm.value;
    this.valueChange.emit(responses);

    if (responses) {
      this.memberService.editTierName(tierId, responses).subscribe(
        success => {
          // Hide modal
          this.editTierModal.hide();
          sessionStorage.removeItem('savedTiers');
        },
        error => {
          console.log(error);
          this.errorMessage = 'There has been an error saving the tier changes. Please refresh the page and try again or contact the IT Team.'
        }
      )
    }
  }
}