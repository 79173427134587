import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Title, DOCUMENT, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Organisation } from 'app/utilities/classes/organisation/organisation';
import { User } from 'app/utilities/classes/user/user';
import { DataService } from 'app/utilities/services/data.service';
import { MemberService } from 'app/utilities/services/member.service';
import { WINDOW } from 'app/utilities/services/window.service';

@Component({ 
  templateUrl: './old-home.component.html',
  styleUrls: ['./old-home.component.scss']
})
export class OldHomeComponent implements OnInit {
  user: User = { "userId" : 0, "fullName" : "", "logonName" : "", "jobTitle" : "", "phoneNo" : "", "lastLogon" : new Date(), "roles": null, "welcomeDialogDismissed": "Y", "mailchimpOptIn": "", "notificationOptIn": "" };
  organisation: Organisation[];
  currentOrganisation: number;
  welcomeMessage: boolean = false;

  public navIsFixed: boolean = false;
  public width: number;

  metaTitle: string = 'Home | NHS Benchmarking Network';

  constructor(
    private memberService: MemberService,
    private dataService: DataService,
    private titleService: Title,
    private meta: Meta,
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window
  ) { }

  ngOnInit() {
    if(sessionStorage.getItem("homepage") == "classicView") {
      this.titleService.setTitle(this.metaTitle);
      this.meta.updateTag({property: 'og:title', content: this.metaTitle});
      this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
      this.getUserDetails();
      this.getOrganisationsByUser();
    } else {
      this.router.navigate(["/home"]);
    }
  }
  
  getUserDetails() {
    this.memberService.User().subscribe(
      u => { 
        this.user = u.data;
        this.dataService.setUser(u.data);

        if (this.user.welcomeDialogDismissed == 'N') {
          this.welcomeMessage = true;
        }        
      }
    );
  }

  getOrganisationsByUser() {
    let currentOrg = this.dataService.getSelectedOrganisation();

    this.memberService.OrganisationsByUser().subscribe(
      o => { 
        this.organisation = o.data,
        this.currentOrganisation = currentOrg ? currentOrg : this.organisation[0].organisationId,
        this.dataService.setOrganisation(o.data)
        this.dataService.setSelectedOrganisation(this.currentOrganisation);
      }
    );
  }

  onOrgChange(message: number) {
    this.dataService.setSelectedOrganisation(message);
    
    this.currentOrganisation = message;
  }

  classicView() {
    sessionStorage.removeItem("homepage");
    // sessionStorage.setItem("initialClassicView", "false")
  }

  dismissWelcomeMessage() {
    this.memberService.DismissWelcomeDialog({ welcomeDialogDismissed: 'Y' }).subscribe();

    this.welcomeMessage = false;
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    let width = document.getElementById('sidebar').clientWidth;
    if (number > 80) {
      this.navIsFixed = true;
      this.width = width - 65;
    } else if (this.navIsFixed && number < 10) {
      this.navIsFixed = false;
    }
  }

}
