import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserAdministrationComponent } from './user-administration.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const useradministrationRoutes: Routes = [
  { path: 'user-administration', component: UserAdministrationComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(useradministrationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserAdministrationRoutingModule { }