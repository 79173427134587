import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const dashboardRoutes: Routes = [
  // Bypass dashboard view for monthly-only projects
  { path: 'dashboard/29', redirectTo: 'project/29/dashboard', pathMatch: 'full' },
  { path: 'dashboard/41', redirectTo: 'project/41/dashboard', pathMatch: 'full' },
  { path: 'dashboard/42', redirectTo: 'project/42/dashboard', pathMatch: 'full' },
  { path: 'dashboard/52', redirectTo: 'project/52/dashboard', pathMatch: 'full' },
  // Standard path
  { path: 'dashboard/:projectId', component: DashboardComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(dashboardRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule { }