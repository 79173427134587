<!-- Navbar -->
<div *ngIf="!auth.authenticated()">
  <nav class="navbar navbar-default"  data-ng-hide="contentHide">
      <div class="container-fluid">
          <div class="navbar-header">
              <button type="button" class="navbar-toggle collapsed" (click)="isCollapsed = !isCollapsed" aria-expanded="false">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand" href="https://www.nhsbenchmarking.nhs.uk/" target="_blank"><img alt="NHSBN Logo" src="../assets/images/nhsbn-mark.png"></a>
          </div>
      
          <!-- Collect the nav links, forms, and other content for toggling -->
          <div class="collapse navbar-collapse" [collapse]=isCollapsed>
              <ul class="nav navbar-nav">
                  <li><a [routerLink]="['/home']" routerLinkActive="active">Home</a></li>
                  <li><a href="https://www.nhsbenchmarking.nhs.uk/contact" target="_blank">Contact</a></li>
              </ul>
              <ul class="nav navbar-nav navbar-right">
                  <li><a [routerLink]="['/help']" routerLinkActive="active">Help</a></li>
                  <li><a [routerLink]="['/landing']" routerLinkActive="active">Log in</a></li>
              </ul>
          </div><!-- /.navbar-collapse -->
      </div><!-- /.container-fluid -->
  </nav>
  <div class="colored-border"></div>
</div>
<!-- end Navbar -->

<!-- Content -->
<div class="container" id="containerError">
  <div class="row">
    <div class="col-md-12">
      <h1>Page Not Found</h1>
      <h3>The link you clicked may be broken or the page may have been removed.</h3>
      <p>Go to the <a href="/">home page</a> or <a href="https://www.nhsbenchmarking.nhs.uk/contact">contact us</a> about the problem.</p>
    </div>
  </div>
</div>
<!-- end Content -->

<!-- Footer -->
<div *ngIf="!auth.authenticated()">
  <footer id="footer">
      <div class="container-fluid">
          <div class="col-md-4">
              <img alt="NHSBN Logo" src="../assets/images/nhsbn-logo-clear.png">

              <p>
                  The Benchmarking Network is the in-house benchmarking service of the NHS. The Network is hosted by 
                  <a href="https://www.elft.nhs.uk/" target="_blank">East London Foundation Trust</a>.
              </p>

              <p>&copy; East London Foundation Trust {{date | date:'yyyy'}}</p>
          </div>
      </div>
  </footer>
</div>
<!-- end Footer -->