<!-- SELECTOR BAR-->
<div id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back" (click)="navHome()">
            <i class="fa fa-caret-left"></i> Back to Home
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-fluid" id="containerProfile">
  <div class="row">
    <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2">
      <div class="row">
        <div class="col-md-12">
          <h1>Edit Profile</h1>
          <p class="intro">
            Use this page to edit your profile and view your roles. If you need
            any support, please contact the
            <a [routerLink]="['/contact']" routerLinkActive="active"> Support Team</a>.
          </p>
        </div>
      </div>
      <p class="projects-loading" *ngIf="!user">
        <span class="fa fa-spinner fa-spin"></span> Loading user profile...
      </p>
      <div class="row" *ngIf="user">
        <div class="col-md-12">
          <div class="col-md-12 view-card">
            <h3>My Details</h3>
            <p class="intro">
              If you find that the below details are incorrect, please change
              them and click 'Update' once finished.
            </p>

            <form [formGroup]="myProfile" (ngSubmit)="updateUser()" class="form-horizontal">
              <div class="form-group">
                <label for="logonName" class="col-md-3 col-sm-4 control-label">Email Address</label>
                <div class="col-md-9 col-sm-8">
                  <input type="email" formControlName="logonName" class="form-control" />
                  <div class="alert alert-info">
                    If you need to change your email address, please contact the
                    <a href="mailto:enquiries@nhsbenchmarking.nhs.uk"> Support Team</a>
                    on 0161&nbsp;266&nbsp;1797.
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="fullName" class="col-md-3 col-sm-4 control-label">Full Name</label>
                <div class="col-md-9 col-sm-8">
                  <input type="text" formControlName="fullName" class="form-control" />
                </div>
              </div>

              <div *ngIf="formErrors.fullName" class="alert alert-danger">
                {{ formErrors.fullName }}
              </div>

              <div class="form-group">
                <label for="jobTitle" class="col-md-3 col-sm-4 control-label">Job Title</label>
                <div class="col-md-9 col-sm-8">
                  <input type="text" formControlName="jobTitle" class="form-control" />
                </div>
              </div>

              <div *ngIf="formErrors.jobTitle" class="alert alert-danger">
                {{ formErrors.jobTitle }}
              </div>

              <div class="form-group">
                <label for="phoneNo" class="col-md-3 col-sm-4 control-label">Phone Number</label>
                <div class="col-md-9 col-sm-8">
                  <input type="text" formControlName="phoneNo" class="form-control" />
                </div>
              </div>

              <div *ngIf="formErrors.phoneNo" class="alert alert-danger">
                {{ formErrors.phoneNo }}
              </div>

              <div>
                <div class="row">
                  <div class="col-md-12">
                    <h4>Newsletter Preferences</h4>
                  </div>
                </div>

                <div class="form-group">
                  <label for="mailchimpOptIn" class="col-md-3 col-sm-4 control-label">Newsletter Updates</label>
                  <div class="col-md-9 col-sm-8">
                    <select formControlName="mailchimpOptIn" type="select" id="mailchimpOptIn" class="form-control">
                      <option value="N">
                        I don't want to receive updates by email
                      </option>
                      <option value="Y">
                        I want to receive updates by email
                      </option>
                    </select>
                    <div class="help-block">
                      Select 'I want to receive updates' above to receive monthly and quarterly bulletins from the NHS Benchmarking Network.
                    </div>
                  </div>
                </div>

                <div *ngIf="formErrors.mailchimpOptIn" class="alert alert-danger">
                  {{ formErrors.mailchimpOptIn }}
                </div>

                <div class="form-group">
                  <label for="notificationOptIn" class="col-md-3 col-sm-4 control-label">Notifications</label>
                  <div class="col-md-9 col-sm-8">
                    <select formControlName="notificationOptIn" type="select" id="notificationOptIn"
                      class="form-control">
                      <option value="N">
                        I don't want to receive notifications by email
                      </option>
                      <option value="Y">
                        I want to receive notifications by email
                      </option>
                    </select>
                    <div class="help-block">
                      Notifications are accessible from the navigation at the
                      top of this page at any time. To receive a daily digest of
                      your new notifications, select 'I want to receive
                      notifications' above.
                    </div>
                  </div>
                </div>

                <div *ngIf="formErrors.notificationOptIn" class="alert alert-danger">
                  {{ formErrors.notificationOptIn }}
                </div>
              </div>

              <div id="userActions" class="pull-right">
                <button type="submit" class="btn btn-success" [promiseBtn]="profileSpinner"
                  [disabled]="!myProfile.valid || !myProfile.dirty">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 view-card">
            <h3>Change Password</h3>
            <p class="intro">
              In order to change your password, please enter a new password
              twice below. The password must be eight or more characters in
              length, and should contain at least one uppercase letter, one
              lowercase letter and one number.
            </p>

            <form [formGroup]="myPassword" (ngSubmit)="updatePassword()" class="form-horizontal">
              <div class="form-group">
                <label for="newPassword" class="col-md-3 col-sm-4 control-label">New Password&nbsp;*</label>
                <div class="col-md-9 col-sm-8">
                  <input type="text" name="newPassword" formControlName="password" class="form-control"
                    type="password" />
                </div>
              </div>

              <div *ngIf="formErrors.password" class="alert alert-danger">
                {{ formErrors.password }}
              </div>

              <div class="form-group">
                <label for="confirmPassword" class="col-md-3 col-sm-4 control-label">Confirm New Password&nbsp;*</label>
                <div class="col-md-9 col-sm-8">
                  <input type="text" name="confirmPassword" formControlName="confirmPassword" class="form-control"
                    type="password" />
                </div>
              </div>

              <div *ngIf="formErrors.confirmPassword" class="alert alert-danger">
                {{ formErrors.confirmPassword }}
              </div>

              <div class="pull-right">
                <span class="text-muted explainer" *ngIf="
                    myPassword.controls.password.value !=
                    myPassword.controls.confirmPassword.value
                  ">
                  The two passwords above must match
                </span>
                <button type="submit" [disabled]="
                    !myPassword.valid ||
                    !myPassword.dirty ||
                    myPassword.controls.password.value !=
                      myPassword.controls.confirmPassword.value
                  " class="btn btn-success" [promiseBtn]="passwordSpinner">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 view-card">
            <h3>My Roles</h3>
            <p class="intro">
              Your roles are listed below. If you have any questions about your
              roles or would like to make any amendments, please contact the
              <a href="mailto:enquiries@nhsbenchmarking.nhs.uk"> Support Team</a>
              on 0161&nbsp;266&nbsp;1797.
            </p>
            <div *ngIf="user" id="roles">
              <ng-template ngFor let-roles [ngForOf]="htmlService.keys(user.roles)">
                <div class="row user">
                  <div class="col-md-4">
                    <p class="role-title">{{ roles }}</p>
                  </div>
                  <div class="col-md-8">
                    <ng-template ngFor let-role [ngForOf]="user.roles[roles]">
                      <ul>
                        <li>
                          {{ role.roleName }} ({{ role.productName }})
                          <span *ngIf="role.projectName">for {{ role.projectName }}</span>
                        </li>
                      </ul>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Column -->
  </div>
  <!-- end Row -->
</div>
<!-- end Container -->