import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EventsComponent } from './events.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const eventsRoutes: Routes = [
  { path: 'events', component: EventsComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(eventsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class EventsRoutingModule { }