import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ResponsiveModule } from 'ngx-responsive';

import { CollectionComponent } from './collection.component';
import { CollectionRoutingModule } from './collection-routing.module';

import { Ng2HighchartsModule } from 'ng2-highcharts';
import { NgPipesModule, SumPipe, MakePluralStringPipe } from 'ngx-pipes';
import { SafePipeModule } from '../utilities/pipes/safe-pipe.module';
import { ModalModule } from "ngx-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    CollectionRoutingModule,
    Ng2HighchartsModule,
    NgPipesModule,
    SafePipeModule,
    ReactiveFormsModule,
    ResponsiveModule,
    ModalModule
  ],
  declarations: [
    CollectionComponent
  ],
  providers: [
    SumPipe,
    MakePluralStringPipe
  ]
})
export class CollectionModule {}