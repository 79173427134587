import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService) { }

  public authenticated() {
    return !this.jwtHelper.isTokenExpired();
  }

  public confirmed() {
    let token = localStorage.getItem("id_token");
    const decodedToken = this.jwtHelper.decodeToken(token);
    return decodedToken.reviewUserDetails == false;
  }
}