<div [id]="projectView" class="tile" (click)="selectMetric.emit(metric.tier)">
    <div class="row">
        <div class="open-report">
            Open full report <i class="far fa-arrow-right"></i>
        </div>
        <div class="col-sm-7">
            <h4>
                {{ metric.reportName }}&nbsp;
                <!-- <span class="button link" *ngIf="metric.tier" (click)="selectMetric.emit(metric.tier)">
                    Open full report <i class="fas fa-caret-right"></i>
                </span> -->
            </h4>
            <div class="row">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-xs-6 value">
                            <span *ngIf="metric.latestMonth.cohortAverage !== null">
                                {{ metric.latestMonth.cohortAverage > 100 ? (metric.latestMonth.cohortAverage | number: '1.0-0') : (metric.latestMonth.cohortAverage | number: metric.decimalPlaces) }}<ng-container *ngIf="metric.formatModifier === 'P'">%</ng-container>
                            </span>
                            <span *ngIf="metric.latestMonth.cohortAverage === null">-</span>
                            <p>
                                <i [ngStyle]="colorBlindnessEnabled ? {'color': nationalMean} : {'color': '#009639'}" class="fas fa-circle fa-xs fa-green"></i>
                                {{ projectId == 52 ? 'Regional' : 'National' }} mean
                            </p>
                        </div>
                        <div class="col-xs-6 value" *ngIf="showSubmission">
                            <span *ngIf="metric.latestMonth.submissionValue !== null">
                                {{ metric.latestMonth.submissionValue > 100 ? (metric.latestMonth.submissionValue | number: '1.0-0') : (metric.latestMonth.submissionValue | number: metric.decimalPlaces) }}<ng-container *ngIf="metric.formatModifier === 'P'">%</ng-container>
                            </span>
                            <span *ngIf="metric.latestMonth.submissionValue === null">-</span>
                            <p>
                                <i [ngStyle]="colorBlindnessEnabled ? {'color': submissionValue} : {'color': '#DA291C'}" class="fas fa-diamond fa-xs fa-red"></i>
                                Submission value
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-5">
            <div class="sparkline-container">
                <div class="sparkline" [ng2-highcharts]="sparklineOptions"></div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="projectId == 29">
        <div class="col-sm-12">
            <div class="secondary-info">
                <div>
                    Benchmark: <strong>{{ metric.benchmark }}<ng-container *ngIf="metric.formatModifier === 'P'">%</ng-container></strong>
                </div> 
                <div>
                    6-month average: <strong>{{ metric.average6 }}<ng-container *ngIf="metric.formatModifier === 'P'">%</ng-container></strong>
                </div>
                <div>
                    Completion rate: <strong>{{ metric.percentageCompletion ? metric.percentageCompletion : '-' }}%</strong>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="projectId == 52 && metric.nationalAverage">
        <div class="col-sm-12">
            <div class="secondary-info">
                <div>
                    National average: <strong>{{ metric.nationalAverage | number: '1.0-0' }}<ng-container *ngIf="metric.formatModifier === 'P'">%</ng-container></strong>
                </div> 
            </div>
        </div>
    </div>
</div>