<!-- View toggle -->
<div class="container-fluid" *ngIf="project">
  <div class="p-relative">
    <!-- Error -->
    <div *ngIf="errorMessage" class="alert alert-danger alert-fixed" [innerHTML]="errorMessage"></div>
    <!-- Loading -->
    <div *ngIf="loadingMessage && !errorMessage" class="alert alert-info alert-fixed" [innerHTML]="loadingMessage"></div>
  </div>
  <div class="row mb-4">
    <div class="col-md-12">
      <h5 style="margin-top:0;margin-bottom:1em">
        Validation for {{ project.projectName }} {{ selectedYear }}
      </h5>
      <ng-container *ngIf="selectedProjectId">
        <div class="btn-group mr-2" role="group">
          <button type="button" class="btn" [class.btn-primary]="showListView" [class.btn-secondary]="!showListView" (click)="switchView(true)">List view</button>
          <button type="button" class="btn" [class.btn-primary]="!showListView" [class.btn-secondary]="showListView" (click)="switchView(false)">Tier view</button>
        </div>
        <ng-container *ngIf="showListView">
          <button role="button" class="btn btn-info mr-2" (click)="refreshValidations()">Clear Filters</button>
          <app-csv-download [result]="project.validations" [csvSettings]="csvSettings"></app-csv-download>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<!-- LIST VIEW -->
<div class="container-fluid" *ngIf="project?.validations && showListView">
  <div class="row">
    <div class="col-md-12">

      <!-- Search and filter -->
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label for="validationSearch">Search</label>
            <input type="text" class="form-control" placeholder="Search all columns..." [(ngModel)]="validationSearch" id="validationSearch">
          </div>
        </div>
        <ng-container *ngFor="let filter of project.filters"> 
          <div class="col-md-2">
            <div class="form-group">
              <label [for]="filter.name">{{ filter.name }}</label>
              <select class="form-control form-control-sm" [id]="filter.key" [(ngModel)]="filter.selected" (change)="filterByColumn(filter.key, $event.target.value)">
                <option [value]="null" selected>Select {{ filter.name }}...</option>
                <option *ngFor="let opt of filter.options" [value]="opt">{{ opt }}</option>
              </select>
            </div>
          </div>
        </ng-container>
        <div class="col-md-6">
          <label>Missing</label><br>
          <span class="btn btn-default mr-2 mb-4" (click)="filterByColumn('questionId', null)">No questions</span>
          <span class="btn btn-default mr-2 mb-4" (click)="filterByColumn('validationDescription', '')">No external notes</span>
          <span class="btn btn-default mr-2 mb-4" (click)="filterByColumn('notes', '')">No internal notes</span>
        </div>
      </div>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th style="width:8%">Status</th>
            <th style="width:20%">Report Name (and external note)</th>
            <th style="width:20%; cursor: pointer;" (click)="sortSubmissions()">Submission (and Project Lead)
              <i [class]="tableFilter == 'submissionName' ? 'fas fa-sort-alpha-down' : 'fas fa-sort-alpha-down grayed-out'"></i>
            </th>
            <th style="width:8%">Service</th>
            <th style="width:20%">Question (and internal note)</th>
            <th style="width:8%">Response</th>
            <th style="width:8%">Assignee</th>
            <th style="width:8%;text-align:center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="!validationSearch && !project?.validations.length">
            <tr>
              <td colspan="8" class="text-center">
                <i>There are no validations for this project.</i>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="validationSearch && (project?.validations | filterBy: searchableColumns: validationSearch).length == 0">
            <tr>
              <td colspan="8" class="text-center">
                <i>There are no validations matching this search.</i>
              </td>
            </tr>
          </ng-container>
          <tr *ngFor="let vld of project?.validations | filterBy: searchableColumns: validationSearch | orderBy: tableFilter">
            <td>
              <span [class]="'status' + ' -' + vld.status">
                {{ vld.status }}
              </span>
            </td>
            <td>
              <span class="text-primary c-pointer" (click)="getTier(vld.tier, vld.optionId)">
                {{ vld.tierId }}: {{ vld.reportName }}
              </span>
              <small class="d-block text-muted">{{ vld.validationDescription || 'No description added' }}</small>
            </td>
            <td>
              {{ vld.submissionCode }} ({{ vld.submissionId }}): {{ vld.submissionName || '-' }}
              <small class="d-block text-muted">Project Lead: {{ vld.projectLeadName || '-' }}</small>
            </td>
            <td>
              {{ vld.serviceItemName || '-' }}
            </td>
            <td>
              <span class="text-primary c-pointer" (click)="dataCollectionLink(vld)">
                {{ vld.questionId }}: {{ vld.displayQuestionText || '-' }}
              </span>
              <small class="d-block text-muted">{{ vld.notes || 'No note added' }}</small>
            </td>
            <td>
              {{ vld.response || '-' }}
            </td>
            <td>
              {{ vld.validatorName }}
            </td>
            <td style="text-align:center">
              <i class="fas c-pointer mr-2" tooltip="Toggle status: validated/pending"
                [class.text-muted]="vld.status == 'validated'" [class.fa-times-square]="vld.status == 'validated'"
                [class.text-success]="vld.status != 'validated'" [class.fa-check-square]="vld.status != 'validated'"
                (click)="editValidationStatus(vld.validationId, vld.status == 'validated' ? 'pending' : 'validated')"
              ></i>
              <i class="fas fa-minus-square text-danger mr-2 c-pointer" (click)="deleteValidation(vld.validationId)" tooltip="Delete validation"></i>
              <i class="fas fa-pen-square text-primary c-pointer" (click)="editValidation(vld, false)" tooltip="Edit validation"></i>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>

<!-- TIER VIEW -->
<div class="container-fluid" *ngIf="project?.validations && !showListView">
  <!-- Content -->
  <div class="row">
    <div class="col-md-4">
      <ng-container *ngIf="project?.tiers.length && project?.validations">
        <!-- Tier tree -->
        <div class="sidebar">
          <!-- Search and results -->
          <div class="position-relative">
            <input type="text" class="form-control mb-2" [(ngModel)]="tierSearch" placeholder="Search by tier ID or name...">
            <div class="mt-2 text-muted" *ngIf="(searchableTiers | filterBy: ['id', 'name']: tierSearch).length == 0">
              There are no tiers matching the search.
            </div>
            <div class="tier-search-results" *ngIf="tierSearch">
              <div *ngFor="let tier of searchableTiers | filterBy: ['id', 'name']: tierSearch" (click)="getSearchTier(tier, project.tiers)" class="mb-2 c-pointer searchable-tier">
                {{ tier.name }}
                <small *ngIf="tier.serviceItemName" class="text-muted"><br>{{ tier.serviceItemName }}</small>
              </div>
            </div>
          </div>
          <!-- Flat tree -->
          <ng-container *ngIf="!tierSearch">
            <div *ngFor="let tier of project.tiers" [class]="selectedTier?.id == tier.id ? 'tier active' : 'tier'" (click)="tier.reportId ? getTier(tier) : toggleTierCollapsed(tier)">
              <ng-container *ngIf="!tier.collapsed">
                <div [id]="tier.id" [innerHtml]="tier.displayName"></div>
                <span *ngIf="tier.pendingValidationsCount" class="badge badge-success ml-2">{{ tier.pendingValidationsCount }}</span>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="col-md-8">
      <ng-container *ngIf="selectedTier">
        <!-- Heading -->
        <h3 style="margin-top:0">{{ selectedTier.report.reportName }}</h3>
        <small>
          Reference (links to outputs): <a [href]="'../outputs/' + selectedProjectId + '?tier=' + selectedTier.id" target="_blank">{{ selectedTier.id }}-{{ selectedTier.reportId }}</a> | 
          Type: {{ selectedTier.report.viewTypes[0].viewType }}
        </small>
        <hr>
        <ng-container *ngIf="!errorMessage">
          <!-- Option -->
          <div class="row form-group">
            <div class="col-md-4">
              <label for="option">Options</label>
              <select name="option" id="option" [(ngModel)]="selectedOptionId" (change)="getTier(selectedTier, $event.target.value, selectedPeerGroupId)" class="form-control" [disabled]="selectedTier.report.options.denominators.length < 2">
                <option [value]="null" selected disabled>Select an option...</option>
                <option *ngFor="let opt of selectedTier.report.options.denominators" [value]="opt.optionId">{{ opt.optionName }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="peer">Peer Group</label>
              <select name="peer" id="peer" [(ngModel)]="selectedPeerGroupId" (change)="getTier(selectedTier, selectedOptionId, $event.target.value)" class="form-control" [disabled]="selectedTier.report.peerGroups.length < 2">
                <option [value]="null" selected disabled>Select a peer group...</option>
                <option *ngFor="let opt of selectedTier.report.peerGroups" [value]="opt.id">{{ opt.name }}</option>
              </select>
            </div>
          </div>
          <!-- Chart -->
          <div [ng2-highcharts]="chartObject" (click)="chartClick($event)"></div>
          <!-- Averages -->
          <div>
            <span class="mr-2">
              <i class="fas fa-horizontal-rule mr-2" style="color:#009639"></i>Mean: {{ mean | number: '1.2-2' }}
            </span>
            <span class="mr-2">
              <i class="fas fa-horizontal-rule mr-2" style="color:#78BE20"></i>Median: {{ median | number: '1.2-2' }}
            </span>
          </div>
          <hr>
          <!-- Table -->
          <h4 style="font-weight: bold">Validation Points</h4>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style="width:8%">Status</th>
                <th style="width:20%">Report Name (and external note)</th>
                <th style="width:20%">Submission (and Project Lead)</th>
                <th style="width:8%">Service</th>
                <th style="width:20%">Question (and internal note)</th>
                <th style="width:8%">Response</th>
                <th style="width:8%">Assignee</th>
                <th style="width:8%;text-align:center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="!selectedTier?.validations.length">
                <tr>
                  <td colspan="8" class="text-center">
                    <i>There are no validations for this project.</i>
                  </td>
                </tr>
              </ng-container>
              <tr *ngFor="let vld of selectedTier?.validations | orderBy: '-validationId'">
                <td>
                  <span [class]="'status' + ' -' + vld.status">
                    {{ vld.status }}
                  </span>
                </td>
                <td>
                  <span class="text-primary c-pointer" (click)="getTier(vld.tier, vld.optionId)">
                    {{ vld.tierId }}: {{ vld.reportName }}
                  </span>
                  <small class="d-block text-muted">{{ vld.validationDescription || 'No description added' }}</small>
                </td>
                <td>
                  {{ vld.submissionCode }} ({{ vld.submissionId }}): {{ vld.submissionName || '-' }}
                  <small class="d-block text-muted">Project Lead: {{ vld.projectLeadName || '-' }}</small>
                </td>
                <td>
                  {{ vld.serviceItemName || '-' }}
                </td>
                <td>
                  <span class="text-primary c-pointer" (click)="dataCollectionLink(vld)">
                    {{ vld.questionId }}: {{ vld.displayQuestionText }}
                  </span>
                  <small class="d-block text-muted">{{ vld.notes || 'No note added' }}</small>
                </td>
                <td>
                  {{ vld.response }}
                </td>
                <td>
                  {{ vld.validatorName }}
                </td>
                <td style="text-align:center">
                  <i class="fas c-pointer mr-2" tooltip="Toggle status: validated/pending"
                    [class.text-muted]="vld.status == 'validated'" [class.fa-times-square]="vld.status == 'validated'"
                    [class.text-success]="vld.status != 'validated'" [class.fa-check-square]="vld.status != 'validated'"
                    (click)="editValidationStatus(vld.validationId, vld.status == 'validated' ? 'pending' : 'validated')"
                  ></i>
                  <i class="fas fa-minus-square text-danger mr-2 c-pointer" (click)="deleteValidation(vld.validationId)" tooltip="Delete validation"></i>
                  <i class="fas fa-pen-square text-primary c-pointer" (click)="editValidation(vld, false)" tooltip="Edit validation"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
      
    </div>
  </div>
</div>

<!-- SINGLE SLIDER -->
<ng-container *ngIf="selectedValidation">
  <div id="sliderBg" (click)="selectedValidation = null"></div>
  <div id="slider">
    <!-- Header -->
    <h2>
      Edit Validation<br>
      <small>Submission Code: {{ selectedValidation.submissionCode }}</small>
    </h2>
    <hr>
    <!-- Responses -->
    <ng-container *ngIf="availableResponses">
      <div class="form-group mt-2">
        <label>Link validation point to a response</label>
        <div class="help-block">Select a response from the parameters below.</div>
      </div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th style="width:50%">Question ID-Part and Text</th>
            <th style="width:15%">Type</th>
            <th style="width:20%">Response</th>
            <th style="width:15%"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let resp of availableResponses">
            <td>
              <div [innerHTML]="resp.displayQuestionText"></div>
              <div class="text-muted">{{ resp.questionId }}-{{ resp.questionPart }}</div>
            </td>
            <td>
              {{ resp.parameterType }}
              <i class="fas fa-question-circle text-muted" [tooltip]="resp.parameterDesc"></i>
            </td>
            <td>{{ resp.response }}</td>
            <td class="text-center">
              <ng-container *ngIf="!checkResponsePosted()">
                <button class="btn btn-sm btn-success" (click)="setResponse(resp, selectedValidation)" [disabled]="checkResponsePosted()">Set</button>
              </ng-container>
              <ng-container *ngIf="checkResponsePosted() && resp.set">
                <i class="fas fa-check-circle text-success"></i>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <!-- Description Form -->
    <ng-container *ngIf="validationDescriptionForm">
      <form [formGroup]="validationDescriptionForm" (submit)="editValidationDescription(selectedValidation)">
        <div class="form-group">
          <label for="validationDescription">External note:</label>
          <div class="help-block">This is an external note, visible to users when validation points are shared.</div>
          <input type="text" class="form-control" formControlName="validationDescription">
        </div>
        <button type="submit" class="btn btn-sm btn-success mr-2" [disabled]="validationDescriptionForm.pristine">Save</button>
        <span class="ml-2 text-success" *ngIf="descriptionPosted">
          <i class="far fa-check-circle mr-1"></i>External note saved
        </span>
      </form>
    </ng-container>
    <!-- Note Form -->
    <ng-container *ngIf="validationNoteForm">
      <form [formGroup]="validationNoteForm" (submit)="editValidationNote(false)">
        <div class="form-group">
          <input type="hidden" formControlName="validationResponseId">
          <label for="note">Internal note:</label>
          <div class="help-block">This is an internal note, only visible to admins and not users.</div>
          <input type="text" class="form-control" formControlName="notes">
        </div>
        <button type="submit" class="btn btn-sm btn-success mr-2" [disabled]="validationNoteForm.pristine">Save</button>
        <span class="ml-2 text-success" *ngIf="notePosted">
          <i class="far fa-check-circle mr-1"></i>Internal note saved
        </span>
      </form>
    </ng-container>
    <hr>
    <button class="btn btn-default mr-2" (click)="selectedValidation = null">Close Slider</button>
    <button class="btn btn-danger" (click)="deleteValidation(selectedValidation.validationId)">Delete Validation</button>
  </div>
</ng-container>