import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})

export class SectionComponent implements OnChanges {
  
  @Input() section;
  @Input() index;
  @Input() projectId;
  @Input() selectedProjectDate;

  charts = [
    {
      reportName: null,
      benchmark: null,
      dataTable: null,
      primaryChartOptions: null,
      primaryChart: null,
      secondaryChartOptions: null,
      secondaryChart: null
    },
    {
      reportName: null,
      benchmark: null,
      dataTable: null,
      primaryChartOptions: null,
      primaryChart: null,
      secondaryChartOptions: null,
      secondaryChart: null
    }
  ]

  copyrightYear: number = new Date().getFullYear();

  constructor() {}

  ngOnChanges() {
    this.charts.forEach((c, i) => {
      if (this.section[i] && this.section[i].reportName) {
        c.reportName = this.section[i].reportName,
        c.benchmark = this.section[i].benchmark,
        c.dataTable = this.section[i].dataTable,
        c.primaryChartOptions = this.createBarChart(this.section[i].dataBarChart);
        c.secondaryChartOptions = this.createLineChart(this.section[i].dataLineChart, this.projectId === 29 ? this.section[i].benchmark : null);
      }
    });
    this.index = this.index + 4;
  }

  createBarChart(data) {

    let values = data.map(d => d.y);
    let count = values.length
    let average = values.reduce((a,b) => a + b, 0) / count;

    return {
      chart: {
        type: 'column',
        height: 220,
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent',
        // events: { 
        //   load: (e => {
        //     this.primaryChart = e.target;
        //   })
        // },
      },
      colors: ['#003087', '#005EB8', '#0072CE', '#41B6E6', '#00A9CE', '#231f20', '#425563', '#768692'],
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.key}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;">{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' }
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: true,
        gridLineColor: '#EEF1F8'
      },
      yAxis: {
        // min: this.reportSettings.min ? this.reportSettings.min : null,
        // max: this.reportSettings.max ? this.reportSettings.max : null,
        // tickInterval: this.reportSettings.tickInterval ? this.reportSettings.tickInterval : null,
        gridLineColor: '#EEF1F8',
        title: {
          text: null // Report name
        },
        plotLines: [{
          color: '#009639',
          value: average ? average : null,
          width: '2',
          zIndex: 10
        }],
      },
      plotOptions: {
        series: {
          animation: false
        }
      },
      series: [
        {
          data: data,
          color: '#005EB8'
        }
      ]
    };
  }

  createLineChart(data, benchmark?) {
    return {
      chart: {
        type: 'line',
        height: 150,
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent',
        // events: { 
        //   load: (e => {
        //     this.secondaryChart = e.target;
        //   })
        // },
      },
      colors: ['#003087', '#005EB8', '#0072CE', '#41B6E6', '#00A9CE', '#231f20', '#425563', '#768692'],
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.key}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:0.25em"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' }
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          animation: false
        }
      },
      xAxis: {
        categories: true,
        crosshair: true
      },
      yAxis: {
        // min: this.reportSettings.min ? this.reportSettings.min : null,
        // max: this.reportSettings.max ? this.reportSettings.max : null,
        // tickInterval: this.reportSettings.tickInterval ? this.reportSettings.tickInterval : null,
        gridLineColor: '#EEF1F8',
        allowDecimals: false,
        title: {
          text: ''
        },
        plotLines: [{
          color: '#768692',
          value: benchmark ? benchmark : null,
          width: '2',
          zIndex: 5
        }],
      },
      series: data
    };
  }

}