import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { IcsService } from 'app/utilities/services/ics.service';
import { MemberService } from '../../utilities/services/member.service';

@Component({
  selector: 'app-pr-submission',
  templateUrl: './pr-submission.component.html',
  styleUrls: ['./pr-submission.component.scss']
})
export class PrSubmissionComponent implements OnChanges {
  @Input() projectDetails;
  @Input() registration;
  @Input() projectId;
  @Input() guidanceSubmissionAdd: string;
  @Input() icsProject: boolean;
  @Output() submissionAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() submissionEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() submissionDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() submissionLeadAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() submissionLeadDelete: EventEmitter<any> = new EventEmitter<any>();

  currentSubmissionId: number;
  peerGroups: boolean;
  missingPeerGroups: boolean = false;
  submissionForm: FormGroup; showSubmissionFormAdd: boolean = true;
  submissionLeadForm: FormGroup;
  submissionState = { sfView: false, slfView: false };
  checkSubmissionName: number = 1;
  maxSubmissions: boolean;
  submissionList: any;

  constructor(
    private memberService: MemberService,
    private fb: FormBuilder,
    private icsService: IcsService
  ) { }

  ngOnChanges() {
    if (this.registration) {
      if (this.registration.registration.registrationType.displaySubmissionTypes == 'Y') {
        this.peerGroups = true;
        if (this.registration.registration.submission.submissionList.map(s => s.peerGroupId).includes(null)) {
          this.missingPeerGroups = true;
        };
      } else {
        this.peerGroups = false;
      }
      if (this.registration.registration.registrationType.maxSubmissions && this.registration.registration.submission.submissionList.length >= this.registration.registration.registrationType.maxSubmissions) {
        this.maxSubmissions = true;
      } else {
        this.maxSubmissions = false;
      }
      this.submissionList = this.registration.registration.submission.submissionList;
    }
  }

  buildSubmissionForm() {
    if (this.peerGroups) {
      this.submissionForm = this.fb.group({
        submissionName: ['', [Validators.required, Validators.maxLength(150)]],
        peerGroupId: [null, [Validators.required]]
      });
    }
    else {
      this.submissionForm = this.fb.group({
        submissionName: ['', [Validators.required, Validators.maxLength(150)]]        
      });
    }

    this.submissionForm.valueChanges.subscribe(
      data => this.onSubmissionValueChanged(data)
    );

    this.onSubmissionValueChanged();
  }

  buildSubmissionLeadForm() {
    this.submissionLeadForm = this.fb.group({
      logonName: ['', [Validators.required, Validators.maxLength(200), <any>Validators.email]],
      fullName: ['', [Validators.required, Validators.maxLength(150)]]
    });

    this.submissionLeadForm.valueChanges.subscribe(
      data => this.onSubmissionLeadValueChanged(data)
    );

    this.onSubmissionLeadValueChanged();
  }

  showAddSubmission(checkSubmissionName) {
    this.checkSubmissionName = checkSubmissionName;
    this.currentSubmissionId = undefined;

    this.buildSubmissionForm();

    this.setSubmissionState(1, 1);
  }

  submissionFormSubmit() {
    if (this.submissionCheck(this.submissionForm.value.submissionName)) {
      return;
    }    

    let submissionData = { 
      submissionId: this.currentSubmissionId, 
      submissionName: 
      this.submissionForm.value.submissionName.trim(),
      peerGroupId: this.submissionForm.value.peerGroupId
    }

    if (this.currentSubmissionId) {
      this.editSubmission(this.registration.registrationId, submissionData);
    }
    else {
      this.addSubmission(this.registration.registrationId, submissionData);
    }

    this.setSubmissionState(1, 2);

    this.buildSubmissionForm();
  }

  submissionFormCancel() {
    this.currentSubmissionId = undefined;

    this.setSubmissionState(1, 2);
  }

  submissionChangeCheck(submissionId: number, checkSubmissionName: number) {
    if (this.registration.registration.submission.submissionList.filter(s => s.submissionId == submissionId)[0].responseCount > 0) {
      if (confirm("Data collection has already started for this submission. Are you sure you wish to continue to change the submission details?")) {
        this.submissionChange(submissionId, checkSubmissionName);
      }
    }
    else {
      this.submissionChange(submissionId, checkSubmissionName);
    }
  }

  submissionChange(submissionId, checkSubmissionName) {

    this.checkSubmissionName = checkSubmissionName;
    this.buildSubmissionForm();

    this.currentSubmissionId = submissionId;

    let submission = this.registration.registration.submission.submissionList.filter(s => s.submissionId == submissionId)[0];

    if (this.peerGroups) {
      this.submissionForm.setValue({
        submissionName: submission.submissionName.trim(),
        peerGroupId: submission.peerGroupId || null
      });
    }
    else {
      this.submissionForm.setValue({
        submissionName: submission.submissionName
      });
    }

    this.setSubmissionState(1, 1);
  }

  submissionRemove(submissionId: number) {
    if (confirm("Are you sure you want to remove this submission?")) {
      this.currentSubmissionId = undefined;

      if (this.icsProject) {
        this.removeIcsSubmission(this.registration.registration.registrationId, submissionId)
      } else {
        this.removeSubmission(this.registration.registration.registrationId, submissionId);
      }
    }
  }

  addSubmission(registrationId: number, submissionData) {
    this.memberService.AddSubmission(registrationId, submissionData).subscribe(
      r => {
        this.submissionAdd.emit(r.data);

        // Add to ICS database, if required
        if (this.icsProject) {

          let icsSubmissionData = {
            organisationName: this.registration.organisation.organisationName,
            organisationCode: this.registration.organisation.nhsCode,
            organisationTypeName: this.projectId == 29 ? 'Community' : this.projectDetails.project.projectCategoryName,
            externalOrganisationId: this.registration.organisation.organisationId,
            submissionList: [{
              submissionName: r.data.submissionName,
              submissionCode: this.registration.organisation.nhsCode + '-' + this.registration.registration.submission.submissionList.length,
              externalSubmissionId: r.data.submissionId
            }]
          }     

          this.addIcsSubmission(icsSubmissionData);
        }

      },
      e => {
        console.log('There has been an error whilst trying to add a submission');
      }
    );
  }

  addIcsSubmission(icsSubmissionData: any) {
    this.icsService.addSubmissions(this.projectId, icsSubmissionData).subscribe(
      r => {
        console.log('Submission added to the ICS database.');             
      },
      e => {
        console.log(e);
      }
    );
  }

  editSubmission(registrationId: number, submissionData) {
    this.memberService.EditSubmission(registrationId, submissionData).subscribe(
      r => {
        this.submissionEdit.emit(r.data);

        // Update peer groups
        if (this.peerGroups && this.registration.registration.submission.submissionList.map(s => s.peerGroupId).includes(null)) {
          this.missingPeerGroups = true;
        } else {
          this.missingPeerGroups = false;
        }

        // Edit ICS submission
        if (this.icsProject) {

          let icsSubmissionData = {
            submissionName: r.data.submissionName
          }

          this.editIcsSubmission(r.data.submissionId, icsSubmissionData);
        }

      },
      e => {
        console.log('There has been an error whilst trying to edit a submission');
      }
    )
  }

  editIcsSubmission(submissionId: number, submissionData: any) {
    this.icsService.modifySubmission(this.projectId, submissionId, submissionData).subscribe(
      r => {
        console.log('Submission edited on the ICS database.');            
      },
      e => {
        console.log(e);
      }
    );
  }

  removeSubmission(registrationId: number, submissionId: number) {
    this.memberService.RemoveSubmission(registrationId, submissionId).subscribe(
      r => {
        this.submissionDelete.emit(submissionId);
      },
      e => {
        console.log('There has been an error whilst trying to delete a submission');
      }
    );
  }

  removeIcsSubmission(registrationId: number, submissionId: number) {
    this.icsService.deleteSubmission(this.projectId, submissionId).subscribe(
      r => {
        this.removeSubmission(registrationId, submissionId);
      },
      e => {
        console.log(e);
        console.log('There has been an error whilst trying to delete a submission. Please contact the Support Team.');
      }
    );
  }

  submissionLeadFormSubmit() {
    let submissionLead = {
      fullName: this.submissionLeadForm.value.fullName,
      logonName: this.submissionLeadForm.value.logonName,
      jobTitle: '',
      phoneNo: ''
    };

    this.addSubmissionLead(this.registration.registration.registrationId, this.currentSubmissionId, submissionLead)

    this.setSubmissionState(2, 2);

    this.buildSubmissionLeadForm();
    this.showSubmissionFormAdd = true;
  }

  submissionLeadFormAdd(submissionId: number) {
    this.showSubmissionFormAdd = false;
    this.currentSubmissionId = submissionId;

    this.buildSubmissionLeadForm();

    this.setSubmissionState(2, 1);
  }

  submissionLeadFormRemove(submissionId: number, userId: number) {
    if (confirm("Are you sure you want to remove this Submission Lead?")) {
      this.removeSubmissionLead(this.registration.registration.registrationId, submissionId, userId);
    }
  }

  submissionLeadFormCancel() {
    this.showSubmissionFormAdd = true;
    this.setSubmissionState(2, 2);
  }

  addSubmissionLead(registrationId: number, submissionId: number, submissionLeadData) {
    this.memberService.AddSubmissionLead(registrationId, submissionId, submissionLeadData).subscribe(
      r => {
        this.submissionLeadAdd.emit(r.data[0]);
      },
      e => {
        console.log('There has been an error whilst trying to add a submission lead');
      }
    );
  }

  removeSubmissionLead(registrationId: number, submissionId: number, userId: number) {
    this.memberService.RemoveSubmissionLead(registrationId, submissionId, userId).subscribe(
      r => {
        let submissionUser = { submissionId: submissionId, userId: userId };

        this.submissionLeadDelete.emit(submissionUser);
      },
      e => {
        console.log('There has been an error whilst trying to remove a submission lead');
      }
    );
  }

  setSubmissionState(form: number, action: number) {
    //form: submissionForm 1 submissionLeadForm 2
    //action: open 1 close 2
    if (form == 1) {
      this.submissionState.sfView = action == 1 ? true : false;
      this.showSubmissionFormAdd = action == 1 ? false : true;
    }

    if (form == 2) {
      this.submissionState.slfView = action == 1 ? true : false;
    }
  }

  getSubmissionType(peerGroupId: number) {
    if (!peerGroupId) { return peerGroupId; }

    let submissionType = this.registration.registration.submission.peerGroups.filter(s => s.peerGroupId === peerGroupId)[0].peerGroupName;

    return submissionType;
  }

  submissionCheck(submissionName): boolean {
    if (this.registration.registration.submission.submissionList.findIndex(c => c.submissionName == submissionName) != -1 && this.checkSubmissionName < 3) {
      this.submissionForm.controls['submissionName'].setErrors({'used': true});
      return true;
    }
    else {
      this.submissionForm.controls['submissionName'].setErrors(null);
      return false;
    }
  }

  onSubmissionValueChanged(data?: any) {
    if (!this.submissionForm) { return; }
    const form = this.submissionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  onSubmissionLeadValueChanged(data?: any) {
    if (!this.submissionLeadForm) { return; }
    const form = this.submissionLeadForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  formErrors = {
    'submissionName': '',
    'peerGroupId': '',
    'fullName': '',
    'logonName': ''
  }

  validationMessages = {
    'submissionName': {
      'required': 'Submission name is required.',
      'maxlength': 'Full name cannot be longer than 150 characters.'
    },
    'peerGroupId': {
      'required': 'Must have selected a valid Submission Type.'
    },
    'fullName': {
      'required': 'Full name is required.',
      'maxlength': 'Full name cannot be longer than 150 characters.'
    },
    'logonName': {
      'required': 'Email Address is required.',
      'maxlength': 'Email Address cannot be longer than 200 characters.',
      'email': 'Email Address is not valid.'
    }
  }
}