<div *ngIf="registration">

  <div *ngIf="!showProjectLeadForm">
    <div *ngIf="projectLead">
      <table class="table">
        <thead>
          <tr>
            <th class="col-md-3">Name</th>
            <th class="col-md-3">Job Title</th>
            <th class="col-md-4">Email</th>
            <th class="col-md-2">Phone</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{projectLead?.fullName}}</td>
            <td>{{projectLead?.jobTitle || '-'}}</td>
            <td>
              <a href="mailto:{{projectLead?.logonName}}">{{projectLead?.logonName}}</a>
            </td>
            <td>{{projectLead?.phoneNo || '-'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <form *ngIf="showProjectLeadForm" [formGroup]="projectLeadForm" novalidate>
    <hr>
    <div (click)="getUserDetails()" class="hint -link">
      <i class="fas fa-lightbulb-on"></i>Click here to add your own details to the form
    </div>
    <div class="form-group">
      <label>Name <span class="text-danger">*</span></label>
      <input type="text" class="form-control" formControlName="fullName">
    </div>

    <div *ngIf="formErrors.fullName" class="alert alert-danger">
      {{ formErrors.fullName }}
    </div>

    <div class="form-group">
      <label for="logonName">Email <span class="text-danger">*</span></label>
      <input type="email" formControlName="logonName" class="form-control">
    </div>

    <div *ngIf="formErrors.logonName" class="alert alert-danger">
      {{ formErrors.logonName }}
    </div>

    <div class="form-group">
      <label>Job Title <span class="text-danger">*</span></label>
      <input type="text" class="form-control" formControlName="jobTitle">
    </div>

    <div *ngIf="formErrors.jobTitle" class="alert alert-danger">
      {{ formErrors.jobTitle }}
    </div>

    <div class="form-group">
      <label>Contact Number</label>
      <input type="text" class="form-control" formControlName="phoneNo">
    </div>

    <div *ngIf="formErrors.phoneNo" class="alert alert-danger">
      {{ formErrors.phoneNo }}
    </div>

    <div>
      <button type="button" class="btn btn-success button-margin" [disabled]="!projectLeadForm.valid" (click)="projectLeadFormSubmit()">Save</button>
      <button type="button" class="btn btn-default button-margin" (click)="showProjectLeadForm = false; showProjectLeadFormAdd = true;">Cancel</button>
    </div>
    <p class="text-muted explainer" *ngIf="!projectLeadForm.valid">
      Name, email and job title are required are required before the Project Lead can be saved. Contact number is optional.
    </p>
  </form>

  <div>
    <button type="button" class="btn btn-primary" *ngIf="!projectLead && showProjectLeadFormAdd" (click)="projectLeadFormAdd()">Add Project Lead</button>
  </div>

  <div *ngIf="projectLeadExists" class="alert alert-warning">
    As this Project Lead already exists within our database, their job title and contact number have been set previously and cannot be edited here. 
    This will need to be done on the <strong>My Profile</strong> page by the project lead.
  </div>
</div>