<div class="container-fluid unauth-page">
  <div
    class="
      col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2
    "
  >
    <div class="login-header">
      <img
        src="../assets/images/nhsbn-logo-colour-svg.svg"
        alt="NHSBN Banner"
      />
    </div>

    <div class="panel panel-default">
      <div class="panel-body">
        <h1>Reset Password</h1>

        <p>
          Please enter your email address below. If your email address is held
          on our database, you will receive an email to update your login
          credentials
        </p>

        <hr />

        <form [formGroup]="resetPassword" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label>Email Address</label>
            <input
              type="email"
              class="form-control form-fix"
              formControlName="emailAddress"
            />
          </div>

          <div class="form-group">
            <div *ngIf="formErrors.emailAddress" class="alert alert-danger">
              <p>{{ formErrors.emailAddress }}</p>
            </div>
          </div>

          <div class="form-group">
            <div *ngIf="success" class="alert alert-success">
              <p>
                Request successfully submitted. You should recieve an email
                shortly, please allow for up to 15 minutes for it to arrive. If
                it fails to do so then please contact the support team.
              </p>
            </div>
          </div>

          <div class="form-group">
            <div *ngIf="error" class="alert alert-danger">
              <p> {{ errorText }}</p> <br />
              <p>If you're having problems you can: <br />
                <a href="https://www.nhsbenchmarking.nhs.uk/contact">Contact the support team</a>
              </p>
            </div>
          </div>

          <button
            type="submit"
            [disabled]="!resetPassword.valid"
            class="btn btn-lg btn-primary nhs-blue-bg btn-promise"
            [promiseBtn]="newSpinner"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>

    <div class="login-footer">
      <p>
        The Benchmarking Network is the in-house benchmarking service of the
        NHS. The Network is hosted by
        <a href="https://www.elft.nhs.uk/" target="_blank"
          >East London Foundation Trust</a
        >.
      </p>
      <p>&copy; East London Foundation Trust {{ copyDate | date: "yyyy" }}</p>
    </div>
  </div>
</div>
