<!-- SELECTOR BAR-->
<div id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back" (click)="navHome()">
            <i class="fa fa-caret-left"></i> Back to Home
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-fluid" id="containerForum">
  <!-- SIDEBAR -->
  <div class="col-lg-4 col-md-4 col-sm-12" id="sidebar">
    <h1>Events</h1>
    <p class="intro-p">
      If you wish to make a provisional booking for yourself or a colleague,
      please select an event and click on the green
      <strong>Register</strong> button.
    </p>
    <p class="intro-p">
      If you have any questions or require further information, please contact the
      <a [routerLink]="['/contact']" routerLinkActive="active"> Support Team</a>
      on 0161 521 0818.
    </p>
    <p>
      Please note that NHS Benchmarking Network will be photographing throughout
      the events, which may be posted on our social media channels and used in
      promotional materials.
    </p>
    <hr />
    <div *ngIf="quotes" class="quotes">
      <div *ngFor="let q of selectedQuotes" class="panel panel-default">
        <div class="panel-body">
          "<i>{{ q.content }}</i
          >"<br />
          <span
            >&mdash; Feedback from {{ q.category }} event from
            {{ q.year }}</span
          >
        </div>
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="col-lg-7 col-lg-offset-1 col-md-8 col-sm-12">
    <!-- Loader -->
    <div class="loading-overview" *ngIf="!filteredEvents">
      <p>
        <i class="fas fa-spinner fa-spin"></i> Loading events. Please wait...
      </p>
    </div>

    <div id="search" *ngIf="filteredEvents">
      <div class="form-group">
        <i class="fal fa-search" id="searchIcon"></i>
        <input
          type="text"
          class="form-control"
          placeholder="e.g. workshop, CAMHS, conference"
          [(ngModel)]="searchText"
        />
      </div>

      <div class="filter" *ngIf="hideShowCategories">
        <span
          *ngFor="let i of categories | without: [null] | unique | orderBy: i"
          (click)="filterByCategory(i)"
          [class]="
            filter == i ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'
          "
          >{{ i }}</span
        >
        <span (click)="clearFilter()" class="btn btn-sm btn-default btn-filter"
          ><i class="far fa-close"></i
        ></span>
      </div>

      <div class="filter" *ngIf="hideShowRegions">
        <span
          *ngFor="let i of regions | without: [null] | unique | orderBy: i"
          (click)="filterByRegion(i)"
          [class]="
            filter == i ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'
          "
          >{{ i }}</span
        >
        <span (click)="clearFilter()" class="btn btn-sm btn-default btn-filter"
          ><i class="far fa-close"></i
        ></span>
      </div>

      <div id="options">
        <button
          (click)="hideShowCategories = !hideShowCategories"
          [class]="
            hideShowCategories
              ? 'btn btn-sm btn-default'
              : 'btn btn-sm btn-primary'
          "
        >
          <i class="fas fa-filter"></i
          >{{ hideShowCategories ? "Close" : "Filter by Category" }}
        </button>
        <button
          (click)="hideShowRegions = !hideShowRegions"
          [class]="
            hideShowRegions
              ? 'btn btn-sm btn-default'
              : 'btn btn-sm btn-primary'
          "
        >
          <i class="fas fa-filter"></i
          >{{ hideShowRegions ? "Close" : "Filter by Region" }}
        </button>
        <!-- <button (click)="togglePast()" [class]="showingPastEvents ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'">
				<i class="far fa-history"></i>{{ showingPastEvents ? 'Hide Past Events' : 'Show Past Events' }} 
			</button> -->
      </div>

      <div *ngIf="filter" class="filter-showing" (click)="clearFilter()">
        Results are filtered to <strong>{{ filter }}</strong>
        <i class="far fa-times"></i>
      </div>

      <div *ngIf="linkedEvent" class="filter-showing" (click)="clearFilter()">
        Events are filtered to show one event <i class="far fa-times"></i>
      </div>

      <div
        *ngIf="
          filteredEvents &&
          (
            filteredEvents
            | filterBy: ['eventName', 'eventDescription']:searchText
          ).length === 0
        "
        class="no-matches"
      >
        {{
          searchText
            ? "There are no events matching this search."
            : "There are currently no upcoming events."
        }}
      </div>

      <div class="hint" (click)="togglePast()">
        <div class="row">
          <div class="col-sm-1 text-center">
            <i class="fas fa-history"></i>
          </div>
          <div class="col-sm-11">
            <strong
              >Click here to toggle past events. Past events include links to
              presentations and other documents.</strong
            ><br />
            You can also use the search bar above to search for specific events,
            past and future.
          </div>
        </div>
      </div>
      <div *ngFor="let event of filteredEvents | filterBy: ['eventName', 'eventDescription']:searchText" [id]="event.eventId">
        <div [class]="event.when === 'future' ? 'request' : 'request past'">
          <div class="request-header">
            <div class="row">
              <div class="col-md-2 col-sm-2 hidden-xs">
                <div class="date-block">
                  <span class="day">{{ event.eventDt | date: "dd" }}</span
                  ><br />
                  <span class="month">{{ event.eventDt | date: "MMM" }}</span>
                </div>
              </div>
              <div class="col-md-10 col-sm-10 col-xs-12">
                <div class="title-correction">
                  <h3
                    (click)="
                      copyLink(
                        'https://members.nhsbenchmarking.nhs.uk/events?event=' +
                          event.eventId
                      )
                    "
                  >
                    {{ event.eventName }} <i class="fas fa-link"></i>
                  </h3>
                  <p class="request-meta">
                    {{ event.eventDt | date: "d MMMM y / H:mm":'GMT' }} - 
                    {{ event.eventEndDt | date: "H:mm":'GMT' }}
                    <span *ngIf="event.projectCategoryName">
                      &bull;
                      {{ event.projectCategoryName }}
                    </span>
                    <span *ngIf="event.regionName">
                      &bull;
                      {{ event.regionName }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="request-body">
            <p
              *ngIf="event.when === 'future'"
              innerHTML="{{ event.eventDescription }}"
            ></p>
            <h4 *ngIf="event.when === 'past'">
              This event took place on {{ event.eventDt | date: "d MMMM y" }}.
            </h4> <br />
            <p *ngIf="event.when === 'past'" innerHTML="{{ event.eventDescription }}">
              This event took place on {{ event.eventDt | date: "d MMMM y" }}.
            </p>
            <p class="request-tags" *ngIf="event.requestTags.length">
              <i class="fas fa-tag"></i> Tags:
              <span
                *ngFor="let tag of event.requestTags"
                class="tag"
                (click)="filterByTag(tag)"
                >{{ tag }}</span
              >
            </p>

            <div class="event-actions">
              <a
                *ngIf="
                  event.when === 'future' &&
                  event.registrationEnabled == 'Y' &&
                  event.registrationLink
                "
                class="btn btn-success"
                href="{{ event.registrationLink }}"
                target="_blank"
              >
                Register
              </a>
              <button
                *ngIf="
                  event.when === 'future' &&
                  event.registrationEnabled == 'Y' &&
                  !event.registrationLink
                "
                class="btn btn-success"
                (click)="showModal(event.eventName, event.eventId)"
              >
                Register
              </button>
              <!-- <a *ngIf="event.when === 'future' && event.cubeFile" 
							class="btn btn-default" 
							href="{{ event.cubeFile }}" 
							target="_blank">
								Details
						</a> -->
              <button
                *ngIf="event.documentCount > 0"
                (click)="getDocuments(event)"
                [class]="
                  event.showDocuments ? 'btn btn-default' : 'btn btn-primary'
                "
              >
                {{ event.showDocuments ? "Hide" : "Show" }}
                {{ getDocumentCount(event) }} 
                {{ "document" | makePluralString: getDocumentCount(event) }}
              </button>
            </div>

            <div *ngIf="event.showDocuments">
              <div *ngFor="let doc of event.documents">
                <div *ngIf="doc.isVisible !== 'N'" class="document" (click)="getDocument(doc.documentId)">
                  <i class="far fa-file-download"></i>{{ doc.documentName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Search -->
  </div>

  <!-- Registration Modal -->
  <div
    bsModal
    #lgModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="EventRegistrationModal"
    aria-hidden="true"
    (onHide)="hideModal()"
    (onShown)="getOrganisationsByUser()"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" *ngIf="eventRegistration">
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            Registration Form<br />
            <span>{{ eventTitle }}</span>
          </h4>
          <button
            type="button"
            class="close pull-right"
            (click)="lgModal.hide(); registered = false; isOnline = false"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form [formGroup]="eventRegistration" *ngIf="!registered">
            <div class="form-group">
              <label class="control-label"
                >Please select the organisation you are registering for:
                <span style="color: #f00">*</span></label
              >
              <select
                class="form-control"
                formControlName="orgSelect"
                (change)="selectOrganisation()"
              >
                <option [ngValue]="0" selected disabled>
                  Select an organisation...
                </option>
                <option
                  *ngFor="let o of orgList"
                  [ngValue]="o.organisationId"
                  [disabled]="o.isDefunct == 'Y'"
                >
                  {{ o.organisationName }}
                </option>
              </select>
            </div>

            <div *ngIf="girft && orgSelection" class="alert alert-warning">
              This organisation is not a member of the Network. To register for
              this event, please contact
              <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries</a> or 0161 521 0818.
            </div>

            <div *ngIf="!girft && orgSelection">
              <div class="form-group">
                <label class="control-label"
                  >Delegate Name: <span style="color: #f00">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="delegateName"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <div *ngIf="formErrors.delegateName" class="alert alert-danger">
                  {{ formErrors.delegateName }}
                </div>
              </div>

              <div class="form-group">
                <label class="control-label"
                  >Job Title: <span style="color: #f00">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="delegateJob"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <div *ngIf="formErrors.delegateJob" class="alert alert-danger">
                  {{ formErrors.delegateJob }}
                </div>
              </div>

              <div class="form-group">
                <label class="control-label"
                  >Delegate Email Address:
                  <span style="color: #f00">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  formControlName="delegateEmail"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <div
                  *ngIf="formErrors.delegateEmail"
                  class="alert alert-danger"
                >
                  {{ formErrors.delegateEmail }}
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label"
                      >Phone No: <span style="color: #f00">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="delegateNumber"
                      autocomplete="off"
                    />
                  </div>

                  <div class="form-group">
                    <div
                      *ngIf="formErrors.delegateNumber"
                      class="alert alert-danger"
                    >
                      {{ formErrors.delegateNumber }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Twitter Handle:</label>

                    <div class="input-group">
                      <span class="input-group-addon">@</span>
                      <input
                        type="text"
                        class="form-control"
                        fromControlName="delegateTwitter"
                        autocomplete="off"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div
                      *ngIf="formErrors.delegateTwitter"
                      class="alert alert-danger"
                    >
                      {{ formErrors.delegateTwitter }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!isOnline">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Dietary Requirements:</label>
                    <select class="form-control" formControlName="delegateDiet">
                      <option value="N">None</option>
                      <option value="V">Vegetarian</option>
                      <option value="P">Pescatarian</option>
                      <option value="E">Vegan</option>
                      <option value="O">Other (Specify)</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <div
                      *ngIf="formErrors.delegateDiet"
                      class="alert alert-danger"
                    >
                      {{ formErrors.delegateDiet }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label"
                      >Details (including any allergies)</label
                    >

                    <input
                      type="text"
                      class="form-control"
                      formControlName="delegateAllergies"
                      autocomplete="off"
                    />
                  </div>

                  <div class="form-group">
                    <div
                      *ngIf="formErrors.delegateAllergies"
                      class="alert alert-danger"
                    >
                      {{ formErrors.delegateAllergies }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="!isOnline">
                <label class="control-label">Access Requirements:</label>
                <textarea
                  rows="2"
                  class="form-control"
                  formControlName="delegateAccess"
                  autocomplete="off"
                ></textarea>
              </div>
              <div class="form-group">
                <div
                  *ngIf="formErrors.delegateAccess"
                  class="alert alert-danger"
                >
                  {{ formErrors.delegateAccess }}
                </div>
              </div>
              <hr />
              <div class="form-group">
                <div class="row">
                  <div class="col-md-8">
                    <label class="control-label"
                      >Delegate List <span style="color: #f00">*</span></label
                    >
                    <p style="margin-bottom: 0">
                      Are you happy for your details to be included on the
                      delegate list?
                    </p>
                    <span class="text-muted"
                      >This list will be shared with all delegates attending on
                      the day.</span
                    >
                  </div>
                  <div class="col-md-3 col-md-offset-1">
                    <select
                      class="form-control"
                      formControlName="delegateList"
                      ng-selected="Please Select"
                      style="margin-top: 2em"
                    >
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-actions">
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="!eventRegistration.valid"
                  (click)="registerEvent()"
                  *ngIf="!girft"
                >
                  Register
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="cancelEvent()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>

          <div id="registered" *ngIf="registered">
            <h2>
              <i class="far fa-check-circle"></i>
              You have registered successfully
            </h2>
            <p>Thank you for registering for the event. If you would like to change details of your booking, or cancel your place, please email <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries@nhsbenchmarking.nhs.uk.</a> The MS Teams webinar link will be sent to you on the morning of the event.</p>
            <button
              class="btn btn-lg btn-success"
              (click)="downloadIcs(eventId)"
            >
              Add to Calendar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
