import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TREE_ACTIONS, ITreeOptions } from 'angular-tree-component';

export interface Tier {
  tierId: number,
  tierName: string,
  displaySequence: number,
  reportId: number,
  isVisible: string,
  tierTypeId: number,
  tierTypeName: string,
  childrenTiers: Tier[],
  tierTags: string[],
  name: string,
  id: number,
  hasChildren?: boolean,
  children?: Tier[]
}

@Component({
  selector: 'app-tier-tree',
  templateUrl: './tier-tree.component.html',
  styleUrls: ['./tier-tree.component.scss']
})
export class TierTreeComponent implements OnInit {

  @Input() public apiTiers: Tier[];
  @Output() public reportTier: EventEmitter<string> = new EventEmitter();

  public tiers: Tier[];

  @ViewChild('tree') tree;

  options: ITreeOptions = {
    displayField: 'tierName',
    idField: 'tierId',
    hasChildrenField: 'hasChildren',
    actionMapping: {
      mouse: { click: (tree, node, $event) => {
        if (node.hasChildren) { 
          TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        } 
        else {
          TREE_ACTIONS.ACTIVATE(tree, node, $event);
          this.reportTier.emit(node.data.reportId + '-' + node.data.tierId);
        }
      }}
    }
  }

  constructor() { }

  ngOnInit() {
    this.tiers = this.updateTiers(this.apiTiers, 'childrenTiers', 'children');
  }

  private updateTiers(data: any, oldKey: string, newKey: string): Tier[] {
    if (Array.isArray(data)) {
      data.forEach(item => this.updateTiers(item, oldKey, newKey));
    } else if (typeof data === 'object' && data !== null) {
      if (data.hasOwnProperty(oldKey)) {
        // Add hasChildrenField defined in options
        if (data.childrenTiers.length && data.reportId == null) {
          data.hasChildren = true;
        } else {
          data.hasChildren = false;
          // Remove secondary tiers
          delete data.childrenTiers;
        }
        // Replace API key
        data[newKey] = data[oldKey];
      }
      // Recursive to update all nested tiers
      Object.values(data).forEach(value => this.updateTiers(value, oldKey, newKey));
    }
    return data;
  }

}
