import { Component, Input, OnInit } from '@angular/core';
import { DynamicInfoPanel } from 'app/utilities/classes/dynamic-info-panel/dynamic-info-panel';
import { OrderByPipe } from 'ngx-pipes';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  providers: [ OrderByPipe ]
})
export class InfoPanelComponent implements OnInit {
  @Input() infoPanel: DynamicInfoPanel;
  @Input() onInfoPanelClick: (info: string) => void;
  
  constructor() { }

  ngOnInit() { }
}
