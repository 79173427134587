import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TabsModule } from 'ngx-bootstrap';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { SafePipeModule } from '../utilities/pipes/safe-pipe.module';
import { DocumentPanelModule } from 'app/shared/document-panel/document-panel.module';

@NgModule({
  imports: [ 
    CommonModule, 
    TabsModule.forRoot(),
    SafePipeModule,
    DashboardRoutingModule,
    FormsModule,
    ButtonsModule.forRoot(),
    DocumentPanelModule
  ],
  declarations: [ DashboardComponent ]
})
export class DashboardModule {}