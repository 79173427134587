import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { User } from 'app/utilities/classes/user/user';
import { AuthService } from 'app/utilities/services/auth.service';
import { DataService } from 'app/utilities/services/data.service';
import { HtmlService } from 'app/utilities/services/html.service';
import { MemberService } from 'app/utilities/services/member.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-user-check",
  templateUrl: "./user-check.component.html",
  styleUrls: ["./user-check.component.scss"],
})
export class UserCheckComponent implements OnInit {
  public user: User;
  public countdown: any;
  public form: FormGroup;
  public updatedUser: any;
  public roles: FormGroup;
  public seconds: number = 3;
  public myProfile: FormGroup;
  public allOrganisations = [];
  public organisationsData = [];
  public progressBarWidth: number = 0;
  public selectedorganisationIds = {};
  public progressBarWidthProfile: number;
  public organisationsSelected: boolean = false;
  public metaTitle: string = "UserCheck | NHS Benchmarking Network";
  newsletterUpdates: string;
  notificationUpdates: string;
  
  constructor(
    private meta: Meta,
    private router: Router,
    private fb: FormBuilder,
    public auth: AuthService,
    private titleService: Title,
    private toastr: ToastrService,
    private dataService: DataService,
    private htmlService: HtmlService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private memberService: MemberService,
    private profileSpinner: Angular2PromiseButtonModule,
    public passwordSpinner: Angular2PromiseButtonModule
  ) {
    this.myProfile = fb.group({
      logonName: [{ value: null, disabled: true }],
      fullName: [null, [Validators.required, Validators.maxLength(60)]],
      jobTitle: [null, [Validators.required, Validators.maxLength(60)]],
      phoneNo: [null, [Validators.required, Validators.maxLength(40)]],
      mailchimpOptIn: [null, [Validators.maxLength(1)]],
      notificationOptIn: [null, [Validators.maxLength(1)]],
    });

    this.myProfile.valueChanges.subscribe((data) => this.onValueChanged(data));

    this.onValueChanged();
    this.form = this.formBuilder.group({
      organisations: new FormArray([], this.minSelectedCheckboxes(1)),
    });
  }

  ngOnInit() {
    // if (this.authService.authenticated()) {
    //   this.router.navigate(['/']);
    // }
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({ property: "og:title", content: this.metaTitle });
    this.meta.updateTag({ name: "twitter:title", content: this.metaTitle });
    this.getUserDetails();
  }

  // Confirm Organisation
  private addCheckboxes() {
    for (let key in this.organisationsData) {
      let child = this.organisationsData[key];
      this.allOrganisations.push(child[0]);
      this.organisationsFormArray.push(new FormControl(false));
    }
  }

  get organisationsFormArray() {
    return this.form.controls.organisations as FormArray;
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map((control) => control.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0);

      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  submitOrganisation() {
    this.progressBarWidth += 50;
    this.organisationsSelected = true;
    this.selectedorganisationIds["OrganisationId"] =
      this.form.value.organisations.map((checked, i) =>
          checked ? null : this.allOrganisations[i].organisationId).filter((v) => v !== null);
  }

  // My Details
  getUserDetails() {
    this.memberService.User().subscribe((u) => {
      (this.user = u.data),
      this.dataService.setUser(u.data),
        this.myProfile.get("logonName").setValue(this.user.logonName),
        this.myProfile.get("fullName").setValue(this.user.fullName),
        this.myProfile.get("jobTitle").setValue(this.user.jobTitle),
        this.myProfile.get("phoneNo").setValue(this.user.phoneNo),
        this.myProfile.get("mailchimpOptIn").setValue(this.user.mailchimpOptIn),
        this.myProfile.get("notificationOptIn").setValue(this.user.notificationOptIn);
      this.organisationsData = this.user.roles;
      this.addCheckboxes();
      this.newsletterUpdates = null
      this.notificationUpdates = this.user.notificationOptIn
    },
    (e) => {
      this.router.navigate(["/landing"]);
      console.log(e);
    });
  }

  updateUser() {
    this.progressBarWidthProfile = 50;
    let payload = this.myProfile;
    let user = {};

    Object.keys(payload.controls).forEach((key) => {
      if (payload.controls[key].dirty) {
        user[key] = payload.controls[key].value;
      }
    });

    // console.log(user['userDetailsLastReviewed'] = "2021-10-24T00:00:00.000Z")
    this.updatedUser = user;
    if(Object.keys(this.updatedUser).length === 0) {
      user['logonName'] = this.user.logonName
    }

    this.profileSpinner = new Promise((resolve, reject) => {
      setTimeout(resolve, 3000);
    });

  }

  onValueChanged(data?: any) {
    this.progressBarWidthProfile = 0;
    if (!this.myProfile) {
      return;
    }
    const form = this.myProfile;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + " ";
        }
      }
    }
  }

  // Submit & Log Out
  submitAll() {
    this.countdown = setInterval(() => {
      this.updateSec();
    }, 1000);
  }

  updateSec() {
    this.seconds--;
    if (this.seconds == 0) {
      clearInterval(this.countdown);
      this.memberService.UserRolesDisabled(this.selectedorganisationIds["OrganisationId"]).subscribe(
        (u) => {
          this.updateUserDetails(this.updatedUser);
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }

  updateUserDetails(user?: Object) {
    this.memberService.UserUpdate(user).subscribe(
      (r) => {
        // On success, update sessionStorage user
        let storedUser = JSON.parse(sessionStorage.getItem("user"));
        Object.keys(user).forEach((key) => {
          storedUser[key] = user[key];
        });
        sessionStorage.setItem("user", JSON.stringify(storedUser));
        this.redirect();
      },
      (e) => {
        this.toastr.error(
          "There was an error whilst trying to update your profile details. Please try again."
        );
      }
    );
  }

  redirect() {
    this.memberService.RegenerateToken().subscribe(
      (u) => {
        if(Object.keys(this.organisationsData).length == this.selectedorganisationIds["OrganisationId"].length){
          this.logout();
        } else {
          this.router.navigate(["/"]);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  logout() {
    localStorage.removeItem("id_token");
    sessionStorage.removeItem("savedTiers");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("notifications");
    this.router.navigate(["/landing"]);
  }

  formErrors = {
    fullName: "",
    jobTitle: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
  };

  validationMessages = {
    fullName: {
      required: "Full name is required.",
      maxlength: "Full name cannot be longer than 60 characters.",
    },
    jobTitle: {
      required: "Job title is required.",
      maxlength: "Job title cannot be longer than 60 characters.",
    },
    phoneNo: {
      required: "Phone number is required.",
      maxlength: "Phone number cannot be longer than 40 characters.",
    },
    password: {
      required: "This field is required to change your password.",
      minlength: "Password must be a minimum of 8 characters in length.",
      forbiddenName: "New password cannot include the pound symbol (£).",
    },
    confirmPassword: {
      required:
        "This field is required to change your password and must match New Password above.",
    },
  };
}
