import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { Angular2PromiseButtonModule } from "angular2-promise-buttons/dist";
import { TabsModule } from "ngx-bootstrap";

import { ProfileComponent } from "./profile.component";
import { ProfileRoutingModule } from "./profile-routing.module";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TabsModule.forRoot(),
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fa fa-spinner fa-spin"></span>',
      disableBtn: true,
      btnLoadingClass: "is-loading",
      handleCurrentBtnOnly: false,
    }),
    ProfileRoutingModule,
  ],
  declarations: [ProfileComponent],
})
export class ProfileModule {}
