import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

import { Angular2PromiseButtonModule } from "angular2-promise-buttons/dist";
import { ToastrService } from "ngx-toastr";

import { DataService } from "../utilities/services/data.service";
import { HtmlService } from "../utilities/services/html.service";
import { MemberService } from "../utilities/services/member.service";
import { User } from "../utilities/classes/user/user";

import { forbiddenNameValidator } from "../utilities/validation/forbidden-name";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit {
  myProfile: FormGroup;
  myPassword: FormGroup;
  user: User;

  metaTitle: string = 'My Profile | NHS Benchmarking Network';

  constructor(
    fb: FormBuilder,
    private dataService: DataService,
    private htmlService: HtmlService,
    private memberService: MemberService,
    private profileSpinner: Angular2PromiseButtonModule,
    public passwordSpinner: Angular2PromiseButtonModule,
    private toastr: ToastrService,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
  ) {
    this.myProfile = fb.group({
      logonName: [{ value: null, disabled: true }],
      fullName: [null, [Validators.required, Validators.maxLength(60)]],
      jobTitle: [null, [Validators.required, Validators.maxLength(60)]],
      phoneNo: [null, [Validators.required, Validators.maxLength(40)]],
      mailchimpOptIn: [null, [Validators.maxLength(1)]],
      notificationOptIn: [null, [Validators.maxLength(1)]],
    });

    this.myPassword = fb.group({
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          forbiddenNameValidator(/£/i),
        ],
      ],
      confirmPassword: [null, [Validators.required]],
    });

    this.myProfile.valueChanges.subscribe((data) => this.onValueChanged(data));

    this.myPassword.valueChanges.subscribe((data) =>
      this.onPasswordChanged(data)
    );

    this.onValueChanged();
    this.onPasswordChanged();
  }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.getUserDetails();
  }

  getUserDetails() {
    this.memberService.User().subscribe((u) => {
      (this.user = u.data),
        this.dataService.setUser(u.data),
        this.myProfile.get("logonName").setValue(this.user.logonName),
        this.myProfile.get("fullName").setValue(this.user.fullName),
        this.myProfile.get("jobTitle").setValue(this.user.jobTitle),
        this.myProfile.get("phoneNo").setValue(this.user.phoneNo),
        this.myProfile.get("mailchimpOptIn").setValue(this.user.mailchimpOptIn),
        this.myProfile
          .get("notificationOptIn")
          .setValue(this.user.notificationOptIn);
    });
  }

  updateUserDetails(user?: Object) {
    this.memberService.UserUpdate(user).subscribe(
      (r) => {
        this.toastr.success(
          "Your profile details have been successfully updated."
        );

        // On success, update sessionStorage user
        let storedUser = JSON.parse(sessionStorage.getItem("user"));
        Object.keys(user).forEach((key) => {
          storedUser[key] = user[key];
        });
        sessionStorage.setItem("user", JSON.stringify(storedUser));
      },
      (e) => {
        this.toastr.error(
          "There was an error whilst trying to update your profile details. Please try again."
        );
      }
    );
  }

  updateUser() {
    let payload = this.myProfile;
    let user = {};

    Object.keys(payload.controls).forEach((key) => {
      if (payload.controls[key].dirty) {
        user[key] = payload.controls[key].value;
      }
    });

    this.profileSpinner = new Promise((resolve, reject) => {
      setTimeout(resolve, 3000);
    });

    this.updateUserDetails(user);
  }

  updatePassword() {
    let payload = this.myPassword;
    let newPassword = payload.controls.password.value;
    let confirmPassword = payload.controls.confirmPassword.value;
    let password = { password: newPassword };

    this.passwordSpinner = new Promise((resolve, reject) => {
      setTimeout(resolve, 3000);
    });

    if (newPassword === confirmPassword) {
      this.updateUserDetails(password);
    }
  }

  onValueChanged(data?: any) {
    if (!this.myProfile) {
      return;
    }
    const form = this.myProfile;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + " ";
        }
      }
    }
  }

  onPasswordChanged(data?: any) {
    if (!this.myPassword) {
      return;
    }
    const form = this.myPassword;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + " ";
        }
      }
    }
  }

  navHome() {
    this.router.navigate(["/home"]);
  }

  formErrors = {
    fullName: "",
    jobTitle: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
  };

  validationMessages = {
    fullName: {
      required: "Full name is required.",
      maxlength: "Full name cannot be longer than 60 characters.",
    },
    jobTitle: {
      required: "Job title is required.",
      maxlength: "Job title cannot be longer than 60 characters.",
    },
    phoneNo: {
      required: "Phone number is required.",
      maxlength: "Phone number cannot be longer than 40 characters.",
    },
    password: {
      required: "This field is required to change your password.",
      minlength: "Password must be a minimum of 8 characters in length.",
      forbiddenName: "New password cannot include the pound symbol (£).",
    },
    confirmPassword: {
      required:
        "This field is required to change your password and must match New Password above.",
    },
  };
}
