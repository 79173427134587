<div class="hint" *ngIf="registration.registration.submission.allowChanges && !registration.registration.registrationLocked && peerGroups && missingPeerGroups">
  <i class="fas fa-lightbulb-on"></i>Please ensure you set a type for each submission.
</div>

<div class="hint -link" *ngIf="projectId !== 20 && registration.registration.submission.allowChanges && !registration.registration.registrationLocked && registration.organisation.organisationName === registration.registration.submission.submissionList[0].submissionName" (click)="submissionChangeCheck(registration.registration.submission.submissionList[0].submissionId, 2)">
  <i class="fas fa-lightbulb-on"></i>{{ projectId === 6 ? "Click here to change to the name of the default submission to the name of the hospital / emergency department." : "The default submission's name is currently set to the organisation's name. Click here to edit it." }}
</div>

<div *ngIf="registration">

  <table class="table table-sub" *ngIf="registration.registration.submission.submissionList.length > 0">
    <thead>
      <tr>
        <th>Name</th>
        <th *ngIf="peerGroups">Type <span class="text-danger">*</span></th>
        <th *ngIf="projectId !== 36 && projectId !== 20">Submission Lead</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let s of registration.registration.submission.submissionList; index as i">
        <td>
          <span *ngIf="icsProject" class="text-muted">{{ registration.organisation.nhsCode }}-{{ i + 1}}:&nbsp;</span>{{s.submissionName}}&nbsp;
          <button class="btn btn-xs btn-default" *ngIf="registration.registration.submission.allowChanges && !registration.registration.registrationLocked" (click)="submissionChangeCheck(s.submissionId, 2)">Edit</button>
        </td>
        <td *ngIf="peerGroups">
          {{getSubmissionType(s.peerGroupId) || '-'}}&nbsp;
          <button class="btn btn-xs btn-default" *ngIf="registration.registration.submission.allowChanges && !registration.registration.registrationLocked" (click)="submissionChangeCheck(s.submissionId, 3)">Edit</button>
        </td>
        <td *ngIf="projectId !== 36 && projectId !== 20">
          <div *ngIf="s.submissionLeadList[0]"> <!-- There is a Submission Lead -->
            <a href="mailto:{{s.submissionLeadList[0]?.logonName}}">{{s.submissionLeadList[0]?.fullName}}&nbsp;</a>
            <button class="btn btn-xs btn-danger" *ngIf="registration.registration.submission.allowChanges && !registration.registration.registrationLocked" (click)="submissionLeadFormRemove(s.submissionId, s.submissionLeadList[0]?.userId)">Remove</button>
          </div>
          <div *ngIf="!s.submissionLeadList[0]"> <!-- There is NOT a Submission Lead -->
            <span class="text-muted">-</span>&nbsp;
            <button class="btn btn-xs btn-default" *ngIf="registration.registration.submission.allowChanges && !registration.registration.registrationLocked" (click)="submissionLeadFormAdd(s.submissionId)">Edit</button>
          </div>
        </td>
        <td class="text-right">
          <!-- If/else to make Remove button show on creation -->
          <div *ngIf="submissionList[i].responseCount > 0 || submissionList[i].peerGroupMembershipCount > 0 || submissionList[i].submissionCaseCodeCount > 0 || submissionList[i].submissionCodeCount > 0  || submissionList[i].services.length > 0; then cannotRemove else canRemove"></div>
          <ng-template #cannotRemove>
            <div *ngIf="!registration.registration.registrationLocked">
                <button class="btn btn-xs btn-halt" tooltip="This submission has services assigned or data collection has started. To remove it, please contact the Project Team." placement="top">Remove</button>
            </div>
          </ng-template>
          <ng-template #canRemove>
            <div *ngIf="!registration.registration.registrationLocked && registration.registration.submission.submissionList.length > 1">
                <button class="btn btn-xs btn-danger" *ngIf="registration.registration.submission.allowChanges" (click)="submissionRemove(s.submissionId)">Remove</button>
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- New Submission Form -->
  <form [formGroup]="submissionForm" *ngIf="submissionState.sfView">
    <hr>
    <div class="form-group" *ngIf="checkSubmissionName < 3">
      <label>Submission Name <span class="text-danger">*</span></label>
      <p *ngIf="guidanceSubmissionAdd" [innerHTML]="guidanceSubmissionAdd | safe: 'html'" class="help-text"></p>
      <p *ngIf="!guidanceSubmissionAdd" class="help-text">
        The submission name should clearly identify the area covered by the submission. Clearly name your submission(s) so it is obvious which hospital(s)/site(s) the submission is for. For example: <strong>ABC Trust - North Locality</strong> or <strong>ABC Trust – Hospital 1</strong>. If you only have one submission, we recommend you use your organisation’s name.        
      </p>
      <input type="text" class="form-control" formControlName="submissionName">
    </div>

    <div *ngIf="formErrors.submissionName" class="alert alert-danger">
      {{ formErrors.submissionName }}
    </div>

    <div *ngIf="submissionForm.controls['submissionName'].hasError('used')" class="alert alert-danger">
      <p>This submission name has already been used.</p>
    </div>

    <div *ngIf="peerGroups" class="form-group"> <!-- && (checkSubmissionName == 1 || checkSubmissionName == 3) -->
      <label>Submission Type <span class="text-danger">*</span></label>

      <select class="form-control" formControlName="peerGroupId">
        <option [ngValue]="null" disabled>Choose a submission type...</option>
        <option *ngFor="let s of registration.registration.submission.peerGroups" [ngValue]="s.peerGroupId">{{s.peerGroupName}}</option>
      </select>
    </div>

    <div *ngIf="formErrors.peerGroupId" class="alert alert-danger">
      {{ formErrors.peerGroupId }}
    </div>

    <button type="button" class="btn btn-success button-margin" [disabled]="!submissionForm.valid" (click)="submissionFormSubmit()">Save</button>
    <button type="button" class="btn btn-default button-margin" (click)="submissionFormCancel()">Cancel</button>

  </form>

  <!-- New Submission Lead Form -->
  <form [formGroup]="submissionLeadForm" *ngIf="submissionState.slfView">
    <hr>
    <div class="form-group">
      <label>Submission Lead Name</label>
      <p class="help-text">
          The Submission Lead can enter data for their submission only. If a submission lead is not specified, the Project Lead will be assigned this role.
      </p>
      <input type="text" class="form-control" formControlName="fullName">
    </div>

    <div *ngIf="formErrors.fullName" class="alert alert-danger">
      {{ formErrors.fullName }}
    </div>

    <div class="form-group">
      <label>Submission Lead Email</label>

      <input type="text" class="form-control" formControlName="logonName">
    </div>

    <div *ngIf="formErrors.logonName" class="alert alert-danger">
      {{ formErrors.logonName }}
    </div>

    <button type="button" class="btn btn-success button-margin" [disabled]="!submissionLeadForm.valid" (click)="submissionLeadFormSubmit()">Save</button>
    <button type="button" class="btn btn-default button-margin" (click)="submissionLeadFormCancel()">Cancel</button>

  </form>


  <div *ngIf="projectId === 6">
    <button 
      class="btn btn-primary button-margin" 
      *ngIf="!maxSubmissions && showSubmissionFormAdd && registration.registration.submission.allowChanges && !registration.registration.registrationLocked"
      (click)="showAddSubmission(1)">
        Add another emergency department
    </button>
  </div>
  <div *ngIf="projectId !== 6">
    <button 
      class="btn btn-primary button-margin" 
      *ngIf="!maxSubmissions && showSubmissionFormAdd && registration.registration.submission.allowChanges && !registration.registration.registrationLocked"
      (click)="showAddSubmission(1)">
        Add Submission
    </button>
  </div>

</div>