import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InfoPanelComponent } from "./info-panel.component";
import { Ng2HighchartsModule } from "ng2-highcharts";
import { NgPipesModule } from "ngx-pipes";


@NgModule({
  imports: [
    CommonModule,
    NgPipesModule,
    Ng2HighchartsModule
  ],
  declarations: [InfoPanelComponent],
  exports: [InfoPanelComponent]
})
export class InfoPanelModule {}