import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OutputsValidationComponent } from './outputs-validation.component';

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const outputsValidationRoutes: Routes = [
  { path: 'validationOld/:projectId/:year', component: OutputsValidationComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(outputsValidationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class OutputsValidationRoutingModule { }