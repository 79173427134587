import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';

import { MemberService } from '../../utilities/services/member.service'
import { forbiddenNameValidator } from '../../utilities/validation/forbidden-name';
import { ToastrService } from 'ngx-toastr';

@Component({  
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None, // New spinner (child-elements)
})
export class ResetPasswordConfirmComponent implements OnInit {
  resetPasswordConfirm: FormGroup;
  passwordMatch: boolean = false; passwordReset: boolean = false; error: boolean = false;
  resetCode: string;
  copyDate = new Date(); // Set copyright year
  errorText: string;
  
  constructor(
    fb: FormBuilder, 
    private _MemberService: MemberService, 
    private route: ActivatedRoute, 
    private router: Router,
    private toastr: ToastrService,
    public newSpinner: Angular2PromiseButtonModule, // New spinner
    private titleService: Title, // Set page titles
  ) { 
    this.resetCode = this.route.snapshot.paramMap.get('resetCode');
    this.resetPasswordConfirm = fb.group({
      "emailAddress": ["", [Validators.required, Validators.maxLength(200), <any>Validators.email]],
      "newPassword": ["", [Validators.required, Validators.minLength(8), forbiddenNameValidator(/£/i)]],
      "confirmNewPassword": ["", [Validators.required]]
    });

    this.resetPasswordConfirm.valueChanges.subscribe(
      data => this.onValueChanged(data)
    );

    this.onValueChanged();
  }

  ngOnInit() {

  }

  onSubmit() {
    // New spinner
    this.newSpinner = new Promise((resolve, reject) => {
      setTimeout(resolve, 3000);
    });
    if (this.resetPasswordConfirm.value.newPassword != this.resetPasswordConfirm.value.confirmNewPassword) {
      this.passwordMatch = true;
    }
    else {
      this._MemberService.ResetPasswordConfirm({ 
        logonName: this.resetPasswordConfirm.value.emailAddress, 
        resetCode: this.resetCode, 
        newPassword: this.resetPasswordConfirm.value.confirmNewPassword
      }).subscribe(
        r => {
          this.passwordReset = true;
        },
        e => {
          this.error = true;
          // Invalid user
          if(e.error.error.statusCode == '6101') {
            this.errorText = "Email address not found, please try another.";
            this.toastr.error(
              'Email address not found, please try another or contact <a href="https://www.nhsbenchmarking.nhs.uk/contact">The Support Team <i class="far fa-envelope"></i></a>',
              "Reset Password",
              { enableHtml: true }
            );
          }
          // Reset code is invalid or expired
          if(e.error.error.statusCode == '6102') {
            this.errorText = "Reset code is invalid or has expired.";
            this.toastr.error(
              'Reset code is invalid or has expired',
              "Reset Code",
              { enableHtml: true }
            );
          } else {
            console.log(e)
          }
        }
      );
    }
  }

  onValueChanged(data?: any) {
    if (!this.resetPasswordConfirm) { return; }
    const form = this.resetPasswordConfirm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.touched && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  navLanding() {
    this.router.navigate(['/landing']);
  }

  formErrors = {
    'emailAddress': '',
    'newPassword': '',
    'confirmNewPassword': ''
  };

  validationMessages = {
    'newPassword': {
      'required': 'New Password field is required.',
      'minlength': 'New Password must be a minimum length of 8 characters. ',
      'forbiddenName': 'New password cannot include the pound symbol (£).'
    },
    'confirmNewPassword': {
      'required': 'Confirm New Password field is required'
    },
    'emailAddress': {
      'required': 'Email Address is required.',
      'maxLength': 'Email Address cannot be longer than 200 characters.',
      'email': 'Email Address is not valid.'
    }
  }
}