import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BenchmarkerComponent } from './benchmarker.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const benchmarkerRoutes: Routes = [
  { path: 'benchmarker', component: BenchmarkerComponent, canActivate: [AuthGuardService] },
  { path: 'ics', component: BenchmarkerComponent, canActivate: [AuthGuardService] },
  { path: 'national-indicators', component: BenchmarkerComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(benchmarkerRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class BenchmarkerRoutingModule { }