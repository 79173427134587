import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { TREE_ACTIONS, ITreeOptions } from 'angular-tree-component';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap';

import { MemberService } from '../utilities/services/member.service'
import { DataService } from '../utilities/services/data.service'
import { ValidationService } from '../utilities/services/validation.service'

@Component({
  templateUrl: './outputs.component.html',
  styleUrls: ['./outputs.component.scss'],
})
export class OutputsComponent implements OnInit {
  projectId: number; projectDetails = {};
  windowHeight = window.innerHeight - 250;
  nodes; nodeSelection;
  organisation; currentOrganisation; organisationSelection;
  tierState: boolean = false; error: boolean = false;
  @ViewChild('tree') tree;
  @ViewChild('outputsDisplay') outputsDisplay;
  tierParam; groupParam; submissionParam; optionParam; searchableTiers;
  loadingParams: boolean = false;

  // Validation
  activeAdmin: boolean = false;
  validations: any = [];
  quickValidation: boolean = false;
  fullValidation: boolean = false;
  showSubmissionId: boolean = false;
  reportParameters = []; showParameters: boolean = false;
  storedProcedure = [];
  loadingValidations: boolean;
  errorValidations: boolean = false;
  searchText: string;
  tiersEditParent;
  parentNode;

  // Download
  download;
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [ 'submission_code', 'value', 'value2' ],
    useBom: false,
    removeNewLines: true,
    keys: [ 'submission', 'value', 'value2' ]
  };

  options: ITreeOptions = {
    actionMapping: {
      mouse: { click: (tree, node, $event) => {
        this.tiersEditParent = node.data;
        if (node.hasChildren) { 
          TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        } 
        else {
          TREE_ACTIONS.ACTIVATE(tree, node, $event);
          this.router.navigate([], { queryParams: { tier: this.tierParam, group: this.groupParam, submission: null, option: null }, queryParamsHandling: 'merge' })
        }
      }}
    }
  }

  // Edit Report
  @ViewChild('editReportModal') public editReportModal:ModalDirective;

  // Edit Tier
  @ViewChild('editTierModal') public editTierModal:ModalDirective;
  
  reportDetails: any;
  currentYear: number;
  metaTitle: string = 'Outputs | NHS Benchmarking Network';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private dataService: DataService,
    private validationService: ValidationService,
    private titleService: Title,
    private meta: Meta,
    private toastr: ToastrService,
  ) { 
    this.projectId = +this.route.snapshot.paramMap.get('projectId');
  }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.getOrganisationsByUser(this.projectId);
    let currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    const tierIdQueryParam = this.route.snapshot.queryParams['tier']
    const optionQueryParam = this.route.snapshot.queryParams['option']
    const node = { id: tierIdQueryParam, projectId: this.projectId }
    if (optionQueryParam) {
      this.onNodeSelection(node, optionQueryParam)
    }
  }

  updateTierName(e){
    this.tiersEditParent.name = e.tierName;
    this.tiersEditParent.isVisible = e.isVisible;
  }

  onInitialized() {

    // Set groupParam
    let groupParam = this.route.snapshot.queryParams['group'];
    if (groupParam) {
      if (typeof(groupParam) === 'object') {
        this.groupParam = groupParam[0];
      } else {
        this.groupParam = groupParam;
      }
    } else {
      this.groupParam = 0;
    }

    // Set optionParam if greater than 0
    if (this.route.snapshot.queryParams['option'] > 0) {
      this.optionParam = this.route.snapshot.queryParams['option'];
    } else {
      this.optionParam = null;
    }

    // If tierParam exists, open node
    // Else clear and get parentTiers
    if (this.tierParam) {
      this.loadingParams = true;
      const targetNode = this.tree.treeModel.getNodeById(this.tierParam);
      this.tiersEditParent = targetNode.data;
      if (targetNode) {
        targetNode.setActiveAndVisible();
      } else {
        this.router.navigate([], { queryParams: { tier: null, group: null, submission: null, option: null }, queryParamsHandling: 'merge' });
        this.groupParam = null; this.submissionParam = null; this.optionParam = null;
        this.toastr.error('This chart is no longer available to view. Please use the tree on the left to navigate to another chart.', 'Error: Chart not found');
        this.getParentTiers(this.projectId);
      }
      this.loadingParams = false;
    }

  }

  ngOnDestroy() {
    sessionStorage.removeItem('savedTiers');
    sessionStorage.removeItem('nhsbnOptPref');
  }

  getOrganisationsByUser(projectId: number) {
    let currentOrg = this.dataService.getSelectedOrganisation();
    
    this.memberService.OrganisationsByUser(projectId).subscribe(
      o => { 
        this.organisation = o.data;

        this.currentOrganisation = currentOrg ? currentOrg : this.organisation[0].organisationId,

        this.organisationSelection = currentOrg ? this.organisation[this.organisation.findIndex(o => o.organisationId == currentOrg)] : this.organisation[0]
        this.dataService.setOrganisation(o.data);

        this.tierParam = +this.route.snapshot.queryParams['tier'];

        let savedTiers = JSON.parse(sessionStorage.getItem('savedTiers'));

        if (this.tierParam) {
          this.getParamTiers(this.tierParam);
        } else {
          if (savedTiers) {
            let projectId = savedTiers.projectId;
            if (projectId === this.projectId) {
              this.nodes = savedTiers;
              this.tierState = true;
              this.setProjectInfo(savedTiers);
            } else {
              this.getParentTiers(this.projectId);
              sessionStorage.removeItem('savedTiers');
            }
          } else {
            this.getParentTiers(this.projectId);
            sessionStorage.removeItem('savedTiers');
          }
        }

      }
    );
  }

  getParentTiers(projectId: number) {
    this.memberService.childTiers(projectId).subscribe(
      r => {
        this.nodes = r.data;
        this.nodes.projectId = this.projectId;
        this.tierState = true;
        this.setProjectInfo(r.data);
      },
      e => {
        console.log(e);
        this.toastr.error('There was an error loading the tiers. Please refresh the page to try again.');
      }
    );
  }

  getParamTiers(tierId, search?) {
    this.memberService.getTierPath(this.projectId, tierId).subscribe(
      r => {
        this.nodes = r.data;
        this.tierState = true;

        // Reset project info, if required
        if (!search) {
          this.setProjectInfo(r.data);
        }
      },
      e => {
        this.getParentTiers(this.projectId);
        console.log(e);
      }
    )
  }

  setProjectInfo(data) {
    let projectContact;
    if (data.projectRoles.filter(r => r.role === 'Project Coordinator')[0]) {
      projectContact = {
        name: data.projectRoles.filter(r => r.role === 'Project Coordinator')[0].name,
        email: data.projectRoles.filter(r => r.role === 'Project Coordinator')[0].email
      }
    } else {
      projectContact = {
        name: data.projectRoles[0].name,
        email: data.projectRoles[0].email
      }
    }

    this.projectDetails = {
      projectId: data.projectId,
      projectName: data.projectName, 
      projectContact: projectContact
    };

    if (data.currentUserIsAdmin === true) {
      this.activeAdmin = true;
      this.getValidations(this.projectId);
    }
  }

  toggleExpanded(event) {
    let parentId = event.node.data.id;
    this.parentNode = event.node.data || null;
    
    if (this.parentNode && !this.parentNode.childrenLoaded && !this.loadingParams) {
      this.memberService.childTiers(this.projectId, parentId).subscribe(
        r => {
          this.parentNode.children = r.data.tiers;
          this.tree.treeModel.update();
          this.nodes.projectId = this.projectId;
          sessionStorage.setItem('savedTiers', JSON.stringify(this.nodes));
        },
        e => {
          this.toastr.error('There has been an error loading the tiers. Please refresh the page and try again.')
          console.log(e);
        }
      )
    }
  }

  onNodeSelection(message, optionParam?) {
    this.nodeSelection = message;
    this.tierParam = message.id;
    this.optionParam = optionParam || this.optionParam;

    // Include params on node selection
    if (message.reportId !== null) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          tier: message.id,
          group: this.groupParam,
          submission: this.submissionParam,
          option: this.optionParam
        }, 
        queryParamsHandling: 'merge' 
      });
    }
  }

  peerGroupAdd($event) {
    this.groupParam = $event;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { group: $event }, queryParamsHandling: 'merge'
    });
  }

  submissionAdd($event) {
    this.submissionParam = $event;
  }

  optionAdd($event) {
    this.optionParam = $event;
    this.router.navigate([], {
     relativeTo: this.route,
     queryParams: { option: $event }, queryParamsHandling: 'merge'
    });
  }

  onOrgChange(message) {
    this.dataService.setSelectedOrganisation(message);

    this.currentOrganisation = message;

    this.organisationSelection = this.organisation[this.organisation.findIndex(o => o.organisationId == message)];

    // Remove selected submission
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        submission: null
      }, queryParamsHandling: 'merge' 
    });
  }

  savePoint(event) {
    let validation = {
      validationId: undefined,
      year: event.year,
      //userId: this.dataService.getUser().userId,
      tierId: parseInt(this.route.snapshot.queryParams['tier']),
      submissionCode: undefined, 
      validationDescription: undefined,
      reportName: undefined,
      validationValue: event.point.y,
      serviceItemId: event.serviceItemId
    }
    if (event.point.shapeType === 'arc') {
      validation.submissionCode = undefined;
      validation.validationDescription = event.point.name;
    } else {
      if (event.point.category.substr(2) === '---') {
        validation.submissionCode = event.point.category.substr(2);
      } else {
        validation.submissionCode = parseInt(event.point.category.substr(2));
      }
      validation.validationDescription = undefined;
    }
    if (validation.submissionCode != '---') {
      this.validationService.addValidation(validation).subscribe(
        r => {
          validation.validationId = r.data.newValidationId;
          validation.reportName = event.reportName;
          this.validations.push(validation);
        },
        e => {
          this.toastr.error(e.error.error.message);
        }
      )
    } else {
      this.toastr.error('You cannot add a point for validation until submission codes have been applied. Please speak to the IT team.')
    }
  }

  getValidations(projectId) {
    // TODO: Add year selector to HTML
    let year = 2022;
    this.loadingValidations = true;
    this.validationService.getValidations(projectId, year).subscribe(
      r => {
        this.validations = r.data.validations;
        this.loadingValidations = false;
      },
      e => {
        this.errorValidations = true;
        console.log(e);
      }
    )
  }

  deleteValidation(validationId) {
    if (confirm("Are you sure you want to delete this validation? This action cannot be undone.")) {
      this.validationService.deleteValidation(validationId).subscribe(
        r => {
          this.validations = this.validations.filter(v => v.validationId !- validationId);
        },
        e => {
          console.log(e);
        }
      )
    }
  }

  setReportParameters(event) {
    this.reportParameters = event.reportPar;
    this.storedProcedure = event.storedPro;
  }

  ngAfterViewChecked() {
    if (this.download && this.activeAdmin) {
      document.querySelector('angular2csv > button').innerHTML = '<i class="far fa-download"></i> Download (.csv)';
    }
  }

  setDownload(event) {
    this.download = event;
  }

  navProjectDetail(projectId: number) {
    this.router.navigate(['/project-detail', projectId]);
  }

  navDashboard(projectId: number) {
    this.router.navigate(['/dashboard', projectId]);
  }

  getSearchTiers(projectId) {
    this.memberService.getSearchTiers(projectId).subscribe(
      r => {
        this.searchableTiers = this.formatSearchTiers(r.data.leafTiers);
      },
      e => {
        console.log(e);
      }
    )
  }

  formatSearchTiers(tiers) {
    tiers.forEach(t => {
      t.reportNameFormatted = this.placeholder(t.reportName, t.reportYear);
    });
    return tiers;
  }

  openSearch(tier) {
    this.optionParam = tier.denominatorId;
    // Reset view
    this.tierState = false;
    // Build search node
    let node = { id: tier.tierId, projectId: this.projectId }
    // Select node and load report
    this.onNodeSelection(node, tier.denominatorId);
    // Reload tier structure
    this.getParamTiers(node.id, true);
    // Remove search text
    this.searchText = null;
  }

  placeholder(text, year) {
    if (text == null) { return text; }

    if (text.indexOf("|SINGLE_YEAR_CURRENT|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_CURRENT\|\*/g, year.toString());
    }
    if (text.indexOf("|SINGLE_YEAR_NEXT|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_NEXT\|\*/g, (year + 1).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_PREVIOUS|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_PREVIOUS\|\*/g, (year - 1).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_MINUS_2|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_MINUS_2\|\*/g, (year - 2).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_MINUS_3|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_MINUS_3\|\*/g, (year - 3).toString());
    }
    if (text.indexOf("|DOUBLE_YEAR_NEXT|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_NEXT\|\*/g, (year).toString() + "/" + (year + 1).toString().substring(2, 4));
    } 
    if (text.indexOf("|DOUBLE_YEAR_CURRENT|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_CURRENT\|\*/g, (year - 1).toString() + "/" + (year).toString().substring(2, 4));
    }    
    if (text.indexOf("|DOUBLE_YEAR_PREVIOUS|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_PREVIOUS\|\*/g, (year - 2).toString() + "/" + (year - 1).toString().substring(2, 4));
    }
    if (text.indexOf("|DOUBLE_YEAR_MINUS_2|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_MINUS_2\|\*/g, (year - 3).toString() + "/" + (year - 2).toString().substring(2, 4));
    }
    if (text.indexOf("|DOUBLE_YEAR_MINUS_3|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_MINUS_3\|\*/g, (year - 4).toString() + "/" + (year - 3).toString().substring(2, 4));
    }
    return text;
  }

}