import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProjectOverviewComponent } from './project-overview.component';
import { ProjectOverviewRoutingModule } from './project-overview-routing.module';

import { ToastrModule } from 'ngx-toastr';
import { ResponsiveModule } from 'ngx-responsive';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { Angular2CsvModule } from 'angular2-csv';

import { SafePipeModule } from '../utilities/pipes/safe-pipe.module';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SafePipeModule,
    ResponsiveModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    ToastrModule.forRoot(),
    ProjectOverviewRoutingModule,
    Angular2CsvModule,
    QRCodeModule
  ],
  declarations: [
    ProjectOverviewComponent
  ]
})
export class ProjectOverviewModule {}