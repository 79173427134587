import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectRegistrationComponent } from './project-registration.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const projectregistrationRoutes: Routes = [
  { path: 'project-registration/:projectId/:organisationId', component: ProjectRegistrationComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(projectregistrationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProjectRegistrationRoutingModule { }