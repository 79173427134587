import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { EventRegistrationNonMember } from "../classes/event/event-registration-non-member";
import { Observable } from "rxjs";

@Injectable()
export class NonMemberService {
  private URL: string = environment.apiURL;

  constructor(private http: HttpClient) {}


  public RegisterEvent(EventDetails: EventRegistrationNonMember) {
    return this.http.post(
      this.URL + "/unauthenticated/nonMemberEventReg",
      JSON.stringify(EventDetails),
      {
        headers: new HttpHeaders()
          .set("Content-Type", "application/json"),
      }
    );
  }

  public getEventsList(): Observable<any> {
    return this.http.get(this.URL + "/unauthenticated/getFutureEvents");  
  }
}
