<div class="container-fluid unauth-page center-content">
  <!-- Login view -->
  <div class="row">
    <!-- Members' Area Login section -->
    <div class="col-lg-4 col-lg-offset-2 col-md-6 col-sm-12 col-xs-12 large-padding" *ngIf="showLoginScreen" >
      <div class="login-header-banner">
        <img src="../assets/images/nhsbn-logo-colour-svg.svg" alt="NHSBN Banner" />
      </div>
      <div class="login-card panel-login">
        <div class="panel-body">
          <h1>Members' Area Login</h1>
          <p class="grey-text">Use your credentials below</p>
          <div class="alert alert-danger mt-2" *ngIf="devMode">
            <strong>Development Site:</strong> 
            This site is being used for the development of new projects.
            <a href="https://members.nhsbenchmarking.nhs.uk" class="link">
              Please click here to login to the live Members' Area.</a>
          </div>
          <form [formGroup]="signIn" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label class="control-label">Email Address</label>
              <input class="form-control form-fix" type="email" formControlName="emailAddress" id="txtEmail" />
            </div>
            <div class="form-group">
              <label class="control-label">Password</label>
              <input class="form-control form-fix" type="password" formControlName="password" id="txtPassword" />
            </div>
            <div class="form-group">
              <div *ngIf="signInFormErrors.emailAddress" class="alert alert-danger" >
                <p>
                  {{ signInFormErrors.emailAddress }}
                </p>
              </div>
            </div>
            <div class="form-group">
              <div *ngIf="errorMessage" [innerHtml]="errorMessage" class="alert alert-danger"></div>
            </div>
            <button type="submit" class="btn btn-lg btn-success btn-nhs-blue" [disabled]="loggingIn">
              <span *ngIf="!loggingIn">Login</span>
              <span *ngIf="loggingIn">
                <i class="fas fa-spinner fa-spin"></i>
                &nbsp;Logging in...
              </span>
            </button>
            <div class="link">
              <a (click)="navResetPassword()">Forgotten Password?</a>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Request login search section -->
    <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 request-top-margin" *ngIf="showLoginScreen" >
      <h3>Request a Login</h3>
      <p>
        <b>
          Your organisation must have an active membership to the Network to
          request a login.
        </b>
      </p>
      <div class="panel-login">
        <form [formGroup]="loginRequestForm" (ngSubmit)="loginRequest()" novalidate >
          <div class="form-group search-container">
            <input type="text" class="form-control form-fix-white" placeholder="Organisation name..." formControlName="organisationName" (blur)="closeDropDown()" (click)="showDropdown()"/>
            <div *ngIf="loginRequestForm.get('organisationName').value && arrMembers">
              <div class="search-dropdown menu" [style.display]="isVisible ? 'block' : 'none'">
                <div *ngFor="let member of arrMembers | filterBy: ['organisationName']: loginRequestForm.get('organisationName').value">
                  <strong class="font-blue" (click)="alreadyMember(member)">
                    {{ member.organisationName }} <br />
                    <span class="font-lightblue"
                      >Request a login for this organisation
                      <i class="fas fa-caret-right"></i>
                    </span>
                  </strong>
                </div>
                <div>
                  <strong class="faded-list-result">
                    <a class="faded-list-result" href="https://www.nhsbenchmarking.nhs.uk/contact">
                      My organisation does not appear in the list <br />
                      <span>
                        Click here to contact us about membership
                        <i class="fas fa-caret-right"></i>
                      </span>
                    </a>
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <p>
        If you do not have a login for the Members' Area, use the search box
        above to check if your organisation has an active membership. <br />You
        will be redirected to create a user profile or to our Contact Us page
        where you can request a membership pack.
      </p>
    </div>
  </div>
  <!-- Request login view -->
  <div class="row">
    <div class="col-lg-4 col-lg-offset-2 col-md-5 col-md-offset-1 large-padding" *ngIf="showRequestLoginScreen" >
      <div *ngIf="!requestSuccessful">
        <div class="login-header-banner">
          <img src="../assets/images/nhsbn-logo-colour-svg.svg" alt="NHS Banner" />
        </div>
        <div class="login-card panel-body">
          <h1>Request a Login</h1>
          <p>
            Use the following form to request a login to the Members' Area. This
            should only be completed if your organisation is a member of the
            Network.
          </p>
          <div class="alert alert-danger mt-2" *ngIf="devMode">
            <strong>Development Site:</strong> 
            This site is being used for the development of new projects.
            <a href="https://members.nhsbenchmarking.nhs.uk" class="link">
              Please click here to login to the live Members' Area.</a>
          </div>
          <form class="form-horizontal form-padding" [formGroup]="loginRequestForm" (ngSubmit)="loginRequest()" >

            <!-- Name input field -->
            <div class="form-group">
              <label [class.text-danger]="submitted && loginRequestForm.controls['name'].errors" for="name" class="control-label required">Full Name</label>
              <input type="text" id="name" class="form-control form-fix" formControlName="name" />
              <div *ngIf="submitted && loginRequestForm.controls['name'].errors" class="text-danger">Required & cannot be more then 60 characters</div>
            </div>

            <!-- Email input field -->
            <div class="form-group">
              <label [class.text-danger]="submitted && loginRequestForm.controls['email'].errors" for="email" class="control-label required">Email Address
              </label>
              <input type="text" id="email" class="form-control form-fix" formControlName="email" />
              <div *ngIf="submitted && loginRequestForm.controls['email'].errors" class="text-danger">Email is required</div>
            </div>

            <!-- Job Title input field -->
            <div class="form-group">
              <label [class.text-danger]="submitted && loginRequestForm.controls['jobTitle'].errors" for="jobTitle" class="control-label required"> Job Title </label>
              <input type="text" id="jobTitle" class="form-control form-fix" formControlName="jobTitle" />
              <div *ngIf="submitted && loginRequestForm.controls['jobTitle'].errors" class="text-danger">Required & cannot be more then 60 characters</div>
            </div>

            <!-- Organisation input field -->
            <div class="form-group has-success has-feedback">
              <label>Organisation</label>
              <input type="text" class="form-control form-fix" id="inputSuccess5" aria-describedby="inputSuccess5Status" placeholder="{{ organisationPickedByUser }}" disabled />
              <span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span>
              <span id="inputSuccess5Status" class="sr-only">(success)</span>
            </div>

            <div class="form-group">
              <label for="checkboxes" class="control-label">
                Projects
              </label>
              <p class="faded-text">
                Please select the projects for which you require access
              </p>
              <div class="form-check">
                <span class="checkboxes" formArrayName="checkboxes" *ngFor="let genre of loginRequestForm.controls['checkboxes'].controls; let i = index">
                  <input style="cursor: pointer;" type="checkbox" [formControlName]="i">
                  <span style="padding-left: 5px;">{{ products[i].productName }}</span>
                </span>
              </div>
              <div *ngIf="submitted && checkboxSelected()" class="text-danger">Selection of one or more is required</div>
            </div>

            <div class="row">
              <hr />
            </div>
            <div class="form-group form-check">
              <div style="display: flex; flex-direction: column;">
                <div style="display: flex;">
                <input style="cursor: pointer;" type="checkbox" formControlName="globalSubscribe" id="globalSubscribe" class="form-check-input" [ngClass]="{ 'is-invalid': submitted }" />
                <span style="padding-left: 5px; font-weight: 600; padding-top: 5px" for="globalSubscribe">I would like to receive marketing communications
                </span>
              </div>
              <div style="display: flex;">
                <input style="cursor: pointer;" type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted }" />
                <span style="padding-left: 5px; font-weight: 600; padding-top: 5px" for="acceptTerms">I have read and agreed to the 
                  <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Other/2023/NHSBN%20Terms%20of%20Membership%20from%20September%202023%20-%20FINAL2.pdf" target="_blank">
                    Terms & Conditions
                  </a>
                </span>
              </div>
              </div>
              <div *ngIf="submitted && loginRequestForm.controls['acceptTerms'].errors" class="text-danger">Accepting the Ts & Cs is required</div>
            </div>

            <div class="center-texts">
              <button type="submit" class="btn btn-lg btn-success btn-nhs-blue btn-promise" [disabled]="isSubmitting" [promiseBtn]="newSpinner">
                Submit
              </button>
              <b class="link-spacing cursor-pointer">
                <a (click)="backToLogin()">
                  <i class="fas fa-caret-left"></i>
                  &nbsp;Back to login
                </a>
              </b>
            </div>
          </form>
        </div>
      </div>

      <!-- Request Successful Message view -->
      <div *ngIf="requestSuccessful">
        <div class="login-header-banner">
          <img src="../assets/images/nhsbn-login-banner-short.png" alt="NHSBN Banner"/>
        </div>
        <div class="login-card panel-body">
          <h1>Request Received <i class="fas fa-check"></i></h1>
          <p> You will be contacted as soon as we process your request. <br /> </p>
          <p> For more information on what we do, please visit our website:
            <a href="https://www.nhsbenchmarking.nhs.uk/">NHS Benchmarking Network</a>
          </p>
          <p class="another-login">
            <a style="cursor: pointer; color: #768692" (click)="anotherRequest()">
              Submit another for a colleague <i class="fas fa-caret-right"></i>
            </a>
          </p>
        </div>
      </div>
    </div>

    <!-- Request Successful view -->
    <div class="col-lg-4 col-md-4 center-text" *ngIf="showRequestLoginScreen">

      <!-- Network Projects info -->
      <div class="panel-bodys">
        <div class="img-name">
          <div class="logo-fit">
            <img src="../assets/images/nhsbn-mark.png" alt="Network" />
          </div>
          <h5>Network Projects</h5>
        </div>
        <p class="small-text">
          The Network projects cover all acute, community, mental health and
          commissioning sector projects. You will receive access to a library of
          resources, including online toolkits, summary reports, and good
          practice case studies, plus so much more. To support networking,
          register to attend one of our upcoming events, or contribute to the
          Forum to share ideas and practices. For more information, please
          contact
          <a href="mailto: enquiries@nhsbenchmarking.nhs.uk">enquiries@nhsbenchmarking.nhs.uk</a>
        </p>
      </div>

      <!-- NACEL info -->
      <div class="panel-bodys">
        <div class="img-name">
        <div class="logo-fit">
          <img src="../assets/images/NACEL.png" alt="NACEL" />
        </div>
        <h5>NACEL</h5>
      </div>
        <p class="small-text">
          The National Audit of Care at the End of Life (NACEL) is a national
          comparative audit of the quality and outcomes of care experienced by
          the dying person and those important to them during the last admission
          leading to death in acute hospitals, community hospitals and mental
          health inpatient providers in England, Wales, and Northen Ireland. For
          further information on the audit, please contact the Support Team on
          <a href="mailto: enquiries@nhsbenchmarking.nhs.uk">nhsbn.nacelsupport@nhs.net</a>
        </p>
      </div>

      <!-- NHSE & NHSI info -->
      <div class="panel-bodys">
        <div class="img-name">
        <div class="logo-fit">
          <img src="../assets/images/NHSE&NHSI-LearningDisabilities.png" alt="NHSE"/>
        </div>
        <h5>NHSE & NHSI Learning Disability Improvement Standards</h5>
      </div>
        <p class="small-text">
          The Learning Disabilities Improvement Standard review is a national
          data collection, commissioned by NHS England & NHS Improvement. It is
          designed to fully understand the extent of Trust compliance with the
          published Improvement Standards, and to identify improvement
          oppurtunities. For further information on the review, please contact
          the Support Team on
          <a href="mailto: nhsbn.nhsildsupport@nhs.net">nhsbn.nhsildsupport@nhs.net</a>
        </p>
      </div>

      <!-- SDEC info -->
      <div class="panel-bodys">
        <h5>Same Day Emergency Care (SDEC)</h5>
        <p class="small-text">
          This is a collaboration between the NHS Benchmarking Network and NHS 
          England/ NHS Improvement (NHSE/I) and is open to all English Acute Trusts. 
        </p>
      </div>
    </div>
  </div>
</div>
