import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';

import { OutputsValidationComponent } from './outputs-validation.component';
import { OutputsValidationRoutingModule } from './outputs-validation-routing.module';
import { OutputValidationService } from './outputs-validation.service';

import { NgPipesModule } from 'ngx-pipes';
import { Ng2HighchartsModule } from 'ng2-highcharts';
import { CsvDownloadModule } from 'app/shared/csv-download/csv-download.module';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    OutputsValidationRoutingModule,
    NgPipesModule,
    Ng2HighchartsModule,
    CsvDownloadModule,
    TooltipModule
  ],
  providers: [
    OutputValidationService
  ],
  declarations: [ 
    OutputsValidationComponent
  ]
})
export class OutputsValidationModule {}