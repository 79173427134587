import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ResetPasswordComponent } from './reset-password.component'
import { ResetPasswordConfirmComponent } from './reset-password-confirm/reset-password-confirm.component'

const resetpasswordRoutes: Routes = [
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset/:resetCode', component: ResetPasswordConfirmComponent },
];
@NgModule({
  imports: [
    RouterModule.forChild(resetpasswordRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ResetPasswordRoutingModule { }