import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SafePipe } from '../pipes/safe.pipe';

@NgModule({
    imports: [BrowserModule],
    declarations: [SafePipe],
    exports: [SafePipe]
})
export class SafePipeModule { }