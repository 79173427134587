export class OutputsColor {
    // Only Outputs are using these global Colours
    default = ['rgba(198,43,44,1)', 'rgba(255,151,0,1)', 'rgba(157,38,176,1)', 'rgba(233,31,99,1)', 'rgba(13,181,124,1)', 'rgba(128,0,0,1)',
    'rgba(215,145,0,1)', 'rgba(0,255,0,1)', 'rgba(0,128,0,1)', 'rgba(0,255,255,1)', 'rgba(32, 29, 224)', 'rgba(204, 93, 8)', 'rgba(242, 231, 27)',
    'rgba(68, 18, 142)', 'rgba(200, 162, 214)', 'rgba(44,48,66)', 'rgba(160,32,107)', 'rgba(107, 82, 55)', 'rgba(65, 145, 117)', 'rgba(49, 117, 50)'];

    red: string = '#C62B2C'; 
    green: string = '#01C953'; 
    blue: string = '#0000D0'; 
    grey: string = "#D3D3D3"; 
    chartBlue: string = "#7cb5ec";

    pieColors = ['#005EB8', '#F75C03', '#8F2D56', '#D90368', '#F1C40F', '#A4036F', '#00A6FB', '#173753', '#FC440F', '#29335C', '#D00000', '#F18F01'];
    pieColorBlind = ['#E69F00', '#56B4E9', '#009E73', '#F0E442', '#0072B2', '#D55E00', '#CC79A7', '#671616', '#1A1667', '#A500BB', '#1100BB', '#67BB00'];
    colorBlindRed = '#DCBE27';
    colorBlindchartBlue = '#88CCEE';
    colorBlindGreen = '#117733'; 
  }

  export class ProjectColor {
    // Only Projects are using these global Colours
    colors = [
      'rgba(198,43,44,1)', 'rgba(255,151,0,1)', 'rgba(157,38,176,1)', 'rgba(233,31,99,1)', 'rgba(13,181,124,1)', 'rgba(128,0,0,1)',
      'rgba(255,165,0,1)', 'rgba(0,255,0,1)', 'rgba(0,128,0,1)', 'rgba(0,255,255,1)'
    ]
  
    subColors = [
      {color: 'rgba(198,43,44,1)', subColors: ['rgba(198,43,44,1)','rgba(198,43,44,0.5)','rgba(198,43,44,0.1)'] },
      {color: 'rgba(255,151,0,1)', subColors: ['rgba(255,151,0,1)','rgba(255,151,0,0.5)','rgba(255,151,0,0.1)'] },
      {color: 'rgba(157,38,176,1)', subColors: ['rgba(157,38,176,1)','rgba(157,38,176,0.5)','rgba(157,38,176,0.1)'] },
      {color: 'rgba(233,31,99,1)', subColors: ['rgba(233,31,99,1)','rgba(233,31,99,0.5)','rgba(233,31,99,0.1)'] },
      {color: 'rgba(13,181,124,1)', subColors: ['rgba(13,181,124,1)','rgba(13,181,124,0.5)','rgba(13,181,124,0.1)'] },
      {color: 'rgba(128,0,0,1)', subColors: ['rgba(128,0,0,1)','rgba(128,0,0,0.5)','rgba(128,0,0,0.1)'] },
      {color: 'rgba(255,165,0,1)', subColors: ['rgba(255,165,0,1)','rgba(255,165,0,0.5)','rgba(255,165,0,0.1)'] },
      {color: 'rgba(0,255,0,1)', subColors: ['rgba(0,255,0,1)','rgba(0,255,0,0.5)','rgba(0,255,0,0.1)'] },
      {color: 'rgba(0,128,0,1)', subColors: ['rgba(0,128,0,1)','rgba(0,128,0,0.5)','rgba(0,128,0,0.1)'] },
      {color: 'rgba(0,255,255,1)', subColors: ['rgba(0,255,255,1)','rgba(0,255,255,0.5)','rgba(0,255,255,0.1)'] }
    ]
  
    setColour = ['#003087', '#005EB8', '#0072CE', '#41B6E6', '#00A9CE', '#231f20', '#425563', '#768692'];
    primaryBarColour = '#003087';
    secondaryBarColour = '#005EB8';
    nationalMeanColour = '#009639';
    nationalMedianColour = '#78BE20';
    colourCollection = ['#003087', '#005EB8', '#0072CE', '#41B6E6', '#00A9CE', '#231f20', '#425563', '#768692'];
    colourBlindCollection = ['#0072B2', '#009E73', '#E69F00', '#CC79A7', '#D55E00', '#F0E442', '#56B4E9', '#768692']; 
  }