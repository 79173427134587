export class helpVideos {
  static videoBaseUrl = "https://www.youtube.com/embed/";
  static videos: Array<object> = [
    {
      videoURL: helpVideos.videoBaseUrl + "Q6oiiaSu88Q",
      title: "Community Services monthly dashboard",
      info: `This tutorial video will lead you through the dashboard, 
      covering both the toolkit and report. The video demonstrates 
      each of the features, including downloadable content, selectors 
      and search functions. This dashboard aims to track the impact of 
      the Covid-19 pandemic on community services provision across the UK.`,
    },
    {
      videoURL: helpVideos.videoBaseUrl + "JADuwMw7JeM",
      title: "Mental Health & Learning Disabilities monthly dashboard",
      info: `This tutorial video explores the dashboard toolkit, 
      highlighting Mental Health and CYPMH metrics. The video 
      demonstrates many of the features, including accessing 
      the metrics, selecting a service, and time series insights. 
      This dashboard aims to support Mental Health providers to
      track the impact of Covid-19 on core service provision,
      along with the recovery process and digital service provision.`,
    },
    {
      videoURL: helpVideos.videoBaseUrl + "2PRxxe95tvk",
      title: "Project toolkit: Pharmacy",
      info: `This tutorial video demonstrates the main features 
      of the project toolkit. We explore the search function, 
      tree drop down, and multiple data views, using the Pharmacy 
      project as an example. All online project toolkits follow a 
      consistent format, so you can apply the tutorial instructions 
      to the entire range of toolkits on the members’ area.`,
    },
    {
      videoURL: helpVideos.videoBaseUrl + "Tktw9-LvlM8",
      title: "ICS Benchmarker toolkit",
      info: `This tutorial video will lead you through the 
      main features and components of the ICS Benchmarker, 
      which has been developed to support local planning, 
      service improvement, and system development. Divided 
      into Domains, each report includes an interactive map, 
      bar chart, data table and time series chart for you to 
      utilise at executive or board level meetings. Discover 
      your position against nearest neighbours, aggregate up 
      to ICS from local authority level, and drill down 
      into categories to extract optimal insights.`,
    },
    {
      videoURL: helpVideos.videoBaseUrl + "VhQqQsIbtSs ",
      title: "Members' Area",
      info: `This tutorial video will provide you with an overview 
      of the NHS Benchmarking Network's members' area. <br> 
      It highlights key features and outlines the 
      resources available to members.`,
    },
  ];
  static getVideos() {
    return this.videos;
  }
}
