import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MemberService } from 'app/utilities/services/member.service';
import { ModalDirective } from 'ngx-bootstrap';
import { OrderByPipe, UniquePipe } from 'ngx-pipes';

@Component({
  selector: 'app-document-panel',
  templateUrl: './document-panel.component.html',
  styleUrls: ['./document-panel.component.scss'],
  providers: [ UniquePipe, OrderByPipe ]
})
export class DocumentPanelComponent implements OnChanges {

  @Input() public projectName: string;
  @Input() public documents: any;
  @Input() public openDocumentModal?: boolean;

  @ViewChild('documentModal') public documentModal:ModalDirective;

  public rawDocuments: any;
  public selectedYear: string;
  public filters: any;

  constructor(
    private memberService: MemberService,
    public unique: UniquePipe,
    public orderBy: OrderByPipe
  ) { }

  ngOnChanges() {
    if (this.documents) {
      this.rawDocuments = JSON.parse(JSON.stringify(this.documents));
      this.selectedYear = Object.keys(this.documents)[0];
      this.createFilters(this.documents);
      if (this.openDocumentModal == true) {
        setTimeout(() => { this.documentModal.show(); }, 1000);
      }      
    }
  }

  public createFilters(documents: any): void {
    // Create filters
    let submissionOptions = this.unique.transform(documents[this.selectedYear].map(doc => doc.submissionName));
    let serviceItemOptions = this.unique.transform(documents[this.selectedYear].map(doc => doc.serviceItemName));
    this.filters = [
      { key: 'submissionName', name: 'submission', orderBy: 'submissionName', options: this.orderBy.transform(submissionOptions) },
      { key: 'serviceItemName', name: 'service', orderBy: 'serviceItemName', options: this.orderBy.transform(serviceItemOptions) },
    ]
    // Remove nulls from options
    this.filters.forEach(filter => {
      filter.options = filter.options.filter(f => f !== null);
    });
  }

  public selectFilter(filter: any, option: string): void {
    this.documents[this.selectedYear] = this.rawDocuments[this.selectedYear].filter(doc => doc[filter.key] == option);
  }

  public clearFilters(): void {
    this.documents[this.selectedYear] = this.rawDocuments[this.selectedYear];
  }

  public getSelectedDocument(documentId: number): void {
    this.memberService.AWSSelectedDocument(documentId).subscribe(
      doc => { window.open(doc.data.documentUrl) }
    );
  }

}
