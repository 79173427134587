import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent implements OnChanges {
 
  @Input() data: any;
  @Input() title: string;
  public connectWithMemberInfo: boolean = false;
  public extraInfoToggler: boolean = false;

  constructor() {}

  ngOnChanges() {}

}
