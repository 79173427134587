import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { Meta, Title } from '@angular/platform-browser';
import { IcsService } from 'app/utilities/services/ics.service';

// Import pipes
import { GroupByPipe, OrderByPipe, ChunkPipe, SumPipe } from 'ngx-pipes';
import { DataService } from 'app/utilities/services/data.service';
import { ProjectColor } from '_variables';
import { DynamicInfoPanel } from 'app/utilities/classes/dynamic-info-panel/dynamic-info-panel';
import { CsvDownload } from 'app/utilities/classes/csv/csv-download';

import html2canvas from 'html2canvas'; 
import { ModalDirective } from 'ngx-bootstrap';
import { MemberService } from 'app/utilities/services/member.service';
import { ProjectDocument } from 'app/utilities/classes/project/project-document';
import * as moment from 'moment';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  projectId: number;
  projectName: string;
  projectDetails;
  introDashboard: string;
  projectDates;
  activeAdmin: boolean = false;
  projectView: string;
  submissionVisible: boolean = true;
  subscriptions = [];
  error: string; errorReport: string; errorMetrics: string; errorReportMetrics: string;
  promo: boolean = false;
  hideTable = true;
  openSubmissionTables = [];

  copyrightYear: number = new Date().getFullYear();
  infoPanel: DynamicInfoPanel;
  metrics;
  showSubmission: boolean = true;
  metricsLoading: boolean = false;
  chartCount: number;

  tiers;
  searchableTiers;
  tierOpen: boolean = false;
  selectedTierName; // -- Test
  selectedTiers = [
    { level: 0, levelName: 'Domain', levelCode: 'a', selected: null, tierName: null, childrenTiers: null, open: false },
    { level: 1, levelName: 'Sector', levelCode: 'b', selected: null, tierName: null, childrenTiers: null, open: false, report: null },
    { level: 2, levelName: 'Primary', levelCode: 'c', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 3, levelName: 'Secondary', levelCode: 'd', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 4, levelName: 'Permissions', levelCode: 'e', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 5, levelName: 'Search', levelCode: 'f', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 6, levelName: 'Dates', levelCode: 'g', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 7, levelName: 'Categories', levelCode: 'h', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 8, levelName: 'Organisations', levelCode: 'i', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 9, levelName: 'Date Picker', levelCode: 'j', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 10, levelName: 'Submissions', levelCode: 'k', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 11, levelName: 'Downloads', levelCode: 'l', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 12, levelName: 'New Tiers', levelCode: 'm', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 13, levelName: 'Options', levelCode: 'n', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 14, levelName: 'Dashboard Options', levelCode: 'o', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
    { level: 15, levelName: 'Report Groups', levelCode: 'q', selected: null, tierName: null, childrenTiers: null, open: false, report: null},
  ];
  options; selectedOption;

  searchText;
  
  organisations; 
  submissions;
  submissionData = [];
  submissionName;

  reportLoading: boolean = false;
  timeseriesAvailable: boolean = true;
  reportDetails; reportSettings;
  reportType;
  reportGroups: any;
  reportAllCategories; reportCategories; reportCategoryTypes;
  averages; categoryAverages; averagesTable;

  reportData;
  monthData; monthAverage;
  tableData; tableHeader;

  selectedProjectDate; selectedDate;
  selectedCategoryType;
  selectedOrganisation;
  savedOrganisation;
  selectedSubmission;
  selectedReportGroup;

  primaryChartOptions; secondaryChartOptions; chartOptions;
  primaryChart: any; secondaryChart: any; legend = [];

  params: string;
  
  csvSettings: CsvDownload;
  getTableData: boolean;
  getAverages: boolean;
  getDenNumTable: boolean;

  reportId: number;
  anonymousEnabled: boolean = false;

  // Setting global colors
  globalColors = new ProjectColor();
  colors = this.globalColors.colors;
  subColors = this.globalColors.subColors;
  setColour = this.globalColors.setColour;
  primaryBarColour = this.globalColors.primaryBarColour;
  secondaryBarColour = this.globalColors.secondaryBarColour;
  nationalMeanColour = this.globalColors.nationalMeanColour;
  nationalMedianColour = this.globalColors.nationalMedianColour;
  colourCollection = this.globalColors.colourCollection;
  colourBlindCollection = this.globalColors.colourBlindCollection;

  objectKeys = Object.keys;

  // Report
  participants;
  reportMetrics = [];
  reportMetricsLoading: boolean = false;
  reportSections; reportSectionsLength;

  // Extract
  extractOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    useBom: false,
    removeNewLines: true,
    headers: ['Date', 'Code', 'Group', 'Metric', '% completion (in month)', 'Benchmark', 'Group 6-month average', 'Submission value', 'Change from previous month', '% change from previous month'],
    keys: ['date', 'code', 'aName', 'reportName', 'percentageCompletion', 'benchmark', 'average6', 'organisationResponse', 'monthStatus', 'monthDiff']
  };

  // Colourblindness
  colorBlindnessEnabled: boolean = false;

  // Export fullscreen
  @ViewChild('fullscreen') fullscreen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  // Mass Export
  @ViewChild('reportDownloadView') reportDownloadView: ElementRef;
  @ViewChild('reportCanvas') reportCanvas: ElementRef;
  @ViewChild('downloadReportLink') downloadReportLink: ElementRef;
  @ViewChild('selectReportModal') public selectReportModal: ModalDirective;
  showReportModal: boolean = false;
  massDownloadView: boolean = false;
  stopMassDownload: boolean = false;
  startMassDownload: boolean = false;
  selectedPeriod: string;
  selected: Array<any> = [];
  openTiers: boolean = false;
  selectedStatus: boolean;

  // Supplementary data
  supplementaryData: any = null;
  calculatedNationalMeansAvailable: boolean;

  // Report Download
  dynamicDocuments: Array<ProjectDocument> = [];
  dynamicDocumentsDropdown: boolean = false;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private dataService: DataService,
    private icsService: IcsService,
    private memberService: MemberService,
    private group: GroupByPipe,
    private order: OrderByPipe,
    private sum: SumPipe,
    private chunk: ChunkPipe,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.projectId = +this.route.snapshot.paramMap.get('projectId');
    this.projectView = this.route.snapshot.paramMap.get('view');
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getTiers(this.projectId);
    this.params = this.router.url;
  }

  public onInfoPanelClick = (info: string): void => {
    this.toggleTier(4);
  }

  // GET

  getSubmissions(projectId, organisation?) {
    this.icsService.submissions(projectId).subscribe(
      r => {

        // Set project name
        this.projectName = r.data.projectDetails.projectName;
        this.projectDetails = r.data.projectDetails;

        // Set admin user
        let user = JSON.parse(sessionStorage.getItem('user'));
        this.activeAdmin = user && user.isAdmin && user.isAdmin === 'Y' ? true : false;

        // Get available organisations
        this.organisations = r.data.userOrganisations;

        // Get saved organisation
        let savedOrganisation = JSON.parse(sessionStorage.getItem('organisationId'));
        this.savedOrganisation = savedOrganisation;

        // Set selected organisation
        let selectedOrganisation;
        if (organisation) {
          selectedOrganisation = organisation;
        } else if (!organisation && !savedOrganisation) {
          selectedOrganisation = '';
        } else if (savedOrganisation) {
          selectedOrganisation = this.organisations.filter(o => o.externalOrganisationId === +savedOrganisation)[0];
        } else {
          if (this.organisations.length > 0) {
            selectedOrganisation = this.organisations[0];
          } else {
            selectedOrganisation = null;
          }
        }
        this.selectedOrganisation = selectedOrganisation;

        // Save organisationId, if changed
        if (organisation && organisation.externalOrganisationId) {
          this.dataService.setSelectedOrganisation(organisation.externalOrganisationId);
        }

        // Check for permissions then set submissions
        if (this.selectedOrganisation && this.selectedOrganisation.submissionList) {
          this.submissions = this.formatSubmissions(this.selectedOrganisation);
          this.selectedSubmission = this.submissions[0];
        }

        // Set project dates
        if (this.projectId == 52) {
          // For SE MH, ignore selected project date and reset
          this.projectDates = this.formatDates(r.data.projectDates);
          this.selectedProjectDate = this.projectDates[0];
          this.selectedPeriod = this.selectedProjectDate.dateName

          this.setView(this.projectView);
        } else {
          if (r.data.projectDates.length) {
            if (!this.selectedProjectDate) {
              this.projectDates = this.formatDates(r.data.projectDates);
              this.selectedProjectDate = this.projectDates[0];
              this.setView(this.projectView);
            } else {
              // Set view
              this.setView(this.projectView);
            }
          } else {
            this.error = "There are no active dates set for this project."
          }
        }

      },
      e => {
        console.log(e);
        this.error = e.error.message;
      }
    )
  }

  getReportGroups(projectId) {
    this.icsService.reportGroups(projectId, 'Dashboard').subscribe(
      r => {
        this.reportGroups = r.data.reportGroups;
        this.selectedReportGroup = this.reportGroups[0];
        this.getDashboard(
          this.searchableTiers, 
          this.projectId, 
          this.selectedSubmission ? this.selectedSubmission.submissionId : null, 
          this.selectedProjectDate,
          this.selectedReportGroup ? this.selectedReportGroup.reportGroupName : null
        );
      },
      e => {
        console.log(e);
      }
    )
  }

  getDashboard(tiers, projectId, submissionId, selectedProjectDate, reportGroupName?) {
    this.metricsLoading = true;
    this.subscriptions.push(
      this.icsService.dashboard(projectId, submissionId, selectedProjectDate.dateId, reportGroupName).subscribe(
        r => {
          let metrics = this.formatMetrics(tiers, r.data.metricList, selectedProjectDate);
          if (metrics.length) {
            this.metrics = metrics;
          } else {
            this.errorMetrics = "There is no data for this dashboard in the selected period."
          }
        },
        e => {
          console.log(e);
          this.errorMetrics = "There has been an error loading the metrics. Please try again by refreshing the page."
        },
        () => {
          this.metricsLoading = false;
        }
      )
    )
  }

  getReport(projectId, dateId, organisation) {
    this.reportMetricsLoading = true;
    this.subscriptions.push(
      this.icsService.report(projectId, dateId, organisation ? organisation.organisationId : null).subscribe(
        r => {
          let metricList = r.data.metricList;
          if (metricList.length) {
            let reportSections = this.formatReportMetrics(metricList, organisation);
            this.reportSections = this.chunk.transform(reportSections, 2);
            this.reportSectionsLength = this.reportSections.length;
            this.reportMetrics = []; 
            this.reportSections.forEach((r, i) => {
              r.forEach(rr => {
                this.reportMetrics.push({
                  reportName: rr.reportName,
                  pageNumber: i + 4
                });
              });
            });
          } else {
            this.errorReportMetrics = 'There are no metrics for the selected date. Click "Dashboard" to go back and select a different date.'
          }
        },
        e => {
          console.log(e);
        },
        () => {
          this.reportMetricsLoading = false;
          this.chartCount = this.reportMetrics.length;
        }
      )
    )
  }

  getParticipants(projectId, dateId) {
    if (projectId == 29) {
      this.icsService.participantSubmissions(projectId, dateId).subscribe(
        r => {
          this.participants = r.data.participantList;
        },
        e => {
          console.log(e);
        }
      )
    } else {
      this.icsService.participants(projectId, dateId).subscribe(
        r => {
          this.participants = r.data.participantList;
        },
        e => {
          console.log(e);
        }
      )
    }
  }

  massDownloadStarted(reportId, tierName, projectId) {
    return new Promise(resolve => {
      setTimeout(() => {
        this.getSubmissions(projectId);
        this.selectedTierName = tierName;
        this.getReportDetails(reportId);

        setTimeout(() => {
          this.downloadReportImage();
          resolve('resolved');
        }, 1000);
        resolve('resolved');
      }, 5000);
    });
  }

  tierCheck(tier, parent) {
    if(tier.selected == true) {
      tier.selected = false;
      this.selected = this.selected.filter(item => item.reportId != tier.reportId)
    } else {
      tier.selected = true;
      this.selected.push({tier: tier.tierName, tierId: tier.tierId, parent: parent, reportId: tier.reportId});
    }
  }

  startDownload() {
    this.changeView('toolkit');
    this.startMassDownload = true;
    this.stopMassDownload = false;
    this.getTiers(this.projectId, this.selected);
    this.showReportModal = true;
  }

  massDownloadTiers(selected, openTiers?) {
    this.selectedStatus = selected;
    this.openTiers = openTiers;
    this.tiers.forEach(t1 => {
        t1.childrenOpen = openTiers;
      if (t1.childrenTiers && t1.childrenTiers.length) {
        t1.childrenTiers.forEach(t2 => {
            t2.childrenOpen = openTiers;
          if (t2.childrenTiers && t2.childrenTiers.length) {
            t2.childrenTiers.forEach(t3 => {
                t3.childrenOpen = openTiers;
              if(t3.childrenTiers.length == 0) {
                if(selected != null) {
                  t3.selected = selected;
                }
                if(selected) {
                  this.selected.push({tier: t3.tierName, parent: t2.tierName, reportId: t3.reportId});
                }
              }
              if (t3.childrenTiers && t3.childrenTiers.length) {
                t3.childrenTiers.forEach(t4 => {
                    t4.childrenOpen = openTiers;
                    if(selected != null) {
                      t4.selected = selected;
                    }
                    if(selected) {
                      this.selected.push({tier: t4.tierName, parent: t3.tierName, reportId: t4.reportId});
                    }
                });
              }
            });
          }
        });
      }
    });
  }

  async getTiers(projectId: number, selectedTiers?: any) {
    this.icsService.getTiers(projectId).subscribe(
      async r => {
        this.tiers = r.data;
        if(this.startMassDownload) {
          this.tiers = selectedTiers;
          for(let tier of selectedTiers) {
            if(this.stopMassDownload) {
              this.massDownloadView = false;
              break;
            } else {
              this.massDownloadView = true;
              await this.massDownloadStarted(tier.reportId, tier.tier, projectId);
            }
          }

          setTimeout(() => {
            this.massDownloadView = false;
            this.stopMassDownload = true;
            window.location.reload();
          }, 6000);
        }
        if (r.data !== null) {
          this.searchableTiers = this.formatTiers(r.data);
        } else {
          this.error = 'There are no tiers or reports defined for this project.'
        }

        if (this.searchableTiers) {
          this.getSubmissions(projectId);
        } else {
          this.error = 'There are no tiers or reports defined for this project.'
        }

      },
      e => {
        if (e.error.message.includes('outputs access')) { 
          this.promo = true;
        } else {
          console.log(e);
          this.promo = false;
          this.error = e.error.message;
        }
      }
    )
  }

  async getReportDetails(reportId: number) {
    // Stop all API calls and reset
    this.reportId = reportId;
    this.unsubscribe();
    this.reportLoading = true;

    this.subscriptions.push(
      this.icsService.reportDetails(reportId).subscribe(
        r => {
          this.reportDetails = r.data;
          this.infoPanel = new DynamicInfoPanel(this.reportDetails)
          this.infoPanel.admin = this.activeAdmin;
          
          // Time series available
          if (r.data.timeseriesAvailable == 'Y') {
            this.timeseriesAvailable = true;
          } else {
            this.timeseriesAvailable = false;
          }

          // Set categories
          this.reportAllCategories = this.formatCategories(r.data.categories);

          if (this.reportAllCategories.categoryTypes.length > 1) {
            this.reportCategoryTypes = this.reportAllCategories.categoryTypes;
            this.selectedCategoryType = this.reportAllCategories.categoryTypes[0];
            this.reportCategories = this.reportAllCategories.categories.filter(c => c.categoryType === this.selectedCategoryType);
          } else {
            this.reportCategories = this.reportAllCategories.categories;
          }

          // Report settings
          this.reportSettings = {
            decPlaces: r.data.decPlaces,
            maxDecPlaces: r.data.decPlaces !== null ? '1.0-' + r.data.decPlaces : '1.2-2',
            formatModifier: r.data.formatModifier,
            tickInterval: r.data.rangeMax === 100 ? 10 : null,
            min: r.data.rangeMin,
            max: r.data.rangeMax
          }

          // Temporarily hide Oct20 from CI toolkit for three metrics
          let hideOct = ['QR3a', 'QR3b', 'QR3c'];
          if (hideOct.some(ho => this.selectedTierName.includes(ho))) {
            this.projectDates.forEach(pd => { if (pd.dateName === 'October 2020') { pd.hidden = true; } });
            this.selectedProjectDate = this.projectDates[1];
            this.reportDetails.postponed = true;
          } else {
            this.projectDates.forEach(pd => { delete pd.hidden; });
            
            // Set existing project date, if available
            let dateParam = this.route.snapshot.queryParams.date;
            if (this.projectId == 52) {
              // For SE MH, replace project dates with report dates
              this.projectDates = r.data.allDates.reverse();
              this.selectedProjectDate = this.projectDates[0];
            } else {
              if (dateParam) {
                let date = this.projectDates.find(pd => pd.dateId == dateParam);
                this.selectedProjectDate = date ? date : this.projectDates[0];
              } else {
                this.selectedProjectDate = this.projectDates[0];
              }
            }

            this.reportDetails.postponed = false;
          }

          // Set reportTypes and switch
          const reportTypes = r.data.reportViews.map(r => r.reportViewName);
          switch(true) {
            case reportTypes.includes('Stacked bar'):
              this.reportType = 'stacked';
              this.getReportDataSeries(reportId, this.selectedProjectDate, this.selectedOrganisation);
              break;
            case reportTypes.some(r => ['Pie', 'Radar'].includes(r)):
              this.reportType = 'pie';
              this.getReportDataSeries(reportId, this.selectedProjectDate, this.selectedOrganisation); 
              break;
            case reportTypes.includes('Bar'):
              this.reportType = 'bar';
              // Make anonymous view default if organisation is NHSBN(232) for SEMH
              this.getReportData(
                reportId,
                this.selectedProjectDate, 
                this.selectedOrganisation ? this.selectedOrganisation : null,
                (this.selectedOrganisation && this.selectedOrganisation.externalOrganisationId == 232 && this.projectId == 52) ? true : false
              );
              break;
            default:
              this.error = 'Chart type has not been set.'
          }

          // Add reportId to URL
          this.router.navigate([], { 
            relativeTo: this.route, 
            queryParams: { 
              reportId: reportId
            }, 
            queryParamsHandling: 'merge'
          });

        },
        e => {
          console.log(e);
          this.error = e.error.message;
        }
      )
    )
  }

  getReportData(reportId: number, selectedDate, selectedOrganisation?, anon?: boolean) {
    this.subscriptions.push(
      this.icsService.reportDataAll(reportId, null, selectedOrganisation ? selectedOrganisation.organisationId : null).subscribe(
        r => {
          this.getSupplementaryData(reportId, r.data, selectedDate, anon);
        },
        e => {
          console.log(e);
          this.error = e.error.message;
        }
      )
    )
  }

  getReportDataSeries(reportId: number, selectedDate, selectedOrganisation?) {
    this.subscriptions.push(
      this.icsService.reportDataSeries(reportId, selectedOrganisation ? selectedOrganisation.organisationId : null).subscribe(
        r => {

          // Set national averages
          this.categoryAverages = this.formatCategoryAverages(r.data.dateCategoryAverages, r.data.dateCategoryMedians);
          this.createLineChart(this.categoryAverages);

          if (this.reportType === 'stacked') {
            this.reportData = this.new_formatStackedBarData(r.data);
            this.setData(this.reportData, selectedDate);
            this.isSubmissionsEmpty(this.submissions);
          } else {
            this.projectDates.forEach(pd => {
              this.new_formatCategoryData(pd, r.data);
            });
            this.new_setData(selectedDate, this.categoryAverages);
          }

        },
        e => {
          console.log(e);
          this.error = e.error.message;
        }
      )
    )
  }

  getSupplementaryData(reportId: number, reportData: any, selectedDate, anon) {
    this.icsService.supplementaryData(reportId).subscribe(
      r => {
        // Check for supplementary data
        this.calculatedNationalMeansAvailable = r.data.supplementaryData.length;
        // Map to match calculatedNationalAverages array
        let supplementaryData: {} = r.data.supplementaryData.reduce(function(map, obj) {
          map[obj.dateId] = obj.supplementaryValue;
          return map;
        }, {});
        // Replace calculatedNationalAverages in data end-point
        reportData.calculatedNationalAverages = supplementaryData;
        // Continue with processing data
        this.reportData = this.formatBarData(reportData, anon);
        this.setData(this.reportData, selectedDate);
        this.addSubmissiontoLineChart(this.submissions)
        this.isSubmissionsEmpty(this.submissions);
      },
      e => { 
        console.log(e);
        this.error = e.error.message;
      }
    )
  }

  setView(view) {
    this.projectView = view;
    switch(view) {
      case 'toolkit':
        let toolkitMetaTitle: string = 'Toolkit | ' + this.projectName + ' | NHS Benchmarking Network';
        this.titleService.setTitle(toolkitMetaTitle);
        this.meta.updateTag({property: 'og:title', content: toolkitMetaTitle});
        this.meta.updateTag({name: 'twitter:title', content: toolkitMetaTitle});
        // Direct link to a report
        let params = this.route.snapshot.queryParams;
        if (params) {
          this.selectParamTier(params);
        }
        break;
      case 'dashboard':
        let dashboardMetaTitle: string = 'Dashboard | ' + this.projectName + ' | NHS Benchmarking Network';
        this.titleService.setTitle(dashboardMetaTitle);
        this.meta.updateTag({property: 'og:title', content: dashboardMetaTitle});
        this.meta.updateTag({name: 'twitter:title', content: dashboardMetaTitle});
        this.getReportGroups(this.projectId);
        if(this.selectedOrganisation && this.selectedOrganisation.externalOrganisationId != undefined) {
          this.getAWSDocuments(this.projectId, this.selectedOrganisation.externalOrganisationId, moment(this.selectedProjectDate.dateTo).format('YYYY'))
        }

        break;
      case 'report':
        let reportMetaTitle: string = 'Report | ' + this.projectName + ' | NHS Benchmarking Network';
        this.titleService.setTitle(reportMetaTitle);
        this.meta.updateTag({property: 'og:title', content: reportMetaTitle});
        this.meta.updateTag({name: 'twitter:title', content: reportMetaTitle});
        // Set project date from params
        let paramDate = +this.route.snapshot.queryParams.date;
        if (paramDate) {
          this.selectedProjectDate = this.projectDates.filter(pd => pd.dateId === paramDate)[0];
        }
        if (this.selectedProjectDate) {
          this.getReport(this.projectId, this.selectedProjectDate.dateId, this.selectedOrganisation);
          this.getParticipants(this.projectId, this.selectedProjectDate.dateId);
        } else {
          this.selectedProjectDate = this.projectDates[0];
          this.errorReportMetrics = 'There are no metrics for the selected date. Click "Dashboard" to go back and select a different date.'
        }
        break;
      default:
        this.error = 'No view set.'
    }  
  }

  getAWSDocuments(projectId: number, organisationId?: number, date?: any) {
    this.dynamicDocuments = [];
    this.memberService.AWSDocuments(projectId, organisationId).subscribe(
      a => {
        if (a.data['Bespoke']) {
          for(let key in a.data['Bespoke']) {
            let reports = a.data['Bespoke'][key];
            this.dynamicDocuments = reports;
          }
        }
        if (a.data['Report']) {
          for(let key in a.data['Report']) {
            let reports = a.data['Report'][key];
            reports.forEach(item => {
              this.dynamicDocuments.push(item);
            })
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getSelectedDocument(documentId: number) {
    this.memberService.AWSSelectedDocument(documentId, this.savedOrganisation).subscribe(
      a => { window.open(a.data.documentUrl) }
    );
  }

  setData(data, selectedDate) {
    let dateCheck = data.byMonth.hasOwnProperty(selectedDate.dateName)
    if (dateCheck) {
      this.errorReport = null;
      switch(this.reportType) {
        case 'stacked':
          if (data.byMonth[selectedDate.dateName]) {
            this.createStackedBarChart(data.byMonth[selectedDate.dateName]);
          } else {
            this.errorReport = 'There is no data for this particular report. Please select another report using the menus above.'
          }
          break;
        case 'pie':
          this.monthData = {
            data: data.byCategory[selectedDate.dateName],
            categories: Object.keys(data.byMonth[selectedDate.dateName])
          }
          this.createPieChart(data.byCategory[selectedDate.dateName]);
          this.createTable(data.bySubmission, selectedDate);
          break;
        case 'bar':
          this.monthData = {
            date: selectedDate.dateName,
            data: data.byMonth[selectedDate.dateName],
            average: data.byMonthAverages.filter(d => d.name === selectedDate.dateName)[0].y
          }
          // National calculated means
          let nationalMeans = data.bySeries.find(s => s.name == 'Calculated National Mean');
          if (nationalMeans) {
            let monthNationalMean = nationalMeans.data.find(d => d.name === selectedDate.dateName);
            if (monthNationalMean) {
              this.monthData.nationalCalculatedMean = monthNationalMean.y
            }
          }
          // Reverse order (including rank) if set to ASC in database
          if (this.reportDetails.sortOrder && this.reportDetails.sortOrder.includes('ASC')) {
            this.monthData.data.reverse();
          }
          this.createBarChart(this.monthData.data, selectedDate.dateName, this.monthData.nationalCalculatedMean);
          this.createLineChart(data.bySeries);
          this.createTable(this.monthData.data, selectedDate);
          break;
        default:
          this.error = 'Chart type has not been set.'
      }
    } else {
      this.errorReport = 'There is no data for the selected period. Please select another period from the menu above.'
    }
  }

  // SELECT

  selectReportGroup(group) {
    this.errorMetrics = null;
    this.selectedReportGroup = group;
    this.getDashboard(this.searchableTiers, 
      this.projectId, 
      this.selectedSubmission ? this.selectedSubmission.submissionId : null, 
      this.selectedProjectDate,
      group.reportGroupName
    );
  }

  clearSelectedTiers(t1?, t2?, t3?, t4?) {
    this.options = null;
    this.selectedOption = null;

    this.tiers.forEach(t1 => {
      delete t1.selected;
      if (t1.childrenTiers && t1.childrenTiers.length) {
        t1.childrenTiers.forEach(t2 => {
          delete t2.selected;
          if (t2.childrenTiers && t2.childrenTiers.length) {
            t2.childrenTiers.forEach(t3 => {
              delete t3.selected;
              if (t3.childrenTiers && t3.childrenTiers.length) {
                t3.childrenTiers.forEach(t4 => {
                  delete t4.selected;
                });
              }
              
            });
          }
        });
      }
    });
  }

  newSelectTier(t1, t2?, t3?, t4?) {

    this.clearSelectedTiers(t1, t2, t3, t4);

    // Filter only tiers selected
    let tiers = [t1, t2, t3, t4].filter(t => t !== null);

    // Mark tiers as selected
    tiers.forEach(t => {
      t.selected = true;
      if (t.tierTypeId === 5) {
        this.getReportDetails(t.childrenTiers[0].reportId);
        this.options = t.childrenTiers;
        this.selectedOption = this.options[0].tierName;
        //t.childrenTiers = null;
        this.selectedTiers[12].open = false;
        this.tierOpen = false;
      } else {
        this.options = null;
      }
    });

    // Get the final tier
    let selectedTier = tiers.slice(-1)[0];
    this.selectedTierName = selectedTier.tierName;

    // Open the final tier's report
    if (selectedTier.reportId) {
      this.getReportDetails(selectedTier.reportId);
      this.selectedTiers[12].open = false;
      this.tierOpen = false;
    }

    // Add to params
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { 
        a:  t1.tierId,
        b:  t2 && t2.tierId ? t2.tierId : null,
        c:  t3 && t3.tierId ? t3.tierId : null,
        d:  t4 && t4.tierId ? t4.tierId : null,
        o:  null
      }, 
      queryParamsHandling: 'merge'
    });
    
    if(this.secondaryChart !== undefined && this.secondaryChart.series !== undefined)
      this.resetSubmissionbuttons();
  }

  selectTier(level, tierId, url): any {

    // Select tier from all tiers
    let selectedTier;
    
    if (level === 0) {
      selectedTier = this.tiers.filter(t => t.tierId === tierId)[0];
    } else {
      if (this.selectedTiers[level - 1].childrenTiers) {
        selectedTier = this.selectedTiers[level - 1].childrenTiers.filter(t => t.tierId === tierId)[0];
      }
    }

    if (selectedTier !== undefined) {

      // Set tierId of selected tier and children
      this.selectedTiers[level].selected = selectedTier.tierId;
      this.selectedTiers[level].tierName = selectedTier.tierName;
      this.selectedTiers[level].childrenTiers = selectedTier.childrenTiers;

      // If reportId, set it and set associated report
      if (selectedTier.reportId) {
        this.selectedTiers[level].report = selectedTier.reportId;
        this.getReportDetails(selectedTier.reportId);
      }
      
      // If lower tier selected, null higher tiers
      this.selectedTiers.forEach((t, i) => {
        if (i > level) {
          t.selected = null;
          t.tierName = null;
          t.childrenTiers = null;
        }
      })

      // Update URL with tier parameters
      if (!url) {
        this.router.navigate([], { 
          relativeTo: this.route, 
          queryParams: { 
            a:  this.selectedTiers[0].selected,
            b:  this.selectedTiers[1].selected,
            c:  this.selectedTiers[2].selected,
            d:  this.selectedTiers[3].selected
          }, 
          queryParamsHandling: 'merge'
        });
      }

      // Automatically select the top tier in the fourth group
      if (level === 2 && !selectedTier.reportId && !url) {
        this.selectTier(3, this.selectedTiers[2].childrenTiers[0].tierId, false);
      }

    } else {
      this.error = 'This report has moved or no longer exists. Please try using the search function above.'
    }

  }

  selectParamTier(params) {

    // Handle option param
    let option;
    if (params.o) {
      option = params.o;
      let newParams = {};
      Object.keys(params).forEach(p => {
        if (p !== 'o') {
          newParams[p] = params[p]
        }
      })
      params = newParams;
    }

    let tierIds = []
    
    Object.keys(params).forEach(p => {
      if (p !== 'date' && p !== 'reportId') {
        tierIds.push(+params[p])
      }
    });

    // NEW SECTION
    let selectedTiers = [];
    let t1 = this.tiers.filter(t => t.tierId === tierIds[0])[0];
    if (t1) {
      t1.childrenOpen = true;
      t1.selected = true;
      let t2 = t1.childrenTiers.filter(t => t.tierId === tierIds[1])[0];
      selectedTiers.push(t1);
      if (t2) {
        t2.childrenOpen = true;
        t2.selected = true;
        let t3 = t2.childrenTiers.filter(t => t.tierId === tierIds[2])[0];
        selectedTiers.push(t2);
        if (t3) {
          t3.childrenOpen = true;
          t3.selected = true;
          let t4 = t3.childrenTiers.filter(t => t.tierId === tierIds[3])[0];
          selectedTiers.push(t3);
          if (t4) {
            t4.selected = true;
            selectedTiers.push(t4);
          }
        }
      }
    }
    let selectedTier = selectedTiers.slice(-1)[0];
    if (selectedTier) {
      this.selectedTierName = selectedTier.tierName;
      if (selectedTier.tierTypeId === 5) {
        // If option passed, set/open option
        let optionIndex = 0;
        if (option) {
          optionIndex = selectedTier.childrenTiers.findIndex(ct => ct.tierId == option);
        }
        this.getReportDetails(selectedTier.childrenTiers[optionIndex].reportId);
        this.options = selectedTier.childrenTiers;
        this.selectedOption = this.options[optionIndex].tierName;
        this.selectedTiers[12].open = false;
        this.tierOpen = false;
      }
    }
    // end NEW SECTION

    const tiersFromParams = async () => {
      for (let i = 0; i < tierIds.length; i++) {
        await selectTier(i, tierIds[i]);
      }
    }
    
    const selectTier = (i, tierId) => {
      return new Promise((resolve, reject) => {
        resolve(
          this.selectTier(i, tierId, true)
        )
      });
    };
    
    tiersFromParams();
  }

  selectSearchTier(metric) {
    let params;
    params = { a: null, b: null, c: null, d: null };
    if (metric.aId) { params.a = metric.aId.toString() } else { delete params.a };
    if (metric.bId) { params.b = metric.bId.toString() } else { delete params.b };
    if (metric.cId) { params.c = metric.cId.toString() } else { delete params.c };
    if (metric.dId) { params.d = metric.dId.toString() } else { delete params.d };
    if (this.selectedProjectDate) { params.date = this.selectedProjectDate.dateId.toString() };
    if (this.projectView === 'toolkit') {
      this.selectParamTier(params);
      this.router.navigate(['project/' + this.projectId + '/toolkit'], { queryParams: params });
    } else {
      this.projectView = 'toolkit';
      this.selectParamTier(params);
      this.router.navigate(['project/' + this.projectId + '/toolkit'], { queryParams: params });
    }
    
  }

  selectCategoryType(type) {
    this.reportLoading = true;
    this.selectedCategoryType = this.reportAllCategories.categoryTypes.filter(c => c === type)[0];
    this.reportCategories = this.reportAllCategories.categories.filter(c => c.categoryType === type);
    // TODO: Allow for category type selection without additional API call 
    this.getReportDataSeries(this.reportDetails.reportId, this.selectedProjectDate, this.selectedOrganisation.organisationId);
    this.resetSubmissionbuttons();
  }

  selectDate(date) {
    let dateIndex = this.projectDates.indexOf(date);
    this.selectedProjectDate = this.projectDates[dateIndex];
    if (this.reportType === 'pie') {
      this.new_setData(this.selectedProjectDate, this.categoryAverages);
    } else {
      this.setData(this.reportData, this.selectedProjectDate);
    }
    // Add date to query param
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { 
        date: this.selectedProjectDate.dateId
      }, 
      queryParamsHandling: 'merge'
    });

    this.isSubmissionsEmpty(this.submissions);
    if(this.secondaryChart !== undefined && this.secondaryChart.series !== undefined)
      this.resetSubmissionbuttons();
  }
  
  selectProjectDate(date) {
    this.errorMetrics = null;
    this.selectedProjectDate = date;
    this.metrics = this.getDashboard(this.searchableTiers, this.projectId, this.selectedSubmission ? this.selectedSubmission.submissionId : null, this.selectedProjectDate, this.selectedReportGroup.reportGroupName);
    if (this.selectedOrganisation) {
      this.getAWSDocuments(this.projectId, this.selectedOrganisation.externalOrganisationId, moment(this.selectedProjectDate.dateTo).format('YYYY'));
    }
  }

  selectSubmission(submission) {
    this.selectedSubmission = submission;
    this.metrics = this.getDashboard(this.searchableTiers, this.projectId, this.selectedSubmission.submissionId, this.selectedProjectDate, this.selectedReportGroup.reportGroupName);
  }

  selectRange(dates, number) {

    let lookupDates = this.reportDetails.dates.slice(0, number).map(d => d.dateName);

    let temp = {};
    
    for (var index in dates) {
      if (lookupDates.includes(index)) {
        temp[index] = dates[index]
      }
    }

  }

  selectOption(option) {
    this.getReportDetails(option.reportId);
    this.selectedOption = option.tierName;
    this.selectedTiers[13].open = false;
    this.tierOpen = false;
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { 
        o: option.tierId
      }, 
      queryParamsHandling: 'merge'
    });
    this.resetSubmissionbuttons();
  }

  // UTILITIES

  toggleTier(level) {
    this.selectedTiers.forEach(t => { t.open = false });
    this.selectedTiers[level].open = true;
    this.tierOpen = true;
    this.params = this.router.url;
    if(this.infoPanel && level == 4)
      this.infoPanel.info = true;
  }

  // FORMATS

  formatDates(dates) {
    let outputData = [],
        timeNow = new Date(Date.now());

    // Add dates if outputs are available or user is
    // an admin, but only if outputAvailability is set
    dates.forEach(d => {
      d.data = [];
      if (d.outputAvailability) {
        let timeOutputs = new Date(d.outputAvailability);
        let timeDiff = timeOutputs.getTime() - timeNow.getTime();
        if (timeDiff < 0) {
          d.activeAdmin = false;
        } else {
          d.activeAdmin = true;
        }
        outputData.push(d);
      }
    });

    // Check for current financial year
    outputData.forEach(d => {
      let dateFrom = new Date(d.dateFrom);
      let currentFY = new Date('2021/04/01');
      if (dateFrom >= currentFY) {
        d.currentFY = true;
      } else {
        d.currentFY = false;
      }
    });

    // Remove dates where user is not admin
    if (!this.activeAdmin) {
      outputData = outputData.filter(d => d.activeAdmin == false);
    }

    return outputData.reverse();
  }

  formatMetrics(tiers, metrics, selectedProjectDate) {

    let availableDates;
    if (this.activeAdmin) {
      availableDates = this.projectDates.map(pd => pd.dateName);
    } else {
      availableDates = this.projectDates.filter(pd => pd.activeAdmin === false).map(pd => pd.dateName);
    }

    metrics.forEach(m => {

      // Link to a tier, if available
      let tier = tiers.filter(s => s.tierMap.includes(m.tierId))[0];
      if (tier) {
        m.aId = tier.aId;
        m.aName = tier.aName;
        m.tier = tier;
        m.visible = true;
      } else {
        if (m.subGroup) {
          m.aName = m.subGroup,
          m.visible = true
        } else {
          m.aName = 'Miscellaneous';
          m.visible = true;
        }
      }

      // Extract latest month
      if (m.dateList) {

        let latestMonth = m.dateList.filter(d => d.dateId === selectedProjectDate.dateId)[0];
        if (latestMonth) {
          m.latestMonth = latestMonth
        } else {
          this.errorMetrics = 'There is no data available for the selected period.'
        }

        // Calculate 6m average
        let map6m = m.dateList.map(d => d.cohortAverage);
        let sum6m: number = this.sum.transform(map6m);
        let average6m = sum6m / map6m.length;

        // Calculate percentage change
        let currentMonth = this.selectedProjectDate.dateId,
            currentMonthIndex = m.dateList.findIndex(d => d.dateId == currentMonth),
            previousMonthIndex = currentMonthIndex + 1,
            previousMonthSubmissionValue = m.dateList[previousMonthIndex] ? m.dateList[previousMonthIndex].submissionValue : null;
        
        let monthDiff = null, monthStatus = null;
        if (previousMonthSubmissionValue && latestMonth && latestMonth.submissionValue) {
          monthDiff = getPercentageChange(previousMonthSubmissionValue, latestMonth.submissionValue).diff;
          monthStatus = getPercentageChange(previousMonthSubmissionValue, latestMonth.submissionValue).status;
        }

        // Remove unavailable dates
        m.dateList = m.dateList.filter(d => availableDates.includes(d.dateName));
        m.dateList = m.dateList.reverse();

        if (m.latestMonth) {
          // Add extract-specific information
          m.percentageCompletion = latestMonth.completionRate ? latestMonth.completionRate.toFixed(2) : null;
          m.benchmark = latestMonth.benchmark !== null ? latestMonth.benchmark : (average6m ? average6m.toFixed(2) : null);
          m.average6 = average6m ? average6m.toFixed(2) : '';
          m.organisationResponse = latestMonth.submissionValue !== null ? latestMonth.submissionValue.toFixed(2) : '';
          m.monthDiff = monthDiff ? monthDiff.toFixed(2) : '';
          m.monthStatus = monthStatus ? monthStatus : '';
          m.cohortAverage = latestMonth.cohortAverage ? latestMonth.cohortAverage : null;
          m.nationalAverage = latestMonth.nationalAverage ? latestMonth.nationalAverage : null;
        }

        // Set decimal places
        let decPlaces = '1.0-2'
        if (m.decimalPlaces) { decPlaces = '1.0-' + m.decimalPlaces }
        m.decimalPlaces = decPlaces;

        if (this.projectId == 29 && m.latestMonth) {
          // Selected date
          m.date = m.latestMonth.dateName;
          // Metric code
          m.code = tier ? tier.bName.substring(0, 5).replace(' -', '') : null;
        }
      } else {
        metrics = [];
      }

    });

    // Percentage change function
    function getPercentageChange(oldNumber, newNumber){
      var decreaseValue = oldNumber - newNumber;
      return {
        diff: Math.abs((decreaseValue / oldNumber) * 100),
        status: decreaseValue < 0 ? 'increase' : 'decrease'
      }
    }

    // Return only metrics with national averages (i.e. with some data)
    // return metrics.filter(m => m.nationalAverage != null);
    return metrics; // not sure why this was filtered out in the lines above, it stops the dashboard from rendering anything!
  }

  formatReportMetrics(metrics, organisation) {
    let outputData = [];

    let availableDates = this.projectDates.filter(pd => pd.activeAdmin === false).map(pd => pd.dateName);
  
    let codeType = 'anonSubmissionCode';
    
    if (organisation) {
      organisation.submissionList.forEach((s, i) => {
        s.color = this.colors[i];
      });
    }

    metrics.forEach(m => {

      let dataLineChart = [];
      let submissions = [];

      // Create time series element
      // National
      dataLineChart.push({
        name: this.projectId == 52 ? 'Regional Mean' : 'Sample Mean',
        color: this.nationalMeanColour,
        data: m.dateList.map(md => {
          return {
            name: md.dateName,
            y: md.nationalAverage
          }
        })
      });
      
      // Submission(s)
      m.dateList.forEach(dl => {
        if (dl.organisationData) {
          dl.organisationData.forEach(od => {
            let matchingSub = organisation.submissionList.filter(s => s[codeType] === od[codeType])[0];
            submissions.push({
              submission: od[codeType],
              color:      matchingSub ? matchingSub.color : null,
              month:      dl.dateName,
              y:          od.submissionValue
            });
          });
        };
      });

      submissions = this.group.transform(submissions, 'submission');

      Object.keys(submissions).forEach(key => {
        dataLineChart.push({
          name:   key,
          color:  submissions[key][0].color,
          data:   submissions[key].map(s => { return { name: s.month, y: s.y } })
        })
      });

      // Remove unavailable dates
      dataLineChart.forEach(dlc => {
        dlc.data = dlc.data.filter(d => availableDates.includes(d.name)).reverse();
      })
      
      // Bar chart
      let dataList = [];
      if (m.dataList) {
        m.dataList.map(md => { 
          let matchingSub;
          if (organisation) {
            matchingSub = organisation.submissionList.filter(s => s[codeType] === md[codeType])[0];
          }
          dataList.push({
            color:  matchingSub ? matchingSub.color : null,
            name:   md[codeType],
            y:      md.submissionValue
          });
        });
      }

      // Benchmark
      let benchmark = null;
      if (this.projectId === 29) {
        m.dateList[0].benchmark;
      }

      // Calculate 6m average
      let map6m = m.dateList.map(d => d.nationalAverage);
      let sum6m: number = this.sum.transform(map6m);
      let average6m = sum6m / map6m.length;

      // Table
      let selectedDateName = this.selectedProjectDate.dateName;
      let dataTable = [];
      dataLineChart.forEach(dlc => {
        dlc.data.forEach(d => {
          if (d.name === selectedDateName) {
            dataTable.push({
              name: dlc.name,
              month: d.name,
              y: d.y
            });
          }
        })
      });

      // Create format
      outputData.push({
        reportName: m.reportName,
        displaySequence: m.displaySequence,
        formatModifier: m.formatModifier,
        rangeMin: null,
        rangeMax: null,
        decPlaces: null,
        dataBarChart: dataList,
        dataLineChart: dataLineChart,
        dataTable: dataTable,
        benchmark: benchmark !== null ? benchmark : average6m
      });

    });

    outputData = this.order.transform(outputData, 'displaySequence');

    return outputData;
  }

  formatCategoryAverages(averages, medians) {
    let tempAverage = [],
        tempMedian = [],
        data = [];

    Object.keys(averages).forEach(av => {
      let matchingDate = this.projectDates.filter(pd => pd.dateId === +av)[0];
      if (matchingDate) {
        // Means
        Object.keys(averages[av].categoryResponseList).forEach(crl => {
          let matchingCategory = this.reportCategories.filter(c => c.categoryId === +crl)[0];
          if (matchingCategory) {
            tempAverage.push({
              dateFrom: matchingDate.dateFrom,
              date: matchingDate.dateName,
              name: matchingCategory.categoryName,
              y:    averages[av].categoryResponseList[crl]
            })
          }
        })
        // Medians
        Object.keys(medians[av].categoryResponseList).forEach(crl => {
          let matchingCategory = this.reportCategories.filter(c => c.categoryId === +crl)[0];
          if (matchingCategory) {
            tempMedian.push({
              dateFrom: matchingDate.dateFrom,
              date: matchingDate.dateName,
              name: matchingCategory.categoryName,
              y:    medians[av].categoryResponseList[crl]
            })
          }
        })
      }
    })

    // Order by dateFrom
    tempAverage = this.order.transform(tempAverage, 'dateFrom');
    tempMedian = this.order.transform(tempMedian, 'dateFrom');

    tempAverage = this.group.transform(tempAverage, 'name');
    Object.keys(tempAverage).forEach(key => {
      data.push({
        category: 'Means',
        name: key,
        data: tempAverage[key].map(d => { 
          return {
            name: d.date.substring(0, 3) + '-' + d.date.slice(-2),
            y:    d.y  
          }
        })
      })
    });

    tempMedian = this.group.transform(tempMedian, 'name');
    Object.keys(tempMedian).forEach(key => {
      data.push({
        category: 'Medians',
        name: key,
        data: tempMedian[key].map(d => { 
          return {
            name: d.date.substring(0, 3) + '-' + d.date.slice(-2),
            y:    d.y  
          }
        })
      })
    });

    return data;
  }

  formatAverages(means, medians, calculatedNationalMeans?) {
    let data = [
      {
        name: this.projectId == 52 ? 'Regional Mean' : 'National Mean',
        color: this.nationalMeanColour,
        data: []
      },
      {
        name: this.projectId == 52 ? 'Regional Median' : 'National Median',
        color: this.nationalMedianColour,
        data: []
      }
    ];

    if (calculatedNationalMeans) {
      data.push(
        {
          name: 'Calculated National Mean',
          color: 'grey',
          data: []
        }
      )
    }

    // Means
    Object.keys(means).forEach(av => {
      let matchingDate = this.projectDates.filter(pd => pd.dateId === +av)[0];
      if (matchingDate) {
        data[0].data.push({
          dateFrom: matchingDate.dateFrom,
          dateName: matchingDate.dateName,
          y:        means[av]
        });
      }
    })

    // Medians
    Object.keys(medians).forEach(av => {
      let matchingDate = this.projectDates.filter(pd => pd.dateId === +av)[0];
      if (matchingDate) {
        data[1].data.push({
          dateFrom: matchingDate.dateFrom,
          dateName: matchingDate.dateName,
          y:        medians[av]
        });
      }
    });

    // Calculated national means
    Object.keys(calculatedNationalMeans).forEach(cnm => {
      let matchingDate = this.projectDates.filter(pd => pd.dateId === +cnm)[0];
      if (matchingDate) {
        data[2].data.push({
          dateFrom: matchingDate.dateFrom,
          dateName: matchingDate.dateName,
          y:        calculatedNationalMeans[cnm]
        });
      }
    });

    // Order by dateFrom
    data[0].data = this.order.transform(data[0].data, 'dateFrom');
    data[1].data = this.order.transform(data[1].data, 'dateFrom');
    data[2].data = this.order.transform(data[2].data, 'dateFrom');

    // Add median to mean for table
    data[0].data.forEach(d => {
      let matchingMedian = data[1].data.filter(dd => dd.dateName === d.dateName)[0];
      let matchingNationalCalculatedMean = data[2].data.filter(dd => dd.dateName === d.dateName)[0];
      d.median = matchingMedian.y;
      d.nationalCalculatedMean = matchingNationalCalculatedMean ? matchingNationalCalculatedMean.y : null;
    })

    return data;
  }

  formatSubmissions(organisation) {
    let outputData = [];

    organisation.submissionList.forEach((s, i) => {
      outputData.push({
        organisationCode: organisation.organisationOnsCode ? organisation.organisationOnsCode : organisation.organisationCode,
        organisationName: organisation.organisationName,
        submissionId:     s.submissionId,
        submissionCode:   s.anonSubmissionCode ? s.anonSubmissionCode : s.submissionCode,
        submissionName:   s.submissionName,
        color:            this.colors[i]
      })
    });

    return outputData;
  }

  formatTiers(inputData) {
    let outputData = [];

    inputData.forEach(d => {
      d.childrenTiers.forEach(dd => {
        if (dd.isVisible === 'Y') {
          outputData.push({
            aId: d.tierId,
            aName: d.tierName,
            bId: dd.tierId,
            bName: dd.tierName,
            reportId: dd.reportId,
            bVisible: dd.isVisible
          });
          dd.childrenTiers.forEach(ddd => {
            if (ddd.isVisible === 'Y') {
              outputData.push({
                aId: d.tierId,
                aName: d.tierName,
                bId: dd.tierId,
                bName: dd.tierName,
                bVisible: dd.isVisible,
                cId: ddd.tierId,
                cName: ddd.tierName,
                cVisible: ddd.isVisible,
                reportId: ddd.reportId
              });
              ddd.childrenTiers.forEach(dddd => {
                if (dddd.isVisible === 'Y') {
                  outputData.push({
                    aId: d.tierId,
                    aName: d.tierName,
                    bId: dd.tierId,
                    bName: dd.tierName,
                    bVisible: dd.isVisible,
                    cId: ddd.tierId,
                    cName: ddd.tierName,
                    cVisible: ddd.isVisible,
                    dId: dddd.tierId,
                    dName: dddd.tierName,
                    dVisible: dddd.isVisible,
                    reportId: dddd.reportId
                  });
                  dddd.childrenTiers.forEach(ddddd => {
                    if (ddddd.isVisible === 'Y') {
                      outputData.push({
                        aId: d.tierId,
                        aName: d.tierName,
                        bId: dd.tierId,
                        bName: dd.tierName,
                        bVisible: dd.isVisible,
                        cId: ddd.tierId,
                        cName: ddd.tierName,
                        cVisible: ddd.isVisible,
                        dId: dddd.tierId,
                        dName: dddd.tierName,
                        dVisible: dddd.isVisible,
                        eId: ddddd.tierId,
                        eName: ddddd.tierName,
                        eVisible: ddddd.isVisible,
                        reportId: ddddd.reportId
                      });
                    }
                  });
                }
              });
            }
          })
        }
      })
    });

    outputData.forEach(o => {
      o.tierMap = [o.aId, o.bId, o.cId, o.dId, o.eId];
    });

    // Remove null reports
    outputData = outputData.filter(d => d.reportId !== null);

    return outputData;
  }

  formatCategories(categories) {
    let allCategories = [];

    // Remove all types with single category
    Object.keys(categories).forEach(key => {
      if (categories[key].length === 1) {
        delete categories[key];
      }
    });

    let categoryTypes = Object.keys(categories);

    // Return all categories
    Object.keys(categories).forEach(key => {
      categories[key].forEach(c => {
        allCategories.push({
          categoryType: key,
          categoryName: c.categoryName,
          categoryId:   c.categoryId,
          data:         []
        })
      });
    });

    return { categories: allCategories, categoryTypes: categoryTypes };
  }

  formatBarData(reportData, anon?: boolean) {
    if(anon == true)
      this.anonymousEnabled = true;
    else {
      this.anonymousEnabled = false;
    }
    // Filter reportData to projectDates
    let availableDates = [];
    reportData.availableDates.forEach(ad => {
      this.projectDates.forEach(pd => {
        if (ad.dateName === pd.dateName) {
          availableDates.push(ad);
        }
      })
    });
    reportData.availableDates = availableDates;

    let bySeries = [];
    let bySubmission = [];
    let byMonth = [];
    let byMonthAverages = [];

    // Linearise
    reportData.availableDates.forEach(d => {
      d.organisationList.forEach(o => {
        o.submissionData.forEach(s => {

          let highlighted;
          
          if (this.submissions) {
            if (s.anonSubmissionCode) {
              highlighted = this.submissions.filter(p => p.submissionCode === s.anonSubmissionCode)[0];
            } else {
              highlighted = this.submissions.filter(p => p.submissionCode === s.submissionCode)[0];
            }           
          } else {
            highlighted = null;
          }

          bySubmission.push({
            date:   d.dateName,
            name:   anon == true ? s.submissionCode : s.anonSubmissionCode,
            y:      s.result,
            color:  highlighted ? highlighted.color : null,
            numeratorValue:  s.numeratorValue ? s.numeratorValue : null,
            denominatorValue:  s.denominatorValue ? s.denominatorValue : null
          })
        });
      });
    });

    // Remove admin only dates if not admin
    if (!this.activeAdmin && this.projectId !== 52) {
      let availableDates = this.projectDates.filter(pd => pd.activeAdmin === false).map(pd => pd.dateName);
      bySubmission = bySubmission.filter(s => availableDates.includes(s.date));
    }

    // Remove submissions without submissionCode or anonSubmissionCode
    bySubmission = bySubmission.filter(s => s.name !== null);

    // Group by date (date series)
    byMonth = this.group.transform(bySubmission, 'date');

    // Get date average
    Object.keys(byMonth).forEach(key => {
      let results = byMonth[key].map(dd => dd.y);
      let count = byMonth[key].length;
      let sum = results.reduce((a, b) => a + b, 0);
      byMonthAverages.push({
        name: key,
        y:    sum / count // 'National' average
      })
    });

    // Add cohort average to time series
    this.averages = this.formatAverages(reportData.dateAverages, reportData.dateMedians, reportData.calculatedNationalAverages);
    this.averages.forEach(a => {
      bySeries.push({
        name:   a.name,
        data:   a.data.map(ad => { return { name: ad.dateName, y: ad.y } }),
        color:  a.color
      });
    });

    // Group by submission
    bySubmission = this.group.transform(bySubmission, 'name');

    // Create series
    Object.keys(bySubmission).forEach(key => {
      // Only add to series if color set (i.e. submissions granted)
      if (bySubmission[key][0].color !== null) {
        let data = bySubmission[key].map(d => [d.date, d.y]);
        bySeries.push({
          name: key,
          data: data,
          color: bySubmission[key][0].color
        });
      }
    });

    // Order byMonth data
    Object.keys(byMonth).forEach(key => {
      byMonth[key] = this.order.transform(byMonth[key], '-y')
    });

    return { bySeries: bySeries, bySubmission: bySubmission, byMonth: byMonth, byMonthAverages: byMonthAverages };
  }

  formatStackedBarData(reportData) {

    // Filter reportData to projectDates
    let availableDates = [];
    reportData.availableDates.forEach(ad => {
      this.projectDates.forEach(pd => {
        if (ad.dateName === pd.dateName) {
          availableDates.push(ad);
        }
      })
    });

    this.new_formatStackedBarData(reportData);

    let bySubmission = [],
        byMonth = {},
        byMonthAverages = [],
        byCategory = {};

    // Linearise
    reportData.organisationList.forEach(o => {
      o.submissionData.forEach(s => {
        let highlighted = this.submissions.filter(p => p.submissionCode === s.submissionCode);
        Object.keys(s.dateCategoryList).forEach(dcl => {
          let date = this.reportDetails.dates.filter(d => d.dateId === +dcl)[0];
          Object.keys(s.dateCategoryList[dcl].categoryResponseList).forEach(crl => {
            let category = this.reportCategories.filter(c => c.categoryId === +crl)[0];
            // Remove any category responses with only one category within a category type
            // TODO: Review category usage (collection vs. toolkit)
            if (category) {
              bySubmission.push({
                dateId:       +dcl,
                date:         date.dateName,
                categoryId:   +crl,
                category:     category.categoryName,
                name:         s.anonSubmissionCode ? s.anonSubmissionCode : s.submissionCode,
                y:            s.dateCategoryList[dcl].categoryResponseList[crl],
                color:        highlighted[0] ? highlighted[0].color : null,
              });
            }
          });
        });
      });
    });

    // Remove submissions without submissionCode or anonSubmissionCode
    bySubmission = bySubmission.filter(s => s.name !== null);

    // Add missing zero values
    let submissions = this.group.transform(bySubmission, 'name');
    Object.keys(submissions).forEach(key => {
      this.projectDates.forEach(pd => {
        submissions[key].forEach(sk => {
          this.reportCategories.forEach(c => {
            let response = bySubmission.filter(m => m.categoryId === c.categoryId && m.name === sk.name && m.dateId === pd.dateId)[0];
            let sumOtherResponses = bySubmission.filter(m => m.name === sk.name && m.dateId === pd.dateId).map(s => s.y).reduce((a, b) => a + b, 0);
            if (!response && sumOtherResponses !== 0) {
              bySubmission.push({
                dateId:       pd.dateId,
                date:         pd.dateName,
                categoryId:   c.categoryId,
                category:     c.categoryName,
                name:         sk.name,
                y:            0,
                color:        sk.color
              });
            };
          });
        })
      })
    });

    // Order by submission name
    bySubmission = this.order.transform(bySubmission, 'name');

    // Create byMonth
    let monthTemp = this.group.transform(bySubmission, 'date');
    Object.keys(monthTemp).forEach(key => {
      byMonth[key] = this.group.transform(monthTemp[key], 'name')
    });

    // Create byCategory
    let categoryTemp = this.group.transform(bySubmission, 'date');
    Object.keys(categoryTemp).forEach(key => {
      byCategory[key] = this.group.transform(categoryTemp[key], 'category');
    });

    // Set monthly category averages
    let averagesTemp = [];
    Object.keys(byCategory).forEach(key => {
      Object.keys(byCategory[key]).forEach(c => {
        let results = byCategory[key][c].filter(dd => dd.y !== 0).map(dd => dd.y);
        let count = results.length;
        let sum = results.reduce((a, b) => a + b, 0);
        averagesTemp.push({
          category: c,
          name:     key,
          y:        byCategory[key][c].average = sum/count
        });
      });
    });

    averagesTemp = this.group.transform(averagesTemp, 'category');

    Object.keys(averagesTemp).forEach(key => {
      byMonthAverages.push({
        name: key + ' (National)',
        data: averagesTemp[key].map(a => { return { name: a.name, y: a.y } })
      })
    });

    // Create bySubmission
    bySubmission = this.group.transform(bySubmission, 'name');

    return { bySubmission: bySubmission, byMonth: byMonth, byCategory: byCategory, byMonthAverages: byMonthAverages };
  }

  // CHARTS

  createBarChart(data, date, nationalCalculatedMean) {
    
    let mean = this.averages[0].data.filter(d => d.dateName === date)[0].y;
    let median = this.averages[1].data.filter(d => d.dateName === date)[0].y;
    this.averagesTable = this.order.transform(this.averages[0].data, '-dateFrom');

    this.primaryChartOptions = {
      chart: {
        type: 'column',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: '#FFFFFF',
        events: { 
          load: (e => {
            this.primaryChart = e.target;
            this.createLegend(this.primaryChart);
          })
        },
      },
      colors: this.setColour,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.key}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;">{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' }
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: true,
        gridLineColor: '#EEF1F8'
      },
      yAxis: {
        min: this.reportSettings.min ? this.reportSettings.min : null,
        max: this.reportSettings.max ? this.reportSettings.max : null,
        tickInterval: this.reportSettings.tickInterval ? this.reportSettings.tickInterval : null,
        gridLineColor: '#EEF1F8',
        title: {
          text: this.reportDetails.reportName
        },
        labels: {
          formatter: (e) => {
            if (this.reportSettings.formatModifier === 'P') {
              return e.value + "%";
            } else {
              return e.value;
            }
          }
        },
        plotLines: [{
          color: this.nationalMeanColour,
          value: mean,
          width: '2',
          zIndex: 5
        },
        {
          color: this.nationalMedianColour,
          value: median,
          width: '2',
          zIndex: 5
        },
        {
          color: 'grey',
          value: nationalCalculatedMean,
          width: '2',
          zIndex: 5
        }],
      },
      plotOptions: {
        series: {
          animation: false
        }
      },
      series: [
        {
          data: data,
          color: this.primaryBarColour
        }
      ]
    };
  }

  createStackedBarChart(data) {

    let series = [];
    this.reportCategories.forEach((rc, i) => {
      series.push({
        name: rc.categoryName,
        submission: rc.submission,
        data: data.map(d => d.responses[i])
      })
    });

    let categories = data.map(d => d.submission);

    this.tableData = data;
    this.tableData.forEach(n => {
      n.responses.forEach(k => {
        n[k.category] = k.y
      })
    })
    this.tableHeader = this.reportCategories.map(rc => rc.categoryName);

    let tooltipText;
    if (this.reportSettings.formatModifier === 'P') {
      tooltipText = '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:0.25em"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}%</td></tr>'
    } else {
      tooltipText = '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:0.25em"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>'
    }

    this.primaryChartOptions = {
      chart: {
        type: 'column',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: '#FFFFFF',
        events: { 
          load: (e => {
            this.primaryChart = e.target;
            this.createLegend(this.primaryChart);
          })
        },
      },
      colors: this.setColour,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.key}</div><table>',
        pointFormat: tooltipText,
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' }
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        labels: {
          formatter () {
            let submission = data.filter(td => td.submission === this.value)[0];
            if (submission && submission.color) {
              return `<span style="color:${submission.color};font-weight:bold">${this.value}</span>`
            } else {
              return this.value
            }
          }
        }
      },
      yAxis: {
        min: this.reportSettings.min ? this.reportSettings.min : null,
        max: this.reportSettings.max ? this.reportSettings.max : null,
        tickInterval: this.reportSettings.tickInterval ? this.reportSettings.tickInterval : null,
        gridLineColor: '#EEF1F8',
        title: {
          text: this.reportDetails.reportName
        },
        labels: {
          formatter: (e) => {
            if (this.reportSettings.formatModifier === 'P') {
              return e.value + "%";
            } else {
              return e.value;
            }
          }
        },
        // plotLines: [{
        //   color: '#009639',
        //   value: average,
        //   width: '2',
        //   zIndex: 10
        // }],
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        },
        series: {
          animation: false
        }
      },
      series: series
    };
  }

  createLineChart(data) {
    let headerFormat;

    // Remove national data from time series
    if (!this.timeseriesAvailable) {
      data = data.filter(d => !d.name.includes('National'));
    }

    // Remove medians from stacked bar/pie and style header
    if (this.reportType !== 'bar') {
      data = data.filter(d => d.category !== 'Medians');
      headerFormat = '<div style="margin-bottom:5px;font-size:14px;">National Means for<br><strong>{point.key}</strong></div><table>';
    } else {
      headerFormat = '<div style="margin-bottom:5px;font-size:14px;font-weight:bold">{point.key}</div><table>';
    }

    this.secondaryChartOptions = {
      chart: {
        type: 'line',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: '#FFFFFF',
        events: { 
          load: (e => {
            this.secondaryChart = e.target;
          })
        },
      },
      colors: this.setColour,
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: headerFormat,
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:0.25em"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' }
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          animation: false
        }
      },
      xAxis: {
        categories: true,
        crosshair: true,
        labels: {
          step: 1,
          rotation: 315
        }
      },
      yAxis: {
        min: this.reportSettings.min ? this.reportSettings.min : null,
        max: this.reportSettings.max ? this.reportSettings.max : null,
        tickInterval: this.reportSettings.tickInterval ? this.reportSettings.tickInterval : null,
        gridLineColor: '#EEF1F8',
        title: {
          text: ''
        },
        labels: {
          formatter: (e) => {
            if (this.reportSettings.formatModifier === 'P') {
              return e.value + "%";
            } else {
              return e.value;
            }
          }
        },
      },
      series: data
    };
    
    this.reportLoading = false;
  }

  createPieChart(data) {

    let seriesData = []

    Object.keys(data).forEach(key => {
      seriesData.push({
        name: key,
        y: data[key].average
      });
    });

    this.primaryChartOptions = {
      chart: {
        type: 'pie',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: '#FFFFFF',
        events: { 
          load: (e => {
            this.primaryChart = e.target;
          })
        },
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
          
      },
      title: {
          text: ''
      },
      colors: this.setColour,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.key}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;">{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.percentage:.1f}%</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' }
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f}%'
              }
          }
      },
      series: [{
          name: 'Brands',
          colorByPoint: true,
          data: seriesData
      }]
    };
  }

  createLegend(data) {
    this.legend = [];
    let series = data.series;
    if (series.length > 1) {
      series.forEach(s => {
        this.legend.push({
          name: s.name,
          color: s.color,
          visible: true
        })
      })
    }
  }

  // TABLES

  createTable(data, selectedDate) {
    let tableData = {};

    switch(this.reportType) {
      case 'stacked':
        Object.keys(data).forEach(key => {
          tableData[key] = data[key].filter(d => d.date === selectedDate.dateName);
        });
        break;
      case 'pie':
        Object.keys(data).forEach(key => {
          tableData[key] = data[key].filter(d => d.date === selectedDate.dateName);
        });
        this.tableData = tableData;
        break;
      case 'bar':
        this.tableData = data;
        break
      default:
        this.error = 'Chart type has not been set.'
    }
    let headerFind = Object.keys(tableData).filter(key => tableData[key].length)[0];
    this.tableHeader = tableData[headerFind];
  }

  // UTILITIES

  toggleSeriesVisibility(index) {
    let visibility = this.primaryChart['series'][index].visible;
    if (visibility === true) {
      this.primaryChart['series'][index].update({ visible: false });
      this.secondaryChart['series'][index].update({ visible: false });
      this.legend[index].visible = false;
    } else {
      this.primaryChart['series'][index].update({ visible: true });
      this.secondaryChart['series'][index].update({ visible: true });
      this.legend[index].visible = true;
    }
  }

  isSubmissionsEmpty(submission) {
    let submissionData = [];
    if (submission) {
      submission.forEach(d => {
        Object.keys(this.reportData.byMonth).forEach(month => {
          this.reportData.byMonth[month].forEach(sub => {
            if (sub.submission == d.submissionCode) {
              submissionData.push(sub);
            }
          })
        })
      });
    }

    if(submissionData.length == 0) {
      this.submissionVisible = false;
    }
    else {
      this.submissionVisible = true;
    }
  }

  resetSubmissionbuttons() {
    if (this.submissions) {
      this.submissions.forEach(d => this.resetLineChart(d))
    }
  }

  addSubmissiontoLineChart(submission) {
    this.hideTable = false;
    if (this.reportType == 'stacked') {
      let submissionData = [];
      let chartData = [];
      // Copy dates array from existing chart
      let categoryData = JSON.parse(JSON.stringify(this.categoryAverages[0].data));
      // Add submission responses to copied array
      Object.keys(this.reportData.byMonth).forEach(month => {
        this.reportData.byMonth[month].forEach(sub => {
          if (sub.submission == submission.submissionCode) {
            let shortDate = sub.date.substring(0, 3) + '-' + sub.date.slice(-2);
            let matchingCategory = categoryData.find(cd => cd.name == shortDate);
            matchingCategory.responses = sub.responses;
            submissionData.push(sub);
          }
        })
      });

      // Set sub-colours
      let colorProfile = this.subColors.find(sc => sc.color === submission.color);

      // Map submission responses to dates
      let means = this.categoryAverages.filter(ca => ca.category == 'Means');
      means.forEach((ca, i) => {  
        chartData.push({
          category: ca.category + ' (submission)',
          name: ca.name + ' (' + submission.submissionCode + ')',
          color: colorProfile.subColors[i],
          data: categoryData.map(cd => { 
            return { 
              name: cd.name, 
              y: cd.responses && cd.responses[i] ? cd.responses[i].y : null
            } 
          })
        })
      });

      // Add to line chart
      chartData.forEach(data => {
        this.secondaryChart.addSeries(data, true);
      });
      chartData["name"] = submission.submissionName;
      // Collect all open tables
      this.openSubmissionTables.push(chartData);
      // Create table
      this.submissionData = chartData;
      this.submissionName = submission.submissionName;
      // Set visibility
      submission.visible = true;

    }
  }

  resetLineChart(submission) {
    this.openSubmissionTables = this.openSubmissionTables.filter(d => d.name !== submission.submissionName)
    let subCode = submission.submissionCode;
    
    for(let i = 0; i < this.secondaryChart.series.length; i++){
      let regexp = new RegExp(subCode);
      let val = this.secondaryChart.series[i].userOptions.name;
      let sub = regexp.test(val);

      if(sub) {
        submission.visible = false;
        this.secondaryChart.series[i].remove();
        i--;
      }
    }
  }

  toggleGroup(group) {
    let metrics = this.metrics.filter(q => q.aName === group[0]);
    if (metrics[0].visible === true) {
      metrics.forEach(q => q.visible = false);
    } else {
      metrics.forEach(q => q.visible = true);
    }
  }

  changeView(view) {
    this.error = null;
    this.errorReport = null;
    this.errorReportMetrics = null;
    this.errorMetrics = null;
    this.selectedTierName = null;
    this.unsubscribe();

    this.clearSelectedTiers();
      this.tiers.forEach(p => {
        p.childrenOpen = null;
      });

    if (view === 'report') {
      this.router.navigate(['project/' + this.projectId + '/' + view], { queryParams: { date: this.selectedProjectDate.dateId } });
    } else {
      this.router.navigate(['project/' + this.projectId + '/' + view], { queryParams: null });
    }

    if (this.projectId == 52 && view == 'dashboard') {
      // For SE MH, always reset submissions on return to dashboard
      this.getSubmissions(this.projectId);
      this.projectView = view;
    } else {
      this.setView(view);
    }

  }

  reloadReport(reportId) {
    let tier = this.searchableTiers.filter(s => s.reportId === reportId)[0];
    if (tier) {
      this.selectSearchTier(tier);
    }
  }

  export(type) {

    // Data Table - Bar
    if(type === 'dataTable' && this.reportType === 'bar') {
      this.getTableData = true;
      this.csvSettings = {
          fileName: this.reportDetails.reportName + ' - Data for ' + this.selectedProjectDate.dateName,
          headers: ['Submission', 'Result'],
          keys: ['name', 'y'],
          styling: false
        }
    }

    // Regional Averages - Bar
    if(type === 'averages' && this.reportType === 'bar') {
      this.getAverages = true
      this.csvSettings = {
         fileName: this.reportDetails.reportName + ' - ' + (this.projectId == 52 ? 'Regional' : 'National') + ' Averages',
         headers: ['Month', 'Mean', 'Median'],
         keys: ['dateName', 'y', 'median'],
         styling: false
       }
     }

    // Data Table - Stacked
    if(type === 'dataTable' && this.reportType === 'stacked') {
      this.getTableData = true;
      this.csvSettings = {
          fileName: this.reportDetails.reportName + ' - Data for ' + this.selectedProjectDate.dateName,
          headers: ['Submission'].concat(this.tableHeader),
          keys: ['submission'].concat(this.tableHeader),
          styling: false
        }
    }

    // Regional Averages - Stacked
    if(type === 'averages' && this.reportType === 'stacked') {
      let ar = [];
      this.categoryAverages[0].data.forEach(n => {
          ar.push(n.name)
       }),
      this.categoryAverages.forEach(n => {
        n.data.forEach(k => {
          n[k.name] = k.y
        })
      })
      
      this.getAverages = true
      this.csvSettings = {
         fileName: this.reportDetails.reportName + ' - ' + (this.projectId == 52 ? 'Regional' : 'National') + ' Averages',
         headers: ['Type'].concat(ar),
         keys: ['name'].concat(ar),
         styling: false
       }
     }

    // Denominator/Numerator Table - Bar
    if(type === 'denNumTable' && this.reportType === 'bar') {
      let extraHeaders = [];
      let extraKeys = [];
      this.getDenNumTable = true;
      if(this.tableData[0].denominatorValue) {
        extraHeaders.push('Denominator')
        extraKeys.push('denominatorValue')
      }
      if(this.tableData[0].numeratorValue) {
        extraHeaders.push('Numerator')
        extraKeys.push('numeratorValue')
      }

      this.csvSettings = {
          fileName: this.reportDetails.reportName + ' - Denominator/Numerator',
          headers: ['Submission'].concat(extraHeaders),
          keys: ['name'].concat(extraKeys),
          styling: false
        }
    }

    // Chart
    if (type === 'main') {
      this.primaryChart.exportChart(null, {
        credits: { enabled: true, text: 'https://members.nhsbenchmarking.nhs.uk' + this.params },
        chart: {
          spacingBottom: 60, 
          events: {
            render: function() {
              var chart = this,
              width = chart.chartWidth - 600,
              height = chart.chartHeight - 40;
              chart.renderer.image('https://s3.eu-west-2.amazonaws.com/nhsbn-static/Other/2022/nhsbn-logo.png', width, height, 144, 35).add();
            }
          }
        },
        type: 'image/png',
        filename: 'chart-' + this.reportDetails.reportId
      });
      this.createBarChart(this.monthData.data, this.selectedProjectDate.dateName, this.monthData.nationalCalculatedMean);
    }

    // Time series
    if (type === 'timeseries') {
      this.secondaryChart.exportChart(null, {
        credits: { enabled: true, text: 'https://members.nhsbenchmarking.nhs.uk' + this.params },
        chart: {
          spacingBottom: 60, 
          events: {
            render: function() {
              var chart = this,
              width = chart.chartWidth - 600,
              height = chart.chartHeight - 40;
              chart.renderer.image('https://s3.eu-west-2.amazonaws.com/nhsbn-static/Other/2022/nhsbn-logo.png', width, height, 144, 35).add();
            }
          }
        },
        type: 'image/png',
        filename: 'timeseries-' + this.reportDetails.reportId
      });
      if (this.categoryAverages) {
        this.createLineChart(this.categoryAverages);
      }
      else {
        this.setData(this.reportData, this.selectedProjectDate);
      }
    }

    setTimeout(() => {
      this.selectedTiers[11].open = false
    }, 100);
  }

  navBack() {
    this.unsubscribe();
    // Non-ATD projects, back to home
    // ATD projects, back to dashboard
    if ([29,41,42,52,89].includes(this.projectId)) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/dashboard/49'])
    }
  }

  closeMenu() {
    this.searchText = null;
    this.tierOpen = false;
    this.infoPanel ? this.infoPanel.info = false : null;
    this.selectedTiers.forEach(s => {
      s.open = false;
    });
    this.getTableData = false;
    this.getDenNumTable = false;
    this.getAverages = false;
    this.dynamicDocumentsDropdown = false;
  }

  unsubscribe() {
    this.primaryChartOptions = null;
    this.secondaryChartOptions = null;
    this.reportData = null;
    this.reportDetails = null;
    this.reportCategoryTypes = null;
    this.errorReport = null;
    this.error = null;
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  print() {
    window.print();
  }

  // NEW

  new_formatStackedBarData(reportData) {
    let outputData = [];

    reportData.organisationList.forEach(ol => {
      ol.submissionData.forEach(sd => {
        Object.keys(sd.dateCategoryList).forEach(dcl => {
          let date = this.projectDates.filter(pd => pd.dateId === +dcl)[0];
          let highlighted;
          let responses = [];
          
          if (this.submissions) {
            if (sd.anonSubmissionCode) {
              highlighted = this.submissions.filter(p => p.submissionCode === sd.anonSubmissionCode)[0];
            } else {
              highlighted = this.submissions.filter(p => p.submissionCode === sd.submissionCode)[0];
            } 
          } else {
            highlighted = null;
          }

          Object.keys(sd.dateCategoryList[dcl]).forEach(rcl => {
            Object.keys(sd.dateCategoryList[dcl][rcl]).forEach(res => {
              let category = this.reportCategories.filter(rc => rc.categoryId === +res)[0];
              if (category) {
                responses.push({
                  category: category.categoryName,
                  y: sd.dateCategoryList[dcl][rcl][res]
                })
              }
            })
          });

          if (date) {
            outputData.push({
              submission: sd.anonSubmissionCode,
              date: date.dateName,
              responses: responses,
              responsesSum: Math.round(this.sum.transform(responses.map(r => r.y))),
              sortOrder: responses[0].y,
              color: highlighted ? highlighted.color : null
            });
          }
        });
      });
    });

    // Group by month
    outputData = this.group.transform(outputData, 'date');

    Object.keys(outputData).forEach(key => {
      outputData[key] = this.order.transform(outputData[key], ['-responsesSum', 'sortOrder']);
    });
    
    return { byMonth: outputData };

  }

  new_formatCategoryData(projectDate, reportData) {
    projectDate.data = [];

    let categoryCount = this.reportCategories.length;

    reportData.organisationList.forEach(ol => {
      ol.submissionData.forEach(sd => {
        Object.keys(sd.dateCategoryList).forEach(dcl => {
          let data = sd.dateCategoryList[dcl].categoryResponseList;
          let sub;
          if (this.submissions) {
            sub = this.submissions.filter(s => s.submissionCode === sd.anonSubmissionCode)[0];
          } else {
            sub = null;
          }
          let color = null;
          if (sub) { color = sub.color }
          if (projectDate.dateId === +dcl) {
            projectDate.data.push({
              submission: sd.anonSubmissionCode,
              color: color,
              data: Object.keys(data).map(d => { 
                let category = this.reportCategories.filter(rc => rc.categoryId === +d)[0];
                return {
                  name: category.categoryName,
                  y: data[d]
                }
              })
            });
          }
        })
      });
    });

    // Reorder data in order of first category response
    projectDate.data.forEach(pdd => { 
      if (pdd.data.length !== categoryCount) {
        pdd.orderBy = null;
      } else {
        pdd.orderBy = pdd.data[0].y;
      }
    });
    let removeNulls = projectDate.data.filter(pd => pd.orderBy !== null);
    projectDate.data = this.order.transform(removeNulls, '-orderBy');
    
  }

  new_setData(selectedDate, categoryAverages) {
    let categories, series = [];

    this.errorReport = null;

    if (selectedDate.data.length) {
      switch(this.reportType) {

        case 'pie': 
  
          this.tableData = selectedDate.data;
          
          let newSeries = [];
  
          let highlighted = selectedDate.data.filter(d => d.color !== null);
  
          if (highlighted) {
            highlighted.forEach(h => {
              newSeries.push({
                name: h.submission,
                color: h.color,
                data: h.data.map(hd => hd.y)
              })
            })
          }
  
          categories = this.reportCategories.map(c => c.categoryName);
  
          this.reportCategories.forEach((c, i) => {
            series.push({
              name: c.categoryName,
              data: selectedDate.data.map(d => d.data[i].y),
              color: selectedDate.color
            })
          });
  
          series.forEach(s => {
            let count = s.data.length;
            let sum = s.data.reduce((a, b) => a + b, 0);
            s.y = sum / count;
          });
  
          // Add national averages
          let nationalAverages = [];
          categoryAverages = categoryAverages.filter(c => c.category !== 'Medians');
          categoryAverages.forEach(ca => {
            nationalAverages.push(
              ca.data.filter(d => d.name === selectedDate.dateName)[0].y
            )
          });
  
          newSeries.push({
            name: 'National Means',
            data: nationalAverages
          });
  
          this.new_createRadarChart(categories, newSeries);
  
          break;
  
        default:
          this.error = 'Chart type has not been set.'
      }
    } else {
      this.errorReport = 'There is no data for the selected period. Please select another period from the menu above.'
    }
  }

  new_createRadarChart(categories, series) {
    let tooltipText;
    if (this.reportSettings.formatModifier === 'P') {
      tooltipText = '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:0.25em"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}%</td></tr>'
    } else {
      tooltipText = '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:0.25em"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>'
    }

    this.primaryChartOptions = {
      chart: {
        polar: true,
        type: 'line',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent',
        events: { 
          load: (e => {
            this.primaryChart = e.target;
            this.createLegend(this.primaryChart);
          })
        },
      },
      colors: this.setColour,
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.key}</div><table>',
        pointFormat: tooltipText,
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' }
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories
      },
      yAxis: {
        min: this.reportSettings.min ? this.reportSettings.min : null,
        max: this.reportSettings.max ? this.reportSettings.max : null,
        tickInterval: this.reportSettings.tickInterval ? this.reportSettings.tickInterval : null,
        gridLineColor: '#EEF1F8'
      },
      plotOptions: {
        series: {
          animation: false
        }
      },
      series: series
    };
  }

  enableColorBlindness() {
    if(this.colorBlindnessEnabled == false){
      this.primaryBarColour = '#88CCEE';
      this.secondaryBarColour = '#332288'
      this.nationalMeanColour = '#882255';
      this.nationalMedianColour = '#117733';
      this.setColour = this.colourBlindCollection;
      this.colorBlindnessEnabled = true;
    } else {
      this.primaryBarColour = this.globalColors.primaryBarColour;
      this.secondaryBarColour = this.globalColors.secondaryBarColour;
      this.nationalMeanColour = this.globalColors.nationalMeanColour;
      this.nationalMedianColour = this.globalColors.nationalMedianColour;
      this.setColour = this.colourCollection;
      this.colorBlindnessEnabled = false;
    }
    this.setView(this.projectView);
  }

  linkToFeedbackForm() {
    let airtableFormUrl = 'https://airtable.com/shro8tKYNltABl5BK?prefill_Source%20URL=';
    let currentUrl = 'https://members.nhsbenchmarking.nhs.uk' + this.router.url;
    window.open(airtableFormUrl + encodeURIComponent(currentUrl), '_blank');
  }

  downloadImage(){
    html2canvas(this.fullscreen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'semh-fullscreen.png';
      this.downloadLink.nativeElement.click();
    });
  }

  downloadReportImage(){
    let submissionCode: string;
    if(this.selectedSubmission == undefined) {
      submissionCode = 'NHSBN';
    } else {
      submissionCode = this.selectedSubmission.submissionCode;
    }
    
    if(this.projectView !== 'toolkit') {
      this.stopMassDownload = true;
      window.location.reload();
    } else {
      html2canvas(this.reportDownloadView.nativeElement).then(canvas => {
        this.reportCanvas.nativeElement.src = canvas.toDataURL();
        this.downloadReportLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadReportLink.nativeElement.download = submissionCode + '-' + this.selectedTierName + `(${this.selectedProjectDate.dateName})` ;
        this.downloadReportLink.nativeElement.click();
      });
    }
  }
}