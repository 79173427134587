<!-- Tier Modal -->
<div bsModal #selectTierModal="bs-modal" class="modal" tabindex="-1" role="dialog" (onHide)="closeModal()">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- Tiers -->
      <ng-container *ngIf="selectedTier">   
        <div class="modal-header">
          <h5 class="modal-title">{{ selectedTier.tierName }}</h5>
        </div>
        <div class="modal-body">
          <app-tier-tree [apiTiers]="selectedTier.childrenTiers" (reportTier)="reportTier.emit($event)"></app-tier-tree>
        </div>
      </ng-container>
      <!-- Tags -->
      <ng-container *ngIf="selectedTag">
        <div class="modal-header">
          <h5 class="modal-title">Tag: {{ selectedTag }} ({{ tiersWithTag.length }})</h5>
        </div>
        <div class="modal-body">
          <input class="form-control search-input mb-3" type="text" [(ngModel)]="query" placeholder="Search indicators..." />  
          <div *ngFor="let tier of tiersWithTag | filterBy: ['searchName']: query" class="mb-2">
            <div (click)="reportTier.emit(tier.reportId + '-' + tier.searchTier)">
              <small class="text-primary">
                {{ tier.tierNameMap.join(' > ') }}
              </small><br>
              <strong class="tier-name">{{ tier.searchName }}</strong><br>
              <small class="text-muted">
                Tags: {{ tier.tierTags?.join(', ') }}
              </small>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-8">
    <!-- Themes -->
    <ng-container *ngIf="themes">
      <h3>
        <i class="fas fa-analytics mr-1"></i>
        Themes
      </h3>
      <p class="text-muted">
        Indicators grouped into themes with the option of adding secondary, comparator metrics to view correlations.
      </p>
      <div class="row">
        <div class="col-sm-6" *ngFor="let tier of themes">
          <h4 class="text-primary modal-link" (click)="getChildrenTiers(tier); selectTierModal.show()">{{ tier.tierName }}</h4>
        </div>
      </div>
    </ng-container>
    <!-- Domains -->
    <ng-container *ngIf="domains">
      <hr>
      <h3>
        <i class="fas fa-folders mr-1"></i>
        Domains
      </h3>
      <p class="text-muted">
        Indicators grouped by domains. Click an indicator group to open.
      </p>
      <div class="row">
        <div class="col-sm-6" *ngFor="let tier of domains">
          <h4 class="text-primary modal-link" (click)="getChildrenTiers(tier); selectTierModal.show()">{{ tier.tierName }}</h4>
        </div>
      </div>
    </ng-container>
    <!-- Tags -->
    <ng-container *ngIf="tags">
      <hr>
      <h3>
        <i class="fas fa-tags mr-1"></i>
        Tags
      </h3>
      <p class="text-muted">
        Indicators grouped by tags. Click a tag to see all indicators with that tag.
      </p>
      <div class="row">
        <div class="col-sm-12">
          <input class="form-control search-input mb-3" type="text" [(ngModel)]="tagQuery" placeholder="Search tags..." />
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="tagQuery && tagQuery.length > 2">
          <div class="col-sm-6" *ngFor="let tag of filteredTags">
            <h4 class="text-primary modal-link" (click)="getTags(tag); selectTierModal.show()">{{ tag }}</h4>
          </div>
        </ng-container>
        <ng-container *ngIf="!tagQuery || (tagQuery && tagQuery.length < 3)">
          <div class="col-sm-6" *ngFor="let tag of (showAllTags ? tags : tags.slice(0, 9))">
            <h4 class="text-primary modal-link" (click)="getTags(tag); selectTierModal.show()">{{ tag }}</h4>
          </div>
          <div class="col-sm-6">
            <h4 class="text-muted modal-link" (click)="showAllTags = !showAllTags">
              {{ showAllTags ? 'Show less tags' : 'Show all ' + tags.length + ' tags' }}
            </h4>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="col-md-4">
    <h3>
      <i class="fas fa-bullhorn mr-1"></i>
      Latest Updates
    </h3>
    <p class="text-muted">
      The latest news and changes to the National Indicators.
    </p>
    <div class="mt-4">
      <h4><strong>New reports:</strong></h4>
      <p>
        Data from the Friends and Family Test regarding experiences in A&E, Mental Health, Maternity, and Outpatients services has been added to the “Quality and Outcomes” domain. This data is available at the ICB level up to August 2024 and will be updated monthly.
      </p>
      <h4><strong>Data from the following monthly data collections have been updated to September 2024:</strong></h4>
      <ul>
          <li><a href="https://digital.nhs.uk/data-and-information/publications/statistical/appointments-in-general-practice" target="_blank">Appointments in General Practice</a></li>
          <li><a href="https://digital.nhs.uk/data-and-information/publications/statistical/primary-care-dementia-data" target="_blank">Primary Care Dementia Data</a></li>
          <li><a href="https://digital.nhs.uk/data-and-information/publications/statistical/general-and-personal-medical-services" target="_blank">General and Personal Medical Services</a></li>
          <li><a href="https://www.england.nhs.uk/statistics/statistical-work-areas/ae-waiting-times-and-activity/" target="_blank">A&E Waiting Times and Activity</a></li>
          <li><a href="https://www.england.nhs.uk/statistics/statistical-work-areas/ambulance-quality-indicators/" target="_blank">Ambulance Quality Indicators</a></li>
      </ul>
      
      <h4><strong>Data from the following monthly data collections have been updated to August 2024:</strong></h4>
      <ul>
          <li><a href="https://digital.nhs.uk/data-and-information/publications/statistical/nhs-talking-therapies-monthly-statistics-including-employment-advisors" target="_blank">NHS Talking Therapies Monthly Statistics Including Employment Advisors</a></li>
          <li><a href="https://digital.nhs.uk/data-and-information/publications/statistical/mental-health-services-monthly-statistics" target="_blank">Mental Health Services Monthly Statistics</a></li>
          <li><a href="https://digital.nhs.uk/data-and-information/publications/statistical/learning-disabilities-health-check-scheme" target="_blank">Learning Disabilities Health Check Scheme</a></li>
          <li><a href="https://www.england.nhs.uk/statistics/statistical-work-areas/rtt-waiting-times/" target="_blank">RTT Waiting Times</a></li>
          <li><a href="https://www.england.nhs.uk/statistics/statistical-work-areas/iucadc-new-from-april-2021/" target="_blank">IUCADC (New from April 2021)</a></li>
      </ul>
      
      <h4><strong>Data from the following monthly data collections have been updated to July 2024:</strong></h4>
      <ul>
          <li><a href="https://opendata.nhsbsa.net/dataset/english-prescribing-data-epd" target="_blank">English Prescribing Data (EPD)</a></li>
          <li><a href="https://digital.nhs.uk/data-and-information/publications/statistical/maternity-services-monthly-statistics" target="_blank">Maternity Services Monthly Statistics</a></li>
          <li><a href="https://digital.nhs.uk/data-and-information/publications/statistical/nhs-workforce-statistics" target="_blank">NHS Workforce Statistics</a></li>
          <li><a href="https://www.england.nhs.uk/statistics/statistical-work-areas/2-hour-urgent-community-response/" target="_blank">2-Hour Urgent Community Response</a></li>
      </ul>
    </div>

  </div>
</div>