import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ValidationService } from '../../utilities/services/validation.service'
import { DataService } from '../../utilities/services/data.service'

import { UniquePipe } from 'ngx-pipes';

@Component({
  selector: 'app-active-admin',
  templateUrl: './active-admin.component.html',
  styleUrls: ['./active-admin.component.scss'],
  providers: [ UniquePipe ]
})
export class ActiveAdminComponent implements OnChanges {
  @Input() fullValidation;
  @Input() projectDetails;
  @Input() projectId;
  @Output() validationsEmitter: EventEmitter<any> = new EventEmitter();
  @Output() fullValidationEmitter: EventEmitter<boolean> = new EventEmitter();
  validations: any = [];
  responses: any = [];
  activeValidation: number;
  mailtoPl: string; mailtoDpls: string;
  showValidated: boolean = true;
  validationNote: FormGroup;
  selectedValidation: number;
  currentValidation: number;
  loading: boolean;
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [ 'Status', 'Tier ID', 'Report ID', 'Report Name', 'Metric Position', 'Submission Code', 'Submission ID', 'Submission Name', 'Service Name', 'Question ID', 'Question Part', 'Question Text', 'Question Response', 'Notes', 'Validator', 'Data Collection Link', 'Chart Link'  ],
    useBom: false,
    removeNewLines: true,
    keys: [ 'status', 'tierId', 'reportId', 'reportName', 'validationValue', 'submissionCode', 'submissionId', 'submissionName', 'serviceItemName', 'questionId', 'questionPart', 'questionText', 'response', 'notes', 'validatorName', 'dataCollectionLink', 'chartLink' ]
  };

  constructor(
    private validationService: ValidationService,
    private dataService: DataService,
    private router: Router,
    public unique: UniquePipe,
    private fb: FormBuilder
  ) { }

  ngOnChanges() {
    this.getValidations(this.projectId);
  }

  getValidations(projectId) {
    // TODO: Add year selector to HTML
    let year = 2022;
    this.loading = true;
    this.validationService.getValidations(projectId, year).subscribe(
      r => {
        //Might be better placed in the API - might be better to do it without consent...
        r.data.validations.forEach(e => {
          if (e.questionId) {
            if (e.serviceItemId > 0) {
              e.dataCollectionLink = 'Data Collection Link: https://members.nhsbenchmarking.nhs.uk/data-collection/' + e.questionGroupLevel + '/' + e.submissionId + '/' + e.validationYear + '/' + e.serviceItemId + '?group=' + + e.questionGroupId;
            } else {
              e.dataCollectionLink = 'Data Collection Link: https://members.nhsbenchmarking.nhs.uk/data-collection/' + e.questionGroupLevel + '/' + e.submissionId + '/' + e.validationYear + '?group=' + + e.questionGroupId;
            }
          }
          else {
            e.dataCollectionLink = '';
          }

          if (e.status === 'null' || e.status === null) { e.status = '' }
          if (e.notes === 'null' || e.notes === null) { e.notes = '' }

          e.chartLink = 'Chart Link: https://members.nhsbenchmarking.nhs.uk/outputs/' + this.projectId + '?tier=' + e.tierId;
        });
        
        this.validations = r.data.validations;

        // Add group name
        this.validations.forEach(v => {
          v.groupName = v.submissionName + ' (' + ('000' + v.submissionCode).slice(-3) + ')'
        })

        this.validationsEmitter.emit(this.validations);
        if (this.validations.length === 0) {
          this.fullValidationEmitter.emit(false);
        }
        this.loading = false;
      },
      e => {
        console.log(e);
        this.loading = false;
      }
    )
  }

  toggleValidated() {
    if (this.showValidated) {
      this.validations = this.validations.filter(v => v.status != "validated");
      this.showValidated = false;
    } else {
      this.getValidations(this.projectId);
      this.showValidated = true;
    }
  }

  getValidationResponses(validationId, reportId, year, submissionId, serviceItemId) {
    this.validationService.getValidationResponses(reportId, year, submissionId, serviceItemId).subscribe(
      r => {
        this.responses = r.data.responses;
        this.activeValidation = validationId;
      },
      e => {
        console.log(e);
      }
    )
  }

  saveResponse(validationId, submissionId, questionId, questionPart, serviceItemId) {
    let validationResponse = {
      validationId: validationId, 
      submissionId: submissionId, 
      questionId: questionId, 
      questionPart: questionPart, 
      serviceItemId: serviceItemId
    }
    this.validationService.addValidationResponse(validationResponse).subscribe(
      r => {
        this.validations = this.validations.filter(v => v.validationId !- validationId);
        this.getValidation(validationId);
        this.responses = [];
        this.activeValidation = null;
      },
      e => {
        console.log(e);
      }
    )
  }

  duplicatePoint(point){
    let validation = {
      validationId: undefined,
      year: point.validationYear,
      //userId: this.dataService.getUser().userId,
      tierId: point.tierId,
      submissionCode: point.submissionCode,
      validationValue: point.validationValue,
      serviceItemId: point.serviceItemId
    }
    this.validationService.addValidation(validation).subscribe(
      r => {
        this.getValidation(r.data.newValidationId);
      },
      e => {
        console.log(e)
      }
    )
  }

  getValidation(validationId) {
    this.validationService.getValidation(validationId).subscribe(
      r => {
        var newValidation = r.data.validation[0];
        var newValidations = [];
        this.validations.forEach(v => {
          newValidations.push(v);
        });
        newValidations.push(newValidation);
        this.validations = newValidations;
      },
      e => {
        console.log(e)
      }
    )
  }

  changeValidationStatus(validationId, status) {
    let validation = {
      validationId: validationId,
      status: status
    }
    this.validationService.changeValidationStatus(validation).subscribe(
      r => {
        if (status === undefined) {
          this.validations.filter(v => v.validationId === validationId)[0].status = null;
        } else {
          this.validations.filter(v => v.validationId === validationId)[0].status = status;
        }
        
      },
      e => {
        console.log(e)
      }
    )
  }

  // NOT IN USE
  // changeValidationVisibility(validationId, visibility) {
  //   let validation = {
  //     validationId: validationId,
  //     visibility: visibility
  //   }
  //   this.validationService.changeValidationVisibility(validation).subscribe(
  //     r => {
  //       this.validations = [];
  //       this.getValidations(this.projectId);
  //     },
  //     e => {
  //       console.log(e)
  //     }
  //   )
  // }

  buildValidationForm(validationId, validationResponseId) {
    this.selectedValidation = validationId;
    this.currentValidation = validationResponseId;
    let note = this.validations.filter(v => v.validationResponseId === validationResponseId)[0].notes;
    this.validationNote = this.fb.group({    
      validationNote: ['', [Validators.required, Validators.maxLength(200)]]
    });
    this.validationNote.setValue({
      validationNote: note
    });
  }

  editValidationNote() {
    let validation = {
      validationResponseId: this.currentValidation,
      note: this.validationNote.value.validationNote
    }
    this.validationService.editValidationNote(validation).subscribe(
      r => {
        this.validations.filter(v => v.validationId === this.selectedValidation)[0].notes = this.validationNote.value.validationNote;
        this.currentValidation = null;
        this.selectedValidation = null;
      },
      e => {
        console.log(e)
      }
    )
  }

  emailLeads(validationId, projectId, year, submissionId){
    let body = [];
    let validations = this.validations.filter(v => v.submissionId === submissionId)
    validations = this.unique.transform(validations, 'questionId');
    validations.forEach((e) => {
      if (e.questionId && e.status != 'validated') {
        body.push(
          e.questionText + '%0ACurrent Value: ' + e.response + 
          '%0AData Collection Link: https://members.nhsbenchmarking.nhs.uk/data-collection/' + e.questionGroupLevel.toLowerCase() + '/' + e.submissionId + '/' + e.validationYear + '?group=' + + e.questionGroupId + 
          '%0AChart Link: https://members.nhsbenchmarking.nhs.uk/outputs/' + this.projectId + '?tier=' + e.tierId +
          '%0ANotes: ' + e.notes + '%0A%0A'
        )
      }
    })
    let projectLeadEmail = this.validations.filter(v => v.validationId === validationId)[0].projectLeadEmail;
    this.mailtoPl = '<a href="mailto:' + projectLeadEmail + '?subject=Data%20Validation&body=' + body.join('') + '">Email PL (only)</a>'
    let noDeputyProjectLeads = this.validations.filter(v => v.validationId === validationId)[0].deputyProjectLeads;
    if (noDeputyProjectLeads > 0) {
      this.validationService.getDeputyProjectLeads(projectId, year, submissionId).subscribe(
        r => {
          let deputyProjectLeads = r.data.map(d => d.email).join(';');
          this.mailtoDpls = '<a href="mailto:' + projectLeadEmail + '?cc=' + deputyProjectLeads + '&subject=Data%20Validation&body=' + body.join('') + '">Email PL and DPLs</a>'
        },
        e => {
          console.log(e)
        }
      )
    }
  }

  deleteValidation(validationId) {
    if (confirm("Are you sure you want to delete this validation? This action cannot be undone.")) {
      this.validationService.deleteValidation(validationId).subscribe(
        r => {
          this.validations = this.validations.filter(v => v.validationId !== validationId);
        },
        e => {
          console.log(e);
        }
      )
    }
  }

  navChart(tierId) {
    this.router.navigate(['/outputs', this.projectId], { queryParams: { tier: tierId } });
    this.fullValidation = false;
  }

}