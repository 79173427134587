import { UserRole } from './user-role'

export class User {
    constructor (
        public userId: number,
        public logonName: string,
        public fullName: string,
        public jobTitle: string,
        public phoneNo: string,
        public lastLogon: Date,
        public roles: UserRole[],
        public welcomeDialogDismissed: string,
        public mailchimpOptIn: string,
        public notificationOptIn: string
    ) {}
}