import { Component, Input, OnChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MemberService } from "../../utilities/services/member.service";
import { Project } from "../../utilities/classes/project/project";
import { LoaderService } from "../../utilities/services/loader.service";
import * as moment from 'moment';

interface Section {
  title: string;
  projects: Array<Project>;
}

type ProjectApiResponse = { [key: string]: Array<Project> }
@Component({
  selector: "app-project-table",
  templateUrl: "./project-table.component.html",
  styleUrls: ["./project-table.component.scss"],
})
export class ProjectTableComponent implements OnChanges {
  @Input() organisationSelection: number;

  public sections: Array<Section> = null;
  public archived: Section;
  public errorRegistrationNA: string = "Registration is not currently available for this project"; // Error codes: 2101, 2103
  public errorDataCollectionNA: string = "Data collection is not currently available for this project."; // 2102
  public errorOrganisationAccess: string = "Your organisation does not currently have access to this project. Please contact support."; // 2104, 2105, 2106
  public errorUserAccess: string = "You do not currently have access to this project. Please contact support."; // 2107, 2108, 2109

  public showHistorical: boolean = false;
  public showOtherProjects: boolean = true;
  public showActiveProjects: boolean = true;
  public searchText: string = '';
  public currentDate: any = new Date();
  public filterSections: Array<Section> = null;
  public sortButton: boolean = false;
  public selectedItem: string = 'Filter by sector...';
  public defaultSections: Array<Section> = null;
  public reset: boolean = false;

  constructor(
    private memberService: MemberService,
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService
  ) {}

  ngOnChanges() {
    if (this.organisationSelection) {
      this.getProjectAccess();
    }
  }

  getProjectAccess() {
    this.memberService.ProjectAccess(this.organisationSelection).subscribe(
      (p) => {
        if (p.data) {
          this.sections = Object.entries(p.data as ProjectApiResponse).map(([sectionTitle, projects]) => {
            return {
              title: sectionTitle,
              projects: projects
            }
          })
          this.defaultSections = this.sections
          let i = this.sections.findIndex(
            (item) => item.title === "Archived"
          );

          if (i > -1) {
            this.archived = this.sections[i];
            this.sections.splice(i, 1);
          }

          this.filterSections = this.sections;

          this.sections.forEach(section => {
            section.projects.forEach(project => {
              var openDate: any = new Date(project.dataCollectionOpenDate);
              var closeDate: any = new Date(project.dataCollectionCloseDate);

              // Active project if it has a 'registrationId'
              if(project.registrationId) {
                project.activeProject = true;
              } else {
                project.activeProject = false;
              }
          
              // Checking if Data Collection is closed
              if(this.currentDate.getTime() > closeDate.getTime()) {
                if(project.year !== null) {
                  project.status =  `Data collection closed for ${ project.year }/${ ((project.year + 1).toString()).slice(2,4) }`;
                }
                project.statusColor = 'red';
              }

              // Checking if Data Collection is open or soon to be
              if(openDate.getTime() > this.currentDate.getTime()) {
                project.status = 'Data collection opens ' + moment(openDate).format('MMMM Do');
                project.statusColor = '#005EB8';
              }
              else if(closeDate.getTime() > this.currentDate.getTime()) {
                var closingDays: any = Math.floor((closeDate - this.currentDate) / (1000 * 60 * 60 * 24));
                project.status = 'Data collection closes in ' + closingDays + ' days';
                if(closingDays > 14) {
                  project.statusColor = 'green';
                } else {
                  project.statusColor = 'orange';
                }
              }
            })
          });
        }
        //Check if filters is in URL
        if(this.route.snapshot.queryParams.filter && !this.reset){
          this.onChange(this.route.snapshot.queryParams.filter)
        }
      },
      error => {
        console.log(error);
      });
  }

  getRegistrationClosedTooltip(p: Project) {
    if (p.signUpErrorCode == 2101 || p.signUpErrorCode == 2103) {
      return this.errorRegistrationNA;
    }

    if ((p.signUpErrorCode > 2103 && p.signUpErrorCode < 2107) || p.signUpErrorCode == 2110) {
      return this.errorOrganisationAccess;
    }

    if (p.signUpErrorCode == 2107 || p.signUpErrorCode == 2108) {
      return this.errorUserAccess;
    }

    return null;
  }

  resetProjectList() {
    this.reset = true
    this.getProjectAccess();
    this.selectedItem = 'Filter by sector...';
    this.sortButton = false; 
    this.searchText = '';
    this.router.navigate([],);
  }

  onChange(item) {
    this.sections = this.defaultSections
    this.router.navigate([], { queryParams: { filter: item }, queryParamsHandling: 'merge' });
    this.selectedItem = item;
    this.sections = this.sections.filter(el => el.title == item);
    this.sortButton = true;
  }

  sortList() {
    this.sections.sort(function(a, b) {
      return a.title.localeCompare(b.title);
    });
    this.sortButton = true;
  }

  getDataCollectionClosedTooltip(p: Project) {
    if (p.accessProjectErrorCode == 2102) {
      return this.errorDataCollectionNA;
    }

    if (p.accessProjectErrorCode > 2103 && p.accessProjectErrorCode < 2107) {
      return this.errorOrganisationAccess;
    }

    if (p.accessProjectErrorCode == 2107 || p.accessProjectErrorCode == 2109) {
      return this.errorUserAccess;
    }

    return null;
  }

  navProjectDetail(projectId: number) {
    this.router.navigate(["/project-detail", projectId]);
  }

  navProjectOverview(projectId: number) {
    this.router.navigate(["/project-overview", projectId]);
  }

  navDashboard(projectId: number) {
    if (projectId == 39) {
      this.router.navigate(["/ics"]);
    } else {
      this.router.navigate(["/dashboard", projectId]);
    }
  }

  navProjectRegistration(projectId: number, organisationId: number) {
    this.router.navigate(["/project-registration", projectId, organisationId]);
  }
}
