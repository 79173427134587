<div class="print-page-header"></div>

<div class="print-page-section" *ngFor="let section of charts">
    <div class="print-page-section-title">
        {{ section.reportName }}
    </div>
    <div class="print-page-section-chart">
        <div [ng2-highcharts]="section.primaryChartOptions"></div>
    </div>
    <div class="print-page-section-row" *ngIf="section.dataTable">
        <div class="print-page-section-cols">
            <div class="print-page-section-timeseries">
                <div [ng2-highcharts]="section.secondaryChartOptions"></div>
            </div>
            <div class="print-page-section-table">
                <div class="print-page-section-table-title">Data for {{ selectedProjectDate.dateName }}</div>
                <table class="table table-condensed table-bordered table-sm">
                    <tr *ngFor="let t of section.dataTable">
                        <td>{{ t.name }}</td>
                        <td>{{ t.y | number: '1.0-2' }}</td>
                    </tr>
                    <tr *ngIf="projectId == 29">
                        <td>Benchmark</td>
                        <td>{{ section.benchmark | number: '1.0-2' }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="print-page-section-row -footer">
    <div class="print-page-section-cols">
        <div class="print-page-footer-left">
            &copy; NHS Benchmarking Network {{ copyrightYear }}
        </div>
        <div class="print-page-footer-right">
            Page {{ index }}
        </div>
    </div>
</div>