import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ContactRoutingModule } from "./contact-routing.module";
import { ContactComponent } from "./contact.component";

import { AuthService } from "../utilities/services/auth.service";
import { AuthGuardService } from "../utilities/services/auth-guard.service";

import { CollapseModule } from "ngx-bootstrap/collapse";

@NgModule({
  declarations: [ContactComponent],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    ContactRoutingModule,
    CollapseModule,
  ],
  providers: [AuthService, AuthGuardService],
})
export class ContactModule {}
