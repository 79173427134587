import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ResponsiveModule } from "ngx-responsive";
import { AccordionModule } from "ngx-bootstrap";

import { HelpComponent } from "./help.component";
import { HelpRoutingModule } from "./help-routing.module";

import { AuthService } from "../utilities/services/auth.service";
import { AuthGuardService } from "../utilities/services/auth-guard.service";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { SafePipeModule } from "../utilities/pipes/safe-pipe.module";

@NgModule({
  imports: [
    CommonModule,
    ResponsiveModule,
    HelpRoutingModule,
    CollapseModule,
    SafePipeModule,
    AccordionModule.forRoot(),
  ],
  declarations: [HelpComponent],
  providers: [AuthService, AuthGuardService],
})
export class HelpModule {}
