<!-- MENU BACKGROUND -->
<div class="menu-background" *ngIf="menuSelect[0].open || menuSelect[1].open || menuSelect[2].open" (click)="closeMenu()"></div>

<div class="container-fluid" *ngIf="error">
  <!-- ERRORS -->
  <div class="loading-data error">
    <p>There has been an error loading the data collection.</p>
    <p>{{ error }}</p>
    <p (click)="navHome()">Click here to exit the page.</p>
  </div>
</div>

<!-- LOADING -->
<div class="container-fluid" *ngIf="!organisations && !error">
  <div class="loading-data" >
    <p><i class="far fa-spinner fa-spin"></i> Loading data collection page...</p>
  </div>
</div>

<div class="container-fluid" *ngIf="!error && organisations">

  <!-- SELECTOR -->
  <div class="row" id="selectorBar" *ngIf="organisations">
      <div class="col-sm-1 col-xs-2">
        <div class="button" [routerLink]="'/project-overview/' + projectId">Back</div> 
      </div>
      <div class="col-sm-11 col-xs-10 text-right">
          <button type="button" class="button" (click)="menuSelect[0].open = !menuSelect[0].open">
              {{ selectedOrganisation ? selectedOrganisation.organisationName : 'Select organisation...' }}
              <i *ngIf="organisations.length > 1" [class]="menuSelect[0].open ? 'fas fa-caret-up ml-05' : 'fas fa-caret-down ml-05'"></i>
          </button>
          <div class="menu-container" *ngIf="menuSelect[0].open && organisations.length > 1">
              <div class="menu-select -right">
                  <div class="menu-context">
                      <p>Select the organisation for which you would like to submit data.</p>
                      <div class="selector-group" *ngFor="let o of organisations">
                          <div [class]="selectedOrganisation.organisationId === o.organisationId ? 'selector-container -selected' : 'selector-container'">
                              <div class="row">
                                  <div class="col-xs-12" (click)="selectOrganisation(o); menuSelect[0].open = false">
                                      {{ o.organisationName }}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="row background">

    <!-- INTRODUCTION -->
    <div class="col-md-8 col-md-offset-2">
      <div id="intro">
        <h1>Data Collection</h1>
        <h2>{{ projectName }}</h2>
        <p *ngIf="projectDetails && projectDetails.projectCollectionIntro" [innerHtml]="projectDetails.projectCollectionIntro"></p>
      </div>
    </div>

    <!-- Wrong Browser -->
    <div *showIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']">
      <div class="col-md-8 col-md-offset-2 col-sm-12">
        <section id="wrongBrowser">
          <h3>Your browser is not supported.</h3>
          <p>
            <a href="/help" target="_blank">Click here for more information and links to alternative browsers.</a>
          </p>
        </section>
      </div>
    </div>

    <div *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']">

      <!-- RIBBON -->
      <div class="col-md-8 col-md-offset-2 col-sm-12">

        <div class="row" id="ribbonBar">

          <!-- Period -->
          <div class="col-sm-4">
            <h5>Select period</h5>
            <div [class]="selectedProjectDate ? 'tier-toggle' : 'tier-toggle -select'" (click)="toggleMenu(1)">
              <div>{{ selectedProjectDate ? selectedProjectDate.dateName : 'Select a period...' }}</div>
              <i [class]="menuSelect[1].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
            </div>
            <div class="menu-container">
              <div class="menu-select" *ngIf="menuSelect[1].open">
                <div *ngFor="let date of projectDates" [class]="date.dateId === selectedProjectDate.dateId ? 'tier -selected' : 'tier'" (click)="getCollection(projectId, date, selectedSubmission.submissionId); toggleMenu(1)">
                  <div [class]="date.activeAdmin ? 'highlight-admin' : ''">{{ date.dateName }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Submission -->
          <div class="col-sm-6">
            <h5>Select submission</h5>
            <div [class]="selectedSubmission ? 'tier-toggle' : 'tier-toggle -select'" (click)="toggleMenu(2)">
              <div>{{ selectedSubmission.submissionCode}}: {{ selectedSubmission.submissionName }}</div>
              <i [class]="menuSelect[2].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
            </div>
            <div class="menu-container">
              <div class="menu-select" *ngIf="menuSelect[2].open">
                <div *ngFor="let sub of submissions" [class]="sub.submissionId === selectedSubmission.submissionId ? 'tier -selected' : 'tier'" (click)="changeSubmission(sub); toggleMenu(2)">
                  {{ sub.submissionCode }}: {{ sub.submissionName }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Instructions -->
      <div class="col-md-8 col-md-offset-2 col-sm-12" *ngIf="!questions && !loadingQuestions">
        <div id="instructions">
          <p class="text-center">
            Please select the submission and data collection period for which you would like to enter data using the selectors above.
          </p>    
        </div>
      </div>

      <!-- Loading -->
      <div class="loading-questions" *ngIf="loadingQuestions">
        <i class="far fa-spinner fa-spin"></i> Loading questions. Please wait...
      </div>

      <!-- QUESTIONS -->
      <div *ngIf="questions">

        <div class="col-md-8 col-md-offset-2 col-sm-12">
          <section id="excelImport">
            <h3>Copy and Paste from Excel <span class="badge badge-success ml-05">beta</span></h3>
            <p style="margin-bottom:1em;">
              You can copy responses from the data collection specification into this online page. Click <strong>Show Paste Area</strong> to continue, or click 'Guidance' for a step-by-step guide. <i>Please remember to check and save the responses once they have been added to the form.</i>
            </p>
            <button class="btn btn-primary" (click)="showPaste = !showPaste"><i class="far fa-paste mr-05"></i>{{ showPaste ? 'Hide' : 'Show' }} Paste Area</button>
            <button class="btn btn-default ml-05" (click)="guidanceModal.show()"><i class="far fa-question-circle mr-05"></i>Guidance</button>
            <div *ngIf="showPaste">
              <p>
                You are currently viewing the data collection page for <strong>{{ csvDate }}</strong>. Please make sure you include this column from the Excel spreadsheet.
              </p>
              <textarea id="csvInput" rows="10" class="form-control" placeholder="Paste cells here..." #csvTextarea></textarea>
              <button class="btn btn-success" (click)="fromCSV('csvInput')">
                <i *ngIf="csvSuccess" class="far fa-check-circle mr-05"></i>{{ csvSuccess ? 'Added' : 'Add to Form' }}
              </button>
              <button class="btn btn-default ml-05" (click)="csvErrors = null; csvTextarea.value = null; csvSuccess = false">Clear Input</button>
              <div *ngIf="collectionForm.dirty && collectionForm.invalid && !errorSave" class="csvErrors">
                There are errors on the data collection form below. You will not be able to save until these errors are cleared.
              </div>
              <div *ngIf="csvErrors && csvErrors.length" class="csvErrors">
                <strong>Please be aware of the following issues:</strong>
                <ul>
                  <li *ngFor="let error of csvErrors">{{ error }}</li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        <!-- MODAL: COPY/PASTE GUIDANCE -->
        <div class="modal fade bs-example-modal-lg" bsModal #guidanceModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="guidanceModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="guidanceModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" id="guidanceModalLabel">Copy and Paste Guidance</h5>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-8">
                    <h3>Step 1: Copy from Excel</h3>
                    <p *ngIf="projectId === 42">
                        Open the Excel spreadsheet containing the responses. For both response sheets (<strong>Community MH Services <u>and</u> Inpatient MH Services</strong>) and select the 'Ref' and month columns from, and including, the row headers down to the end of the collection tables in each sheet and copy the contents using <strong>CTRL+C</strong>.
                    </p>
                    <p *ngIf="projectId === 29">
                      Open the Excel spreadsheet containing the monthly responses and select the 'Ref' and all date columns, including the row headers, down to the end of the collection table (<strong>cells D6:Q46</strong>) and copy the contents using <strong>CTRL+C</strong>.
                    </p>
                    <p>
                      Please ensure all validation errors are cleared from the Excel spreadsheet before copying the contents.
                    </p>
                  </div>
                  <div class="col-md-4">
                    <img src="/assets/images/ci_copy.png" alt="Copy and paste guidance: Input paste" class="img-guidance">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <h3>Step 2: Paste into Paste Area</h3>
                    <p>
                      <strong>Select the month that you wish to enter data for. </strong>The copy and paste function will only upload the data within the column that matches the period selected. 
                    </p>
                    <p>
                      Close this modal and select <strong><i class="far fa-paste"></i> Show Paste Area</strong>. Click inside the box that says "Paste cells here..." and paste from the Excel cells using <strong>CTRL+V</strong>. The codes and responses should then show in the box without the table formatting.
                    </p>
                    <p>
                      Finally, click <strong>Add to Form</strong> to populate the form with the data. If data is added successfully, the button will change to added. Should there be any errors, these will show beneath the box and the relevant question(s). The system will automatically select the responses for the data collection period.
                    </p>
                    <p *ngIf="projectId == 42">
                      This will need to be done for both Community MH Services and Inpatient MH Services, and the data will be added to the form below.
                    </p>
                  </div>
                  <div class="col-md-4">
                    <img src="/assets/images/ci_paste.png" alt="Copy and paste guidance: Input paste" class="img-guidance">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <h3>Step 3: Check and Save</h3>
                    <p>
                      Please check that the responses have been added correctly. Once you are happy that the data is correct, click <strong>Save</strong> at the bottom of the page.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-md-offset-2 col-sm-12" *ngIf="collectionClosed">
          <section id="collectionClosed">
            <h3>
              Data Collection Closed
            </h3>
            <p style="margin-top:1em">
              Data collection for the selected period closed on <strong>{{ collectionCloseDate | date: 'EEEE d MMMM y' }}</strong>. You can still view the data submitted in a read-only format, but any changes will need to be made by contacting the project team.
            </p>
          </section>
        </div>

        <form [formGroup]="collectionForm">
          <div *ngFor="let group of questions | groupBy: 'groupName' | pairs" class="col-md-8 col-md-offset-2 col-sm-12">
            <section>
              <div class="row" (click)="toggleGroup(group)">
                <div class="col-xs-10">
                  <h3>{{ group[0] }}</h3>
                </div>
                <div class="col-xs-2 text-right">
                  <h3>
                    <i [class]="group[1][0].visible ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
                  </h3>
                </div>
              </div>
              <div *ngFor="let subGroup of group[1] | groupBy: 'categoryName' | pairs">
                <div [class]="subGroup[0] === 'null' ? 'no-subgroup' : 'subgroup'">
                  <div class="subgroup-header">{{ subGroup[0] }}</div>
                  <div *ngFor="let q of subGroup[1]; let i = index">
                    <div *ngIf="q.visible" [class]="i === 0 ? 'form-group first' : 'form-group'">
                      <!-- Narrative -->
                      <ng-container *ngIf="q.formatModifier === 'NR'">
                        <div class="row narrative">
                          <div [class]="q.code ? 'col-sm-1' : 'hidden-xs'">
                            <p *ngIf="q.code">{{ q.code }}</p>
                          </div>
                          <div [class]="q.code ? 'col-sm-8' : 'col-sm-12'">
                            <p>
                              {{ q.label }}
                            </p>
                          </div>
                        </div>
                      </ng-container>
                      <!-- Number -->
                      <ng-container *ngIf="q.formatModifier !== 'NR'">
                        <div [ngSwitch]="q.type">
                          <div class="row" *ngSwitchCase="'number'">
                            <div [class]="q.code ? 'col-sm-1' : 'hidden-xs'">
                              <label *ngIf="q.code" class="code">{{ q.code }}</label>
                            </div>
                            <div [class]="q.code ? 'col-sm-8' : 'col-sm-9'">
                              <label [for]="q.id">
                                {{ q.label }}&nbsp;<i *ngIf="q.help" class="fas fa-question-circle" (click)="toggleHelp(q)"></i>
                              </label>
                              <div class="help" *ngIf="q.showHelp" [innerHTML]="q.help | safe: 'html'"></div>
                            </div>
                            <div class="col-sm-3">
                              <div class="input-group">
                                <input [id]="q.id" type="number" formControlName="{{q.id}}" attr.data-code="{{q.code}}" class="form-control" />
                                <div *ngIf="q.formatModifier === 'P'" class="input-group-addon">%</div>
                              </div>
                              <div *ngIf="q.formatModifier === 'S'" (click)="autoSum(q)" class="autosum"><i class="fas fa-plus-circle"></i>Calculate total</div>
                            </div>
                            <div class="col-xs-12" *ngIf="q.errors && q.errors.pattern && !q.decimalPlaces">
                              <div class="question-error">
                                The response must be a whole number (no decimal places)
                              </div>
                            </div>
                            <div class="col-xs-12" *ngIf="q.errors && q.errors.pattern && q.decimalPlaces">
                              <div class="question-error">
                                The response must be a number with no more than {{ q.decimalPlaces }} {{ 'decimal place' | makePluralString: q.decimalPlaces }}.
                              </div>
                            </div>
                            <div class="col-xs-12" *ngIf="q.errors && q.errors.min">
                              <div class="question-error">
                                The minimum value for this field is {{ q.rangeMin }}.
                              </div>
                            </div>
                            <div class="col-xs-12" *ngIf="q.errors && q.errors.max">
                              <div class="question-error">
                                The maximum value for this field is {{ q.rangeMax }}.
                              </div>
                            </div>
                          </div>
                        </div> 
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div> 
            </section>
          </div>
          <div class="col-md-8 col-md-offset-2" *ngIf="!collectionClosed">
            <div class="form-actions">
                <p>
                    Click 'Save' below to save your responses. This data collection period can be accessed and edited up to <strong>{{ collectionCloseDate | date: 'EEEE d MMMM y' }}</strong>.
                </p>
                <button class="btn btn-lg btn-success" (click)="submit()" [disabled]="!collectionForm.dirty || collectionForm.invalid || saving">
                    Save
                </button>
                <button class="btn btn-lg btn-default ml-05" (click)="navHome()">Cancel</button>
                <p *ngIf="saving" class="message text-success">
                    Saving data. Please wait...
                </p>
                <p *ngIf="!collectionForm.dirty && !errorSave" class="message text-success">
                    All data is saved and up-to-date.
                </p>
                <p *ngIf="collectionForm.dirty && collectionForm.invalid && !errorSave" class="message text-danger">
                    There are errors on the data collection form above. You will not be able to save until these errors are cleared.
                </p>
                <p *ngIf="errorSave" class="message text-danger">
                    There has been an error whilst trying to save. Please contact the Support Team. ({{ error }})
                </p>
            </div>
          </div>
        </form>

      </div>

    </div>

  </div>

</div>

<div *ngIf="hiddenYear" id="activeAdminBar">
  <button class="btn btn-default" disabled>
    This data collection year is only visible to admins
  </button>
</div>

<!-- ActiveAdmin 
<div *ngIf="activeAdmin" id="activeAdmin">
  <div class="container-fluid">
    <div class="col-sm-10">
      <div *ngIf="collectionOverride">
        <strong><i class="fas fa-exclamation-triangle"></i> This data collection page is read-only to users.</strong> Only amend and save responses if absolutely necessary.
      </div>
      <div *ngIf="selectedProjectDate.activeAdmin">
        <strong><i class="far fa-low-vision"></i> This data collection period is current live-but-hidden.</strong> Admin can see it, but users cannot.
      </div>
    </div>
    <div class="col-sm-2 text-right">
      <strong (click)="showPaste = !showPaste"><i class="far fa-copy"></i> Show Copy/Paste</strong>
    </div>
  </div>
</div> -->