import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { ModalModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ResponsiveModule } from 'ngx-responsive';

import { LandingComponent } from './landing.component';
import { LandingRoutingModule } from './landing-routing.module'

import { SearchPipe } from '../utilities/pipes/search.pipe';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';

@NgModule({
  imports: [ 
    CommonModule, 
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    LandingRoutingModule,
    ResponsiveModule.forRoot(),
    HttpModule,
    HttpClientModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fa fa-spinner fa-spin"></span>',
      disableBtn: true, 
      btnLoadingClass: 'is-loading',
      handleCurrentBtnOnly: false
    })
  ],
  declarations: [ SearchPipe, LandingComponent ]
})
export class LandingModule {}