import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OutputValidationService } from 'app/outputs-validation/outputs-validation.service';
import { CsvDownload } from 'app/utilities/classes/csv/csv-download';
import { ProjectOverviewList } from 'app/utilities/classes/project/project-overview-list';
import { ProjectOverviewValidate } from 'app/utilities/classes/project/project-overview-validate';
import { DataService } from 'app/utilities/services/data.service';
import { MemberService } from 'app/utilities/services/member.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-data-validation',
  templateUrl: './data-validation.component.html',
  styleUrls: ['./data-validation.component.scss']
})
export class DataValidationComponent implements OnInit {
  @HostListener('window:focus') onFocus() {
    this.validations(this.organisationId);
  }

  public data: any;
  public sourceOfTruth: any;
  public year: number;
  public project: string;
  public projectId: number;
  public statusSorted: boolean = false;
  public submissionSorted: boolean = false;
  public organisationId: number;
  public projectOverview: ProjectOverviewValidate;
  public subFilterData: Array<string> = [];
  public organisationList: Array<ProjectOverviewList>;
  public serviceFilterData: Array<string> = [];
  public selectedSubmission: Array<string> = [];
  public selectedOrg: number;
  public selectedFilter: string;
  public extractData: any;
  public selectedValidationResponseId: number;
  public comment: string;
  public organisationDetails: any;
  public chatHistory: any;
  public selectedValidationId: number = null;
  public submissionName: string;
  public displayQuestionText: string;
  public selectedValidation: any;
  public tabsOpen: any = [];
  public showComments: boolean = false;
  public hasWhitespace: boolean = false;

  public csvSettings: CsvDownload = {
    fileName: 'validations',
    buttonText: 'Download (.csv)',
    buttonStyle: 'btn btn-primary',
    icon: '',
    headers: ['Status', 'Question', 'Question Link', 'Toolkit', 'Submission', 'Service', 'Response', 'Notes', 'Comments'],
    keys: ['status', 'displayQuestionText', 'collectionLink', 'reportLink', 'submissionName', 'serviceItemName', 'responses', 'userNote', 'validationDescriptions'],
    styling: true
  }

  @ViewChild('myArea') myTextArea: ElementRef;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private memberService: MemberService,
    private outputValidationService: OutputValidationService
  ) { 
    this.projectId = parseInt(route.snapshot.paramMap.get('projectId'));
    this.year = parseInt(route.snapshot.paramMap.get('year'));
  }

  ngOnInit() {
    this.organisationId = JSON.parse(sessionStorage.getItem('organisationId'));
    this.organisationDetails = JSON.parse(sessionStorage.getItem('organisation'));
    if(this.organisationId == null) {
      this.getOrganisationsByUser();
    } else {
      this.router.navigate([], { queryParams: { id: this.organisationId }, queryParamsHandling: 'merge' });
      this.getProjectOverview(this.projectId);
      this.getTiers(this.projectId, this.year);
    }
  }

  getChatHistory(validationId: number, selectedResponseId: number, data) {
    this.chatHistory = null;
    this.selectedValidationResponseId = selectedResponseId;
    this.selectedValidationId = validationId;
    this.submissionName = this.selectedValidation.submissionName
    this.displayQuestionText = this.selectedValidation.displayQuestionText

    this.memberService.ValidationConversationHistory(validationId).subscribe(
      success => {
        this.chatHistory = success.result;
        this.chatHistory.reverse();
      },
      error => {
        console.log(error);
      }
    )
  }

  changeOrganisation(organisationId) {
    this.dataService.setSelectedOrganisation(organisationId);
    this.data = this.sourceOfTruth;
    this.extractData = this.data;
    this.serviceFilterData = [];
    this.selectedFilter = null;
    this.subFilterData = [];
    this.projectOverview = null;
    this.ngOnInit();
  }

  getProjectOverview(projectId: number) {
    this.memberService.ProjectOverviewOrganisations(projectId).subscribe(
      a => {
        this.projectOverview = a.data;
        this.organisationList = this.projectOverview.viewOutputsOrganisationList;
        this.selectedOrg = this.organisationId;
        this.validations(this.organisationId);
      },
      error => {
        console.log(error);
      }
    )
  }

  getOrganisationsByUser() {
    this.memberService.OrganisationsByUser().subscribe(
      o => { 
        this.organisationId = o.data[0].organisationId;
        this.dataService.setOrganisation(o.data)
        this.dataService.setSelectedOrganisation(this.organisationId);
        this.router.navigate([], { queryParams: { id: this.organisationId }, queryParamsHandling: 'merge' });
        this.getProjectOverview(this.projectId);
        this.validations(this.organisationId);
        this.getTiers(this.projectId, this.year);
      },
      error => {
        console.log(error);
      }
    );
  }

  private validations(organisationId): void {
    this.outputValidationService.getValidations(this.projectId, this.year, organisationId).subscribe(
      success => {
        this.data = success.data.validations;
        this.data.forEach(vld => {
          
          // Add status depending on response updates
          if ((vld.status !== 'validated' && vld.status !== 'confirmed' && vld.status !== 'accepted') && (vld.createdAt > vld.lastUpdated || !vld.lastUpdated)) { vld.status = 'pending' };
          if (vld.status !== 'validated' && (vld.questionId && vld.createdAt < vld.lastUpdated)) { vld.status = 'updated' };
          if (vld.responseDeleted == 'Y') { vld.status = 'deleted' }
          if(this.subFilterData.indexOf(vld.submissionName) === -1) {
            this.subFilterData.push(vld.submissionName);
          }
          vld.showComment = false
          this.tabsOpen.push({validationId: vld.validationId, tabOpen: false})
          if(vld.lastUpdated) {
            vld.lastUpdated = moment(vld.lastUpdated).format('lll');
          } else {
            vld.lastUpdated = 'N/A'
          }
        })
        this.data = this.setAvailableResponses(this.data, this.year);

        this.data = this.data.filter(value => {
          return !value.status.includes('validated') && !value.status.includes('accepted');
        });

        this.data.sort((a, b) => {
          const order = { 'Y': 1, 'N': 2, null: 3 };
          return order[a.alertUser] - order[b.alertUser];
        });

        if(this.showComments) {
          this.data.forEach(item => item.showComment = true)
        }

        if(this.tabsOpen) {
          this.tabsOpen.forEach(tab => {
            this.data.filter(data => {
              if(data.validationId == tab.validationId && tab.tabOpen == true) {
                data.showComment = true;
              }
            })
          })
        }

        this.sourceOfTruth = this.data;
        this.extractData = this.data;
        if(this.projectOverview) {
          this.createExtract();
        }
      },
      error => {
        console.log(error);
      },
      () => {
        if(this.selectedFilter) {
          this.setSubmissionFilter(this.selectedFilter);
        }
      }
    )
  }

  expandOpen(validation, value) {
    this.tabsOpen.filter(data => {
      if(data.validationId == validation.validationId) {
        data.tabOpen = value;
      }
    })

    return value;
  }

  showAllComments(value) {
    this.showComments = value;
    this.data.forEach(item => item.showComment = value)
    this.tabsOpen.forEach(item => item.tabOpen = value)
  }


  liveOrTestUrl() {
    let url: string;
    // Check environment
    if (environment.production) {
      url = 'https://members.nhsbenchmarking.nhs.uk/'
    } else {
      url = 'http://membertest.bmchealth.co.uk/'
    }

    return url
  }

  createExtract() {
    let url: string = this.liveOrTestUrl();
    this.csvSettings.fileName = this.projectOverview.projectName;
    this.extractData.forEach(validation => {
      if(validation.questionGroupLevel) {
        if (validation.serviceItemId > 0) {
            url + 'data-collection/'
            + validation.questionGroupLevel + '/' 
            + validation.submissionId + '/' 
            + validation.validationYear + '/' 
            + validation.serviceItemId + '?group=' 
            + validation.questionGroupId + '#'
            + validation.questionId;
        } else {
            url + 'data-collection/'
            + validation.questionGroupLevel + '/' 
            + validation.submissionId + '/'
            + validation.validationYear + '?group=' 
            + validation.questionGroupId + '#'
            + validation.questionId;
        }
      } else {
          url = "Not Available"
      }
      
      if(validation.tierId) {
        validation.reportLink = url + 'outputs/' + this.projectId + '?tier=' + validation.tierId + '&option=' + validation.optionId
      } else {
        validation.reportLink = "Not Available";
      }
      
      validation.collectionLink = url;
      validation.responses = !validation.response ? '-' : validation.response;
      validation.userNote = !validation.userNotes ? '-' : validation.userNotes;
      validation.validationDescriptions = !validation.validationDescription ? '-' : validation.validationDescription;
    })
  }

  checkWhitespace(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value.startsWith(' ')) {
      this.hasWhitespace = true;
      input.value = '';
      this.comment = null;
    } else {
      this.hasWhitespace = false;
    }
  }

  setSubmissionFilter(org: string) {
    this.selectedFilter = org
    this.serviceFilterData = [];
    this.data = this.sourceOfTruth;
    this.data = this.data.filter(orgs => orgs.submissionName == org)

    this.data.forEach(item => {
      if(this.serviceFilterData.indexOf(item.serviceItemName) === -1) {
        this.serviceFilterData.push(item.serviceItemName);
      }
    })
    this.extractData = this.data;
  }

  setServiceFilter(service) {
    this.data = this.data.filter(serv => serv.serviceItemName == service)
    this.extractData = this.data;
  }

  public setAvailableResponses(responses: any, year: number): any {
    responses.forEach(resp => {
        let displayQuestionText;
        if (resp.nestedQuestionText) {
            displayQuestionText = `${ resp.questionText } | ${ resp.nestedQuestionText }`
            // Add heading text, if available
            if (resp.nestedQuestionHeadingText) { displayQuestionText = `${ resp.questionText } | ${ resp.nestedQuestionText } | ${ resp.nestedQuestionHeadingText }` }
        } else {
            displayQuestionText = resp.questionText;
        }
        resp.displayQuestionText = this.replacePlaceholder(displayQuestionText, year);
    });

    return responses;
  }

  public editValidationStatus(validationId: number, status: string, note: any, validationResponseId: number): void {
    let updatedValidation = {
      validationId: validationId,
      status: status
    }
    
    this.outputValidationService.editValidationStatus(updatedValidation).subscribe(
      success => {
        this.ngOnInit();
      },
      error => {
        console.log(error);
      }
    )
  }

  private getTiers(projectId: number, year: number): void {
    this.outputValidationService.getProjectTiers(projectId, year).subscribe(
      success => {
        this.project = success.data;
      },
      error => {
        console.log(error);
      }
    )
  }

  public editUserValidationNote(validationResponseId: number, note: any) {
    let updatedNote = {
      validationResponseId: this.selectedValidationResponseId,
      note: note
    }
    
    this.outputValidationService.editUserValidationNote(updatedNote).subscribe(
      success => {
        this.getChatHistory(this.selectedValidationId, this.selectedValidationResponseId, null);
        this.comment = null;
      },
      error => {
        console.log(error);
      }
    )
  }
  
  public dataCollectionLink(validation: any): void {
    let url: string;
    if (validation.serviceItemId > 0) {
      url = '/data-collection/' 
        + validation.questionGroupLevel + '/' 
        + validation.submissionId + '/' 
        + validation.validationYear + '/' 
        + validation.serviceItemId + '?group=' 
        + validation.questionGroupId + '#'
        + validation.questionId;
    } else {
      url = '/data-collection/' 
        + validation.questionGroupLevel + '/' 
        + validation.submissionId + '/'
        + validation.validationYear + '?group=' 
        + validation.questionGroupId + '#'
        + validation.questionId;
    }
    window.open(url, '_blank');
  }

  public outputsLink(validation) {
    let url: string = this.liveOrTestUrl();
    url = url + 'outputs/' + this.projectId + '?tier=' + validation.tierId + '&option=' + validation.optionId
    window.open(url, '_blank');
  }

  public sortByStatus(sorted, column) {
    if(!sorted) {
      this.data.sort((a, b) => a[column].localeCompare(b[column]));
    } else {
      this.data.sort((a, b) => b[column].localeCompare(a[column]));
    }
    if(column == 'status') {
      this.statusSorted = !sorted;
    } else {
      this.submissionSorted = !sorted;
    }
    this.extractData = this.data;
  }

  public replacePlaceholder(text, year): string {
    if (text == null) { return text; }

    if (text.indexOf("|SINGLE_YEAR_CURRENT|") > 0) {
        text = text.replace(/\*\|SINGLE_YEAR_CURRENT\|\*/g, year.toString());
    }
    if (text.indexOf("|SINGLE_YEAR_NEXT|") > 0) {
        text = text.replace(/\*\|SINGLE_YEAR_NEXT\|\*/g, (year + 1).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_PREVIOUS|") > 0) {
        text = text.replace(/\*\|SINGLE_YEAR_PREVIOUS\|\*/g, (year - 1).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_MINUS_2|") > 0) {
        text = text.replace(/\*\|SINGLE_YEAR_MINUS_2\|\*/g, (year - 2).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_MINUS_3|") > 0) {
        text = text.replace(/\*\|SINGLE_YEAR_MINUS_3\|\*/g, (year - 3).toString());
    }
    if (text.indexOf("|DOUBLE_YEAR_NEXT|") > 0) {
        text = text.replace(/\*\|DOUBLE_YEAR_NEXT\|\*/g, (year).toString() + "/" + (year + 1).toString().substring(2, 4));
    } 
    if (text.indexOf("|DOUBLE_YEAR_CURRENT|") > 0) {
        text = text.replace(/\*\|DOUBLE_YEAR_CURRENT\|\*/g, (year - 1).toString() + "/" + (year).toString().substring(2, 4));
    }    
    if (text.indexOf("|DOUBLE_YEAR_PREVIOUS|") > 0) {
        text = text.replace(/\*\|DOUBLE_YEAR_PREVIOUS\|\*/g, (year - 2).toString() + "/" + (year - 1).toString().substring(2, 4));
    }
    if (text.indexOf("|DOUBLE_YEAR_MINUS_2|") > 0) {
        text = text.replace(/\*\|DOUBLE_YEAR_MINUS_2\|\*/g, (year - 3).toString() + "/" + (year - 2).toString().substring(2, 4));
    }
    if (text.indexOf("|DOUBLE_YEAR_MINUS_3|") > 0) {
        text = text.replace(/\*\|DOUBLE_YEAR_MINUS_3\|\*/g, (year - 4).toString() + "/" + (year - 3).toString().substring(2, 4));
    }
    text = text.replace(/&nbsp;/g, "");
    return text;
  }

  navProjectdetail() {
    this.router.navigate([`/project-overview/${this.projectId}`]);
  }
}