import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RequestComponent } from './request.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const outputsRoutes: Routes = [
  { path: 'request/:requestId', component: RequestComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(outputsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RequestRoutingModule { }