import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { ModalDirective } from "ngx-bootstrap";

import { DataService } from "../utilities/services/data.service";
import { MemberService } from "../utilities/services/member.service";
import { UserRegistration } from "../utilities/classes/user/user-registration";
import { User } from "../utilities/classes/user/user";
import { CsvDownload } from "app/utilities/classes/csv/csv-download";
import { FlattenPipe, UniquePipe } from "ngx-pipes";

@Component({
  selector: "app-user-administration",
  templateUrl: "./user-administration.component.html",
  styleUrls: ["./user-administration.component.scss"],
  providers: [ FlattenPipe ]
})
export class UserAdministrationComponent implements OnInit {
  @ViewChild("rolesModal") public rolesModal: ModalDirective;
  @ViewChild("passwordModal") public passwordModal: ModalDirective;
  @ViewChild("addUserModal") public addUserModal: ModalDirective;
  user: User = {
    userId: 0,
    fullName: "",
    logonName: "",
    jobTitle: "",
    phoneNo: "",
    lastLogon: new Date(),
    roles: null,
    welcomeDialogDismissed: "Y",
    mailchimpOptIn: "",
    notificationOptIn: "",
  };

  csvSettings: CsvDownload = {
      fileName: 'Users',
      buttonText: 'Download Users (.csv)',
      buttonStyle: 'btn btn-outline-primary',
      icon: '',
      headers: ['Name', 'Active', 'Email'],
      keys: ['fullName', 'isActive', 'logonName'],
      styling: true
    }

  registerUser: FormGroup;
  organisation;
  currentOrganisation;
  users;
  orgPermission: number;
  userRoles;
  productLead;
  errorMessage;
  showDeactivatedAccounts: boolean = false;
  userHasProductId2: boolean;
  organisationsLength: number;
  searchText: string;

  uniqueProductList: any = [];

  metaTitle: string = 'User Administration | NHS Benchmarking Network';

  constructor(
    fb: FormBuilder,
    private dataService: DataService,
    private memberService: MemberService,
    private toastr: ToastrService,
    private router: Router, // Set page titles
    private titleService: Title, // Set page titles
    private meta: Meta,
    private flatten: FlattenPipe,
    private unique: UniquePipe
  ) {
    this.registerUser = fb.group({
      logonName: ["", [Validators.required, Validators.maxLength(60), <any>Validators.email]],
      fullName: ["", [Validators.required, Validators.maxLength(60)]],
      jobTitle: ["", [Validators.required, Validators.maxLength(60)]],
      phoneNo: ["", [Validators.required, Validators.maxLength(40)]],
      productId: [""]
    });

    this.registerUser.valueChanges.subscribe((data) =>
      this.onValueChanged(data)
    );

    this.onValueChanged();
  }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle); // Set page title
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.userRoles = null;
    this.getUserDetails();
  }

  getUserDetails() {
    this.memberService.User().subscribe((u) => {
      this.user = u.data;
      this.dataService.setUser(u.data);
      this.getOrganisationsByUser(this.user.roles);
    });
  }

  getOrganisationsByUser(userRoles?) {
    let currentOrg = this.dataService.getSelectedOrganisation();
    this.memberService.OrganisationsByUser().subscribe((o) => {
      this.organisation = o.data;
      this.currentOrganisation = currentOrg
        ? currentOrg
        : this.organisation[0].organisationId;

      if (currentOrg)
        this.orgPermission = this.organisation.filter(
          (o) => o.organisationId == currentOrg
        )[0].minRole;
      else this.orgPermission = this.organisation[0].minRole;

      this.organisationsLength = o.data.length;
      this.setUserPermission(this.orgPermission);
      this.dataService.setOrganisation(o.data);
      this.getOrganisationUserAdmin(this.currentOrganisation);
      this.getOrganisationProducts(userRoles, this.currentOrganisation);
    });
  }

  getOrganisationProducts(userRoles, currentOrganisation) {
    // Get unique list of products for which current user has permissions
    this.uniqueProductList = [];
    userRoles = Object.keys(userRoles).map(org => userRoles[org]);
    userRoles = this.flatten.transform(userRoles).filter(role => role.organisationId == currentOrganisation);
    let uniqueProductIds = this.unique.transform(userRoles.map(role => role.productId)).sort(function (a, b) { return a - b });
    uniqueProductIds.forEach(id => {
      this.uniqueProductList.push({ 
        productId: id, 
        productName: userRoles.find(role => role.productId == id).productName
      })
    });
    // Update registration form
    this.registerUser.patchValue({ productId: this.uniqueProductList[0].productId });
  }

  // Fix API to return only data relevant to the user permission
  setUserPermission(permission: number) {
    // Product Lead, Deputy Product Lead, Project Lead & Deputy Project Lead have Active/Deactive permissions
    if (permission <= 3 || permission == 8) this.userHasProductId2 = true;
    else this.userHasProductId2 = false;
  }

  getOrganisationUserAdmin(organisationId: number) {
    this.memberService.OrganisationsUserAdmin(organisationId).subscribe((o) => {
      this.users = o.data.userList;
      this.productLead = o.data.productLeadList.filter(
        (p) => p.productId == 1
      )[0];
    });
  }

  getUserRoles(userId: number, organisationId: number) {
    this.memberService.UserViewRoles(userId, organisationId).subscribe((u) => {
      this.userRoles = u.data;
    });
  }

  setUserState(userId: number, organisationId: number, state: string) {
    this.memberService.UserState(userId, organisationId, state).subscribe(
      (s) => {
        this.getOrganisationUserAdmin(organisationId);
        this.toastr.success("User state updated.", "User Administration");
      },
      (e) => {
        this.toastr.success(
          "Unable to update user state.",
          "User Administration"
        );
      }
    );
  }

  createNewUser(userRegData: UserRegistration) {
    this.memberService.RegisterUser(userRegData).subscribe(
      (data) => {
        this.addUserModal.hide();
        this.toastr.success(
          "User has been added successfully.",
          "User Administration"
        );
      },
      (error) => {
        this.errorMessage = error.error.error.message;
      }
    );
  }

  showAddUser() {
    this.registerUser.patchValue({ productId: this.uniqueProductList[0].productId });
    this.errorMessage = null;
    this.addUserModal.show();
  }

  addUser() {
    let userRegData: UserRegistration = this.registerUser.value;

    userRegData.organisationId = this.currentOrganisation;

    this.createNewUser(userRegData);
  }

  onOrgChange(message: number) {
    this.dataService.setSelectedOrganisation(message);

    this.users = null;

    this.orgPermission = this.organisation.filter(
      (o) => o.organisationId == message
    )[0].minRole;

    this.setUserPermission(this.orgPermission);

    this.currentOrganisation = message;

    this.getOrganisationUserAdmin(message);
    this.getOrganisationProducts(this.user.roles, message);
  }

  viewRoles(userId: number) {
    this.getUserRoles(userId, this.currentOrganisation);

    this.rolesModal.show();
  }

  resetPassword(userId: number) {
    this.passwordModal.show();
  }

  accountState(userId: number, isActive: string) {
    if (isActive == "Y") {
      this.setUserState(userId, this.currentOrganisation, "N");
    }

    if (isActive == "N") {
      this.setUserState(userId, this.currentOrganisation, "Y");
    }
  }

  onValueChanged(data?: any) {
    if (!this.registerUser) {
      return;
    }
    const form = this.registerUser;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = "";
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + " ";
        }
      }
    }
  }

  navHome() {
    this.router.navigate(["/home"]);
  }

  formErrors = {
    logonName: "",
    fullName: "",
    jobTitle: "",
    phoneNo: "",
  };

  validationMessages = {
    logonName: {
      required: "Email Address is required.",
      maxlength: "Email Address cannot be longer than 60 characters.",
      email: "Email Address is not valid.",
    },
    fullName: {
      required: "Full name is required.",
      maxlength: "Full name cannot be longer than 60 characters.",
    },
    jobTitle: {
      required: "Job title is required.",
      maxlength: "Job title cannot be longer than 60 characters.",
    },
    phoneNo: {
      required: "Phone number is required.",
      maxlength: "Phone number cannot be longer than 40 characters.",
    },
  };
}
