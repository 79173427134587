import { NgModule } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ResponsiveModule } from 'ngx-responsive';

import { ForumComponent } from './forum.component';
import { ForumRoutingModule } from './forum-routing.module';

import { AuthService } from '../utilities/services/auth.service';
import { AuthGuardService } from '../utilities/services/auth-guard.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { HighlightPipe } from '../utilities/pipes/highlight.pipe';
import { KenService } from 'app/utilities/services/ken.service';
import { NgPipesModule, FlattenPipe } from 'ngx-pipes';
import { AngularEditorModule } from '@kolkov/angular-editor';


@NgModule({
  imports: [
    CommonModule,
    ResponsiveModule,
    ForumRoutingModule,
    CollapseModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    AngularEditorModule
  ],
  declarations: [
    ForumComponent,
    HighlightPipe
  ],
  providers: [
    AuthService,
    AuthGuardService,
    KenService,
    DatePipe,
    FlattenPipe
  ]
})
export class ForumModule {}