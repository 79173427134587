import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ToastrModule } from "ngx-toastr";
import { ModalModule } from "ngx-bootstrap";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { UserAdministrationComponent } from "./user-administration.component";
import { UserAdministrationRoutingModule } from "./user-administration-routing.module";

import { SafePipeModule } from "../utilities/pipes/safe-pipe.module";
import { NgPipesModule } from "ngx-pipes";
import { Angular2CsvModule } from "angular2-csv";
import { CsvDownloadModule } from "app/shared/csv-download/csv-download.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    UserAdministrationRoutingModule,
    TooltipModule,
    SafePipeModule,
    NgPipesModule,
    Angular2CsvModule,
    CsvDownloadModule
  ],
  declarations: [UserAdministrationComponent],
})
export class UserAdministrationModule {}
