<div>

  <div *ngIf="!showAddressForm">
    <div *ngIf="address">
      <hr>
      <h4>Saved Address:</h4>
      <table class="table table-bordered">
        <tr>
          <td style="width:25%;font-weight:bold">Contact Name</td>
          <td style="width:75%;">{{ address.addressContact }}</td>
        </tr>
        <tr>
          <td style="width:25%;font-weight:bold">Phone Number</td>
          <td style="width:75%;">{{ address.addressPhone }}</td>
        </tr>
        <tr>
          <td style="width:25%;font-weight:bold">Delivery Address</td>
          <td style="width:75%;">
            {{ address.address1 }}
            <br>{{ address.address2 }}
            <span *ngIf="address.address3"><br>{{ address.address3 }}</span>
            <span *ngIf="address.address4"><br>{{ address.address4 }}</span>
            <br>{{ address.addressPostcode }}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <form *ngIf="showAddressForm" [formGroup]="addressForm" novalidate>
    <hr>
    <div class="form-group">
      <label>Contact Name</label>
      <input type="text" class="form-control" formControlName="contact">
    </div>
    <div *ngIf="formErrors.contact" class="alert alert-danger">
      {{ formErrors.contact }}
    </div>

    <div class="form-group">
      <label>Phone Number</label>
      <input type="text" class="form-control" formControlName="phone">
    </div>
    <div *ngIf="formErrors.phone" class="alert alert-danger">
      {{ formErrors.phone }}
    </div>

    <div class="form-group">
      <label>Delivery Address</label>
      <input type="text" class="form-control" formControlName="address1">
    </div>
    <div *ngIf="formErrors.address1" class="alert alert-danger">
      {{ formErrors.address1 }}
    </div>

    <div class="form-group">
      <input type="text" class="form-control" formControlName="address2">
    </div>
    <div *ngIf="formErrors.address2" class="alert alert-danger">
      {{ formErrors.address2 }}
    </div>

    <div class="form-group">
      <input type="text" class="form-control" formControlName="address3">
    </div>
    <div *ngIf="formErrors.address3" class="alert alert-danger">
      {{ formErrors.address3 }}
    </div>

    <div class="form-group">
      <input type="text" class="form-control" formControlName="address4">
    </div>
    <div *ngIf="formErrors.address4" class="alert alert-danger">
      {{ formErrors.address4 }}
    </div>

    <div class="form-group">
      <label>Postcode</label>
      <input type="text" class="form-control" formControlName="postcode">
    </div>
    <div *ngIf="formErrors.postcode" class="alert alert-danger">
      {{ formErrors.postcode }}
    </div>

    <div>
      <button type="button" class="btn btn-success button-margin" [disabled]="!addressForm.valid" (click)="addressFormSubmit()">Save</button>
      <button type="button" class="btn btn-default button-margin" (click)="showAddressForm = false; showAddressFormAdd = true;">Cancel</button>
    </div>
    <p class="text-muted explainer" *ngIf="!addressForm.valid">
      All parts of the form are required before the address can be saved.
    </p>
  </form>

  <div class="actions" *ngIf="!addressClosed && registration.registration.deputyProjectLead.allowChanges && !registration.registration.registrationLocked">
    <button type="button" class="btn btn-primary" *ngIf="showAddressFormAdd && !address" (click)="getLastAddress()">Get Last Address</button>
    <button type="button" class="btn btn-default" *ngIf="showAddressFormAdd && !address" (click)="addressFormAdd()">Add New Address</button>
    <button type="button" class="btn btn-default" *ngIf="showAddressFormAdd && address" (click)="addressFormEdit()">Edit Address</button>
    <button type="button" class="btn btn-danger" *ngIf="showAddressFormAdd && address" (click)="removeAddress(address.addressId)">Delete Address</button>
  </div>

  <div *ngIf="addressError" class="alert alert-danger alert-address">
    No previous address found. Please click 'Add Address' to complete new details for the current year.
  </div>

</div>