import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ResponsiveModule } from "ngx-responsive";
import { AccordionModule, TabsModule } from "ngx-bootstrap";


import { AuthService } from "../utilities/services/auth.service";
import { AuthGuardService } from "../utilities/services/auth-guard.service";
import { UserCheckComponent } from "./user-check.component";
import { UserCheckRoutingModule } from "./user-check-routing.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { Angular2PromiseButtonModule } from "angular2-promise-buttons";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TabsModule.forRoot(),
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fa fa-spinner fa-spin"></span>',
      disableBtn: true,
      btnLoadingClass: "is-loading",
      handleCurrentBtnOnly: false,
    }),    
    ResponsiveModule,
    UserCheckRoutingModule,
    AccordionModule.forRoot(),
  ],
  declarations: [UserCheckComponent],
  providers: [AuthService, AuthGuardService],
})
export class UserCheckModule {}
