export class DynamicInfoPanel {
    public info: boolean;
    public reportId: number;
    public reportName: string;
    public organisationType: string;
    public factor: number;
    public options: any;
    public mapUrl: any;
    public noResults: any;
    public noMapAreas: any;
    public components: any;
    public componentDescriptions: string;
    public admin: boolean;

    constructor (data: any) {
        this.mapInfoPanel(data);
    }

    private mapInfoPanel(data: any): void {
        this.componentDescriptions = data.componentDescriptions || null;
        this.factor = data.factor;
        this.reportId = data.reportId;
        this.reportName = data.reportName;
        this.components = data.components;
        this.admin = data.admin || null;
        this.info = data.info || null;
        this.mapUrl = data.mapUrl || null;
        this.options = data.options || null;
        this.noResults = data.noResult || null;
        this.noMapAreas = data.noMapAreas || null;
        this.organisationType = data.organisationType || null;
    }
}