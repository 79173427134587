<!-- SELECTOR BAR-->
<div id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back" (click)="navProjectdetail()">
            <i class="fa fa-caret-left"></i> Back to Project Overview
          </span>
        </div>
        <div class="col col-md-3" style="float: right;" *ngIf="organisationList?.length > 1">
          <div class="form-group">
            <select class="form-control" style="font-size: 14px !important" id="organisation" name="organisation"
              [ngModel]="selectedOrg" (ngModelChange)="changeOrganisation($event)">
              <option *ngFor="let o of organisationList" [ngValue]="o.organisationId">{{o.organisationName}}</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-fluid" id="containerProfile" *ngIf="organisationId"
  (clickElsewhere)="codesModal.hide(); selectedValidation = null;">
  <div *ngIf="!projectOverview">
    <p class="loading" style="margin-top: 31vh;">
      <span class="fa fa-spinner fa-spin"></span> Loading validations...
    </p>
  </div>
  <div *ngIf="projectOverview && project">
    <div class="col-lg-10 col-lg-offset-1">
      <div class="row">
        <h1>Validation Points</h1>
        <h3>{{ projectOverview.projectName }}</h3>
        <p class="intro">
          <br />
          The list below shows the validation points for the {{ projectOverview.projectName }}.<br />
          <br />
          To review the responses, click on the question text; this will take you to the relevant data collection
          page. If you are happy that the response is correct and does not require update, select ‘Confirmed’. There is also
          a chat that can be used to communicate with the {{ projectOverview.projectName }} team.<br />
          <br />
          All changes to the validation points listed will be communicated to the project team automatically, but if
          you need any support <a [href]="'mailto:' + project.projectRoles[0].email">contact them directly.</a>
        </p>

      </div>
      <!-- Modal -->
      <div bsModal #codesModal="bs-modal" class="modal codeList requires-no-scroll" tabindex="-1" role="dialog">
        <div class="col-lg-6 col-md-offset-3" style="margin-top: 11em;">
          <div class="card">
            <div class="chat">
              <div class="chat-header clearfix">
                <div class="row">
                  <div style="text-align: center;">
                    <b>{{ submissionName }}</b> <br />
                    <small>Q: {{ displayQuestionText }}</small>
                  </div>
                </div>
              </div>
              <div class="chat-history" style="height: 30em; overflow-x: auto; display: flex; flex-direction: column-reverse;">
                <ul class="m-b-0">
                  <li *ngFor="let data of chatHistory" class="clearfix">
                    <div *ngIf="data.message">
                      <div class="message-data" [class.text-right]="data.is_user == 'Y'">
                        <div [ngStyle]="{'display': data.is_user == 'Y' ? 'flex' : '', 'flex-direction': data.is_user == 'Y' ? 'column-reverse' : ''}">
                          <img *ngIf="data.is_user == 'N'" src="../../assets/images/nhsbn-mark-new.png" alt="avatar">
                          <span style="font-size: 11px;" class="message-data-time">{{ data.sent_at | date: 'MMM d, y h:mm a' }}</span>
                        <span class="message-data-time">{{ data.username }}</span>
                      </div>
                      </div>
                      <div class="message" [ngClass]="data.is_user == 'Y' ? 'other-message' : 'my-message'"
                        [class.float-right]="data.is_user == 'Y'">
                        {{ data.message }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="chat-message clearfix">
                <div class="mb-0" style="display: flex;">
                  <input type="text" class="form-control search-input" (input)="checkWhitespace($event)" maxlength="500" [(ngModel)]="comment"
                    placeholder="Enter comment regarding validation...">
                  <button [disabled]="!comment" class="btn btn-success" style="margin-left: 5px;"
                    (click)="editUserValidationNote(selectedValidationResponseId, comment);">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <app-csv-download style="float: right;" [result]="extractData"
          [csvSettings]="csvSettings"></app-csv-download>
        <div *ngIf="subFilterData.length > 0">
          <div style="display: flex;">
            <button class="btn btn-primary" style="margin-right: 5px;"
              (click)="data = sourceOfTruth; serviceFilterData = []; selectedSubmission = []; selectedFilter = null">Reset</button>
            <select class="form-controls" id="submission" name="submission" [ngModel]="selectedSubmission"
              (ngModelChange)="setSubmissionFilter($event)">
              <option value="" disabled selected>Select a Submission...</option>
              <option *ngFor="let o of subFilterData" [ngValue]="o">{{o}}</option>
            </select>
            <select *ngIf="serviceFilterData.length > 1" style="margin-left: 5px;" class="form-controls" id="service"
              name="service" [ngModel]="selectedSubmission" (ngModelChange)="setServiceFilter($event)">
              <option value='' disabled>Select a Service...</option>
              <option *ngFor="let o of serviceFilterData" [ngValue]="o">{{o}}</option>
            </select>
            <button style="margin-left: 5px;" class="btn btn-primary"
              (click)="showComments ? showAllComments(false) : showAllComments(true)">
              <i [class]="showComments ? 'far fa-eye' : 'far fa-eye-slash'"></i> Comments
            </button>
          </div>
        </div>
        <div *ngIf="data" style="padding-top: 2em;">
          <table class="table">
            <thead>
              <tr>
                <th style="cursor: pointer; min-width: 80px" (click)="sortByStatus(statusSorted, 'status')" scope="col">
                  Status
                  <i [class]="statusSorted ? 'fas fa-sort-alpha-down' : 'fas fa-sort-alpha-down grayed-out'"></i>
                </th>
                <th scope="col">Last updated</th>
                <th scope="col" style="cursor: pointer;" (click)="sortByStatus(submissionSorted, 'submissionName')">
                  Submission & Service
                  <i [class]="submissionSorted ? 'fas fa-sort-alpha-down' : 'fas fa-sort-alpha-down grayed-out'"></i>
                </th>
                <th scope="col">Question & Response</th>
                <th scope="col">Toolkit</th>
                <th scope="col">Chat</th>
                <th scope="col" style="text-align: center;">Confirmed</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody class="align-middle" *ngFor="let item of data; let i = index">
              <tr *ngIf="data.length == 0">
                <td colspan="6">
                  <h3 style="text-align: center; padding-top: 10px;">You currently don't have any validation points</h3>
                </td>
              </tr>
              <tr>
                <th>
                  <span [class]="item.status" style="font-size: 0.8em;">
                    {{ item.status || "Pending" | uppercase }}
                  </span>
                </th>
                <th>
                  <small style="color: grey;"> {{ item.lastUpdated }} </small>
                </th>
                <th style="font-weight: 400;">
                  <span>{{ item.submissionName }} <br />
                    <small style="color: grey;">{{ item.serviceItemName || "—" }}</small>
                  </span>
                </th>
                <th class="line" *ngIf="item.displayQuestionText">
                  <span tooltip="{{ item.displayQuestionText }}" [class]="item.displayQuestionText ? 'question' : ''"
                    (click)="item.displayQuestionText ? dataCollectionLink(item) : ''">
                    {{ item.displayQuestionText | slice:0:30 }}{{ item.displayQuestionText.length > 30 ? '...' : '' }}
                    <br />
                  </span>
                  <small style="color: grey;">{{ item.response || "—" }}</small>
                </th>
                <th class="line" *ngIf="!item.displayQuestionText">
                  <span>—</span>
                </th>
                <th>
                  <span [class]="item.tierId ? 'question' : ''" (click)="item.tierId ? outputsLink(item) : ''">
                    {{ item.tierId ? "View Chart" : "N/A"}}
                  </span>
                </th>
                <th>
                  <span class="question"
                    (click)="codesModal.show(); comment = null; selectedValidation = item; getChatHistory(item.validationId, item.validationResponseId, item)">
                    Open Chat
                    <span *ngIf="item.alertUser == 'Y'" class="badge badge-secondary"
                      style="margin-bottom: 10px; background-color: orangered;">New</span>
                  </span>
                </th>
                <th style="text-align: center;"
                  (click)="(item.status == 'pending' && item.displayQuestionText) ? editValidationStatus(item.validationId, 'confirmed', myArea?.value, item.validationResponseId) : editValidationStatus(item.validationId, 'pending', myArea?.value, item.validationResponseId)">
                  <i class="fas fa-lg fa-check-square"
                    [style.display]="(item.status != 'pending' || !item.displayQuestionText) ? 'none' : ''"></i>
                  <i class="fas fa-undo-alt" tooltip="Undo"
                    [style.display]="(item.status != 'confirmed') ? 'none' : ''"></i>
                  <span *ngIf="(item.status != 'pending' || !item.displayQuestionText) && item.status != 'confirmed'">N/A</span>
                </th>
                <th>
                  <span class="question"
                    (click)="item.showComment ? item.showComment = expandOpen(item, false) : item.showComment = expandOpen(item, true)">
                    <i [class]="!item.showComment ? 'fas fa-chevron-left' : 'fas fa-chevron-down'"></i> Expand
                  </span>
                </th>
              </tr>
              <tr *ngIf="item.validationDescription && item.showComment">
                <th colspan="8">
                  <span class="comment-title">Comment</span>
                  <span class="comment-text message-admin" tooltip="{{ item.validationDescription }}">
                    {{ item.validationDescription | slice:0:150 }}{{ item.validationDescription.length > 150 ? '...' : '' }} <br />
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>