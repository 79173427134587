import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MemberService } from '../utilities/services/member.service';
import { DataService } from '../utilities/services/data.service';
import { User } from '../utilities/classes/user/user';
import { Organisation } from '../utilities/classes/organisation/organisation';
import { Event } from '../utilities/classes/event/event'
import { OrderByPipe } from 'ngx-pipes';
import { KenService } from 'app/utilities/services/ken.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { IResourcesNonProjectDocuments } from 'app/utilities/models/resources/resources-nonProjectDocuments.vm';
import { GoogleAnalyticsService } from 'app/utilities/services/google-analytics.service';

@Component({ 
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public user: User = { "userId" : 0, "fullName" : "", "logonName" : "", "jobTitle" : "", "phoneNo" : "", "lastLogon" : new Date(), "roles": null, "welcomeDialogDismissed": "Y", "mailchimpOptIn": "", "notificationOptIn": "" };
  public organisation: Organisation[];
  public currentOrganisation: number;
  public welcomeMessage: boolean = false;
  public resourceList: Array<IResourcesNonProjectDocuments>;
  public yourReports: Array<IResourcesNonProjectDocuments> = [];
  public eventList: Array<Event>;
  public filteredEvents: Array<Event>;
  public filteredRequests: Array<Object> = [];
  public organisations: any;
  public metaTitle: string = 'Home | NHS Benchmarking Network';
  public currentYear = new Date().getFullYear();

  constructor(
    private memberService: MemberService,
    private dataService: DataService,
    private titleService: Title,
    private meta: Meta,
    public order: OrderByPipe,
    private datePipe: DatePipe,
    private kenService: KenService,
    public router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) { }

  ngOnInit() {
    if(sessionStorage.getItem("homepage") == "classicView") {
      this.router.navigate(["/classicView"]);
    } else {
      this.titleService.setTitle(this.metaTitle);
      this.meta.updateTag({property: 'og:title', content: this.metaTitle});
      this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
      this.getUserDetails();
      this.getOrganisationsByUser();
      this.getEventList();
      this.getRequests();
    }
  }
  
  getUserDetails() {
    this.memberService.User().subscribe(
      u => { 
        this.user = u.data;
        this.dataService.setUser(u.data);

        if (this.user.welcomeDialogDismissed == 'N') {
          this.welcomeMessage = true;
        }        
      }
    );
  }

  getOrganisationsByUser() {
    let currentOrg = this.dataService.getSelectedOrganisation();

    this.memberService.OrganisationsByUser().subscribe(
      o => { 
        this.organisation = o.data,
        this.currentOrganisation = currentOrg ? currentOrg : this.organisation[0].organisationId,
        this.dataService.setOrganisation(o.data)
        this.dataService.setSelectedOrganisation(this.currentOrganisation);
        this.getResources(this.currentYear);
      }
    );
  }

  onOrgChange(message: number) {
    this.dataService.setSelectedOrganisation(message);
    this.currentOrganisation = message;
    this.getResources(this.currentYear);
  }

  dismissWelcomeMessage() {
    this.memberService.DismissWelcomeDialog({ welcomeDialogDismissed: 'Y' }).subscribe();
    this.welcomeMessage = false;
  }

  removeHiddenDocuments(arr) {
    return arr.reduce((acc, obj) => {
      if (obj.isVisible !== 'N') {
        for (const key in obj) {
          if (Array.isArray(obj[key])) {
            obj[key] = this.removeHiddenDocuments(obj[key]);
          }
        }
        acc.push(obj);
      }
      return acc;
    }, []);
  }

  getResources(year: number) {
    this.memberService.ResourceDocuments(year, this.currentOrganisation).subscribe(
      (success) => {
        this.resourceList = success.data['nonProjectDocuments'].reverse();
        success.data['projectDocuments'].forEach(sector => {
          sector.projects.forEach(project => {
            project.documents.forEach(document => {
              this.yourReports.push(document)
            })
          })
        })

        
        Array.prototype.push.apply(this.resourceList,this.yourReports);
        
        this.resourceList.sort(function(a, b) {
          let c: any = new Date(a.uploadTime);
          let d: any = new Date(b.uploadTime);
          return d-c;
        });
        this.resourceList = this.removeHiddenDocuments(this.resourceList);
      },
      (error) => {
        console.log(error)
      });
  }

  getEventList() {
    this.memberService.Events().subscribe(
      r => { 
        this.eventList = this.formatEvents(r.data);
        this.filteredEvents = this.eventList.filter(e => e.when === 'future');
      },
      e => {
        console.log(e);
      }
    );
  }

  getRequests() {
    this.kenService.getRequests().subscribe(
      r => {
        this.filteredRequests = this.cleanRequests(r.data);
        this.filteredRequests.reverse()
      },
      e => { 
        console.log(e) 
      }
    );
  }

  classicView() {
    sessionStorage.setItem("homepage", "classicView");
    this.googleAnalyticsService.classicViewLandingPage("Classic View");
  }

  cleanRequests(data) {
    // Retain only one serviceItemId
    let cleanedRequests = [];

    data.forEach((e) => {

      let requestTags;
      if (e.requestTags === null) {
        requestTags = '';
      } else {
        requestTags = e.requestTags.split(', ');
      }

      if (e.isVisible === 'Y') {
        cleanedRequests.push({
          kenRequestId: e.kenRequestId,
          projectId: e.projects.map(p => p.projectId),
          projectName: e.projects.map(p => p.projectName),
          allProjects: e.linkAllProjects,
          requestBody: e.requestBody,
          requestDate: e.requestDate,
          requestMonth: this.datePipe.transform(e.requestDate, 'MMMM y'),
          requestTitle: e.requestTitle,
          commentCount: e.commentCount,
          sectorName: e.sectorName,
          requestTags: requestTags,
          categoryId: e.sectorId,
          submittedBy: e.submittedBy
        });
      }
    });

    // If allProjects true, add 'All Projects' to projectName
    cleanedRequests.forEach(c => {
      if (c.allProjects === 'Y') {
        c.projectName.push('All Projects');
      }
    })

    return cleanedRequests
  }


  formatEvents(events) {
    let currentDate = new Date();

    events.forEach(ev => {
      // Format tags
      let requestTags;
      if (ev.requestTags === null) {
        requestTags = '';
      } else {
        requestTags = ev.requestTags.split(', ');
      }
      ev.requestTags = requestTags;
      // Set when: past or future
      let eventDate = new Date(ev.eventDt);
      if (eventDate >= currentDate) {
        ev.when = 'future';
      } else {
        ev.when = 'past';
      }
    });

    // Order future events in chronological order
    // and past events in reverse chronological order
    let future = this.order.transform(events.filter(e => e.when === 'future'), 'eventDt');
    let past = this.order.transform(events.filter(e => e.when === 'past'), '-eventDt');
    events = future.concat(past);

    return events;
  }
}