import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { AuthService } from "../utilities/services/auth.service";
import { MemberService } from "../utilities/services/member.service";
import { User } from "../utilities/classes/user/user";

import { contactInformation } from "./contact-info";
import { DataService } from "app/utilities/services/data.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  contactInfos: Array<object> = contactInformation.getContactInfo();
  acuteInfos: Array<object> = contactInformation.getAcuteInfo();
  communityInfos: Array<object> = contactInformation.getCommunityInfo();
  acuteAndCommunityInfos: Array<object> = contactInformation.getacuteAndCommunity();
  mentalHealthInfos: Array<object> = contactInformation.getMentalHealthInfo();
  nationalAuditsInfos: Array<object> = contactInformation.getNationalAuditsInfo();
  commissioningInfos: Array<object> = contactInformation.getCommissioningInfo();
  contactOptions: Array<string> = ['General', 'Acute', 'Acute and Community', 'Community', 'Mental Health', 'National Audits', 'Whole System']

  user: User;
  userName: String;
  userEmail: String;

  isDisplayed: Boolean = false;
  displayError: Boolean = false;

  profileForm: FormGroup;
  organisation: FormControl;
  phone: FormControl;
  subject: FormControl;
  message: FormControl;
  project: FormControl;
  flattenedProjects: any;
  formSubmitted: boolean = false;

  metaTitle: string = 'Contact | NHS Benchmarking Network';
  selectedContact: Array<object>;

  constructor(
    public auth: AuthService,
    private memberService: MemberService,
    private titleService: Title,
    private meta: Meta,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle); // Set page title
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.getUserDetails();
    this.createFormControls();
    this.createForm();
    let selectedOrganisationId = this.dataService.getSelectedOrganisation();
    this.getProjects(selectedOrganisationId);
    this.selectedContact = this.contactInfos
  }

  getUserDetails() {
    this.memberService.User().subscribe((u) => {
      this.user = u.data;
      this.userName = this.user.fullName;
      this.userEmail = this.user.logonName;
    });
  }

  getProjects(organisationId) {
    this.memberService.ProjectAccess(organisationId).subscribe((u) => {
      let sectors = u.data;
      let projects = [];
      for(let key in sectors) {
        if(sectors.hasOwnProperty(key)) {
          projects.push(sectors[key].map(f => f.projectName))
        }
      }
      this.flattenedProjects = [].concat.apply([], projects);
      this.flattenedProjects.sort()

    });
  }

  createFormControls() {
    this.organisation = new FormControl("", [
      Validators.required,
      Validators.minLength(1),
    ]);
    this.phone = new FormControl("", [Validators.pattern("^[0-9]*$")]);
    this.subject = new FormControl("", [
      Validators.required,
      Validators.minLength(1),
    ]);
    this.message = new FormControl("", [
      Validators.required,
      Validators.minLength(1),
    ]);
    this.project = new FormControl("", [ ]);
  }

  createForm() {
    this.profileForm = new FormGroup({
      organisation: this.organisation,
      phone: this.phone,
      subject: this.subject,
      message: this.message,
      project: this.project,
    });
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.profileForm.valid) {
      this.submitContactDetails(
        this.profileForm.get("organisation").value,
        this.profileForm.get("phone").value,
        this.profileForm.get("subject").value,
        this.profileForm.get("message").value,
        this.profileForm.get("project").value
      );
      this.profileForm.reset();
    }
  }

  submitContactDetails(organisation: String, phone: String, subject: String, message: String, project: String) {
    this.memberService.ContactForm(organisation, phone, subject, message, project).subscribe(
        (r) => {
          this.isDisplayed = true;
          this.displayError = false;
        },
        (e) => {
          this.isDisplayed = false;
          this.displayError = true;
        },
        () => {}
      );
  }

  navHome() {
    this.router.navigate(["/home"]);
  }

  getContacts(contact){
    switch (contact){
      case "General":
        this.selectedContact = this.contactInfos
        break;
      case "Community":
        this.selectedContact = this.communityInfos
        break;
      case "Acute":
          this.selectedContact = this.acuteInfos
        break;
      case "Acute and Community":
        this.selectedContact = this.acuteAndCommunityInfos
        break;
      case "Mental Health":
          this.selectedContact = this.mentalHealthInfos
        break;
      case "National Audits":
            this.selectedContact = this.nationalAuditsInfos
        break;
      case "Whole System":
              this.selectedContact = this.commissioningInfos
        break;
    }
  }
}
