import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResourcesComponent } from './resources.component';
import { AuthGuardService } from 'app/utilities/services/auth-guard.service';

const resourcesRoutes: Routes = [
  { path: 'resources', component: ResourcesComponent, canActivate: [AuthGuardService] },
];
@NgModule({
  imports: [
    RouterModule.forChild(resourcesRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ResourcesRoutingModule { }