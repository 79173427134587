<div>
  <div class="row" id="optionsRow">
    <div class="col-md-2">
      <div class="form-group">
        <label>Year</label>

        <select class="form-control" [ngModel]="selectedYear" (ngModelChange)="onYearChange($event)" [disabled]="!tierData || !chartLoaded">
          <option *ngFor="let y of tierYears" value="{{y.reportYear}}">{{y.reportYear}}</option>
        </select>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label style="width:100%" class="codes">
          Peer Group
          <i *ngIf="projectId == 60" class="fas fa-info-circle" (click)="peerGroupModal.show()" tooltip="‘Need index’ refers to Community Need Index. ‘Highest’, ‘Middle’ and ‘Lowest’ are in relation to the project sample."></i>
          <div *ngIf="defaultYear" (click)="getSubmissionPeerGroups(projectId, defaultYear); highlightPreferenceModal.show()" tooltip="Set peer group highlight for this toolkit. Click for more details." class="highlight-lock">
            <i *ngIf="highlightedPeerGroupId" class="fas fa-highlighter text-success"></i>
            <i *ngIf="!highlightedPeerGroupId" class="fas fa-highlighter text-muted"></i>
          </div>
        </label>
        <select class="form-control" [ngModel]="selectedPeerGroup" (ngModelChange)="onPeerGroupChange($event)" [disabled]="availablePeerGroups?.length < 2 || (!tableLoaded && !chartLoaded)" id="peerGroup">
          <optgroup *ngFor="let g of availablePeerGroups | groupBy: 'peerGroupTypeName' | pairs" [label]="g[0]">
            <option *ngFor="let p of g[1]" value="{{p.id}}">{{p.name}}</option>
          </optgroup>
        </select>
      </div>
    </div>

    <div class="col-md-3">
      <div class="form-group">
        <label class="codes">
          Submission
          <i class="fas fa-info-circle" (click)="submissionsModal.show()" *ngIf="submissionCodes.length" tooltip="View submission codes for your organisation"></i>
        </label>

        <select class="form-control" [ngModel]="selectedSubmission" (ngModelChange)="onSubmissionChange($event)" [disabled]="availableSubmissions?.length < 2 || (!tableLoaded && !chartLoaded)">
          <option *ngFor="let s of availableSubmissions" value="{{s.submissionId}}">{{s.submissionName}}</option>
        </select>
      </div>
    </div>

    <div class="col-md-3" id="{{ this.optionTitleId }}">
        <div class="form-group">
          <label style="width:100%">
            <span>{{ this.optionTitle }}</span>
            <ng-container *ngIf="availableOptions.length > 1 && (chartLoaded || tableLoaded)">
              <div class="option-shifters">
                <i class="fas fa-caret-circle-left text-muted" (click)="shiftOption('down')" tooltip="Previous option"></i>
                <i class="fas fa-caret-circle-right text-muted" (click)="shiftOption('up')" tooltip="Next option"></i>
              </div>
            </ng-container>
            <div *ngIf="projectId == 9 || projectId == 8" (click)="optionPreferenceModal.show()" tooltip="Set an option preference for this toolkit. Click for more details." class="option-lock">
              <i *ngIf="optionPreference" class="fas fa-lock text-success"></i>
              <i *ngIf="!optionPreference" class="fas fa-lock-open text-muted"></i>
            </div>
          </label>
          <!-- Standard options -->
          <ng-container *ngIf="!groupAvailableOptions">
            <select class="form-control" [ngModel]="selectedOption" (ngModelChange)="onOptionChange($event)" [disabled]="availableOptions?.length < 2 || (!tableLoaded && !chartLoaded)">
              <option *ngFor="let d of availableOptions" value="{{d.optionId}}">{{d.optionName}}</option>
            </select>
          </ng-container>
          <!-- Option groups -->
          <ng-container *ngIf="groupAvailableOptions">
            <app-outputs-display-options [options]="availableOptions" [selectedOption]="selectedOption" (emitOptionId)="onOptionChange($event)"></app-outputs-display-options>
          </ng-container>
        </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>View</label>

        <select class="form-control" [ngModel]="selectedView" (ngModelChange)="onViewChange($event)" [disabled]="availableViews?.length < 2 || (!tableLoaded && !chartLoaded)">
          <option *ngFor="let v of availableViews" [value]="v.viewType">{{v.viewName}}</option>
        </select>
      </div>
    </div>
  </div>

  <div id="highcharts-container" [style.height.px]="windowHeight">

    <!-- Options Bar -->
    <div id="optionsBar">
      <div *ngIf="chartData && userReportParamaters.length > 0" (click)="reportDetailsModal.show()">
        <i class="fal fa-info-circle"></i>Info
      </div>
      <div *ngIf="chartData && userReportParamaters.length > 0" (click)="enableColorBlindness()">
        <i [class]="colorBlindnessEnabled ? 'fas fa-check-circle' : 'fas fa-low-vision'"></i>Colour Blindness Correction
      </div>
      <div *ngIf="chartData && tierData.hasDataSharingAccess == 'Y' && submissionCodeList?.length > 1" (click)="codesModal.show()">
        <i class="fas fa-list"></i>Show Codes
      </div>
      <div [class]="reportNotifications[0].notificationType.toLowerCase()" *ngIf="chartData && reportNotifications.length == 1 && reportNotifications[0].notificationType != 'SUB_PEER_GROUP'" (click)="reportNotificationsModal.show()">
        <i class="fal fa-exclamation-triangle"></i>{{ reportNotifications[0].notificationTitle }}
      </div>
      <div *ngIf="chartData && reportNotifications.length > 1" (click)="reportNotificationsModal.show()">
        <i class="fal fa-exclamation-triangle"></i>Notifications
      </div>
      <div *ngIf="chartData && alternativeReportId" (click)="showingAlternative ? toggleAlternative('base') : toggleAlternative('alternative')">
        <i [class]="showingAlternative ? 'fas fa-undo-alt' : 'fas fa-history'"></i>{{ showingAlternative ? 'Return to full report' : 'Show time series' }}
      </div>
    </div>

    <!-- Show Codes -->
    <div bsModal #codesModal="bs-modal" class="modal codeList" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <app-popup-modal [data]="submissionCodeMappedData" title="Code List"></app-popup-modal>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="!firstRun">
      <div class="row">
        <div class="col-md-6 col-md-offset-3 guidance">
          <h1>Toolkit Guidance</h1>
          <p>The Network's online toolkit is easy to use and contains a large number of useful comparisons. You may find it helpful to know the following:</p>
        </div>
      </div>
      <div class="row guide-row">
        <div class="col-md-4">
          <div class="guide-container">
            <img src="/assets/images/toolkit_guide_1.png" alt="Sidebar navigation">
            <p>
              The toolkit is split into sections, which you can navigate using the menu tree on the left.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="guide-container">
            <img src="/assets/images/toolkit_guide_2.png" alt="Bar chart with highlighted position">
            <p>
              Bar charts show your submission's position highlighted in red. The mean average is shown in green.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="guide-container">
            <img src="/assets/images/toolkit_guide_3.png" alt="Sample information table">
            <p>
              A range of summary statistics and submission-specific information is shown beneath the chart.
            </p>
          </div>
        </div>
      </div>
      <div class="row guide-row">
        <div class="col-md-4">
          <div class="guide-container">
            <img src="/assets/images/toolkit_guide_4.png" alt="Drop down lists for peer groups and years">
            <p>
              You can change peer groups, years, submissions and options using the drop-down menus at the top.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="guide-container">
            <img src="/assets/images/toolkit_guide_5.png" alt="Drop down lists for options and view">
            <p>
              On some charts, you can switch to a table view of the data using the top-right drop-down menu.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="guide-container">
            <img src="/assets/images/toolkit_guide_6.png" alt="The NHSBN User Guide">
            <p>
              For further guidance, you can download the full Members' Area User Guide by <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Other/2018/MembersAreaUserGuide.pdf" target="_blank">clicking here</a>.
            </p>
          </div>
        </div>
      </div>
    </div> -->
    
    <div *ngIf="!firstRun">

      <h3 class="text-center">Welcome to the {{projectDetails?.projectName}} toolkit</h3>

      <p>
        The toolkit contains all the metrics generated from the {{projectDetails?.projectName}} data collection. Please expand the
        menu tree on the left by clicking on the arrows and then click on the heading of the chart you wish to generate.
        Your position will be highlighted in red on the bar charts, if you supplied all the data required to calculate the
        metric. You can download or print the chart by clicking on the square, three-line icon in the top right hand corner
        of the chart. Where applicable, you can toggle between years using the drop down box in the tool bar at the top of
        the page. Where peer group profiling is available, you can select the peer group you wish to view using the drop
        down box. If you created and entered data for more than one submission, you can choose the submission you wish to
        view where applicable. On bar charts, all your submissions will be shown. For some charts, you can change the denominator
        used to calculate the metric using the drop down menu. The 'view' drop down box allows you to choose between viewing
        the chart and viewing the data table.
      </p>

      <p *ngIf="projectDetails.projectContact?.email">
        If you have any questions or suggestions about this toolkit, please contact
        <a href="mailto:{{projectDetails.projectContact.email}}">{{projectDetails.projectContact.name}}</a>.
      </p>

    </div>

    <div *ngIf="firstRun && !error && !groupError && !chartLoaded && !tableLoaded &&!hideLoadingSpinner" class="loading-spinner">
      <i class="fa fa-spinner fa-spin"></i>
    </div>

    <div class="text-center" *ngIf="groupError">
      <h3>The selected peer group does not exist for this chart.</h3>
      <p>Please select another peer group from the drop-down menu above.</p> 
    </div>

    <div class="text-center" *ngIf="error">
      <h3>There has been an error when obtaining the chart data.</h3>
      <p>Please contact the project support for further information.</p>
      <div><p>Reference: <b>{{ tid }}</b></p></div>
    </div>

    <!-- Load Full Screen Chart if total responses are over 35
    <button *ngIf="totalResponses > 35" class="btn btn-primary btn-blue btn-lg-chart" (click)="viewLargeChart()">
      <i class="fa fa-expand"></i>&nbsp;Show Full Screen
    </button> -->

      <div id="chart" *ngIf="chartData && !error && !groupError" [ng2-highcharts]="chartData" (click)="savePoint($event, selectedYear)"></div>

    <div *ngIf="projectId == 40 && noDataNotification" class="notification">
        The clinical outcome measure chart is not displayed, as your organisation did not submit the data for the {{ selectedYear }} {{ projectDetails.projectName }} project.
    </div>

    <div *ngIf="(projectId == 14 || projectId == 15) && selectedYear == 2019 && chartData" class="notification">
      Only Northern Ireland took part in the {{ projectDetails.projectName }} project in 2019. Data for England and Wales is available for 2018.
    </div>

    <div *ngIf="barthelKey" class="row">
      <div class="col-sm-6 col-sm-offset-3">
        <table class="table table-bordered">
          <tr>
            <td><img src="../assets/images/greenarrow.png" /></td>
            <td>Positive Barthel Score</td>
            <td><img src="../assets/images/naicavg.png" /></td>
            <td>Total Barthel Score mean</td>
          </tr>
          <tr>
            <td><img src="../assets/images/redarrow.png" /></td>
            <td>Negative Barthel Score</td>
            <td><img src="../assets/images/youravg.png" /></td>
            <td>Your service total Barthel Score mean</td>
          </tr>
        </table>
      </div>
    </div> 

    <div *ngIf="sunderlandKey" class="col-sm-6 col-sm-offset-3">
      <table class="table table-bordered">
        <tr>
          <td><img src="../assets/images/greenarrow2.png" /></td>
          <td>Positive Sunderland Score</td>
          <td><img src="../assets/images/naicavg.png" /></td>
          <td>Total Sunderland Score mean</td>
        </tr>
        <tr>
          <td><img src="../assets/images/redarrow2.png" /></td>
          <td>Negative Sunderland Score</td>
          <td><img src="../assets/images/youravg.png" /></td>
          <td>Your service total Sunderland Score mean</td>
        </tr>
      </table>
    </div>

    <div *ngIf="tomKey" class="col-sm-6 col-sm-offset-3">
      <table class="table table-bordered">
        <tr>
          <td><img src="../assets/images/greenarrow.png" /></td>
          <td>Positive TOM Score</td>
          <td><img src="../assets/images/naicavg.png" /></td>
          <td>Total TOM Score mean</td>
        </tr>
        <tr>
          <td><img src="../assets/images/redarrow.png" /></td>
          <td>Negative TOM Score</td>
          <td><img src="../assets/images/youravg.png" /></td>
          <td>Your service total TOM Score mean</td>
        </tr>
      </table>
    </div>

    <div *ngIf="premTable && premTable.length > 0 && !error">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th class="col-md-5">PREM Question</th>
            <th class="col-md-3">Option</th>
            <th class="col-md-2 text-center">National Average (%)</th>
            <th class="col-md-2 text-center">Your Position (%)</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let p of premTable">
            <tr>
              <td [attr.rowspan]="p.responses.length + 1">{{p.questionText}}</td>
            </tr>

            <tr *ngFor="let q of p.responses">
              <td>{{q.responseName}}</td>
              <td class="text-center">{{q.nationalPosition || '-'}}</td>
              <td class="text-center">{{q.individualPosition || '-'}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div *ngIf="rpsTable && rpsTable.length > 0 && !error">
      <ng-container *ngFor="let r of rpsTable">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="col-md-2">Section</th>
              <th class="col-md-3">Question</th>
              <th class="col-md-2">Your response</th>
              <th class="col-md-3" colspan="2">Overall results</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let q of r.questions; let i = index">
              <tr>
                <td *ngIf="i == 0" [attr.rowspan]="r.rowspan * 4"><strong><span [innerHTML]="r.sectionTitle | safe: 'html'"></span></strong>
                  - <span [innerHTML]="r.sectionText | safe: 'html'"></span></td>
                <td rowspan="4"><span [innerHTML]="q.questionText | safe: 'html'"></span></td>
                <td rowspan="4">{{q.yourResponse}}</td>
                <td>Fully Implemented</td>
                <td class="text-center">{{q.overallResults.fullyImplemented / q.overallResults.total | percent : '1.2-2'}}</td>
              </tr>

              <tr>
                <td>Partially Implemented</td>
                <td class="text-center">{{q.overallResults.partiallyImplemented / q.overallResults.total | percent : '1.2-2'}}</td>
              </tr>

              <tr>
                <td>Not Implemented</td>
                <td class="text-center">{{q.overallResults.notImplemented / q.overallResults.total | percent : '1.2-2'}}</td>
              </tr>

              <tr>
                <td>N/A</td>
                <td class="text-center">{{q.overallResults.notApplicable / q.overallResults.total | percent : '1.2-2'}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div *ngIf="tableTitle && tableData && !hideTable" class="table-spacing text-center">
          <h5>{{ tableH5 ? tableH5 : 'Sample Information' }}</h5>
          <table class="table table-bordered table-striped table-condensed table-metric"> <!-- removed [ngClass]="tableOnly ? 'dataTable' : ''" -->
            <thead>
              <tr>
                <th class="text-center table-heading" *ngFor="let t of tableTitle">{{t.name}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of tableData"> <!-- removed class="{{ data.highlighted }}" -->
                <ng-template ngFor let-d [ngForOf]="htmlService.keys(data)">
                  <td class="text-center">{{data[d] !== null ? data[d] : '-'}}</td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

              <p *ngIf="numberResponses" class="number-responses">
                Total number of responses: <strong>{{ numberResponses }}</strong>
                <span *ngIf="numberSubmissionResponses">
                  | Total submission responses: <strong>{{ numberSubmissionResponses }}</strong>
                </span>
              </p>

    <div *ngIf="tableDataFull.length > 0" class="table-spacing text-center">
      <h5>Sample Information</h5>
      <div *ngFor="let d of tableDataFull">
        <div class="series-name" (click)="selectedSeries === d.seriesName ? selectedSeries = null : selectedSeries = d.seriesName">
          {{ d.seriesName }}
          <i [class]="selectedSeries === d.seriesName ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
        </div>
        <table class="table table-bordered table-striped table-condensed table-metric" *ngIf="selectedSeries === d.seriesName">
          <thead>
            <tr>
              <th class="text-center table-heading" >Submission Code</th>
              <th class="text-center table-heading" >Response</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dd of d.data">
              <td>{{ dd.submissionCode }}</td>
              <td>{{ dd.response }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="tableTitle2 && tableTitle2.length > 0 && tableData2 && tableData2.length > 0" class="table-spacing text-center">
      <h5>Additional Sample Information</h5>
      <table class="table table-bordered table-condensed table-metric table-equal">
        <thead>
          <tr>
            <th class="text-center table-heading" *ngFor="let t of tableTitle2">{{t.name}}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let data of tableData2">
            <ng-template ngFor let-d [ngForOf]="htmlService.keys(data)">
              <td class="text-center">{{data[d] ? data[d] : 0}}</td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div *ngIf="selectedView == 'SC'" class="text-center" style="margin-top: 1rem;">
      <table class="table table-bordered table-striped table-condensed table-metric table-equal">
        <thead>
          <tr>
            <th class="text-center table-heading">R value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
              <td class="text-center">{{correlationCoefficient}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div *ngIf="figureData.length > 0" class="text-center">
      <h5>Your Response</h5> <!-- Formerly 'Your Figure' -->
      <table class="table table-bordered table-striped table-condensed table-metric table-equal">
        <thead>
          <tr>
            <th class="text-center table-heading" *ngFor="let t of figureTitle">{{t.name}}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let data of figureData">
            <ng-template ngFor let-d [ngForOf]="htmlService.keys(data)">
              <td class="text-center">{{data[d] !== null ? data[d] : "-"}}</td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Tier ID -->
    <div id="tier" *ngIf="chartData">{{ tid }}</div>
  </div>
</div>

<!-- Large Chart Modal -->
<div class="modal fade" bsModal #largeChartModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="viewLargeChartModal" aria-hidden="true">
  <div class="modal-dialog modal-lg-chart modal-lg">
      <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close pull-right" aria-label="Close" (click)="largeChartModal.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div *ngIf="chartData && !error" [ng2-highcharts]="chartData"></div>
          </div>
      </div>
  </div>
</div>

<!-- Peer Group Modal -->
<div class="modal fade" bsModal #peerGroupModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="viewPeerGroupModal" aria-hidden="true">
  <div class="modal-dialog" style="width: 800px;">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="peerGroupModal.hide()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Need Index Peer Group</h4>
        </div>
          <div class="modal-body">
            <p>
              The <b>need index</b> peer groups are based on the community need index calculated using the <b>weighted population</b> 
              and <b>population</b> values submitted to the project.</p>
              <p>
                <b>Highest</b>, <b>Middle</b> and <b>Lowest</b> refers to the relative position within the sample of organisations who
              submitted data to the 2023 District Nursing project:<br />
              <b>Lowest</b> is assigned to any organisation where the community need index was less than 0.9.<br />
              <b>Middle</b> is assigned to any organisation where the community need index was between 0.9 and 1.1.<br />
              <b>Highest</b> is assigned to any organisation where the community need index was more than 1.1.
            </p>
          </div>
      </div>
  </div>
</div>

<!-- Submissions Modal -->
<div class="modal fade" bsModal #submissionsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="viewSubmissionsModal" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="submissionsModal.hide()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Submission Codes</h4>
        </div>
          <div class="modal-body">
            <p>
              The following submissions are available for your selected organisation:
            </p>
            <p *ngFor="let s of submissionCodes">
              <strong [style.color]="s.color">{{ s.submissionCode }}: </strong> {{ s.submissionName }}
            </p>
          </div>
      </div>
  </div>
</div>

<!-- Report Details Modal -->
<div class="modal fade" bsModal #reportDetailsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="reportDetailsModal" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="reportDetailsModal.hide()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Information</h4>
        </div>
          <div class="modal-body" *ngIf="userReportParamaters">
            <div *ngFor="let p of userReportParamaters" class="parameter-container">
              <h4 *ngIf="userReportParamaters.length > 1">{{ p.seriesName }}</h4>
              <div *ngFor="let pp of p.parameterTypes | orderBy: 'displaySequence'">
                <p class="parameter-type">{{ pp.parameterTypeName }}</p>
                <ng-container class="parameter" *ngFor="let ppp of pp.parameters">
                  <p *ngIf="ppp.parameterDefinition">
                    {{ ppp.parameterDefinition }}
                  </p>
                  <div *ngIf="!ppp.parameterDefinition" style="margin-bottom:0.25em;">
                    {{ ppp.questionText ? ppp.questionText : ppp.constantValue }}
                    <span *ngIf="ppp.subQuestionText">&nbsp;| {{ ppp.subQuestionText }}</span>
                    <span *ngIf="ppp.subQuestionHeadingText">&nbsp;| {{ ppp.subQuestionHeadingText }}</span>
                    <i *ngIf="ppp.dataDefinition || ppp.parentQuestionText"><br>Context: {{ ppp.dataDefinition ? ppp.dataDefinition : ppp.parentQuestionText }}</i>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>

<!-- Report Notifications Modal -->
<div class="modal fade" bsModal #reportNotificationsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="reportNotificationsModal" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="reportNotificationsModal.hide()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Notifications</h4>
        </div>
          <div class="modal-body" *ngIf="reportNotifications">
            <div *ngFor="let n of reportNotifications" class="parameter-container">
              <h4>{{ n.notificationTitle }}</h4>
              <div [innerHtml]="n.notificationText"></div>
            </div>
          </div>
      </div>
  </div>
</div>

<!-- Option Preference Modal -->
<div class="modal fade" bsModal #optionPreferenceModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="optionPreferenceModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="optionPreferenceModal.hide()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Option Preference</h4>
      </div>
      <div class="modal-body">
        <p>
          Select an option preference using the drop-down below.
        </p>
        <select id="optionPreference" [(ngModel)]="optionPreference" (change)="changeOptionPreference($event.target.value)" class="form-control">
          <option [value]="undefined">No Preference</option>
          <option disabled>──────────</option>
          <option *ngFor="let opt of optionGroups" [value]="opt">{{ opt }}</option>
        </select>
        <p style="margin-top:0.5em;">
          The option you select above will be saved whilst you are browsing the toolkit and, if the option is available for the selected chart, it will be automatically selected. If the option is not available, the system will default to the first available option.
        </p>
        <button class="btn btn-primary" (click)="optionPreferenceModal.hide()">Save Preference</button>
        <button class="btn btn-default" style="margin-left:0.25em" (click)="removeOptionPreference(); optionPreferenceModal.hide()">Remove Preference</button>
      </div>
    </div>
  </div>
</div>

<!-- Highlight Preference Modal -->
<div class="modal fade" bsModal #highlightPreferenceModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="highlightPreferenceModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="highlightPreferenceModal.hide()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Highlight Peer Group Preference</h4>
      </div>
      <div class="modal-body">
        <p>
          Select a peer group to highlight throughout the toolkit.
        </p>
        <select id="optionPreference" [(ngModel)]="highlightedPeerGroupId" (change)="highlightedPeerGroupId = $event.target.value" class="form-control">
          <option disabled [value]="undefined">No Preference</option>
          <option disabled>──────────</option>
          <option *ngFor="let group of allPeerGroups" [value]="group.peerGroupId">{{ group.peerGroupName }}</option>
        </select>
        <p style="margin-top:0.5em;">
          The peer group you select above will be highlighted throughout the toolkit where it is possible to do so. The selection will be cleared between years, as peer groups are redefined each year.
        </p>
        <button class="btn btn-primary" (click)="changeHighlightedPeerGroup(highlightedPeerGroupId); highlightPreferenceModal.hide()">Save Group</button>
        <button class="btn btn-default" style="margin-left:0.25em" (click)="removeHighlightedPeerGroup(); highlightPreferenceModal.hide()">Remove Group</button>
      </div>
    </div>
  </div>
</div>