<div class="tiers-loading" *ngIf="!tierState">
  <i class="fa fa-spinner fa-spin"></i>Loading the toolkit...
</div>

<div class="container-fluid" id="containerOutputs" *ngIf="!fullValidation">
  <div class="row">
    <div *ngIf="tierState">
      <div class="col-sm-3" id="listTree">
        <div class="output-header" (click)="navDashboard(projectId)"> 
          <div class="header-container">
            <div class="header-left">
              <h3><span class="fa fa-caret-left"></span></h3>
            </div>
            <div class="header-right">
              <h3>{{nodes?.projectName}}</h3>
            </div>
          </div>            
        </div>

        <div>
          <select id="organisations" class="form-control" [ngModel]="currentOrganisation" (ngModelChange)="onOrgChange($event)">
            <option *ngFor="let o of organisation" [ngValue]="o.organisationId" [class]="o.isDefunct == 'Y' ? 'defunct' : 'live'">{{o.organisationName}}</option>
          </select>
          <div id="outputSidebar">
            <div class="form-group" (click)="!searchableTiers ? getSearchTiers(projectId) : ''">
              <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="Search charts...">
            </div>
            <div id="searchableTiers" *ngIf="searchText && searchText.length > 1">
              <div class="tier" *ngFor="let s of searchableTiers | filterBy: ['tierName', 'reportNameFormatted', 'reportId']: searchText | orderBy: '-reportYear'" (click)="openSearch(s)">
                <div class="tier-name">{{ s.reportNameFormatted }}</div>
                <div class="tier-meta">
                  <span>{{ s.reportYear }}</span>
                  <span *ngIf="s.serviceItemName">{{ s.serviceItemName }}</span>
                </div>
              </div>
              <div class="no-results" *ngIf="!searchableTiers && searchText.length > 1">
                Loading charts...
              </div>
              <div class="no-results" *ngIf="searchableTiers && (searchableTiers | filterBy: ['tierName', 'reportNameFormatted', 'reportId']: searchText).length === 0">
                There are no results for this search
              </div>
            </div>
          </div>
        </div>

        <div class="tree-container" [style.height.px]="windowHeight">
          <tree-root #tree 
            [nodes]="nodes?.tiers" 
            [options]="options" 
            (activate)="onNodeSelection($event.node.data)" 
            (initialized)="onInitialized(tree)" 
            (toggleExpanded)="toggleExpanded($event)"
          >
            <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
              <div
                [class]="node.getClass()"
                [class]="node.data.isVisible === 'H' ? 'activeAdmin-show' : 'activeAdmin-hide'"
                [class.tree-node]="true"
                [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
                [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
                [class.tree-node-leaf]="node.isLeaf"
                [class.tree-node-active]="node.isActive"
                [class.tree-node-focused]="node.isFocused"
                *ngIf="node.data.isVisible == 'Y' || (node.data.isVisible === 'H' && activeAdmin === true)">

                <tree-node-wrapper [node]="node" [index]="index" [templates]="templates">
                </tree-node-wrapper>

                <tree-node-children [node]="node" [templates]="templates" [id]="node.data.id">
                </tree-node-children>
              </div>
            </ng-template>
          </tree-root>
        </div>
      </div>

      <div class="col-sm-9">
        <app-outputs-display #outputsDisplay
          [nodeSelection]="nodeSelection" 
          [organisationSelection]="organisationSelection" 
          [projectDetails]="projectDetails"
          [activeAdmin]="activeAdmin"
          [showSubmissionId]="showSubmissionId"
          (peerGroupAdd)="peerGroupAdd($event)"
          (submissionAdd)="submissionAdd($event)"
          (optionAdd)="optionAdd($event)"
          (point)="activeAdmin ? savePoint($event) : null"
          (reportParameters)="setReportParameters($event)"
          (download)="setDownload($event)"
          (reportDetails)="reportDetails = $event">
        </app-outputs-display>
      </div>
    </div>

    <div *ngIf="error">
      <p>There has been an issue whilst loading the outputs page, please contact a member of the NHS Benchmarking Network to assist you with this issue.</p>
    </div>
  </div>
</div>

<div id="activeAdminBar" [class]="fullValidation ? 'top' : ''" *ngIf="activeAdmin">
  <a [href]="'http://admin.bmchealth.co.uk/project/' + currentYear + '/' + projectId + '/validation'" target="_blank" class="btn btn-default">Validation Tool</a>
  <button *ngIf="tiersEditParent" class="btn btn-default" (click)="editTierModal.show()">
    <i class="far fa-edit"></i>Edit Tier: {{ tiersEditParent.name.length > 6 ? (tiersEditParent.name | slice: 0:10) + '...' : tiersEditParent.name }}
  </button>
  <button class="btn btn-default" *ngIf="reportDetails" (click)="editReportModal.show()">
    <i class="far fa-edit"></i>Edit Report
  </button>
  <button class="btn btn-default" *ngIf="reportDetails" (click)="showParameters = !showParameters">
    <i class="far fa-list-alt"></i>{{ showParameters ? 'Hide' : 'Show' }} Parameters
  </button>
  <button class="btn btn-default" *ngIf="reportDetails" (click)="showSubmissionId = !showSubmissionId">
    <i [class]="showSubmissionId ? 'fas fa-toggle-on' : 'fas fa-toggle-off'"></i>Submission IDs
  </button>
  <angular2csv *ngIf="download" [data]="download" filename="data-{{ projectId }}-{{ tierParam }}" [options]="csvOptions"></angular2csv>
</div>

<div id="quickValidation" *ngIf="quickValidation && validations">
  <table>
    <thead>
      <th>Tier</th>
      <th>Submission</th>
      <th>Description</th>
      <th>Value</th>
      <th style="text-align:center">Delete</th>
    </thead>
    <tbody>
      <tr *ngFor="let v of validations">
        <td>{{ v.reportName }} ({{ v.tierId }})</td>
        <td>{{ v.submissionCode || '-' }}</td>
        <td>{{ v.validationDescription || '-' }}</td>
        <td>{{ v.validationValue }}</td>
        <td style="text-align:center">
          <i class="fas fa-minus-square" *ngIf="!v.questionId" (click)="deleteValidation(v.validationId)"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div id="reportParameters" *ngIf="showParameters">
  <div *ngFor="let p of reportParameters" class="parameter-container">
    <h4 *ngIf="reportParameters.length > 1">{{ p.seriesName }}</h4>
    <div *ngFor="let pp of p.parameterTypes | orderBy: 'displaySequence'">
      <div class="parameter-type">{{ pp.parameterTypeName }}</div>
      <div class="parameter" *ngFor="let ppp of pp.parameters">
        {{ ppp.questionId ? ppp.questionId : '' }}{{ ppp.questionPart ? '-' + ppp.questionPart : '' }}
        <span *ngIf="ppp.parameterDefinition">
          {{ ': ' + ppp.parameterDefinition }}
        </span>
        <span *ngIf="!ppp.parameterDefinition">
          {{ ppp.questionText ? ppp.questionText : ppp.constantValue }}
            <span *ngIf="ppp.subQuestionText">&nbsp;| {{ ppp.subQuestionText }}</span>
            <span *ngIf="ppp.subQuestionHeadingText">&nbsp;| {{ ppp.subQuestionHeadingText }}</span>
            <i *ngIf="ppp.dataDefinition || ppp.parentQuestionText"><br>Context: {{ ppp.dataDefinition ? ppp.dataDefinition : ppp.parentQuestionText }}</i>
          <i *ngIf="ppp.dataDefinition || ppp.parentQuestionText"><br>Context: {{ ppp.dataDefinition ? ppp.dataDefinition : ppp.parentQuestionText }}</i>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="storedProcedure" class="parameter-container">
    <br />
    <div class="parameter-type">Stored Procedure</div>
    <div class="parameter">{{ storedProcedure }}</div>
  </div>
</div>

<!-- Full Validation -->
<app-active-admin 
  [fullValidation]="fullValidation" 
  [projectDetails]="projectDetails" 
  [projectId]="projectId"
  (validationsEmitter)="validations = $event"
  (fullValidationEmitter)="fullValidation = $event"
  *ngIf="fullValidation">
</app-active-admin>

<!-- Edit Report -->
<div bsModal #editReportModal="bs-modal" class="modal" tabindex="-1" role="dialog" (onHide)="getOrganisationsByUser(projectId)">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <app-edit-report [tierDetails]="nodeSelection" [reportDetails]="reportDetails" [editReportModal]="editReportModal"></app-edit-report>
    </div>
  </div>
</div>

<!-- Edit Tier -->
<div bsModal #editTierModal="bs-modal" class="modal" tabindex="-1" role="dialog" (onHide)="getOrganisationsByUser(projectId)">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <app-edit-tier (valueChange)="updateTierName($event)" [reportDetails]="reportDetails" [editTierModal]="editTierModal" [tiersEditParent]="tiersEditParent"></app-edit-tier>
    </div>
  </div>
</div>
