import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserCheckComponent } from './user-check.component';

const userCheckRoutes: Routes = [
  { path: 'userCheck', component: UserCheckComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(userCheckRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class UserCheckRoutingModule { }