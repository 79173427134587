import { Injectable } from '@angular/core';


@Injectable()
export class ErrorService {
  constructor() { }

  getErrorMessage(statusCode: number) {
    switch (statusCode) {
      case 3101: {
        return 'The delegate does not have the necessary permissions to register for this event. Please contact the Support Team.';
      }
      case 3102: {
        return 'This event is no longer available for registration.';
      }
      case 3103: {
        return 'The delegate is already registered for this event.';
      }
      case 4201: {
        return 'Registration is not available.';
      }
    }
  }
}