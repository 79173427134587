import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  // Data from Classic View link
  public classicViewLandingPage(name: string) {
    gtag('event', name)
  }
}