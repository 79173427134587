import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectDetailComponent } from './project-detail.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const projectdetailRoutes: Routes = [
  // Redirect for new Acute Therapies project (2019)
  { path: 'project-detail/3', redirectTo: 'project-detail/40', pathMatch: 'full' },
  { path: 'project-detail/7', redirectTo: 'project-detail/40', pathMatch: 'full' },
  { path: 'project-detail/25', redirectTo: 'project-detail/40', pathMatch: 'full' },
  { path: 'project-detail/26', redirectTo: 'project-detail/40', pathMatch: 'full' },
  // Standard path
  { path: 'project-detail/:projectId', component: ProjectDetailComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(projectdetailRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProjectDetailRoutingModule { }