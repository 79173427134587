<div class="container-fluid unauth-page">
    <div class="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">

        <div class="login-header">
            <img src="../assets/images/nhsbn-login-banner-new.png" alt="NHSBN Banner">
        </div>

        <div class="panel panel-default">
            <div class="panel-body">
              <h1>Reset Password</h1>

              <p>
                Please enter a new password below, this will need to be done twice and will need to confirm to the below rules.
              </p>

              <ul>
                <li>Minimum of 8 characters</li>
                <li>Minimum of 1 special character</li>
              </ul>

              <hr>

              <form [formGroup]="resetPasswordConfirm" novalidate (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label>Email Address</label>
                  <input type="email" class="form-control form-fix" formControlName="emailAddress">
                </div>

                <div class="form-group">
                  <div *ngIf="formErrors.emailAddress" class="alert alert-danger">
                    <p>{{formErrors.emailAddress}}</p>
                  </div>
                </div>

                <div class="form-group">
                  <label>New Password</label>
                  <input type="password" class="form-control form-fix" formControlName="newPassword">
                </div>

                <div class="form-group">
                  <div *ngIf="formErrors.newPassword" class="alert alert-danger">
                    <p>{{formErrors.newPassword}}</p>
                  </div>
                </div>

                <div class="form-group">
                  <label>Confirm New Password</label>
                  <input type="password" class="form-control form-fix" formControlName="confirmNewPassword">
                </div>

                <div class="form-group">
                  <div *ngIf="formErrors.confirmNewPassword" class="alert alert-danger">
                    <p>{{formErrors.confirmNewPassword}}</p>
                  </div>
                </div>

                <div class="form-group">
                  <div *ngIf="passwordMatch" class="alert alert-danger">
                    <p>Passwords do not match. Please re-enter both passwords.</p>
                  </div>
                </div>

                <div class="form-group">
                  <div *ngIf="error" class="alert alert-danger">
                    <p>{{ errorText }}</p><br />
                    <p>If you're having problems you can: <br />
                      <a [routerLink]="['/reset-password']">Request a new reset password link</a> <br />
                      <a href="https://www.nhsbenchmarking.nhs.uk/contact">Contact the support team</a>
                    </p>
                  </div>
                </div>

                <div class="form-group">
                  <div *ngIf="passwordReset" class="alert alert-success">
                    <p>Password has been reset successfully, you can navigate back to the landing page using the 'Back to Login'</p>
                  </div>
                </div>

                <button type="submit" [disabled]="!resetPasswordConfirm.valid" [promiseBtn]="newSpinner" class="btn btn-lg btn-primary nhs-blue-bg btn-promise">Reset Password</button>
                <button type="click" (click)="navLanding()" class="btn btn-default">Back to Login</button>
              </form>
        </div>
      </div>

      <div class="login-footer">
        <p>
            The Benchmarking Network is the in-house benchmarking service of the NHS. The Network is hosted by 
            <a href="https://www.elft.nhs.uk/" target="_blank">East London Foundation Trust</a>.
        </p>
        <p>
            &copy; East London Foundation Trust {{copyDate | date:'yyyy'}}
        </p>
      </div>

  </div>
</div>