<!-- SELECTOR BAR-->
<div id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back">
            <a [routerLink]="['/home']" class="dropdown-item" style="color: white;" routerLinkActive="active">
              <i class="fa fa-caret-left"></i>
              Back to Home
            </a>
          </span>
        </div>
        <div class="col col-md-3 col-sm-6 col-xs-12" style="float: right;">
          <div class="form-group">
            <select class="form-control" id="organisation" name="organisation" [ngModel]="organisationId"
              (ngModelChange)="onOrgChange($event)">
              <option *ngFor="let o of organisation" [ngValue]="o.organisationId">{{o.organisationName}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-fluid" id="containerResources">

  <!-- SIDEBAR -->
  <div class="col-lg-4 col-md-4 col-sm-12" id="sidebar">
    <div>
      <h1>Resources</h1>
      <p class="intro-p">
        This page shows resources from across all Network projects, including presentations, reports and case studies.
      </p>
    </div>
  </div>



  <!-- CONTENT -->
  <div class="col-lg-7 col-lg-offset-1 col-md-8 col-sm-12">

    <div class="loading-overview" *ngIf="!resourceData">
      <p><i class="fas fa-spinner fa-spin"></i> Loading resources. Please wait...</p>
    </div>

    <div id="search" *ngIf="resourceData">
      <div class="form-group">
        <i class="fal fa-search" id="searchIcon"></i>
        <input type="text" class="form-control" placeholder="e.g. Report, Recording, Presentation" [(ngModel)]="searchText" />
      </div>
      <div class="filter" *ngIf="hideShowYear">
        <span *ngFor="let year of availableYears | slice:0:5" (click)="getResources(year)" 
          [class]="selectedYear == year ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'">
          {{ year }}
        </span>
      </div>
      <div class="filter" *ngIf="hideShowSector">
        <span *ngFor="let data of resourceData.projectDocuments" 
          (click)="filterBySector(data.sector)" 
          [class]="sector == data.sector ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'">
          {{ data.sector }}
        </span>
        <span (click)="reset(); sector = null; setQueryParam('sector')" class="btn btn-sm btn-default btn-filter"><i class="far fa-close"></i></span>
      </div>
      <div class="filter" *ngIf="hideShowSector">
        <span *ngFor="let type of resourceType" 
          (click)="filterByType(type)" 
          [class]="filteredType == type ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'">
          {{ type }}
        </span>
      <span (click)="reset(); filteredType = null; setQueryParam('group')" class="btn btn-sm btn-default btn-filter"><i class="far fa-close"></i></span>
      </div>
      <div id="options">
        <button (click)="hideShowYear = !hideShowYear" 
          [class]="hideShowYear ? 'btn btn-sm btn-default' : 'btn btn-sm btn-primary'">
          <i class="fas fa-filter"></i>{{ hideShowYear ? 'Close' : selectedYear }} 
        </button>
        <button *ngIf="resourceData?.projectDocuments.length > 0" (click)="hideShowSector = !hideShowSector" 
          [class]="hideShowSector ? 'btn btn-sm btn-default' : 'btn btn-sm btn-primary'">
          <i class="fas fa-filter"></i>{{ hideShowSector ? 'Close' : 'Filter by...' }} 
        </button>
        <button *ngIf="resourceData?.projectDocuments.length > 0" (click)="displayEventDocuments(); showNonProjectDocs = !showNonProjectDocs" 
          [class]="showNonProjectDocs ? 'btn btn-sm btn-default' : 'btn btn-sm btn-primary'">
          <i [class]="!showNonProjectDocs ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>Event documents
        </button>
      </div>

      <div *ngIf="sector || filteredType" class="filter-showing" (click)="reset(); filteredType = null; sector = null">
        Resources are filtered to <strong>{{ (sector && filteredType) ? (sector + ' and ' + filteredType) : ((sector && !filteredType) ? sector : filteredType) }}</strong> <i class="far fa-times"></i>
      </div>

      <!-- Project List -->
      <div class="row" *ngIf="resourceData && !showNonProjectDocs">
        <div class="col-md-12" style="padding-top: 1em;" *ngFor="let data of resourceData.projectDocuments | filterBy: ['sector']: sector">
              <h5>{{ data.sector }}</h5>
              <div *ngFor="let project of data.projects">
                <div class="project-list">
                  <b class="list-group-item list-group-item-action active" 
                    [ngStyle]="(filteredRowCount(project.documents) == '0') ? {'background-color': '#768692', 'border-color': '#768692'} : ''" (click)="showDocuments(project.projectId)"
                    style="border-radius: 5px;">
                    <i [class]="project.projectId == selectedProject ? 'fas fa-chevron-up' : 'fas fa-chevron-down'" style="padding-right: 10px;"></i>
                    {{ project.projectName }}
                    <span class="badge badge-primary badge-pill">{{ filteredRowCount(project.documents) || project.documents.length }}</span>
                  </b>
                  <table class="table table-striped" *ngIf="project.projectId == selectedProject">
                    <tbody>
                      <tr *ngFor="let docs of project.documents | filterBy: ['documentCategoryName', 'documentName']: (searchText || filteredType); let i = index" 
                        (click)="getSelectedDocument(docs.documentId)">
                        <td [title]="(docs.documentName.length > 80) ? docs.documentName : ''"
                          innerHTML="{{ (docs.documentName.length > 80) ? (docs.documentName | slice:0:80) + '...' : (docs.documentName) }}">
                        </td>
                        <td style="text-align: right;" innerHTML="{{ docs.documentCategoryName}}"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
        </div>
      </div>

      <!-- Non Project List -->
      <div class="row" *ngIf="(!resourceData && !sector) || showNonProjectDocs">
        <div class="col-md-12">
          <hr />
          <b class="list-group-item list-group-item-action active" style="border-radius: 5px;">
            Event documents
            <span class="badge badge-primary badge-pill">{{ resourceData?.nonProjectDocuments.length }}</span>
          </b>
          <table class="table table-striped">
            <tbody>
              <tr *ngFor="let data of resourceData?.nonProjectDocuments | filterBy: ['documentCategoryName', 'documentName']: searchText" 
                (click)="getSelectedDocument(data.documentId)" style="display: flex; justify-content: space-between;">
                <td [title]="(data.documentName.length > 80) ? data.documentName : ''"
                  innerHTML="{{ (data.documentName.length > 80) ? (data.documentName | slice:0:80) + '...' : (data.documentName) }}">
                </td>
                <td innerHTML="{{ data.documentCategoryName }}"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>