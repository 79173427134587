import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProjectComponent } from './project.component';
import { ProjectRoutingModule } from './project-routing.module';

import { Ng2HighchartsModule } from 'ng2-highcharts';
import { NgPipesModule, OrderByPipe, GroupByPipe, ChunkPipe } from 'ngx-pipes';
import { SafePipeModule } from '../utilities/pipes/safe-pipe.module'

import { BlockComponent } from 'app/project/block/block.component';
import { SectionComponent } from 'app/project/section/section.component';
import { Angular2CsvModule } from 'angular2-csv';
import { IcsService } from 'app/utilities/services/ics.service';
import { InfoPanelModule } from 'app/shared/info-panel/info-panel.module';
import { CsvDownloadModule } from 'app/shared/csv-download/csv-download.module';
import { ModalComponent } from './modal/modal.component';
import { ModalModule } from 'ngx-bootstrap';

window['Highcharts'].setOptions({
  lang: {
      thousandsSep: ','
  }
});

@NgModule({
  imports: [
    CommonModule,
    ProjectRoutingModule,
    Ng2HighchartsModule,
    NgPipesModule,
    FormsModule,
    SafePipeModule,
    Angular2CsvModule,
    InfoPanelModule,
    CsvDownloadModule,
    ModalModule.forRoot(),
  ],
  declarations: [
    ProjectComponent,
    BlockComponent,
    SectionComponent,
    ModalComponent
  ],
  providers: [
    OrderByPipe,
    GroupByPipe,
    ChunkPipe,
    IcsService
  ]
})

export class ProjectModule {}