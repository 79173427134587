import { Component, Input, OnInit } from '@angular/core';
import { CsvDownload } from 'app/utilities/classes/csv/csv-download';

@Component({
  selector: 'app-csv-download',
  templateUrl: './csv-download.component.html',
  styleUrls: ['./csv-download.component.scss']
})
export class CsvDownloadComponent implements OnInit {

  @Input() result: any;
  @Input() csvSettings: CsvDownload;

  constructor() { }

  ngOnInit() {
    if(this.csvSettings.styling == false) {
      this.exportCSV(this.result)
    }
  }

  public exportCSV(data): void {
    let csvData = this.convertToCsv(data, this.csvSettings.headers, this.csvSettings.keys);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) { 
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${this.csvSettings.fileName}.csv`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  private convertToCsv(objArray: any, arrHeader: Array<string>, keys): string {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;

    let str = '';
    let row = '';
    for (let index in arrHeader) {
      row += arrHeader[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in arrHeader) {
        let head = keys[index];
        line += this.strRep(array[i][head]) + ',';
      }
      str += line + '\r\n';
    }
    return str;
  }

  private strRep(data): string {
    data ? data = data.toString().replace(/,/g, " ").replace(/\n/g, " ") : "undefined";
    if (data == undefined || data == "undefined") {
      return "";
    }
    else if (data == null) {
      return "";
    }
    else {
      return data;
    }
  }

}
