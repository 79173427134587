import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DataCollectionComponent } from './data-collection.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const datacollectionRoutes: Routes = [
  { path: 'data-collection/:type/:submissionId/:year', component: DataCollectionComponent, canActivate: [AuthGuardService] },
  { path: 'data-collection/:type/:submissionId/:year/:serviceItemId', component: DataCollectionComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(datacollectionRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DataCollectionRoutingModule { }