import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-outputs-display-options',
  templateUrl: './outputs-display-options.component.html',
  styleUrls: ['./outputs-display-options.component.scss']
})
export class OutputsDisplayOptionsComponent implements OnChanges {

  @Input() options: any;
  @Input() selectedOption?: number;
  @Output() emitOptionId: EventEmitter<number> = new EventEmitter();

  optionsOpen: boolean = false;
  selectedOptionDetails: any;

  constructor() {
  }

  ngOnChanges() {
    if (this.options.length && this.selectedOption) {
      this.selectedOptionDetails = this.options.find(opt => opt.optionId == this.selectedOption);
    } else if (this.options.length && !this.selectedOption) {
      this.selectedOptionDetails = this.options.find(opt => opt.optionId == 0);
    }
  }

  onOptionChange(optionId: number): void {
    this.optionsOpen = false;
    this.emitOptionId.emit(optionId);
  }

}