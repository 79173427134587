import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabsModule } from 'ngx-bootstrap';

import { ProjectDetailComponent } from './project-detail.component';
import { ProjectDetailRoutingModule } from './project-detail-routing.module';

import { SafePipeModule } from '../utilities/pipes/safe-pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  imports: [ 
    CommonModule, 
    TabsModule.forRoot(),
    SafePipeModule,
    ProjectDetailRoutingModule ,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule
  ],
  declarations: [ ProjectDetailComponent ]
})
export class ProjectDetailModule {}