export class Event {
    constructor (
        public eventId: number,
        public eventName: string,
        public eventLocation: string,
        public eventDt: Date,
        public eventEndDt: Date,
        public projectCategory: number,
        public projectCategoryName: string,
        public placesPerOrganisation: number,
        public maxCapacity: number,
        public eventDescription: string,
        public cubeFile: string,
        public registrationLink: string,
        public registrationEnabled: string,
        public isVisible: string,
        public isOnline: string,
        public when: string,
        public documentCount: number,
        public requestTags: string,
        public regionName: string,
        public regionId: number,
        public meetingId: string,
        public meetingPasscode: string,
        public meetingUrl: string
    ) {}
}
