import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Project } from 'app/utilities/classes/project/project';
import * as moment from 'moment';

interface Section {
  title: string;
  projects: Array<Project>;
}

@Component({
  selector: 'app-project-table-card',
  templateUrl: './project-table-card.component.html',
  styleUrls: ['./project-table-card.component.scss']
})
export class ProjectTableCardComponent implements OnInit {
  @Input() sections: Section;
  @Input() archived: Section;
  @Input() searchText: string;
  @Input() registrationStatus: boolean;
  @Input() organisationSelection: number;

  constructor(
    private router: Router
  ) { }

  ngOnInit() { }

  dateFormat(date) {
    return moment(date).add(1, 'years').format('YY');
  }

  navProjectDetail(projectId: number) {
    this.router.navigate(["/project-detail", projectId]);
  }

  navProjectOverview(projectId: number) {
    this.router.navigate(["/project-overview", projectId]);
  }

  navDashboard(projectId: number) {
    if (projectId == 39) {
      this.router.navigate(["/ics"]);
    } else {
      this.router.navigate(["/dashboard", projectId]);
    }
  }

  navProjectRegistration(projectId: number, organisationId: number) {
    this.router.navigate(["/project-registration", projectId, organisationId]);
  }

}
