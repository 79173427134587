<!-- SELECTOR BAR-->
<div id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back" (click)="navHome()">
            <i class="fa fa-caret-left"></i> Back to Home
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Content -->
<div class="container-fluid" id="containerContact">
  <!-- SIDEBAR -->
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12" id="sidebar">
      <h1>Contact</h1>
      <p class="intro-p">
        You can contact the NHS Benchmarking Network at any time using the form
        here to the side. Please also feel free to contact each of the project
        teams using the telephone numbers provided. These will redirect you to MS
        Teams Phone.
      </p>
      <hr />

      <div class="row" >
      <select  class="form-control select-width" (change)="getContacts($event.target.value)">
        <option value='' disabled>Select a Project...</option>
        <option *ngFor="let contacts of contactOptions" value="{{ contacts }}">
          {{ contacts }}
        </option>
      </select>
      <div class="card">
        <div class="card-body" style="max-height: 20rem;overflow-y: auto;">
          <div *ngFor="let contact of selectedContact">
            <div style="height: 105px;" class="col-sm-12 col-lg-6 ">
              <b>{{ contact.title }}</b><br />
              <div>  {{ contact.phone }}</div>
              <a href="mailto:{{contact.email}}" style="font-size: 12px;">
              {{ contact.email }}<br /></a>
            </div>
          </div>
        </div>
      </div>
</div>
    </div>

    <!-- CONTENT -->
    <div id="contact" class="col-lg-7 col-lg-offset-1 col-md-6 col-sm-12 padding">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-5 form-card" *ngIf="!isDisplayed">
          <form [formGroup]="profileForm" [hidden]="isDisplayed" (ngSubmit)="onSubmit()">
            <div class="col-lg-6">
              <label>Name</label>
              <input type="text" class="form-control" placeholder="{{ userName }}" disabled />
            </div>
            <div class="col-lg-6">
              <label>Email</label>
              <input type="email" name="Email" class="form-control" id="inputEmail3" placeholder="{{ userEmail }}"
                disabled />
            </div>
            <div class="col-lg-6">
              <label [class]="formSubmitted && profileForm.controls.project.errors?.required ? 'text-danger' : 'null'"
                for="project">Project</label>
              <select id="project" class="form-control select-width" formControlName="project">
                <option value='' disabled>Select a Project...</option>
                <option *ngFor="let o of flattenedProjects" value="{{ o }}">
                  {{ o }}
                </option>
              </select>
            </div>
            <div class="col-lg-6">
              <label>Phone</label>
              <input type="text" name="Phone" class="form-control" formControlName="phone" />
              <div class="alert alert-danger" *ngIf="profileForm.controls['phone'].hasError('pattern')">
                Only use numbers
              </div>
            </div>
            <div class="col-lg-6">
              <label
                [class]="formSubmitted && profileForm.controls.subject.errors?.required ? 'text-danger' : 'null'">Subject*</label>
              <input type="text" name="Subject" class="form-control" formControlName="subject" />
            </div>
            <div class="col-lg-6">
              <label
                [class]="formSubmitted && profileForm.controls.organisation.errors?.required ? 'text-danger' : 'null'">Organisation*</label>
              <input type="text" class="form-control" formControlName="organisation" />
            </div>
            <div class="col-lg-12">
              <label
                [class]="formSubmitted && profileForm.controls.message.errors?.required ? 'text-danger' : 'null'">Message*</label>
              <textarea name="Message" class="form-control" rows="5" formControlName="message"></textarea>
            </div>
            <div class="col-lg-12">
              <button type="submit" class="btn btn-primary btn-request">
                Submit
              </button>
            </div>
            <div class="col-lg-12 text-center" *ngIf="displayError">
              <label class="error-text-head">Submission Failed</label>
              <label class="error-text-body">You can contact us directly at
                <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries@nhsbenchmarking.nhs.uk</a><br />
                or just give us a call at 0161 521 0818</label>
            </div>
          </form>
        </div>
        <div class="col-lg-10 col-md-12 col-sm-5 form-card" *ngIf="isDisplayed">
          <div class="col-lg-12 text-center">
            <h3>Request submitted</h3>
            <label class="error-text-body">
              Thanks for contacting us! The support team will be in touch with you
              shortly.</label>
            <b (click)="isDisplayed = !isDisplayed">Submit another request <i class="fas fa-arrow-right"></i></b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>