<div class="container-fluid">
    <form [formGroup]="innerForm" class="form-horizontal" autocomplete="off" id="questionForm">
        <div [ngSwitch]="true">

            <!-- NARRATIVE -->
            <div *ngSwitchCase="question.questionType == 'narrative'" [id]="question.questionId" class="form-group intro" [class.linked-question]="question.questionId == linkedQuestion">
                <label class="col-sm-11 col-xs-10 vcenter" [class.boldTitle]="question.formatModifier == 'B'" [class.italicTitle]="question.formatModifier == 'I'" [class.fullTitle]="question.formatModifier == 'T'">
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label><!--

                --><div class="col-sm-1 col-xs-2 vcenter">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- TEXT -->
            <div *ngSwitchCase="question.questionType == 'text'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion" [class.condition-disabled]="conditionQuestion" [class.condition-hidden]="conditionQuestionHide">
                <div *ngIf="conditionQuestion" >
                    <div class="hint -disabled">
                        <i class="fas fa-ban"></i>{{ conditionalQuestionWarning }}
                    </div>
                </div>
                <label class="col-sm-4 col-xs-12 vcenter control-label">
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label><!--
                --><div class="col-sm-7 col-xs-10 vcenter">
                    <input *ngIf="question.formatModifier != 'M' && question.formatModifier != 'L'" 
                            [formControlName]="question.responses[0].uniqueId" 
                            class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                            [id]="question.responses[0].uniqueId" 
                            type="text" 
                            (blur)="questionCount(question.responses[0].uniqueId, $event.target.value)">
                    <textarea *ngIf="question.formatModifier == 'M' || question.formatModifier == 'L'" 
                                [formControlName]="question.responses[0].uniqueId" 
                                class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                [class.medium]="question.formatModifier == 'M'" 
                                [class.large]="question.formatModifier == 'L'" 
                                [id]="question.responses[0].uniqueId" 
                                (blur)="questionCount(question.responses[0].uniqueId, $event.target.value)"></textarea>

                    <div *ngIf="innerForm.controls[question.responses[0].uniqueId].hasError('maxlength') && innerForm.controls[question.responses[0].uniqueId].touched" class="alert alert-danger">
                        Text questions have a max length of 4000 characters.
                    </div>

                </div><!--
                --><div class="col-sm-1 col-xs-2 vcenter">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- DATE -->
            <div *ngSwitchCase="question.questionType == 'date'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion" [class.condition-disabled]="conditionQuestion" [class.condition-hidden]="conditionQuestionHide">
                <div *ngIf="conditionQuestion">
                    <div class="hint -disabled">
                        <i class="fas fa-ban"></i>{{ conditionalQuestionWarning }}
                    </div>
                </div>
                <label class="col-sm-7 col-xs-12 vcenter control-label">
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label><!--

                --><div class="col-sm-4 col-xs-10 vcenter">
                    <input [formControlName]="question.responses[0].uniqueId" 
                            class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                            [id]="question.responses[0].uniqueId" 
                            type="text" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" 
                            [minDate]="question.rangeMin" 
                            [maxDate]="question.rangeMax" 
                            (bsValueChange)="questionCount(question.responses[0].uniqueId, $event); nacelValidate(question, $event)">

                    <div *ngIf="innerForm.controls[question.responses[0].uniqueId].hasError('dateMin') && innerForm.controls[question.responses[0].uniqueId].touched" class="alert alert-danger">
                        This question has a minimum date value of {{question.rangeMin}}, please enter a date after this time.
                    </div>

                    <div *ngIf="innerForm.controls[question.responses[0].uniqueId].hasError('dateMax') && innerForm.controls[question.responses[0].uniqueId].touched" class="alert alert-danger">
                        This question has a maximum date value of {{question.rangeMax}}, please enter a date before this time.
                    </div>

                </div><!--
                --><div class="col-sm-1 col-xs-2 vcenter">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- TIME -->
            <div *ngSwitchCase="question.questionType == 'TM'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion" [class.condition-disabled]="conditionQuestion" [class.condition-hidden]="conditionQuestionHide">
                <div *ngIf="conditionQuestion" >
                    <div class="hint -disabled">
                        <i class="fas fa-ban"></i>{{ conditionalQuestionWarning }}
                    </div>
                </div>
                <label class="col-sm-7 col-xs-12 vcenter control-label">
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label><!--
                --><div class="col-sm-4 col-xs-10 vcenter">
                    <input [formControlName]="question.responses[0].uniqueId" 
                            class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                            [id]="question.responses[0].uniqueId" 
                            type="time" 
                            (blur)="questionCount(question.responses[0].uniqueId, $event.target.value)"
                            (change)="questionCount(question.responses[0].uniqueId, $event.target.value); nacelValidate(question, $event.target.value)">

                    <div *ngIf="innerForm.controls[question.responses[0].uniqueId].hasError('pattern') && innerForm.controls[question.responses[0].uniqueId].touched" class="alert alert-danger">
                        The format for this question is HH:MM. The answer requires : e.g. 17:00.
                    </div>
                </div><!--
                --><div class="col-sm-1 col-xs-2 vcenter">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- TIME 2 -->
            <div *ngSwitchCase="question.questionType == 'TM2'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion" [class.condition-disabled]="conditionQuestion" [class.condition-hidden]="conditionQuestionHide">
                <div *ngIf="conditionQuestion" >
                    <div class="hint -disabled">
                        <i class="fas fa-ban"></i>{{ conditionalQuestionWarning }}
                    </div>
                </div>
                <label class="col-sm-4 col-xs-12 vcenter control-label">
                    <div *ngIf="question.questionHeading.length > 0 && question.showHeadings == 'Y'" class="column-heading -hidden">-</div>
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label><!--                    
                --><div class="col-sm-7 col-xs-10 vcenter">
                    <div class="row">
                        <div *ngFor="let q of question.responses; let i = index" class="col-sm-6">
                            <div *ngIf="question.questionHeading.length > 0 && question.showHeadings == 'Y'" class="column-heading">
                                <strong>{{question.questionHeading[i]?.headingText || '-'}}</strong>
                            </div>

                            <input [formControlName]="question.responses[i].uniqueId" 
                                    class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                    [id]="question.responses[i].uniqueId" 
                                    type="time" 
                                    (blur)="questionCount(question.responses[0].uniqueId, $event.target.value)"
                                    (change)="questionCount(question.responses[i].uniqueId, $event.target.value); nacelValidate(question, $event.target.value)">

                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('pattern') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                The format for this question is HH:MM. The answer requires : e.g. 17:00.
                            </div>
                        </div>
                    </div>
                </div><!--
                --><div class="col-sm-1 col-xs-2 vcenter text-center">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- DROPDOWN -->
            <div *ngSwitchCase="question.questionType == 'dropdown'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion" [class.condition-disabled]="conditionQuestion" [class.condition-hidden]="conditionQuestionHide">
                <div *ngIf="conditionQuestion">
                    <div class="hint -disabled">
                        <i class="fas fa-ban"></i>{{ conditionalQuestionWarning }}
                    </div>
                </div>
                <label class="col-sm-7 col-xs-12 vcenter control-label">
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label>

                <div class="col-sm-4 col-xs-10 vcenter">
                    <select [formControlName]="question.responses[0].uniqueId" 
                            [id]="question.responses[0].uniqueId" 
                            class="form-control  parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                            (blur)="questionCount(question.responses[0].uniqueId, $event.target.value)"
                            (change)="updateChildQuestion(question.responses[0].uniqueId, $event.target.value); parentCheck(question, $event.target.value)">
                        <option *ngIf="!innerForm.value[question.responses[0].uniqueId]" [ngValue]="null" selected disabled></option>
                        <option *ngFor="let opt of question.options" [ngValue]="opt.optionId">{{opt.optionName}}</option>
                    </select>

                    <!-- <div *ngIf="conditionQuestion" class="alert alert-info">
                        {{ conditionalQuestionWarning }}
                    </div> -->
                </div><!--
                --><div class="col-sm-1 col-xs-2 vcenter">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- RADIO -->
            <div *ngSwitchCase="question.questionType == 'radio'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion">
                <label class="col-sm-7 col-xs-12 vcenter control-label">
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label><!--

                --><div class="col-sm-4 col-xs-10 vcenter">
                    <ng-container *ngFor="let opt of question.options">
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" [formControlName]="question.responses[0].uniqueId" id="{{question.responses[0].uniqueId}}" type="radio" [value]="opt.optionId" (blur)="questionCount(question.responses[0].uniqueId, $event.target.value)">
                            <label class="custom-control-label" for="{{question.responses[0].uniqueId}}">{{opt.optionName}}</label>
                        </div>
                    </ng-container>
                </div><!--

                --><div class="col-sm-1 col-xs-2 vcenter">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                    <!-- <button type="button" *ngIf="question.questionHelp" class="btn btn-info" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</button> -->
                </div>
            </div>

            <!-- MULTI-DROPDOWN -->
            <div *ngSwitchCase="question.questionType == 'multidropdown'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion">
                <label class="col-sm-5 col-xs-12 vcenter control-label">
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label><!--
                    
                --><div class="col-sm-6 col-xs-10 vcenter">
                    <div class="row">
                        <div *ngFor="let q of question.responses; let i = index" class="{{ question.totalResponses == 2 ? 'col-xs-6' : 'col-xs-4' }}">
                            <div *ngIf="question.questionHeading.length > 0 && question.showHeadings == 'Y'" class="column-heading">
                                <strong>{{question.questionHeading[i]?.headingText || '-'}}</strong>
                            </div>
                        </div>
                    </div> 
                    <div class="row">
                        <div *ngFor="let q of question.responses; let i = index" class="{{ question.totalResponses == 2 ? 'col-xs-6' : 'col-xs-4' }}">
                            <select [formControlName]="question.responses[i].uniqueId" [id]="question.responses[i].uniqueId" class="form-control" (blur)="questionCount(question.responses[i].uniqueId)">
                                <option *ngIf="!innerForm.value[question.responses[i].uniqueId]" [ngValue]="null" selected disabled></option>
                                <option *ngFor="let opt of question.options" [ngValue]="opt.optionId">{{opt.optionName}}</option>
                            </select>
                        </div>
                    </div>
                </div><!--

                --><div class="col-sm-1 col-xs-2 vcenter">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- N1 -->
            <div *ngSwitchCase="question.questionType == 'N1'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion" [class.condition-disabled]="conditionQuestion" [class.condition-hidden]="conditionQuestionHide">
                <div *ngIf="conditionQuestion" >
                    <div class="hint -disabled">
                        <i class="fas fa-ban"></i>{{ conditionalQuestionWarning }}
                    </div>
                </div>
                <label class="col-sm-7 col-xs-12 vcenter control-label">
                    <div *ngIf="question.questionHeading.length > 0 && question.showHeadings == 'Y'" class="column-heading -hidden">-</div>
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                    <div class="import" *ngIf="question.responses[0].updatedBy == 0">
                        <i class="fas fa-info-circle"></i>This response has been populated from data provided in the Acute Transformation Dashboard.
                    </div>
                </label><!--
                --><div class="col-sm-4 col-xs-10 vcenter">
                    <div *ngIf="question.questionHeading.length > 0 && question.showHeadings == 'Y'" class="column-heading">
                        <strong>{{question.questionHeading[0]?.headingText || '-'}}</strong>
                    </div>

                    <div [class.input-group]="question.formatModifier == 'C' || question.formatModifier == 'P'">
                        <span *ngIf="question.formatModifier == 'C'" class="input-group-addon">£</span>
                        <input [formControlName]="question.responses[0].uniqueId" 
                                class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                [id]="question.responses[0].uniqueId" 
                                type="number" 
                                OnlyNumber='true'                                   
                                (blur)="numericPaste(question.responses[0].uniqueId, $event); questionCount(question.responses[0].uniqueId, $event.target.value); question.responses[0].updatedBy = null"
                                (keyup)="updateChildQuestion(question.responses[0].uniqueId, $event.target.value); parentCheck(question, $event.target.value)">
                        <span *ngIf="question.formatModifier == 'P'" class="input-group-addon">%</span>
                    </div>

                    <div *ngIf="innerForm.controls[question.responses[0].uniqueId].hasError('min') && innerForm.controls[question.responses[0].uniqueId].touched" class="alert alert-danger">
                        {{minValueText}} ({{question.rangeMin}})
                    </div>
                    <div *ngIf="innerForm.controls[question.responses[0].uniqueId].hasError('max') && innerForm.controls[question.responses[0].uniqueId].touched" class="alert alert-danger">
                        {{maxValueText}} ({{question.rangeMax}})
                    </div>
                    <div *ngIf="innerForm.controls[question.responses[0].uniqueId].hasError('pattern') && innerForm.controls[question.responses[0].uniqueId].touched" class="alert alert-danger">
                        {{decimalText}} ({{question.decimalPlaces == null ? 10 : question.decimalPlaces}})
                    </div>
                </div><!--
                --><div class="col-sm-1 col-xs-2 vcenter">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- N2 || N3 || N4 -->
            <div *ngSwitchCase="question.questionType == 'N2' || question.questionType == 'N3' || question.questionType == 'N4'" class="form-group question" [class.linked-question]="question.questionId == linkedQuestion" [class.condition-disabled]="conditionQuestion" [class.condition-hidden]="conditionQuestionHide">
                <div *ngIf="conditionQuestion" >
                    <div class="hint -disabled">
                        <i class="fas fa-ban"></i>{{ conditionalQuestionWarning }}
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-4 col-xs-12">
                        <div *ngIf="question.questionHeading.length > 0 && question.showHeadings == 'Y'" class="column-heading -hidden">-</div>                 
                    </div>
                    <div class="col-sm-7 col-xs-12">
                        <div class="row">
                            <div *ngFor="let q of question.responses; let i = index" class="{{ question.totalResponses == 2 ? 'col-xs-6' : question.totalResponses == 3 ? 'col-xs-4' : 'col-xs-3' }}">
                                <div *ngIf="question.questionHeading.length > 0 && question.showHeadings == 'Y'" class="column-heading">
                                    <strong>{{question.questionHeading[i]?.headingText || '-'}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <label class="col-sm-4 col-xs-12 vcenter control-label">
                    <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                    <span [innerHTML]="question.questionText | safe:'html'"></span>
                    <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                </label><!--                    
                --><div class="col-sm-7 col-xs-10 vcenter">
                    <div class="row">
                        
                        <div *ngFor="let q of question.responses; let i = index" class="{{ question.totalResponses == 2 ? 'col-xs-6' : question.totalResponses == 3 ? 'col-xs-4' : 'col-xs-3' }}"
                            [style.padding-left.px]="question.formatModifier == 'S' ? '0' : ''" [style.display]="question.formatModifier == 'S' ? 'flex' : '0'">
                            <span *ngIf="question.formatModifier == 'S' && i == 2" style="font-size: 21px;">|</span>
                            <div [class.input-group]="question.formatModifier == 'C' || question.formatModifier == 'P'" [style.padding-left.px]="question.formatModifier == 'S' ? '13' : ''">
                                <span *ngIf="question.formatModifier == 'C'" class="input-group-addon">£</span>
                                <input [formControlName]="question.responses[i].uniqueId"
                                    class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}"
                                    [id]="question.responses[i].uniqueId" 
                                    type="number" 
                                    OnlyNumber='true' 
                                    (blur)="numericPaste(question.responses[i].uniqueId, $event); questionCount(question.responses[0].uniqueId, $event.target.value)">
                                <span *ngIf="question.formatModifier == 'P'" class="input-group-addon">%</span>
                            </div>

                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('min') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                {{minValueText}} ({{question.rangeMin}})
                            </div>
                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('max') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                {{maxValueText}} ({{question.rangeMax}})
                            </div>
                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('pattern') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                {{decimalText}} ({{question.decimalPlaces == null ? 10 : question.decimalPlaces}})
                            </div>
                        </div>
                    </div>
                </div><!--
                --><div class="col-sm-1 col-xs-2 vcenter text-center">
                    <ng-template #popTemplate><div [innerHtml]="question.questionHelp"></div></ng-template>
                    <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                </div>
            </div>

            <!-- POPUP -->
            <div *ngIf="!conditionHidden">

                <div *ngSwitchCase="question.questionType == 'popup'" class="form-group question -popup popupId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" [class.linked-question]="question.questionId == linkedQuestion" [class]="popupHidden ? 'form-group question -popup disabled-popup popupId-' + question.conditionQuestions[0]?.conditionQuestionId || 0 : 'form-group question -popup popupId-' + question.conditionQuestions[0]?.conditionQuestionId || 0">

                    <div class="-popup-disabled">
                        <i class="far fa-ban"></i> This group has been disabled due to an answer given for a previous question.
                    </div>

                    <div class="col-sm-12">

                        <!-- Pop-up parent question -->
                        <div class="row">
                            <div class="col-sm-11 col-xs-10 vcenter">
                                <span [innerHTML]="question.questionPrefix | safe:'html'" *ngIf="question.questionPrefix" class="prefix"></span>
                                <span [innerHTML]="question.questionText | safe:'html'" class="popup-title"></span>
                                <i *ngIf="isAdmin" (click)="openEdit(question)" class="fas fa-pen-square admin-edit"></i>
                            </div><!--
                            --><div class="col-sm-1 col-xs-2 vcenter text-center">
                                <ng-template #popTemplate>
                                    <div [innerHtml]="question.questionHelp"></div>
                                </ng-template>
                                <i type="button" *ngIf="question.questionHelp" class="question-mark-help" [popover]="popTemplate" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                            </div>
                        </div>
                        
                        <!-- Pop-up headings -->
                        <div *ngIf="question.questionHeading.length > 0 && question.showHeadings == 'Y'" class="column-heading">
                            {{question.questionHeading[0]?.headingText || '-'}}
                        </div>

                        <div *ngIf="showPopup">
                            
                            <!-- Pop-up description text -->
                            <div class="row">
                                <div class="col-md-12" *ngIf="question.popupDescriptionText">
                                    <p class="description-text">{{question.popupDescriptionText}}</p>
                                </div>
                            </div>

                            <div class="row">
                                <ng-container *ngFor="let popup of question.popupQuestions; let i = index;">

                                    <div *ngIf="popup.questionType == 'N1' || popup.questionType == 'LS' || popup.questionType == 'YN' || popup.questionType == 'PL' || popup.questionType == 'TX' || popup.questionType == 'DT' || popup.questionType == 'TM'" [class.row-autosum]="popup.isAutosum == 'Y'" class="popup-question">

                                        <div class="form-group">
                                            <div class="col-sm-12">
                                                
                                                <div class="col-sm-5 vcenter">
                                                    <label [ngClass]="{ 'boldTitle': popup.isAutosum == 'Y' }" for="{{question.responses[i].uniqueId}}">
                                                        <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading -hidden">-</div>
                                                        {{popup.questionText}}
                                                        <i *ngIf="isAdmin" (click)="openEdit(popup)" class="fas fa-pen-square admin-edit"></i>
                                                        <div class="import" *ngIf="question.responses[i].updatedBy == 0">
                                                            <i class="fas fa-info-circle"></i>This response has been populated from data provided in the Acute Transformation Dashboard.
                                                        </div>
                                                    </label>
                                                </div><!--
                                                --><div [class]="popup.questionType == 'N1' ? 'col-sm-4 col-sm-offset-2 vcenter' : 'col-sm-6 vcenter'">
                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                        <strong>{{popup.headings[0]?.headingText || '-'}}</strong>
                                                    </div>

                                                    <ng-container *ngIf="popup.questionType == 'N1'">
                                                        <div [class.input-group]="popup.formatModifier == 'C' || popup.formatModifier == 'P'">
                                                            <span *ngIf="popup.formatModifier == 'C'" class="input-group-addon">£</span>
                                                            <input [formControlName]="question.responses[i].uniqueId" 
                                                                class="form-control {{question.questionId}} item-group-{{popup.itemGroup}} parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                [ngClass]="{ 'autosum': popup.isAutosum == 'Y' }" 
                                                                [id]="question.responses[i].uniqueId" 
                                                                type="number" 
                                                                (blur)="autoSum(question.responses[i].uniqueId, $event.target.value, popup.isAutosum, popup.itemGroup, popup.decimalPlaces || 0); questionCount(question.responses[i].uniqueId);question.responses[i].updatedBy = null" 
                                                                OnlyNumber='true'
                                                            >
                                                            <span *ngIf="popup.formatModifier == 'P'" class="input-group-addon">%</span>
                                                        </div>

                                                        <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('min') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                                            {{minValueText}} ({{popup.rangeMin}})
                                                        </div>
                                                        <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('max') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                                            {{maxValueText}} ({{popup.rangeMax}})
                                                        </div>
                                                        <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('pattern') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                                            {{decimalText}} ({{popup.decimalPlaces == null ? 10 : popup.decimalPlaces}})
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="popup.questionType == 'LS' || popup.questionType == 'YN' || popup.questionType == 'PL'">
                                                        <select [formControlName]="question.responses[i].uniqueId" 
                                                                [id]="question.responses[i].uniqueId" 
                                                                class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                (blur)="questionCount(question.responses[i].uniqueId)">
                                                            <option *ngIf="!innerForm.value[question.responses[i].uniqueId]" [ngValue]="null" selected disabled></option>
                                                            <option *ngFor="let opt of popup.options" [value]="opt.optionId">{{opt.optionName}}</option>
                                                        </select>
                                                    </ng-container>

                                                    <ng-container *ngIf="popup.questionType == 'TX'">
                                                        <input *ngIf="question.formatModifier != 'M' && question.formatModifier != 'L'" [formControlName]="question.responses[i].uniqueId" class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" [id]="question.responses[i].uniqueId" type="text" (blur)="questionCount(question.responses[i].uniqueId)">
                                                        <textarea *ngIf="question.formatModifier == 'M' || question.formatModifier == 'L'" [formControlName]="question.responses[i].uniqueId" [class.medium]="question.formatModifier == 'M'" [class.large]="question.formatModifier == 'L'" class="form-control" [id]="question.responses[i].uniqueId" (blur)="questionCount(question.responses[i].uniqueId)"></textarea>
                                                    </ng-container>

                                                    <ng-container *ngIf="popup.questionType == 'DT'">
                                                        <div>
                                                            <input *ngIf="popup.isAutosum != 'Y'"
                                                                [formControlName]="question.responses[i].uniqueId" 
                                                                class="form-control {{question.questionId}} item-group-{{popup.itemGroup}} parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}"
                                                                [id]="question.responses[i].uniqueId" 
                                                                type="text" bsDatepicker
                                                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">

                                                            <input *ngIf="popup.isAutosum == 'Y'"
                                                                readonly
                                                                [formControlName]="question.responses[i].uniqueId" 
                                                                class="form-control {{question.questionId}} item-group-{{popup.itemGroup}} parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}"
                                                                [ngClass]="{ 'dateTimeCalc': popup.isAutosum == 'Y' }"
                                                                [id]="question.responses[i].uniqueId" 
                                                                type="text">

                                                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('dateMin') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                                               This question has a minimum date value of <strong>{{ projectId === 34 ? '01/04/2019' : popup.rangeMin | date:'shortDate'}}</strong>, please enter a date after this time.
                                                            </div>

                                                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('dateMax') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                                                This question has a maximum date value of <strong>{{ projectId === 34 ? '31/05/2019' : popup.rangeMax | date:'shortDate'}}</strong>, please enter a date before this time.
                                                            </div>

                                                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('dateOutOfBounds1')" class="alert alert-danger">
                                                                {{ dateOutOfBounds1 }}
                                                            </div>

                                                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('dateOutOfBounds2')" class="alert alert-danger">
                                                                {{ dateOutOfBounds2 }}
                                                            </div>

                                                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('dateOutOfBounds3')" class="alert alert-danger">
                                                                {{ dateOutOfBounds3 }}
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="popup.questionType == 'TM'">
                                                        <div>
                                                            <input 
                                                                [formControlName]="question.responses[i].uniqueId" 
                                                                class="form-control {{question.questionId}} item-group-{{popup.itemGroup}} parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                [id]="question.responses[i].uniqueId" 
                                                                type="time" 
                                                                (blur)="questionCount(question.responses[i].uniqueId); nacelDateTime(question.responses[i].uniqueId, $event.target.value)"
                                                                (bsValueChange)="numericPaste(question.responses[i].uniqueId, $event)">
                                                            <div *ngIf="innerForm.controls[question.responses[i].uniqueId].hasError('pattern') && innerForm.controls[question.responses[i].uniqueId].touched" class="alert alert-danger">
                                                                The format for this question is HH:MM. The answer requires : e.g. 17:00.
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    
                                                </div><!--
                                                --><div class="col-sm-1 vcenter text-center">
                                                    <i type="button" *ngIf="popup.questionHelp" class="question-mark-help" popover="{{popup.questionHelp}}" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                                                </div>

                                                <div *ngIf="datesTimesErrors.length > 0">
                                                    <div class="datesTimesErrors">
                                                        <div *ngFor="let d of datesTimesErrors">{{ d }}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        
                                        <!-- <div class="subtotalDivider" *ngIf="popup.isAutosum == 'Y'"></div> -->
                                    </div>
                                    
                                    <div *ngIf="popup.questionType == 'YN2'" class="popup-question">
                                        <div class="form-group">
                                            <div class="col-sm-12">
                                                <div class="col-sm-4 vcenter">
                                                    <label>
                                                        {{popup.questionText}}
                                                        <i *ngIf="isAdmin" (click)="openEdit(popup)" class="fas fa-pen-square admin-edit"></i>
                                                    </label>
                                                </div><!--

                                                --><div class="col-sm-7 vcenter">

                                                    <div class="row">
                                                        <div *ngFor="let q of question.responses; let j = index">
                                                            <ng-container *ngIf="j % 2 === 0 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-6">
                                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                        <strong>{{popup.headings[0]?.headingText || '-'}}</strong>
                                                                    </div>

                                                                    <select [formControlName]="question.responses[j].uniqueId" 
                                                                            [id]="question.responses[j].uniqueId" 
                                                                            class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                            (blur)="questionCount(question.responses[i].uniqueId)">
                                                                        <option *ngIf="!innerForm.value[question.responses[j].uniqueId]" [ngValue]="null" selected disabled></option>
                                                                        <option *ngFor="let opt of popup.options" [value]="opt.optionId">{{opt.optionName}}</option>
                                                                    </select>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="j % 2 !== 0 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-6">
                                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                        <strong>{{popup.headings[1]?.headingText || '-'}}</strong>
                                                                    </div>
                                                                    
                                                                    <select [formControlName]="question.responses[j].uniqueId" 
                                                                            [id]="question.responses[j].uniqueId" 
                                                                            class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                            (blur)="questionCount(question.responses[i].uniqueId)">
                                                                        <option *ngIf="!innerForm.value[question.responses[j].uniqueId]" [ngValue]="null" selected disabled></option>
                                                                        <option *ngFor="let opt of popup.options" [value]="opt.optionId">{{opt.optionName}}</option>
                                                                    </select>
                                                                </div>
                                                            </ng-container>
                                                        </div>  
                                                    </div>
                                                </div><!--

                                                --><div class="col-sm-1 vcenter">
                                                    <!-- <button type="button" *ngIf="popup.questionHelp" class="btn btn-info" popover="{{popup.questionHelp}}" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</button> -->
                                                    <i type="button" *ngIf="popup.questionHelp" class="question-mark-help" popover="{{popup.questionHelp}}" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="popup.questionType == 'TM2'" class="popup-question">
                                        <div class="form-group">
                                            <div class="col-sm-12">
                                                <div class="col-sm-4 vcenter">
                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading -hidden">-</div>
                                                    <label>
                                                        {{popup.questionText}}
                                                        <i *ngIf="isAdmin" (click)="openEdit(popup)" class="fas fa-pen-square admin-edit"></i>
                                                    </label>
                                                </div><!--

                                                --><div class="col-sm-7 vcenter">

                                                    <div class="row">
                                                        <div *ngFor="let q of question.responses; let j = index">
                                                            <ng-container *ngIf="j % 2 === 0 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-6">
                                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                        <strong>{{popup.headings[0]?.headingText || '-'}}</strong>
                                                                    </div>
                                                                    
                                                                    <input 
                                                                        [formControlName]="question.responses[j].uniqueId" 
                                                                        class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                        [id]="question.responses[j].uniqueId" 
                                                                        type="time" 
                                                                        (blur)="questionCount(question.responses[i].uniqueId);"
                                                                        (bsValueChange)="numericPaste(question.responses[j].uniqueId, $event)">
                                                                        
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('pattern') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        The format for this question is HH:MM. The answer requires : e.g. 17:00.
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="j % 2 !== 0 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-6">
                                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                        <strong>{{popup.headings[1]?.headingText || '-'}}</strong>
                                                                    </div>

                                                                    <input 
                                                                        [formControlName]="question.responses[j].uniqueId" 
                                                                        class="form-control parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                        [id]="question.responses[j].uniqueId" 
                                                                        type="time" 
                                                                        (blur)="questionCount(question.responses[i].uniqueId);"
                                                                        (bsValueChange)="numericPaste(question.responses[j].uniqueId, $event)">
                                                                        
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('pattern') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        The format for this question is HH:MM. The answer requires : e.g. 17:00.
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div><!--

                                                --><div class="col-sm-1 vcenter">
                                                    <i type="button" *ngIf="popup.questionHelp" class="question-mark-help" popover="{{popup.questionHelp}}" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                                                    <!-- <button type="button" *ngIf="popup.questionHelp" class="btn btn-info" popover="{{popup.questionHelp}}" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="popup.questionType == 'N2'" class="popup-question">
                                        <div class="form-group">
                                            <div class="col-sm-12">
                                                <div class="col-sm-4 vcenter">
                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading -hidden">-</div>
                                                    <label [ngClass]="{ 'boldTitle': popup.isAutosum == 'Y' }">
                                                        {{popup.questionText}}
                                                        <i *ngIf="isAdmin" (click)="openEdit(popup)" class="fas fa-pen-square admin-edit"></i>
                                                    </label>
                                                </div><!--

                                                --><div class="col-sm-7 vcenter">

                                                    <div class="row">
                                                        <div *ngFor="let q of question.responses; let j = index">
                                                            <ng-container *ngIf="j % 2 === 0 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-6">

                                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                        {{popup.headings[0]?.headingText || '-'}}
                                                                    </div>

                                                                    <div [class.input-group]="popup.formatModifier == 'C' || popup.formatModifier == 'P'">
                                                                        <span *ngIf="popup.formatModifier == 'C'" class="input-group-addon">£</span>
                                                                        <input [formControlName]="question.responses[j].uniqueId" 
                                                                            class="form-control {{question.questionId}} item-group-1 parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                            [ngClass]="{ 'autosum': popup.isAutosum == 'Y' }" 
                                                                            [id]="question.responses[j].uniqueId" 
                                                                            type="number" 
                                                                            (blur)="autoSum(question.responses[j].uniqueId, $event.target.value, popup.isAutosum, 1); questionCount(question.responses[i].uniqueId);" 
                                                                            OnlyNumber='true'
                                                                        >
                                                                        <span *ngIf="popup.formatModifier == 'P'" class="input-group-addon">%</span>
                                                                    </div>

                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('min') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{minValueText}} ({{popup.rangeMin}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('max') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{maxValueText}} ({{popup.rangeMax}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('pattern') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{decimalText}} ({{popup.decimalPlaces == null ? 10 : popup.decimalPlaces}})
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="j % 2 !== 0 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-6">

                                                                    <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                        {{popup.headings[1]?.headingText || '-'}}
                                                                    </div>

                                                                    <div [class.input-group]="popup.formatModifier == 'C' || popup.formatModifier == 'P'">
                                                                        <span *ngIf="popup.formatModifier == 'C'" class="input-group-addon">£</span>
                                                                        <input [formControlName]="question.responses[j].uniqueId" 
                                                                            class="form-control {{question.questionId}} item-group-2 parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                            [ngClass]="{ 'autosum': popup.isAutosum == 'Y' }" 
                                                                            [id]="question.responses[j].uniqueId" 
                                                                            type="number" 
                                                                            (blur)="autoSum(question.responses[j].uniqueId, $event.target.value, popup.isAutosum, 2); questionCount(question.responses[i].uniqueId);" 
                                                                            OnlyNumber='true'
                                                                        >
                                                                        <span *ngIf="popup.formatModifier == 'P'" class="input-group-addon">%</span>
                                                                    </div>

                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('min') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{minValueText}} ({{popup.rangeMin}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('max') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{maxValueText}} ({{popup.rangeMax}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('pattern') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{decimalText}} ({{popup.decimalPlaces == null ? 10 : popup.decimalPlaces}})
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div><!--

                                                --><div class="col-sm-1 vcenter">
                                                    <i type="button" *ngIf="popup.questionHelp" class="question-mark-help" popover="{{popup.questionHelp}}" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                                                    <!-- <button type="button" *ngIf="popup.questionHelp" class="btn btn-info" popover="{{popup.questionHelp}}" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="popup.questionType == 'N3'" class="popup-question">
                                        <div class="form-group">
                                            <div class="col-sm-12">
                                                
                                                <!-- Header row -->
                                                <div class="row">
                                                    <div class="col-sm-7 col-sm-offset-4">
                                                        <div class="row">
                                                            <div *ngFor="let q of question.responses; let j = index">
                                                                <ng-container *ngIf="j % 3 === 0 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                    <div class="col-sm-4">
                                                                        <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                            <strong>{{popup.headings[j]?.headingText || '-'}}</strong>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="j % 3 === 1 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                    <div class="col-sm-4">
                                                                        <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                            <strong>{{popup.headings[j]?.headingText || '-'}}</strong>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="j % 3 === 2 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                    <div class="col-sm-4">
                                                                        <div *ngIf="popup.headings.length > 0 && popup.showHeadings == 'Y'" class="column-heading">
                                                                            <strong>{{popup.headings[j]?.headingText || '-'}}</strong>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-sm-4 vcenter">
                                                    <label [ngClass]="{ 'boldTitle': popup.isAutosum == 'Y' }">
                                                        {{popup.questionText}}
                                                        <i *ngIf="isAdmin" (click)="openEdit(popup)" class="fas fa-pen-square admin-edit"></i>
                                                    </label>
                                                </div><!--

                                                --><div class="col-sm-7 vcenter">
                                                    <div class="row">
                                                        <div *ngFor="let q of question.responses; let j = index">
                                                            <ng-container *ngIf="j % 3 === 0 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-4">

                                                                    <div [class.input-group]="popup.formatModifier == 'C' || popup.formatModifier == 'P'">
                                                                        <span *ngIf="popup.formatModifier == 'C'" class="input-group-addon">£</span>
                                                                        <input [formControlName]="question.responses[j].uniqueId" 
                                                                            class="form-control {{question.questionId}} item-group-1 parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                            [ngClass]="{ 'autosum': popup.isAutosum == 'Y' }" 
                                                                            [id]="question.responses[j].uniqueId" 
                                                                            type="number" 
                                                                            (blur)="autoSum(question.responses[j].uniqueId, $event.target.value, popup.isAutosum, 1); questionCount(question.responses[i].uniqueId);" 
                                                                            OnlyNumber='true'
                                                                        >
                                                                        <span *ngIf="popup.formatModifier == 'P'" class="input-group-addon">%</span>
                                                                    </div>

                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('min') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{minValueText}} ({{popup.rangeMin}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('max') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{maxValueText}} ({{popup.rangeMax}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('pattern') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{decimalText}} ({{popup.decimalPlaces == null ? 10 : popup.decimalPlaces}})
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="j % 3 === 1 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-4">

                                                                    <div [class.input-group]="popup.formatModifier == 'C' || popup.formatModifier == 'P'">
                                                                        <span *ngIf="popup.formatModifier == 'C'" class="input-group-addon">£</span>
                                                                        <input [formControlName]="question.responses[j].uniqueId" 
                                                                            class="form-control {{question.questionId}} item-group-1 parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                            [ngClass]="{ 'autosum': popup.isAutosum == 'Y' }" 
                                                                            [id]="question.responses[j].uniqueId" 
                                                                            type="number" 
                                                                            (blur)="autoSum(question.responses[j].uniqueId, $event.target.value, popup.isAutosum, 1); questionCount(question.responses[i].uniqueId);" 
                                                                            OnlyNumber='true'
                                                                        >
                                                                        <span *ngIf="popup.formatModifier == 'P'" class="input-group-addon">%</span>
                                                                    </div>

                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('min') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{minValueText}} ({{popup.rangeMin}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('max') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{maxValueText}} ({{popup.rangeMax}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('pattern') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{decimalText}} ({{popup.decimalPlaces == null ? 10 : popup.decimalPlaces}})
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="j % 3 === 2 && popup.displaySequence == question.responses[j].popupItemQuestionPart">
                                                                <div class="col-sm-4">

                                                                    <div [class.input-group]="popup.formatModifier == 'C' || popup.formatModifier == 'P'">
                                                                        <span *ngIf="popup.formatModifier == 'C'" class="input-group-addon">£</span>
                                                                        <input [formControlName]="question.responses[j].uniqueId" 
                                                                            class="form-control {{question.questionId}} item-group-1 parentId-{{question.conditionQuestions[0]?.conditionQuestionId || 0}}" 
                                                                            [ngClass]="{ 'autosum': popup.isAutosum == 'Y' }" 
                                                                            [id]="question.responses[j].uniqueId" 
                                                                            type="number" 
                                                                            (blur)="autoSum(question.responses[j].uniqueId, $event.target.value, popup.isAutosum, 1); questionCount(question.responses[i].uniqueId);" 
                                                                            OnlyNumber='true'
                                                                        >
                                                                        <span *ngIf="popup.formatModifier == 'P'" class="input-group-addon">%</span>
                                                                    </div>

                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('min') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{minValueText}} ({{popup.rangeMin}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('max') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{maxValueText}} ({{popup.rangeMax}})
                                                                    </div>
                                                                    <div *ngIf="innerForm.controls[question.responses[j].uniqueId].hasError('pattern') && innerForm.controls[question.responses[j].uniqueId].touched" class="alert alert-danger">
                                                                        {{decimalText}} ({{popup.decimalPlaces == null ? 10 : popup.decimalPlaces}})
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div><!--

                                                --><div class="col-sm-1 vcenter">
                                                    <i type="button" *ngIf="popup.questionHelp" class="question-mark-help" popover="{{popup.questionHelp}}" placement='left' triggers="click" container="body" tabindex="-1" [outsideClick]="true">?</i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- Show/Hide -->
            <div *ngSwitchCase="question.questionType == 'popup'" class="show-hide">
                <div (click)="showPopup = !showPopup">
                    <span>{{ showPopup ? 'hide' : 'show' }} group</span>
                    <i [class]="showPopup ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </div>
            </div>

        </div>
    </form>
</div>