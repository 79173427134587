import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-popup-banner',
    templateUrl: './popup-banner.component.html',
    styleUrls: ['./popup-banner.component.scss']
})
export class PopupBannerComponent implements OnInit {
    open: boolean = true;
    @Input() message: string = '';
    constructor() { }

    ngOnInit() { }

    closeBanner(): void {
        this.open = false
    }


}