import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})

export class BlockComponent implements OnChanges {
  
  @Input() metric;
  @Input() projectId;
  @Input() colorBlindnessEnabled;
  @Input() showSubmission: boolean;
  @Input() projectView: string;
  @Output() selectMetric: EventEmitter<any> = new EventEmitter();

  sparklineOptions;
  nationalMean = '#009639';
  submissionValue = '#DA291C';
  constructor() {}

  ngOnChanges() {
    if(this.colorBlindnessEnabled) {
      this.nationalMean = '#7CB5EC';
      this.submissionValue = '#D81B60';
    }
    this.createSparkline(this.metric.dateList, this.metric);
  }

  createSparkline(data, metric) {
    
    let benchmark, tooltipFooter;
    if (this.projectId == 29) {
      benchmark = +metric.benchmark
      tooltipFooter = '</table></div><div style="margin-top:0.2em;opacity:0.5">Grey line denotes benchmark</div>';
    } else {
      tooltipFooter = '</table></div>';
    }

    this.sparklineOptions = {
        title: { text: '' },
        tooltip: {
          shared: true,
          useHTML: true,
          backgroundColor: 'rgba(0,0,0,0.8)',
          borderWidth: 0,
          shadow: false,
          headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.key}</div><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0.25em 0;font-weight:bold">{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
          footerFormat: tooltipFooter,
          valueDecimals: 2,
          style: { color: '#FFFFFF' }
        },
        exporting: { enabled: false },
        chart: {
          height: 110,
          type: 'line',
          margin: [0, 0, 0, 0],
          animation: false,
          style: { 
            fontFamily: '"Helvetica Neue", Arial, sans-serif',
            overflow: 'visible'
          },
          backgroundColor: 'transparent',
        },
        legend: false,
        credits: false,
        yAxis: {
          title: {
            text: null
          },
          maxPadding: 0,
          minPadding: 0,
          gridLineWidth: 0,
          ticks: false,
          endOnTick: false,
          labels: {
            enabled: false,
          },
          gridLineColor: 'transparent',
          plotLines: [{
            color: '#768692',
            value: benchmark,
            width: '2',
            zIndex: 5
          }]
        },
        xAxis: {
          tickLength: 0,
          categories: data.map(d => d.dateName.substring(0, 3) + '-' + d.dateName.slice(-2)),
          crosshair: true,
          labels: {
            style: {
              color: '#000000',
              width: '300px'
            },
            autoRotation: 0,
            overflow: 'allow',
            formatter() {
              if(this.isFirst || this.isLast) {
                  return this.value
              } else {
                  return ''
              }
            }
          },
          lineColor: 'transparent',
          gridLineColor: 'transparent'
        },
        plotOptions: {
          series: {
            animation: false,
            enableMouseTracking: true,
            lineWidth: 2,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            marker: {
              radius: 2
            }
          }
        },
        series: [
          {
            data: data.map(d => d.cohortAverage),
            name: this.projectId == 52 ? 'Regional Mean' : 'National Mean',
            color: this.nationalMean,
            states: {
              hover: {
                color: '#F5A623'
              },
              inactive: {
                color: '#09DEED',
                opacity: 1
              },
              select: {
                borderColor: '#FFFFFF',
                color: '#DA291C'
              }
            }
          },
          {
            data: this.showSubmission ? data.map(d => d.submissionValue) : [],
            name: 'Submission value',
            color: this.submissionValue,
            states: {
              hover: {
                color: '#F5A623'
              },
              inactive: {
                color: '#09DEED',
                opacity: 1
              },
              select: {
                borderColor: '#FFFFFF',
                color: '#DA291C'
              }
            }
          },
          {
            data: data.map(d => d.nationalAverage),
            name: 'National average',
            color: '#768692',
            states: {
              hover: {
                color: '#F5A623'
              },
              inactive: {
                color: '#09DEED',
                opacity: 1
              },
              select: {
                borderColor: '#FFFFFF',
                color: '#DA291C'
              }
            }
          }
        ]
      }
  }

  // Utilities

  selectDashboardTier(tier) {
    this.selectMetric.emit(tier);
  }

}