import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { EventRegistration } from "../classes/event/event-registration";
import { UserRegistration } from "../classes/user/user-registration";
import { environment } from "environments/environment";
import { isConstructorDeclaration } from "typescript";

@Injectable()
export class MemberService {
  private URL: string = environment.apiURL;

  constructor(private http: HttpClient) {}

  public OrganisationList(): Observable<any> {
    return this.http.get(this.URL + "unauthenticated/organisationList");
  }

  public OrganisationListWithProducts(): Observable<any> {
    return this.http.get(this.URL + "unauthenticated/organisationProductList");
  }

  public Login(username, password): Observable<any> {
    return this.http
      .get(this.URL + "authentication", {
        headers: new HttpHeaders().append(
          "Authorization",
          "Basic " + btoa(username + ":" + password)
        ),
      })
      .pipe(tap((data) => localStorage.setItem("id_token", data.data.token)));
  }

  public RegenerateToken(): Observable<any> {
    let token = localStorage.getItem("id_token");
    return this.http
      .get(this.URL + "authentication/regenerateToken", {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(tap((data) => localStorage.setItem("id_token", data.data.token)));
  }

  public Events(): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "events/", {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public User(): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "users/details", {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public UserRolesDisabled(organisationList) {
    let token = localStorage.getItem("id_token");
    const body = { organisationList: organisationList };

    return this.http.post(
      this.URL + "users/disableOrgRoles", body, {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public UserRoles(userId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "users/" + userId, {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public UserViewRoles(
    userId: number,
    organisationId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "users/" + userId + "/roles", {
      headers: new HttpHeaders().set("Authorization", token),
      params: new HttpParams().set("organisationId", organisationId.toString()),
    });
  }

  public ContactForm(organisation: String, phone: String, subject: String, message: String, project: String): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(this.URL + "users/contactUs",
      {
        organisation: organisation,
        phoneNo: phone,
        subject: subject,
        message: message,
        project: project,
      },
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public UserUpdate(user?: Object): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.patch(this.URL + "users", user, {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public UserState(
    userId: number,
    organisationId: number,
    state: string
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "users/" + userId + "/setActive", {
      headers: new HttpHeaders().set("Authorization", token),
      params: new HttpParams()
        .set("organisationId", organisationId.toString())
        .set("isActive", state),
    });
  }

  public getNotifications(): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "notifications/getNotifications", {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public dismissNotification(notificationId: number, dismissed) {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "notifications/" + notificationId + "/setDismissed",
      JSON.stringify(dismissed),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public OrganisationsByUser(projectId?: number): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    if (projectId) params = params.set("projectId", projectId.toString());

    return this.http.get(this.URL + "users/organisations", {
      headers: new HttpHeaders().set("Authorization", token),
      params: params,
    });
  }

  public OrganisationsUserAdmin(organisationId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL + "organisations/" + organisationId + "/users",
      {
        headers: new HttpHeaders().set("Authorization", token),
      }
    );
  }

  public OrganisationProducts(organisationId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL + "organisations/" + organisationId + "/products",
      {
        headers: new HttpHeaders().set("Authorization", token),
      }
    );
  }

  public ProjectAccess(organisationId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL +
        "projects/categoryProjectAccessList?organisationId=" +
        organisationId,
      {
        headers: new HttpHeaders().set("Authorization", token),
      }
    );
  }

  // Organisation Participation Overview (3yrs)
  public ProjectParticipation(organisationId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL +
        "projects/projectParticipation?organisationId=" +
        organisationId,
      {
        headers: new HttpHeaders().set("Authorization", token),
      }
    );
  }

  public ProjectInformation(projectId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL + "projects/" + projectId + "/projectDetails",
      {
        headers: new HttpHeaders().set("Authorization", token),
      }
    );
  }

  public modifyProject(projectId: number, newProjectDetails): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.patch(
      this.URL + "projects/" + projectId + "/modifyProject",
      JSON.stringify(newProjectDetails),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public ProjectOverview(projectId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "projects/" + projectId + "/overview", {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public ProjectOverviewOrganisations(projectId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL + "projects/" + projectId + "/overviewOrganisations",
      {
        headers: new HttpHeaders().set("Authorization", token),
      }
    );
  }

  public ValidationConversationHistory(validationId: number): Observable<any> {
    let token = localStorage.getItem("id_token");
    return this.http.get(
      this.URL + "validation/getValidationConversationHistory", {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('validationId', validationId.toString()).set('resetAlert', 'Y')
      });
  }

  public ProjectOverviewSubmissions(
    projectId: number,
    organisationId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL + "projects/" + projectId + "/organisationOverview",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams().set(
          "organisationId",
          organisationId.toString()
        ),
      }
    );
  }

  // public OrganisationProjectOverview(projectId: number, organisationId: number): Observable<any> {
  //   let token = localStorage.getItem('id_token');
  //   let params: HttpParams = new HttpParams();

  //   return this.http
  //     .get(this.URL + 'projects/' + projectId + '/overview', {
  //       headers: new HttpHeaders().set('Authorization', token),
  //       params: new HttpParams().set('organisationId', organisationId.toString())
  //     });
  // }

  public QuestionGroups(
    projectId: number,
    year: number,
    groupLevel: string,
    submissionId?: number,
    serviceItemId?: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    params = params
      .set("questionYear", year.toString())
      .set("groupLevel", groupLevel);

    if (serviceItemId)
      params = params.append("serviceId", serviceItemId.toString());
    if (submissionId)
      params = params.append("submissionId", submissionId.toString());

    return this.http.get(
      this.URL + "projects/" + projectId + "/questionGroups",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: params,
      }
    );
  }

  public CNRCodes(submissionId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL + "submissions/" + submissionId + "/caseCodes",
      {
        headers: new HttpHeaders().set("Authorization", token),
      }
    );
  }

  public Questions(
    projectId: number,
    submissionId: number,
    groupId: number,
    serviceItemId?: number,
    caseNoteReviewCode?: string
  ): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    params = params
      .set("submissionId", submissionId.toString())
      .set("groupId", groupId.toString());

    if (serviceItemId)
      params = params.append("serviceId", serviceItemId.toString());

    if (caseNoteReviewCode)
      params = params.append("submissionCaseCode", caseNoteReviewCode);

    return this.http.get(
      this.URL + "projects/" + projectId + "/questionsInGroup",
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Cache-Control", "no-cache")
          .set("Pragma", "no-cache"),
        params: params,
      }
    );
  }

  public SaveQuestionData(
    questionData,
    submissionId: number,
    serviceItemId?: number,
    caseNoteReviewCode?: string
  ) {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    params = params.set("submissionId", submissionId.toString());

    if (serviceItemId)
      params = params.append("serviceId", serviceItemId.toString());

    if (caseNoteReviewCode)
      params = params.append("submissionCaseCode", caseNoteReviewCode);

    return this.http.post(
      this.URL + "projects/questions",
      JSON.stringify(questionData),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
        params: params,
      }
    );
  }

  public modifyQuestion(questionData): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "projects/modifyQuestion",
      JSON.stringify(questionData),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public childResponseCount(
    parentIds,
    submissionId: number,
    serviceItemId: number,
    caseCodeId?: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    params = params
      .set("parentIds", parentIds)
      .set("submissionId", submissionId.toString())
      .set("serviceItemId", serviceItemId.toString());
    if (caseCodeId) params = params.append("caseCodeId", caseCodeId.toString());

    return this.http.get(this.URL + "projects/childResponseCount", {
      headers: new HttpHeaders().set("Authorization", token),
      params: params,
    });
  }

  public purgeResponsesForParent(questionData): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "projects/purgeResponsesForParent",
      JSON.stringify(questionData),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public Tiers(projectId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "outputs/tiers", {
      headers: new HttpHeaders().set("Authorization", token),
      params: new HttpParams().set("projectId", projectId.toString()),
    });
  }

  public parentTiers(projectId: number, tierId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "outputs/tierParents", {
      headers: new HttpHeaders().set("Authorization", token),
      params: new HttpParams()
        .set("projectId", projectId.toString())
        .set("tierId", tierId.toString()),
    });
  }

  public getTierPath(projectId: number, tierId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "outputs/tierPath", {
      headers: new HttpHeaders().set("Authorization", token),
      params: new HttpParams()
        .set("projectId", projectId.toString())
        .set("tierId", tierId.toString()),
    });
  }

  public childTiers(projectId: number, parentId?: number): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    params = params.set("projectId", projectId.toString());

    if (parentId) params = params.append("parentId", parentId.toString());

    return this.http.get(this.URL + "outputs/childTiers", {
      headers: new HttpHeaders().set("Authorization", token),
      params: params,
    });
  }

  public getSearchTiers(projectId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "outputs/leafTiers", {
      headers: new HttpHeaders().set("Authorization", token),
      params: new HttpParams().set("projectId", projectId.toString()),
    });
  }

  public TierYears(tierId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "outputs/tiers/" + tierId + "/years", {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public TierData(
    chartId: number,
    organisationId: number,
    peerGroup: number,
    submissionId?: number,
    serviceItemId?: number,
    denominatorOptionId?: number,
    year?: number,
    submissionLevel?: string
  ): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    params = params
      .set("organisationId", organisationId.toString())
      .set("peerGroup", peerGroup.toString());

    if (submissionId)
      params = params.append("submissionId", submissionId.toString());

    if (serviceItemId)
      params = params.append("serviceItemId", serviceItemId.toString());

    if (denominatorOptionId)
      params = params.append(
        "denominatorOptionId",
        denominatorOptionId.toString()
      );

    if (year) params = params.append("year", year.toString());

    if (submissionLevel)
      params = params.append("submissionLevel", submissionLevel.toString());

    return this.http.get(this.URL + "outputs/" + chartId + "/data", {
      headers: new HttpHeaders().set("Authorization", token),
      params: params,
    });
  }

  public dataSharingCodes(projectId: number, year: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'projects/' + projectId.toString() + '/dataSharingCodes', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set("year", year.toString()),
      }
    );
  }

  public editReportDetails(reportId: number, details: any): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'outputs/' + reportId + '/reportDetails', JSON.stringify(details), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public editTierName(reportId: number, details: any): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.patch(
      this.URL + 'outputs/' + reportId + '/tierDetails', JSON.stringify(details), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public getPrimaryOptionNames(projectId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'projects/' + projectId + '/primaryOptionNames', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  } 

  public getSubmissionPeerGroups(projectId: number, year: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'projects/' + projectId + '/peerGroupSubmissions', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set("submissionYear", year.toString()),
      }
    );
  } 

  public SubmissionState(
    state: string,
    submissionId: number,
    organisationId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL + "submissions/" + submissionId + "/" + state,
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams().set(
          "organisationId",
          organisationId.toString()
        ),
      }
    );
  }

  public SubmissionDetails(
    submissionId: number,
    serviceItemId?: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    if (serviceItemId)
      params = params.append("serviceId", serviceItemId.toString());

    return this.http.get(
      this.URL + "submissions/" + submissionId + "/details",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: params,
      }
    );
  }

  public SubmissionCNRCode(submissionId: number): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    return this.http.get(
      this.URL + "submissions/" + submissionId + "/caseCodeToken",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: params,
      }
    );
  }

  public RegisterUser(userRegistration: UserRegistration) {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "users/registerUser",
      JSON.stringify(userRegistration),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public RegisterEvent(EventId: number, EventDetails: EventRegistration) {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "events/" + EventId + "/register",
      JSON.stringify(EventDetails),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public eventDocuments(eventId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "events/" + eventId + "/awsDocuments", {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public AWSDocuments(projectId: number, organisationId?: number): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    if (organisationId) params = params.set("organisationId", organisationId.toString());

    return this.http.get(this.URL + "projects/" + projectId + "/awsDocuments", {
      headers: new HttpHeaders().set("Authorization", token),
      params: params
    });
  }

  public ResourceDocuments(year: number, organisationId: number): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "awsDocuments/resources", {
      headers: new HttpHeaders().set("Authorization", token),
      params: new HttpParams().set("year", year.toString()).set("organisationId", organisationId.toString()),
    });
  }

  public ResetPassword(emailAddress: string) {
    return this.http.get(this.URL + "unauthenticated/getPasswordResetCode", {
      params: new HttpParams().set("logonName", emailAddress.toString()),
    });
  }

  public ResetPasswordConfirm(resetPassword) {
    return this.http.post(
      this.URL + "unauthenticated/resetPassword",
      JSON.stringify(resetPassword),
      {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      }
    );
  }

  public DismissWelcomeDialog(dismiss) {
    let token = localStorage.getItem("id_token");

    return this.http.patch(this.URL + "users", dismiss, {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public AWSSelectedDocument(documentId: number, selectedOrganisation?: number): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    if (selectedOrganisation) params = params.append("organisationId", selectedOrganisation.toString());

    return this.http.get(
      this.URL + "awsDocuments/" + documentId + "/download",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: params
      }
    );
  }

  public DynamicDocuments(projectId: number, year: string): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(
      this.URL + 'awsDocuments/dynamic', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams()
          .set('projectId', projectId.toString())
          .set('year', year)
      }
    );
  }

  public ProjectRegistrationBasicDetails(
    projectId: number,
    organisationId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "projectRegistration/projectDetails", {
      headers: new HttpHeaders()
        .set("Authorization", token)
        .set("Cache-Control", "no-cache")
        .set("Pragma", "no-cache"),
      params: new HttpParams()
        .set("organisationId", organisationId.toString())
        .set("projectId", projectId.toString()),
    });
  }

  public ProjectRegistration(
    productId: number,
    projectId: number,
    organisationId: number,
    registrationYear?: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    params = params
      .set("productId", productId.toString())
      .set("projectId", projectId.toString())
      .set("organisationId", organisationId.toString());

    if (registrationYear) {
      params = params.append("registrationYear", registrationYear.toString());
    }

    return this.http.get(this.URL + "projectRegistration/overview", {
      headers: new HttpHeaders()
        .set("Authorization", token)
        .set("Cache-Control", "no-cache")
        .set("Pragma", "no-cache"),
      params: params,
    });
  }

  public CreateRegistration(
    projectId: number,
    organisationId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    let body = {
      projectId: projectId,
      organisationId: organisationId,
    };

    return this.http.post(
      this.URL + "projectRegistration/createRegistration",
      JSON.stringify(body),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public AddProjectLead(
    registrationId: number,
    productId: number,
    projectId: number,
    organisationId: number,
    projectLeadDetails
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "projectRegistration/" + registrationId + "/addProjectLead",
      JSON.stringify(projectLeadDetails),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
        params: new HttpParams()
          .set("productId", productId.toString())
          .set("projectId", projectId.toString())
          .set("organisationId", organisationId.toString()),
      }
    );
  }

  public AddDeputyProjectLead(
    registrationId: number,
    deputyProjectLeadDetails
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL +
        "projectRegistration/" +
        registrationId +
        "/addDeputyProjectLead",
      JSON.stringify(deputyProjectLeadDetails),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public RemoveDeputyProjectLead(
    registrationId: number,
    userId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.delete(
      this.URL +
        "projectRegistration/" +
        registrationId +
        "/removeDeputyProjectLead",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams().set("userId", userId.toString()),
      }
    );
  }

  public SubmissionOrganisations(): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "projectRegistration/commissionerList", {
      headers: new HttpHeaders().set("Authorization", token),
    });
  }

  public AddOrganisationSubmissionGroup(
    registrationId: number,
    group
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL +
        "projectRegistration/" +
        registrationId +
        "/addOrganisationToGroup",
      group,
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public RemoveOrganisationSubmissionGroup(
    registrationId: number,
    organisationId: number,
    groupHeaderId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.delete(
      this.URL +
        "projectRegistration/" +
        registrationId +
        "/removeOrganisationFromGroup",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams()
          .set("organisationId", organisationId.toString())
          .set("groupHeaderId", groupHeaderId.toString()),
      }
    );
  }

  public AddSubmission(
    registrationId: number,
    submissionData
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "projectRegistration/" + registrationId + "/addSubmission",
      JSON.stringify(submissionData),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public EditSubmission(
    registrationId: number,
    submissionData
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.patch(
      this.URL + "projectRegistration/" + registrationId + "/changeSubmission",
      JSON.stringify(submissionData),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
        params: new HttpParams().set(
          "submissionId",
          submissionData.submissionId
        ),
      }
    );
  }

  public RemoveSubmission(
    registrationId: number,
    submissionId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.delete(
      this.URL + "projectRegistration/" + registrationId + "/removeSubmission",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams().set("submissionId", submissionId.toString()),
      }
    );
  }

  public AddSubmissionLead(
    registrationId: number,
    submissionId: number,
    submissionLeadData
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "projectRegistration/" + registrationId + "/addSubmissionLead",
      JSON.stringify(submissionLeadData),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
        params: new HttpParams().set("submissionId", submissionId.toString()),
      }
    );
  }

  public RemoveSubmissionLead(
    registrationId: number,
    submissionId: number,
    userId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.delete(
      this.URL +
        "projectRegistration/" +
        registrationId +
        "/removeSubmissionLead",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams()
          .set("submissionId", submissionId.toString())
          .set("userId", userId.toString()),
      }
    );
  }

  public AddService(
    registrationId: number,
    submissionId: number,
    serviceItem
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL +
        "projectRegistration/" +
        registrationId +
        "/addSubmissionService",
      serviceItem,
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams().set("submissionId", submissionId.toString()),
      }
    );
  }

  public RemoveService(
    registrationId: number,
    submissionId: number,
    serviceItemId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.delete(
      this.URL +
        "projectRegistration/" +
        registrationId +
        "/removeSubmissionService",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams()
          .set("submissionId", submissionId.toString())
          .set("serviceItemId", serviceItemId.toString()),
      }
    );
  }

  public AddServiceLead(
    registrationId: number,
    submissionId: number,
    serviceItemId: number,
    serviceLead
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "projectRegistration/" + registrationId + "/addServiceLead",
      serviceLead,
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams()
          .set("submissionId", submissionId.toString())
          .set("serviceItemId", serviceItemId.toString()),
      }
    );
  }

  public RemoveServiceLead(
    registrationId: number,
    submissionId: number,
    serviceItemId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.delete(
      this.URL + "projectRegistration/" + registrationId + "/removeServiceLead",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams()
          .set("submissionId", submissionId.toString())
          .set("serviceItemId", serviceItemId.toString()),
      }
    );
  }

  public AddRegistrationAddress(
    registrationId: number,
    addressDetails
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "projectRegistration/" + registrationId + "/addAddress",
      JSON.stringify(addressDetails),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public EditRegistrationAddress(
    registrationId: number,
    addressDetails
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.patch(
      this.URL + "projectRegistration/" + registrationId + "/modifyAddress",
      JSON.stringify(addressDetails),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public RemoveRegistrationAddress(
    registrationId: number,
    registrationAddressId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.delete(
      this.URL + "projectRegistration/" + registrationId + "/removeAddress",
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams().set(
          "registrationAddressId",
          registrationAddressId.toString()
        ),
      }
    );
  }

  public RegisterProject(
    registrationId: number,
    state: string
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "projectRegistration/" + registrationId + "/setLocked",
      null,
      {
        headers: new HttpHeaders().set("Authorization", token),
        params: new HttpParams().set("locked", state),
      }
    );
  }

  public IndividualAccess(registrationId: number, user): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL +
        "projectRegistration/" +
        registrationId +
        "/addProjectIndividualAccessUser ",
      user,
      {
        headers: new HttpHeaders().set("Authorization", token),
      }
    );
  }

  public MembershipRegistrationRequest(membershipData) {
    return this.http.post(
      this.URL + "membershipRequests",
      JSON.stringify(membershipData),
      {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      }
    );
  }

  public ProjectDashboard(
    projectId: number,
    submissionId: number,
    serviceItemId: number
  ): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.get(this.URL + "projects/" + projectId + "/dashboard", {
      headers: new HttpHeaders()
        .set("Authorization", token)
        .set("Content-Type", "application/json"),
      params: new HttpParams()
        .set("submissionId", submissionId.toString())
        .set("serviceItemId", serviceItemId.toString()),
    });
  }

  public DashboardMetrics(projectId: number, serviceItemId?: number, year?: number): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams();

    if (serviceItemId) params = params.set("serviceItemId", serviceItemId.toString());

    if (year) params = params.append("year", year.toString());

    return this.http.get(
      this.URL + "projects/" + projectId + "/dashboardMetrics",
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
        params: params,
      }
    );
  }

  public DashboardMetric(metricId: number, submissionId?: number): Observable<any> {
    let token = localStorage.getItem("id_token");
    let params: HttpParams = new HttpParams().set("metricId", metricId.toString());

    if (submissionId) params = params.append("submissionId", submissionId.toString())

    return this.http.get(this.URL + "projects/dashboardMetricValue", {
      headers: new HttpHeaders()
        .set("Authorization", token)
        .set("Content-Type", "application/json"),
      params: params
    });
  }

  public addCnrCodes(submissionId: number, newCodeCount): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "submissions/" + submissionId + "/addCnrCodes",
      JSON.stringify(newCodeCount),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public editCnrNotes(cnrNote): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "submissions/editCnrNotes",
      JSON.stringify(cnrNote),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public removeCnrCode(submissionId: number, caseCode): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "submissions/" + submissionId + "/removeCaseCode",
      JSON.stringify(caseCode),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public purgeCaseCode(caseCode): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "submissions/purgeCaseCode",
      JSON.stringify(caseCode),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public completeCnrCode(submissionId: number, caseCode): Observable<any> {
    let token = localStorage.getItem("id_token");

    return this.http.post(
      this.URL + "submissions/" + submissionId + "/setCaseCodeCompleted",
      JSON.stringify(caseCode),
      {
        headers: new HttpHeaders()
          .set("Authorization", token)
          .set("Content-Type", "application/json"),
      }
    );
  }

  public caseCodeCount(submissionId: number, prefix): Observable<any> {
    return this.http.get(
      this.URL + "unauthenticated/" + submissionId + "/caseCodeCount",
      {
        params: new HttpParams().set("prefix", prefix),
      }
    );
  }

  public LoginRequest(loginRequestData) {
    return this.http.post(
      this.URL + "unauthenticated/requestLogin",
      JSON.stringify(loginRequestData),
      {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      }
    );
  }

  // private handleError (error: Response | any) {
  //   let errMsg: string;
  //   if (error instanceof Response) {
  //     const body = error.json() || '';
  //     const err = body.error || JSON.stringify(body);
  //     errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  //   }
  //   else {
  //     errMsg = error.message ? error.message : error.toString();
  //   }

  //   console.error(errMsg);

  //   return observableThrowError(errMsg);
  // }
}
