<div *showIEVersion="['ie 7', 'ie 8', 'ie 9', 'ie 10']" class="support no-print">
  <h4>
    <i class="far fa-exclamation-triangle"></i> Your browser is not fully supported.
  </h4>
  <a href="/help">Click here for more information and links to alternative browsers.</a>
</div>

<div id="wrapper">
  <app-popup-banner [message]="bannerMessage"></app-popup-banner>
  <div *ngIf="auth.authenticated() && auth.confirmed()" class="no-print">
    <nav class="navbar navbar-default" data-ng-hide="contentHide">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" (click)="isCollapsed = !isCollapsed" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="active">
            <img alt="NHSBN Logo" src="../assets/images/nhsbn-mark-new.png" />
          </a>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" [collapse]="isCollapsed">
          <ul class="nav navbar-nav">
            <li>
              <a [routerLink]="['/home']" routerLinkActive="active">Home</a>
            </li>
            <li>
              <a [routerLink]="['/forum']" routerLinkActive="active">Forum</a>
            </li>
            <li>
              <a [routerLink]="['/events']" routerLinkActive="active">Events</a>
            </li>
            <li>
              <a [routerLink]="['/resources']" routerLinkActive="active">Resources</a>
            </li>
            <li>
              <a [routerLink]="['/contact']" routerLinkActive="active">Contact</a>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li>
              <a (click)="showNotifications = !showNotifications" [class]="showNotifications ? 'notifications-nav active' : 'notifications-nav'">
                Notifications
                <span *ngIf="unreadNotifications.length" class="badge notifications-badge">
                  {{ unreadNotifications.length }}
                </span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/help']" routerLinkActive="active">Help</a>
            </li>
            <li dropdown (onShown)="onShown()" (onHidden)="onHidden()" (isOpenChange)="isOpenChange()" class="dropdown">
              <a href id="basic-link" dropdownToggle (click)="(false)" aria-controls="basic-link-dropdown">
                <strong>{{ userName }}</strong><span class="caret"></span>
              </a>
              <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
                <li>
                  <a [routerLink]="['/profile']" class="dropdown-item" routerLinkActive="active">
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/membership']" class="dropdown-item" routerLinkActive="active">
                    <span>Membership History</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/user-administration']" class="dropdown-item" routerLinkActive="active">
                    <span>User Administration</span>
                  </a>
                </li>
                <hr />
                <li>
                  <a (click)="logout()" class="dropdown-item" routerLinkActive="active">
                    <strong>Log Out</strong>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-fluid -->
    </nav>
    <div class="colored-border"></div>
  </div>

  <!-- Notifications -->
  <div class="md-6 no-print" id="notifications" *ngIf="showNotifications && auth.confirmed()">
    <div *ngIf="notifications.length === 0" class="no-notifications">
      You don't have any notifications.
    </div>
    <div *ngIf="unreadNotifications.length" (click)="dismissAllNotifications()" class="notification-actions">
      <span>Mark all as read</span>
    </div>
    <div *ngFor="let n of notifications" [class]="n.dismissedDt ? 'notification -read' : 'notification'">
      <p (click)="navNotification(n.linkUrl)">
        <i *ngIf="n.iconName" [class]="n.iconName"></i>{{ n.typeTitle }}
      </p>
      <p>
        {{ n.typeBody }}
      </p>
      <span *ngIf="n.linkUrl" (click)="navNotification(n.linkUrl)">
        Go to {{ n.descriptor }}
      </span>
      <span (click)="dismissNotification(n.notificationId, n.dismissedDt)">
        Mark as {{ n.dismissedDt ? "unread" : "read" }}
      </span>
    </div>
  </div>

  <!-- Content -->
  <div id="content" (click)="showNotifications ? (showNotifications = false) : null">
    <router-outlet (activate)="getNotifications($event)"></router-outlet>

    <!-- Non-Sticky Footer (for IE)
        <div *ngIf="auth.authenticated()">
            <div *isIE id="ieFooter">
                <div class="container-fluid">
                    <p>
                        The Benchmarking Network is the in-house benchmarking service of the NHS. The Network is hosted by 
                        <a href="https://www.elft.nhs.uk/" target="_blank">East London Foundation Trust</a>.
                    </p>
                    <p>
                        &copy; East London Foundation Trust {{date | date:'yyyy'}}
                    </p>
                </div>
            </div>
        </div> -->
  </div>

  <!-- Sticky Footer (for Chrome, Firefox, Safari, Opera) -->
  <div *ngIf="auth.authenticated() && auth.confirmed()" class="no-print">
    <footer *hideItBrowser="['ie']" id="footer">
      <div class="container-fluid">
        <div class="col-md-4">
          <img alt="NHSBN Logo" src="../assets/images/nhsbn-logo-clear.png" />

          <p>
            The Benchmarking Network is the in-house benchmarking service of the
            NHS. The Network is hosted by
            <a href="https://www.elft.nhs.uk/" target="_blank">East London Foundation Trust</a>.
          </p>

          <p>&copy; East London Foundation Trust {{ date | date: "yyyy" }}</p>
        </div>
        <div class="col-md-4 col-md-offset-2" id="links">
          <h5>Quick Links</h5>
          <a href="https://twitter.com/NHSBenchmarking" target="_blank"><i class="fab fa-twitter"></i>Twitter</a>
          <a href="https://www.linkedin.com/groups/4853395/profile" target="_blank"><i class="fab fa-linkedin"></i>LinkedIn</a>
          <a [routerLink]="['/events']" routerLinkActive="active">Events</a>
          <a [routerLink]="['/help']" routerLinkActive="active">Help</a>
          <a [routerLink]="['/contact']" routerLinkActive="active">Contact</a>
          <a href="https://www.nhsbenchmarking.nhs.uk/terms-and-privacy" target="_blank">Terms & Privacy</a>
        </div>
      </div>
    </footer>
  </div>
</div>

<!-- Temp Event pop-up modal -->
<!-- <div class="popup" *ngIf="isPopupVisible && auth.authenticated() && auth.confirmed()">
  <div class="popup-content">
    <div class="popup-header">
      <img alt="NHSBN Logo" src="../assets/images/nhsbn-mark-new.png" />
      <h2>Upcoming Event!</h2>
      <span class="close-btn" title="Close" (click)="closePopup()"></span>
    </div>
    <p style="padding: 10px;">
      Our Annual General Meeting takes place on Wednesday 25th September, 15:00 - 16:00, we'd love for you to join us on
      MS Teams.<br />
      <a href="https://members.nhsbenchmarking.nhs.uk/events?event=417">Click here if you would like to register</a>.
    </p>
  </div>
</div> -->
<!-- end Wrapper -->

<!-- Temp pop-up modal -->
<!-- <div *ngIf="auth.authenticated() && auth.confirmed()" class="no-print">
  <div *ngIf="popupModalOpen" class="custom-modal">
    <h3>Annual Members Survey<i class="fas fa-lightbulb-exclamation"></i></h3>
    <hr /> <br />
    <p>Spare 5 minutes to enhance your membership experience!</p>
    <p>
      <a href="https://www.surveymonkey.co.uk/r/QQKYWPG" target="_blank" style="color: white; text-decoration: underline;">
        Complete our annual members survey now!
      </a>
    </p>
    <button class="btn btn-warning" (click)="closeModal()" style="float: right;">Close</button>
  </div>
</div> -->

