import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { MemberService } from '../utilities/services/member.service';
import { HtmlService } from '../utilities/services/html.service';
import { DataService } from '../utilities/services/data.service';

import { ProjectInformation } from '../utilities/classes/project/project-information';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-project',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  projectInformation: any;
  projectTimetableVisible: boolean = true;
  awsDocuments: Object;
  projectGuidancePD = null;

  public navIsFixed: boolean = false;
  public width: number;

  // Active Admin
  currentUserApproved: boolean = false;

  projectId;
  detailsForm: FormGroup;
  editingDetails: boolean = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50rem',
    minHeight: '25rem',
    translate: 'no'
  };

  metaTitle: string = 'Project Detail | NHS Benchmarking Network';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private memberService: MemberService,
    private htmlService: HtmlService,
    private titleService: Title,
    private meta: Meta,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle); // Set page title
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    let projectId = +this.route.snapshot.paramMap.get('projectId');

    this.getProjectInformation(projectId);
  }

  getProjectInformation(projectId: number) {
    this.memberService.ProjectInformation(projectId).subscribe(
      p => { 
        this.projectInformation = p.data;
        this.projectId = projectId;

        if (Object.keys(p.data.projectGuidance).length > 0) {
          if (p.data.projectGuidance.hasOwnProperty('project_detail')) {
            if (Object.keys(p.data.projectGuidance['project_detail']).length > 0) {
              this.projectGuidancePD = p.data.projectGuidance['project_detail'][0].guidanceText
            }
          }
        }

        let nullCount = 0;

        Object.keys(p.data.projectTimetable).forEach(element => {
          if (p.data.projectTimetable[element] == null && (element == 'openDate' || element == 'closeDate' || element == 'eventDate' || element == 'publicationDate')) {
            nullCount++;
          }
        })

        if (nullCount == 4) {
          this.projectTimetableVisible = false;
        }

        this.checkUser();
      }
    );
  }

  navHome() {
    this.router.navigate(['/home']);
  }

  resourceLink() {
    return `/resources?year=2024&sector=${this.projectInformation.projectCategory}`
  }

  gotoOutputs(projectId: number) {
    this.router.navigate(['/outputs', projectId]);
  }

  // Active Admin

  checkUser() {
    let currentUser = this.dataService.getUser();
    if (currentUser && currentUser.isAdmin && currentUser.isAdmin === 'Y') {
      this.currentUserApproved = true;
    }
  }

  editDetails() {
    this.buildDetailsForm();
    this.detailsForm.setValue({
      description: this.projectInformation.projectDescription
    });
    this.editingDetails = true;
  }

  buildDetailsForm() {
    this.detailsForm = this.fb.group({    
      description: ['', []]
    });
  }

  submitForm() {
    let newProjectDetails = {
      projectDescription: this.detailsForm.value.description
    };
    this.memberService.modifyProject(this.projectId, newProjectDetails).subscribe(
      r => {
        this.editingDetails = false;
        this.getProjectInformation(this.projectId);
        this.toastr.success("The project details have been successfully updated.");
      },
      e => {
        console.log(e);
        this.toastr.error("There has been an error whilst trying to edit the project details. Please discuss with IT Team.");
      }
    );
  }

}