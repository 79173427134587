import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Constants } from 'app/utilities/constants/constants';
import { MemberService } from 'app/utilities/services/member.service';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss']
})
export class EditReportComponent implements OnChanges {
  
  @Input() tierDetails;
  @Input() reportDetails;
  @Input() editReportModal;

  currentYear = new Date().getFullYear();
  currentYearShort: number = +this.currentYear.toString().slice(-2);

  editReportForm: FormGroup;

  placeholderHelp: boolean = false;
  placeholders = Constants.placeholders;

  errorMessage: string;
  copiedMessage: string;

  constructor(private memberService: MemberService,) {
    this.placeholders 
  }

  ngOnChanges() {
    if (this.tierDetails && this.reportDetails) {
      this.buildEditReportForm(this.tierDetails, this.reportDetails);
    }
  }

  buildEditReportForm(tier: any, report: any) {
    this.editReportForm = new FormGroup({
      reportName: new FormControl(report.reportName, [Validators.required, Validators.minLength(5)]),
      decPlaces: new FormControl(report.decPlaces),
      formatModifier: new FormControl(report.formatModifier),
      maxValue: new FormControl(report.maxValue),
      hideCodes: new FormControl(report.hideCodes)
    });
  }

  updateReport(editReportForm: any) {
    let reportId = this.tierDetails.reportId;
    let responses = editReportForm.value;

    if (responses) {
      this.memberService.editReportDetails(reportId, responses).subscribe(
        success => {
          // Hide modal
          this.editReportModal.hide();
          this.copiedMessage = null;
        },
        error => {
          console.log(error);
          this.errorMessage = 'There has been an error saving the report details. Please refresh the page and try again or contact the IT Team.'
        }
      )
    }
  }

  copyToClipboard(id: string) {
    var range = document.createRange();
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.copiedMessage = 'Copied to clipboard'
  }

}