import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { AuthService } from "../utilities/services/auth.service";
import { MemberService } from "../utilities/services/member.service";
import { DataService } from "../utilities/services/data.service";
import { LoaderService } from "../utilities/services/loader.service";

import { User } from "../utilities/classes/user/user";
import { Organisation } from "../utilities/classes/organisation/organisation";
import { Participation } from "../utilities/classes/project/project-participation";

@Component({
  templateUrl: "./membership.component.html",
  styleUrls: ["./membership.component.scss"],
})
export class MembershipComponent implements OnInit {
  user: User = {
    userId: 0,
    fullName: "",
    logonName: "",
    jobTitle: "",
    phoneNo: "",
    lastLogon: new Date(),
    roles: null,
    welcomeDialogDismissed: "Y",
    mailchimpOptIn: "",
    notificationOptIn: "",
  };
  organisation: Organisation[];
  currentOrganisation: number;
  participation: Participation;
  currentYear: number = new Date().getFullYear();
  yearParticipants: number;
  organisationsLength: number;
  productLeadName: string;
  productLeadEmail: string;
  projectYears: Array<number> = [];
  projectsList: Array<any> = [];

  metaTitle: string = 'Membership | NHS Benchmarking Network';

  constructor(
    public auth: AuthService,
    private memberService: MemberService,
    private dataService: DataService,
    public loaderService: LoaderService,
    private titleService: Title,
    private meta: Meta,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.getOrganisationsByUser();
  }

  getOrganisationsByUser() {
    let currentOrg = this.dataService.getSelectedOrganisation();

    this.memberService.OrganisationsByUser().subscribe((o) => {
      (this.organisation = o.data),
        (this.currentOrganisation = currentOrg
          ? currentOrg
          : this.organisation[0].organisationId);
      this.dataService.setOrganisation(o.data);
      this.getProjectParticipation(this.currentOrganisation);
      this.organisationsLength = o.data.length;
    });
  }

  onOrgChange(message: number) {
    this.dataService.setSelectedOrganisation(message);
    this.currentOrganisation = message;
    this.projectsList.splice(0, this.projectsList.length);
    this.projectYears.splice(0, this.projectYears.length);
    this.getProjectParticipation(this.currentOrganisation);
  }

  getProjectParticipation(currentOrganisation: number) {
    this.memberService
      .ProjectParticipation(currentOrganisation)
      .subscribe((p) => {
        this.participation = p.data;
        for (let years in this.participation) {
          if (years == "productLead") {
            break;
          }
          this.projectYears.push((this.currentYear = parseInt(years, 10)));
        }

        let rev: Array<number> = this.projectYears.reverse();

        for (var i = 0; i < rev.length; i++) {
          var projectsPerYear = {
            year: rev[i],
            yearParticipants: (this.yearParticipants = this.participation[
              rev[i]
            ].filter(function (x) {
              return x.hasParticipated == true;
            }).length),
            productLeadName: (this.productLeadName = this.participation[
              "productLead"
            ][0].productLeadName),
            productLeadEmail: (this.productLeadEmail = this.participation[
              "productLead"
            ][0].productLeadEmail),
          };
          this.projectsList.push(projectsPerYear);
        }
      });
  }

  navHome() {
    this.router.navigate(["/home"]);
  }
}
