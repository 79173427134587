import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { DataService } from '../utilities/services/data.service'
import { MemberService } from '../utilities/services/member.service';
import { HtmlService } from '../utilities/services/html.service';
import { LoaderService } from '../utilities/services/loader.service';
import { KenService } from '../utilities/services/ken.service';

import { ProjectInformation } from '../utilities/classes/project/project-information';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-project',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  projectInformation: ProjectInformation;
  projectTimetableVisible: boolean = true;
  awsDocuments: Object;

  projectId;
  organisationExists;
  currentYear: number;
  projectOverview;
  currentNatSub;
  offline: boolean = false;

  // Documents section toggles
  reportsInitial; presentationsInitial; studiesInitial; toolkitInitial; bespokesInitial;
  toggleState: number = 1;
  shortSection = true;
  longBespoke = false;
  longReports = false;
  longPresentations = false;
  longStudies = false;
  longToolkit = false;
  openDocumentModal: boolean = false;
  currentCalendarYear: number;

  // Tour
  tourState = false;
  tourStep = 1;
  loadCount = 0;

  // Errors
  dataError: boolean = false;
  organisationError: boolean = false;
  acuteError: boolean = false;
  accessError: boolean = false;
  permissionsError: boolean = false;

  availableYearSubmissions: any; submissionYear: number;
  availableOrganisations = []; availableSubmissions = []; availableServices = [];
  selectedOrganisation; selectedSubmission; selectedService: number;
  forumRequests = [];
  subscriptions = [];

  // Metrics
  metricValues = [];
  metricList;
  counter: number;
  toolkitOpened: boolean = false;
  loadFinished: boolean = false;
  combinedTotal: number;

  // ATD
  subProjectsList: { projectId: number, projectFa: string; projectName: string, lastUpdated: string; }[];
  isSubProject: boolean = false;
  isAtd: boolean = false;

  // Export Dashboard
  @ViewChild('dashboard') dashboard: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  selectedSubmissionName: string;
  selectedServiceName: string;

  metaTitle: string = 'Dashboard | NHS Benchmarking Network';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private dataService: DataService,
    private htmlService: HtmlService,
    private titleService: Title,
    private meta: Meta,
    public loaderService: LoaderService,
    public kenService: KenService,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.projectId = +this.route.snapshot.paramMap.get('projectId');
    this.currentNatSub = 'National';
    this.getProjectInformation(this.projectId);
    this.currentCalendarYear = new Date().getFullYear()
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  getProjectInformation(projectId: number) {
    this.memberService.ProjectInformation(projectId).subscribe(
      p => {
        this.projectInformation = p.data;

        // Online vs offline outputs
        if (p.data.outputsAvailable === 'Y' && p.data.outputsOnline !== 'N') {
          this.getOverview(this.projectId);
        } else {
          this.offline = true;
          this.getDashboardMetrics(this.projectId, null, this.currentYear);
        }

        // Set user's document access
        if (p.data.hasDocumentAccess) {
          this.selectedOrganisation = this.dataService.getSelectedOrganisation();
          this.getProjectRequests(this.projectId);
          this.getAWSDocuments(this.projectId, this.selectedOrganisation);
        } else {
          this.accessError = true;
        }

        // TODO: Needs a database definition or switch to static projectId, if necessary
        this.isAtd = this.projectInformation.projectName == 'Acute Transformation Dashboard';

        // ATD sub-projects
        this.subProjectsList = [
          { projectId: 38, projectFa: 'far fa-briefcase-medical', projectName: 'Acute Therapies', lastUpdated: 'December 2022' },
          { projectId: 6, projectFa: 'far fa-hospital-symbol', projectName: 'Emergency Care', lastUpdated: 'December 2022' },
          { projectId: 35, projectFa: 'far fa-bed-alt', projectName: 'Managing Frailty in the Acute Setting', lastUpdated: 'December 2022' },
          { projectId: 5, projectFa: 'far fa-user-md', projectName: 'Operating Theatres', lastUpdated: 'December 2022' },
          { projectId: 31, projectFa: 'far fa-clock', projectName: 'Outpatients', lastUpdated: 'December 2022' },
          { projectId: 4, projectFa: 'far fa-skeleton', projectName: 'Radiology', lastUpdated: 'March 2022' }
        ]

        // ATD sub project with metrics
        let subProject = this.subProjectsList.find(s => s.projectId == projectId);
        if (subProject && subProject.lastUpdated) {
          this.isSubProject =  true
        }

      },
      e => {
        console.log(e);
      }
    );
  }

  getProjectRequests(projectId: number) {
    this.kenService.getProjectRequests(projectId).subscribe(
      r => {
        let filteredFormRequests = r.data.filter(d => d.isVisible === 'Y');
        this.forumRequests = filteredFormRequests.slice(0,3);
      },
      e => {
        console.log(e);
      }
    )
  };

  getOverview(projectId: number) {
    this.memberService.ProjectOverviewOrganisations(projectId).subscribe(
      a => {
        if (a.data.viewOutputsOrganisationList.length > 0) {
          this.availableOrganisations = a.data.viewOutputsOrganisationList;
          this.getOrganisation();
        } 
        else {
          this.organisationError = true;
          this.getDashboardMetrics(this.projectId, this.selectedService, this.currentYear, null);
        }
      },
      e => {
        console.log(e);
      }
    )
  };

  getOrganisation() {
    let selectedOrganisation = this.dataService.getSelectedOrganisation();
    let organisationExists = this.availableOrganisations.map(o => o.organisationId).includes(selectedOrganisation);
    if (organisationExists) {
      this.selectedOrganisation = selectedOrganisation;
    }
    else {      
      this.selectedOrganisation = this.availableOrganisations[0].organisationId;
    }

    // Get documents
    this.getAWSDocuments(this.projectId, this.selectedOrganisation);

    // Get submissions
    this.getSubmissionsList(this.projectId, this.selectedOrganisation);
  }

  getSubmissionsList(projectId, organisationId) {
    this.memberService.ProjectOverviewSubmissions(projectId, organisationId).subscribe(
      success => {
        this.availableYearSubmissions = success.data.submissionData[0].years;
        this.getDashboardMetrics(projectId);
      },
      error => {
        console.log(error);
      }
    );
  }

  getDashboardMetrics(projectId, serviceItemId?, year?, submissionId?) {
    
    // Reset
    this.metricList = [];
    this.toolkitOpened = false;
    this.loadFinished = false;
    this.combinedTotal = null;
    
    this.memberService.DashboardMetrics(projectId, serviceItemId, year).subscribe(
      success => {

        // Set national metrics
        this.counter = 0;
        this.metricList = success.data.metricList;

        // Set selected year
        this.currentYear = success.data.selectedYear;

        // Get submissions list
        if (this.availableYearSubmissions) {
          let currentYearCheck = this.availableYearSubmissions.find(submission => submission.year == this.currentYear);
          this.availableSubmissions = currentYearCheck ? currentYearCheck.submissions : [];
        }

        // Set selected submission
        if(submissionId) {
          this.availableSubmissions.forEach(k => {
            if(k.submissionId == this.selectedSubmission) {
              this.selectedSubmissionName = k.submissionName
              this.selectedSubmission = k.submissionId
            }
          });
        } else {
            this.selectedSubmission = this.availableSubmissions.length ? this.availableSubmissions[0].submissionId : null;
            this.selectedSubmissionName = this.availableSubmissions.length ? this.availableSubmissions[0].submissionName : null;
        }


        if (this.metricList.length === 0) {
          this.dataError = true;
        } else {
          let metricIdList = this.metricList.map(m => m.metricId);
          metricIdList.forEach(m => {
            this.getMetricValue(m, submissionId);
          });
        }

        if (submissionId) {
          this.selectedSubmission = submissionId;
        } else if (this.selectedSubmission) {
          this.selectedSubmission = this.selectedSubmission;
        } else {
          this.selectedSubmission = this.availableSubmissions.length ? this.availableSubmissions[0].submissionId : null;
        }

        // Get services list
        if(this.currentNatSub === 'National') {
          this.availableServices = success.data.availableYearServices[this.currentYear];
        } else {
          this.setSelectedService();
        }

        // Set selected service
        if (serviceItemId) {
          this.selectedService = serviceItemId;
        } else {
          this.selectedService = success.data.selectedService;
        }

        if(this.availableServices) {
          this.availableServices.forEach(k => {
            if(k.serviceItemId == this.selectedService) {
              this.selectedServiceName = k.serviceItemName
            }
          });
        }
      },
      error => {
        console.log(error);
        this.permissionsError = true;
      }
    )
  }

  setSelectedService() {
    let submission = this.availableYearSubmissions[0].submissions.filter(k => k.submissionId == this.selectedSubmission)
    this.availableServices = submission[0].services;

    if(this.availableServices.length > 0) {
      this.selectedService = this.availableServices[0].serviceItemId;
    }
  }

  getMetricValue(metricId, submissionId?) {
    this.subscriptions.push(
      this.memberService.DashboardMetric(metricId, submissionId).subscribe(
        r => {
          let value = r.data.metric.metricValue,
              metric = this.metricList.filter(m => m.metricId === metricId)[0],
              prefix = metric.prefix,
              suffix = metric.suffix;

          if (value) {
            metric.value = value;
            metric.finalValue = this.formatValue(prefix, value, suffix);
          } else {
              if(metric.showZero == 'Y') {
                metric.finalValue = this.formatValue(prefix, value, suffix);
              } else {
                metric.value = 0;
                metric.finalValue = 'n/a';
              }
          }
        },
        e => {
          console.log(e);
        },
        () => {
          if (this.counter === this.metricList.length - 1) {
            this.loadFinished = true;
            this.combinedTotal = this.metricList.map(ml => ml.value).reduce((acc, cur) => acc + cur, 0);
          } else {
            this.counter = this.counter + 1;
          }
        }
      )
    )
  }

  formatValue(prefix, value, suffix) {
    let newValue, newSuffix;
    // Turn 10,000 to 10k
    if (value >= 1000 && value <= 1000000) { 
      newValue = value / 1000; 
      newSuffix = 'k'; 
    } 
    // Turn 1,000,000 to 1m
    else if (value >= 1000000) { 
      newValue = value / 1000000; 
      newSuffix = 'm'; 
    } 
    else { 
      newValue = value; 
      newSuffix = suffix;
    }
    // If over 100, limit to zero DP
    if ((prefix === '£' || suffix === '%') && value > 10) {
      newValue = newValue.toFixed(0);
    }
    else if (newValue > 100) {
      newValue = newValue.toFixed(0);
    }
    else if (typeof(value) === 'string' ) {
      newValue = value;
    }
    else {
      newValue = newValue.toFixed(1);
    }
    return prefix + newValue + newSuffix;
  }

  changeOrganisation(organisationId) {
    this.natSubToggle('National');
    this.dataService.setSelectedOrganisation(organisationId);
    this.selectedOrganisation = organisationId;
    this.dataError = false;
    this.organisationError = false;
    this.getSubmissionsList(this.projectId, organisationId);
    this.getAWSDocuments(this.projectId, this.selectedOrganisation);
  }

  changeSubmission(submissionId) {
    this.currentNatSub = 'Submission';
    this.selectedSubmission = submissionId;
    this.setSelectedService();
    this.getDashboardMetrics(this.projectId, this.selectedService, this.currentYear, submissionId);
  }

  changeService(serviceItemId) {
    if (this.currentNatSub == 'National') {
      this.getDashboardMetrics(this.projectId, serviceItemId, this.currentYear);
    } else {
      this.getDashboardMetrics(this.projectId, serviceItemId, this.currentYear, this.selectedSubmission);
    }
  }

  downloadDashboard(){
    let fileName: string;
    if(this.selectedServiceName) {
      fileName = `${this.selectedSubmissionName}(${this.selectedServiceName})`
    } else {
      fileName = this.selectedSubmissionName
    }

    html2canvas(this.dashboard.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = fileName;
      this.downloadLink.nativeElement.click();
    });
  }

  getAWSDocuments(projectId: number, organisationId?: number) {
    this.memberService.AWSDocuments(projectId, organisationId).subscribe(
      a => {
        this.awsDocuments = a.data;
        // TODO: API call to retrieve top-3 separately
        if (this.awsDocuments['Bespoke']) {
          let bespokesYear = this.htmlService.keys(this.awsDocuments['Bespoke']).reverse()[0];
          this.bespokesInitial = this.awsDocuments['Bespoke'][bespokesYear].slice(0,3);
        }
        if (this.awsDocuments['Report']) {
          let reportsYear = this.htmlService.keys(this.awsDocuments['Report']).reverse()[0];
          this.reportsInitial = this.awsDocuments['Report'][reportsYear].slice(0,3);
        }
        if (this.awsDocuments['Presentation']) {
          let presentationsYear = this.htmlService.keys(this.awsDocuments['Presentation']).reverse()[0];
          this.presentationsInitial = this.awsDocuments['Presentation'][presentationsYear].slice(0,3);
        }
        if (this.awsDocuments['CaseStudy']) {
          let studiesYear = this.htmlService.keys(this.awsDocuments['CaseStudy']).reverse()[0];
          this.studiesInitial = this.awsDocuments['CaseStudy'][studiesYear].slice(0,3);
        }
        if (this.awsDocuments['Toolkit']) {
          let toolkitYear = this.htmlService.keys(this.awsDocuments['Toolkit']).reverse()[0];
          this.toolkitInitial = this.awsDocuments['Toolkit'][toolkitYear].slice(0,3);
        }
        // Open bespoke report modal, is URL specifies
        this.route.queryParams.subscribe(params => {
          if (params.bespokes) {
            this.openDocumentModal = true;
          } else {
            this.router.navigate([], { queryParams: { bespokes: null } });
          }
        });
      },
      error => {
        console.log(error);
        this.accessError = true;
      }
    );
  }

  getSelectedDocument(documentId: number) {
    this.memberService.AWSSelectedDocument(documentId, this.selectedOrganisation).subscribe(
      a => { window.open(a.data.documentUrl) }
    );
  }

  toggleSection(section) {
    if (this.toggleState == 2) {
      this.shortSection = true;
      this.longBespoke = false;
      this.longReports = false;
      this.longPresentations = false;
      this.longStudies = false;
      this.longToolkit = false;
      this.toggleState = 1
    }
    else {
      this.shortSection = false;
      this.longBespoke = (section == 'bespokes' ? true : false)
      this.longReports = (section == 'reports' ? true : false)
      this.longPresentations = (section == 'presentations' ? true : false)
      this.longStudies = (section == 'studies' ? true : false)
      this.longToolkit = (section == 'toolkit' ? true : false)
      this.toggleState = 2;
    } 
  }

  toggleTour() {
    if (this.tourState) {
      this.tourState = false;
      this.tourStep = 1;
    }
    else {
      this.tourState = true;
    }
  }

  stepTour() {
    this.tourStep = this.tourStep + 1;
  }

  natSubToggle(message) {
    this.toolkitOpened = true;
    if (message === 'National') {
      this.getDashboardMetrics(this.projectId, this.selectedService, this.currentYear);
    } else {
      this.setSelectedService();
      this.getDashboardMetrics(this.projectId, this.selectedService, this.currentYear, this.selectedSubmission);
    }
    this.currentNatSub = message;
  }

  unsubscribe() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  navHome() {
    this.router.navigate(['/home']);
  }

  navProjectDetail(projectId: number) {
    this.router.navigate(['/project-detail', projectId]);
  }

  navOutputs(projectId: number) {
    this.router.navigate(['/outputs', projectId]);
  }

  navChart(projectId: number, tierId: number, optionId: number) {
    if (tierId) {
      this.router.navigate(['/outputs', projectId], { queryParams: { tier: tierId, option: optionId } });
    }
  }

  navForum(projectName) {
    this.router.navigate(['/forum'], { queryParams: { project: projectName, ref: 'dashboard' } });
  }

  navRequest(requestId) {
    this.router.navigate([]).then(result => {  window.open('/request/' + requestId, '_blank'); });
  }

}