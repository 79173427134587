<div *ngIf="!submissionSpecific.length && !yearError" class="loading-spinner loading-above loading-overview">
  <i class="fa fa-spinner fa-spin"></i>
  <p>The data collection page is loading. Please wait...</p>
</div>

<div *ngIf="yearError" class="loading-spinner loading-above loading-overview">
  <p>
    Data collection is not yet available for this project.<br>
    Click 'Back to Projects' to return to the home page.
  </p>
</div>

<!-- SELECTOR BAR-->
<div *ngIf="projectOverviewOrganisations" id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back" (click)="navHome()">
            <i class="fa fa-caret-left"></i> Back to Projects
          </span>
        </div>
        <div class="col col-md-2 col-sm-3 col-xs-4">
          <div class="form-group" *ngIf="!yearError">
            <select *ngIf="yearSpecific" class="form-control" id="year" name="year" [ngModel]="currentYear" (ngModelChange)="onYearChange($event)">
              <option *ngFor="let y of yearSpecific" [ngValue]="y.year">{{y.year}}</option>
            </select>
          </div>
        </div>
        <div class="col col-md-4 col-sm-5 col-xs-8">
          <div class="form-group">
            <select class="form-control" id="organisation" name="organisation" [ngModel]="currentOrganisation" (ngModelChange)="onOrgChange($event)">
              <option *ngFor="let o of projectOverviewOrganisations.organisationList" [ngValue]="o.organisationId" [class]="o.isDefunct == 'Y' ? 'defunct' : 'live'">{{o.organisationName}}</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-fluid" id="containerOverview" *ngIf="projectOverviewOrganisations && submissionSpecific.length">

  <!-- CONTENT -->
  <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-xs-12">

    <!-- Header -->
    <div class="row header">
      <div [class]="projectId === 36 ? 'col-sm-3 vcenter' : 'col-sm-2 vcenter'">
        <img alt="NHSBN {{projectOverviewOrganisations.projectCategoryName}} logo"
             src="../../assets/images/{{projectOverviewOrganisations.projectCategoryName.split(' ').join('')}}.png" />
      </div>
      <div [class]="projectId === 36 ? 'col-sm-9 vcenter' : 'col-sm-10 vcenter'">
        <h2>{{ currentOrganisationName }}</h2>
        <h1>{{ projectOverviewOrganisations.projectName }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="title-intro">Data Collection{{ projectId == 34 && currentYear === 2022 ? ': Round 4' : '' }}</h2>
        <p *ngIf="guidanceIntro" [innerHTML]="guidanceIntro | safe: 'html'"></p>
        <p *ngIf="!guidanceIntro && projectId !== 86">
          Welcome to the {{ projectOverviewOrganisations.projectName }} project data collection. Follow each of the steps on this page to complete the required information. You can switch organisation using the drop-down menu in the blue bar at the top of the page.
        </p>
        <p *ngIf="projectId == 86">
          There are four stages of data collection for NACEL Northern Ireland 2024. For more information about the timescales, audit process and copies of the data specifications (questions and answers) please refer to the <a  target="_blank" href="https://www.nacel.nhs.uk/audit-guidance-ni">NACEL NI Audit Guidance page.</a>
        </p>

        <div *ngIf="projectOverviewSubmissions">
          <p>
            If you need technical support or have any queries whilst completing the {{ productId === 34 ? 'audit' : 'data collection'}} process, please

            <span *ngIf="projectId === 34 || projectId === 57; else standard">
              <span *ngIf="projectId === 34">email <a href="mailto:nhsbn.nacelsupport@nhs.net">nhsbn.nacelsupport@nhs.net</a> or call 0161 521 0866</span>
              <span *ngIf="projectId === 57">email <a href="mailto:nhsbn.yots@nhs.net">nhsbn.yots@nhs.net</a></span>
            </span>

            <ng-template #standard>
              <span *ngIf="projectOverviewOrganisations.projectPhoneNo; else no_phone">
                call the {{ projectOverviewOrganisations.projectName }} Support Team on
                <strong>{{ projectOverviewOrganisations.projectPhoneNo }}</strong>
                <span *ngIf="projectCoordinator">
                  or email <strong><a href="mailto:{{ projectCoordinator.email }}">{{ projectCoordinator.name }}</a></strong>
                </span>
              </span>

              <ng-template #no_phone>
                <span *ngIf="projectManager?.email">
                  email <a href="mailto:{{ projectManager.email }}">{{ projectManager.name }}</a>
                </span>

                <span *ngIf="!projectManager?.email">
                  call 0161 266 1797
                </span>
              </ng-template>
            </ng-template>
          </p>


          <div *ngIf="introFiles.length">
            <p><strong>Project Files:</strong></p>
            <ul class="file-list">
              <li *ngFor="let file of introFiles">
                <a href="{{ file.link }}">
                  <i *ngIf="file.icon" class="{{ file.icon }}"></i>
                  <i *ngIf="!file.icon" class="fas fa-file"></i>
                  {{ file.label }}</a>
              </li>
            </ul>
          </div>

          <div *ngIf="projectLead">
            <p>
              <strong>Project <span *ngIf="!submissionLeadName == null">and Submission</span> Lead: </strong><a href="mailto:{{ projectLead.email }}">{{ projectLead.name }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- IE warning message -->
    <p *showIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" class="wrong-browser">
        <strong><i class="far fa-exclamation-triangle"></i> You are currently using an outdated version of Internet Explorer.</strong><br />
        You will not be able to complete data collection using this browser. Please download the latest version or see our <a [routerLink]="['/help']">help page</a> for more information on alternative browsers.
    </p>

    <!-- Time series / ATD projects -->
    <div class="dc-section" *ngIf="(atdProject && currentYear >= currentYearDate) && (projectId != 6 && projectId != 31) ">
      <h3 *ngIf="projectId != 48 && projectId != 6">Acute Transformation Dashboard (ATD) Data Collection</h3>
      <h3 *ngIf="projectId == 48">SDEC Transformation Dashboard Data Collection</h3>
      <div [innerHTML]="guidanceTimeSeries | safe: 'html'"></div>
    </div>

    <div class="dc-section -group" *ngIf="atdProject && currentYear >= currentYearDate">
      <h3>Core Annual Project</h3>
    </div>

    <!-- Add again when correct flag has been added to API -->
    <div class="dc-section" *ngIf="projectOverviewSubmissions.validationStartDate <= date && projectOverviewSubmissions.validationEndDate > date">
      <h3>Validation</h3>
        <p >Access your organisation's validation queries for the current year by clicking the button below.
        If you need any further assistance, please contact our support team. </p>
        <button type="button" class="btn btn-primary" [routerLink]="'/validation/' + projectId + '/' + currentYear">
          View Validation
        </button>
    </div>

    <!-- Data Collection Closed -->
    <div class="row" *ngIf="(currentYear < currentYearDate || projectReadOnly) && !projectOpen">
      <div class="col-md-12">
        <div class="alert alert-info">
          <strong>Data collection for the {{projectOverviewOrganisations.projectName}} {{ currentYear }} project is currently closed.</strong> You may continue to view the data your organisation has submitted previously, but access will be limited to "read-only".
        </div>
      </div>
    </div>

    <!-- Data Collection in Validation -->
    <div class="row" *ngIf="currentYear == currentYearDate && projectValidation">
      <div class="col-md-12">
        <div class="alert alert-info">
          <strong>Data collection for the {{projectOverviewOrganisations.projectName}} {{ currentYear }} project is currently in the validation period.</strong> You can continue to view, edit and add data during the validation period.
        </div>
      </div>
    </div>

    <!-- Data Collection Closed -->
    <div class="row" *ngIf="currentYear == currentYearDate && !projectOpen && !projectValidation && !projectReadOnly">
        <div class="col-md-8 col-md-offset-2">
          <div class="not-open-yet">
            <h3>Data Collection is not yet open</h3>
            <p>The data collection pages for {{projectOverviewOrganisations.projectName}} are not yet available. Please check the project calendar on the home page for more information.</p>
          </div>
        </div>
      </div>

    <!-- Overview sections -->
    <div>

      <!-- Monthly collections -->
      <div class="dc-section" *ngIf="guidanceMonthly">
        <h3>Monthly Data Collection</h3>
        <div [innerHTML]="guidanceMonthly | safe: 'html'"></div>
        <button type="button" class="btn btn-primary" [routerLink]="'/project/' + projectId + '/collection'">
          Monthly Data Collection
        </button>
      </div>

      <!-- General: Project Specification Document -->
      <div class="dc-section dc-spec" *ngIf="projectId !== 36 && projectOverviewSubmissions && projectOverviewSubmissions.specDocumentId">
        <h3>Project Specification</h3>
        <p *ngIf="projectOverviewSubmissions.supportText" [innerHTML]="projectOverviewSubmissions.supportText | safe: 'html'"></p>
        <p *ngIf="!projectOverviewSubmissions.supportText">You can download a copy of the audit questions for this project by clicking on the <strong>Download Specification</strong> button below. If you need any further assistance, please contact the Support Team on the email address above.</p>
        <button type="button" class="btn btn-default btn-default-outline" *ngIf="projectOverviewSubmissions.specDocumentId" (click)="getSelectedDocument(projectOverviewSubmissions.specDocumentId)">
          <i class="far fa-file-download"></i> Download Specification
        </button>
      </div>

      <!-- General: Project Specification Document -->
      <div class="dc-section dc-spec" *ngIf="projectId === 36 && currentYear >= currentYearDate">
        <h3>Project Specification</h3>
        <p>
          You may download a copy of the audit questions for the NHS England Learning Disability Improvement Standards project by clicking on the "Download Specification" button below. You can also download the full project guidance by clicking the "Download Guidance" button below.
        </p>
        <a type="button" class="btn btn-default" *ngIf="currentPeerGroup === 7" target="_blank"
          href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NHS%20England%20Learning%20Disability%20Improvement%20Standards/2023/NHSI-LD%20organisation%20survey%20Year%206%20Final.xlsx">
            Download Specification
        </a>
        <a type="button" class="btn btn-default" *ngIf="currentPeerGroup === 5" target="_blank"
          href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NHS%20England%20Learning%20Disability%20Improvement%20Standards/2023/NHSI-LD%20organisation%20survey%20Year%206%20Final.xlsx">
            Download Specification
        </a>
        <a type="button" class="btn btn-default" target="_blank"
          href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NHS%20England%20Learning%20Disability%20Improvement%20Standards/2023/Project%20Guidance.pdf">
            Download Guidance
        </a>
      </div>

      <!-- NACEL: Guidance
      <div class="dc-section dc-spec" *ngIf="projectOverviewOrganisations.productId == 5 && ((peerGroupId == 1 || peerGroupId == 2) || currentYear > 2018)">
        <h3 class="title">Guidance Notes</h3>
        <p>Click on the button below to download the full NACEL guidance notes. This document will lead you through the process of completing the audit.</p>
        <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NACEL/2019/nacel_19_guidance.pdf" target="_blank" class="btn btn-default">
          <i class="far fa-file-download"></i> Guidance Notes
        </a>
      </div>
      -->

      <!-- NACEL Trust/HB Overview 2018 & 2021 -->
      <div class="dc-section" *ngIf="projectOverviewOrganisations.productId == 5 && (currentYear < 2019 || (currentYear == 2021 && acSubmissions))">
        <h3 class="title">
          Trust / HB Overview
          <small class="break" *ngIf="currentYear >= 2020">Acute / Community</small>
        </h3>
        <p>Click the button below to submit data for the Trust / HB Organisational Overview. This only needs to be completed once for the entire Trust / HB relating to your organisation's acute / community hospital provision.</p>
        <button class="btn btn-primary" type="button" (click)="dataCollection('oac', organisationSubmission?.submissionId)">
          Trust / HB Collection - Acute / Community
        </button>
        <a type="button" class="btn btn-default" target="_blank" href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NACEL/2021/Trust-HB%20overview%202021%20(A-C)%20FINAL.xlsx">
          <i class="far fa-file-download"></i> Download Data Specification (A/C)
        </a>
      </div>

      <!--
        NACEL Trust/HB Overview (MH only) 2021
      -->
      <div class="dc-section" *ngIf="projectOverviewOrganisations.productId == 5 && currentYear == 2021 && mhSubmissions">
        <h3 class="title">
          Trust / HB Overview
          <small class="break" *ngIf="currentYear >= 2020">Mental Health</small>
        </h3>
        <p>Click the button below to submit data for the Trust / HB Organisational Overview. This only needs to be completed once for the entire Trust / HB relating to your organisation's mental health hospital provision.</p>
        <button class="btn btn-primary" type="button" (click)="dataCollection('omh', organisationSubmission?.submissionId)">
          Trust / HB Collection - Mental Health
        </button>
        <a type="button" class="btn btn-default" target="_blank" href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NACEL/2021/Trust.HB%20level%20audit%20-%202021%20MH%20FINAL.xlsx">
          <i class="far fa-file-download"></i> Download Data Specification (MH)
        </a>
      </div>

      <!-- General: Organisation Questions -->
      <div class="dc-section" *ngIf="organisationQuestions == 'Y'">
        <h3>
          <span *ngIf="guidanceOrganisationTitle" [innerHTML]="guidanceOrganisationTitle"></span>
          <span *ngIf="!guidanceOrganisationTitle">Organisation Questions</span>
          <br><small>for {{ currentOrganisationName }}</small>
        </h3>
        <p *ngIf="guidanceOrganisation" [innerHTML]="guidanceOrganisation | safe: 'html'"></p>
        <p *ngIf="!guidanceOrganisation">
          Access the organisation questions for {{projectOverviewOrganisations.projectName}}. These questions are asked once per organisation.
        </p>
        <button [disabled]="!organisationSubmission" *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" class="btn btn-primary" type="button" (click)="dataCollection('o', organisationSubmission?.submissionId)">
          <span *ngIf="guidanceOrganisationTitle" [innerHTML]="guidanceOrganisationTitle"></span>
          <span *ngIf="!guidanceOrganisationTitle">Organisation Questions</span>
        </button>
      </div>

      <!-- General: Organisation Specification -->
      <div class="dc-section dc-spec" *ngIf="projectOverviewOrganisations.organisationLevelSpecDocumentId">
        <h3 class="title">Organisation Specification</h3>
        <p>Click on the button below to download the Organisation Specification document.</p>
        <button class="btn btn-default" type="button" (click)="getSelectedDocument(projectOverviewOrganisations.organisationLevelSpecDocumentId)">
          Organisation Specification
        </button>
      </div>

      <!-- Select Submission -->
      <form *ngIf="submissionSpecific.length > 1" class="form-vertical select-submission">
        <div class="row">
          <div class="col-md-12">
            <h3 style="color:black;">Select a Submission</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-7 vcenter">
            The following sections of the data collection must be completed on a submission-by-submission basis. Please select a submission from the drop-down.
          </div><!--
          --><div class="col-sm-5 vcenter">
            <div class="form-group submission-select">
              <select *ngIf="submissionSpecific" class="form-control" id="submission" name="submission" [ngModel]="currentSubmission" (ngModelChange)="onSubmissionChange($event)">
                <option [ngValue]="0" disabled>Select submission...</option>
                <option *ngFor="let y of submissionSpecific" [ngValue]="y.submissionId">{{y.submissionName}}</option>
              </select>
            </div>
          </div>
        </div>
      </form>

      <!-- Submission Lead -->
      <div class="row" style="margin-top:1em" *ngIf="currentSubmission && submissionLeadName != null">
        <div class="col-md-12">
          <p>
            <strong>Submission Lead: </strong> <a href="mailto:{{ submissionLeadEmail }}">{{ submissionLeadName }}</a>
          </p>
        </div>
      </div>

      <!-- Data Collection Locked -->
      <div class="row" *ngIf="projectOpen && submissionLocked">
        <div class="col-md-12">
          <div class="alert alert-success">
            <strong>This submission is currently marked as completed and the data collection pages are 'read-only'.</strong> You can still edit the data up to the deadline of {{ projectOverviewSubmissions.currentYearCloseDate | date: 'd LLLL y' }} by clicking 'Edit Submission' below.
          </div>
        </div>
      </div>

      <!-- Registration Not Locked -->
      <div class="row" *ngIf="currentSubmission > 0 && !registrationLocked && currentYear >= currentYearDate">
        <div class="col-md-12">
          <div class="alert alert-danger alert-click" (click)="navRegistration(projectId, currentOrganisation)">
            <strong>Registration for this project has not been fully completed.</strong> Please click here to return to the registration form and complete it before continuing with data collection.
          </div>
        </div>
      </div>

      <div *ngIf="currentSubmission > 0 && (registrationLocked || currentYear < currentYearDate)">

        <!-- General: Qualification Questions -->
        <div class="dc-section" *ngIf="qualification">
          <h3>
            Qualification Questions
            <br><small>for {{ currentSubmissionName }}</small>
          </h3>
          <p *ngIf="!qualificationAnswered">
            You must complete the following questions before accessing the data collection for this submission. Once complete, the data collection page will become available on the Project Overview page, tailored to the answers you provide here.
          </p>
          <p *ngIf="qualificationAnswered">
            You have answered these questions and they are now marked as read-only. If you need to change your answers, please contact the support team.
          </p>
          <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" class="btn btn-primary" type="button" (click)="dataCollection('h')">
            Qualification Questions
          </button>
        </div>

        <!-- General: Project Questions -->
        <div class="dc-section" *ngIf="(projectQuestions == 'Y' && projectId !== 36) || (projectId === 36 && currentPeerGroup === 7)">
          <h3>
            <span *ngIf="guidanceProjectTitle" [innerHTML]="guidanceProjectTitle"></span>
            <span *ngIf="!guidanceProjectTitle">Project Questions</span>
            <br><small>for {{ currentSubmissionName }}</small>
          </h3>
          <p *ngIf="guidanceProject" [innerHTML]="guidanceProject | safe: 'html'"></p>
          <p *ngIf="!guidanceProject">
            Access the project questions for {{projectOverviewOrganisations.projectName}}. These questions are asked once per submission.
          </p>
          <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']"
            class="btn btn-primary"
            type="button"
            (click)="dataCollection('p')"
            [disabled]="qualification && !qualificationAnswered">
            <span *ngIf="guidanceProjectTitle" [innerHTML]="guidanceProjectTitle"></span>
            <span *ngIf="!guidanceProjectTitle">Project Questions</span>
          </button>
        </div>

        <!-- Select Service -->
        <div *ngIf="serviceSpecific?.length > 1">
          <form class="form-vertical select-submission">
            <div class="row">
              <div class="col-md-12">
                <h3 style="color:black;">Select a Service</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7 vcenter">
                The following sections of the data collection must be completed on a service-by-service basis. Please select a service from the drop-down.
              </div><!--
              --><div class="col-md-5 vcenter">
                <div class="form-group">
                  <select class="form-control" id="service" name="service" [ngModel]="currentService" (ngModelChange)="onServiceChange($event)">
                    <option *ngFor="let y of serviceSpecific" [ngValue]="y.serviceItemId">{{y.serviceItemName}}</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- General: Services Questions -->
        <div class="dc-section" *ngIf="serviceQuestions == 'Y' && serviceSpecific.length > 0">
          <h3>
            <span *ngIf="guidanceServiceTitle" [innerHTML]="guidanceServiceTitle"></span>
            <span *ngIf="!guidanceServiceTitle">Service Questions</span>
            <br><small>for {{ currentServiceName }}</small>
          </h3>
          <p *ngIf="guidanceService" [innerHTML]="guidanceService | safe: 'html'"></p>
          <p *ngIf="!guidanceService">
            Access the service questions for the submission / service shown above. These questions are asked once for each service included in the selected submission.
          </p>
          <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" class="btn btn-primary" type="button" (click)="dataCollection('s')">
            Service Questions
          </button>
        </div>

        <!--
          NACEL: Quality Survey Letter Generator
        -->
        <div class="dc-section" *ngIf="currentYear > 2018 && currentYear < 2023 && projectOverviewOrganisations.productId == 5">
          <h3>
            Quality Survey Letter Generator
            <small class="break" *ngIf="currentYear >= 2020 && (peerGroupId == 1 || peerGroupId == 2)">Acute / Community</small>
            <small class="break" *ngIf="currentYear >= 2020 && peerGroupId == 3">Mental Health</small>
          </h3>
          <!-- QS not applicable to NI -->
          <div class="row" *ngIf="(peerGroupId == 1 || peerGroupId == 2) && (region == 6)">
            <div class="col-md-12">
              <div class="alert alert-info">
                Northern Ireland have opted out of the Acute/Community Quality Survey for NACEL in round four.
              </div>
            </div>
          </div>
          <!-- QS applicable -->
          <div class="row" *ngIf="(region !== 6) || (peerGroupId !== 1 && peerGroupId !== 2)">
            <div class="col-md-7">
              <p>
                The Quality Survey Letter Generator should be used to assign a survey code to each nominated person and generate the letter. Each nominated person will be assigned a non-identifiable Quality Survey Code. Once the nominated person's details are logged within the document, a Quality Survey Letter can be generated.
              </p>
              <p>
                Click the button below to download the document (Microsoft Excel).
              </p>
              <button type="button" class="btn btn-primary" (click)="getExcelDocument(projectOverviewOrganisations.productId, region, peerGroupId)" [disabled]="submissionLocked">
                <i class="far fa-file-excel"></i> Download Letter Generator
              </button>
            </div>
            <div class="col-md-5" *ngIf="currentYear >= currentYearDate">
              <p>
                You will need to enter the following two codes into the Quality Survey Letter Generator to generate the required codes.
              </p>
              <input type="text" class="code-copy" *ngIf="currentOrganisation" value="{{ ('0000' + currentOrganisation).slice(-4) }}" disabled />
              <input type="text" class="code-copy" *ngIf="currentSubmission" value="{{ ('000000' + currentSubmission).slice(-6) }}" disabled />
              <p class="text-danger" style="margin-top:1em" *ngIf="filteredCaseNoteCodes">
                <strong>Validation Code:</strong> Enter the following validation code, once prompted: <strong class="code-copy-validation">{{ crcCheck }}</strong>
              </p>
            </div>
          </div>
        </div>

        <!--
          NHSI LD
        -->
        <div class="dc-section" *ngIf="(projectOverviewOrganisations.productId == 6 && peerGroupId == 5) || (projectId === 36 && currentPeerGroup === 5)">
          <h3>
            Project Questions
            <br><small>for {{ currentSubmissionName }}</small>
          </h3>
          <p>Click on the button below to complete the Specialist LD Provider Overview data collection.</p>
          <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" type="button" class="btn btn-primary" (click)="dataCollection(currentYear < 2020 ? 'l' : 'p')">
            Project Questions
          </button>
        </div>

        <!--
          NACEL Quality Survey 2024
        -->
        <div class="dc-section" *ngIf="projectId == 34 && currentYear >= 2024 || (projectId == 86 && currentYear >= 2024)">
          <h3>
            Quality Survey
            <small class="break" *ngIf="peerGroupId == 1 || peerGroupId == 2">Acute / Community</small>
            <small class="break" *ngIf="peerGroupId == 3">Mental Health</small>
          </h3>
          <div class="row">
            <div class="col-md-8 col-sm-7">
              <p>
                Please share the following survey link/QR code with the bereaved person(s) of all eligible patients who died within the hospital/site from {{ projectId == 34 ? '1st January to 31st December 2024.' : '1st July 2024 to 30th June 2025.' }}
              </p>
              <p>
                This collection should be completed for each {{ projectId == 34 ? 'NACEL submission.' : 'NACEL NI submission.' }} <strong>The survey link is unique to the submission named above.</strong>
              </p>
              <p class="surveyLink">
                {{ qualitySurveyLink }}
              </p>
              <p>
                Click on the link above to select it, then copy it (CTRL + C). The QR code for the survey can be downloaded. The link and QR code can then be pasted into a communication (e.g. email, letter, poster etc.) to share with the bereaved persons(s).
              </p>
              <p *ngIf="projectId == 34">
                Example promotional resources and the Quality Survey questionnaire is available to download from the NACEL <a href="https://www.nacel.nhs.uk/audit-guidance" target="_blank">Audit Guidance page</a>.
              </p>
              <p *ngIf="projectId == 86">
                Example promotional resources and the Quality Survey questionnaire is available to download from the NACEL NI <a href="https://www.nacel.nhs.uk/audit-guidance-ni" target="_blank">Audit Guidance page</a>.
              </p>
              <p class="text-primary">
                So far, <strong>{{ qualitySurveyCount }}</strong> Quality Surveys have been completed for your submission.
              </p>
            </div>
            <div class="col-md-4 col-sm-5">
              <div class="qr-code-container">
                <qrcode
                  [qrdata]="qualitySurveyLink"
                  [size]="155"
                  [level]="'H'"
                  class="qr-code">
                </qrcode>
                <button class="btn btn-primary" (click)="downloadQRCode(0)">Download QR Code</button>
              </div>
            </div>
          </div>
        </div>

        <!--
          NACEL: Case Note Review 2019+
        -->
        <div class="dc-section" *ngIf="currentYear > 2018 && projectOverviewOrganisations.productId == 5">
          <h3>
            Case Note Review
            <small class="break" *ngIf="currentYear >= 2020 && (peerGroupId == 1 || peerGroupId == 2)">Acute / Community</small>
            <small class="break" *ngIf="currentYear >= 2020 && peerGroupId == 3">Mental Health</small>
          </h3>
          <p>
            The Case Note Review will collect patient level data on the deaths within the audit criteria. The Case Note Review collection should be completed for each NACEL submission.
          </p>
          <p>
            Each submission should complete a Case Note Review for 20-70 consecutive deaths each quarter, submitting the data within the data collection pages.
          </p>
          <p>
            To complete the Case Note Review, each patient must be assigned a 'case code' to submit their data under; this is to ensure no patient identifiable data is submitted. Use the buttons below to add new case notes, as required, up to a maximum of 140 per data collection period (70 per quarter).
          </p>
          <p style="margin-bottom:1em">
            To access the Case Note Review questions for each case note, select "Open" to the right of the selected case code. You can add the initials of the reviewer for each case note. To mark a case note as "Complete", select the green tick to right of the status. <strong class="text-danger">Please do not include patient-confidential information.</strong>
          </p>
          <div *ngIf="!filteredCaseNoteCodes" class="caseCodesLoading">
            <i class="fa fa-spinner fa-spin"></i><br>
            Loading case codes. This may take a few seconds.
          </div>
          <div class="row cnr-filters" *ngIf="filteredCaseNoteCodes">
            <div class="col-md-4">
              <select *ngIf="filteredCaseNoteCodes" class="form-control" id="year" name="year" [ngModel]="cnrFilter" (ngModelChange)="onCnrFilterChange($event)">
                <option [ngValue]="null">Filter codes by status...</option>
                <option [ngValue]="'Not started'">Not started</option>
                <option [ngValue]="'In progress'">In progress</option>
                <option [ngValue]="'Completed'">Completed</option>
              </select>
            </div>
            <div class="col-md-3">
              <select *ngIf="filteredCaseNoteCodes" class="form-control" id="year-half" name="year-half" [ngModel]="filterValue" (ngModelChange)="filterCaseNoteCodes($event)">
                <option [ngValue]="casecodesFirstHalfFilter">Jan - Jun</option>
                <option [disabled]="firstHalfCaseCodes" [ngValue]="casecodesSecondHalfFilter">Jul - Dec</option>
              </select>
            </div>
          </div>
          <small>Viewing casecodes for period <b>{{ filterValue == casecodesFirstHalfFilter ? 'Jan - Jun' : 'Jul - Dec' }}</b></small>
          <div class="table-responsive" *ngIf="filteredCaseNoteCodes">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th style="width:15%">Status</th>
                  <th style="width:25%">Case Code</th>
                  <th style="width:30%">
                    Reviewer's Initials
                    <i class="fa fa-question-circle"
                      tooltip="Optional: Add the initials of the case note reviewer (max. 10 characters)"
                      placement="top"></i>
                  </th>
                  <th style="width:30%;text-align:right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let c of filteredCaseNoteCodes">
                  <td class="status">
                    <span class="label label-default" *ngIf="c.completionStatus === 'Not started'">
                      {{ c.completionStatus }}
                    </span>
                    <span class="label label-warning" *ngIf="c.completionStatus === 'In progress'">
                      {{ c.completionStatus }}
                    </span>
                    <span class="label label-success" *ngIf="c.completionStatus === 'Completed'">
                      {{ c.completionStatus }}
                    </span>
                  </td>
                  <td><strong>{{ c.caseCode.substring(5).slice(1,-5) }}</strong></td>
                  <td class="notes">
                    <div *ngIf="currentCaseCode != c.caseCode">
                      <!-- Note -->
                      <div *ngIf="c.caseCodeNotes" ng-class="showCodeNotesForm && currentCaseCode == c.caseCode ? 'hide' : ''">
                        <button class="btn btn-xs btn-default" type="button" (click)="buildCaseCodeNotesForm(c.caseCode)">Edit</button>
                        <span tooltip="{{ c.caseCodeNotes.length > 30 ? c.caseCodeNotes : '' }}">{{ c.caseCodeNotes | slice:0:30 }}{{ c.caseCodeNotes.length > 30 ? '...' : '' }}&nbsp;</span>
                      </div>
                      <!-- No note -->
                      <div *ngIf="!c.caseCodeNotes" ng-class="showCodeNotesForm && currentCaseCode == c.caseCode ? 'hide' : ''">
                        <button class="btn btn-xs btn-default" type="button" (click)="buildCaseCodeNotesForm(c.caseCode)">Add</button>
                      </div>
                    </div>
                    <!-- Add a note -->
                    <div *ngIf="showCodeNotesForm && currentCaseCode == c.caseCode">
                      <form [formGroup]="caseCodeNotesForm" class="notesForm" novalidate>
                        <div class="form-group">
                          <input type="textarea" class="form-control" formControlName="caseCodeNotes" maxlength="10">
                        </div>
                        <button type="button" class="btn btn-xs btn-success" (click)="caseCodeNotesFormSubmit()">Save</button>
                        <button type="button" class="btn btn-xs btn-default" (click)="showCodeNotesForm = false; currentCaseCode = null">Cancel</button>
                      </form>
                    </div>
                  </td>
                  <td class="text-right actions">
                    <button class="btn btn-xs btn-success btn-width"
                      *ngIf="c.responseCount > 0 && c.completionStatus != 'Completed'"
                      (click)="completeCaseCode(currentSubmission, c.caseCode, 'Y')"
                      tooltip="Change the status of this case code to complete"
                      placement="top"
                      [disabled]="submissionLocked || projectReadOnly">
                        <i class="fas fa-check"></i>
                    </button>
                    <button class="btn btn-xs btn-warning btn-width"
                      *ngIf="c.completionStatus == 'Completed'"
                      (click)="completeCaseCode(currentSubmission, c.caseCode, 'N')"
                      tooltip="Change the status of this case code to 'in progress'"
                      placement="top"
                      [disabled]="submissionLocked || projectReadOnly">
                        <i class="fal fa-clock"></i>
                    </button>
                    <button class="btn btn-xs btn-danger btn-width"
                      *ngIf="c.completionStatus != 'Completed'"
                      (click)="purgeCaseCode(c.caseCode)"
                      tooltip="Delete this case note"
                      placement="top"
                      [disabled]="submissionLocked || projectReadOnly || (!firstHalfCaseCodes && filterValue == casecodesFirstHalfFilter)">
                        <i class="fal fa-trash"></i>
                    </button>
                    <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']"
                      class="btn btn-xs btn-primary"
                      (click)="openCaseCode(c.caseCode)">
                        Open
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-muted no-case-codes" *ngIf="filteredCaseNoteCodes && caseNoteCodeLength < 1">
              You have not yet added any case codes.
            </div>
            <div class="text-muted no-case-codes" *ngIf="cnrFilter && filteredCaseNoteCodes.length == 0">
              There are no case codes matching this filter.
            </div>
            <div *ngIf="caseNoteCodeLength > 10 && cnrFilter == null" class="text-center">
              <pagination id="paginationCustom "
                [totalItems]="caseNoteCodeLength"
                [maxSize]="maxSize"
                (pageChanged)="pageChanged($event)"
                [boundaryLinks]="true"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;"
                [(ngModel)]="currentPage">
              </pagination>
              <p class="cnr-totals">{{ startItem + 1 || 1 }}-{{ endItem || 10 }} of {{caseNoteCodeLength}} case codes</p>
            </div>
          </div>
          <div *ngIf="!submissionLocked">
            <div *ngIf="(!firstHalfCaseCodes && filterValue == casecodesSecondHalfFilter) || firstHalfCaseCodes">
              <div class="cnr-actions">
                <button class="btn btn-primary"
                  type="button"
                  (click)="addCnrCodes(currentSubmission, 1)"
                  *ngIf="caseNoteCodeLength < 140"
                  [disabled]="submissionLocked || currentYear < currentYearDate || projectReadOnly">
                    Add Case Note
                </button>
                <button class="btn btn-default"
                  type="button"
                  (click)="addCnrCodes(currentSubmission, 10)"
                  *ngIf="caseNoteCodeLength < 130"
                  [disabled]="submissionLocked || currentYear < currentYearDate || projectReadOnly">
                    Add 10 Case Notes
                  </button>
              </div>
              <p style="margin-top:1em">
                <strong>Data collection timescales</strong>
              </p>
              <ul *ngIf="projectId !== 86">
                <li>First data collection will run from <strong>1st January 2024 to 30th June 2024</strong>, collecting data about patients who died during Q4 and Q1.</li>
                <li>Second data collection will run from <strong>1st July 2024 to 31st December 2024</strong>, collecting data about patients who died during Q2 and Q3.</li>
              </ul>
              <ul *ngIf="projectId == 86">
                <li>First data collection will run from <strong>1st July 2024 to 31st December 2024</strong>, collecting data about patients who died during Q2 2024/25 and Q3 2024/25.</li>
                <li>Second data collection will run from <strong>1st January 2025 to 30th June 2025</strong>, collecting data about patients who died during Q4 2024/25 and Q1 2025/2026.</li>
              </ul>
              <p *ngIf="projectId == 34">
                More information about the eligibility criteria, sample size and timescales can be found from the Audit Guidance document, available to download from the NACEL <a href="https://www.nacel.nhs.uk/audit-guidance" target="_blank">Audit Guidance page</a>. A Microsoft Excel and PDF version of the Case Note Review questionnaire is further available to download from the Audit Guidance page.
              </p>
              <p *ngIf="projectId == 86">
                More information about the eligibility criteria, sample size and timescales can be found from the Audit Guidance document, available to download from the NACEL NI <a href="https://www.nacel.nhs.uk/audit-guidance-ni" target="_blank">Audit Guidance page</a>. A Microsoft Excel and PDF version of the Case Note Review questionnaire is further available to download from the Audit Guidance page.
              </p>
            </div>
          </div>
          <div class="tracking" *ngIf="!submissionLocked">
            <h5>Tracking Case Codes</h5>
            <p>
              For your internal use only, we ask that you keep a log of the Case Note Review codes assigned to each patient. This will be useful for instances when the Case Note details need to be followed up.
            </p>
            <div class="angular2csv" *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10', 'ie 11']">
              <angular2csv [data]="exportCodes" filename="nacel_case_codes" [options]="csvOptions"></angular2csv>
            </div>
          </div>
        </div>

        <div class="dc-section" *ngIf="projectOverviewOrganisations.productId == 5 && (peerGroupId == 1 || peerGroupId == 2 || peerGroupId == 13) && currentYear >= 2024 && projectId !== 86"> <!-- Hidden from 2024 until mid-year -->
          <h3>
            Hospital / Site Overview
            <small class="break" *ngIf="currentYear !== 2019">Acute / Community</small>
          </h3>
          <p>
            Click on the button below to submit data for the hospital / site. The data collection will collect data on activity, workforce and quality improvement within the hospital / site. This collection should be completed for each NACEL submission.
          </p>
          <p *ngIf="projectId !== 86">
            Data collection for the Hospital/Site Overview will run from <strong>1st July 2024 to 30th September 2024</strong>. Download a copy of the Hospital/Site Overview data specification from the NACEL <a href="https://www.nacel.nhs.uk/audit-guidance" target="_blank">Audit Guidance page</a> page.
          </p>
          <p *ngIf="projectId == 86">
            Data collection for the Hospital/Site Overview will run from <strong>1st July 2024 to 30th September 2024</strong>. Download a copy of the Hospital/Site Overview data specification from the NACEL NI <a href="https://www.nacel.nhs.uk/audit-guidance-ni" target="_blank">Audit Guidance page</a> page.
          </p>
          <div class="section-actions">
            <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" class="btn btn-primary" type="button" (click)="dataCollection('a')">
              Hospital / Site Collection
            </button>
          </div>
        </div>
        
        <!--
          NHSI LD and NACEL Staff Survey
        -->
        <div class="dc-section" *ngIf="(projectId == 36 && currentYear >= 2023) || (projectId == 34 && currentYear >= 2024) || (projectId == 86 && currentYear >= 2024)">
          <h3>
            Staff Reported Measure
            <small class="break" *ngIf="currentYear >= 2020 && (peerGroupId == 1 || peerGroupId == 2)">Acute / Community</small>
            <small class="break" *ngIf="currentYear >= 2020 && peerGroupId == 3">Mental Health</small>
          </h3>
          <div class="row">
            <div class="col-md-8 col-sm-7">
              <p *ngIf="projectOverviewOrganisations.productId === 6 && projectId != 34">
                Please send the following link to 150 staff members who have seen patients with Learning Disabilities or Autism.
              </p>
              <p *ngIf="projectOverviewOrganisations.productId === 5 && projectId != 34">
                Please send the following survey link/QR code to your inpatient staff. This collection should be completed for each NACEL submission. The survey link is unique to the submission named above.
              </p>
              <p *ngIf="projectId != 34" class="surveyLink">
                {{ staffSurveyLink }}
              </p>
              <p *ngIf="projectOverviewOrganisations.productId === 6 && projectId != 34">
                Click on the link above to select it, then copy it (CTRL+C). You can then paste it into an email or company communication to share it with your staff. Alternatively, download the QR code to share the link on notice boards or posters.
              </p>
              <p *ngIf="projectOverviewOrganisations.productId === 5 && projectId != 34">
                Click on the link above to select it, then copy and paste it (CTRL + C). The QR code for the survey can be downloaded. You can then paste them into a communication (e.g. email, poster, notice board etc).
              </p>
              <p *ngIf="projectOverviewOrganisations.productId === 5 && projectId !== 86 && projectId != 34">
                Data collection for the Staff Reported Measure will run from <strong>1st April 2024 to 30th June 2024</strong>. An example promotional poster and the survey questionnaire is available to download from the NACEL <a href="https://www.nacel.nhs.uk/audit-guidance" target="_blank">Audit Guidance page</a>.
              </p>
              <p *ngIf="projectOverviewOrganisations.productId === 5 && projectId == 86 && projectId != 34">
                Data collection for the Staff Reported Measure will run from <strong>1st July 2024 to 30th September 2024</strong>. An example promotional poster and the survey questionnaire is available to download from the NACEL NI <a href="https://www.nacel.nhs.uk/audit-guidance-ni" target="_blank">Audit Guidance page</a>.
              </p>
              <p class="text-primary">
                So far, <strong>{{ surveyCount }}</strong> staff surveys have been completed for your submission.
              </p>
            </div>
            <div *ngIf="projectId != 34" class="col-md-4 col-sm-5">
              <div class="qr-code-container">
                <qrcode
                  [qrdata]="staffSurveyLink"
                  [size]="155"
                  [level]="'H'"
                  class="qr-code">
                </qrcode>
                <button class="btn btn-primary" (click)="downloadQRCode(1)">Download QR Code</button>
              </div>
            </div>
          </div>
          <div *ngIf="projectId === 34">
            <p>
              <strong> Data collection </strong> for the Staff Reported Measure <strong> is now closed </strong>. 
                <br>
              Data collection was open from 1st April 2024 to 30th June 2024
            </p>
          </div>  
        </div>

        <!--
          NACEL: Case Note Review and Data Reliability Study 2018
        -->
        <div class="dc-section" *ngIf="currentYear == 2018 && caseNoteReview == 'Y' && projectId === 34">
          <h3>Case Note Review</h3>
          <button class="btn btn-primary" type="button" (click)="dataCollection('c')">Case Note Review</button>
        </div>

        <!--
          NACEL: Data Reliability Study 2018
        -->
        <div class="dc-section" *ngIf="currentYear == 2018 && caseNoteReview == 'Y' && projectId === 34">
          <h3>Data Reliability Study</h3>
          <button class="btn btn-primary" type="button" (click)="dataCollection('d')">Data Reliability Study</button>
        </div>

        <!--
          NACEL: Hospital/Site
          In 2018 and 2021, show A/C and Mental Health separately.
          In 2019, only show A/C without title.
        -->

        <div class="dc-section" *ngIf="projectOverviewOrganisations.productId == 5 && peerGroupId == 3 && currentYear !== 2019 && currentYear < 2023">
          <h3>
            Hospital / Site Overview
            <small class="break">Mental Health</small>
          </h3>
          <p>
            Click on the button below to submit data for the hospital / site submission. This data collection will need to be completed once per submission.
          </p>
          <p>
            The hospital / site overview collects data on activity, workforce, quality and outcomes, and good practice within the hospital / site.
          </p>
          <div class="section-actions">
            <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" class="btn btn-primary" type="button" (click)="dataCollection('m')">
              Hospital / Site Collection
            </button>
            <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NACEL/2021/Hospital.Site%20overviewMH%202021%20FINAL.xlsx" class="btn btn-default">
              <i class="far fa-file-download"></i> Download Data Specification (MH)
            </a>
          </div>
        </div>

        <!--
          NACEL: Audit Summary
          2018: If caseNoteReview. 2019+: Always show
        -->
        <div class="dc-section" *ngIf="projectOverviewOrganisations.productId == 5 && ((currentYear == 2018 && caseNoteReview == 'Y') || (currentYear > 2018 && currentYear < 2023))">
          <h3>
            Audit Summary
            <small class="break" *ngIf="currentYear >= 2020 && (peerGroupId == 1 || peerGroupId == 2)">Acute / Community</small>
            <small class="break" *ngIf="currentYear >= 2020 && peerGroupId == 3">Mental Health</small>
          </h3>
          <p>
            The audit summary collects data on the total number of deaths within the audit period. This is to be completed once per submission.
          </p>
          <div class="section-actions">
            <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" class="btn btn-primary" type="button" (click)="dataCollection(peerGroupId == 3 ? 'umh' : 'uac')">
              Audit Summary Collection
            </button>
          </div>
        </div>

        <!--
          NHSE LDIS Patient Survey (NHS)
        -->
        <!-- <div class="dc-section" *ngIf="projectId == 36 && currentYear >= currentYearDate">
          <h3>Patient Survey</h3>
          <p>
            The patient survey aspect of this year's data collection includes a paper-based patient survey. Your Trust will receive a survey pack which will include 100 surveys, each with a freepost envelope. These will be sent to you by courier, the week commencing 31st October 2022.
          </p>
          <p>
            If you do not receive them by 4th November 2022, please contact <a href="mailto:a.worden@nhs.net">Alison Worden</a>. The patient surveys can be distributed to any patient with a Learning Disability or Autism over the age of 18 who accesses your service over the collection period. Trusts may also choose to post the surveys to patients who have accessed their services in the past 12 months, provided this complies with your Trust’s Information Governance guidance. <a href="https://www.nhsbenchmarking.nhs.uk/nhsild-resources" target="_blank">Information Governance guidance for this project can be found here</a>.
          </p>
        </div> -->

        <!--
          NHSE LDIS Patient Survey (Ind. Sec.)
        -->
        <div class="dc-section" *ngIf="projectId == 50 && currentYear >= currentYearDate">
          <h3>Patient Survey</h3>
          <p>
            The patient survey aspect of the data collection uses a paper-based survey. Your
            registered sites will each receive a survey pack which will include 50 surveys, each
            with a freepost return envelope. These will be sent to you by courier, in due course.
            You will be notified when they have been distributed.
          </p>
          <p>
            Please notify the support team at <a href="mailto:nhsbn.nhsildsupport@nhs.net">nhsbn.nhsildsupport@nhs.net</a> when you receive
            these, or if you have not received them by. The patient surveys can be distributed to
            any patient with a Learning Disability or Autism over the age of 18 who accesses
            your service over the collection period. Organisations may also choose to post the
            surveys to patients who have accessed their services in the past 12 months,
            provided this complies with your Organisation's Information Governance guidance.
          </p>
        </div>

        <!--
          Intermediate Care SUQ
          TODO: Merge with internalSurveys
        -->
        <div class="dc-section" *ngIf="projectId === 40 && currentService !== 46 && currentYear >= currentYearDate">
          <h3>Clinical Case Review</h3>
          <p>
            A Clinical Case Review (CCR) should be created and completed for every service user referred into this intermediate care service during the data collection period. Information about each service user is to be collected on admission to the service and on discharge. More information can be found in the <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Intermediate%20Care%20(Providers)/2024/Intermediate%20Care%20guidance%202024_Final.pdf" target="_blank">Intermediate Care guidance</a>.
          </p>
          <p style="margin-top:1em">
            To mark a CCR as "Complete", select the green tick to right of the status. To access the questions for each CCR, select "Open" to the right of the selected CCR code. You can add the initials of the clinician for each CCR.
          </p>
          <p>
            <strong class="text-danger">Please do not include patient-confidential information.</strong>
          </p>
          <div *ngIf="!filteredCaseNoteCodes" class="caseCodesLoading">
            <i class="fa fa-spinner fa-spin"></i><br>
            Loading CCR codes. This may take a few seconds.
          </div>
          <div class="table-responsive" *ngIf="filteredCaseNoteCodes">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th style="width:15%">Status</th>
                  <th style="width:25%">CCR Code</th>
                  <th style="width:30%">
                    Clinician's Initials
                    <i class="fa fa-question-circle"
                      tooltip="Optional: Add the initials of the clinician (max. 10 characters)"
                      placement="top"></i>
                  </th>
                  <th style="width:30%;text-align:right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let c of filteredCaseNoteCodes">
                  <td class="status">
                    <span class="label label-default" *ngIf="c.completionStatus === 'Not started'">
                      {{ c.completionStatus }}
                    </span>
                    <span class="label label-warning" *ngIf="c.completionStatus === 'In progress'">
                      {{ c.completionStatus }}
                    </span>
                    <span class="label label-success" *ngIf="c.completionStatus === 'Completed'">
                      {{ c.completionStatus }}
                    </span>
                  </td>
                  <td><strong>{{ c.caseCode.substring(5).slice(1,-5) }}</strong></td>
                  <td class="notes">
                    <div *ngIf="currentCaseCode != c.caseCode">
                      <!-- Note -->
                      <div *ngIf="c.caseCodeNotes" ng-class="showCodeNotesForm && currentCaseCode == c.caseCode ? 'hide' : ''">
                        <button class="btn btn-xs btn-default" type="button" (click)="buildCaseCodeNotesForm(c.caseCode)">Edit</button>
                        <span tooltip="{{ c.caseCodeNotes.length > 30 ? c.caseCodeNotes : '' }}">{{ c.caseCodeNotes | slice:0:30 }}{{ c.caseCodeNotes.length > 30 ? '...' : '' }}&nbsp;</span>
                      </div>
                      <!-- No note -->
                      <div *ngIf="!c.caseCodeNotes" ng-class="showCodeNotesForm && currentCaseCode == c.caseCode ? 'hide' : ''">
                        <button class="btn btn-xs btn-default" type="button" (click)="buildCaseCodeNotesForm(c.caseCode)">Add</button>
                      </div>
                    </div>
                    <!-- Add a note -->
                    <div *ngIf="showCodeNotesForm && currentCaseCode == c.caseCode">
                      <form [formGroup]="caseCodeNotesForm" class="notesForm" novalidate>
                        <div class="form-group">
                          <input type="textarea" class="form-control" formControlName="caseCodeNotes" maxlength="10">
                        </div>
                        <button type="button" class="btn btn-xs btn-success" (click)="caseCodeNotesFormSubmit()">Save</button>
                        <button type="button" class="btn btn-xs btn-default" (click)="showCodeNotesForm = false; currentCaseCode = null">Cancel</button>
                      </form>
                    </div>
                  </td>
                  <td class="text-right actions">
                    <button class="btn btn-xs btn-success btn-width"
                      *ngIf="c.responseCount > 0 && c.completionStatus != 'Completed'"
                      (click)="completeCaseCode(currentSubmission, c.caseCode, 'Y')"
                      tooltip="Change the status of this case code to complete"
                      placement="top"
                      [disabled]="submissionLocked || currentYear < currentYearDate || projectReadOnly">
                        <i class="fal fa-check"></i>
                    </button>
                    <button class="btn btn-xs btn-warning btn-width"
                      *ngIf="c.completionStatus == 'Completed'"
                      (click)="completeCaseCode(currentSubmission, c.caseCode, 'N')"
                      tooltip="Change the status of this case code to 'in progress'"
                      placement="top"
                      [disabled]="submissionLocked || currentYear < currentYearDate || projectReadOnly">
                        <i class="fal fa-clock"></i>
                    </button>
                    <button class="btn btn-xs btn-danger btn-width"
                      *ngIf="c.completionStatus != 'Completed'"
                      (click)="purgeCaseCode(c.caseCode)"
                      tooltip="Delete this case note"
                      placement="top"
                      [disabled]="submissionLocked || currentYear < currentYearDate || projectReadOnly">
                        <i class="fal fa-trash"></i>
                    </button>
                    <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']"
                      class="btn btn-xs btn-primary"
                      (click)="openCaseCode(c.caseCode, currentService)">
                        Open
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-muted no-case-codes" *ngIf="filteredCaseNoteCodes && caseNoteCodeLength < 1">
              You have not yet added any CCR codes.
            </div>
            <div *ngIf="caseNoteCodeLength > 10" class="text-center">
              <pagination id="paginationCustom "
                [totalItems]="caseNoteCodeLength"
                [maxSize]="maxSize"
                (pageChanged)="pageChanged($event)"
                [boundaryLinks]="true"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;"
                [(ngModel)]="currentPage">
              </pagination>
              <p class="cnr-totals">{{ startItem + 1 || 1 }}-{{ endItem || 10 }} of {{caseNoteCodeLength}} CCR codes</p>
            </div>
          </div>
          <div *ngIf="!submissionLocked && !projectReadOnly">
            <div class="cnr-actions">
              <button class="btn btn-primary"
                type="button"
                (click)="addCnrCodes(currentSubmission, 1)"
                *ngIf="caseNoteCodeLength < 200">
                  Add CCR Code
              </button>
              <button class="btn btn-default"
                type="button"
                (click)="addCnrCodes(currentSubmission, 10)"
                *ngIf="caseNoteCodeLength < 191">
                  Add 10 CCR Codes
              </button>
            </div>
            <p>
              Use the buttons above to add new CCRs, as required, up to a maximum of 200 for both bed based and IC services provided at home.
            </p>
          </div>
          <p *ngIf="currentServiceName == 'Bed Based Intermediate Care'"> Click <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Intermediate%20Care%20(Providers)/2024/Bed%20based%20CCR%20form_Final.pdf" target="_blank">here</a> to download a PDF of the CCR</p>
          <p *ngIf="currentServiceName == 'Intermediate Care Provided at Home'"> Click <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Intermediate%20Care%20(Providers)/2024/Services%20provided%20at%20home%20CCR%20form_Amended_Final_v2.pdf" target="_blank">here</a> to download a PDF of the CCR</p>
        </div>

        <!--
          Internal Surveys
        -->
        <div class="dc-section" *ngIf="(internalSurvey && currentYear >= currentYearDate) || projectId == 68">
          <h3>{{ internalSurvey.name }}</h3>
          <p [innerHTML]="internalSurvey.introductoryText"></p>
          <p style="margin-top:1em">
            To mark a {{ internalSurvey.shortName }} as "Complete", select the green tick to right of the status. To access the questions for each {{ internalSurvey.shortName }}, select "Open" to the right of the selected {{ internalSurvey.shortName }} code. You can add the initials of the clinician for each {{ internalSurvey.shortName }}.
          </p>
          <p>
            <strong class="text-danger">Please do not include patient-confidential information.</strong>
          </p>
          <div *ngIf="!filteredCaseNoteCodes" class="caseCodesLoading">
            <i class="fa fa-spinner fa-spin"></i><br>
            Loading {{ internalSurvey.shortName }} codes. This may take a few seconds.
          </div>
          <div class="table-responsive" *ngIf="filteredCaseNoteCodes">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th style="width:15%">Status</th>
                  <th style="width:25%">{{ internalSurvey.shortName }} Code</th>
                  <th style="width:30%">
                    Clinician's Initials
                    <i class="fa fa-question-circle"
                      tooltip="Optional: Add the initials of the clinician (max. 10 characters)"
                      placement="top"></i>
                  </th>
                  <th style="width:30%;text-align:right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let c of filteredCaseNoteCodes">
                  <td class="status">
                    <span class="label label-default" *ngIf="c.completionStatus === 'Not started'">
                      {{ c.completionStatus }}
                    </span>
                    <span class="label label-warning" *ngIf="c.completionStatus === 'In progress'">
                      {{ c.completionStatus }}
                    </span>
                    <span class="label label-success" *ngIf="c.completionStatus === 'Completed'">
                      {{ c.completionStatus }}
                    </span>
                  </td>
                  <td><strong>{{ c.caseCode.substring(5).slice(1,-5) }}</strong></td>
                  <td class="notes">
                    <div *ngIf="currentCaseCode != c.caseCode">
                      <!-- Note -->
                      <div *ngIf="c.caseCodeNotes" ng-class="showCodeNotesForm && currentCaseCode == c.caseCode ? 'hide' : ''">
                        <button class="btn btn-xs btn-default" type="button" (click)="buildCaseCodeNotesForm(c.caseCode)">Edit</button>
                        <span tooltip="{{ c.caseCodeNotes.length > 30 ? c.caseCodeNotes : '' }}">{{ c.caseCodeNotes | slice:0:30 }}{{ c.caseCodeNotes.length > 30 ? '...' : '' }}&nbsp;</span>
                      </div>
                      <!-- No note -->
                      <div *ngIf="!c.caseCodeNotes" ng-class="showCodeNotesForm && currentCaseCode == c.caseCode ? 'hide' : ''">
                        <button class="btn btn-xs btn-default" type="button" (click)="buildCaseCodeNotesForm(c.caseCode)">Add</button>
                      </div>
                    </div>
                    <!-- Add a note -->
                    <div *ngIf="showCodeNotesForm && currentCaseCode == c.caseCode">
                      <form [formGroup]="caseCodeNotesForm" class="notesForm" novalidate>
                        <div class="form-group">
                          <input type="textarea" class="form-control" formControlName="caseCodeNotes" maxlength="10">
                        </div>
                        <button type="button" class="btn btn-xs btn-success" (click)="caseCodeNotesFormSubmit()">Save</button>
                        <button type="button" class="btn btn-xs btn-default" (click)="showCodeNotesForm = false; currentCaseCode = null">Cancel</button>
                      </form>
                    </div>
                  </td>
                  <td class="text-right actions">
                    <button class="btn btn-xs btn-success btn-width"
                      *ngIf="c.responseCount > 0 && c.completionStatus != 'Completed'"
                      (click)="completeCaseCode(currentSubmission, c.caseCode, 'Y')"
                      tooltip="Change the status of this case code to complete"
                      placement="top"
                      [disabled]="(submissionLocked || currentYear < currentYearDate || projectReadOnly) && !projectOpen">
                        <i class="fal fa-check"></i>
                    </button>
                    <button class="btn btn-xs btn-warning btn-width"
                      *ngIf="c.completionStatus == 'Completed'"
                      (click)="completeCaseCode(currentSubmission, c.caseCode, 'N')"
                      tooltip="Change the status of this case code to 'in progress'"
                      placement="top"
                      [disabled]="(submissionLocked || currentYear < currentYearDate || projectReadOnly) && !projectOpen">
                        <i class="fal fa-clock"></i>
                    </button>
                    <button class="btn btn-xs btn-danger btn-width"
                      *ngIf="c.completionStatus != 'Completed'"
                      (click)="purgeCaseCode(c.caseCode)"
                      tooltip="Delete this case note"
                      placement="top"
                      [disabled]="(submissionLocked || currentYear < currentYearDate || projectReadOnly) && !projectOpen">
                        <i class="fal fa-trash"></i>
                    </button>
                    <button *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']"
                      class="btn btn-xs btn-primary"
                      (click)="openCaseCode(c.caseCode, currentService)">
                        Open
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-muted no-case-codes" *ngIf="filteredCaseNoteCodes && caseNoteCodeLength < 1">
              You have not yet added any {{ internalSurvey.shortName }} codes.
            </div>
            <div *ngIf="caseNoteCodeLength > 10" class="text-center">
              <pagination id="paginationCustom "
                [totalItems]="caseNoteCodeLength"
                [maxSize]="maxSize"
                (pageChanged)="pageChanged($event)"
                [boundaryLinks]="true"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;"
                [(ngModel)]="currentPage">
              </pagination>
              <p class="cnr-totals">{{ startItem + 1 || 1 }}-{{ endItem || 10 }} of {{caseNoteCodeLength}} {{ internalSurvey.shortName }} codes</p>
            </div>
          </div>
          <div *ngIf="!submissionLocked && !projectReadOnly">
            <div class="cnr-actions">
              <button class="btn btn-primary"
                type="button"
                (click)="addCnrCodes(currentSubmission, 1)"
                *ngIf="caseNoteCodeLength < internalSurvey.maxNumberCodes">
                  Add {{ internalSurvey.shortName }} Code
              </button>
              <button class="btn btn-default"
                type="button"
                (click)="addCnrCodes(currentSubmission, 10)"
                *ngIf="caseNoteCodeLength < internalSurvey.maxNumberCodes - 10">
                  Add 10 {{ internalSurvey.shortName }} Codes
                </button>
            </div>
            <p>
              Use the buttons above to add new {{ internalSurvey.shortName }}s, as required, up to a maximum of {{ internalSurvey.maxNumberCodes }}.
            </p>
          </div>
        </div>

        <!--
          External Survey Links
        -->
        <div *ngIf="externalSurvey && currentYear >= currentYearDate">
          <div class="dc-section" *ngFor="let survey of externalSurvey.surveys; let i = index">
            <div class="row">
              <div class="col-md-12">
                <h3>{{ survey.name | titlecase }}</h3>
                <p class="surveyCount">
                  So far, <strong>{{ survey.surveyCount }}</strong> {{ survey.name }}s have been completed for this submission.
                </p>
                <p *ngIf="projectId == 35">
                  <strong><a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Managing%20Frailty%20in%20the%20Acute%20Setting/2021/mf_2021_sua_specification.xlsx"><i class="far fa-file-excel"></i>&nbsp;Download the Service User Audit specification</a></strong>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-7">
                <p>
                  {{ survey.userType | titlecase }}s can complete the {{ survey.name }} by visiting the web-page below. The {{ survey.userType }} will then need to add the two-part code in order to complete the survey. The code is specific to this submission/site, and should only be used by {{ survey.userType }}s of this submission/site.
                  <b *ngIf="projectId == 50 && survey.name == 'staff survey'">
                    The deadline for the staff survey is 31st March 2024
                  </b>
                </p>
                <p>
                  <span class="prem-link">https://surveys.nhsbenchmarking.nhs.uk</span>
                  <span class="prem-link -black">{{ currentSubmission }}</span>
                  <span class="prem-link -black">{{ currentOrganisation }}</span>
                </p>
                <p>
                  Use the button below to copy the link and codes to your clipboard for use in communications with {{ survey.userType }}s.
                </p>
                <button class="btn btn-primary" (click)="copyToClipboard(survey.questionGroupLevel, currentSubmission, currentOrganisation)">
                  <i class="far fa-copy"></i>&nbsp;Copy link and code to clipboard
                </button>
                <div *ngIf="survey?.link">
                  <p *ngIf="survey.link[0].id == this.currentServiceName"> Click <a href={{survey.link[0].text}} target="_blank">here </a> to download a PDF of the PREM</p>
                  <p *ngIf="survey.link[1].id == this.currentServiceName"> Click <a href={{survey.link[1].text}} target="_blank">here </a> to download a PDF of the PREM</p>
                  <p *ngIf="survey.link[2].id == this.currentServiceName"> Click <a href={{survey.link[1].text}} target="_blank">here </a> to download a PDF of the PREM</p>
                </div>
                </div>
              <div class="col-md-4 col-sm-5">
                <div class="qr-code-container">
                  <qrcode
                    [qrdata]="survey.staffSurveyLink"
                    [size]="155"
                    [level]="'H'"
                    class="qr-code">
                  </qrcode>
                  <button class="btn btn-primary" (click)="downloadQRCode(i)">Download QR Code</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- SUBMIT SUBMISSION -->
        <div class="lock-submission" *ngIf="(currentYear >= currentYearDate && !submissionLocked && !guidanceMonthly || projectOpen) && projectId !== 34 && projectId !== 86">
          <p>
            By clicking the button below, you indicate to the project team that you have completed data collection for this submission and the data collection pages will become 'read-only'. You will still have the option to edit the data up to the deadline of {{ projectOverviewSubmissions.currentYearCloseDate | date: 'd LLLL y' }}.
          </p>
          <button class="btn btn-lg btn-success"
            (click)="setProjectState()"
            [disabled]="!allowLock || projectReadOnly">
              Complete Submission
          </button>
          <p class="text-muted" *ngIf="!allowLock">
            Please note that only Project Leads and Deputy Project Leads can mark a submission as complete.
          </p>
        </div>

        <div class="lock-submission" *ngIf="currentYear >= currentYearDate && submissionLocked && !guidanceMonthly">
          <p>
            <strong>This submission is currently marked as completed and the data collection pages are 'read-only'.</strong> You can still edit the data up to the deadline of {{ projectOverviewSubmissions.currentYearCloseDate | date: 'd LLLL y' }} by clicking 'Edit Submission' below.
          </p>
          <button class="btn btn-lg btn-default"
            (click)="setProjectState()"
            [disabled]="!allowLock || projectReadOnly">
              Edit Submission
          </button>
          <p class="text-muted" *ngIf="!allowLock">
            Please note that only Project Leads and Deputy Project Leads can mark a submission as editable.
          </p>
        </div>

      </div> <!-- end of projectOpen -->

    </div>
  </div>
</div>

<div *ngIf="hiddenYear" id="activeAdminBar">
  <button class="btn btn-default" disabled>
    This data collection year is only visible to admins
  </button>
</div>
