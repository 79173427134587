import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForumComponent } from './forum.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const outputsRoutes: Routes = [
  { path: 'forum', component: ForumComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(outputsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ForumRoutingModule { }