<div *ngIf="loaderService.isLoading | async" class="loading-spinner loading-above">
  <i class="fa fa-spinner fa-spin"></i>
</div>

<div class="row">
  <div class="col-md-6 col-xs-12 filter">
    <div class="form-inline">
      <div class="form-group">
        <select id="organisations" class="form-control select-width" [ngModel]="selectedItem"
          (ngModelChange)="onChange($event)">
          <option disabled selected>Filter by sector...</option>
          <option *ngFor="let sector of filterSections" [ngValue]="sector.title">{{sector.title}}</option>
        </select>
      </div>
    </div>
    <input [(ngModel)]="searchText" class="form-control mr-sm-2 search-input" type="search" placeholder="Search" aria-label="Search">
  </div>
  <div class="col-md-4 col-xs-12 sort-clear">
    <button class="btn btn-outline-primary" [disabled]="sortButton" (click)="sortList()">Sort <i class="fas fa-sort-alpha-down"></i></button>
    <button class="btn btn-primary" style="margin-left: 15px;" *ngIf="sortButton || searchText" (click)="resetProjectList()">Clear</button>
  </div>
</div>

<div *ngIf="sections" class="project-table">
  <h3 class="current-projects" (click)="showActiveProjects = !showActiveProjects">
    Your current projects  <i [class]="showActiveProjects ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
  </h3>
  <app-project-table-card 
    *ngIf="showActiveProjects" 
    [sections]="sections" 
    [searchText]="searchText" 
    [registrationStatus]="true"
    [organisationSelection]="organisationSelection">
  </app-project-table-card>

  <h3 class="other-historical-projects" (click)="showOtherProjects = !showOtherProjects"> 
    Other NHSBN projects <i [class]="showOtherProjects ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
  </h3>
  <app-project-table-card 
    *ngIf="showOtherProjects" 
    [sections]="sections" 
    [searchText]="searchText"
    [registrationStatus]="false"
    [organisationSelection]="organisationSelection">
  </app-project-table-card>

    <h3 class="other-historical-projects" (click)="showHistorical = !showHistorical"> 
      Historical Projects <i [class]="showHistorical ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
    </h3>
    <app-project-table-card 
      *ngIf="showHistorical" 
      [archived]="archived" 
      [searchText]="searchText"
      [organisationSelection]="organisationSelection">
    </app-project-table-card>
</div>