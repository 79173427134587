import { Injectable } from '@angular/core';

import { DropdownQuestion } from '../classes/question/question-dropdown';
import { NarrativeQuestion } from '../classes/question/question-narrative';
import { TextboxQuestion } from '../classes/question/question-textbox';
import { NumberQuestion } from '../classes/question/question-number';
import { PopupQuestion } from '../classes/question/question-popup';
import { DateQuestion } from '../classes/question/question-date';
import { TimeQuestion } from '../classes/question/question-time';
import { RadioQuestion } from '../classes/question/question-radio';

@Injectable()
export class QuestionService {
  
  constructor() {
  }

  getQuestionDetails(questionData) {
    let questions = { questionCount: 0, question: [] };

    questionData.questions.forEach(element => {

      let questionPrefix;
      if (element.questionPrefix === null) {
        questionPrefix = null;
      } else {
        questionPrefix = element.questionPrefix.trim();
      }

      if (element.questionType == 'N1' || element.questionType == 'N2' || element.questionType == 'N3' || element.questionType == 'N4') {

        let isParent;
        if (element.conditionQuestions[0] && element.conditionQuestions[0].conditionMin != null) {
          isParent = true;
        } else {
          isParent = false;
        }

        questions.question.push(new NumberQuestion({
          questionId: element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: element.questionType,
          questionHeading: element.headings,
          responses: element.responses,
          totalResponses: element.totalResponses,
          displaySequence: element.displaySequence,
          formatModifier: element.formatModifier,
          rangeMin: element.rangeMin, 
          rangeMax: element.rangeMax,
          decimalPlaces: element.decimalPlaces,
          showHeadings: element.showHeadings,
          conditionQuestions: element.conditionQuestions,
          isParent: isParent,
          modifierType: 'numeric',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType == 'TX') {
        questions.question.push(new TextboxQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: 'text',
          responses: element.responses,
          displaySequence: element.displaySequence,
          formatModifier: element.formatModifier,
          conditionQuestions: element.conditionQuestions,
          modifierType: 'text',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType == 'NR') {
        questions.question.push(new NarrativeQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: 'narrative',
          displaySequence: element.displaySequence,
          formatModifier: element.formatModifier,
          conditionQuestions: element.conditionQuestions,
          modifierType: 'narrative',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType == 'YN' || element.questionType == 'YNX' || element.questionType == 'YNI') {
        let optionsYN = [
          { optionId: 'Y', optionName: 'Yes', displaySequence: 1 },
          { optionId: 'N', optionName: 'No', displaySequence: 2 },
          { optionId: '-', optionName: '-', displaySequence: null }
        ];

        let optionsYNX = [
          { optionId: 'Y', optionName: 'Yes', displaySequence: 1 },
          { optionId: 'N', optionName: 'No', displaySequence: 2 },
          { optionId: 'X', optionName: 'N/A', displaySequence: 3 },
          { optionId: '-', optionName: '-', displaySequence: null }
        ];        

        if (element.formatModifier == 'S') {
          optionsYN.splice(2, 1);
          optionsYNX.splice(3, 1);
        }

        let optionsYNI = [
          { optionId: 'Y', optionName: 'Yes', displaySequence: 1 },
          { optionId: 'N', optionName: 'No', displaySequence: 2 },
          { optionId: 'X', optionName: 'N/A (Northern Ireland only)', displaySequence: 3 },
          { optionId: '-', optionName: '-', displaySequence: null }
        ];

        let isParent;
        if (element.conditionQuestions[0] && element.conditionQuestions[0].conditionMin != null) {
          isParent = true;
        } else {
          isParent = false;
        }

        let options = element.questionType == 'YN' ? optionsYN : element.questionType == 'YNX' ? optionsYNX : optionsYNI;

        questions.question.push(new DropdownQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: 'dropdown',
          questionResponseType: 'YN',
          options: options,
          responses: element.responses,
          displaySequence: element.displaySequence,
          formatModifier: element.formatModifier,
          conditionQuestions: element.conditionQuestions,
          isParent: isParent,
          modifierType: 'dropdown',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType == 'YN2' || element.questionType == 'YN3') {
        let options = [
          { optionId: 'Y', optionName: 'Yes', displaySequence: 1 },
          { optionId: 'N', optionName: 'No', displaySequence: 2 },
          { optionId: '-', optionName: '-', displaySequence: null }
        ];

        questions.question.push(new DropdownQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionHeading: element.headings,
          questionType: 'multidropdown',
          options: options,
          responses: element.responses,
          totalResponses: element.totalResponses,
          displaySequence: element.displaySequence,
          formatModifier: element.formatModifier,
          showHeadings: element.showHeadings,
          conditionQuestions: element.conditionQuestions,
          modifierType: 'dropdown',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType == 'LS' || element.questionType == 'PL') {
        let options = element.options;

        options.push({ optionId: '-', optionName: '-', displaySequence: null })

        let isParent;
        if (element.conditionQuestions[0] && element.conditionQuestions[0].conditionMin != null) {
          isParent = true;
        } else {
          isParent = false;
        }

        questions.question.push(new DropdownQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: 'dropdown',
          options: options,
          responses: element.responses,
          displaySequence: element.displaySequence,
          conditionQuestions: element.conditionQuestions,
          isParent: isParent,
          modifierType: 'dropdown',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType == 'RD') {
        questions.question.push(new RadioQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: 'radio',
          options: element.options,
          responses: element.responses,
          displaySequence: element.displaySequence,
          conditionQuestions: element.conditionQuestions,
          modifierType: 'radio',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType == 'DT') {
        questions.question.push(new DateQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: 'date',
          options: element.options,
          responses: element.responses,
          rangeMin: +element.rangeMin ? new Date(+element.rangeMin) : null, 
          rangeMax: +element.rangeMax ? new Date(+element.rangeMax) : null,
          displaySequence: element.displaySequence,
          formatModifier: element.formatModifier,
          conditionQuestions: element.conditionQuestions,
          modifierType: 'datetime',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType == 'TM' || element.questionType == 'TM2') {
        questions.question.push(new TimeQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: element.questionType,
          options: element.options,
          responses: element.responses,
          displaySequence: element.displaySequence,
          formatModifier: element.formatModifier,
          conditionQuestions: element.conditionQuestions,
          modifierType: 'datetime',
          questionPrefix: questionPrefix,
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.popupQuestions.length > 0) {
        element.popupQuestions.forEach(innerElement => {

          innerElement.modifierType = 'popup';

          if (innerElement.questionType == 'DT') {
            innerElement.rangeMin = +innerElement.rangeMin ? new Date(+innerElement.rangeMin) : null
            innerElement.rangeMax = +innerElement.rangeMax ? new Date(+innerElement.rangeMax) : null
            innerElement.modifierType = 'popup-datetime';
          }

          if (innerElement.questionType == 'TM2') {
            innerElement.modifierType = 'popup-datetime';
          }

          if (innerElement.questionType == 'YN' || innerElement.questionType == 'YN2') {
            let optionsYN = [
              { optionId: 'Y', optionName: 'Yes', displaySequence: 1 },
              { optionId: 'N', optionName: 'No', displaySequence: 2 },
              { optionId: '-', optionName: '-', displaySequence: null }
            ];            
            innerElement.options = optionsYN;
            innerElement.modifierType = 'popup-dropdown';
          }

          if (innerElement.questionType == 'N1' || innerElement.questionType == 'N2' || innerElement.questionType == 'N3' || innerElement.questionType == 'N4') {
            innerElement.modifierType = 'popup-numeric';
          }

        });

        questions.question.push(new PopupQuestion({
          questionId : element.questionId,
          questionText: element.questionText,
          rawQuestionText: element.rawQuestionText,
          questionHelp: element.questionHelp,
          questionType: 'popup',
          noColumns: element.noColumns,
          popupQuestions: element.popupQuestions,
          popupDescriptionText: element.popupDescriptionText,
          responses: element.responses.sort((a, b) => a.popupItemQuestionPart - b.popupItemQuestionPart),
          displaySequence: element.displaySequence,
          questionPart: element.questionPart,
          formatModifier: element.formatModifier,
          conditionQuestions: element.conditionQuestions,
          modifierType: 'popup',
          parentQuestionId: element.parentQuestionId,
          parentQuestionText: element.parentQuestionText,
          parentQuestionHelp: element.parentQuestionHelp,
          dataDefinition: element.dataDefinition
        }));
      }

      if (element.questionType != 'NR') {
        questions.questionCount++;
      }
    });

    questions.question.sort((a, b) => a.displaySequence - b.displaySequence);

    return questions;
  }
}