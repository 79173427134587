<div class="modal-header">
    <h5 class="modal-title">Edit Report Details</h5>
</div>
<div class="modal-body" *ngIf="editReportForm">
    <form [formGroup]="editReportForm">
        <div class="form-group">
            <label for="reportName">Report Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="reportName" (click)="placeholderHelp = false; copiedMessage = null">
            <div class="help-block"><strong>Changes to report title will impact multiple years.</strong> Please remember to use placeholders instead of dates; these can be copied from the helper pop-up below.</div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="decPlaces">Decimal Places</label>
                    <input type="number" class="form-control" formControlName="decPlaces">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="maxValue">Maximum Value</label>
                    <input type="number" class="form-control" formControlName="maxValue">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="formatModifier">Format Modifier</label>
                    <select name="formatModifier" formControlName="formatModifier" class="form-control">
                        <optgroup label="Popular">
                            <option value=" ">No modifier</option>
                            <option value="P">Percentage (%)</option>
                            <option value="C">Currency (£)</option>
                        </optgroup>
                        <optgroup label="Others">
                            <option value="T">Time</option>
                            <option value="D">Days</option>
                            <option value="Y">Years</option>
                        </optgroup>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="hideCodes">Hide Codes</label>
                    <select name="hideCodes" formControlName="hideCodes" class="form-control">
                        <option value="N">Show codes</option>
                        <option value="Y">Hide codes</option>
                    </select>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-md-8 text-left">
            <button type="button" class="btn btn-success" (click)="updateReport(editReportForm)" [disabled]="editReportForm?.pristine || editReportForm?.invalid || errorMessage">Save and Reload</button>
            <button type="button" class="btn btn-secondary" (click)="editReportModal.hide()">Cancel</button>
        </div>
        <div class="col-md-4">
            <!-- Placeholders -->
            <div id="placeholderHelp" *ngIf="placeholderHelp">
                <p>
                    <strong>Available Placeholders</strong><br>
                    Click on a placeholder below to copy to clipboard
                </p>
                <div *ngFor="let p of placeholders" (click)="copyToClipboard(p.id)">
                    <p class="placeholder"><span>{{ p.example }}</span><strong [id]="p.id"><i>*|</i>{{ p.name }}<i>|*</i></strong></p>
                </div>
                <div class="placeholder-copied" *ngIf="copiedMessage">
                    <i class="fas fa-check-circle"></i> {{ copiedMessage }}
                </div>
            </div>
            <span type="button" [class]="placeholderHelp ? 'btn btn-admin -help -active' : 'btn btn-admin -help'" (click)="placeholderHelp = !placeholderHelp">
                <i class="fas fa-question-circle"></i> Placeholders
            </span>
        </div>
    </div>
    <div class="row error" *ngIf="errorMessage">
        <div class="col-md-12 mt-2">
            <div class="alert alert-danger">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</div>