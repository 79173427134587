import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BsDropdownModule, ModalModule } from 'ngx-bootstrap';

import { HomeComponent } from './home.component';
import { ProjectTableComponent } from './project-table/project-table.component';
import { HomeRoutingModule } from './home-routing.module';
import { ProjectDetailModule } from '../project-detail/project-detail.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { SafePipeModule } from '../utilities/pipes/safe-pipe.module'
import { ReversePipe } from '../utilities/pipes/reverse.pipe';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OldHomeComponent } from './old-home/old-home.component';
import { OldProjectTableComponent } from './old-home/old-project-table/old-project-table.component';
import { NgPipesModule, OrderByPipe, UniquePipe } from 'ngx-pipes';
import { ProjectTableCardComponent } from './project-table/project-table-card/project-table-card.component';
import { SidebarSectionComponent } from './sidebar/sidebar-section/sidebar-section.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ProjectDetailModule,
    SafePipeModule,
    NgPipesModule,
    HomeRoutingModule,
    TooltipModule.forRoot()
  ],
  providers: [
    OrderByPipe,
    UniquePipe,
  ],
  declarations: [
    HomeComponent,
    ProjectTableComponent,
    ProjectTableCardComponent,
    ReversePipe,
    SidebarComponent,
    OldHomeComponent,
    OldProjectTableComponent,
    SidebarSectionComponent
  ]
})
export class HomeModule {}