import { Component, OnInit, ViewChild, HostListener, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { ToastrService } from 'ngx-toastr';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';
import { ModalDirective } from 'ngx-bootstrap';

import { MemberService } from '../utilities/services/member.service';
import { LoaderService } from '../utilities/services/loader.service';
import { IcsService } from 'app/utilities/services/ics.service';
import { ErrorService } from '../utilities/services/error.service';

@Component({
    selector: 'app-project-registration',
    templateUrl: './project-registration.component.html',
    styleUrls: ['./project-registration.component.scss']
})
export class ProjectRegistrationComponent implements OnInit {

    projectDetails; projectBasicDetails;
    projectId: number; organisationId: number;
    deputyProjectLeadView: boolean = false; submissionView: boolean = false; submissionGroupView: boolean = false;
    principleContact;
    organisationGroupList; commissioningPartners;
    projectLead; deputyProjectLead;
    serviceCheck: boolean = false;
    maxServices: number;
    guidance;
    guidanceIntro: string; guidanceProjectLead: string; guidanceDeputyProjectLead: string; 
    guidanceSubmissionGroup: string; guidanceSubmission: string; guidanceService: string; guidanceSubmissionAdd: string; guidanceServiceTitle: string;
    allowChanges: boolean;
    registrationClosed: boolean;
    organisationError: boolean;
    addressAdded: boolean;
    addressClosed: boolean;
    showACDocuments: boolean = false;
    showMHDocuments: boolean = false;
    deputyProjectLeadNumber: string
    
    icsProject: boolean = false;
    icsError: string;
    icsOptInData: any;

    public navIsFixed: boolean = false;
    public width: number;

    metaTitle: string = 'Project Registration | NHS Benchmarking Network';

    constructor(
        private memberService: MemberService,
        private icsService: IcsService,
        private errorService: ErrorService,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private titleService: Title,
        private meta: Meta,
        private newSpinner: Angular2PromiseButtonModule,
        public loaderService: LoaderService
    ) {
        this.projectId = +this.route.snapshot.paramMap.get('projectId');
        this.organisationId = +this.route.snapshot.paramMap.get('organisationId');
    }

    ngOnInit() {
        this.titleService.setTitle(this.metaTitle); // Set page title
        this.meta.updateTag({property: 'og:title', content: this.metaTitle});
        this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
        this.getProjectRegistrationBasicDetails(this.projectId, this.organisationId);

        // Close NHSE LDIS patient survey delivery address
        let timeClose = new Date('2023/12/31 23:00:00'),
            timeNow = new Date(Date.now()),
            diff = timeClose.getTime() - timeNow.getTime();
        
        if (diff < 0) {
        this.addressClosed = true
        } else {
        this.addressClosed = false;
        }
    }

    getProjectRegistrationBasicDetails(projectId: number, organisationId: number) {
        let provider: number = 15
        let commmissioner: number = 14

        this.memberService.ProjectRegistrationBasicDetails(projectId, organisationId).subscribe(
            d => {
                this.projectBasicDetails = d.data;
                
                // Set ICS project status
                // TODO: Be more specific about ICS vs. non-ICS
                if (d.data.project.collectionFrequency !== null) {
                    this.icsProject = true;
                }

                // Return project-specific guidance
                let guidance = this.projectBasicDetails.project.projectGuidance.registration;
                if (guidance) {
                    for (let index = 1; index <= 15; index++) {
                        let selectedGuidance = guidance.filter(p => p.guidanceTypeId == index)
                        if (selectedGuidance[0]) {
                            switch (index) {
                                case 1: {
                                    this.guidanceIntro = selectedGuidance[0].guidanceText;
                                    break;
                                }
                                case 2: {
                                    this.guidanceProjectLead = selectedGuidance[0].guidanceText;
                                    break;
                                }
                                case 3: {
                                    this.guidanceDeputyProjectLead = selectedGuidance[0].guidanceText;
                                    break;
                                }
                                case 4: {
                                    this.guidanceSubmissionGroup = selectedGuidance[0].guidanceText;
                                    break;
                                }
                                case 5: {
                                    this.guidanceSubmission = selectedGuidance[0].guidanceText;
                                    break;
                                }
                                case 6: {
                                    this.guidanceService = selectedGuidance[0].guidanceText;
                                    break;
                                }
                                case 8: {
                                    this.guidanceSubmissionAdd = selectedGuidance[0].guidanceText;
                                    break;
                                }
                                case 15: {
                                    this.guidanceServiceTitle = selectedGuidance[0].guidanceText;
                                    break;
                                }
                            }
                        }
                    }
                };

                let registrationId = this.projectBasicDetails.registrationId;
                let registrationStatus = this.projectBasicDetails.project.registrationAvailable;

                if (this.projectId != provider && this.projectId != commmissioner) {
                    if (registrationId) {
                        this.getProjectRegistrationDetails(this.projectBasicDetails.project.productId, projectId, organisationId);
                    } else if (!registrationId && registrationStatus === 'N') {
                        this.registrationClosed = true;
                    } else {
                        let data = {
                            projectId: projectId,
                            organisationId: organisationId,
                            productId: d.data.project.productId,
                            project: { projectName: this.projectBasicDetails.project.projectName },
                            registration: { projectLead: { projectLeadList: [] } }
                        };

                        this.projectDetails = data;
                        this.projectLead = null;
                        this.deputyProjectLead = null;
                    }
                };
                // Set principle contact
                let principleContact = this.projectBasicDetails.project.projectRoles.filter(p => p.principleContact == 'Y')[0];
                if (principleContact) {
                  this.principleContact = principleContact;
                } else {
                  this.principleContact = this.projectBasicDetails.project.projectRoles.filter(p => p.role == 'Project Coordinator')[0];
                }
            },
            e => {
                this.toastr.error(this.errorService.getErrorMessage(e.error.error.statusCode));
                if (e.error.error.statusCode == 4101) {
                    this.registrationClosed = true;
                }
                this.router.navigate(['/home']);
            }
        );
    }

    getProjectRegistrationDetails(productId: number, projectId: number, organisationId: number) {
        this.memberService.ProjectRegistration(productId, projectId, organisationId).subscribe(
            d => {
                let data = d.data;
                this.deputyProjectLeadNumber = this.convertNumberToWords(data.registration.registrationType.maxDeputyProjectLeads)
                data.projectId = projectId; data.organisationId = organisationId; data.productId = productId;

                this.projectDetails = data;

                this.projectLead = this.projectDetails.registration.projectLead.projectLeadList;

                this.allowChanges = this.projectDetails.organisation.allowChanges;

                this.deputyProjectLead = this.projectDetails.registration.deputyProjectLead.deputyProjectLeadList;
                
                this.projectDetails.registration.submission.submissionList.sort((a, b) => a.submissionId - b.submissionId);

                // Move this to API
                if (this.projectDetails.registration.registrationType.maxServicesPerSubmission == null) { this.maxServices = 100 } 
                else { this.maxServices = this.projectDetails.registration.registrationType.maxServicesPerSubmission }

                if (this.projectDetails.registration.projectLead.projectLeadList.length > 0) {
                    this.deputyProjectLeadView = true;
                    this.submissionView = true;

                    if (this.projectDetails.registration.commissionerProvider == 'C') {
                        let index = this.projectDetails.organisation.commissioningPartners.findIndex(o => o.organisationId == organisationId);
                        this.projectDetails.organisation.commissioningPartners.splice(index, 1);
                        
                        this.organisationList();
                        this.submissionGroupView = true;
                    }
                }

                // Remove visible services for AT in 2021
                // TODO: Make services year-specific. Added to Airtable.
                if (projectId === 38) {
                    this.projectDetails.registration.submission.serviceList = [];
                }

                // If CI, check and display opt-in status
                if (projectId === 29) {
                    this.icsGetOptIn(projectId, this.projectDetails['organisation'].nhsCode, "Community")
                }

                this.serviceCheckUpdate();
                this.documentCheck();
            }
        );
    }

    projectLeadAdd(message) {
        this.getProjectRegistrationDetails(message.productId, message.projectId, message.organisationId);

        this.deputyProjectLeadView = true;
    }

    addressAdd(message) {
        this.addressAdded = message;
        this.getProjectRegistrationDetails(this.projectDetails.productId, this.projectId, this.organisationId);
    }

    deputyProjectLeadAdd(message) {
        this.projectDetails.registration.deputyProjectLead.deputyProjectLeadList.push({
            userId: message.userId,
            logonName: message.logonName,
            fullName: message.fullName,
            jobTitle: message.jobTitle,
            phoneNo: message.phoneNo
        });

        this.projectDetails.registration.deputyProjectLead.deputyProjectLeadList.sort((a, b) => a.fullName.localeCompare(b.fullName));

        this.submissionView = true;
    }

    deputyProjectLeadRemove(message) {
        this.projectDetails.registration.deputyProjectLead.deputyProjectLeadList.splice(this.projectDetails.registration.deputyProjectLead.deputyProjectLeadList.findIndex(p => p.userId == message), 1);
    }

    organisationList() {
        this.memberService.SubmissionOrganisations().subscribe(
            s => {
                this.organisationGroupList = s.data;
            }
        )
    }

    setOrganisationError(message) {
        this.organisationError = message;
    }

    submissionGroupAdd(message) {
        let commissioners = this.projectDetails.organisation.commissioningPartners;

        if (commissioners.length < 1) {
            this.projectDetails.organisation.commissioningPartners = [{
                organisationId: message.organisationId, 
                organisationName: message.organisationName
            }];
        }
        else {
            commissioners.push({
                organisationId: message.organisationId, 
                organisationName: message.organisationName
            });
        }

        this.projectDetails.organisation.commissioningPartners.sort((a, b) => a.organisationName.localeCompare(b.organisationName));
    }

    submissionGroupDelete(message) {
        let commissioners = this.projectDetails.organisation.commissioningPartners;

        let commissionerIndex = commissioners.findIndex(o => o.organisationId == message);

        commissioners.splice(commissionerIndex, 1);
    }

    submissionAdd(message) {
        this.projectDetails.registration.submission.submissionList.push({
            submissionId: message.submissionId,
            submissionName: message.submissionName,
            peerGroupId: message.peerGroupId,
            peerGroupName: message.peerGroupName,
            submissionLeadList: [],
            services: [],
            organisationId: null,
            organisationName: null
        });
  
        this.projectDetails.registration.submission.submissionList.sort((a, b) => a.submissionId - b.submissionId);

        this.serviceCheckUpdate();
    }

    submissionEdit(message) {
        let currentSubmission = this.projectDetails.registration.submission.submissionList.findIndex(p => p.submissionId == message.submissionId);

        this.projectDetails.registration.submission.submissionList[currentSubmission].submissionName = message.submissionName;
        this.projectDetails.registration.submission.submissionList[currentSubmission].peerGroupId = message.peerGroupId;

        this.projectDetails.registration.submission.submissionList.sort((a, b) => a.submissionId - b.submissionId);
        
        this.documentCheck();
    }

    submissionDelete(message) {
        this.projectDetails.registration.submission.submissionList.splice(this.projectDetails.registration.submission.submissionList.findIndex(p => p.submissionId == message), 1);

        this.serviceCheckUpdate();
    }

    submissionLeadAdd(message) {
        let submission = this.projectDetails.registration.submission.submissionList.findIndex(p => p.submissionId == message.submissionId);

        this.projectDetails.registration.submission.submissionList[submission].submissionLeadList.push({
            userId: message.userId,
            fullName: message.fullName,
            logonName: message.logonName,
            jobTitle: message.jobTitle,
            phoneNo: message.phoneNo
        });
    }

    submissionLeadDelete(message) {
        let submission = this.projectDetails.registration.submission.submissionList.findIndex(p => p.submissionId == message.submissionId);
        let user = this.projectDetails.registration.submission.submissionList[submission].submissionLeadList.findIndex(p => p.userId = message.userId);

        this.projectDetails.registration.submission.submissionList[submission].submissionLeadList.splice(user);
    }

    serviceAdd(message) {
        let submission = this.projectDetails.registration.submission.submissionList;
        let submissionIndex = submission.findIndex(s => s.submissionId == message.submissionId);
        
        submission[submissionIndex].services.push({
            serviceItemId: message.serviceItemId,
            serviceItemName: message.serviceItemName
        });

        submission[submissionIndex].services.sort((a, b) => a.serviceItemId - b.serviceItemId);

        this.serviceCheckUpdate();
    }
    
    serviceDelete(message) {
        let submission = this.projectDetails.registration.submission.submissionList;
        let submissionIndex = submission.findIndex(s => s.submissionId == message.submissionId);
        let service = submission[submissionIndex].services.findIndex(s => s.serviceItemId == message.serviceItemId);

        this.projectDetails.registration.submission.submissionList[submissionIndex].services.splice(service, 1);

        this.serviceCheckUpdate();
    }

    serviceLeadAdd(message) {
        let submissionIndex = this.projectDetails.registration.submission.submissionList.findIndex(p => p.submissionId == message.submissionId);
        let serviceIndex = this.projectDetails.registration.submission.submissionList[submissionIndex].services.findIndex(s => s.serviceItemId == message.serviceItemId);

        this.projectDetails.registration.submission.submissionList[submissionIndex].services[serviceIndex].serviceLeadList = [{
            userId: message.userId,
            userRoleId: message.userRoleId,
            fullName: message.fullName,
            logonName: message.logonName,
            jobTitle: '',
            phoneNo: ''
        }];
    }

    serviceLeadDelete(message) {
        let submissionIndex = this.projectDetails.registration.submission.submissionList.findIndex(p => p.submissionId == message.submissionId);
        let serviceIndex = this.projectDetails.registration.submission.submissionList[submissionIndex].services.findIndex(s => s.serviceItemId == message.serviceItemId);

        this.projectDetails.registration.submission.submissionList[submissionIndex].services[serviceIndex].logonName = null;
        this.projectDetails.registration.submission.submissionList[submissionIndex].services[serviceIndex].userId = null;

        this.projectDetails.registration.submission.submissionList[submissionIndex].services[serviceIndex].serviceLeadList = [];
    }

    serviceCheckUpdate() {
        if (this.projectDetails.registration.submission.serviceList.length > 0) {
            let submissions = this.projectDetails.registration.submission.submissionList;        
            let submissionServiceCount = 0;

            submissions.forEach(s => {
                if (s.services.length > 0) {
                    submissionServiceCount ++;
                }
            });

            if (submissionServiceCount == submissions.length) {
                this.serviceCheck = false;
            }
            else {
                this.serviceCheck = true;
            }
        }

        this.documentCheck();
    }

    icsAddSubmissions() {

        this.newSpinner = new Promise((resolve) => {
            setTimeout(resolve, 3000);
        });

        let organisation = this.projectDetails['organisation'],
            submissions = {
                organisationName: organisation.organisationName,
                organisationCode: organisation.nhsCode,
                organisationTypeName: "Community",
                externalOrganisationId: this.organisationId,
                submissionList: []
            }

        this.projectDetails.registration.submission.submissionList.forEach((s, i) => {
            submissions.submissionList.push({
                submissionName: s.submissionName,
                submissionCode: organisation.nhsCode + '-' + (i + 1)
            })
        });

        this.icsService.addSubmissions(this.projectId, submissions).subscribe(
            r => {
                this.registerProject();              
            },
            e => {
                console.log(e);
                this.icsError = 'There has been an error trying to save the submissions to the database (Error Code: ICS01). Please try refreshing the page and completing the registration again, then contact the Support Team.'
            }
        );
    }

    icsOptIn(yesNo) {
        let consent = {
            organisationTypeName: "Community",
            organisationCode: this.projectDetails['organisation'].nhsCode,
            consent: yesNo
        }

        // Save consent
        this.icsService.saveConsent(this.projectId, consent).subscribe(
            r => {
                this.icsGetOptIn(this.projectId, this.projectDetails['organisation'].nhsCode, "Community");
            },
            e => {
                console.log(e);
                this.icsError = 'There has been an error trying to save your consent status (Error Code: ICS02). Please contact the Support Team.'
            }
        );
    }

    icsGetOptIn(projectId, organisationCode, organisationType) {
        this.icsService.getConsent(projectId, organisationCode, organisationType).subscribe(
            r => { 
                this.icsOptInData = r.data;
            },
            e => {
                console.log(e);
                this.icsOptInData = null;
            }
        )
    }
    
    registerProject() {

        this.newSpinner = new Promise((resolve) => {
            setTimeout(resolve, 3000);
        });

        // Set states
        let state = null;
        if (this.projectDetails.registration.registrationLocked) { state = 'N'; } 
        else { state = 'Y'; }

        // Register projects
        this.memberService.RegisterProject(this.projectDetails.registrationId, state).subscribe(
            r => {
                if (state == 'Y') {
                    this.projectDetails.registration.registrationLocked = true;
                    this.toastr.success('You can edit the registration details at any time.', 'Registration Complete');
                    this.router.navigate(['/home']);
                }
                else {
                    this.projectDetails.registration.registrationLocked = false;
                }
            }
        );
    }

    individualAccess() {

        // Not currently in use

        let registrationId = this.projectDetails.registration.registrationId;
        let users = [];

        // Add current user / registration lead
        let currentUser = JSON.parse(sessionStorage.getItem('user'));
        users.push({ userId: currentUser.userId });

        // Add PL
        this.projectDetails.registration.projectLead.projectLeadList.forEach(pl => {
            users.push({ userId: pl.userId });
        });

        // Add DPLs
        this.projectDetails.registration.deputyProjectLead.deputyProjectLeadList.forEach(dpl => {
            users.push({ userId: dpl.userId });
        });

        // Add SLs
        this.projectDetails.registration.submission.submissionList.forEach(s => {
            s.submissionLeadList.forEach(sll => {
                users.push({ userId: sll.userId });
            });
        });

        // Send to database
        users.forEach(u => {
            this.memberService.IndividualAccess(registrationId, u).subscribe(
                r => { console.log(r) },
                e => { console.log(e) }
            )
        });

    }

    documentCheck() {
        if (this.projectId === 34) {
            let regionId = this.projectDetails.organisation.regionId, // AC should only show for England in 2021
                peerGroups = this.projectDetails.registration.submission.submissionList.map(s => s.peerGroupId),
                allowedAC = [1, 2],
                allowedMH = [3];
            if (peerGroups.some(p => allowedAC.includes(p)) && ![5,6].includes(regionId) ) {
                this.showACDocuments = true;
            } else {
                this.showACDocuments = false;
            }
            if (peerGroups.some(p => allowedMH.includes(p))) {
                this.showMHDocuments = true;
            } else {
                this.showMHDocuments = false;
            }
        }
    }

    navHome() {
        this.toastr.warning('The registration has been initiated and your progress saved for completion later.', 'Registration Initiated');
        this.router.navigate(['/home']);
    }

    convertNumberToWords(input: number): string{
        const numbersToWords = {
        0: "zero",
        1: "one",
        2: "two",
        3: "three",
        4: "four",
        5: "five",
        6: "six",
        7: "seven",
        8: "eight",
        9: "nine",
        10: "ten",
        11: "eleven",
        12: "twelve",
        13: "thirteen",
        14: "fourteen",
        15: "fifteen",
        16: "sixteen",
        17: "seventeen",
        18: "eighteen",
        19: "nineteen",
        20: "twenty",
        30: "thirty",
        40: "forty",
        50: "fifty",
        60: "sixty",
        70: "seventy",
        80: "eighty",
        90: "ninety",
    };
    if(input == null){
        return null
    } else {
    let words = "";
    if (input >= 100) {
      words += this.convertNumberToWords(Math.floor(input / 100)) + " hundred";
      input %= 100;
    }
      if (input > 0) {
      if (words !== "") words += " and ";
  
      if (input < 20) words += numbersToWords[input];
      else {
        words += numbersToWords[Math.floor(input / 10) * 10];
        if (input % 10 > 0) {
          words += "-" + numbersToWords[input % 10];
        }
      }
    }
    return words
    }
    }
}