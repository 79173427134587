import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { MemberService } from 'app/utilities/services/member.service';
import { DataService } from '../utilities/ics/data.service';
import { FormatService } from '../utilities/ics/format.service';

import { Map } from 'mapbox-gl';
import extent from 'turf-extent';

import { FilterByPipe, OrderByPipe } from 'ngx-pipes';
import { DynamicInfoPanel } from 'app/utilities/classes/dynamic-info-panel/dynamic-info-panel';

declare global {
  interface Navigator {
      msSaveBlob: (blob: any, defaultName?: string) => boolean
  }
}

@Component({
  selector: 'app-benchmarker',
  templateUrl: './benchmarker.component.html',
  styleUrls: ['./benchmarker.component.scss'],
  providers: [ FilterByPipe, OrderByPipe ]
})
export class BenchmarkerComponent implements OnInit {

  projectId: number = 39;
  subscriptions = [];
  tiers; tierId; domainId; topTierRight: boolean = false;
  search; query; count: number = -1;
  details; relationships; aggregatable: boolean = false;
  data; data_raw; seriesData;
  primary_details_saved; primary_data_saved; data_saved;
  periods; period;
  selected;
  averages;
  categories = []; seriesCategory: number; selectedSeries;
  independentCategory; 
  user; admin: boolean = false;
  drop = { 
    any: false, 
    tiers: false,
    menu: false, 
    period: false, 
    category: false, 
    highlight: false, 
    secondary: false, 
    series: false, 
    preferences: false,
    constituents: false,
    flagged: false,
    info: false,
    export: false,
    bookmarks: false
  };
  error: string; errorInline: string;
  constituents: any; constituentsDetails: boolean = false;

  // Preferences
  preferences;
  organisations;
  currentDefault;

  // Secondary
  secondary; secondaryDetails; secondaryData;
  r2Value;

  stackable: boolean = false;
  highlightable: boolean = false;
  highlighted: string;
  highlights: any;
  filtered: boolean = false;

  loading: boolean = false;

  // Charts
  chartBarOptions; chartBar: any;
  chartLineOptions; chartLine: any;
  chartScatterOptions; chartScatter: any;
  chartStackedColumnOptions; chartStackedColumn: any;
  chartNationalStackedColumnOptions;
  colors: any = [
    "#e03616","#f1a208","#00a878","#111b54","#a01a7d","#09c2de",
    "#f39886","#fbd281","#54ffcf","#566adc","#e873c9","#78e8f9",
    "#6f1c0b","#795204","#00543c","#090e2a","#4f0d3d","#05606e"            
  ];

  map: Map;
  mapDetails; mapDefault;
  mapOptions;
  hover; mouseX; mouseY;
  point;
  noMapbox: boolean = false;

  copied: boolean = false;
  bookmarked: string;

  params: string;

  // Tables
  columnOrder: string = '+';
  highestLowest: any;
  highestLowestGroup: any;
  infoPanel: DynamicInfoPanel;

  colorBlindnessEnabled: boolean = false;
  nationalMeanColour = '#28A745';
  organisationValueColour = '#E03616';

  // Downloads
  @ViewChild('dlMap') dlMap: ElementRef;
  @ViewChild('dlCanvas') dlCanvas: ElementRef;
  @ViewChild('dlLink') dlLink: ElementRef;

  // Video
  videoURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Tktw9-LvlM8?controls=0');

  constructor(
    private memberService: MemberService,
    private dataService: DataService,
    private formatService: FormatService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private filterBy: FilterByPipe,
    private orderBy: OrderByPipe,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getUserDetails();
    this.getPreferences();
    this.params = this.router.url;

    // Set loading
    let params = this.route.snapshot.queryParams;
    if (params.rep) {
      this.loading = true;
    }
  }

  public onInfoPanelClick = (info: string): void => {
    this.toggleDrop(info);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  // GETS

  getUserDetails() {
    this.memberService.User().subscribe(
      u => { 
        this.user = u.data;
        if (this.user && this.user.isAdmin && this.user.isAdmin == 'Y') {
          this.admin = true;
        }
      }
    );
  }

  getPreferences(refresh?: boolean) {
    this.dataService.getPreferences().subscribe(
      r => {
        this.preferences = this.formatService.preferences(r.data.preferenceList);

        if (refresh !== true) {
          this.getTiers(this.projectId);
        }

        // TODO: Update search tiers and apply
        // this.getSearchTiers(this.projectId);
      },
      e => {
        console.log(e);
      }
    )
  }

  getTiers(projectId) {
    this.dataService.getTiers(projectId).subscribe(
      r => { 
        this.tiers = r.data;
        this.search = this.formatService.tiers(r.data);

        // Check for report params
        let params = this.route.snapshot.queryParams;
        if (params.rep) {
          this.loading = true;
          this.getDetails(params.rep, null, params.per, params.cat);
        }

        // Check for old-style links
        if (params.p && !params.rep) {
          let matchingTiers = this.search.filter(s => s.reportId == params.p);
          if (matchingTiers.length == 1) {
            this.getDetails(matchingTiers[0].reportId + '-' + matchingTiers[0].searchTier);
          } else {
            this.loading = false;
            this.error = 'The report you are looking for has not been found. Please try using the search bar or navigation above.'
          }
        }

        // Remove old-style params
        this.router.navigate([], { queryParams: { d: null, s: null, p: null }, queryParamsHandling: 'merge' });

      },
      e => { 
        console.log(e)
      }
    )
  }

  getSearchTiers(projectId) {
    this.dataService.getSearchTiers(projectId).subscribe(
      r => {
        console.log(r);
      },
      e => {
        console.log(e);
      }
    )
  }

  getDetails(reportTier, secondary?, period?, categories?) {

    // Convert reportTier
    let reportId = +reportTier.split('-')[0];
    let tierId = +reportTier.split('-')[1];
    
    // Set tierId
    this.tierId = tierId;

    // Close menu dropdown
    this.toggleDrop('any');
    this.tiers.forEach(t => t.open = false);
    this.query = null;

    // Reset categories and start load
    // TODO: Move to reset()
    this.categories = [];
    this.loading = true;
    this.error = null;
    this.secondary = null;
    this.noMapbox = false;
    this.data_saved = null;
    this.seriesData = null;
    this.chartScatterOptions = null;
    this.secondaryDetails = null;
    this.chartScatterOptions = null;
    this.chartStackedColumnOptions = null;
    this.primary_details_saved = null;
    this.primary_data_saved = null;
    this.relationships = null;
    this.preferences.reportId = null;
    this.highlighted = null;
    this.highlights = null;
    this.filtered = null;
    this.independentCategory = null;
    this.copied = false;
    this.bookmarked = null;

    // Check for report/tier
    let tier = this.search.find(s => s.reportId == reportId && s.searchTier === tierId);
    if (reportId && tierId && tier) {
      
      // Set domain
      this.domainId = tier.t1_tierId ? tier.t1_tierId : null;

      // Set secondary selection
      if (tier && tier.children && tier.children.length) {
        this.secondary = this.orderBy.transform(tier.children, 'displaySequence');
      } else if (secondary && secondary.length) {
        this.secondary = this.orderBy.transform(secondary, 'displaySequence');
      }

      // Reset params if new report
      if (period === undefined || (secondary && secondary.length)) {
        this.router.navigate([], { queryParams: { cat: null, per: null, agg: null, sec: null }, queryParamsHandling: 'merge' });
      }

      this.dataService.getDetails(reportId).subscribe(
        r => { 
          // Set details
          this.details = this.formatService.details(r.data);

          // Save reportTier for future use
          this.details.reportTier = reportTier;

          let aggregatableTypes = ['LA', 'CCG'];
          if (aggregatableTypes.includes(this.details.organisationType)) {
            this.aggregatable = true;
          } else {
            this.aggregatable = false;
          }
          
          // Check set param categories or set all available categories
          if (categories) {
            let paramCats = categories.split('-');
            paramCats.forEach(c => {
              let matchingCategory = this.details.categories.find(dc => dc.categoryId === +c);
              if (matchingCategory) {
                this.selectCategory(matchingCategory);
              }
            });
          } else {
            if (this.details.options.includes('IC')) {
              this.selectCategory(this.details.categories[0]);
            } else {
              this.details.categories.forEach(c => { 
                this.selectCategory(c);
              });
            }
            this.router.navigate([], { queryParams: { cat: null }, queryParamsHandling: 'merge' });
          }

          // Check if data should be aggregated
          let params = this.route.snapshot.queryParams;
          if ((this.aggregatable && !params.agg) || params.agg == 'ics') {
            this.details.aggregation = 'ics';
            this.router.navigate([], { queryParams: { agg: 'ics' }, queryParamsHandling: 'merge' });
          }

          // Get data
          this.getData(reportId, period, this.categories, null, this.tierId);
          this.infoPanel = new DynamicInfoPanel(this.details);
          this.infoPanel.info = this.drop.info;
          this.infoPanel.admin = true;
        },
        e => { 
          console.log(e)
        }
      )      

    } else {
      reportId = null;
      this.loading = false;
      this.error = 'The report you are looking for has not been found. Please try using the search bar or navigation above.'
    }

  }

  getData(reportId, period?, categories?, organisationId?, tierId?) {

    // Reset stacked bar
    this.removeStacked();

    // Check for secondary metric
    let params, secondary;
    
    if (this.secondary) {
      params = this.route.snapshot.queryParams;
      if (params.sec) {
        secondary = params.sec;
      }
    } else {
      // TODO: Move to reset()
      this.secondary = null;
      this.secondaryDetails = null;
      this.secondaryData = null;
      this.chartScatterOptions = null;
      this.router.navigate([], { queryParams: { sec: null }, queryParamsHandling: 'merge' });
    }

    // Start load
    this.loading = true;
    this.unsubscribe();

    // Set categoryIds for API call
    let categoryIds;
    categories ? categoryIds = categories.map(c => c.categoryId) : null;

    this.subscriptions.push(
      this.dataService.getData(reportId, categoryIds, organisationId, tierId).subscribe(
        r => {

          // Get default
          let currentDefault = this.preferences['default_' + this.details.organisationType.toLowerCase()];
          this.currentDefault = currentDefault;

          // Set data
          this.data_raw = this.formatService.data(r.data);
          this.data = this.formatService.data(r.data, currentDefault ? currentDefault.preferenceItem : null);

          // Remove periods with no submission data (e.g. Wales-only data with England default)
          this.data_raw = this.data_raw.filter(data => data.submissions.length);
          this.data = this.data.filter(data => data.submissions.length);

          // Save primary information
          this.primary_details_saved = JSON.parse(JSON.stringify(this.details));
          this.primary_data_saved = JSON.parse(JSON.stringify(this.data_raw));

          // Check/set param period or set latest period
          if (period) {
            // Check period exists in current data set
            // Replace with latest if not available
            let findPeriod = this.data.find(p => p.periodId === +period);
            if (findPeriod) {
              this.period = findPeriod;
            } else {
              this.period = this.data[0];
            }
          } else {
            this.period = this.data[0];
          }

          if (this.data.length) {

            // Set current period data and averages
            this.selected = this.data.find(d => d.periodId === this.period.periodId);
            this.averages = this.formatService.averages(this.selected.submissions);

            // Add categories to params
            let categoryParams;
            categories ? categoryParams = categoryIds.join('-') : categoryParams = null;
            this.router.navigate([], { queryParams: { rep: this.details.reportTier, cat: categoryParams, per: this.period.periodId }, queryParamsHandling: 'merge' });

            // Aggregate data if required, or create chart/map
            if (this.details.aggregation) {
              this.getAggregation('ICS', categoryIds);
            } else {
              this.createBarChart(this.selected);
              this.createMap(this.selected, this.period, this.averages, this.details.aggregation ? this.details.aggregation : this.details.organisationType);
            }

            // Create time series
            this.createLineChart(this.data.reverse());

          } else {
            this.error = 'There is no data for this report. (Report ID: ' + this.details.reportId + ')';
          }
          
        },
        e => { 
          console.log(e);
        },
        () => {
          // Check for secondary or aggregation
          if (secondary) {
            this.getSecondary(+secondary, null, this.currentDefault);
          } else {
            this.loading = false;
          }          
        }
      )
    )
  }

  getAggregation(parentType, categoryIds) {
    this.loading = true;

    this.dataService.getRelationships(parentType + ' to ' + this.details.organisationType).subscribe(
      r => {

        // Get all relationships
        this.relationships = this.formatService.relationships(r.data.yearRelationshipList);

        // Get default
        let currentDefault = this.preferences['default_ics'];

        // Aggregate all areas
        let aggregations = this.formatService.aggregate(this.data, this.relationships, this.details.aggregationType, currentDefault ? currentDefault.preferenceItem : null, this.details);
        
        // Add aggregations to data
        this.data = aggregations;
        
        // Set aggregated data as selected
        this.data.forEach(d => {
          d.submissions = this.orderBy.transform(d.aggregated, '-submissionResult');
        });

        // Add ICS mean
        if (this.preferences.default_ics) {
          this.data.forEach(d => {
            let defaultOrg = d.submissions.find(s => s.organisationOnsCode === this.preferences.default_ics.preferenceItem);
            if (defaultOrg) {
              d.defaultResult = defaultOrg.submissionResult;
            }
          })
        }

        // Save aggregation type and param
        this.details.aggregation = parentType;
        this.router.navigate([], { queryParams: { agg: parentType.toLowerCase() }, queryParamsHandling: 'merge' });

        // Get linked ICS-only data
        if (this.details.linkedReportId) {
          this.getLinkedData(this.data, this.details.linkedReportId, parentType, categoryIds, null, null, currentDefault);
        } else {
          // or recreate chart and map
          this.createBarChart(this.selected);
          this.createLineChart(this.data.reverse());
          this.createMap(this.selected, this.period, this.averages, parentType);

          // Finish load
          this.loading = false;
        }

      },
      e => {
        console.log(e);
      }
    )
  }

  getLinkedData(data, linkedReportId, parentType, categories?, organisationId?, tierId?, currentDefault?) {

    // Set categoryIds for API call
    let categoryIds;
    categories ? categoryIds = categories.map(c => c.categoryId) : null;

    // Get linked data
    this.subscriptions.push(
      this.dataService.getData(linkedReportId, categoryIds, organisationId, tierId).subscribe(
        r => {
          // Set data
          let linked_data: any = this.formatService.data(r.data, currentDefault ? currentDefault.preferenceItem : null);

          // Combine ICB and aggregated data, prioritising ICB data
          let combined_data = [];
          data.forEach(agg_data => {
            let icb_data = linked_data.find(linked => linked.periodId == agg_data.periodId);
            if (icb_data) { 
              combined_data.push(icb_data);
            } else {
              combined_data.push(agg_data);
            }
          });
          
          // Set the combined data
          this.data = combined_data;

          // Get selected
          this.selected = this.data.find(d => d.periodId === this.period.periodId);
          this.averages = this.formatService.averages(this.selected.submissions);
          
          // Recreate chart and map
          this.createBarChart(this.selected);
          this.createLineChart(this.data.reverse());
          this.createMap(this.selected, this.period, this.averages, parentType);

          // Finish load
          this.loading = false;
        },
        e => { 
          console.log('Error', e);
        }
      )
    )
  }

  getSecondary(reportId, tierId?, currentDefault?) {

    // Reset
    this.chartScatterOptions = null;
    this.error = null;
    this.r2Value = null;
    this.secondaryData = null;
    this.secondaryDetails = null;
    this.data_saved = null;
    this.data = JSON.parse(JSON.stringify(this.data_raw));

    // Close menu dropdown
    this.toggleDrop('any');

    // Start loading
    this.loading = true;

    this.dataService.getDetails(reportId).subscribe(
      r => { 
        // Set secondary details
        this.secondaryDetails = this.formatService.details(r.data);
        this.secondaryDetails.reportId = reportId;

        // Aggregation check
        let allowableTypes = ['LA', 'CCG', 'ICS'];
        let primary = this.details.organisationType;
        let secondary = this.secondaryDetails.organisationType;

        if (allowableTypes.includes(primary) && allowableTypes.includes(secondary)) {
          // Get secondary data
          this.getSecondaryData(reportId, tierId, currentDefault);
          // Add to URL params
          this.router.navigate([], { queryParams: { sec: reportId }, queryParamsHandling: 'merge' });
        } else {
          this.errorInline = 'Only LA, CCG and ICS reports can be used for primary/secondary comparisons. Please select a different secondary metric.'
          this.loading = false;
        }
        
      },
      e => {
        console.log(e);
        this.errorInline = 'This report does not exist.'
      }
    )
  }

  getSecondaryData(reportId, tierId, currentDefault?) {
    this.dataService.getData(reportId, null, null, tierId).subscribe(
      r => {
        this.secondaryData = this.formatService.data(r.data, currentDefault ? currentDefault.preferenceItem : null);

        // Remove periods with no submission data (e.g. Wales-only data with England default)
        this.secondaryData = this.secondaryData.filter(data => data.submissions.length);

        // Check for different types and aggregate both
        let typeCheck = this.details.organisationType === this.secondaryDetails.organisationType ? true : false; 

        if (this.secondaryData.length && !typeCheck) {
          
          let primaryAggregations, secondaryAggregations;

          this.dataService.getRelationships('ICS to ' + this.details.organisationType).subscribe(
            r => {

              // Get primary relationships and aggregations
              let relationships = this.formatService.relationships(r.data.yearRelationshipList);
              primaryAggregations = this.formatService.aggregate(this.data, relationships, this.details.aggregationType, null, this.details);

              this.dataService.getRelationships('ICS to ' + this.secondaryDetails.organisationType).subscribe(
                r => {

                  // Get secondary relationships and aggregations
                  let relationships = this.formatService.relationships(r.data.yearRelationshipList);
                  secondaryAggregations = this.formatService.aggregate(this.secondaryData, relationships, this.details.aggregationType, null, this.details);

                  // If primary or secondary is already ICS, override
                  if (this.details.organisationType === 'ICS') {
                    primaryAggregations.forEach(pa => {
                      pa.aggregated = pa.submissions;
                    });
                  }
                  if (this.secondaryDetails.organisationType === 'ICS') {
                    secondaryAggregations.forEach(sa => {
                      sa.aggregated = sa.submissions;
                    });
                  }

                  // Match available dates: return primary and secondary dates
                  primaryAggregations = this.formatService.periodMatch(primaryAggregations, secondaryAggregations, true)['data'];
                  secondaryAggregations = this.formatService.periodMatch(primaryAggregations, secondaryAggregations, true)['secondaryData'];

                  // Set the data (different organisation types)   
                  this.data = this.formatService.combineAggregated(primaryAggregations, secondaryAggregations);

                  // Save a copy of details and data to local arrays
                  // TODO: Move to format.service
                  this.data_saved = JSON.parse(JSON.stringify({
                    primaryData: this.primary_data_saved[0].submissions,
                    secondaryData: this.secondaryData[0].submissions,
                    primaryAggregated: this.orderBy.transform(this.data[0].primaryAggregated, '-submissionResult'),
                    secondaryAggregated: this.orderBy.transform(this.data[0].secondaryAggregated, '-submissionResult'),
                  }));

                  this.selected = this.data[0];
                  this.createScatterChart(this.selected, true);
                },
                e => {
                  console.log(e);
                  this.error = 'There has been an error retrieving data for the secondary report. (Report ID: ' + this.secondaryDetails.reportId + ')';
                }
              )
            },
            e => {
              console.log(e);
              this.error = 'There has been an error retrieving data for the primary report. (Report ID: ' + this.details.reportId + ')';
            }
          )
        } else if (this.secondaryData.length && typeCheck) {

          // TODO: Match available dates: return primary and secondary dates
          this.data = this.formatService.periodMatch(this.data, this.secondaryData, false)['data'];
          this.secondaryData = this.formatService.periodMatch(this.data, this.secondaryData, false)['secondaryData'];

          // Set the data (same organisation types)
          this.data = this.formatService.combine(this.data, this.secondaryData);

          // TODO: Move to format.service
          this.primary_details_saved = JSON.parse(JSON.stringify(this.details));
          this.primary_data_saved = JSON.parse(JSON.stringify(this.data));
          this.data_saved = JSON.parse(JSON.stringify({
            primaryData: this.data[0].submissions,
            secondaryData: this.secondaryData[0].submissions,
          }));

          this.selected = this.data[0];
          this.createScatterChart(this.selected, false);

        } else {
          this.error = 'There is no data for the secondary report. (Report ID: ' + this.secondaryDetails.reportId + ')';
        }
      },
      e => {
        console.log(e);
        this.error = 'There is no data for the secondary report. (Report ID: ' + this.secondaryDetails.reportId + ')';
      },
      () => {
        this.loading = false;
      }
    )
  }

  getSeries(categoryTypeId, period) {
    this.loading = true;
    this.toggleDrop('any');

    let categoryIds;
    // For independent categories, pass all categories
    // TODO: Check API for IC check; currently returning single series
    if (this.details.options.includes('IC')) {
      this.details.categories.length ? categoryIds = this.details.categories.map(c => c.categoryId) : null;
    } else {
      this.categories ? categoryIds = this.categories.map(c => c.categoryId) : null;
    }

    this.dataService.getSeriesData(this.details.reportId, this.tierId, categoryTypeId, categoryIds).subscribe(
      r => {

        if (r.data.availableDates.length) {

          // Get base level series data
          let seriesData: any = this.formatService.seriesData(r.data, this.categories, categoryTypeId);

          // Aggregate series data, if necessary
          if (this.details.aggregation) {
            let aggregatedSeriesData: any = this.formatService.aggregateSeries(seriesData, this.relationships, this.categories, categoryTypeId, this.details.aggregationType);
            let selected = aggregatedSeriesData.find(d => d.periodId === period.periodId);
            this.createStackedColumn(selected.aggregatedCategories, selected.aggregatedSeries);
            this.seriesData = aggregatedSeriesData;
          } else {
            if (this.highlights) {
              seriesData = this.formatService.highlightSeries(seriesData, this.categories, categoryTypeId, this.highlights);
              let selected = seriesData.find(d => d.periodId === period.periodId);
              this.createStackedColumn(selected.categories, selected.series);
              this.seriesData = seriesData;
            } else {
              this.errorInline = 'Series data is not available for the filters selected.';
              this.loading = false;
            }
          }

          // Set selected categories
          this.selectedSeries = this.categories.find(c => c.categoryTypeId === categoryTypeId);

          // Create national stacked column
          let currentPeriod = this.seriesData.find(sd => sd.periodId == this.period.periodId);
          let series = [];
          currentPeriod.mean.forEach(m => {
            series.push({
              name: m.categoryName,
              data: [ m.result ]
            })
          })
          this.createNationalStackedColumn(series);

          // Complete load
          this.loading = false;

        } else {
          this.errorInline = 'Series data is not available for this category type.';
          this.loading = false;
        }

      },
      e => {
        console.log(e);
        this.errorInline = 'Series data is not available for this category type.';
      },
      () => {
        this.loading = false;
      }
    )
  }

  // SELECTS

  selectPeriod(period) {
    // Set current period data
    this.selected = this.data.find(d => d.periodId === period.periodId);

    // Set averages
    this.averages = this.formatService.averages(this.selected.submissions);

    // Create chart and map
    this.createBarChart(this.selected);
    this.createMap(this.selected, period, this.averages, this.details.aggregation ? this.details.aggregation : this.details.organisationType);

    // Redraw stacked bar chart
    if (this.seriesData) {
      let selected = this.seriesData.find(d => d.periodId === period.periodId);

      let categories, series;
      if (this.details.aggregation) {
        categories = selected.aggregatedCategories;
        series = selected.aggregatedSeries;
      } else {
        categories = selected.categories;
        series = selected.series;
      }

      this.createStackedColumn(categories, series);
    }

    this.filtered = false;
    // Select period and add to params
    this.period = this.data.find(d => d.periodId === period.periodId);
    this.router.navigate([], { queryParams: { per: period.periodId }, queryParamsHandling: 'merge' });
  }

  selectCategory(category) {
    let index = this.categories.indexOf(category);
    if (this.details.options.includes('IC')) {
      this.categories = [];
      this.details.categories.forEach(c => {
        c.selected = false;
      })
    }
    if (category && index < 0) {
      category.selected = true;
      this.categories.push(category);
    } else if (category) {
      category.selected = false;
      this.categories = this.categories.filter(c => c.categoryId !== category.categoryId);
    }
    if (this.details.options.includes('IC')) {
      this.independentCategory = this.categories[0];
    } else {
      this.independentCategory = null;
    }
  }

  selectAll() {
    if (!this.details.options.includes('IC')) {
      this.categories = [];
      this.details.categories.forEach(c => { 
        c.selected = true;
        this.categories.push(c);
      });
    }
  }

  selectArea(type, event) {
    // Get matching area, if aggregated
    if (this.details.aggregation && this.selected.aggregatedData && event) {

      // Get onsCode
      let onsCode;
      if (type === 'map') {
        onsCode = event.features[0].properties.onsCode;
      } else if (type === 'chart') {
        onsCode = event.point.category;
      } else if (type === 'scatter') {
        onsCode = event.point.code;
      } else {
        onsCode = event;
      }

      // Get area from selected data
      let area = onsCode ? this.selected.submissions.find(s => s.organisationOnsCode === onsCode) : null;
      if (area) {
        this.constituents = area;
        this.toggleDrop('constituents');
      }

    }
  }

  selectDefault(item: any) {

    let currentDefault = this.preferences.selected_default;

    // If current default, remove it
    if (currentDefault) {
      this.dataService.removePreference(currentDefault.preferenceId).subscribe(
        r => {
          // Remove from submissions
          let currentSubmission = this.selected.submissions.find(s => s.organisationOnsCode == currentDefault.preferenceItem);
          if (currentSubmission) { 
            currentSubmission.preferenceDefault = null;
          }

          // Remove from organisations
          let currentOrganisation = this.organisations.find(s => s.organisationOnsCode == currentDefault.preferenceItem);
          currentOrganisation.preferenceDefault = null;
          this.preferences.selected_default = null;
        },
        e => {
          console.log(e);
        }
      )
    }

    // Add new default and replace locally
    let type = this.details.aggregation ? this.details.aggregation.toLowerCase() : this.details.organisationType.toLowerCase();

    let postPreference = {
      preferenceName: 'default_' + type,
      preferenceItem: item.organisationOnsCode
    }

    this.dataService.setPreference(postPreference).subscribe(
      r => {
        let preferenceId = r.data.newPreferenceId;
        item.preferenceDefault = preferenceId;
        this.preferences.selected_default = {
          preferenceId: preferenceId,
          preferenceName: postPreference.preferenceName,
          preferenceItem: postPreference.preferenceItem
        };
      },
      e => {
        console.log(e);
      }
    )

  }

  // CREATE

  createBarChart(data) {
    this.chartBarOptions = {
      chart: {
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: '#FFFFFF',
        events: {
          load: ((e) => { 
            this.chartBar = e.target;
          })
        },
      },
      colors: [''],
      tooltip: {
        enabled: false
      },
      title: {
          text: ''
      },
      exporting: { 
        enabled: false,
        chartOptions: {
          title: {
            text: this.details.reportName
          },
          subtitle: {
            text: this.independentCategory ? this.independentCategory.categoryName : ''
          }
        }
      },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        labels: { enabled: false },
        categories: data.submissions.map(s => s.organisationOnsCode),
        gridLineColor: '#EEF1F8'
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false,
        plotLines: [{
          color: '#00A878',
          value: data.mean,
          width: 2,
          zIndex: 5
        }],
        labels: {
          formatter: ((e) => {
            if (this.details.formatModifier === 'P') {
              return e.value + '%'
            } else {
              return e.value
            }
          })
        }
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          },
          point: {
            events: {
              mouseOver: e => {
                this.toggleHover('chart', e);
              },
              mouseOut: e => {
                this.toggleHover('chart', e);
              },
              click: e => {
                this.selectArea('chart', e);
              }
            }
          }
        }
      },
      series: [
        {
          type: 'column',
          name: 'Organisations',
          color: '#005EB8',
          data: data.submissions.map(s => { return { 
            y: s.submissionResult,
            color: s.color ? s.color : null
          }}),
          states: {
            hover: {
              color: '#F5A623'
            }
          }
        }
      ]
    }
    // Allow stackable
    if (this.details.categories.length && data.submissions.length < 100 && !this.details.options.includes('IC') && this.details.organisationType !== 'Ambulance') {
      this.stackable = true;
    } else {
      this.stackable = false;
    }
    // Set available preferences
    if (this.details.aggregation || ['LA', 'CCG', 'ICS'].includes(this.details.organisationType)) {
      this.highlightable = true;
      this.preferences = this.formatService.updatePreferences(this.preferences, this.details.aggregation ? this.details.aggregation : this.details.organisationType, data.submissions);
    } else {
      this.highlightable = false;
    }
    // Set highest/lowest values for selected period
    this.highestLowest = {
      national: {
        highest: data.submissions[0],
        lowest: data.submissions[data.submissions.length - 1]
      },
      group: null
    }
  }

  createLineChart(data: any) {

    // Order by periodFrom
    data = this.orderBy.transform(data, 'periodFrom');

    this.chartLineOptions = {
      chart: {
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: '#FFFFFF',
        events: {
          load: ((e) => { 
            this.chartLine = e.target;
          })
        },
      },
      colors: [''],
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;font-weight:bold">{point.x}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:10px"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' },
        shared: true
      },
      title: {
          text: ''
      },
      exporting: { 
        enabled: false,
        chartOptions: {
          title: {
            text: this.details.reportName
          },
          subtitle: {
            text: this.independentCategory ? this.independentCategory.categoryName : ''
          }
        }
       },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: data.map(d => d.periodName),
        gridLineColor: '#EEF1F8'
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false,
        labels: {
          formatter: ((e) => {
            if (this.details.formatModifier === 'P') {
              return e.value + '%'
            } else {
              return e.value
            }
          })
        }
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      series: [
        {
          type: 'line',
          name: 'National Mean',
          color: this.nationalMeanColour,
          data: data.map(d => d.mean),
          marker: { enabled: true },
          states: {
            hover: {
              color: '#F5A623'
            }
          }
        },
        {
          type: 'line',
          name: 'Organisation Value',
          color: this.organisationValueColour,
          data: data.map(d => d.defaultResult),
          marker: { enabled: true },
          states: {
            hover: {
              color: '#F5A623'
            }
          }
        }
      ]
    }
  }

  createScatterChart(data, aggregated) {
    let scatterData: any = [ { name: 'Combined Data', data: [] } ],
        minX, maxX;

    // Set default ONS code for highlight
    let organisationType, defaultOnsCode;
    
    if (aggregated) {
      organisationType = 'ics';
    } else {
      organisationType = this.details.organisationType.toLowerCase();
    }

    if (this.preferences['default_' + organisationType]) { 
      defaultOnsCode = this.preferences['default_' + organisationType].preferenceItem
    }

    // Build data
    data.combined.forEach(s => {
      scatterData[0].data.push({
        name: s.organisationName,
        code: s.organisationOnsCode,
        x: s.submissionResult,
        y: s.secondaryResult,
        color: s.color ? s.color : (s.organisationOnsCode == defaultOnsCode ? '#E03616' : '#005EB8')
      })
    });

    if (scatterData[0].data.length) {
      // Set min/max on x-axis
      minX = scatterData[0].data.map(s => s.x).reduce((a, b) => Math.min(a, b));
      maxX = scatterData[0].data.map(s => s.x).reduce((a, b) => Math.max(a, b));

      this.chartScatterOptions = {
        title: { text: '' },
        exporting: { 
          enabled: false,
          chartOptions: {
            title: {
              text: this.details.reportName
            },
            subtitle: {
              text: this.independentCategory ? this.independentCategory.categoryName : ''
            }
          }
        },
        legend: { enabled: false },
        credits: { enabled: false },
        tooltip: {
          useHTML: true,
          backgroundColor: '#000000',
          borderWidth: 0,
          valueDecimals: 2,
          shadow: false,
          headerFormat: '<div>',
          pointFormat: '<div style="font-size:14px;padding:2px 0"><span style="font-weight:bold">{point.name}</span><br>{point.code}</div><div style="padding:2px 0">{point.x:.0f}</div><div style="padding:2px 0">{point.y:.0f}</div>',
          footerFormat: '</div>',
          style: { color: '#FFFFFF' },
          shared: true
        },
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          animation: false,
          style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
          backgroundColor: '#FFFFFF',
          events: {
            load: ((e) => { 
              this.chartScatter = e.target;
              this.rSquared();
              this.regression();
            })
          },
        },
        yAxis: {
          gridLineColor: '#EEF1F8',
          title: {
            text: this.secondaryDetails.reportName,
            margin: 20
          },
        },
        xAxis: {
          title: {
            text: this.details.reportName,
            margin: 20
          },
          min: minX,
          max: maxX
        },
        plotOptions: {
          series: {
            point: {
              events: {
                // click: e => {
                //   this.selectArea('scatter', e);
                // }
              }
            }
          }
        },
        series: scatterData
      }

      // Show primary data by default
      this.toggleSelected('primary', aggregated); 
    } else {
      this.error = 'It has not been possible to show this Theme with the default organisation selected.'
    }
  }

  createStackedColumn(categories, series) {

    series = this.orderBy.transform(series, 'name');

    this.chartStackedColumnOptions = {
      title: { text: '' },
      exporting: { 
        enabled: false,
        chartOptions: {
          title: {
            text: this.details.reportName
          },
          subtitle: {
            text: this.independentCategory ? this.independentCategory.categoryName : ''
          }
        }
       },
      credits: { enabled: false },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.x}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:0.25em;"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' },
        shared: true
      },
      chart: {
        type: 'column',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: '#FFFFFF',
        events: {
          load: ((e) => { 
            this.chartStackedColumn = e.target;
          })
        },
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          enabled: false
        },
        labels: {
          formatter: ((e) => {
            return e.value + '%'
          })
        }
      },
      xAxis: {
        categories: categories,
        labels: {
          enabled: false
        }
      },
      legend: {
        align: 'right'
      },
      plotOptions: {
        column: {
            stacking: 'percent'
        }
      },
      series: series
    }
  }

  createNationalStackedColumn(series) {

    series = this.orderBy.transform(series, 'name');

    this.chartNationalStackedColumnOptions = {
      title: { text: '' },
      exporting: { 
        enabled: false,
        chartOptions: {
          title: {
            text: this.details.reportName
          },
          subtitle: {
            text: this.independentCategory ? this.independentCategory.categoryName : ''
          }
        }
       },
      credits: { enabled: false },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">National</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square" style="color:{series.color};margin-right:0.25em;"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' },
        shared: true
      },
      chart: {
        type: 'column',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: '#FFFFFF',
        events: {
          load: ((e) => { 
            this.chartStackedColumn = e.target;
          })
        },
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: this.details.reportName,
          margin: 20
        },
        labels: {
          formatter: ((e) => {
            return e.value + '%'
          })
        }
      },
      xAxis: {
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
            stacking: 'percent'
        }
      },
      series: series
    }
  }

  createMap(results, period, averages?, type?) {

    this.map = null;
    this.mapOptions = null;
    this.point = null;
    this.details.mapUrl = null;
    type = type.toLowerCase();

    // TODO: Move newUrl to format.map (including overrides)
    let newYear;
    let newUrl;
    
    if (results.periodFy >= 2425) {
      newYear = '_24'
    } else if (results.periodFy < 2021 && (type === 'ccg' || type === 'la')) {
      newYear = ''
    }
    else {
      newYear = '_' + results.periodFy.toString().substring(0, 2);
    }
  
    // CAMHS override
    if (this.details.options.includes('CM')) {
      type = 'camhs'
    }

    // QOF 19/20 override
    if (this.details.reportName.substring(0,3) == 'QOF' && results.periodFy == 1920) {
      newYear = '_20'
    }

    // QOF 20/21 override
    if (this.details.reportName.substring(0,3) == 'QOF' && results.periodFy == 2021) {
      newYear = '_21'
    }

    // QOF 21/22 override
    if (this.details.reportName.substring(0,3) == 'QOF' && results.periodFy == 2122) {
      newYear = '_22'
    }

    let periods = this.orderBy.transform(this.data, 'periodFy');
    // Use map one year beyond data period (latest period only)
    if (this.details.options.includes('YP1') && periods[periods.length-1].periodFy == results.periodFy) {
      let yearPlus1 = results.periodFy + 101;
      if (yearPlus1 < 2021 && (type === 'ccg' || type === 'la')) {
        newYear = '_' + (yearPlus1 + 202).toString().substring(0,2);
      } else {
        newYear = '_' + yearPlus1.toString().substring(0,2);
      }
    }
    // Use map one year behind data period (latest period only)
    if (this.details.options.includes('YM1') && periods[periods.length-1].periodFy == results.periodFy) {
      let yearMinus1 = results.periodFy - 101;
      if (yearMinus1 < 2021 && (type === 'ccg' || type === 'la')) {
        newYear = ''
      } else {
        newYear = '_' + yearMinus1.toString().substring(0,2);
      }
    }

    // CCG/sub-ICB override for April-June 2022 (intermediary data period)
    if (['Apr-22','May-22','Jun-22'].includes(period.periodName) && (type == 'ccg' || type == 'ics')) {
      newYear = '_21'
    }

    // Create URL
    newUrl = 'assets/maps/map_' + type + newYear + '.json';

    // ICS URL override
    if (type === 'ics') {
      newUrl = 'assets/maps/map_ics_24.json'
    }

    // ICS archive override
    if (this.details.options.includes('IA')) {
      newUrl = 'assets/maps/map_ics_archive.json'
    }

    // Local Authority Region override
    if (type === 'lar') {
      newUrl = 'assets/maps/map_lar_19.json'
    }

    // Add map URL to details
    this.details.mapUrl = newUrl;
    this.infoPanel.mapUrl = this.details.mapUrl;

    if (newUrl) {
      this.http.get(newUrl).subscribe(
        (geojson: any) => {
  
          // Add value to matching geojson feature (if available)
          geojson.features.forEach(g => {
            let organisation = results.submissions.filter(c => c.organisationOnsCode === g.properties.onsCode)[0];
            if (organisation) {
              organisation.mapArea = true;
              g.properties.value = organisation.submissionResult || null;
            }
          });

          // Limit to just organisations with values
          let values: any = {
            type: "FeatureCollection",
            features: geojson.features.filter(f => f.properties.value)
          }
  
          // Add comparisons counts to details
          this.details.noMapAreas = values.features.length;
          this.details.noResults = results.submissions.length;
          this.infoPanel.mapUrl = this.details.mapUrl;
          this.infoPanel.noMapAreas = this.details.noMapAreas;
          this.infoPanel.noResults = this.details.noResults;

          // Set bounds
          let bounds = extent(values);
  
          // Set fill color based on averages
          let fillColor;
          const stops = [ averages.min, averages.quartile25, averages.median, averages.quartile75 ];
          let duplicates = checkForDuplicates(stops);

          // Duplicate check
          function checkForDuplicates(array) {
            return new Set(array).size !== array.length;
          }

          // Set colors
          if (duplicates) {
            fillColor = '#0D5EB8'
          } else {
            fillColor = [
              'interpolate',
              ['exponential', 0],
              ['get', 'value'],
              averages.min, '#C2D7ED',
              averages.quartile25, '#86AED8',
              averages.median, '#4A87CA',
              averages.quartile75, '#0D5EB8'
            ]
          }

          // Set default
          let defaultOrganisation = results.submissions.find(s => s.color === '#E03616');
          let defaultOnsCode = defaultOrganisation ? defaultOrganisation.organisationOnsCode : '';
          
          // Build Mapbox map
          this.mapOptions = {
            style: 'mapbox://styles/dhughesbmc/ck49sb7ni05c51cn4axz029os',
            bounds: bounds,
            boundsOptions: {
              padding: { top: 20, bottom: 20, left: 20, right: 20 }
            },
            type: 'fill',
            logoPosition: 'top-right',
            paint: {
              'fill-color': fillColor,
              'fill-outline-color': '#FFFFFF',
              'fill-opacity': 0.75
            },
            hover: {
              'line-color': '#f5a63a',
              'line-width': 2
            },
            source: {
                type: 'geojson',
                data: values
            },
            default: {
              'line-color': '#e03616',
              'line-width': 2
            },
            defaultFilter: ['==', 'onsCode', defaultOnsCode],
            filter: ['==', 'onsCode', '']
          }
  
        },
        e => {
          console.log('No map', e);
          this.mapOptions = null;
          this.noMapbox = true;
        }
      );
    } else {
      console.log('No map');
      this.noMapbox = true;
    }
    
  }

  createPreferences() {

    let group = this.preferences.selected_group;

    let organisationType = this.details.organisationType === 'LA' ? 'Local Authority' : this.details.organisationType;
    this.dataService.getOrganisations(this.details.aggregation ? this.details.aggregation : organisationType).subscribe(
      r => {
        this.organisations = r.data.organisationList;
        
        // Add to organisations list
        this.organisations.forEach(d => {
          if (this.preferences.selected_default && d.organisationOnsCode === this.preferences.selected_default.preferenceItem) {
            d.preferenceDefault = this.preferences.selected_default.preferenceId;
          }
          if (group) {
            let findPreference = group.find(g => g.preferenceItem === d.organisationOnsCode);
            if (findPreference) {
              d.preferenceGroup = findPreference.preferenceId;
            }
          }
        })

        // Add to current submissions
        this.selected.submissions.forEach(d => {
          if (this.preferences.selected_default && d.organisationOnsCode === this.preferences.selected_default.preferenceItem) {
            d.preferenceDefault = this.preferences.selected_default.preferenceId;
          }
          if (group) {
            let findPreference = group.find(g => g.preferenceItem === d.organisationOnsCode);
            if (findPreference) {
              d.preferenceGroup = findPreference.preferenceId;
            }
          }
        });

      },
      e => {
        console.log(e);
        this.errorInline = 'There has been an error retrieving your preferences. Please try refreshing the page.'
      }
    )

    // TODO: Save to sessionStorage by type

  }

  // TOGGLES

  toggleHighlight(type: string) {

    let highlights;

    if (this.filtered === true) {
      this.toggleFilter('off');
    }

    // Neighbour
    if (type === 'neighbour') {
      this.dataService.getNeighbours(this.preferences.selected_default.organisationId).subscribe(
        r => {
          let neighbourOrganisations: any = this.formatService.neighbours(r.data.neighbourYears);
          highlights = neighbourOrganisations.map(o => o.organisationOnsCode);
          this.highlight(type, highlights);
        },
        e => {
          console.log(e);
          this.errorInline = 'There has been an error retrieving the neighbour organisations.'
        }
      )
    }

    // Region
    if (type === 'region') {
      let organisationType = this.details.organisationType === 'LA' ? 'Local Authority' : this.details.organisationType;
      this.dataService.getOrganisations(this.details.aggregation ? this.details.aggregation : organisationType).subscribe(
        r => {
          let organisations = r.data.organisationList;
          if (organisations) {
            let regionOrganisations: any = this.formatService.regions(r.data.organisationList, this.preferences.selected_default.preferenceItem);
            highlights = regionOrganisations.map(o => o.organisationOnsCode);
            this.highlight(type, highlights);
          } else {
            this.errorInline = 'There is no region set for your default organisation.'
          }
        },
        e => {
          console.log(e);
          this.errorInline = 'There has been an error retrieving the region organisations.'
        }
      )
    }

    // ICS
    if (type === 'ics') {
      this.dataService.getRelationships('ICS to ' + this.details.organisationType).subscribe(
        r => {
          let relationships: any = this.formatService.relationships(r.data.yearRelationshipList);
          let currentPeriod = relationships.find(r => r.periodFy == this.period.periodFy);
          let defaultIcs, highlights;
          if (currentPeriod) {
            defaultIcs = currentPeriod.relationships.filter(cp => cp.childOnsCode == this.preferences.selected_default.preferenceItem)[0].parentOnsCode
          }
          if (defaultIcs) {
            highlights = currentPeriod.relationships.filter(cp => cp.parentOnsCode == defaultIcs);
            highlights = highlights.map(o => o.childOnsCode);
          }
          if (highlights != undefined) {
            this.highlight(type, highlights);
          } else {
            this.errorInline = 'It has not been possible to highlight the ICS for your current default organisation.'
          }
        },
        e => {
          console.log(e);
        }
      )
    }

    // Custom Group
    if (type === 'custom_group') {
      highlights = this.preferences.selected_group.map(g => g.preferenceItem);
      this.highlight(type, highlights);
    }

  }

  highlight(type, highlights) {

    let arrayName = this.secondaryDetails ? 'combined' : 'submissions';

    let timeSeriesChart = this.chartLine;
    this.point = null;

    // If highlighted, reset colours to original values
    if (type === this.highlighted) {

      this.highlighted = null;
      this.highlights = null;

      this.data.forEach(d => {
        d[arrayName].forEach(s => {
          s.color = '#005EB8'
        });
      });

      if (!this.secondaryDetails) {
        let seriesIndex = timeSeriesChart.series.findIndex(s => s.name === 'Group Mean');
        if (timeSeriesChart.series[seriesIndex]) {
          timeSeriesChart.series[seriesIndex].remove();
        };
      }

      this.highestLowestGroup = null;

    } else {

      let group;

      this.data.forEach(d => {
        d[arrayName].forEach(s => {
          if (highlights && highlights.includes(s.organisationOnsCode)) {
            s.color = '#005EB8';
            s.highlighted = true;
          } else {
            s.color = '#CEDFF0';
            s.highlighted = false;
          }
        });
      });

      // Set group averages for each period
      this.data.forEach(d => {
        group = d[arrayName].filter(s => s.highlighted);
        if (group.length) {
          let groupAverages: any = this.formatService.averages(group);
          d.groupMean = groupAverages.mean;
        }
      });

      // Add to time series
      if (group.length && !this.secondaryDetails) {
        timeSeriesChart.addSeries({
          type: 'line',
          name: 'Group Mean',
          color: '#005EB8',
          data: this.data.map(d => d.groupMean),
          marker: { enabled: true },
          states: {
            hover: {
              color: '#F5A623'
            }
          }
        }, true);
      }

      // TODO: Add group average line to bar chart (addPlotLine not working)

      // Save highlights for seriesData
      this.highlighted = type;
      this.highlights = highlights;

    }

    // Always set default back to red
    if (this.preferences.selected_default) {
      this.data.forEach(d => {
        let defaultOrg = d[arrayName].find(s => s.organisationOnsCode === this.preferences.selected_default.preferenceItem);
      if (defaultOrg) {
        defaultOrg.color = '#E03616';
        defaultOrg.highlighted = true;
      }
      })
    }

    // Add highest and lowest for group for selected period
    let group = this.selected.submissions.filter(s => s.highlighted);
    this.highestLowestGroup = {
      highest: group[0],
      lowest: group[group.length - 1]
    }

    // Redraw bar chart
    this.createBarChart(this.selected);

    // Redraw scatter chart, if visible
    if (this.secondaryDetails) {
      this.createScatterChart(this.selected, false);
    }

  }

  toggleFilter(toggle: string) {

    if (toggle === 'on') {
      // TOGGLE ON
      this.filtered = true;
      // 1. Create copy of submissions (allSubmissions);
      this.selected.allSubmissions = JSON.parse(JSON.stringify(this.selected.submissions));
      // 2. Make submissions = highlighted
      let highlighted = this.selected.submissions.filter(s => s.highlighted == true);
      this.selected.submissions = highlighted;
      // 3. Recreate bar chart
      this.createBarChart(this.selected);
      this.createMap(this.selected, this.period, this.averages, this.details.aggregation ? this.details.aggregation : this.details.organisationType);
    }

    if (toggle === 'off') {
      this.filtered = false;
      // TOGGLE OFF
      // 1. Revert submissions back to allSubmissions
      this.selected.submissions = this.selected.allSubmissions;
      // 3. Recreate bar chart
      this.createBarChart(this.selected);
      // 4. Recreate map (standard zoom)
      this.createMap(this.selected, this.period, this.averages, this.details.aggregation ? this.details.aggregation : this.details.organisationType);
    }

  }

  // TODO: Incorporate this, or not?
  toggleOrganisationHighlighting() {

    let highlights = this.selected.submissions.filter(s => s.highlighted == true);

    highlights.forEach((h, i) => {
      if (h.color !== '#E03616') {
        h.color = this.colors[i];
      }
    })

    // Redraw bar chart
    this.createBarChart(this.selected);

  }

  // TODO: toggleCountry(E, W) {}

  toggleDrop(type) {
    if (this.drop.preferences && type === 'any') {
      Object.keys(this.drop).forEach(d => this.drop[d] = false);
      this.getPreferences(true);
      this.getData(this.details.reportId, this.period.periodId, this.categories); 
    } else {
      if (type === 'any') {
        Object.keys(this.drop).forEach(d => this.drop[d] = false);
        this.tiers.forEach(t => t.open = false);
      } else {
        this.drop[type] = true;
        this.drop.any = true;
      }
    }
    if(this.infoPanel)
      this.infoPanel.info = this.drop.info;
  }
  
  toggleTier(tier, index) {
    if (tier.open) {
      tier.open = false;
      this.toggleDrop('any');
    } else {
      this.toggleDrop('tiers');
      this.tiers.forEach(t => t.open = false);
      tier.open = true;
    }
    if (index > 4) {
      this.topTierRight = true;
    } else {
      this.topTierRight = false;
    }
  } 

  toggleHover(type, event?) {

    let chart = this.chartBar;
    
    // Define codes and points
    let onsCode, organisation, points, point;

    // Reset points
    if (this.mapOptions && this.chartBarOptions) {
      this.mapOptions.filter = ['==', 'onsCode', ''];
      this.point ? this.point.setState('normal') : null;
      points = chart.series[0] ? chart.series[0]['points'] : null;
    }

    if (event != undefined) {

      // Find onsCode of hover
      if (points && type === 'map' && event) {
        onsCode = event.features[0].properties.onsCode;
        this.mouseX = event.originalEvent.clientX;
        this.mouseY = event.originalEvent.clientY - 100;
      } else if (type === 'chart' && event) {
        onsCode = event.target.category;
        this.mouseX = event.target.tooltipPos[0];
        this.mouseY = event.target.plotY + 200;
      } else {
        this.mapOptions.filter = ['==', 'onsCode', ''];
        this.hover = null;
      }

      // Define point and states
      if (points && this.mapOptions && this.chartBarOptions && onsCode) {
        organisation = this.selected.submissions.find(s => s.organisationOnsCode === onsCode);
        point = points.find(p => p['category'] === onsCode);
        this.mapOptions.filter = onsCode ? ['==', 'onsCode', onsCode] : this.mapOptions.filter;
        this.hover = organisation ? organisation : null;
        this.point = point;
        point.setState('hover');
      }

      if (points && type === 'chart' && event.type === 'mouseOut') {
        this.mapOptions.filter = ['==', 'onsCode', ''];
        this.hover = null;
      }
    } else {
      this.hover = null;
    }

  }

  toggleAggregation() {
    // TODO: Set dynamically based on organisationType
    let parentType = 'ICS';
    
    // Remove filters first
    this.filtered = false;
    if (this.selected.allSubmissions) {
      this.selected.submissions = this.selected.allSubmissions;
    }

    // Remove highlighting
    let chart = this.chartLine;
    let seriesIndex = chart.series.findIndex(s => s.name === 'Group Mean');
    if (seriesIndex > -1) {
      chart.series[seriesIndex].remove();
    }
    this.highlighted = null;

    // Remove stacked
    this.removeStacked();

    if (this.details.aggregation) {
      // Reset series data
      this.seriesData = null;
      this.chartStackedColumnOptions = null;
      this.seriesCategory = null;
      // Get data
      this.getData(this.details.reportId, this.period.periodId, this.categories, null, this.tierId);
      this.details.aggregation = null;
      this.router.navigate([], { queryParams: { agg: 'off' }, queryParamsHandling: 'merge' });
    } else {
      this.getAggregation(parentType, this.categories);
    }
  }

  toggleSelected(metric: string, aggregated: boolean) {    
    let details, data, selected, averages;

    if (metric == 'primary' && aggregated == false) {
      details = this.primary_details_saved;
      details.aggregation = null;
      data = this.primary_data_saved;
      data[0].submissions = this.data_saved.primaryData;
    }

    if (metric == 'secondary' && aggregated == false) {
      details = this.secondaryDetails;
      details.aggregation = null;
      data = this.secondaryData;
      data[0].submissions = this.data_saved.secondaryData;
    }

    if (metric == 'primary' && aggregated == true) {
      details = this.primary_details_saved;
      details.aggregation = 'ICS';
      data = this.primary_data_saved;
      data[0].submissions = this.data_saved.primaryAggregated;
    }

    if (metric == 'secondary' && aggregated == true) {
      details = this.secondaryDetails;
      details.aggregation = 'ICS';
      data = this.secondaryData;
      data[0].submissions = this.data_saved.secondaryAggregated;      
    }

    selected = data[0];
    averages = this.formatService.averages(selected.submissions);

    // Set 
    this.details = details;
    this.data = data;
    this.selected = selected;
    this.averages = averages;
    this.createBarChart(this.selected);
    this.createMap(this.selected, this.period, this.averages, this.details.aggregation ? this.details.aggregation : this.details.organisationType);
    this.createLineChart(this.data.reverse());

  }

  // UTILITIES

  deleteGroupItem(item: any) {
    this.dataService.removePreference(item.preferenceGroup).subscribe(
      r => {
        // Remove from submission list
        item.preferenceGroup = null;

        // Remove from preferences
        let preferenceIndex = this.preferences.selected_group.findIndex(p => p.preferenceId === item.preferenceGroup);
        this.preferences.selected_group.splice(preferenceIndex, 1);
      },
      e => {
        console.log(e);
      }
    )
  }

  addGroupItem(item: any) {

    // Define type
    let type = this.details.aggregation ? this.details.aggregation.toLowerCase() : this.details.organisationType.toLowerCase();

    // Create API POST body
    let postPreference = {
      preferenceName: 'custom_group_' + type,
      preferenceItem: item.organisationOnsCode
    }

    this.dataService.setPreference(postPreference).subscribe(
      r => {
        let preferenceId = r.data.newPreferenceId

        // Create preference to add to local array
        item.preferenceGroup = preferenceId;

        // Create a group if not already created
        if (!this.preferences.selected_group) {
          this.preferences.selected_group = [];
        }

        // Create API POST body
        this.preferences.selected_group.push({
          preferenceId: item.preferenceGroup,
          preferenceName: postPreference.preferenceName,
          preferenceItem: postPreference.preferenceItem
        })
      },
      e => {
        console.log(e);
      }
    )
  }

  openBookmarks() {
    
    // Add tier name to bookmarks via tierId, set URL and check aggregation
    if (this.preferences.bookmark) {
      this.preferences.bookmark.forEach(b => {
        let matchTier = this.search.find(s => s.searchTier == +b.preferenceItem);
        if (matchTier) {
          b.tier = matchTier;
          b.url = b.preferenceParameters;
          b.aggregated = b.preferenceParameters.includes('agg=');
        }
      });
    }

    // Open modal
    this.toggleDrop('bookmarks');
  }

  addBookmark() {

    let postPreference = {
      preferenceName: 'bookmark',
      preferenceItem: this.tierId.toString(),
      preferenceParameters: this.router.url
    }

    this.dataService.setPreference(postPreference).subscribe(
      r => {
        if (!this.preferences.bookmark) {
          this.preferences.bookmark = [];
        }
        postPreference['preferenceId'] = r.data.newPreferenceId;
        this.preferences.bookmark.push(
          postPreference
        )
        this.bookmarked = 'Bookmarked';
      },
      e => {
        console.log(e);
        this.bookmarked = 'Already bookmarked';
      }
    )
  }

  removeBookmark(item) {
    if (confirm("Are you sure you want to delete this bookmark?")) {
      this.dataService.removePreference(item.preferenceId).subscribe(
        r => {
          // Remove from preferences
          let preferenceIndex = this.preferences.bookmark.findIndex(p => p.preferenceId === item.preferenceId);
          this.preferences.bookmark.splice(preferenceIndex, 1);
          this.bookmarked = null;
        },
        e => {
          console.log(e);
        }
      )
    }
  }

  clearCustomGroup() {
    if (confirm("Are you sure to clear all organisations from your custom group?")) {
      let customGroup = this.organisations.filter(o => o.preferenceGroup);
      customGroup.forEach(cg => {
        this.deleteGroupItem(cg);
      })
    }
  }

  removeDefault() {

    let currentDefault = this.preferences.selected_default;

    // If current default, remove it
    if (currentDefault) {
      this.dataService.removePreference(currentDefault.preferenceId).subscribe(
        r => {
          let currentSubmission = this.selected.submissions.find(s => s.organisationOnsCode == currentDefault.preferenceItem);
          if (currentSubmission) {
            currentSubmission.preferenceDefault = null;
          }
          let currentOrganisation = this.organisations.find(s => s.organisationOnsCode == currentDefault.preferenceItem);
          if (currentOrganisation) {
            currentOrganisation.preferenceDefault = null;
          }
          this.preferences.selected_default = null;
        },
        e => {
          console.log(e);
        }
      )
    }
  }

  removeStacked() {
    this.chartStackedColumn = null;
    this.chartStackedColumnOptions = null;
    this.seriesCategory = null;
  }

  reorderTable(column: string) {
    this.columnOrder == '+' ? this.columnOrder = '-' : this.columnOrder = '+';
    this.selected.submissions = this.orderBy.transform(this.selected.submissions, this.columnOrder + column);
  }

  keydown(event, query) {

    // Get search results
    let search = this.filterBy.transform(this.search, ['searchName'], query);
    let keycode = event.keyCode;

    // Navigate by keyboard
    if (keycode == 40 && query !== undefined && this.count < (search.length - 1) && search.length > 0) {
      // Down
      this.count = this.count + 1;
      search.forEach(s => s.active = false);
      search[this.count].active = true;
    } else if (keycode === 38 && query !== undefined && this.count > 0 && search.length > 0) {
      // Up
      this.count = this.count - 1;
      search.forEach(s => s.active = false);
      search[this.count].active = true;
    } else if (keycode === 13) {
      // Enter
      if (search.length > 0) {
        if (this.count == -1) { this.count = 0 };
        let tier = search[this.count];
        this.getDetails(tier.reportId + '-' + tier.searchTier, tier.childrenTiers);
        this.count = -1;
      }
    } else if (keycode == 27) {
      // Escape
      this.toggleDrop('any');
      this.count = -1;
    }
  }

  unsubscribe() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  rSquared() {

    let temp = [];
    let themeData = [];
    
    this.chartScatter.series[0].data.forEach(p => {
      // Add values to themeData
      themeData.push({
        name: p['options'].name,
        x: p['options'].x,
        y: p['options'].y
      });
      // Add values to temp array
      temp.push({
        valueY: p.y,
        valueX: p.x
        // Alternative: rounded values
        // valueY: p.y ? Math.floor(p.y) : p.y,
        // valueX: p.x ? Math.floor(p.x) : p.x
      })
    });

    // Remove null values
    temp = temp.filter(d => d.valueX != null && d.valueY != null);

    // Calculate means and rData
    let meanY = temp.map(t => t.valueY).reduce((a, b) => a + b, 0) / temp.length, // y values
        meanX = temp.map(t => t.valueX).reduce((a, b) => a + b, 0) / temp.length, // x values
        rData = [];

    // Calculate differences
    temp.forEach(function(p, i) {
      rData.push({
        diffY: p.valueY - meanY,
        diffX: p.valueX - meanX,
        diffXY: (p.valueX - meanX) * (p.valueY - meanY),
        diffXX: (p.valueX - meanX) * (p.valueX - meanX),
        diffYY: (p.valueY - meanY) * (p.valueY - meanY)
      })
    });
    
    // Numerator (sum of XY differences)
    let num = rData.map(d => d.diffXY).reduce((a, b) => a + b, 0);

    // Denominator (square-root of sum of YY differences multiplied by sum of XX differences)
    let denom = rData.map(d => d.diffYY).reduce((a, b) => a + b, 0) * rData.map(d => d.diffXX).reduce((a, b) => a + b, 0);
        denom = Math.sqrt(denom);

    // R-value (numerator divided by denominator)
    let rValue = num / denom;

    // R-squared
    this.r2Value = (rValue * rValue).toFixed(4);

  }

  regression() {

    let data = this.chartScatter.series[0].data.filter(d => d.x != null && d.y != null),
        values_x = data.map(d => d.x),
        values_y = data.map(d => d.y);

    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var count = 0;
    var x = 0;
    var y = 0;
    var values_length = values_x.length;

    if (values_length != values_y.length) {
      throw new Error('The parameters values_x and values_y need to have same length.');
    }

    if (values_length === 0) {
        return [ [], [] ];
    }

    for (var v = 0; v < values_length; v++) {
        x = values_x[v];
        y = values_y[v];
        sum_x += x;
        sum_y += y;
        sum_xx += x*x;
        sum_xy += x*y;
        count++;
    }

    var m = (count*sum_xy - sum_x*sum_y) / (count*sum_xx - sum_x*sum_x);
    var b = (sum_y/count) - (m*sum_x)/count;

    var result = [];

    for (var v = 0; v < values_length; v++) {
        x = values_x[v];
        y = x * m + b;
        result.push([x, y]);
    }

    result = result.sort((a,b) => a[0] - b[0]);

    // Add line to chart
    this.chartScatter.addSeries({
      type: 'line',
      name: 'regression',
      data: result,
      color: '#DA291C',
      enableMouseTracking: false,
      marker: {
        enabled: false
      }
    })
  }

  copyClipboard(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.copied = true;
  }

  export(type: any) {
    this.params = this.router.url;
    type.exportChart(null, {
      credits: { enabled: true, text: 'https://members.nhsbenchmarking.nhs.uk' + this.params },
      chart: {
        spacingBottom: 60, 
        events: {
          load: ((e) => { }),
          render: function() {
            var chart = this,
            width = chart.chartWidth - 600,
            height = chart.chartHeight - 40;
            chart.renderer.image('https://s3.eu-west-2.amazonaws.com/nhsbn-static/Other/2022/nhsbn-logo.png', width, height, 144, 35).add();
          }
        }
      },
      type: 'application/pdf',
      filename: 'icsb-' + this.details.reportId + '-' + type.renderTo.id
    });
  }

  exportCSV(type: string) {

    let data;
    let extension = '-data';

    if (type == 'highestLowest') {
      // Format data
      data = Object.keys(this.highestLowest.national).map((key) => {
        return [ key + '_national', this.highestLowest.national[key].organisationOnsCode, this.highestLowest.national[key].organisationName.replace(/,/g, " "), this.highestLowest.national[key].submissionResult ]
      });
      if (this.highestLowestGroup) {
        Object.keys(this.highestLowestGroup).forEach((key) => {
          data.push([ key + '_group', this.highestLowestGroup[key].organisationOnsCode, this.highestLowestGroup[key].organisationName.replace(/,/g, " "), this.highestLowestGroup[key].submissionResult ])
        });
      }
      // Add row header
      data.unshift(['data', 'code', 'name', 'value']);
      extension = '-highlow-values'
    } else {
      // Format submissions
      data = this.selected.submissions.map(s => { 
        return [ s.organisationOnsCode, s.organisationName.replace(/,/g, " "), s.submissionResult ]
      })
      // Add row header
      data.unshift(['code', 'name', 'value']);
    }

    // https://stackoverflow.com/a/29304414
    var csvContent = '';
    data.forEach(function(infoArray, index) {
      let dataString = infoArray.join(',');
      csvContent += index < data.length ? dataString + '\n' : dataString;
    });

    var download = function(content, fileName, mimeType) {
      var a = document.createElement('a');
      mimeType = mimeType || 'application/octet-stream';

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(new Blob([content], {
          type: mimeType
        }), fileName);
      } else if (URL && 'download' in a) {
        a.href = URL.createObjectURL(new Blob([content], {
          type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
      }
    }

    download(csvContent, 'icsb-' + this.details.reportId + extension + '.csv', 'text/csv;encoding:utf-8');

  }

  navHome() {
    this.unsubscribe();
    this.router.navigate(['/home']);
  }

  navWelcome() {
    this.unsubscribe();
    this.router.navigate(['/benchmarker']);
    // TODO: Needs simplifying and moving to reset()
    this.categories = [];
    this.loading = false;
    this.details = null;
    this.data = null;
    this.secondaryData = null;
    this.error = null;
    this.secondary = null;
    this.noMapbox = false;
    this.data_saved = null;
    this.seriesData = null;
    this.chartScatterOptions = null;
    this.secondaryDetails = null;
    this.chartScatterOptions = null;
    this.chartStackedColumnOptions = null;
    this.primary_details_saved = null;
    this.primary_data_saved = null;
    this.relationships = null;
    this.preferences.reportId = null;
    this.highlighted = null;
    this.highlights = null;
    this.filtered = null;
    this.independentCategory = null;
    this.copied = false;
    this.domainId = null;
  }

  removeVideo() {
    this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Tktw9-LvlM8?controls=0');
  }

  enableColorBlindness() {
    if(this.colorBlindnessEnabled == false){
      this.nationalMeanColour = '#0c11a6';
      // this.organisationValueColour = '#882255';
      this.colorBlindnessEnabled = true;
    } else {
      this.nationalMeanColour = '#28A745';
      this.organisationValueColour = '#E03616';
      this.colorBlindnessEnabled = false;
    }
    this.getTiers(this.projectId);
  }

}