
export type ParsedEvent = {
    id: number,
    timestamp: Date,
    start: string,
    end: string,
    title: string,
    description: string,
    location: string,
    meetingId: string,
    meetingPasscode: string,
    meetingUrl: string
}

export class EventICS {
    parsedEvent: ParsedEvent;
    constructor(parsedEvent: ParsedEvent) {
        this.parsedEvent = parsedEvent;
    }

    foldLine(line: string): string {
        const maxLineLength = 75;
        let foldedLine = '';

        while (line.length > maxLineLength) {
            foldedLine += line.substring(0, maxLineLength) + '\r\n ';
            line = line.substring(maxLineLength);
        }
        foldedLine += line;

        return foldedLine;
    }

    ics(): string {
        const description = `DESCRIPTION;ENCODING=QUOTED-PRINTABLE:=0D=0A________________________________________________________________________________Microsoft Teams meeting.=0D=0AJoin on your computer, mobile app or room device.=0D=0A${this.parsedEvent.meetingUrl}`;
        const xAltDesc = `X-ALT-DESC;FMTTYPE=text/html;ENCODING=QUOTED-PRINTABLE:<!doctype html><html><body>${this.parsedEvent.description}<p><span style="mso-fareast-font-family:'Times New Roman';color:'#5F5F5F'">________________________________________________________________________________</span></p><h2 style="color:#454545;font-weight:lighter;font-family:sans-serif">Microsoft Teams Meeting</h2><p><b>Join on your computer, mobile app or room device</b><br><a style="color:#6264a7;font-weight:550" href="${this.parsedEvent.meetingUrl}">Click here to join the meeting</a></p><p>Meeting ID: ${this.parsedEvent.meetingId}<br>Passcode: ${this.parsedEvent.meetingPasscode}</p><p><a style="color:#6264a7" href="https://teams.microsoft.com/">Download Teams</a>|<a style="color:#6264a7" href="https://www.microsoft.com/microsoft-teams/join-a-meeting" target="_blank">Join on the web</a></p><span style='mso-fareast-font-family:"Times New Roman";color:#5f5f5f'>________________________________________________________________________________</span><span style="mso-fareast-font-family:'Times New Roman'"></span><span style="mso-ascii-font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri"></span></body></html>`

        return `BEGIN:VCALENDAR
VERSION:2.0
METHOD:REQUEST
X-MS-OLK-FORCEINSPECTOROPEN:TRUE
PRODID:-//Oedgal//BMC_Scheduler//EN
BEGIN:VEVENT
TRANSP:OPAQUE
UID:${this.parsedEvent.id}-${this.parsedEvent.start}@nhsbenchmarking.nhs.uk
DTSTAMP:${this.parsedEvent.timestamp}
DTSTART;TZID=GMT Standard Time:${this.parsedEvent.start}
DTEND;TZID=GMT Standard Time:${this.parsedEvent.end}
ORGANIZER;CN="NHS Benchmarking":mailto:noreply@nhsbenchmarking.nhs.uk
SUMMARY:${this.parsedEvent.title}
LOCATION:Microsoft Teams Meeting
${this.foldLine(description)}
${this.foldLine(xAltDesc)}
X-MICROSOFT-CDO-BUSYSTATUS:BUSY
X-MICROSOFT-CDO-IMPORTANCE:1
X-MICROSOFT-DISALLOW-COUNTER:FALSE
X-MS-OLK-AUTOFILLLOCATION:FALSE
X-MS-OLK-CONFTYPE:0
BEGIN:VALARM
ACTION:DISPLAY
DESCRIPTION:Reminder
END:VALARM
END:VEVENT
END:VCALENDAR`;
    }

    downloadICSFile() {
        const icsContent = this.ics();
        const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'event.ics';
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
}