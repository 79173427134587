import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment'

@Injectable()
export class ValidationService {
  private URL: string = environment.apiURL;

  constructor(private http: HttpClient) { }

  public getValidations(projectId, year): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'validation/getValidations', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('projectId', projectId.toString()).set('year', year.toString())
      }
    );
  }

  public getValidation(validationId): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'validation/getValidation', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('validationId', validationId.toString())
      }
    );
  }

  public addValidation(validation): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'validation/addValidation', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public deleteValidation(validationId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.delete(
      this.URL + 'validation/deleteValidation', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('validationId', validationId.toString())
      }
    );
  }

  public getValidationResponses(reportId, year, submissionId, serviceItemId): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'validation/getValidationResponses', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('reportId', reportId.toString()).set('year', year.toString()).set('submissionId', submissionId.toString()).set('serviceItemId', serviceItemId.toString())
      }
    );
  }

  public addValidationResponse(validationResponse): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'validation/addValidationResponse', JSON.stringify(validationResponse), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public getDeputyProjectLeads(projectId, year, submissionId): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'validation/getDeputyProjectLeads', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('projectId', projectId.toString()).set('year', year.toString()).set('submissionId', submissionId.toString())
      }
    );
  }

  public changeValidationStatus(validation): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'validation/changeValidationStatus', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public changeValidationVisibility(validation): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'validation/changeValidationVisibility', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public editValidationNote(validation): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'validation/editValidationNote', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

}