import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { AuthService } from "../utilities/services/auth.service";
import { MemberService } from "app/utilities/services/member.service";
import { DataService } from "app/utilities/services/data.service";
import { IResourceList } from "app/utilities/models/resources/resource-list.vm";
import { Organisation } from "app/utilities/classes/organisation/organisation";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  public date = new Date();
  public organisationId: number;
  public sectorSelected: boolean = false;
  public yearSelected: boolean = false;
  public selectedsector: string = '';
  public selectedYear: number;
  public availableYears: Array<number>;
  public resourceData: IResourceList;
  public nonProjectDocuments: boolean = true;
  public selectedProject: number = null;
  public sector: string = null;
  public currentYear: number = new Date().getFullYear();
  public hideShowYear: boolean = false;
  public hideShowSector: boolean = false;
  public searchText: string;
  public metaTitle: string = 'Resources | NHS Benchmarking Network';
  public showNonProjectDocs: boolean = false;
  public organisation: Organisation[];
  public currentOrganisation: number;
  public resourceType: Array<string> = ['Bespoke', 'Report', 'CaseStudy', 'Specification', 'Toolkit']
  public filteredType: string = null;
  public currentQueryParams: any;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private titleService: Title,
    private dataService: DataService,
    private meta: Meta,
    public router: Router,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.organisationId = JSON.parse(sessionStorage.getItem('organisationId'));
    this.selectedYear = this.currentYear;
    this.selectedsector = null;
    this.searchText = null;
    this.getOrganisationsByUser();
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});    
  }

  onOrgChange(message: number) {
    this.dataService.setSelectedOrganisation(message);
    this.organisationId = message;
    this.getResources(this.selectedYear || this.currentYear);
  }

  filterBySector(filter: string) {
    this.sector = filter;
    this.selectedsector = filter;
    this.sectorSelected = false;
    this.showNonProjectDocs = false
    this.nonProjectDocuments = null;
    this.searchText = null;
    this.router.navigate([], { queryParams: { sector: this.sector }, queryParamsHandling: 'merge' });
  }

  filterByType(type: string) {
    this.filteredType = type;
    this.sectorSelected = false;
    this.showNonProjectDocs = false;
    this.nonProjectDocuments = null;
    this.searchText = null;
    this.router.navigate([], { queryParams: { group: this.filteredType }, queryParamsHandling: 'merge' });
  }

  getOrganisationsByUser() {
    this.memberService.OrganisationsByUser().subscribe(
      (success) => { 
        this.organisation = success.data;
        if(this.organisationId == null) {
          this.organisationId = success.data[0].organisationId;
        }
        this.dataService.setOrganisation(success.data)
        this.dataService.setSelectedOrganisation(this.organisationId);
        this.route.queryParams.subscribe(params => {
          this.currentQueryParams = params;
          if(this.currentQueryParams.year) {
            this.getResources(this.currentQueryParams.year);
          } else {
            this.sector = null;
            this.filteredType = null;
            this.hideShowYear = false;
            this.hideShowSector = false;
            this.showNonProjectDocs = false;
            this.getResources(this.currentYear);
          }
        });

      },
      (error) => {
        console.log(error);
      }
    );
  }

  setQueryParam(filter) {
    this.router.navigate([], { queryParams: { [filter]: null }, queryParamsHandling: 'merge' });
  }

  getResources(year: number) {
    this.selectedYear = year;
    this.yearSelected = false;
    this.searchText = null;
    this.memberService.ResourceDocuments(year, this.organisationId).subscribe(
      (success) => {
        const containerResources = document.getElementById('containerResources');
        containerResources.classList.add('afterLoadingPadding');
        this.resourceData = success.data;
        this.availableYears = success.data.availableYears;
        this.reset();
        this.route.queryParams.subscribe(params => {
          this.currentQueryParams = params;
          if(this.currentQueryParams.sector) {
            this.filterBySector(this.currentQueryParams.sector)
          }
          if(this.currentQueryParams.group) {
            this.filterByType(this.currentQueryParams.group)
          }
        });
        this.router.navigate([], { queryParams: { year: year }, queryParamsHandling: 'merge' });
      },
      (error) => {
        console.log(error)
      });
  }

  getSelectedDocument(documentId: number) {
    this.memberService.AWSSelectedDocument(documentId, this.organisationId).subscribe(
      a => { window.open(a.data.documentUrl) }
    );
  }

  showDocuments(projectId: number) {
    if(this.selectedProject == projectId){
      this.selectedProject = null;
    } else {
      this.selectedProject = projectId;
    }
  }

  displayEventDocuments() {
    this.sector = null; 
    this.filteredType = null; 
    this.hideShowSector = false;
    this.reset();
  }

  filteredRowCount(project) {
    let rowCount = project.filter(data => data.documentCategoryName == this.filteredType).length;
    if(this.filteredType) {
      if(rowCount > 0) {
        return rowCount;
      } else {
        return '0';
      }
    }
  }
  
  reset() {
    this.nonProjectDocuments = false;
    this.selectedsector = null;
    this.searchText = null;
    this.router.navigate([], { queryParams: { year: this.selectedYear, sector: null, group: null }, queryParamsHandling: 'merge' });
  }
}