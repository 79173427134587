import { Component, Input, Output, EventEmitter, AfterContentInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { QuestionBase } from '../../utilities/classes/question/question-base';

import * as moment from 'moment';
import 'moment/min/locales';

@Component({
  selector: 'app-dynamic-survey',
  templateUrl: './dynamic-survey.component.html',
  styleUrls: ['./dynamic-survey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicSurveyComponent implements AfterContentInit, OnDestroy {
  @Input() question: QuestionBase<any>;
  @Input() innerForm: FormGroup;
  @Input() projectId;
  @Input() peerGroupId;
  @Input() isAdmin;
  @Input() linkedQuestion;
  
  @Output() questionCountEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() questionTotalEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() datesTimesEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() parentChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() nacelValidations: EventEmitter<any> = new EventEmitter<any>();
  @Output() editQuestion: EventEmitter<any> = new EventEmitter<any>();

  minValueText: string = 'You have exceeded the minimum value of this question';
  maxValueText: string = 'You have exceeded the maximum value of this question';
  decimalText: string = 'The limit of decimal places has been exceeded';
  conditionalQuestionWarning: string = 'This question has been disabled due to an answer given for a previous question.';
  conditionalParentWarning: string = 'Changing the response to this question could change responses to subsequent questions.';
  firstLoad: boolean = false;
  dateOutOfBounds1: string = "";
  dateOutOfBounds2: string = "";
  dateOutOfBounds3: string = "";
  conditionQuestion: boolean = false;
  conditionQuestionHide: boolean = false;
  conditionHidden: boolean = false;
  isOpen: boolean = false;
  showPopup: boolean = true;
  popupHidden: boolean = false;

  datesTimes = [];
  datesTimesErrors = [];

  constructor() {
  }

  ngAfterContentInit() {
    if (!this.firstLoad) {
      let questionConditions = this.question.conditionQuestions,
          responses = this.question.responses;

      if (questionConditions.length > 0) {
        if (questionConditions[0].conditionQuestionId) {
          let questionDisable: boolean = true;

          for (let index = 0; index < responses.length; index++) {
            let id = responses[index].uniqueId;
            let question = this.innerForm.controls[id];

            //Check if there is a value from the API, if not then we need to determine what the value is for the parent as it will be on the same page
            if (questionConditions[0].conditionQuestionValue || questionConditions[0].conditionType == 2 || questionConditions[0].conditionType == 4) {
              //Use parent values to disable question
              if (questionConditions[0].conditionType != 4) {
                if (questionConditions[0].parentConditionMin >= questionConditions[0].conditionQuestionValue && questionConditions[0].parentConditionMax <= questionConditions[0].conditionQuestionValue) {
                  questionDisable = false;
                }
              }
              else {
                if (questionConditions[0].conditionMin > questionConditions[0].conditionQuestionValue || !questionConditions[0].hasOwnProperty('conditionQuestionValue')) {
                  this.conditionHidden = true;
                  this.totalEmit();
                }
              }
            }
            else {
              let parentQuestionKey;
              let parentQuestionValue;

              //Get parent from list of questions in angular form
              for (const key in this.innerForm.controls) {
                if (this.innerForm.controls.hasOwnProperty(key)) {
                  const element = this.innerForm.controls[key];

                  if (+key.split('-')[0] == questionConditions[0].conditionQuestionId) {
                    parentQuestionKey = key;
                    parentQuestionValue = element.value;
                  }
                }
              }

              //Handle YN questions differently - they are not stored in the DB and need extra logic to manage
              if (parentQuestionKey) {
                if (parentQuestionKey.split('-')[2] == 'yn') {
                  if (parentQuestionValue == 'Y') {
                    parentQuestionValue = 1;
                  }
                  else if (parentQuestionValue == 'N') {
                    parentQuestionValue = 2;
                  }
                  else {
                    parentQuestionValue = 3;
                  }
                }
              }

              //Enable question if criteria match
              if (parentQuestionValue >= questionConditions[0].parentConditionMin && parentQuestionValue <= questionConditions[0].parentConditionMax) {
                questionDisable = false;
              } else {
                this.popupHidden = true;
              }
            }

            if (questionDisable && questionConditions[0].conditionType !== 4) {
              if (questionConditions[0].conditionType == 2) {

                // Set parent value, accounting for YN
                let parentValue;
                if (questionConditions[0].conditionQuestionValue == 'Y') { parentValue = 1 }
                else if (questionConditions[0].conditionQuestionValue == 'N') { parentValue = 2 }
                else if (questionConditions[0].conditionQuestionValue == '-') { parentValue = 3 }
                else { parentValue = questionConditions[0].conditionQuestionValue }

                // Parent value must MATCH both conditions (i.e. can only be one value)
                // TODO: Improve flexibility to allow more than one value >= and <=
                if (parentValue === questionConditions[0].parentConditionMin && parentValue === questionConditions[0].parentConditionMax) {
                  questionDisable = false;
                } else {
                  this.conditionQuestion = true;
                  this.popupHidden = true;
                  this.showPopup = false;
                  if (this.question.formatModifier === 'H') { this.conditionQuestionHide = true; };
                  question.disable();
                }
              }
              else {
                  question.disable();
              }
            }

          }
        } // conditionalQuestion
      }

      //This is intentionally forcing a change to the questions after Angular loads. Angular does not like this.
      if (this.question.responses) {        
        this.question.responses.forEach((element, index) => {
          if(this.innerForm.controls[this.question.responses[index].uniqueId]) {
            if (this.innerForm.controls[this.question.responses[index].uniqueId].errors) {
              this.innerForm.controls[this.question.responses[index].uniqueId].markAsTouched();
            }
          }
          if(this.innerForm.controls[this.question.responses[index].uniqueId]) {
            if (this.innerForm.controls[this.question.responses[index].uniqueId].value || this.innerForm.controls[this.question.responses[index].uniqueId].value == 0) {
              this.countEmit(this.question.responses[index].uniqueId, 'add');
            }
          }
        });
      }

      this.firstLoad = true;
    }
  }

  ngOnDestroy() {
    this.countEmit('reset', 'reset');
  }

  autoSum(currentQuestion: string, questionValue: number, autoSumCheck: string, itemGroup: number, decimalPlaces: number) {
    let classBreakdown = currentQuestion.split('-');
    let questions = document.getElementsByClassName(classBreakdown[0]);
    let total = [+questionValue];
    let finalTotal = [];

    if (this.innerForm.controls[currentQuestion].dirty && this.innerForm.controls[currentQuestion].touched) {
      //Summable field should always come last....this will need to be modified on the chance this is not the case
      for (let index = 0; index < questions.length; index++) {
        let element = questions[index];
        let currentClass = element.classList.contains('item-group-' + itemGroup);
        let autoSumClass = element.classList.contains('autosum');

        //Add each value related to item group into total array that isnt an autosum field
        if (currentClass) {
          if (!autoSumClass && element.id !== currentQuestion) {
            total.push(+this.innerForm.controls[element.id].value);
          }
        }

        //If question is autoSum and we arent currently editing an autoSum quesiton, then set total
        if (autoSumClass) {
          if (currentClass && autoSumCheck != 'Y') {
            let calc = total.reduce((a, b) => a + b);

            if (decimalPlaces) {
              calc = +calc.toFixed(decimalPlaces);
            } else {
              calc = +calc.toFixed(2);
            }

            this.innerForm.controls[element.id].patchValue(calc);
            this.innerForm.controls[element.id].markAsDirty();
          }

          if (!element.classList.contains('item-group-0')) {
            finalTotal.push(+this.innerForm.controls[element.id].value);
          }

          //Perform calculation for overall total unless total is being edited manually
          if (element.classList.contains('item-group-0') && !currentClass) {
            let calc: number = finalTotal.reduce((a, b) => a + b);

            if (decimalPlaces) {
              calc = +calc.toFixed(decimalPlaces);
            }
            
            this.innerForm.controls[element.id].patchValue(calc);
            this.innerForm.controls[element.id].markAsDirty();
          }
        }
      }
    }
  }

  numericPaste(currentQuestion, event) {
    if (event.srcElement['value'] !== '') {
      var pastedValue = event.srcElement['value'].toString();
      pastedValue = pastedValue.replace(/\,/g,'');
      pastedValue = Number(pastedValue);
      this.innerForm.get(currentQuestion).setValue(pastedValue);
    }
  }

  nacelValidate(question, value) {    
    if (this.projectId == 34 || this.projectId == 86) {
    // NACEL date/time validation for 2020 onwards (date/times outside of nested)
    // NB: conditionType must be set to 6 on question group
      
    // Define empty values, errors and validations
    let currentId = question.questionId + '-1-' + question.questionType,
    values = [],
    errors = [],
    validations = {
      adm: null,
      admPlus4: null,
      rec: null,
      dea: null,
      unrec: null
    };

    // Populate from questions in group
    Object.keys(this.innerForm.value).forEach(key => {
      values.push({
        questionIdNo: +key.substring(0,5),
        questionId: key,
        questionType: key.split('-')[2],
        value: this.innerForm.value[key]
      });
    });

    // Remove non-date/time values
    values = values.filter(v => v.questionType == 'date' || v.questionType == 'time');

    // Define number of questions
    length = values.length;

    // Create moment.js values
    values.forEach(v => {
      if (v.questionType === 'date' && v.value) {
        v.value = moment(v.value).locale('en-gb').format('LL');
      } else if (v.questionType === 'time' && v.value) {
        v.value = moment(v.value, ['h:m', 'H:m']).format('HH:mm');
      } else if (v.questionType === 'time') {
        v.value = moment('00:00', ['h:m', 'H:m']).format('HH:mm');
      }
    });

    // Create default values
    if (question.questionType === 'date' && value) {
      value = moment(value).locale('en-gb').format('LL');
    } else if (question.questionType === 'time' && value) {
      value = moment(value, ['h:m', 'H:m']).format('HH:mm');
    } else if (question.questionType === 'time') {
      value = moment('00:00', ['h:m', 'H:m']).format('HH:mm');
    };

    // Get selected/current value
    if (values.filter(v => v.questionId === currentId)[0]) {
      values.filter(v => v.questionId === currentId)[0].value = value;
    }

    // Populate admission date/time and +4hrs after admission
    if (values[0].value && values[1].value) {
      let admDate = moment(values[0].value + ' ' + values[1].value, 'D MMMM YYYY H:mm');
      validations.adm = moment(admDate).locale('en-gb');
      validations.admPlus4 = moment(admDate).add(4, 'hours').locale('en-gb');
    }

    // Populate recognition date/time
    if (length === 6 && values[2].value && values[3].value) {
      let recDate = moment(values[2].value + ' ' + values[3].value, 'D MMMM YYYY H:mm');
      validations.rec = moment(recDate).locale('en-gb');
    }

    // Populate death date/time
    if (length === 6 && values[4].value && values[5].value) {
      let deaDate = moment(values[4].value + ' ' + values[5].value, 'D MMMM YYYY H:mm');
      validations.dea = moment(deaDate).locale('en-gb');
    } else if (length === 4 && values[2].value && values[3].value) {
      let deaDate = moment(values[2].value + ' ' + values[3].value, 'D MMMM YYYY H:mm');
      validations.dea = moment(deaDate).locale('en-gb');
    }

    // Non-consecutive date/time errors
    if (validations.adm && validations.rec && validations.adm.isAfter(validations.rec)) {
      errors.push('Date / time of recognition cannot be before date / time of admission.');
    }
    if (validations.rec && validations.dea && validations.rec.isAfter(validations.dea)) {
      errors.push('Date / time of death cannot be before date / time of recognition.');
    }
    if (validations.adm && validations.dea && validations.adm.isAfter(validations.dea)) {
      errors.push('Date / time of death cannot be before date / time of admission.');
    }
    // Check for deaths within 4 hours of admission
    if (validations.dea && validations.admPlus4 && validations.dea.isBefore(validations.admPlus4)) {
      errors.push('Date / time of death must be at least 4 hours after date / time of admission.');
    }

    if (validations.dea) {
      if (validations.dea.isSameOrAfter('2024-07-01 00:00') && validations.dea.isSameOrBefore('2024-12-31 23:59')) {
        console.log('Within audit period')
      } else if (validations.dea === null) {
        console.log('No date / time of death')
      } else {
        errors.push('Date / time of death must be between 1st July and 31st December 2024');
      }
    }

    this.nacelValidations.emit(errors);
    }
  }

  nacelDateTime(currentQuestion, value) {
    let question = this.innerForm.controls[currentQuestion]
    if ( question.dirty || question.touched ) {
      this.datesTimes = [];
      this.question.popupQuestions.forEach((e) => {
        if (e.questionType === 'DT') {
          let questionPart = e.questionPart;
          let dateId = this.question.questionId + '-' + questionPart + '-date'
          let timeId = this.question.questionId + '-' + (+questionPart + 1) + '-time'
          let time = document.getElementById(timeId)['value']
          var timeTokens = time.split(':');
          let date;
          if (dateId === currentQuestion) {
            date = question.value
            this.datesTimes.push({
              date: new Date(Date.UTC(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), timeTokens[0], timeTokens[1])),
              integer: new Date(Date.UTC(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), timeTokens[0], timeTokens[1])).getTime()
            })
          } else {
            date = document.getElementById(dateId)['value']
            var dateTokens = date.split('/');
            let day = dateTokens[0]; let month = dateTokens[1]; let year = dateTokens[2];
            this.datesTimes.push({
              date: new Date(year, month-1, day, timeTokens[0], timeTokens[1]),
              integer: new Date(year, month-1, day, timeTokens[0], timeTokens[1]).getTime()
            })
          }
        }
      });
      let admission = this.datesTimes[0].integer;
      let admissionPlus4 = admission + 1.44e+7;
      let recognition = this.datesTimes[1].integer;
      let death = this.datesTimes[2].integer;
      this.datesTimesErrors = [];
      this.datesTimesEmitter.emit(false);
      if (admission > recognition) {
        this.datesTimesEmitter.emit(true);
        this.datesTimesErrors.push(
          'Date and time of admission cannot be after date and time of recognition.'
        )
      }
      if (recognition > death) {
        this.datesTimesEmitter.emit(true);
        this.datesTimesErrors.push(
          'Date and time of recognition cannot be after date and time of death.'
        )
      }
      if (admission > death) {
        this.datesTimesEmitter.emit(true);
        this.datesTimesErrors.push(
          'Date and time of admission cannot be after date and time of death.'
        )
      }
      if (admissionPlus4 > death) {
        this.datesTimesEmitter.emit(true);
        this.datesTimesErrors.push(
          'Time of death cannot be within 4 hours of time of admission.'
        )
      }
    }
  }

  dateTimeCalc(currentQuestion: string, questionEvent) {

    // NACEL date/time validation for 2019 and earlier (date/times inside of nested)
    // TODO: Should be made defunct

    let cQuestion = this.innerForm.controls[currentQuestion]
    let classBreakdown = currentQuestion.split('-');
    let questions = document.getElementsByClassName(classBreakdown[0]);
    
    if (cQuestion.dirty || (cQuestion.touched && classBreakdown[2] == 'date')) {
      let dateArray = [], timeArray = [], dateTimeCalcQuestions = [];
      let dateQuestions = {}, timeQuestions = {}

      for (let index = 0; index < questions.length; index++) {
        let element = questions[index];
        let dateTimeCalcClass = element.classList.contains('dateTimeCalc');
        
        if (element.id.split('-')[2] == 'date') {
          if (dateTimeCalcClass) {
            dateTimeCalcQuestions.push(element.id);
          }
          else {
            let newDate;

            if (currentQuestion == element.id) {
              if (questionEvent) {
                newDate = new Date(Date.UTC(questionEvent.getUTCFullYear(), questionEvent.getUTCMonth(), questionEvent.getUTCDate())).getTime();
              }
              else {
                newDate = null;
              }              
            }
            else {
              let formDate = this.innerForm.controls[element.id].value;

              if (formDate) {
                newDate = new Date(Date.UTC(formDate.getUTCFullYear(), formDate.getUTCMonth(), formDate.getUTCDate())).getTime();
              }
              else {
                newDate = null;
              }
            }

            dateQuestions[dateArray.length] = { dateQuestionId: element.id, value: newDate };
            dateArray.push(element.id);
          }
        }

        if (element.id.split('-')[2] == 'time') {
          timeQuestions[timeArray.length] = { timeQuestionId: element.id, value: currentQuestion == element.id ? questionEvent : this.innerForm.controls[element.id].value };
          timeArray.push(element.id);
        }
      }

      this.dateTimeComparison(1, dateQuestions[0].value, timeQuestions[0].value, dateQuestions[1].value, timeQuestions[1].value, dateTimeCalcQuestions[0], "Recognition of death cannot come before date of admission");
      this.dateTimeComparison(2, dateQuestions[1].value, timeQuestions[1].value, dateQuestions[2].value, timeQuestions[2].value, dateTimeCalcQuestions[1], "Date of admission cannot come before date of death");
      this.dateTimeComparison(3, dateQuestions[0].value, timeQuestions[0].value, dateQuestions[2].value, timeQuestions[2].value, dateTimeCalcQuestions[2], "Recognition of death cannot come before date of death");
    }
  }

  dateTimeComparison(dateOrder, date1, time1, date2, time2, calculationQuestion, errorMessage) {
    if (date1 && date2) {
      //Both dates must exist for necessary processing
      if (date1 > date2) {
        //Date one is greater than date two, dates are out of alignment
        this.innerForm.controls[calculationQuestion].setValue("False");

        if (dateOrder == 1) {
          this.innerForm.controls[calculationQuestion].setErrors({ 'dateOutOfBounds1' : true });
          this.dateOutOfBounds1 = errorMessage 
        }

        if (dateOrder == 2) {
          this.innerForm.controls[calculationQuestion].setErrors({ 'dateOutOfBounds2' : true });
          this.dateOutOfBounds2 = errorMessage 
        }

        if (dateOrder == 3) {
          this.innerForm.controls[calculationQuestion].setErrors({ 'dateOutOfBounds3' : true });
          this.dateOutOfBounds3 = errorMessage 
        }
      }
      else if (date1 == date2) {
        //Dates are the same so then check times
        if (time1 && time2) {
          //Both times have to exist for check, if there is only one time no comparison is made, even if this would put the dates out of alignment
          if (time1 <= time2) {
            if (dateOrder == 3) {
              let startTime = time1.split(":");
              let endTime = time2.split(":");

              let startTimeMinutes: number = +startTime[0] * 60 + +startTime[1];
              let endTimeMinutes: number = +endTime[0] * 60 + +endTime[1];

              if (endTimeMinutes - startTimeMinutes <= 240) {
                //Times are within 4 hour threshold
                let timeError = "Time of death cannot be within 4 hours of time of admission."

                this.innerForm.controls[calculationQuestion].setValue("False");                
                this.innerForm.controls[calculationQuestion].setErrors({ 'dateOutOfBounds3' : true });
                this.dateOutOfBounds3 = timeError                 
              }
              else {
                //Time is outside 4 hour threshold
                this.innerForm.controls[calculationQuestion].setValue("True");
                this.innerForm.controls[calculationQuestion].setErrors(null);

                if (dateOrder == 1) { this.dateOutOfBounds1 = null }
                if (dateOrder == 2) { this.dateOutOfBounds2 = null }
              }
            }
            else {
              //Time one is smaller that time two, dates are aligned correctly
              this.innerForm.controls[calculationQuestion].setValue("True");
              this.innerForm.controls[calculationQuestion].setErrors(null);

              if (dateOrder == 1) { this.dateOutOfBounds1 = null }
              if (dateOrder == 2) { this.dateOutOfBounds2 = null }
              if (dateOrder == 3) { this.dateOutOfBounds3 = null }
            }
          }
          else {
            //Dates are not aligned
            this.innerForm.controls[calculationQuestion].setValue("False");

            if (dateOrder == 1) {
              this.innerForm.controls[calculationQuestion].setErrors({ 'dateOutOfBounds1' : true });
              this.dateOutOfBounds1 = errorMessage 
            }
    
            if (dateOrder == 2) {
              this.innerForm.controls[calculationQuestion].setErrors({ 'dateOutOfBounds2' : true });
              this.dateOutOfBounds2 = errorMessage 
            }
    
            if (dateOrder == 3) {
              this.innerForm.controls[calculationQuestion].setErrors({ 'dateOutOfBounds3' : true });
              this.dateOutOfBounds3 = errorMessage 
            }
          }
        }
        else {
          //Dates are aligned correctly
          this.innerForm.controls[calculationQuestion].setValue("True");
          this.innerForm.controls[calculationQuestion].setErrors(null);

          if (dateOrder == 1) { this.dateOutOfBounds1 = null }
          if (dateOrder == 2) { this.dateOutOfBounds2 = null }
          if (dateOrder == 3) { this.dateOutOfBounds3 = null }
        }
      }
      else {
        //Dates are aligned correctly
        this.innerForm.controls[calculationQuestion].setValue("True");
        this.innerForm.controls[calculationQuestion].setErrors(null);

        if (dateOrder == 1) { this.dateOutOfBounds1 = null }
        if (dateOrder == 2) { this.dateOutOfBounds2 = null }
        if (dateOrder == 3) { this.dateOutOfBounds3 = null }
      }
    }
    else {
      //Remove all calculations as missing dates
      this.innerForm.controls[calculationQuestion].setValue(null);
      this.innerForm.controls[calculationQuestion].setErrors(null);

      if (dateOrder == 1) { this.dateOutOfBounds1 = null }
      if (dateOrder == 2) { this.dateOutOfBounds2 = null }
      if (dateOrder == 3) { this.dateOutOfBounds3 = null }
    }
  }

  questionCount(currentQuestion: string, value) {
    let cQuestion = this.innerForm.controls[currentQuestion];

    if(currentQuestion.split('-')[2] == 'date') {
      this.innerForm.controls[currentQuestion].clearValidators();
    }   

    //Date questions are checked based on the touched critera - the datepicker doesnt update the form in the same method as other questions
    if ((cQuestion.dirty || (cQuestion.touched && currentQuestion.split('-')[2] == 'date')) && value) {
      this.countEmit(currentQuestion, 'add');
    }
    else if (cQuestion.dirty && !value) {
      this.countEmit(currentQuestion, 'remove');
    }
  }

  parentCheck(question, value) {
    let conditionType;
    if (question.conditionQuestions[0]) {
      conditionType = question.conditionQuestions[0].conditionType;
    }
    
    if (question.isParent === true && conditionType == 2) {
      let changedValue = +value.split(': ')[1],
          existingValue = +question.responses[0].value;
      this.parentChangeEmitter.emit({ question: question, changedValue: changedValue, existingValue: existingValue });
    }
  }

  countEmit(question: string, state: string) {
    this.questionCountEmitter.emit(question + '.' + state);
  }

  totalEmit() {
    this.questionTotalEmitter.emit();
  }

  updateStatus(event) {
    if (event.path[0].classList.contains("panel-heading") || event.path[1].classList.contains("accordion-toggle") || event.path[2].classList.contains("accordion-toggle") || event.path[3].classList.contains("accordion-toggle")) {
      this.isOpen = !this.isOpen;
    }
  }

  updateChildQuestion(event, value) {
    if (this.question.conditionQuestions.length > 0) {
      let questions = document.getElementsByClassName("parentId-" + event.split('-')[0]),
          popupQuestions = document.getElementsByClassName("popupId-" + event.split('-')[0]);

      if (this.question.questionType == 'dropdown' && this.question.conditionQuestions[0].conditionType == 1) {
        let response = value.split(':')[1].trim();

        if (this.question.questionResponseType == 'YN') {
          let ynresponse = this.question.options.filter(a => a.optionId == response)[0].displaySequence;

          if (ynresponse >= this.question.conditionQuestions[0].conditionMin && ynresponse <= this.question.conditionQuestions[0].conditionMax) {
            this.enableQuestions(questions);
            this.enablePopupQuestions(popupQuestions);
          }
          else {
            this.disableQuestions(questions);
            this.disablePopupQuestions(popupQuestions);
          }
        }
        else {
          if (response >= this.question.conditionQuestions[0].conditionMin && response <= this.question.conditionQuestions[0].conditionMax) {
            this.enableQuestions(questions);
            this.enablePopupQuestions(popupQuestions);
          }
          else {
            this.disableQuestions(questions);
            this.disablePopupQuestions(popupQuestions);
          }
        }
      }

      if (this.question.questionType == 'N1' && this.question.conditionQuestions[0].conditionType == 1 && (+value || +value == 0)) {
        if (+value >= this.question.conditionQuestions[0].conditionMin && +value <= this.question.conditionQuestions[0].conditionMax) {
          this.enableQuestions(questions);
        }
        else {
          this.disableQuestions(questions);
        }
      }
    }
  }

  disableQuestions(questions) {
    for (let index = 0; index < questions.length; index++) {
      const element = questions[index];
      
      this.innerForm.controls[element.id].disable();
      this.innerForm.controls[element.id].setValue(null);
      this.innerForm.controls[element.id].markAsDirty();
    }
  }
  
  disablePopupQuestions(questions) {
    for (let index = 0; index < questions.length; index++) {
      const element = questions[index];
      element.className = element.className + ' disabled-popup';  
    }
  }

  enableQuestions(questions) {
    for (let index = 0; index < questions.length; index++) {
      const element = questions[index];
      
      this.innerForm.controls[element.id].enable();
    }
  }

  enablePopupQuestions(questions) {
    for (let index = 0; index < questions.length; index++) {
      const element = questions[index];
      element.className = element.className.replace(' disabled-popup', '');
    }
  }

  // Question Editing (Active Admin)
  openEdit(question) {
    this.editQuestion.emit(question);
  }

}
