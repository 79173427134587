import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';
import { AccordionModule, ModalModule } from 'ngx-bootstrap';
import { ResponsiveModule } from 'ngx-responsive';

import { ProjectRegistrationComponent } from './project-registration.component';
import { ProjectRegistrationRoutingModule } from './project-registration-routing.module';

import { SafePipeModule } from '../utilities/pipes/safe-pipe.module';
import { PrProjectLeadComponent } from './pr-project-lead/pr-project-lead.component';
import { PrDeputyProjectLeadComponent } from './pr-deputy-project-lead/pr-deputy-project-lead.component';
import { PrSubmissionGroupComponent } from './pr-submission-group/pr-submission-group.component';
import { PrSubmissionComponent } from './pr-submission/pr-submission.component';
import { PrServiceComponent } from './pr-service/pr-service.component';
import { PrAddressComponent } from './pr-address/pr-address.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ResponsiveModule,
    ToastrModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    SafePipeModule,
    ProjectRegistrationRoutingModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fa fa-spinner fa-spin"></span>',
      disableBtn: true, 
      btnLoadingClass: 'is-loading',
      handleCurrentBtnOnly: false
    })
  ],
  declarations: [
    ProjectRegistrationComponent,
    PrProjectLeadComponent,
    PrDeputyProjectLeadComponent,
    PrSubmissionGroupComponent,
    PrSubmissionComponent,
    PrServiceComponent,
    PrAddressComponent
  ]
})
export class ProjectRegistrationModule {}