import { Component, ViewEncapsulation } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { Angular2PromiseButtonModule } from "angular2-promise-buttons/dist";
import { environment } from '../../environments/environment';
import { ToastrService } from "ngx-toastr";

import { MemberService } from "../utilities/services/member.service";
import { DataService } from "app/utilities/services/data.service";
import { OrderByPipe } from "ngx-pipes";
import { Products } from "app/utilities/classes/products/products";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None, // New spinner (child-elements)
  providers: [ OrderByPipe ]
})
export class LoginComponent {
  queryParam;
  signIn: FormGroup;
  returnUrl: string;
  arrMembers: string[];
  errorMessage: string;
  loggingIn: boolean = false;
  loginRequestForm: FormGroup;
  statusLogin: boolean = false;
  isSubmitting: boolean = false;
  statusSubscribe: boolean = true;
  showLoginScreen: boolean = true;
  organisationPickedByUser: String;
  requestSuccessful: boolean = false;
  showRequestLoginScreen: boolean = false;
  organisationId: number;
  formControls = [];
  products;
  organisationsProducts;
  isVisible: boolean;
  devMode: boolean = false;
  submitted: boolean = false;

  constructor(
    public fb: FormBuilder,
    private memberService: MemberService,
    private dataService: DataService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    public order: OrderByPipe,
    private newSpinner: Angular2PromiseButtonModule // New spinner
  ) { }

  ngOnInit() {
    // Set return URL
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
    this.getOrganisationListWithProducts();
    this.buildLoginForm();
    this.getOrganisationList();
    this.buildRequestLoginForm();
    if (this.route.snapshot.queryParams["option"] == "subscribe") {
      this.statusLogin = true;
      this.statusSubscribe = false;
    }

    // Check environment
    if (environment.production) {
      this.devMode = false;
    } else {
      this.devMode = true;
    }
  }

  alreadyMember(orgPickedByUser) {
    this.products = this.organisationsProducts.filter(org => org.organisationId == orgPickedByUser.organisationId)[0].products;
    this.formControls = this.products.map(control => new FormControl(false));
    this.showLoginScreen = false;
    this.showRequestLoginScreen = true;
    this.organisationPickedByUser = orgPickedByUser.organisationName;
    this.buildRequestLoginForm();
    this.organisationId = orgPickedByUser.organisationId;
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length? null : { 'whitespace': true };       
  }

  buildRequestLoginForm() {
    this.loginRequestForm = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(60), this.noWhitespaceValidator]],
      email: [null, [Validators.required, Validators.email, this.noWhitespaceValidator]],
      jobTitle: [null, [Validators.required, Validators.maxLength(60), this.noWhitespaceValidator]],
      organisationName: [null, [Validators.maxLength(100)]],
      checkboxes: new FormArray(this.formControls),
      globalSubscribe: [false],
      acceptTerms: [false, Validators.requiredTrue]
    });
  }

  showDropdown() {
    this.isVisible = true;
  }

  closeDropDown() {
    setTimeout(() => {
      this.isVisible = false;
    }, 250);
  }

  buildLoginForm() {
    this.signIn = this.fb.group({
      emailAddress: [
        "",
        [Validators.required, Validators.maxLength(200), <any>Validators.email],
      ],
      password: [""],
    });

    this.signIn.valueChanges.subscribe((data) =>
      this.onSignInValueChanged(data)
    );

    this.onSignInValueChanged();
  }

  onSignInValueChanged(data?: any) {
    if (!this.signIn) {
      return;
    }
    const form = this.signIn;

    for (const field in this.signInFormErrors) {
      // clear previous error message (if any)
      this.signInFormErrors[field] = "";
      const control = form.get(field);

      if (control && control.touched && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.signInFormErrors[field] += messages[key] + " ";
        }
      }
    }
  }

  onSubmit() {
    this.loggingIn = true;

    if (this.signIn.value.emailAddress && this.signIn.value.password) {
      this.memberService
        .Login(this.signIn.value.emailAddress, this.signIn.value.password)
        .subscribe(
          (s) => {
            // Navigate to return URL on successful login
            if (this.returnUrl && this.returnUrl !== "%2Fhome") {
              this.getUserDetails();
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigateByUrl("/");
            }
          },
          (e) => {
            let errorCode = e.error.error.errorCode;
            this.loggingIn = false;
            switch (errorCode) {
              case 5101:
                this.errorMessage = `The email address is not recognised. Please try again. <br />
                  If you do not have a login, you can Request a Login`;
                break;
              case 5102:
                this.errorMessage =
                  'Your account is currently disabled. Please contact the <a href="https://www.nhsbenchmarking.nhs.uk/contact">Support Team</a> for more information.';
                break;
              case 5103:
                this.errorMessage =
                  'Your account does not currently have any assigned roles. Please contact the <a href="https://www.nhsbenchmarking.nhs.uk/contact">Support Team</a> for more information.';
                break;
              case 5104:
                this.errorMessage =
                  'Your organisation is not currently subscribed to the network. Please contact the <a href="https://www.nhsbenchmarking.nhs.uk/contact">Support Team</a> for more information.';
                break;
              default:
                this.errorMessage =
                  "This password is incorrect. Please try again or click <b>Forgotten Password?</b> below.";
            }
          }
        );
    }
  }

  getUserDetails() {
    this.memberService.User().subscribe((u) => {
      this.dataService.setUser(u.data);
    });
  }

  navResetPassword() {
    this.router.navigate(["/reset-password"]);
  }

  getOrganisationList() {
    this.memberService.OrganisationList().subscribe((d) => {
      this.arrMembers = d.data.organisations;
    });
  }

  getOrganisationListWithProducts() {
    this.memberService.OrganisationListWithProducts().subscribe((d) => {
      this.organisationsProducts = d.data.organisationList;
      this.organisationsProducts = this.organisationsProducts.map(org => { return new Products(org) });
    });
  }

  // Return to login window from Request login
  backToLogin() {
    this.submitted = false;
    this.showLoginScreen = true;
    this.showRequestLoginScreen = false;
    this.loginRequestForm.reset();
  }

  checkboxSelected() {
    return this.loginRequestForm.value.checkboxes.every(v => v === false)
  }

  loginRequest() {
    this.submitted = true;
    
    if (this.checkboxSelected() || this.loginRequestForm.invalid) {
      this.validateAllFormFields(this.loginRequestForm);
      return;
    } 

    const selectedPreferences = this.loginRequestForm.value.checkboxes
      .map((checked, index) => checked ? this.products[index].productName : null)
      .filter(value => value !== null);

    this.isSubmitting = true;

    this.newSpinner = new Promise((resolve, reject) => {
      setTimeout(resolve, 3000);
    });

    let membershipResponse = this.loginRequestForm.value;

    let loginRequestBody = {
      email: membershipResponse.email,
      jobTitle: membershipResponse.jobTitle,
      name: membershipResponse.name,
      organisationId: this.organisationId,
      projects: selectedPreferences.join(' || '),
      globalSubscribe: membershipResponse.globalSubscribe ? 'Y' : null
    }
    this.memberService.LoginRequest(loginRequestBody).subscribe(
      (r) => {
        this.requestSuccessful = true;
        this.loginRequestForm.reset();
        this.buildRequestLoginForm();
      },
      (e) => {
        this.toastr.error(
          `${ e.error.error.message }, please <a href="https://members.nhsbenchmarking.nhs.uk/reset-password">reset your password <i class="far fa-envelope"></i></a>`,
          "Login Request",
          { enableHtml: true }
        );
        this.requestSuccessful = false;
        console.log(e)
      }
    );
  }

  // Error handling Request login form
  isFieldValid(field: string) {
    return (
      !this.loginRequestForm.get(field).valid &&
      (this.loginRequestForm.get(field).dirty ||
        this.loginRequestForm.get(field).touched)
    );
  }

  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        control.markAsTouched({ onlySelf: true });
        this.validateAllFormFields(control);
      }
    });
  }

  anotherRequest() {
    this.submitted = false;
    this.loginRequestForm.reset();
    this.requestSuccessful = false;
  }

  signInFormErrors = {
    emailAddress: "",
  };

  validationMessages = {
    emailAddress: {
      required: "Email Address is required.",
      maxlength: "Email Address cannot be longer than 60 characters.",
      email: "Email Address is not valid.",
    },
  };
}
