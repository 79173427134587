import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions, readOnly: string) {
    let isAdmin = JSON.parse(sessionStorage.getItem('user'));
    let group: any = {};

    questions.forEach(question => {
      // readOnly or disabled if only condition is conditionType = 3
      if (isAdmin && isAdmin.isAdmin !== 'Y' && (readOnly == 'Y' || (question.conditionQuestions[0] && question.conditionQuestions[0].conditionType == 3))) {
        if (question.questionType == 'date') {
          for (var key in question.responses) {
            let date = question.responses[key].value;

            if (date) {
              date = new Date(question.responses[key].value);
            }
            else {
              date = undefined;
            }

            group[question.responses[key].uniqueId] = new FormControl(
              { value: date, disabled: true }
            );
          }
        }

        if (question.questionType == 'TM' || question.questionType == 'TM2') {
          for (var key in question.responses) {
            let r: Date; let rHours, rMinutes, rTime;

            if (question.responses[key].value) {
              r = new Date(question.responses[key].value);
              rHours = r.getHours().toString().length < 2 ? '0' + r.getHours().toString() : r.getHours();
              rMinutes = r.getMinutes().toString().length < 2 ? '0' + r.getMinutes().toString() : r.getMinutes();

              rTime = rHours + ':' + rMinutes;              
            }

            group[question.responses[key].uniqueId] = new FormControl(
              { value: rTime, disabled: true }
            );
          }
        }

        if (question.questionType != 'TM' && question.questionType != 'TM2' && question.questionType != 'date') {
          for (var key in question.responses) {
            group[question.responses[key].uniqueId] = new FormControl(
              { value: question.responses[key].value, disabled: true }
            );
          }
        }

        if (question.questionType == 'popup') {
          question.popupQuestions.forEach((popup, i) => {
            question.responses.forEach((response, j) => {

              if (popup.questionType == 'DT' && response.responseType == 'date') {
                let date = response.value;

                if (date) {
                  date = new Date(response.value);
                }
                else {
                  date = undefined;
                }

                group[response.uniqueId] = new FormControl(
                  { value: date, disabled: true }
                );
              }
      
              if (popup.questionType == 'TM' && response.responseType == 'time') {
                let r: Date; let rHours, rMinutes, rTime;
    
                if (response.value) {
                  r = new Date(response.value);
                  rHours = r.getHours().toString().length < 2 ? '0' + r.getHours().toString() : r.getHours();
                  rMinutes = r.getMinutes().toString().length < 2 ? '0' + r.getMinutes().toString() : r.getMinutes();
    
                  rTime = rHours + ':' + rMinutes;              
                }
    
                group[response.uniqueId] = new FormControl(
                  { value: rTime, disabled: true }
                );
              }

            });
          });
        }
      }
      else {
        //Narrative
        if (question.questionType == 'narrative') {
          for (var key in question.responses) {
            group[question.responses[key].uniqueId] = new FormControl();
          }
        }

        //Text     
        if (question.questionType == 'text') {
          for (var key in question.responses) {
            group[question.responses[key].uniqueId] = new FormControl(
              question.responses[key].value, Validators.maxLength(4000)
            );
          }
        }

        //Dropdown + Radio questions     
        if (question.questionType == 'dropdown' || question.questionType == 'radio' || question.questionType == 'ldynpicture' || question.questionType == 'ldpicture' || question.questionType == 'ldrange') {
          for (var key in question.responses) {
            group[question.responses[key].uniqueId] = new FormControl(
              question.responses[key].value
            );
          }
        }

        //Date question
        if (question.questionType == 'date') {
          for (var key in question.responses) {
            let date = question.responses[key].value;

            if (date) {
              date = new Date(question.responses[key].value);
            }
            else {
              date = undefined;
            }

            group[question.responses[key].uniqueId] = new FormControl(
              date, [ 
                DateMin(question.rangeMin),
                DateMax(question.rangeMax)
              ]
            );
          }
        }

        //Time question
        if (question.questionType == 'TM' || question.questionType == 'TM2') {
          for (var key in question.responses) {
            let r: Date; let rHours, rMinutes, rTime;

            if (question.responses[key].value) {
              r = new Date(question.responses[key].value);
              rHours = r.getHours().toString().length < 2 ? '0' + r.getHours().toString() : r.getHours();
              rMinutes = r.getMinutes().toString().length < 2 ? '0' + r.getMinutes().toString() : r.getMinutes();

              rTime = rHours + ':' + rMinutes;              
            }

            group[question.responses[key].uniqueId] = new FormControl(
              rTime, Validators.pattern('^([01]?[0-9]|2[0-3]):[0-5][0-9]')
            );
          }
        }

        //Multiple dropdown
        if (question.questionType == 'multidropdown') {
          for (var key in question.responses) {
            group[question.responses[key].uniqueId] = new FormControl(
              question.responses[key].value
            );
          }
        }

        //Number question
        if (question.questionType == 'N1' || question.questionType == 'N2' || question.questionType == 'N3' || question.questionType == 'N4') {
          for (var key in question.responses) {
            group[question.responses[key].uniqueId] = new FormControl(
              question.responses[key].value, [
                Validators.min(question.rangeMin),
                Validators.max(question.rangeMax),
                (question.decimalPlaces != null ? Validators.pattern('^[+-]?[0-9]*(?:\.[0-9]{0,' + question.decimalPlaces + '})?$') : Validators.pattern('^[+-]?[0-9]*(?:\.[0-9]{0,10})?$'))
              ]
            );
          }
        }

        //Popup question
        if (question.questionType == 'popup') {
          question.popupQuestions.forEach((popup, i) => {
            question.responses.forEach((response, j) => {
              
              if (popup.questionType == 'N1' || popup.questionType == 'N2' || popup.questionType == 'N3') {
                if (response.popupItemQuestionPart == popup.displaySequence) {
                  group[response.uniqueId] = new FormControl(
                    response.value, [
                      Validators.min(popup.rangeMin),
                      Validators.max(popup.rangeMax),
                      (popup.decimalPlaces != null ? Validators.pattern('^[+-]?[0-9]*(?:\.[0-9]{0,' + popup.decimalPlaces + '})?$') : Validators.pattern('^[+-]?[0-9]*(?:\.[0-9]{0,10})?$'))
                    ]
                  );
                }
              }

              if (popup.questionType == 'LS' || popup.questionType == 'PL') {
                if (response.popupItemQuestionPart == popup.displaySequence) {
                  group[response.uniqueId] = new FormControl(
                    response.value
                  );
                }
              }

              if (popup.questionType == 'YN' || popup.questionType == 'YN2') {
                if (response.popupItemQuestionPart == popup.displaySequence) {
                  group[response.uniqueId] = new FormControl(
                    response.value
                  );
                }
              }

              if (popup.questionType == 'TX') {
                if (response.popupItemQuestionPart == popup.displaySequence) {
                  group[response.uniqueId] = new FormControl(
                    response.value
                  );
                }
              }

              //Date question
              if (popup.questionType == 'DT') {
                if (response.popupItemQuestionPart == popup.displaySequence) {
                  if (response.questionPart == popup.questionPart) {
                    let rawDate = response.value;
                    let finalDate = undefined;
                    
                    if (rawDate) {
                      finalDate = new Date(response.value).toLocaleDateString('en-GB');
                    }
  
                    group[response.uniqueId] = new FormControl(
                      finalDate, [ 
                        DateMin(popup.rangeMin),
                        DateMax(popup.rangeMax)
                      ]
                    );
                  }
                }
              }

              if (popup.questionType == 'TM' || popup.questionType == 'TM2') {
                if (response.popupItemQuestionPart == popup.displaySequence) {
                  let r: Date; let rHours, rMinutes, rTime;

                  if (response.value) {
                    r = new Date(response.value);
                    rHours = r.getHours().toString().length < 2 ? '0' + r.getHours().toString() : r.getHours();
                    rMinutes = r.getMinutes().toString().length < 2 ? '0' + r.getMinutes().toString() : r.getMinutes();

                    rTime = rHours + ':' + rMinutes;              
                  }

                  group[response.uniqueId] = new FormControl(
                    rTime, Validators.pattern('^([01]?[0-9]|2[0-3]):[0-5][0-9]')
                  );
                }
              }              

            });
          });
        }
      }
    });

    return new FormGroup(group);
  }
}

export function DateMin(date) {
  return (c: FormControl): {[key: string]: boolean} => {
    let finalDate = c.value;

    if (c.value && c.value.toString().split("/").length > 1)
    {     
      let formDate = c.value.split("/");
      let day = formDate[0];
      let month = formDate[1];
      let year = formDate[2];
      finalDate = new Date(year, month - 1, day); 
    }
    
    return (finalDate >= date && (c.value != null || c.value != "")) || c.value == null || c.value == "" || date == "" || date == null ? null : {
      dateMin: true
    }
  };
}

export function DateMax(date) {
  return (c: FormControl): {[key: string]: boolean} => {
    let finalDate = c.value;

    if (c.value && c.value.toString().split("/").length > 1)
    {     
      let formDate = c.value.split("/");
      let day = formDate[0];
      let month = formDate[1];
      let year = formDate[2];
      finalDate = new Date(year, month - 1, day); 
    }

    return (finalDate <= date && (c.value != null || c.value != "")) || c.value == null || c.value == "" || date == "" || date == null ? null : {
      dateMax: true
    }
  };
}