export class CsvDownload {
    constructor (
        public fileName: string,
        public headers: Array<string>,
        public keys: Array<string>,
        public styling: boolean,
        public buttonText?: string,
        public buttonStyle?: string,
        public icon?: string,
    ) {}
}