import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';

import { NgPipesModule } from 'ngx-pipes';
import { Ng2HighchartsModule } from 'ng2-highcharts';
import { CsvDownloadModule } from 'app/shared/csv-download/csv-download.module';
import { ModalModule, TooltipModule } from 'ngx-bootstrap';
import { DataValidationComponent } from './data-validation.component';
import { DataValidationRoutingModule } from './data-validation-routing.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DataValidationRoutingModule,
    NgPipesModule,
    Ng2HighchartsModule,
    ModalModule.forRoot(),
    CsvDownloadModule,
    TooltipModule
  ],
  providers: [
  ],
  declarations: [ 
    DataValidationComponent
  ]
})
export class DataValidationModule {}