import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectComponent } from './project.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const projectRoutes: Routes = [
  { path: 'project/:projectId', redirectTo: 'project/:projectId/dashboard', pathMatch: 'full' },
  { path: 'project/:projectId/collection', redirectTo: 'collection/:projectId', pathMatch: 'full' },
  { path: 'project/:projectId/:view', component: ProjectComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(projectRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProjectRoutingModule { }