<div *ngIf="loading" id="loading">
    <i class="fas fa-spinner fa-spin"></i> Loading validation points
</div>

<div *ngIf="!loading" id="fullValidation">
    <div class="container">
        <h1>{{ projectDetails.projectName }}</h1>
        <h3>Toolkit Validation</h3>

        <div class="filters">
            <button class="btn btn-default" (click)="getValidations(projectId)"><i class="far fa-sync-alt"></i>Refresh</button>
            <button class="btn btn-default" (click)="toggleValidated()"><i [class]="showValidated ? 'far fa-eye-slash' : 'far fa-eye'"></i>{{ showValidated ? 'Hide' : 'Show' }} Validated</button>
            <angular2csv [data]="validations" filename="validation" [options]="csvOptions"></angular2csv>
        </div>
    
        <div class="validation-group" *ngFor="let group of validations | groupBy: 'groupName' | pairs">
            <div class="row">
                <div class="col-md-9">
                    <div *ngIf="group[0] === 'null'; then noSubmission else submission"></div>
                    <ng-template #submission>
                        <h2>
                            {{ group[0] }}<br>
                            <small>Project Lead: {{ group[1][0].projectLeadName }}</small>
                        </h2>
                    </ng-template>
                    <ng-template #noSubmission>
                        <h2>
                            <i>Not aligned to a submission</i>
                        </h2>
                    </ng-template>
                </div>
                <div class="col-md-3 text-right">
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-default dropdown-toggle" aria-controls="dropdown-basic"
                            (click)="emailLeads(group[1][0].validationId, projectId, group[1][0].validationYear, group[1][0].submissionId)"
                            *ngIf="group[1][0].projectLeadName">
                            <i class="fas fa-envelope"></i>Email Options
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" href="#" [innerHtml]="mailtoPl"></a></li>
                            <li role="menuitem"><a class="dropdown-item" href="#" [innerHtml]="mailtoDpls"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th style="width:10%">Status</th>
                        <th style="width:30%">Chart (Point)</th>
                        <th style="width:30%">Question</th>
                        <th style="width:15%">Response</th>
                        <th style="width:15%;text-align:center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let v of group[1]">
                        <td *ngIf="v.status != 'validated'">
                            <span *ngIf="(v.createdAt > v.lastUpdated || !v.lastUpdated)">
                                <span class="status -pending">Pending</span>
                            </span>
                            <span *ngIf="v.questionId && v.createdAt < v.lastUpdated">
                                <span class="status -updated">Updated</span>
                            </span>
                        </td>
                        <td *ngIf="v.status === 'validated'">
                            <span class="status -validated">Validated</span>
                        </td>
                        <td (click)="navChart(v.tierId)">
                            <div>
                                <a class="link" href="/outputs/{{projectId}}?tier={{v.tierId}}" target="_blank" (click)="navChart(v.tierId)">{{ v.reportName }}</a>
                                <span *ngIf="v.validationDescription"> ({{ v.validationDescription }})</span>
                            </div>
                            <div *ngIf="v.serviceItemName" class="service-item"> {{ v.serviceItemName }}</div>
                        </td>
                        <td>
                            <span *ngIf="!v.submissionId || v.isGeneric != 'Y'">
                                <i>Cannot be linked to a question</i>
                            </span>
                            <span *ngIf="v.submissionId && v.isGeneric === 'Y' && activeValidation != v.validationId && v.questionId === null" 
                                (click)="getValidationResponses(v.validationId, v.reportId, v.validationYear, v.submissionId, v.serviceItemId)" 
                                class="response-add">
                                Link to Question
                            </span>
                            <div *ngIf="responses && activeValidation === v.validationId" class="response-dd">
                                <div class="cancel" (click)="responses = []; activeValidation = null">
                                    <span class="response-add">Cancel</span>
                                </div>
                                <div *ngFor="let r of responses" class="response" (click)="saveResponse(v.validationId, v.submissionId, r.questionId, r.questionPart, 0)">
                                    <strong>{{ r.questionText }}</strong><br>
                                    <span *ngIf="r.nestedQuestionText">{{ r.nestedQuestionText }}<br></span>
                                    Current response: {{ r.response }}
                                </div>
                            </div>
                            <span *ngIf="v.questionId > 0">
                                <a *ngIf="v.serviceItemId" class="link" href="/data-collection/{{ v.questionGroupLevel }}/{{ v.submissionId }}/{{ v.validationYear }}/{{ v.serviceItemId }}?group={{ v.questionGroupId }}" target="_blank">
                                    {{ v.questionText }} <span *ngIf="v.nestedQuestionText">- {{ v.nestedQuestionText }}</span>
                                </a>
                                <a *ngIf="!v.serviceItemId" class="link" href="/data-collection/{{ v.questionGroupLevel }}/{{ v.submissionId }}/{{ v.validationYear }}?group={{ v.questionGroupId }}" target="_blank">
                                    {{ v.questionText }} <span *ngIf="v.nestedQuestionText">- {{ v.nestedQuestionText }}</span>
                                </a>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="v.questionId">
                                {{ v.response }}
                            </span>
                            <span *ngIf="!v.questionId">
                                -
                            </span>
                        </td>
                        <td class="actions">
                            <i [class]="v.status === 'validated' ? 'fas fa-times-square -unvalidate' : 'fas fa-check-square -validate'" 
                               (click)="changeValidationStatus(v.validationId, v.status === 'validated' ? 'pending' : 'validated')" 
                               tooltip="Mark as validated"></i>
                            <i class="fas fa-plus-square -duplicate" (click)="duplicatePoint(v)" tooltip="Duplicate"></i>
                            <i class="fas fa-minus-square -delete" (click)="deleteValidation(v.validationId)" tooltip="Delete"></i>
                            <i class="fas fa-info-square -duplicate" popover="Added by {{ v.validatorName }}"></i>
                            <i [class]="v.notes ? 'fas fa-pen-square -notes' : 'far fa-pen-square -notes'" *ngIf="v.questionId" (click)="buildValidationForm(v.validationId, v.validationResponseId)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    
        <div *ngIf="currentValidation > 0" id="validationNote">
            <form [formGroup]="validationNote" class="notesForm" novalidate>
                <div class="form-group">
                    <textarea rows="5" class="form-control" formControlName="validationNote"></textarea>
                </div>
                <button type="button" class="btn btn-success" (click)="editValidationNote()">Save</button>
                <button type="button" class="btn btn-default" (click)="currentValidation = null">Cancel</button>
            </form>
        </div>

    </div>
</div>