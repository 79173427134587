<div class="event-group" style="margin-bottom: 2em;">
  <h2><i [class]="section.icon"></i><b style="margin-left: 1rem;">{{ section.title }}</b></h2>

  <p *ngIf="section.title == 'NHSFutures'">
    Visit our <a href="https://future.nhs.uk/BenchmarkingNetwork" target="_blank" style="text-decoration: underline;">
      NHSFutures workspace</a> and get involved in the discussions.
  </p>

  <p *ngIf="section.title !== 'NHSFutures'">Visit our <a [routerLink]="[section.link]" routerLinkActive="active">
    <u>{{ section.title }}</u></a> {{ section.description }}</p>

    <div *ngFor="let data of section.data | slice:0:3">

      <!-- Events -->
      <div *ngIf="section.title == 'Events'" class="event-list">
        <span>{{ data.eventDt | date: "d" }}</span>
        <div class="event-info">
          <b class="sidebar-link" (click)="selectedEvent(data.eventId)"> {{ data.eventName }}</b> <br />
          <small>{{ data.eventDt | date: "d MMMM y / H:mm":'GMT' }} - {{ data.eventEndDt | date: "H:mm":'GMT' }}</small>
        </div>
      </div>

      <!-- Your Reports -->
      <!-- <div *ngIf="section.title == 'Your Reports'" class="event-list">
        <span>{{ data.uploadTime | date: "d" }}</span>
        <div class="event-info">
          <b> {{ data.documentName }}</b> <br />
          <small>{{ data.documentCategoryName }} / {{ data.year }}</small>
        </div>
      </div> -->

      <!-- Resources -->
      <div *ngIf="section.title == 'Resources'" class="event-list">
        <span>{{ data.uploadTime | date: "d" }}</span>
        <div class="event-info">
          <b class="sidebar-link" (click)="getSelectedDocument(data.documentId)" tooltip="{{ data.documentName }}"> 
            {{ data.documentName | slice:0:50 }}{{ data.documentName.length > 50 ? '...' : '' }}</b> <br />
          <small>{{ data.documentCategoryName }} / {{ data.year }} <i style="padding-left: 5px;" class="far fa-file-download"></i></small> 
        </div>
      </div>

    </div>
</div>