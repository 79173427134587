import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment'

@Injectable()
export class IcsService {
  private URL: string = environment.icsApiURL;

  constructor(private http: HttpClient) { }

  public getOrganisations(type?: string): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (type) {
      params = params.append('orgType', type);
    }
    
    return this.http.get(
      this.URL + 'organisations', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getTiers(projectId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'tiers', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('projectId', projectId.toString())
      }
    );
  }

  public getPreferences(): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'preferences', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getReportData(reportId: number, tierId?): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    params = params.set('reportId', reportId.toString());

    if (tierId) {
      params = params.append('tierId', tierId.toString());
    }

    return this.http.get(
      this.URL + 'reports/' + reportId + '/data', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public setPreference(preference): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'preferences', JSON.stringify(preference), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public removePreference(preferenceId): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.delete(
      this.URL + 'preferences/' + preferenceId, {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  // BENCHMARKER

  public reportDetails(reportId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'reports/' + reportId + '/reportDetails', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public reportData(reportId: number, dateId: number, categoryIds?): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    params = params.set('dateId', dateId.toString());

    if (categoryIds) {
      categoryIds.forEach(c => params = params.append('selectedCategory', c.toString()));
    }
    
    return this.http.get(
      this.URL + 'reports/' + reportId + '/reportDataSpecificOptions', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public supplementaryData(reportId: number): Observable<any> {
    let token = localStorage.getItem('id_token');
    
    return this.http.get(
      this.URL + 'reports/' + reportId + '/supplementaryData', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public reportDataAll(reportId: number, categoryIds?, organisationId?): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (organisationId) {
      params = params.set('organisationId', organisationId.toString())
    }

    if (categoryIds) {
      categoryIds.forEach(c => params = params.append('selectedCategory', c.toString()));
    }
    
    return this.http.get(
      this.URL + 'reports/' + reportId + '/reportDataDatesSpecificOptions', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public reportDataSeries(reportId: number, organisationId?): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (organisationId) {
      params = params.set('organisationId', organisationId.toString())
    }
    
    return this.http.get(
      this.URL + 'reports/' + reportId + '/categoryData', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public timeseriesData(reportId: number, organisationId: number, categoryIds?): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    params = params.set('selectedOrg', organisationId.toString());

    if (categoryIds) {
      categoryIds.forEach(c => params = params.append('selectedCategory', c.toString()));
    }

    return this.http.get(
      this.URL + 'reports/' + reportId + '/reportTimeseries', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public dashboard(projectId: number, submissionId?: number, dateId?: number, groupName?: string): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (submissionId) {
      params = params.set('submissionId', submissionId.toString());
    }

    if (dateId) {
      params = params.append('dateId', dateId.toString());
    }

    if (groupName) {
      params = params.append('groupName', groupName);
    }
    
    return this.http.get(
      this.URL + 'projects/' + projectId + '/dashboard', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public reportGroups(projectId: number, groupType?: string): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (groupType) {
      params = params.set('groupType', groupType);
    }
    
    return this.http.get(
      this.URL + 'projects/' + projectId + '/reportGroups', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public report(projectId: number, dateId: number, organisationId?: number): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    params = params.set('dateId', dateId.toString());

    if (organisationId) {
      params = params.append('organisationId', organisationId.toString());
    }
    
    return this.http.get(
      this.URL + 'projects/' + projectId + '/pdfReport', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public participants(projectId: number, dateId?: number): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (dateId) {
      params = params.set('dateId', dateId.toString());
    }
    
    return this.http.get(
      this.URL + 'projects/' + projectId + '/participants', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public participantSubmissions(projectId: number, dateId?: number): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (dateId) {
      params = params.set('dateId', dateId.toString());
    }
    
    return this.http.get(
      this.URL + 'projects/' + projectId + '/participantSubmissions', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  // REGISTRATION

  public addSubmissions(projectId: number, submissions) {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'projects/' + projectId + '/addSubmissions', JSON.stringify(submissions), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public modifySubmission(projectId: number, externalSubmissionId: number, submission) {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'projects/' + projectId + '/modifySubmission', JSON.stringify(submission), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json'),
        params: new HttpParams().set('externalSubmissionId', externalSubmissionId.toString())
      }
    );
  }

  public deleteSubmission(projectId: number, externalSubmissionId: number) {
    let token = localStorage.getItem('id_token');

    return this.http.delete(
      this.URL + 'projects/' + projectId + '/deleteSubmission', {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json'),
        params: new HttpParams().set('externalSubmissionId', externalSubmissionId.toString())
      }
    );
  }

  // COLLECTION

  public submissions(projectId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'projects/' + projectId + '/submissions', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public collection(projectId: number, dateId: number, submissionId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'projects/' + projectId + '/dataCollection', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('dateId', dateId.toString()).set('submissionId', submissionId.toString())
      }
    );
  }

  public saveResponses(projectId: number, dateId: number, submissionId: number, responses) {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    params = params.set('dateId', dateId.toString()).set('submissionId', submissionId.toString());

    return this.http
      .post(this.URL + 'projects/' + projectId + '/saveResponses', JSON.stringify(responses), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json'),
        params: params
      });
  }

  public getConsent(projectId: number, organisationCode: string, organisationType: string): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'projects/' + projectId + '/getOrganisationConsent', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('organisationCode', organisationCode.toString()).set('organisationType', organisationType.toString())
      }
    );
  }

  public saveConsent(projectId: number, consent) {
    let token = localStorage.getItem('id_token');

    return this.http
      .post(this.URL + 'projects/' + projectId + '/setOrganisationConsent', JSON.stringify(consent), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      });
  }

}