import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CsvDownload } from 'app/utilities/classes/csv/csv-download';
import { DataService } from 'app/utilities/services/data.service';
import { MemberService } from 'app/utilities/services/member.service';

import { ValidationProject } from './outputs-validation';

import { OutputValidationService } from './outputs-validation.service';

@Component({
  selector: 'app-outputs-validation',
  templateUrl: './outputs-validation.component.html',
  styleUrls: ['./outputs-validation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutputsValidationComponent implements OnInit {

  public errorMessage: string;
  public loadingMessage: string;

  public currentUser: any; // MODEL

  public selectedProjectId: number;
  public selectedYear: number;
  public selectedOptionId: number;
  public selectedPeerGroupId: number;
  public selectedPoint: any;
  public selectedValidation: any;

  public project: ValidationProject;

  public mean: number;
  public median: number;

  public tierSearch: string;
  public searchableTiers: any; // MODEL
  public validationSearch: string;
  public searchableColumns: string[] = ['status','tierId','reportName','validationDescription','submissionCode','submissionId','submissionName', 'organisationName', 'projectLeadName','questionId','displayQuestionText','note','validatorName'];

  public selectedTier: any; // MODEL
  public chartObject; // NEEDS TYPE

  public availableResponses: any; // MODEL

  public validationDescriptionForm: FormGroup;
  public validationNoteForm: FormGroup;

  public validationPosted: boolean = false;
  public responsePosted: boolean = false;
  public notePosted: boolean = false;
  public descriptionPosted: boolean = false;

  public showListView: boolean = true;
  public tableFilter: string = '-validationId';

  public csvSettings: CsvDownload = {
    fileName: 'validations',
    buttonText: 'Download (.csv)',
    buttonStyle: 'btn btn-info',
    icon: '',
    headers: ['Status', 'Tier ID', 'Report ID', 'Report Name', 'Submission Code', 'Submission ID', 'Submission Name', 'Organisation Name', 'Service', 'Question ID', 'Question Part', 'Question Text', 'Question Response', 'External Note', 'Assignee', 'Data Collection Link', 'Outputs Link', 'Internal Note'],
    keys: ['status', 'tierId', 'reportId', 'reportName', 'submissionCode', 'submissionId', 'submissionName', 'organisationName', 'serviceItemName', 'questionId', 'questionPart', 'displayQuestionText', 'response', 'validationDescription', 'validatorName', 'dataCollectionLink', 'outputsLink', 'notes'],
    styling: true
  }

    // Data Collection Link
    // Chart Link

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private dataService: DataService,
    private outputValidationService: OutputValidationService
  ) {
    this.selectedProjectId = +this.route.snapshot.paramMap.get('projectId');
    this.selectedYear = +this.route.snapshot.paramMap.get('year');
  }

  ngOnInit() {
    this.currentUserCheck();
  }

  private currentUserCheck(): void {
    this.memberService.User().subscribe(
      success => {
        this.currentUser = success.data;
        if (this.currentUser && this.currentUser.isAdmin == 'Y') {
          this.getTiers(this.selectedProjectId, this.selectedYear);
        }
      },
      error => {
        console.log(error);
        this.errorMessage = error.error.error.message;
      }
    )
  }

  private getTiers(projectId: number, year: number): void {
    this.loadingMessage = "Loading validations. Please wait...";
    this.outputValidationService.getProjectTiers(projectId, year).subscribe(
      success => {
        this.project = new ValidationProject(success.data);
        this.searchableTiers = this.project.tiers.filter(tier => tier.reportId);
        if (this.project.tiers.length) {
          this.getValidations(projectId, year);
        } else {
          this.errorMessage = "There are no tiers defined for this project/year combination."
        }
      },
      error => {
        this.errorMessage = "There was an error retrieving tiers for this project/year combination."
        console.log(error);
      }
    )
  }

  private getValidations(projectId: number, year: number): void {
    this.outputValidationService.getValidationsOld(projectId, year).subscribe(
      success => {
        this.project.setValidationsToTiers(success.data.validations, this.selectedProjectId, this.selectedYear);
        this.project.setFilters(success.data.validations);
        // If tier pre-selected
        let selectedTierId = +this.route.snapshot.queryParams['tier'];
        if (selectedTierId) {
          let selectedTier = this.project.tiers.find(tier => tier.id == selectedTierId);
          this.getTier(selectedTier, 0, 0);
        }
      },
      error => {
        console.log(error);
      },
      () => {
        this.loadingMessage = null;
      }
    )
  }

  public getTier(tier: any, optionId?: number, peerGroupId?: number): void {
    this.tierSearch = null;
    this.loadingMessage = "Loading report details and data. Please wait...";
    this.errorMessage = null;
    this.chartObject = null;
    this.showListView = false;
    this.selectedOptionId = optionId || 0;
    this.selectedPeerGroupId = peerGroupId || 0;
    this.outputValidationService.getTier(tier.reportId, 232, this.selectedPeerGroupId, this.selectedYear, tier.submissionLevel, tier.serviceItemId, this.selectedOptionId).subscribe(
      success => {
        tier.report = this.project.setReportDetails(success.data, this.selectedOptionId, this.selectedYear);
        tier.data = this.project.setValidationsToData(tier.validations, success.data.yearData[this.selectedYear], tier.report.viewTypes[0].viewType);
        let values = tier.data.map(d => d.y);
        this.mean = this.project.mean(values);
        this.median = this.project.median(values);
        this.selectedTier = tier;
        this.router.navigate([], { queryParams: { tier: tier.id }, queryParamsHandling: 'merge' });
        let chartTypeCheck: boolean = this.project.chartTypeCheck(tier.report.viewTypes[0]);
        let isGenericCheck: boolean = tier.report.storedProcedure.includes('generic');
        if (chartTypeCheck && isGenericCheck) {
          this.buildChart(tier.data, this.mean, this.median);
        } else {
          this.errorMessage = `<strong>This chart is not yet available for validation.</strong> This may be because of the chart type (${tier.report.viewTypes[0].viewType}) or because the chart is not built using parameters.`
        }
      },
      error => {
        this.errorMessage = error.error.error.message;
        console.log(error);
      },
      () => {
        this.loadingMessage = null;
        document.getElementById(tier.id).scrollIntoView();
        window.scroll(0,0);
      }
    ) 
  }

  public buildChart(data: any, mean: number, median: number): void {
    this.chartObject = {
      colors: [ 'lightgray' ],
      chart: { style: { fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',  }},
      title: { text: '', style: { fontWeight: 'bold' } },
      xAxis: { categories: data.map(d => d.submissionCode),
        labels: {
          rotation: data.length > 25 ? 60 : 0,
          step: 1,
          formatter () {
            let color = data.find(d => d.submissionCode == this.value).color || null;
            return `<span style="color:${color}">${this.value}</span>`
          }
        },
        title: '', 
        tickInterval: undefined },
      yAxis: { 
        title: { text: null },
        min: null,
        max: null,
        labels: {},
        plotLines: [
          {
            color: '#009639',
            value: mean,
            width: '2',
            zIndex: 10
          },
          {
            color: '#78BE20',
            value: median,
            width: '2',
            zIndex: 11
          }
        ],
        tickInterval: undefined,
        dateTimeLabelFormats: {}
      },
      tooltip: {},
      exporting: { enabled: false },
      plotOptions: { 
        series: { 
          cursor: 'pointer',
          animation: false
        }
      },
      credits: { text: ''},
      legend: { enabled: false },
      series: [
        {
          type: 'column',
          data: data
        }
      ]
    };
  }

  public chartClick(event: any): void {
    // Remove any errors
    this.errorMessage = null;
    // Define point data
    let pointData: any;
    // Highcharts bar selected
    if (event.point) {
      pointData = {
        submissionCode: +event.point.category.substr(2),
        submissionId: event.point.submissionId,
        y: event.point.y
      };
    }
    // Highcharts axis label selected
    else if (event.target.innerHTML) {
      let point = this.selectedTier.data.find(d => d.submissionCode === event.target.innerHTML);
      pointData = {
        submissionCode: point.submissionCode.substr(2),
        submissionId: point.submissionId,
        y: point.y
      };
    } 
    // Check for no codes and duplicates, then build
    let submissionCodeNaN = isNaN(+pointData.submissionCode);
    let duplicatePoint = this.selectedTier.validations.find(vld => vld.submissionCode == pointData.submissionCode);

    if (pointData && !duplicatePoint && !submissionCodeNaN) {
      this.editValidation(pointData, true);
    }
    else if (pointData && submissionCodeNaN) {
      alert('Submission codes have not been assigned. Codes must be assigned before validation can begin.')
    } 
    else if (pointData && duplicatePoint) {
      alert('This point has already been added for validation.')
    }
    else {
      console.log('No point selected')
    }
  }

  public buildValidationDescriptionForm(validation: any, validationDescription?: string): void {
    //this.selectedPoint = pointData;
    this.validationDescriptionForm = new FormGroup({
      validationDescription: new FormControl(validation.validationDescription ? validation.validationDescription : ''),
    });
  }

  public postValidation(pointData: any): void {

    let submissionCodeCheck = this.selectedTier.validations.find(vld => vld.submissionCode == pointData.submissionCode);

    if (!submissionCodeCheck) {
      let validation = {
        validationId: undefined,
        year: this.selectedYear,
        tierId: this.selectedTier.id,
        submissionCode: pointData.submissionCode,
        validationValue: pointData.y,
        serviceItemId: this.selectedTier.serviceItemId,
        validationDescription: '',
        optionId: this.selectedOptionId
      }
  
      this.outputValidationService.postValidation(validation).subscribe(
        success => {
  
          validation.validationId = success.data.newValidationId;
          validation['submissionId'] = pointData.submissionId;
          validation['validatorName'] = this.currentUser.fullName;
          validation['submissionName'] = success.data.submissionName;
          validation['reportId'] = this.selectedTier.reportId;
          validation['reportName'] = this.selectedTier.report.reportName;
          validation['status'] = null;
          validation['createdAt'] = null;
          validation['lastUpdated'] = null;
          validation['questionId'] = null;
          validation['optionId'] = this.selectedOptionId;
          validation['titleOptionName'] = this.selectedTier.report.options.denominators.find(den => { den.optionId == this.selectedOptionId }) || null;
  
          this.project.validations.push(validation);
          this.project.setValidationsToTiers(this.project.validations, this.selectedProjectId, this.selectedYear);
          this.project.setValidationsToData(this.selectedTier.validations, this.selectedTier.data, this.selectedTier.report.viewTypes[0].viewType);
          this.buildChart(this.selectedTier.data, this.mean, this.median);

          this.selectedValidation = validation;
  
          this.getValidationResponses(validation);
        },
        error => {
          console.log(error);
        }
      )
    } else {
      alert('This point has already been added for validation.')
    }
  }

  public getValidationResponses(validation: any): void {
    // Availability of reportId and serviceItemId varies on use case
    let reportId = validation.reportId ? validation.reportId : this.selectedTier.reportId;
    let serviceItemId = this.selectedTier ? this.selectedTier.serviceItemId : validation.serviceItemId;
    this.outputValidationService.getValidationResponses(reportId, this.selectedYear, validation.submissionId, serviceItemId || 0).subscribe(
      success => { 
        this.availableResponses = this.project.setAvailableResponses(success.data.responses, this.selectedYear, validation, this.responsePosted);
      },
      error => { console.log(error) }
    )
  }

  public setResponse(response: any, validation?: any): void {
    let validationResponse = {
      validationId: response.validationId,
      submissionId: response.submissionId, 
      questionId: response.questionId, 
      questionPart: response.questionPart, 
      serviceItemId: validation ? validation.serviceItemId : this.selectedTier.serviceItemId
    };
    this.outputValidationService.addValidationResponse(validationResponse).subscribe(
      success => {
        let validation = this.project.validations.find(vld => vld.validationId == response.validationId);
        validation.validationResponseId = success.data.newValidationResponseId;
        validation.response = response.response;
        validation.displayQuestionText = response.displayQuestionText;
        validation.questionId = response.questionId;
        validation.questionPart = response.questionPart;

        this.buildValidationNoteForm(validation);
        this.responsePosted = true;
        response.set = true;
      },
      error => {
        console.log(error);
      }
    )
  }

  public buildValidationNoteForm(validation: any, notes?: string): void {
    this.validationNoteForm = new FormGroup({
      validationResponseId: new FormControl(validation.validationResponseId),
      notes: new FormControl(validation.notes ? validation.notes : ''),
    });
  }

  public editValidationNote(): void {
    let updatedValidation = {
      validationResponseId: this.validationNoteForm.value.validationResponseId,
      note: this.validationNoteForm.value.notes
    }
    this.outputValidationService.editValidationNote(updatedValidation).subscribe(
      success => {
        let validation = this.project.validations.find(vld => vld.validationResponseId == updatedValidation.validationResponseId);
        validation.notes = this.validationNoteForm.value.notes;
        this.validationNoteForm.markAsPristine;
        this.notePosted = true;
        setTimeout(() => { this.notePosted = false }, 2000);
      },
      error => {
        console.log(error);
      }
    )
  }

  public editValidationDescription(selectedValidation: any): void {
    let updatedValidation = {
      validationId: selectedValidation.validationId,
      description: this.validationDescriptionForm.value.validationDescription
    }
    this.outputValidationService.editValidationDescription(updatedValidation).subscribe(
      success => {
        selectedValidation.validationDescription = this.validationDescriptionForm.value.validationDescription;
        this.validationDescriptionForm.markAsPristine;
        this.descriptionPosted = true;
        setTimeout(() => { this.descriptionPosted = false }, 2000);
      },
      error => {
        console.log(error);
      }
    )
  }

  public deleteValidation(validationId: number): void {
    if (confirm("Are you sure you want to delete this validation? This action cannot be undone.")) {
      this.outputValidationService.deleteValidation(validationId).subscribe(
        success => {
          this.project.validations = this.project.validations.filter(v => v.validationId !== validationId);
          this.project.setValidationsToTiers(this.project.validations, this.selectedProjectId, this.selectedYear);
          this.project.setValidationsToData(this.selectedTier.validations, this.selectedTier.data, this.selectedTier.report.viewTypes[0].viewType);
          this.buildChart(this.selectedTier.data, this.mean, this.median);
          this.selectedValidation = null;
        },
        error => {
          console.log(error);
        }
      )
    }
  }

  public editValidationStatus(validationId: number, status: string): void {
    let updatedValidation = {
      validationId: validationId,
      status: status
    }
    this.outputValidationService.editValidationStatus(updatedValidation).subscribe(
      success => {
        let validation = this.project.validations.find(vld => vld.validationId == validationId);
        validation.status = status;
        this.project.setValidationsToTiers(this.project.validations, this.selectedProjectId, this.selectedYear);
        this.project.setValidationsToData(this.selectedTier.validations, this.selectedTier.data, this.selectedTier.report.viewTypes[0].viewType);
        this.buildChart(this.selectedTier.data, this.mean, this.median);       
      },
      error => {
        console.log(error);
      }
    )
  }

  public dataCollectionLink(validation: any): void {
    let url: string;
    if (validation.serviceItemId > 0) {
      url = '/data-collection/' 
        + validation.questionGroupLevel + '/' 
        + validation.submissionId + '/' 
        + validation.validationYear + '/' 
        + validation.serviceItemId + '?group=' 
        + validation.questionGroupId;
    } else {
      url = '/data-collection/' 
        + validation.questionGroupLevel + '/' 
        + validation.submissionId + '/'
        + validation.validationYear + '?group=' 
        + validation.questionGroupId;
    }
    window.open(url, '_blank');
  }

  public filterByColumn(column: string, value: string): void {
    if (column == 'notes' && value == null) { value = "" };
    this.project.validations = this.project.rawValidations.filter(vld => vld[column] == value);
  }

  public switchView(showListView: boolean): void {
    this.errorMessage = null;
    if (showListView) {
      this.router.navigate([], { queryParams: { tier: null }, queryParamsHandling: 'merge' });
      this.showListView = true;
    } else {
      this.showListView = false;
      this.selectedTier = null;
    }
  }

  public refreshValidations(): void {
    this.project.validations = this.project.rawValidations;
    this.project.filters.forEach(fil => fil.selected = null);
  }

  public checkResponsePosted(): boolean {
    return this.availableResponses.map(resp => resp.set).indexOf(true) > -1;
  }

  public toggleTierCollapsed(tier: any, collapse?: boolean): void {
    tier.children = this.project.toggleChildren(tier.children, collapse);
  }

  public editValidation(validation: any, create: boolean): void {
    this.selectedValidation = validation;
    if (create) {
      this.postValidation(validation);
    }
    this.getValidationResponses(validation);
    this.buildValidationDescriptionForm(validation);
    this.buildValidationNoteForm(validation)
  }

  public getSearchTier(tier: any, allTiers: any): void {
    this.loadingMessage = "Loading report details and data. Please wait...";
    callRecursively(tier, allTiers);
    function callRecursively(tier: any, tiers: any) {
      let parent = tiers.find(t => t.id == tier.parentId);
      if (parent) { 
        parent.children.forEach(child => child.collapsed = false);
        callRecursively(parent, tiers)
      }
    }
    this.getTier(tier);
  }
  
  public sortSubmissions(){
    if(this.tableFilter == 'submissionName') {
      this.tableFilter = '-validationId';
    } else {
      this.tableFilter = 'submissionName';
    }
  }
}