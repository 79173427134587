import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentPanelComponent } from "./document-panel.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap";
import { NgPipesModule } from "ngx-pipes";

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    NgPipesModule
  ],
  declarations: [DocumentPanelComponent],
  exports: [DocumentPanelComponent]
})
export class DocumentPanelModule {}