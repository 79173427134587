import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { MemberService } from './member.service';

@Injectable()
export class AuthGuardService {
  constructor(
    private authService: AuthService,
    private router: Router,
    private memberService: MemberService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.authenticated()) {
      if (this.authService.confirmed()) {
        return true;
      } else {
        this.router.navigate(["/userCheck"]);
        return false;
      }
    } else {
      this.router.navigate(["/landing"], { queryParams: { returnUrl: state.url }, });
      return false;
    }
  }
}
