<div class="modal-header">
  <h5 class="modal-title">Edit Tier Name</h5>
</div>
<div class="modal-body" *ngIf="editTierForm">
  <form [formGroup]="editTierForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="tierName">Tier Name <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            formControlName="tierName"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Tier Visibility: </label>
          <select formControlName="isVisible" class="form-control">
            <option value="Y">Visible</option>
            <option value="H">Hidden</option>
          </select>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12">
      <p class="text-muted">
        <i class="fas fa-info-circle"></i> Any tier can be edited by selecting it in the tree structure.
      </p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-md-8 text-left" *ngIf="editTierForm">
      <button
        type="button"
        class="btn btn-success"
        (click)="updateReport(editTierForm)"
        [disabled]="editTierForm?.invalid || errorMessage"
      >
        Save and Reload
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="editTierModal.hide()"
      >
        Cancel
      </button>
    </div>
  </div>
  <div class="row error" *ngIf="errorMessage">
    <div class="col-md-12 mt-2">
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</div>
