import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { environment } from "environments/environment";
import { User } from "./utilities/classes/user/user";

import { AuthService } from "./utilities/services/auth.service";
import { MemberService } from "./utilities/services/member.service";

import { OrderByPipe } from "ngx-pipes";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  date = new Date();
  isCollapsed = true;
  user: User;
  userName: String;
  isPopupVisible: boolean = true;

  // Notifications
  showNotifications: boolean = false;
  checkedNotifications: boolean = false;
  notifications = [];
  unreadNotifications = [];
  // popupModalOpen: boolean = true;
  bannerMessage: string = `Stay informed with NHS Benchmarking Network updates by adding <span>enquiries&#64;nhsbenchmarking.nhs.uk</span> to your safe sender list. For login and event registration replies, also add <span>donotreply&#64;nhsbenchmarking.nhs.uk</span>.`
  constructor(
    public auth: AuthService,
    public router: Router,
    public orderBy: OrderByPipe,
    private ccService: NgcCookieConsentService,
    private memberService: MemberService
  ) { }

  ngOnInit() {
    // this.setModal();
    if (sessionStorage.getItem("eventPopup") == "N") {
      this.isPopupVisible = false;
    } else {
      this.isPopupVisible = true;
    }
  }

  closePopup() {
    this.isPopupVisible = false;
    sessionStorage.setItem("eventPopup", "N");
  }

  getUserDetails() {
    this.memberService.User().subscribe((u) => {
      this.user = u.data;
      this.userName = this.user.fullName;
      const fullName = this.userName.split(" ");
      this.userName = fullName.shift() + " " + fullName.pop().charAt(0);
      this.userName.toUpperCase();
    });
  }

  getNotifications(event) {
    let landing = Object.keys(event).includes("statusLogin"), // Proxy for /landing
      storedNotifications = sessionStorage.getItem("notifications") || null;
    if (!landing && !storedNotifications) {
      this.memberService.getNotifications().subscribe(
        (r) => {
          this.notifications = this.formatNotifications(r.data.notifications);
          this.getUserDetails();
        },
        (e) => {
          console.log(e);
        }
      );
    } else if (storedNotifications) {
      var parsedNotifications = JSON.parse(storedNotifications);
      this.notifications = this.formatNotifications(parsedNotifications);
      this.getUserDetails();
    }
  }

  formatNotifications(data) {
    data.forEach((d) => {
      if (d.dismissedDt === null) {
        d.order = 1;
      } else {
        d.order = 2;
      }
    });
    data = this.orderBy.transform(data, ["order", "notificationId"]);
    this.unreadNotifications = data.filter((n) => n.dismissedDt === null);
    sessionStorage.setItem("notifications", JSON.stringify(data));
    return data;
  }

  dismissNotification(notificationId, status) {
    let dismissed;
    if (status === null) {
      dismissed = { dismissed: "Y" };
    } else {
      dismissed = { dismissed: "N" };
    }
    this.memberService.dismissNotification(notificationId, dismissed).subscribe(
      (r) => {
        let dateTime = new Date(),
          notification = this.notifications.filter(
            (n) => n.notificationId === notificationId
          )[0];

        if (notification.dismissedDt) {
          notification.dismissedDt = null;
        } else {
          notification.dismissedDt = dateTime;
        }

        this.notifications = this.formatNotifications(this.notifications);
      },
      (e) => {
        console.log(e);
      }
    );
  }

  dismissAllNotifications() {
    this.unreadNotifications.forEach((n) => {
      this.dismissNotification(n.notificationId, n.dismissedDt);
    });
  }

  navNotification(linkUrl) {
    if (linkUrl) {
      this.router.navigate([linkUrl]);
      this.showNotifications = false;
    }
  }

  logout() {
    localStorage.removeItem("id_token");
    sessionStorage.removeItem("savedTiers");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("notifications");
    this.router.navigate(["/landing"]);
  }

  // setModal() {
  //   if(sessionStorage.getItem("popup") !== "visible") {
  //     this.popupModalOpen = false;
  //   } else {
  //     sessionStorage.setItem("popup", "visible");
  //     this.popupModalOpen = true;
  //   }

  //   if(sessionStorage.getItem("popup") == null){
  //     sessionStorage.setItem("popup", "visible");
  //     this.popupModalOpen = true;
  //   }
  // }

  // closeModal() {
  //   sessionStorage.setItem("popup", "hidden");
  //   this.popupModalOpen = false;
  // }

  onHidden(): void { }

  onShown(): void { }

  isOpenChange(): void { }
}
