import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormatService } from 'app/utilities/ics/format.service';

export interface Tier {
  tierId: number
  tierName: string
  displaySequence: number
  reportId: number
  isVisible: string
  tierTypeId: number
  tierTypeName: string
  childrenTiers: Tier[]
  tierTags: string[]
  name: string
  id: number
  hasChildren?: boolean
  children?: Tier[]
}

export interface SearchTier {
  t1_tierId: number
  t1_tierName: string
  t2_tierId: number
  t2_tierName: string
  t2_isVisible: string
  t3_tierId?: number
  t3_tierName?: string
  t3_isVisible?: string
  t4_tierId?: number
  t4_tierName?: string
  t4_isVisible?: string
  t4_children?: ChildSearchTier[]
  t5_tierId?: number
  t5_tierName?: string
  t5_isVisible?: string
  t5_children?: ChildSearchTier[]
  reportId: number
  tierTags: string[]
  tierIdMap: number[]
  tierNameMap: string[]
  searchName: string
  searchTier: number
  children?: ChildSearchTier[]
}

export interface ChildSearchTier {
  tierId: number
  tierName: string
  displaySequence: number
  reportId: number
  isVisible: string
  tierTypeId: number
  tierTypeName: string
  childrenTiers: any[]
  tierTags: string[]
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  @Input() public apiTiers: Tier[];
  @Input() public searchTiers: SearchTier[];
  @Output() public reportTier: EventEmitter<string> = new EventEmitter();

  public domains: Tier[];
  public themes: Tier[];
  public selectedTier: Tier;
  public tags: String[];
  public selectedTag: string;
  public tiersWithTag: SearchTier[];
  public showAllTags: boolean = false;

  // Tag search
  public tagQuery: string = '';
  get filteredTags(): String[] {
    if (!this.tagQuery || this.tagQuery.length <= 2) {
      return this.tags;
    }
    const lowerCaseQuery = this.tagQuery.toLowerCase();
    return this.tags.filter(tag => tag.toLowerCase().includes(lowerCaseQuery));
  }

  constructor(
    private formatService: FormatService,
  ) { }

  ngOnInit() {
    this.defineDomainsAndThemes(this.apiTiers);
    this.defineTags()
  }

  private defineDomainsAndThemes(tiers: Tier[]): void {
    // Define high-level domains
    this.domains = tiers;
    // Pull out Themes (if exist)
    let themesTier = tiers.find(tier => tier.tierName == 'Themes');
    if (themesTier) {
      this.themes = themesTier.childrenTiers;
    };
  }

  private defineTags(): void {
    this.tags = this.formatService.tags(this.searchTiers);
  }

  public getChildrenTiers(tier: Tier): void {
    this.selectedTier = tier;
  }

  public getTags(tag: string): void {
    this.selectedTag = tag;
    this.tiersWithTag = this.searchTiers.filter(data => 
      data.tierTags && data.tierTags.includes(tag)
    );
  }

  public closeModal(): void {
    this.selectedTag = null;
    this.selectedTier = null;
    this.tiersWithTag = [];
  }

}
