import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { IcsService } from 'app/utilities/services/ics.service';
import { MemberService } from '../../utilities/services/member.service';

@Component({
  selector: 'app-pr-project-lead',
  templateUrl: './pr-project-lead.component.html',
  styleUrls: ['./pr-project-lead.component.scss']
})
export class PrProjectLeadComponent implements OnChanges {
  @Input() registration;
  @Input() projectBasicDetails;
  @Input() projectId;
  @Input() icsProject;
  @Output() projectLeadAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() submissionAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() organisationError: EventEmitter<any> = new EventEmitter<any>();
  guidance: boolean = false;

  projectLead; projectLeadForm: FormGroup; 
  updateProjectLead: boolean = false; showProjectLeadForm: boolean = false; showProjectLeadFormAdd: boolean = true; projectLeadExists: boolean = false;
  organisationTypeOther: string;

  constructor(
    private memberService: MemberService, 
    private fb: FormBuilder, 
    private toastr: ToastrService,
    private icsService: IcsService
  ) {}

  ngOnChanges() {
    this.organisationTypeOther = this.projectBasicDetails.organisationTypeOther;
    if (this.registration) {
      //New projectlead
      if (this.registration.registration.projectLead) {
        this.projectLead = this.registration.registration.projectLead.projectLeadList[0];
      }
      //Existing projectlead
      else {
        this.projectLead = this.registration;
      }
    }
  }

  projectLeadFormAdd() {
    this.buildProjectLeadForm();

    this.showProjectLeadForm = true; 
    this.showProjectLeadFormAdd = false;    
  }

  getUserDetails() {
    this.memberService.User().subscribe(
      u => { 
        let user = u.data;
        this.projectLeadFormAdd();
        this.projectLeadForm.controls['fullName'].setValue(user.fullName);
        this.projectLeadForm.controls['logonName'].setValue(user.logonName);
        this.projectLeadForm.controls['phoneNo'].setValue(user.phoneNo);
        this.projectLeadForm.controls['jobTitle'].setValue(user.jobTitle);
      }
    )
  };

  projectLeadFormSubmit() {
    this.updateProjectLead = true;

    if (this.registration.registrationId) {
      this.addProjectLead(this.registration.registrationId, this.registration.productId, this.registration.projectId, this.registration.organisationId, this.projectLeadForm.value);
    }
    else {
      this.createNewRegistration(this.registration.productId, this.registration.projectId, this.registration.organisationId, this.projectLeadForm.value);
    }

    this.showProjectLeadForm = !this.showProjectLeadForm;
    this.showProjectLeadFormAdd = !this.showProjectLeadFormAdd;
  }

  createNewRegistration(productId: number, projectId: number, organisationId: number, projectLeadDetails) {
    this.memberService.CreateRegistration(projectId, organisationId).subscribe(
      r => {        
        this.addProjectLead(r.data.registrationId, productId, projectId, organisationId, projectLeadDetails);
        // Automatically add submission
        if (this.registration.projectId !== 8 && this.registration.projectId !== 9) {
          this.buildSubmissionData(r.data.registrationId);
        } else {
          console.log('Offline project: no submission created');
        }
      },
      e => {
        console.log('There has been an error whilst registering. Error Code:', e.error.error.errorCode.toString());
        this.organisationError.emit(true);
      }
    )
  }

  // Build submission
  buildSubmissionData(registrationId: number) {
    let submissionData = { 
      submissionId: undefined, 
      submissionName: this.projectBasicDetails.organisationName,
      peerGroupId: undefined
    }
    // Auto-assign peer group to NHSI LD 2019
    // TODO: Review in 2020, if required
    if (this.registration.projectId === 36) {
      if (this.organisationTypeOther === 'Specialist') {
        submissionData.peerGroupId = 5;
      } else {
        submissionData.peerGroupId = 7;
      }
    }
    this.addSubmission(registrationId, submissionData);
  }

  addSubmission(registrationId: number, submissionData) {
    this.memberService.AddSubmission(registrationId, submissionData).subscribe(
      r => {
        this.submissionAdd.emit(r.data);

        // Add to ICS database, if required
        if (this.icsProject) {

          let icsSubmissionData = {
            organisationName: this.projectBasicDetails.organisationName,
            organisationCode: this.projectBasicDetails.organisationCode,
            organisationTypeName: this.projectId == 29 ? 'Community' : this.projectBasicDetails.project.projectCategoryName,
            externalOrganisationId: this.registration.organisationId,
            submissionList: [{
              submissionName: r.data.submissionName,
              submissionCode: this.projectBasicDetails.organisationCode + '-1',
              externalSubmissionId: r.data.submissionId
            }]
          }

          this.addIcsSubmission(icsSubmissionData);
        }
      },
      e => {
        console.log('There has been an error whilst trying to add a submission');
      }
    );
  }

  addIcsSubmission(icsSubmissionData: any) {
    this.icsService.addSubmissions(this.projectId, icsSubmissionData).subscribe(
      r => {
        console.log(r);             
      },
      e => {
        console.log(e);
      }
    );
  }

  addProjectLead(registrationId: number, productId: number, projectId: number, organisationId: number, projectLeadDetails) {
    this.registration.registrationId = registrationId;

    this.memberService.AddProjectLead(registrationId, productId, projectId, organisationId, projectLeadDetails).subscribe(
      r => {
        let projectLeadAddDetails = {
          projectLeadForm: projectLeadDetails,
          registrationId: registrationId,
          productId: productId,
          projectId: projectId,
          organisationId: organisationId
        };

        this.projectLeadAdd.emit(projectLeadAddDetails);

        if (!r.data[0].newUser) {
          this.projectLeadExists = true;
        }
      },
      e => {
        let errorMessage = '';

        if (e.error.error.message.split(' ').includes("disabled!")) {
          errorMessage = "The person you have added as a Project Lead is currently disabled. Please enable them before continuing."
        }
        else {
          errorMessage = 'There has been an error whilst adding ' + projectLeadDetails.logonName + ', please try again or contact the Project Manager.'
        }

        this.toastr.error(errorMessage, 'Project Registration');

        console.log(e.error.error.message);
      },
      () => {
      }
    )
  }

  buildProjectLeadForm() {
    this.projectLeadForm = this.fb.group({
      logonName: ['', [Validators.required, Validators.maxLength(200), <any>Validators.email]],
      fullName: ['', [Validators.required, Validators.maxLength(150)]],
      jobTitle: ['', [Validators.required, Validators.maxLength(150)]],
      phoneNo: ['', [Validators.maxLength(40)]]
    });

    this.projectLeadForm.valueChanges.subscribe(
      data => this.onValueChanged(data)
    );

    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.projectLeadForm) { return; }
    const form = this.projectLeadForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  setGuidance() {
    this.guidance = !this.guidance;  
  }

  formErrors = {
    'fullName': '',
    'logonName': '',    
    'jobTitle': '',
    'phoneNo': ''
  }

  validationMessages = {
    'fullName': {
      'required': 'Full name is required.',
      'maxlength': 'Full name cannot be longer than 150 characters.'
    },
    'logonName': {
      'required': 'Email Address is required.',
      'maxlength': 'Email Address cannot be longer than 200 characters.',
      'email': 'Email Address is not valid.'
    },
    'jobTitle': {
      'required': 'Job title is required.',
      'maxlength': 'Job title cannot be longer than 150 characters.'
    },
    'phoneNo': {
      'required': 'Phone number is required.',
      'maxlength': 'Phone number cannot be longer than 40 characters.'
    }
  }
}