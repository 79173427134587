import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class DataService {
  private URL: string = environment.icsApiURL;

  constructor(
    private http: HttpClient
  ) { }

  public getPreferences(): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'preferences', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getTiers(projectId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'tiers', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('projectId', projectId.toString())
      }
    );
  }

  public getSearchTiers(projectId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'tiers/leaf', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('projectId', projectId.toString())
      }
    );
  }

  public getDetails(reportId: number): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.get(
      this.URL + 'reports/' + reportId + '/reportDetails', {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getData(reportId: number, categoryIds?, organisationId?, tierId?): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (organisationId) {
      params = params.set('organisationId', organisationId.toString())
    }

    if (categoryIds) {
      categoryIds.forEach(c => params = params.append('selectedCategory', c.toString()));
    }

    // For analytics
    if (tierId) {
      params = params.append('tierId', tierId.toString());
    }
    
    return this.http.get(
      this.URL + 'reports/' + reportId + '/reportDataDatesSpecificOptions', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getSeriesData(reportId: number, tierId: number, seriesCategoryTypeID: number, categoryIds?: any): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams().set('tierId', tierId.toString()).set('seriesCategoryTypeID', seriesCategoryTypeID.toString())


    if (categoryIds) {
      categoryIds.forEach(c => params = params.append('selectedCategory', c.toString()));
    }
    
    return this.http.get(
      this.URL + 'reports/' + reportId + '/seriesData', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getRelationships(relationshipType: string): Observable<any> {
    let token = localStorage.getItem('id_token');
    
    return this.http.get(
      this.URL + 'organisations/relationshipYearList', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('relationshipType', relationshipType)
      }
    );
  }

  public getNeighbours(organisationId: number): Observable<any> {
    let token = localStorage.getItem('id_token');
    
    return this.http.get(
      this.URL + 'organisations/neighbourList', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('organisationId', organisationId.toString())
      }
    );
  }

  public getOrganisations(type?: string): Observable<any> {
    let token = localStorage.getItem('id_token');
    let params: HttpParams = new HttpParams();

    if (type) {
      params = params.append('orgType', type);
    }
    
    return this.http.get(
      this.URL + 'organisations/list', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public setPreference(preference): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.post(
      this.URL + 'preferences', JSON.stringify(preference), {
        headers: new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
      }
    );
  }

  public removePreference(preferenceId): Observable<any> {
    let token = localStorage.getItem('id_token');

    return this.http.delete(
      this.URL + 'preferences/' + preferenceId, {
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

}