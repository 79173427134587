import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OldHomeComponent } from './old-home.component';
import { AuthGuardService } from 'app/utilities/services/auth-guard.service';

const oldHomeRoutes: Routes = [
  { path: 'classicView', component: OldHomeComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(oldHomeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class OldHomeRoutingModule { }