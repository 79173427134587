import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupByPipe, OrderByPipe } from 'ngx-pipes';
import * as moment from 'moment';

@Injectable()
export class FormatService {

  constructor(
    private group: GroupByPipe,
    private order: OrderByPipe
  ) { }

  public preferences(preferences: any): Observable<any> {

    // Resolve defaults
    ['la', 'ccg', 'ics'].forEach(type => {
      if (preferences.hasOwnProperty('default_' + type)) {
        preferences['default_' + type] = preferences['default_' + type][0];
      }
    });

    // Remove unnecessary properties
    ['indicators_org', 'welcomeDismissed'].forEach(p => {
      if (preferences.hasOwnProperty(p)) {
        delete preferences[p];
      }
    })

    return preferences;
  }

  public updatePreferences(preferences: any, organisationType: string, data: any): Observable<any> {

    organisationType = organisationType.toLowerCase();

    // Define default for current data
    if (preferences.hasOwnProperty('default_' + organisationType)) {
      
      let defaultPreference = preferences['default_' + organisationType];
      let findOrganisation = data.find(d => d.organisationOnsCode === defaultPreference.preferenceItem);

      if (findOrganisation) {
        defaultPreference.organisationId = findOrganisation.organisationId;
        defaultPreference.organisationName = findOrganisation.organisationName;
        defaultPreference.countryCode = defaultPreference.preferenceItem.substring(0, 1);
        defaultPreference.found = true;
        // Set the selected default
        preferences.selected_default = defaultPreference;
      } else {
        defaultPreference.organisationId = null;
        defaultPreference.organisationName = 'Organisation not in this view.';
        defaultPreference.countryCode = null;
        defaultPreference.found = false;
        // Set the selected default
        preferences.selected_default = defaultPreference;
      }

    } else {
      preferences.selected_default = null;
    }

    // Define custom group for current data
    if (preferences.hasOwnProperty('custom_group_' + organisationType)) {
      preferences.selected_group = preferences['custom_group_' + organisationType];
    } else {
      preferences.selected_group = null;
    }

    return preferences;
  }

  public regions(organisations: any, defaultOnsCode: number): Observable<any> {
    let regionOrganisations: any = [];

    // Add region or country to organisations
    organisations.forEach(o => {
      let nhse = o.regionList.find(r => r.regionTypeId === 5);
      let lar = o.regionList.find(r => r.regionTypeId === 3);
      let country = o.regionList.find(r => r.regionTypeId === 1);

      if (nhse) {
        o.regionId = nhse.regionId;
        o.regionName = nhse.regionName;
      } else if (lar) {
        o.regionId = lar.regionId;
        o.regionName = lar.regionName;
      } else if (country) {
        o.regionId = country.regionId;
        o.regionName = country.regionName;
      }

    });

    // Find default organisation
    let organisation = organisations.find(o => o.organisationOnsCode === defaultOnsCode);
    
    // If there's a default, return organisations in same region
    if (organisation) {
      organisations.forEach(o => {
        if (organisation.regionId === o.regionId) {
          regionOrganisations.push(o)
        }
      })
    }

    return regionOrganisations;
  }

  public neighbours(neighbours: any): Observable<any> {
    let neighbourOrganisations: any = [];

    let years = Object.keys(neighbours);

    if (years.length) {
      // TODO: Make this year-specific
      // Default to closest, if no match
      neighbourOrganisations = neighbours[years[0]];
    }

    return neighbourOrganisations;
  }

  public tiers(tiers: any): Observable<any> {
    let formattedData: any = [];

    tiers.forEach(t1 => {
      if (t1.childrenTiers) {
        t1.childrenTiers.forEach(t2 => {
          formattedData.push({
            t1_tierId: t1.tierId,
            t1_tierName: t1.tierName,
            t2_tierId: t2.tierId,
            t2_tierName: t2.tierName,
            t2_isVisible: t2.isVisible,
            reportId: t2.reportId,
            tierTags: t2.tierTags
          });
          if (t2.childrenTiers) {
            t2.childrenTiers.forEach(t3 => {
              formattedData.push({
                t1_tierId: t1.tierId,
                t1_tierName: t1.tierName,
                t2_tierId: t2.tierId,
                t2_tierName: t2.tierName,
                t2_isVisible: t2.isVisible,
                t3_tierId: t3.tierId,
                t3_tierName: t3.tierName,
                t3_isVisible: t3.isVisible,
                t3_children: t3.childrenTiers,
                reportId: t3.reportId,
                tierTags: t3.tierTags
              });
              if (t3.childrenTiers) {
                t3.childrenTiers.forEach(t4 => {
                  formattedData.push({
                    t1_tierId: t1.tierId,
                    t1_tierName: t1.tierName,
                    t2_tierId: t2.tierId,
                    t2_tierName: t2.tierName,
                    t2_isVisible: t2.isVisible,
                    t3_tierId: t3.tierId,
                    t3_tierName: t3.tierName,
                    t3_isVisible: t3.isVisible,
                    t4_tierId: t4.tierId,
                    t4_tierName: t4.tierName,
                    t4_isVisible: t4.isVisible,
                    t4_children: t4.childrenTiers,
                    reportId: t4.reportId,
                    tierTags: t4.tierTags
                  });
                  if (t4.childrenTiers) {
                    t4.childrenTiers.forEach(t5 => {
                      formattedData.push({
                        t1_tierId: t1.tierId,
                        t1_tierName: t1.tierName,
                        t2_tierId: t2.tierId,
                        t2_tierName: t2.tierName,
                        t2_isVisible: t2.isVisible,
                        t3_tierId: t3.tierId,
                        t3_tierName: t3.tierName,
                        t3_isVisible: t3.isVisible,
                        t4_tierId: t4.tierId,
                        t4_tierName: t4.tierName,
                        t4_isVisible: t4.isVisible,
                        t4_children: t4.childrenTiers,
                        t5_tierId: t5.tierId,
                        t5_tierName: t5.tierName,
                        t5_isVisible: t5.isVisible,
                        t5_children: t5.childrenTiers,
                        reportId: t5.reportId,
                        tierTags: t5.tierTags
                      });
                      if (t5.childrenTiers) {
                        t5.childrenTiers.forEach(t6 => {
                          formattedData.push({
                            t1_tierId: t1.tierId,
                            t1_tierName: t1.tierName,
                            t2_tierId: t2.tierId,
                            t2_tierName: t2.tierName,
                            t2_isVisible: t2.isVisible,
                            t3_tierId: t3.tierId,
                            t3_tierName: t3.tierName,
                            t3_isVisible: t3.isVisible,
                            t4_tierId: t4.tierId,
                            t4_tierName: t4.tierName,
                            t4_isVisible: t4.isVisible,
                            t5_tierId: t5.tierId,
                            t5_tierName: t5.tierName,
                            t5_isVisible: t5.isVisible,
                            t6_tierId: t6.tierId,
                            t6_tierName: t6.tierName,
                            t6_isVisible: t6.isVisible,
                            reportId: t6.reportId,
                            tierTags: t6.tierTags
                          });
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }
    });

    // Create tier map
    formattedData.forEach(o => {
      o.tierIdMap = [
        o.t1_tierId, 
        o.t2_tierId ? o.t2_tierId : null,  
        o.t3_tierId ? o.t3_tierId : null, 
        o.t4_tierId ? o.t4_tierId : null,
        o.t5_tierId ? o.t5_tierId : null,
        o.t6_tierId ? o.t6_tierId : null
      ];
      o.tierNameMap = [
        o.t1_tierName, 
        o.t2_tierName ? o.t2_tierName : null,  
        o.t3_tierName ? o.t3_tierName : null, 
        o.t4_tierName ? o.t4_tierName : null,
        o.t5_tierName ? o.t5_tierName : null,
        o.t6_tierName ? o.t6_tierName : null
      ];
    });

    // Remove null reports
    formattedData = formattedData.filter(t => t.reportId !== null);

    formattedData.forEach(o => {
      o.tierIdMap = o.tierIdMap.filter(t => t !== null);
      o.tierNameMap = o.tierNameMap.filter(t => t !== null);
    });

    // Last tier
    formattedData.forEach(o => {
      o.searchName = o.tierNameMap.pop();
      o.searchTier = o.tierIdMap.pop();
    });

    // Secondary tiers
    formattedData.forEach(o => {
      o.children = o.t5_children && o.t5_children.length ? o.t5_children : ( o.t4_children && o.t4_children.length ? o.t4_children : null );
    });

    return formattedData;
  }

  public tags(tiers: any): string[] {
    const uniqueTags = new Set<string>();

    tiers.forEach(data => {
      if (data.tierTags && Array.isArray(data.tierTags)) {
        data.tierTags.forEach(tag => uniqueTags.add(tag));
      }
    });

    return Array.from(uniqueTags).sort((a, b) => a.localeCompare(b));
  }

  public details(data: any): Observable<any> {

    // ORGANISATION TYPE
    let organisationType;
    if (data.organisationTypeName === 'Local Authority') {
      organisationType = 'LA'
    } else if (data.organisationTypeName === 'NHSE Region') {
      organisationType = 'Region'
    } else if (data.organisationTypeName === 'Ambulance Service') {
      organisationType = 'Ambulance'
    } else if (data.organisationTypeName === 'Local Authority Region') {
      organisationType = 'LAR'
    } else {
      organisationType = data.organisationTypeName
    }

    // CATEGORIES
    let categoryTypes = [];
    Object.keys(data.categories).forEach(cd => {
      // Only include category types with more
      // than one category within it
      if (data.categories[cd].length > 1) {
        data.categories[cd].forEach(c => {
          categoryTypes.push({
            categoryTypeId: c.categoryTypeId,
            categoryTypeName: cd,
            categoryId: c.categoryId,
            categoryName: c.categoryName,
            displaySequence: c.displaySequence,
            selected: null
          })
        })
      }
    });

    // COMPONENTS
    let components = [];
    Object.keys(data.componentDescriptions).forEach(c => {
      data.componentDescriptions[c].forEach(cd => {
        components.push({
          type: c === 'N' ? 'Numerators' : 'Denominators',
          id: cd.dataItemId,
          name: cd.dataItemName,
          description: cd.dataItemDescription,
          source: cd.dataSource,
          sourceUrl: cd.componentUrl
        })
      })
    });

    // GET LINKED REPORT ID
    let linkedReport = data.reportOptions.find(r => r.reportOption == 'LR') || null;

    // CHECK IF RETIRED
    let retiredData = data.reportOptions.find(r => r.reportOption == 'RET') || null;

    // FORMATTED DATA
    let formattedData: any = {
      reportId: data.reportId,
      reportName: data.reportName,
      formatModifier: data.formatModifier,
      organisationType: organisationType,
      categories: categoryTypes,
      options: data.reportOptions.map(ro => ro.reportOption),
      components: components,
      factor: data.factor,
      aggregationType: data.aggregationType,
      linkedReportId: linkedReport ? linkedReport.displaySequence : null,
      retired: retiredData ? true : false
    }

    return formattedData;
  }

  public data(data: any, defaultOnsCode?: string): Observable<any> {
    let formattedData: any = [];

    // DATA
    data.availableDates.forEach(ad => {

      // Flatten submissions
      let submissions = [];
      ad.organisationList.forEach(ol => {
        ol.submissionData.forEach(sd => { 
          submissions.push({
            organisationId: ol.organisationId,
            organisationName: ol.organisationName,
            organisationOnsCode: ol.organisationOnsCode ? ol.organisationOnsCode : ol.organisationCode,
            submissionId: sd.submissionId,
            submissionCode: sd.submissionCode,
            submissionResult: sd.result,
            color: ol.organisationOnsCode == defaultOnsCode ? '#E03616' : '#005EB8'
          })
        });
      });

      // Order submissions by result DESC
      submissions = this.order.transform(submissions, '-submissionResult');

      // Get default organisation result
      let defaultResult = null;
      if (defaultOnsCode) {
        let defaultSubmission = submissions.find(s => s.organisationOnsCode === defaultOnsCode);
        defaultResult = defaultSubmission ? defaultSubmission.submissionResult : null;
      }

      // Correct period name
      let periodFrom = moment(ad.dateFrom).format('MMM-YY');
      let periodTo = moment(ad.dateTo).format('MMM-YY');
      let periodName;
      if (periodFrom === periodTo) {
        periodName = periodFrom;
      } else {
        periodName = periodFrom + ' to ' + periodTo;
      }
      let periodFy = this.getFY(ad.dateFrom);

      // Remove Wales data if default is not Wales
      let mean: any, median;
      if (defaultOnsCode && defaultOnsCode.substring(0, 1) !== 'W') {
        submissions = submissions.filter(s => s.organisationOnsCode.substring(0, 1) !== 'W');
        if (submissions.length) {
          mean = this.averages(submissions)['mean'];
          median = this.averages(submissions)['median'];
        }
      } else {
        submissions = submissions;
        if (submissions.length) {
          mean = data.dateAverages[ad.dateId];
          median = data.dateMedians[ad.dateId];
        }
      }

      // Return formatted data
      formattedData.push({
        periodId: ad.dateId,
        periodName: periodName,
        periodFy: periodFy,
        periodFrom: moment(ad.dateFrom).format('YYMMDD'),
        submissions: submissions,
        mean: mean,
        median: median,
        defaultResult: defaultResult
      });

    });

    return formattedData.reverse();
  }

  public seriesData(data: any, categories: any, categoryTypeId: number): Observable<any> {
    let formattedData: any = [];
    let availableCategories = categories.filter(c => c.categoryTypeId === categoryTypeId);

    // DATA
    data.availableDates.forEach(ad => {

      // Flatten submissions
      let submissions = [];
      ad.organisationList.forEach(ol => {
        ol.submissionData.forEach(sd => {

          // Match results to categories, adding nulls where necessary
          let results = [];
          availableCategories.forEach(ac => {
            let findResult = sd.seriesList.find(sd => sd.categoryId === ac.categoryId);
            if (findResult) {
              results.push(findResult)
            } else {
              results.push({
                categoryId: ac.categoryId,
                categoryName: ac.categoryName,
                result: null
              })
            }
          });

          submissions.push({
            organisationId: ol.organisationId,
            organisationName: ol.organisationName,
            organisationOnsCode: ol.organisationOnsCode ? ol.organisationOnsCode : ol.organisationCode,
            submissionId: sd.submissionId,
            submissionCode: sd.submissionCode,
            submissionResults: results,
            submissionResultsOrder: results.slice(-1)[0].result
          })
        });
      });

      // Order submissions by result DESC
      submissions = this.order.transform(submissions, '-submissionResultsOrder');

      // Correct period name
      let periodFrom = moment(ad.dateFrom).format('MMM-YY');
      let periodTo = moment(ad.dateTo).format('MMM-YY');
      let periodName;
      if (periodFrom === periodTo) {
        periodName = periodFrom;
      } else {
        periodName = periodFrom + ' to ' + periodTo;
      }

      let periodFy = this.getFY(ad.dateFrom);

      // Return formatted data
      formattedData.push({
        periodId: ad.dateId,
        periodName: periodName,
        periodFy: periodFy,
        periodFrom: moment(ad.dateFrom).format('YYMMDD'),
        submissions: submissions,
        mean: data.dateAverages[ad.dateId],
        median: data.dateMedians[ad.dateId]
      });

    });

    // Format to Highcharts
    formattedData.forEach(fd => {

      // Create series
      let series = [];
      availableCategories.forEach((ac, i) => {
        series.push({
          name: ac.categoryName,
          data: fd.submissions.map(s => s.submissionResults[i].result)
        })
      })

      // Add categories and series
      fd.categories = fd.submissions.map(s => s.organisationName);
      fd.series = series
    })

    return formattedData.reverse();
  }

  public averages(data: any): Observable<any> {
    let formattedData: any = {};

    let values = data.map(s => s.submissionResult),
        sorted = values.sort((a, b) => { return a-b }),
        half = Math.floor(sorted.length / 2),
        count = sorted.length,
        mean = sorted.reduce((a, b) => a + b, 0) / count,
        median = count % 2 ? sorted[half] : (sorted[half - 1] + sorted[half]) / 2,
        min = sorted.reduce((a, b) => Math.min(a, b)),
        max = sorted.reduce((a, b) => Math.max(a, b)),
        per25 = Math.floor(count * 0.25) - 1,
        per75 = Math.floor(count * 0.75) - 1,
        quartile25 = sorted[per25],
        quartile75 = sorted[per75]

    formattedData = {
      min: min,
      max: max,
      mean: mean,
      median: median,
      count: count,
      quartile25: quartile25,
      quartile75: quartile75
    };

    return formattedData;
  }

  public relationships(relationshipYears: any): Observable<any> {
    let formattedData: any = [];

    Object.keys(relationshipYears).forEach(year => {

      // Add period from and financial year
      let dateFrom = new Date(+year, 3, 1);
      let periodFy = this.getFY(dateFrom);

      formattedData.push({
        year: +year,
        periodFy: periodFy,
        periodFrom: moment(dateFrom).format('YYMMDD'),
        relationships: relationshipYears[year].map(ry => {
          return {
            parentId: ry.childOrganisationId,
            parentOnsCode: ry.childOrganisationOnsCode,
            parentName: ry.childOrganisationName,
            childId: ry.parentOrganisationId,
            childOnsCode: ry.parentOrganisationOnsCode,
            childName: ry.parentOrganisationName,
            factor: ry.relationshipFactor,
            proportion: ry.relationshipProportion
          }
        })
      });

    });

    return formattedData;
  }

  public aggregate(data: any, relationships: any, aggregationType: string, defaultOnsCode?: string, reportDetails?: any): Observable<any> {

    // Add parent and proportion/factor information to submission
    data.forEach(d => {
      d.newSubmissions = [];
      d.submissions.forEach(s => {

        // Match all future years to 2324 relationships
        let periodCheck = d.periodFy > 2324 ? 2324 : d.periodFy;

        // Plus 1 financial year for QOF reports
        if (reportDetails.reportName.substring(0,3) == 'QOF') {
          periodCheck = periodCheck + 101;
        }

        let relationshipPeriod = relationships.find(r => r.periodFy === periodCheck);
        let findRelationships;

        if (relationshipPeriod) {
          findRelationships = relationshipPeriod.relationships.filter(r => r.childOnsCode === s.organisationOnsCode);
        }

        if (relationshipPeriod && findRelationships.length) {
          findRelationships.forEach(fr => {
            d.newSubmissions.push({
              childId: fr.childId,
              childOnsCode: fr.childOnsCode,
              childName: fr.childName,
              result: s.submissionResult,
              parentId: fr.parentId,
              parentOnsCode: fr.parentOnsCode,
              parentName: fr.parentName,
              factor: fr.factor,
              proportion: fr.proportion,
              factoredResult: s.submissionResult && fr.factor ? s.submissionResult * fr.factor : null,
              proportionedResult: s.submissionResult && fr.proportion ? s.submissionResult * fr.proportion : null
            })
          })
        }
      });
    });

    // Group data by parent into new array
    data.forEach(d => {
      d.aggregated = this.group.transform(d.newSubmissions, 'parentOnsCode');
    })

    // Add necessary information
    data.forEach(d => {
      let aggregated = [];
      Object.keys(d.aggregated).forEach(agg => {
        // Checks for parent then creates group
        if (agg !== 'undefined') {
          aggregated.push({
            organisationId: d.aggregated[agg][0].parentId,
            organisationOnsCode: agg,
            organisationName: d.aggregated[agg][0].parentName,
            relationships: d.aggregated[agg],
            color: d.aggregated[agg][0].parentOnsCode == defaultOnsCode ? '#E03616' : '#005EB8'
          });
        }
      });
      d.aggregated = aggregated;
    })

    // Check for flagged aggregations
    // Child areas aggregating without data
    data.forEach(d => {
      d.flaggedAggregations = []

      // Match all future years to 2223 relationships
      let periodCheck = d.periodFy > 2223 ? 2223 : d.periodFy;

      // Add flag for aggregated data
      d.aggregatedData = true;

      // Push relationship year on for QOF reports
      if (reportDetails.reportName.substring(0,3) == 'QOF') {
        periodCheck = periodCheck + 101;
      }

      // Get relationships for the period
      let relationshipPeriod = relationships.find(r => r.periodFy === periodCheck);

      d.aggregated.forEach(a => {
        // Find number of relationships
        a.allRelationships = relationshipPeriod.relationships.filter(r => r.parentOnsCode === a.organisationOnsCode);

        // Keep only if relationships match aggregations
        if (a.relationships.length !== a.allRelationships.length) {
          d.flaggedAggregations.push(a)
        }
      });
    });

    // Calculate aggregated result
    data.forEach(d => {
      d.aggregated.forEach(agg => {
        if (aggregationType === 'Factor') {
          agg.submissionResult = agg.relationships.map(g => g.factoredResult).reduce((a, b) => a + b, 0);;
        } else {
          agg.submissionResult = agg.relationships.map(g => g.proportionedResult).reduce((a, b) => a + b, 0);
        }
      });
    });

    return data;
  }

  public aggregateSeries(data: any, relationships: any, categories: any, categoryTypeId: number, aggregationType: string): Observable<any> {

    let availableCategories = categories.filter(c => c.categoryTypeId === categoryTypeId);

    // Add parent and proportion/factor information to submission
    data.forEach(d => {
      d.submissions.forEach(s => {
        let relationshipPeriod = relationships.find(r => r.periodFy === d.periodFy);
        let relationship; 

        if (relationshipPeriod) {
          relationship = relationshipPeriod.relationships.find(r => r.childOnsCode === s.organisationOnsCode);
        }
        
        if (relationshipPeriod && relationship) {

          let submissionResults = [];
          s.submissionResults.forEach(sr => {
            submissionResults.push({
              categoryId: sr.categoryId,
              factoredResult: sr.result && relationship.factor ? sr.result * relationship.factor : null,
              proportionedResult: sr.result && relationship.proportion ? sr.result * relationship.proportion : null
            })
          });

          s.parentId = relationship.parentId,
          s.parentOnsCode = relationship.parentOnsCode,
          s.parentName = relationship.parentName,
          s.factor = relationship.factor,
          s.proportion = relationship.proportion
          s.submissionResults = submissionResults ? submissionResults : null
        }
      });
    });

    // Group data by parent into new array
    data.forEach(d => {
      d.aggregatedSeries = this.group.transform(d.submissions, 'parentOnsCode');
    })

    // Add necessary information
    data.forEach(d => {
      let aggregated = [];
      Object.keys(d.aggregatedSeries).forEach(agg => {
        // Checks for parent then creates group
        if (agg !== 'undefined') {
          aggregated.push({
            organisationOnsCode: agg,
            organisationName: d.aggregatedSeries[agg][0].parentName,
            relationships: d.aggregatedSeries[agg]
          });
        }
      });
      d.aggregatedSeries = aggregated;
    });

    data.forEach(d => {
      d.aggregatedSeries.forEach(s => {
        let results = [];
        availableCategories.forEach((c, i) => {
          results.push({
            categoryId: c.categoryId,
            proportionedResult: s.relationships.map(r => r.submissionResults[i].proportionedResult).reduce((a, b) => a + b, 0),
            factoredResult: s.relationships.map(r => r.submissionResults[i].factoredResult).reduce((a, b) => a + b, 0)
          })
        })
        s.aggregatedResults = results;
      });
    });

    // Format to Highcharts
    data.forEach(d => {

      // Create series
      let series = [];
      availableCategories.forEach((ac, i) => {
        series.push({
          name: ac.categoryName,
          data: d.aggregatedSeries.map(s => { 
            return { 
              y: aggregationType == 'Factor' ? s.aggregatedResults[i].factoredResult : s.aggregatedResults[i].proportionedResult,
              organisation: s.organisationName
            }
          })
        })
      })

      // Add categories and series
      d.aggregatedCategories = d.aggregatedSeries.map(s => s.organisationName);
      d.aggregatedSeries = series;

    })

    return data;
  }

  public highlightSeries(data: any, categories: any, categoryTypeId: number, highlights: any): Observable<any> {
    let availableCategories = categories.filter(c => c.categoryTypeId === categoryTypeId);
    
    data.forEach(d => {
      let availableSubmissions = [];

      // Filter to just highlighted organisations
      d.submissions.forEach(s => {
        if (highlights.includes(s.organisationOnsCode)) {
          availableSubmissions.push(s);
        }
      })

      // Overwrite submissions
      d.submissions = availableSubmissions;

      // Recreate series
      let series = [];
      availableCategories.forEach((ac, i) => {
        series.push({
          name: ac.categoryName,
          data: d.submissions.map(s => s.submissionResults[i].result)
        })
      })

      // Re-add categories and series
      d.categories = d.submissions.map(s => s.organisationName);
      d.series = series
    });

    return data;
  }

  public periodMatch(data: any, secondaryData: any, aggregating: boolean): Observable<any> {
    let matches: any = { data: null, secondaryData: null };

    // Reorder by reverse financial year
    data = this.order.transform(data, 'periodFy');
    secondaryData = this.order.transform(secondaryData, 'periodFy');

    // Find matching 
    data.forEach(d => {
      let matchingSecondary;
      if (aggregating) {
        secondaryData.find(r => r.periodFy === d.periodFy && d.aggregated.length);
      } else {
        secondaryData.find(r => r.periodFy === d.periodFy);
      }
      if (matchingSecondary) {
        d.matchingPeriod = true;
        matchingSecondary.matchingPeriod = true;
      }
    })

    // Check for results
    // If no matches, take latest data from both
    let check = data.filter(d => d.matchingPeriod).length;
    if (check === 0) {
      let primary = data[data.length - 1],
          secondary = secondaryData[secondaryData.length - 1];

      primary.matchingPeriod = true;
      secondary.matchingPeriod = true
    }

    // Filter the data to just matching time periods
    matches.data = data.filter(d => d.matchingPeriod);
    matches.secondaryData = secondaryData.filter(d => d.matchingPeriod);

    return matches;
  }

  public combine(data: any, secondaryData: any): Observable<any> {
    console.log('Matching organisation types')

    // Combine *matching* organisation types
    let primary = data[0];
    let secondary = secondaryData[0];
    primary.secondarySubmissions = secondary.submissions;

    // Add secondary result
    data.forEach(d => {
      d.submissions.forEach(s => {
        if (d.secondarySubmissions) {
          let secondarySubmission = d.secondarySubmissions.find(sd => sd.organisationOnsCode === s.organisationOnsCode);
          if (secondarySubmission) { s.secondaryResult = secondarySubmission.submissionResult }
        }
      })
    });

    // Remove any data without secondary results
    data.forEach(d => {
      d.combined = d.submissions.filter(sd => sd.secondaryResult);
    });

    // Remove any periods without any secondary data
    data = data.filter(d => d.secondarySubmissions && d.secondarySubmissions.length);

    // Return data
    return data;
  }

  public combineAggregated(data: any, secondaryData: any): Observable<any> {
    console.log('Non-matching organisation types');

    // Combined *non-matching* organisation types
    let primary = data[0];
    let secondary = secondaryData[0];

    // Preserve a copy of the aggregated results
    primary.primaryAggregated = JSON.parse(JSON.stringify(primary.aggregated));

    // TODO: NOT SURE WHY!!
    primary.secondaryAggregated = secondary.aggregated;

    // Add secondary result
    data.forEach(d => {
      d.aggregated.forEach(agg => {
        if (d.secondaryAggregated) {
          let secondarySubmission = d.secondaryAggregated.find(sd => sd.organisationOnsCode === agg.organisationOnsCode);
          if (secondarySubmission) {
            agg.secondaryResult = secondarySubmission.submissionResult
          }
        }
      });
    });

    // Remove any periods without any secondary data
    data = data.filter(d => d.secondaryAggregated && d.secondaryAggregated.length);

    // Standardise data as combined
    data.forEach(d => {
      d.combined = d.aggregated;
      delete d.aggregated;
    });

    // Return data
    return data;
  }

  // UTILITIES 

  getFY(dateFrom) {
    let quarter = moment(dateFrom).quarter();
    let fyFrom = +moment(dateFrom).format('YY');
    let periodFy;
    if (quarter == 1) {
      periodFy = (fyFrom - 1).toString() + fyFrom.toString();
    } else {
      periodFy = fyFrom.toString() + (fyFrom + 1).toString();
    }
    return +periodFy;
  }

}