import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { AuthService } from '../utilities/services/auth.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  date = new Date();
  isCollapsed = true;
  
  metaTitle: string = 'Page Not Found | NHS Benchmarking Network';

  constructor(
    public auth: AuthService,
    private titleService: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
  }

  onHidden(): void { }
  
  onShown(): void { }

  isOpenChange(): void { }

}
