<a type="button" class="view-more" (click)="documentModal.show()">View more...</a>
<div bsModal #documentModal="bs-modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Member Project Reports for {{ projectName }}</h5>
      </div>
      <div class="modal-body">
        <tabset type="pills">
          <tab *ngFor="let year of documents | keyvalue" [heading]="year.key" (click)="selectedYear = year.key">

            <!-- Filters -->
            <ng-container *ngIf="filters">
              <label class="mt-2">Filters:</label><br>
              <select *ngFor="let filter of filters" [name]="filter.name" [id]="filter.name" 
                class="form-control filter-select mr-1" [disabled]="filter.options.length < 2"
                (change)="selectFilter(filter, $event.target.value)">
                <option selected disabled>Filter by {{ filter.name }}...</option>
                <option *ngFor="let opt of filter.options" [value]="opt">{{ opt }}</option>
              </select>
              <button class="btn btn-default" style="margin-top:-3px" (click)="clearFilters()">Clear Filters</button>
            </ng-container>

            <!-- Table-->
            <table class="table table-bordered mt-2">
              <thead>
                <tr>
                  <th style="width:50%">Document Name</th>
                  <th style="width:25%">Submission Name</th>
                  <th style="width:25%">Service Name</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let doc of year.value | orderBy: 'displaySequence'">
                  <td>
                    <a role="button" class="document-link" (click)="getSelectedDocument(doc.documentId)">
                      <i class="far fa-file-download mr-1"></i>{{ doc.documentName }}
                    </a>
                  </td>
                  <td>{{ doc.submissionName || '-' }}</td>
                  <td>{{ doc.serviceItemName || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>