import { Component, OnInit, ViewChild } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "../utilities/services/auth.service";
import { helpVideos } from "./help-videos";

@Component({
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent implements OnInit {
  date = new Date();
  isCollapsed = true;

  videos: Array<object> = helpVideos.getVideos();

  oneAtATime: boolean = true;
  customClass = "customClass";

  metaTitle: string = 'Help | NHS Benchmarking Network';

  constructor(
    public auth: AuthService,
    private titleService: Title,
    private meta: Meta,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle); // Set page title
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
  }

  onHidden(): void {}

  onShown(): void {}

  isOpenChange(): void {}

  stopVideos() {
    var videos = document.querySelectorAll("iframe, video");
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === "video") {
        video.pause();
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  }

  navHome() {
    this.router.navigate(["/home"]);
  }
}
