<div class="container" style="margin-top: 5rem; margin-bottom: 10rem; max-width: 50%;">

  <!-- EVENT FORM --> 
  <form [formGroup]="eventRegistration" *ngIf="!submitted" style="border: solid #ccc 1.5px; padding:3rem; border-radius: 0.5%;">
    <h3 class="modal-title">
      Event Registration Form
    </h3>
    <hr>    
    <div>
      <div class="form-group">
        <label class="control-label"
          >Please select the event you are registering for:
          <span style="color: #f00">*</span></label
        >
        <select
          class="form-control"
          formControlName="eventId"
          (change)="selectEvent($event.target.value)"
          [attr.title]="selectedEvent?.event_name_date"
        >
          <option value="none" disabled>
            Select an event...
          </option>
          <option
            *ngFor="let e of eventsList"
            [ngValue]="e.event_id"
          >
            {{ e.event_name_date }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="control-label"
          >Delegate Name: <span style="color: #f00">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="delegateName"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <div *ngIf="formErrors.delegateName" class="alert alert-danger">
          {{ formErrors.delegateName }}
        </div>
      </div>
      <div class="form-group">
        <label class="control-label"
          >Organisation: <span style="color: #f00">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="organisation"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <div *ngIf="formErrors.organisation" class="alert alert-danger">
          {{ formErrors.organisation }}
        </div>
      </div>
      <div class="form-group">
        <label class="control-label"
          >Job Title: <span style="color: #f00">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="delegateJob"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <div *ngIf="formErrors.delegateJob" class="alert alert-danger">
          {{ formErrors.delegateJob }}
        </div>
      </div>
      <div class="form-group">
        <label class="control-label"
          >Delegate Email Address:
          <span style="color: #f00">*</span></label
        >
        <input
          type="email"
          class="form-control"
          formControlName="delegateEmail"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <div
          *ngIf="formErrors.delegateEmail"
          class="alert alert-danger"
        >
          {{ formErrors.delegateEmail }}
        </div>
      </div> 
      <div class="row" *ngIf="!isOnline">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Dietary Requirements:</label>
            <select class="form-control" formControlName="delegateDiet">
              <option value="N">None</option>
              <option value="V">Vegetarian</option>
              <option value="P">Pescatarian</option>
              <option value="E">Vegan</option>
              <option value="O">Other (Specify)</option>
            </select>
          </div>
          <div class="form-group">
            <div
              *ngIf="formErrors.delegateDiet"
              class="alert alert-danger"
            >
              {{ formErrors.delegateDiet }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label"
              >Details (including any allergies)</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="delegateAllergies"
              autocomplete="off"
            />
          </div>
          <div class="form-group">
            <div
              *ngIf="formErrors.delegateAllergies"
              class="alert alert-danger"
            >
              {{ formErrors.delegateAllergies }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="!isOnline">
        <label class="control-label">Access Requirements:</label>
        <textarea
          rows="2"
          class="form-control"
          formControlName="delegateAccess"
          autocomplete="off"
          style="height: 8vh; resize: none;"
        ></textarea>
      </div>
      <div class="form-group">
        <div
          *ngIf="formErrors.delegateAccess"
          class="alert alert-danger"
        >
          {{ formErrors.delegateAccess }}
        </div>
      </div>
      <div class="form-group">
        <label class="control-label">Comments:</label>
        <textarea
          rows="2"
          class="form-control"
          formControlName="delegateComments"
          autocomplete="off"
          style="height: 8vh; resize: none;"
        ></textarea>
      </div> 
      <hr />      
      <div class="form-group">
        <div class="row">
          <div class="col-md-8">
            <label class="control-label"
              >Delegate List <span style="color: #f00">*</span></label
            >
            <p style="margin-bottom: 0">
              Are you happy for your details to be included on the
              delegate list?
            </p>
            <span class="text-muted"
              >This list will be shared with all delegates attending on
              the day.</span
            >
          </div>
          <div class="col-md-3 col-md-offset-1">
            <select
              class="form-control"
              formControlName="delegateList"
              ng-selected="Please Select"
              style="margin-top: 2em"
            >
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div class="form-actions">
        <button
          type="button"
          class="btn btn-lg btn-primary"
          [disabled]="!eventRegistration.valid"
          (click)="registerEvent()"
        >
          Register
        </button>        
      </div>
    </div>
  </form>

  <!-- SUCCESSFUL REGISTRATION MESSAGE -->
  <div id="registered" *ngIf="registered" style="margin-top: 20rem; border: solid #ccc 1.5px; border-radius: 1%; padding:3rem; overflow-wrap: break-word;">
    <h2>
      <i class="far fa-check-circle" style="color: green;"></i>
      You have successfully submitted a registration request
    </h2>
    <div style="margin-top: 3rem; margin-bottom: 3rem;">
      <p>Thank you for submitting a registration request for the event: 
        <span style="font-weight: bold;"> {{selectedEvent.event_name_date}} </span>.
      </p>
      <p>You will receive a notification once the request has been reviewed.</p>
      <p>If you would like to change details of your request, cancel it, or if you have any queries, please email <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries@nhsbenchmarking.nhs.uk.</a></p>
      <p>You can now close this page.</p>    
    </div>    
  </div>

  <!-- FAILED REGISTRATION MESSAGE -->
  <div *ngIf="submitted && registrationError" style="margin-top: 20rem; border: solid #ccc 1.5px; border-radius: 1%; padding:3rem; overflow-wrap: break-word;">
    <h2>
      <i class="far fa-exclamation-triangle" style="color: #f00;"></i>
      Something went wrong 
    </h2>
    <div *ngIf="!isDelegateAlreadyMember" style="margin-top: 3rem; margin-bottom: 3rem;">
      <p>There has been an error whilst trying to submit a registration request for the event: 
        <span style="font-weight: bold;"> {{selectedEvent.event_name_date}} </span>.
      </p>
      <p>Please try again later. If problem persists please contact the support team at<a href="mailto:enquiries@nhsbenchmarking.nhs.uk"> enquiries@nhsbenchmarking.nhs.uk</a>.</p>
    </div>   
    <div class="member-error" *ngIf="isDelegateAlreadyMember" style="margin-top: 3rem; margin-bottom: 3rem;">
      <p>You are trying to register to the event <br> 
        <span style="font-weight: bold;"> {{ selectedEvent.event_name_date }} </span>  <br>
        using the <i>Non Member Event Registration Form</i>, but we detected that you are from a Member Organisation.</p>
      <p>Please locate the Event from the  
        <a 
        style="font-weight: bold; text-decoration: underline; cursor: pointer;" 
        (click)="navigateToMemberEventsPage()"
        > Member's Event Registration Page
      </a>instead.
    </p>
    </div>     
  </div>

</div>