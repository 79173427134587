<!-- SIDEBAR -->
<div class="col-sm-4">
    <div>
      <h2>Welcome back, {{ user.fullName }}</h2>
      <p>
        This is the new NHS Benchmarking Members' Area home page,
        designed to make it easier to access projects, find information and key metrics across all sectors.
        <a href="https://www.nhsbenchmarking.nhs.uk/member-work-programme" target="_blank"> Read more <i class="fas fa-caret-right"></i></a>
      </p>
    </div>
    <hr />
    <div class="col-sm-11">
      <app-sidebar-section *ngFor="let section of sections" [section]="section"></app-sidebar-section>
    </div>
</div>