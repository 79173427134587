<!-- <div *ngIf="loaderService.isLoading | async" class="loading-spinner dashboard-spinner">
    <i class="fa fa-spinner fa-spin"></i>
    <p>The registration is loading. Please wait...</p>
</div> -->

<div class="container-fluid" id="containerRegistration" *ngIf="projectBasicDetails">
    <div class="row">
        <!-- HEADER -->
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-xs-12">
            <div class="row header">
                <div [class]="projectId === 36 ? 'col-xs-3 vcenter' : 'col-xs-2 vcenter'">
                    <img alt="NHSBN {{projectBasicDetails?.project.projectCategoryName}} logo" src="../../assets/images/{{projectBasicDetails?.project.projectCategoryName.split(' ').join('')}}.png" />  
                </div>
                <div [class]="projectId === 36 ? 'col-xs-9 vcenter' : 'col-xs-10 vcenter'">
                    <h2>{{projectBasicDetails.organisationName}}</h2>
                    <h1>{{projectBasicDetails.project.projectName}}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="registrationClosed">
                    <div class="registration-section">
                        <div>
                            <h3>Registration Closed</h3>
                            <p>
                                Registration for this project is not currently available. If you need further information, please contact us directly on 0161&nbsp;521&nbsp;0818 or email <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries</a>.
                            </p>
                            <p>
                                <button class="btn btn-default" [routerLink]="['/home']">
                                    Exit Registration
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-12" *ngIf="!registrationClosed">

                    <!-- NAIC Commissioners -->
                    <div *ngIf="projectId == 14" class="height-fix">
                        <p>
                            To register for the National Audit of Intermediate Care 2018, please download a copy of the
                            <a target="_blank"
                                href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NAIC%20(Commissioners)/2018/Registration%20template%20-%20commissioner%20-%20new%20FINAL.xlsm">registration form</a> and return to
                            <a href="mailto:nhsbn.naicsupport@nhs.net">NAIC Support</a> to confirm your registration.
                        </p>
                    </div>
                    
                    <!-- NAIC Providers-->
                    <div *ngIf="projectId == 15" class="height-fix">
                        <p>
                            To register for the National Audit of Intermediate Care 2018, please download a copy of the
                            <a target="_blank"
                                href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NAIC%20(Providers)/2018/Registration%20template%20-%20provider%20-%20new%20FINAL.xlsm">registration form</a> and return to
                                <a href="mailto:nhsbn.naicsupport@nhs.net">NAIC Support</a> as soon as possible to confirm your registration. Please note that whilst Providers can still 
                                register for NAIC 2018, the deadline has now passed to participate in the Service User Audit element of NAIC, and Providers will now only be able to 
                                participate in the organisational level audit. If you would like to discuss this further, please contact NAIC Support for further help.
                        </p>
                    </div>
                                
                    <!-- Other Projects -->
                    <div *ngIf="projectBasicDetails && projectId != 14 && projectId != 15">
                        <h2 class="title-intro">Registration{{ projectId == 34 ? ': NACEL 2025' : '' }}</h2>
                        <p *ngIf="guidanceIntro" [innerHTML]="guidanceIntro | safe: 'html'"></p>
                        <p *ngIf="!guidanceIntro">
                            <!-- Introduction -->
                            Welcome to the registration page for <span *ngIf="projectId === 41">the </span>{{projectBasicDetails.project.projectName}}. Please complete the required registration information shown in the sections below, and then click the <strong>Complete Registration</strong> button. Your organisation will then be registered for {{projectId !== 86 ? 'the project' : 'NACEL 2024'}}. You can save and exit at any point and your progress will be saved.
                        </p>
                        <p *ngIf="projectId && ![34, 35, 86, 88].includes(projectId) && projectBasicDetails.project.projectPhoneNo">
                            If you have any queries regarding this process, contact the
                            Support Team on
                            <strong>{{ projectBasicDetails.project.projectPhoneNo }}</strong>
                        </p>
                        <p *ngIf="projectId != 34 && projectId != 86 && projectBasicDetails && !projectBasicDetails.project.projectPhoneNo">
                            <!-- Generic project contact -->
                            If you have any queries regarding this process, please contact <strong><a href="mailto:{{ principleContact.email }}">{{ principleContact.name || principleContact.name }}</a></strong><span *ngIf="principleContact.phoneNo"> on <strong>{{ principleContact.phoneNo }}</strong></span>.
                        </p>
                        <p *ngIf="projectId == 86 || projectId == 88">If you have any queries regarding this process, please email the NACEL Support Team at <a href="mailto:nhsbn.nacelsupport@nhs.net">nhsbn.nacelsupport@nhs.net</a> or call <strong>0161 521 0866</strong>.</p>
                        <div *ngIf="projectId == 41">
                            <!-- Covid-19 project guidance -->
                            <p>
                                <strong><a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Community%20Services%20Covid-19%20Dashboard/2020/covid_19_cs_dashboard_guidance_v2.pdf" target="_blank"><i class="far fa-file-pdf"></i>&nbsp;Click here to download the the guidance document.</a></strong> This contains full details of the project, including how to register and submit data.
                            </p>
                        </div>
                        <div *ngIf="projectDetails?.registration.registrationLocked && allowChanges" class="alert alert-success">
                            <!-- Registration Complete message: non-ICS Project --> 
                            <div class="row">
                                <div class="col-sm-9">
                                    <strong>Your organisation is registered for this project.</strong> You can edit the registration details at any time by clicking Edit Registration, then make any amendments needed and click <strong>Complete Registration</strong> to save the changes.
                                </div>
                                <div class="col-sm-3">
                                    <button (click)="registerProject()"  class="btn btn-success">Edit Registration</button>
                                </div>
                            </div>                          
                        </div>
                        <p *ngIf="projectDetails?.registrationId && !allowChanges" class="alert alert-info">
                            <!-- No permissions -->
                            <strong>You do not currently have the necessary permissions to edit this registration.</strong> If changes need to be made, please contact the Project Lead.
                        </p>
        
                        <p *ngIf="organisationError" class="alert alert-danger">
                            <!-- Organisation error -->
                            <strong>There has been an error whilst trying to add a Project Lead.</strong> Your organisation may not have the required access to register for this project. Please contact the Support Team on 0161&nbsp;266&nbsp;1797.
                        </p>

                        <!-- PROJECT LEAD -->
                        <div class="registration-section" *ngIf="!organisationError">
                            <h3>Project Lead <span class="text-danger">*</span></h3>
                            <p *ngIf="guidanceProjectLead" [innerHTML]="guidanceProjectLead | safe: 'html'"></p>
                            <p *ngIf="!guidanceProjectLead">
                                Please specify the details of the person who will act as the lead contact for this project within your organisation. This role will be the primary recipient of any correspondence and will be responsible for co-ordinating the data collection. If you need to change the Project Lead, please contact the support team.
                            </p>
                            <app-pr-project-lead [registration]="projectDetails" [projectBasicDetails]="projectBasicDetails" [projectId]="projectId" [icsProject]="icsProject" (projectLeadAdd)="projectLeadAdd($event)" (organisationError)="setOrganisationError($event)"></app-pr-project-lead>
                        </div>

                        <!-- SUBMISSION GROUPS -->
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectDetails?.registration.commissionerProvider == 'C' && projectDetails?.registration.registrationType.displaySubmissionGroup != 'N'">
                            <h3>Submission Groups</h3>
                            <p *ngIf="guidanceSubmissionGroup" [innerHTML]="guidanceSubmissionGroup | safe: 'html'"></p>
                            <p *ngIf="!guidanceSubmissionGroup">
                                <strong>Optional:</strong> If you work in collaboration with other organisations you may wish to provide a submission that covers multiple organisations rather than just your own. If this is the case, please add the other organisations by selecting them from the drop-down menu.
                            </p>
                            <app-pr-submission-group [organisation]="projectDetails.organisation.commissioningPartners" [registration]="projectDetails" [organisationGroupList]="organisationGroupList" [commissioningPartners]="projectDetails.organisation.commissioningPartners" (submissionGroupAdd)="submissionGroupAdd($event)" (submissionGroupDelete)="submissionGroupDelete($event)"></app-pr-submission-group>
                        </div>
                        
                        <!-- SUBMISSIONS -->
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectId != 36 && projectId != 8 && projectId != 9">
                            <h3>Submissions <span class="text-danger">*</span></h3>
                            <p *ngIf="guidanceSubmission" [innerHTML]="guidanceSubmission | safe: 'html'"></p>
                            <p *ngIf="!guidanceSubmission">
                                A default submission has been created for you. You can submit one submission for the entire organisation or multiple submissions for different areas of the organisation. There is also the option to add a Submission Lead, should you want this to be different from the Project Lead. The Submission Lead will be responsible for co-ordinating the data collection for the submission.
                            </p>    
                            <app-pr-submission [projectDetails]="projectBasicDetails" [projectId]="projectId" [registration]="projectDetails" [guidanceSubmissionAdd]="guidanceSubmissionAdd" [icsProject]="icsProject" (submissionAdd)="submissionAdd($event)" (submissionEdit)="submissionEdit($event)" (submissionDelete)="submissionDelete($event)" (submissionLeadAdd)="submissionLeadAdd($event)" (submissionLeadDelete)="submissionLeadDelete($event)">
                            </app-pr-submission>
                        </div>

                        <!-- SERVICES -->
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectDetails?.registration.submission?.serviceList.length > 0 && projectDetails?.registration.submission.submissionList.length > 0">
                            <h3>
                                <span *ngIf="guidanceServiceTitle" [innerHTML]="guidanceServiceTitle | safe: 'html'"></span>
                                <span *ngIf="!guidanceServiceTitle">Services</span>
                                <span class="text-danger">&nbsp;*</span>
                            </h3>
                            <p *ngIf="guidanceService" [innerHTML]="guidanceService | safe: 'html'"></p>
                            <p *ngIf="!guidanceService">
                                Please select from the list below the services for which you intend to submit data. Services will need to be selected for each submission. For each service you include, there is the option to specify a Service Lead. If the Service Lead is not specified, the Submission Lead will automatically be assigned the role.
                            </p>
                            <app-pr-service *ngFor="let s of projectDetails?.registration.submission?.submissionList; let i = index" [projectId]="projectId" [registration]="projectDetails" [registrationId]="projectDetails?.registration.registrationId" [submission]="s" [services]="projectDetails?.registration.submission?.serviceList" [maxServices]="maxServices" [index]="i + 1" [serviceTitle]="guidanceServiceTitle || 'Service'" (serviceAdd)="serviceAdd($event)" (serviceDelete)="serviceDelete($event)" (serviceLeadAdd)="serviceLeadAdd($event)" (serviceLeadDelete)="serviceLeadDelete($event)">
                            </app-pr-service>
                        </div>

                        <!-- DEPUTY PROJECT LEAD -->
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0">
                            <h3>Deputy Project Lead</h3>
                            <p *ngIf="this.deputyProjectLeadNumber == null">
                                <strong>Optional:</strong> You can provide details of other colleagues who will be copied into the correspondence sent to the Project Lead. There is no limit on the number of Deputy Project Leads you can create.
                            </p>
                            <p *ngIf="this.deputyProjectLeadNumber != null">
                                <strong>Optional:</strong> You can provide details of up to {{deputyProjectLeadNumber}} other colleagues who will then be copied into the correspondence sent to the Project Lead.
                            </p>
                            <app-pr-deputy-project-lead [registration]="projectDetails" (deputyProjectLeadRemove)="deputyProjectLeadRemove($event)" (deputyProjectLeadAdd)="deputyProjectLeadAdd($event)"></app-pr-deputy-project-lead>
                        </div>

                        <!-- PROJECT ADDRESS -->
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectId == 36 || projectId == 50">


                            <ng-container *ngIf="projectDetails?.registration.address || !addressClosed"> <!-- if open -->
                                <h3>Delivery Address <span class="text-danger">*</span></h3>
                                <p>
                                    <strong class="text-danger">IMPORTANT:</strong> The patient survey aspect of this year’s data collection includes a paper-based patient survey. Your Trust will receive a survey pack which will include 100 surveys, each with a freepost envelope.
                                    <!--
                                        TODO: Update the close date
                                        These will be sent to you by courier on or around <strong>18th November 2019</strong>.
                                    -->
                                </p>
                                <p style="font-weight:bold">
                                    <a href="https://www.nhsbenchmarking.nhs.uk/nhsi-ld-project-documentation" target="_blank">
                                        <i class="fas fa-external-link-alt"></i> Click here to download the project guidance and cover letter.
                                    </a>
                                </p>
                                <p>
                                    Please provide contact details for the person who will receive the survey pack for your Trust and a mailing address to deliver them to.
                                </p>
                                <app-pr-address [registration]="projectDetails" [projectBasicDetails]="projectBasicDetails" (addressAdd)="addressAdd($event)" [addressClosed]="addressClosed"></app-pr-address>
                            </ng-container>

                            <ng-container *ngIf="!projectDetails?.registration.address && addressClosed"> <!-- if closed -->
                                <h3>Patient Survey</h3>
                                <p>
                                    <strong class="text-danger">IMPORTANT:</strong> The patient survey aspect of this year’s data collection is paper-based. The patient surveys are being printed and distributed by a third party and the deadline for participating in the patient survey has now passed. By registering to participate in the project, you will still be able to participate in the organisational level data collection and staff survey.
                                </p>
                                <p style="font-weight:bold">
                                    <a href="https://www.nhsbenchmarking.nhs.uk/nhsi-ld-project-documentation" target="_blank">
                                        <i class="fas fa-external-link-alt"></i> Click here to download the project guidance and cover letter.
                                    </a>
                                </p>
                            </ng-container>


                        </div>

                        <!-- NACEL DOCUMENTS: Not required in 2023
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectId == 34 && showACDocuments">
                            <h3>
                                Quality Survey Proforma: Acute and Community Submissions
                            </h3>
                            <p>
                                Click below to download a proforma to gather the required contact information of the nominated person(s) of patients who die within your hospital/site <b>between 1st April and 31st August 2022</b>.
                            </p>
                            <a class="btn btn-default" href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NACEL/2022/NACEL%20Quality%20Survey%20Proforma%202022%20-%20Acute%20and%20Community.xlsx" target="_blank">
                                <i class="far fa-file-download"></i> Download Proforma
                            </a>
                            <p class="highlight">
                                Download a proforma for each Acute and Community submission created.
                            </p>
                        </div> -->

                        <!-- NACEL MH DOCUMENTS: Not required in 2022
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectId == 34 && showMHDocuments">
                            <h3>
                                Quality Survey Proforma: Mental Health Submissions
                            </h3>
                            <p>
                                Click below to download a proforma to gather the required contact information of the nominated person(s) of patients who die within your hospital/site between <b>1st April and 31st August 2021</b>.
                            </p>
                            <a class="btn btn-default" href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/NACEL/2021/NACEL%20Quality%20Survey%20Proforma%202021%20-%20Mental%20Health%20v2.xlsx" target="_blank">
                                <i class="far fa-file-download"></i> Download Proforma
                            </a>
                            <p class="highlight">
                                Download a proforma for each Mental Health submission created.
                            </p>
                        </div> -->

                        <!-- COMMUNITY INDICATORS DATA SHARING AGREEMENT -->
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectId == 29">
                            <h3>
                                Data Sharing Agreement
                            </h3>
                            <p>
                                Outputs for the 2021/22 Community Indicators project are anonymous. Benchmarked results are displayed with unique identifiers known only to the individual participants themselves. Many participants would however prefer to share identifiers with other participants to support greater collaboration and shared learning.
                            </p>
                            <p>
                                <strong>Would you like to share your organisation's identifying code with other participants and see their codes?</strong>
                            </p>
                            <p>
                                By clicking 'Opt-in' below, you agree to share your organisation code, along with your organisation name with other community providers that likewise ‘Opt-in’ to the monthly community indicators benchmarking project. The output code from this project will be shared amongst the participating organisations in an excel list, emailed to the project team.  
                            </p>
                            <p>
                                Only organisations that ‘Opt-in’ to sharing their organisation code, will receive a list of other organisation codes, to encourage shared learning and transparency.
                            </p>
                            <p>
                                Organisations that select 'Do not opt in' will not have their details shared, but will also not receive the details of others.
                                Please note: If you ‘Opt-in’ to share your organisations toolkit code, this decision cannot be later changed during the 2021/22 project cycle. 
                            </p>
                            <ng-container *ngIf="allowChanges">
                                <p *ngIf="icsOptInData && !icsOptInData.sharingConsent">
                                    <button class="btn btn-primary mr-2" (click)="icsOptIn('Y')">Opt-in to share code</button>
                                    <button class="btn btn-primary" (click)="icsOptIn('N')">Do not opt-in</button>
                                </p>
                                <div *ngIf="icsOptInData && icsOptInData.sharingConsent === 'Y'" class="text-success">
                                    <p>
                                        <i class="fas fa-check-circle"></i><strong>&nbsp;Your organisation opted-in to sharing your organisation code with other participants on {{ icsOptInData.consentDt | date: 'EEEE d MMMM y' }}.</strong>
                                    </p>
                                    <p>
                                        If you would like to change your opt-in status, please contact the Support Team.
                                    </p>
                                </div>
                                <div *ngIf="icsOptInData && icsOptInData.sharingConsent === 'N'" class="text-danger">
                                    <p>
                                        <i class="fas fa-times-circle"></i><strong>&nbsp;Your organisation has not opted-in to sharing your organisation code with other participants.</strong>
                                    </p>
                                    <p>
                                        If you would like to change your opt-in status, please contact the Support Team.
                                    </p>
                                </div>
                            </ng-container>
                        </div>
                        
                        <!-- CS COVID-19 DASHBOARD SPECIFICATION -->
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectBasicDetails.project.projectName === 'Community Services Covid-19 Dashboard'">
                            <h3>
                                Data Specification
                            </h3>
                            <p>
                                Click below to download a copy of the data specification and submission timescales. The Excel data specification can be used to help collate information, but all data should be submitted online.
                            </p>
                            <div class="downloads">
                                <a class="btn btn-primary" href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Community%20Services%20Covid-19%20Dashboard/2020/Covid%20CS%20data%20collection%20-%20FINAL%20-%20updated%2011.09.xlsx">
                                    <i class="far fa-file-default"></i> Download Specification
                                </a>
                            </div>
                        </div>

                        <!-- INTERMEDIATE CARE: Service User Audit -->
                        <!--
                        <div class="registration-section" *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0 && projectId === 40">
                            <h3>
                                Service User Audit
                            </h3>
                            <p>
                                The Intermediate Care project also features a Service User Audit. Further information can be found in the Intermediate Care project guidance (download button below). For more information on the Service User Audit, please contact <a href="mailto:s.handby@nhs.net">Sarah Handby</a>.
                            </p>
                            <div class="downloads">
                                <a class="btn btn-primary" href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Intermediate%20Care%20(Providers)/2023/Intermediate%20Care%20guidance%202023.pdf" target="_blank">
                                    <i class="far fa-file-default"></i> Download Project Guidance
                                </a>
                            </div>
                        </div>
                        -->

                        <!-- ACTIONS -->
                        <div class="registration-action">

                            <!-- Actions -->
                            <ng-container *ngIf="!projectDetails?.registration.registrationLocked && allowChanges">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <!-- With Services -->
                                        <button
                                            *ngIf="projectId !== 36 && projectDetails?.registration.submission?.serviceList?.length"
                                            type="button" 
                                            class="btn btn-lg btn-success btn-promise" 
                                            (click)="registerProject()" 
                                            [disabled]="projectDetails?.registration.projectLead.projectLeadList.length < 1 || projectDetails?.registration.submission.submissionList.length < 1 || serviceCheck"
                                            [promiseBtn]="newSpinner">
                                                Complete Registration
                                        </button>
                                        <!-- Without Services -->
                                        <button 
                                            *ngIf="projectId !== 36 && projectId !== 8 && projectId !== 9 && projectId !== 29 && projectId !== 50 && projectDetails?.registration.submission?.serviceList?.length < 1"
                                            type="button" 
                                            class="btn btn-lg btn-success btn-promise" 
                                            (click)="registerProject()" 
                                            [disabled]="(projectDetails?.registration.projectLead.projectLeadList.length < 1) || (projectDetails?.registration.submission.submissionList.length < 1) || (projectId == 34 && !projectDetails?.registration.submission.submissionList[0].peerGroupId)"
                                            [promiseBtn]="newSpinner">
                                            Complete Registration
                                        </button>
                                        <!-- - NHSI LD -->
                                        <button *ngIf="projectId === 36 || projectId === 50" 
                                            (click)="registerProject()"
                                            type="button" 
                                            class="btn btn-lg btn-success btn-promise"
                                            [promiseBtn]="newSpinner"
                                            [disabled]="!projectDetails?.registration.address && !addressClosed">
                                            Complete Registration
                                        </button>
                                        <!--- Community Indicators -->
                                        <button *ngIf="projectId === 29" 
                                            (click)="registerProject()"
                                            type="button" 
                                            class="btn btn-lg btn-success btn-promise"
                                            [promiseBtn]="newSpinner"
                                            [disabled]="!icsOptInData || !icsOptInData.consentDt">
                                            Complete Registration
                                        </button>
                                        <!--- Offline Projects -->
                                        <button *ngIf="projectId === 8 || projectId === 9" 
                                            (click)="registerProject()"
                                            type="button" 
                                            class="btn btn-lg btn-success btn-promise"
                                            [promiseBtn]="newSpinner">
                                            Complete Registration
                                        </button>
                                        <button *ngIf="projectDetails?.registration.projectLead.projectLeadList.length > 0"  class="btn btn-lg btn-default btn-later" (click)="navHome()">
                                            Finish Later
                                        </button>
                                        <p class="text-muted explainer" *ngIf="projectId !== 36 && projectId !== 8 && projectId !== 9 && (projectDetails?.registration.projectLead.projectLeadList.length < 1 || projectDetails?.registration.submission.submissionList.length < 1 || serviceCheck)">
                                            You must complete the required sections above (marked with <span class="text-danger">*</span>) before you can complete the registration. You can exit the registration at any time and finish it later.
                                        </p>
                                        <p class="text-muted explainer" *ngIf="projectId == 34 && !projectDetails?.registration.submission.submissionList[0].peerGroupId">
                                            Please ensure all submissions have been assigned a type before completing registration.
                                        </p>
                                        <p class="text-muted explainer" *ngIf="!projectDetails?.registration.registrationLocked">
                                            Please complete the registration prior to data collection opening.
                                        </p>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Registration Locked -->
                            <ng-container *ngIf="projectDetails?.registration.registrationLocked && allowChanges">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <button class="btn btn-lg btn-default" [routerLink]="['/home']">
                                            Exit Registration
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                        </div>

                    </div> <!-- Other Projects -->
                </div> <!-- col -->

            </div> <!-- row -->
        </div> <!-- col -->
    </div> <!-- row -->
</div> <!-- container -->