export interface IProduct {
  productId: number;
  productName: string;
}

export class Products {
  public latitude: number;
  public longitude: string;
  public organisationId: number;
  public organisationName: string;
  public organisationTypeId: number;
  public organisationTypeName: string;
  public products: IProduct;
  public sectorDisplayName: string;
  public sectorId: number;
  public sectorName: string;

  constructor(products: Products) {
    this.mapProduct(products);
  }

  private mapProducts(products) {
    products.forEach((product: IProduct) => {
      switch (product.productId) {
        case 1:
          product.productName = "Member Programme Projects";
          break;
        case 2:
          product.productName = "National Audit of Intermediate Care";
          break;
        case 3:
          product.productName = "Patients Association Complainants Survey";
          break;
        case 4:
          product.productName = "Community Hospitals Research";
          break;
        case 5:
          product.productName = "National Audit for Care at the End of Life";
          break;
        case 6:
          product.productName = "NHSI - Learning Disabilities";
          break;
        case 7:
          product.productName = "Community Services Covid-19 Dashboard";
          break;
        case 8:
          product.productName = "CVDprevent";
          break;
        case 9:
          product.productName = "Same Day Emergency Care";
          break;
        case 12:
          product.productName = "South East Mental Health Dashboard";
          break;
        case 13:
          product.productName = "Workforce Programme";
          break;
        case 14:
          product.productName = "Bespoke Mental Health";
          break;
      }
    });

    return products;
  }

  private mapProduct(products: Products): void {
    this.latitude = products.latitude;
    this.longitude = products.longitude;
    this.organisationId = products.organisationId;
    this.organisationName = products.organisationName;
    this.organisationTypeId = products.organisationTypeId;
    this.organisationTypeName = products.organisationTypeName;
    this.products = this.mapProducts(products.products);
    this.sectorDisplayName = products.sectorDisplayName;
    this.sectorId = products.sectorId;
    this.sectorName = products.sectorName;
  }
}