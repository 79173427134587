<div class="row" *ngIf="data?.length > 1" style="margin: 5px;">
    <div class="col-md-12">
        <div class="table-spacing">
            <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-bottom: 10px;">
                <h5>{{ title }}</h5>
                <button *ngIf="extraInfoToggler" class="btn btn-sm btn-primary" (click)="connectWithMemberInfo = !connectWithMemberInfo">
                    Connect With A Member  
                    <i [class]="connectWithMemberInfo ? 'fas fa-chevron-up' : 'fas fa-chevron-down'" style="padding-left: 5px;"></i>
                </button>
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;" *ngIf="connectWithMemberInfo">
                <span>If you are interested in connecting with other members from this project, the NHSBN Support Team can facilitate this. Please contact 
                    <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries@nhsbenchmarking.nhs.uk</a> with: 
                </span> 
                    <ul>
                        <li>The name of the project </li>
                        <li>The name of the member organisation(s) you wish to be connected with </li>
                        <li>A short paragraph explaining what you would like to achieve from the connection</li>
                    </ul>
                <span> We will forward your email onto relevant contacts at the member organisation(s). The final decision to engage is with the receiving member. </span>
            </div>
            <table class="table table-bordered table-striped table-condensed table-metric">
                <thead>
                    <tr style="text-wrap: nowrap;">
                        <th class="text-center table-heading" *ngFor="let item of data[0].headers">{{ item }}</th>
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let item of data">
                        <td class="text-left mx-5" *ngFor="let items of item.data; let i = index">
                            <span>{{ items }} <br />
                                <small style="color: grey;">{{ item.subName[i] }}</small>
                                <a href="mailto:{{item.contactEmail[i]}}">{{ item.contactEmail[i] }}</a>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>