import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { MemberService } from '../../utilities/services/member.service';

@Component({
  selector: 'app-pr-service',
  templateUrl: './pr-service.component.html',
  styleUrls: ['./pr-service.component.scss']
})
export class PrServiceComponent implements OnChanges {
  @Input() projectId;
  @Input() registrationId;
  @Input() registration;
  @Input() submission;
  @Input() services;
  @Input() maxServices;
  @Input() index;
  @Input() serviceTitle;
  @Output() serviceAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() serviceDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() serviceLeadAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() serviceLeadDelete: EventEmitter<any> = new EventEmitter<any>();
  
  currentSubmissionId: number; currentServiceItemId: number; currentServices = [];

  serviceForm: FormGroup; serviceLeadForm: FormGroup; 
  showServiceForm: boolean = false; showServiceFormAdd: boolean = true;

  showServiceLeadForm: boolean = false;
  
  constructor(private memberService: MemberService, private fb: FormBuilder) {
  }

  ngOnChanges() {}

  serviceFormAdd(submissionId: number) {
    this.currentServices = [];
    this.currentSubmissionId = submissionId;
    this.showServiceFormAdd = false;
    
    this.services.forEach(element => {
      if (this.submission.services.findIndex(s => s.serviceItemId == element.serviceItemId) == -1) {
        this.currentServices.push(element);
      }
    });
    
    this.buildServiceForm();
  }

  serviceFormSubmit() {
    this.addService(this.registrationId, this.currentSubmissionId, this.serviceForm.value);

    this.showServiceForm = false;
    this.showServiceFormAdd = true;
  }

  addService(registrationId, submissionId, serviceItem) {
    this.memberService.AddService(registrationId, submissionId, serviceItem).subscribe(
      s => {
        let newService = { 
          submissionId: submissionId, 
          serviceItemId: serviceItem.serviceItemId, 
          serviceItemName: this.services.filter(s => s.serviceItemId == serviceItem.serviceItemId)[0].serviceItemName
        };

        this.serviceAdd.emit(newService);
      }
    );
  }

  serviceFormRemove(submissionId: number, serviceItemId: number) {
    if (confirm("Are you sure you want to remove this service?")) {
      this.removeService(this.registrationId, submissionId, serviceItemId);
    }
  }

  removeService(registrationId, submissionId, serviceItemId) {
    this.memberService.RemoveService(registrationId, submissionId, serviceItemId).subscribe(
      s => {
        let deleteService = { 
          submissionId: submissionId,
          serviceItemId: serviceItemId
        };

        this.serviceDelete.emit(deleteService);
      }
    );
  }

  serviceUpdateLeadAdd(submissionId: number, serviceItemId: number) {
    this.currentSubmissionId = submissionId;
    this.currentServiceItemId = serviceItemId;
    
    this.buildServiceLeadForm();

    this.showServiceLeadForm = true;
  }

  serviceUpdateLeadRemove(submissionId: number, serviceItemId: number) {
    if (confirm("Are you sure you want to remove this Service Lead?")) {
      this.removeServiceLead(this.registrationId, submissionId, serviceItemId);
    }
  }

  serviceLeadFormSubmit() {
    let serviceLead = {
      fullName: this.serviceLeadForm.value.fullName,
      logonName: this.serviceLeadForm.value.logonName, 
      jobTitle: '',
      phoneNo: ''
    };

    this.addServiceLead(this.registrationId, this.currentSubmissionId, this.currentServiceItemId, serviceLead);

    this.showServiceLeadForm = false;
  }

  addServiceLead(registrationId: number, submissionId: number, serviceItemId: number, serviceItem) {
    this.memberService.AddServiceLead(registrationId, submissionId, serviceItemId, serviceItem).subscribe(
      s => {
        let newServiceLead = { 
          submissionId: submissionId, 
          serviceItemId: serviceItemId, 
          userId: s.data[0].userId,
          fullName: s.data[0].fullName,
          logonName: s.data[0].logonName
        };

        this.serviceLeadAdd.emit(newServiceLead);
      }
    );
  }

  removeServiceLead(registrationId: number, submissionId: number, serviceItemId: number) {
    this.memberService.RemoveServiceLead(registrationId, submissionId, serviceItemId).subscribe(
      s => {
        let removeServiceLead = { 
          submissionId: submissionId, 
          serviceItemId: serviceItemId
        };

        this.serviceLeadDelete.emit(removeServiceLead);
      }
    );
  }

  buildServiceForm() {
    this.serviceForm = this.fb.group({
      serviceItemId: [null, [Validators.required]]
    });

    this.serviceForm.valueChanges.subscribe(
      data => this.onServiceValueChanged(data)
    );

    this.onServiceValueChanged();

    this.showServiceForm = true;
  }

  buildServiceLeadForm() {
    this.serviceLeadForm = this.fb.group({
      logonName: ['', [Validators.required, Validators.maxLength(200), <any>Validators.email]],
      fullName: ['', [Validators.required, Validators.maxLength(150)]]
    });

    this.serviceLeadForm.valueChanges.subscribe(
      data => this.onServiceLeadValueChanged(data)
    );

    this.onServiceLeadValueChanged();
  }
  
  onServiceValueChanged(data?: any) {
    if (!this.serviceForm) { return; }
    const form = this.serviceForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  
  onServiceLeadValueChanged(data?: any) {
    if (!this.serviceLeadForm) { return; }
    const form = this.serviceLeadForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  formErrors = {
    'serviceItemId': '',
    'fullName': '',
    'logonName': '',    
    'jobTitle': '',
    'phoneNo': ''
  }

  validationMessages = {
    'serviceItemId': {
      'required': 'Service is required.'      
    },
    'fullName': {
      'required': 'Full name is required.',
      'maxlength': 'Full name cannot be longer than 150 characters.'
    },
    'logonName': {
      'required': 'Email Address is required.',
      'maxlength': 'Email Address cannot be longer than 200 characters.',
      'email': 'Email Address is not valid.'
    },
    'jobTitle': {
      'required': 'Job title is required.',
      'maxlength': 'Job title cannot be longer than 150 characters.'
    },
    'phoneNo': {
      'required': 'Phone number is required.',
      'maxlength': 'Phone number cannot be longer than 40 characters.'
    }
  }
}