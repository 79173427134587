import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// Services
import { Meta, Title } from '@angular/platform-browser';
import { IcsService } from 'app/utilities/services/ics.service';
import { MemberService } from 'app/utilities/services/member.service';

// Pipes
import { SumPipe, MakePluralStringPipe } from 'ngx-pipes';
import { ModalDirective } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {

  @ViewChild('guidanceModal') public guidanceModal:ModalDirective;

  subscriptions = [];
  projectId; projectName; projectDetails;
  activeAdmin; csvErrors; csvSuccess: boolean = false;

  menuSelect = [
    { name: 'organisations', open: false },
    { name: 'dates', open: false },
    { name: 'submissions', open: false }
  ]

  error; errorSave;
  collection;
  collectionClosed: boolean = false;
  collectionOverride: boolean = false;
  collectionCloseDate: any;
  organisations; selectedOrganisation;
  submissions; selectedSubmission;
  dates; selectedDate; 
  selectedGroup;

  projectDates; selectedProjectDate; csvDate;
  hiddenYear: boolean = false;

  collectionForm: FormGroup;
  questions; loadingQuestions: boolean = false;

  saving: boolean = false;

  metaTitle: string = 'Data Collection | NHS Benchmarking Network';

  constructor(
    private titleService: Title,
    private meta: Meta,
    private icsService: IcsService,
    private memberService: MemberService,
    public route: ActivatedRoute,
    public router: Router,
    public sum: SumPipe,
    public pluralise: MakePluralStringPipe,
    public date: DatePipe
  ) {
    this.projectId = +this.route.snapshot.paramMap.get('projectId');
  }

  ngOnInit() { 
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.getSubmissions(this.projectId);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  getSubmissions(projectId) {
    this.subscriptions.push(
      this.icsService.submissions(projectId).subscribe(
        r => {

          // Set project name
          this.projectName = r.data.projectDetails.projectName;
          this.projectDetails = r.data.projectDetails;

          // Set admin user
          let user = JSON.parse(sessionStorage.getItem('user'));
          this.activeAdmin = user && user.isAdmin && user.isAdmin === 'Y' ? true : false;

          // Get saved organisation
          let savedOrganisation = JSON.parse(sessionStorage.getItem('organisationId'));

          // Set project dates
          this.projectDates = this.formatDates(r.data.projectDates);
          this.selectedProjectDate = this.projectDates[0];

          // Save all collection data
          this.collection = r.data;

          // Get organisation
          this.organisations = r.data.userOrganisations;
          if (this.organisations.length > 0) {
            let selectedOrganisation;
            if (savedOrganisation) {
              selectedOrganisation = this.organisations.filter(o => o.externalOrganisationId === +savedOrganisation)[0];
              if (selectedOrganisation) {
                this.selectedOrganisation = selectedOrganisation;
                this.setSubmissions(selectedOrganisation);
              } else {
                this.selectedOrganisation = this.organisations[0];
                this.setSubmissions(this.selectedOrganisation);
              }
            } else {
              this.selectedOrganisation = this.organisations[0];
              this.setSubmissions(this.selectedOrganisation);
            }
          } else {
            this.error = "You do not have the necessary permissions to view this data collection page. Please contact the Support Team."
          }

        },
        e => {
          console.log(e);
          this.error = "There has been an error retrieving the data collection page. Please try refreshing the page, then contact the Support Team."
        }
      )
    )
  }

  getCollection(projectId, selectedDate, submissionId) {
    this.loadingQuestions = true;
    this.selectedProjectDate = selectedDate;
    this.csvSuccess = false;
    this.csvDate = this.date.transform(this.selectedProjectDate.dateFrom, 'MMM-yy');
    this.subscriptions.push(
      this.icsService.collection(projectId, selectedDate.dateId, submissionId).subscribe(
        r => {

          let collectionDates = this.collection.projectDates.filter(c => c.dateId === selectedDate.dateId)[0];

          if (collectionDates.activeAdmin) {
            this.hiddenYear = true;
          } else {
            this.hiddenYear = false;
          }

          let collectionEnd;
          
          if (collectionDates.extendedCollectionEnd) {
            collectionEnd = collectionDates.extendedCollectionEnd;
          } else {
            collectionEnd = collectionDates.collectionEnd
          }
          
          this.collectionCloseDate = new Date(collectionEnd);
          let timeNow = new Date(Date.now());
          let diff = this.collectionCloseDate.getTime() - timeNow.getTime();
          
          if (diff < 0) {
            if (this.activeAdmin) {
              this.collectionOverride = true;
              this.collectionClosed = false;
            } else {
              this.collectionClosed = true;
            }
          } else {
            this.collectionOverride = false;
            this.collectionClosed = false;
          } 

          let groups = r.data.dataItemGroups,
              categories = r.data.dataItemCategories;
          if (groups.length > 0) {
            this.setQuestions(groups, categories);
          } else {
            this.error = "There are no questions set for this project or time period yet. Please contact the Support Team";
            this.loadingQuestions = false;
          }
        },
        e => {
          console.log(e);
          this.error = "There has been an error retrieving the data collection page. Please try refreshing the page, then contact the Support Team.";
          this.loadingQuestions = false;
        }
      )
    )
  }

  setSubmissions(organisation) {
    this.submissions = organisation.submissionList;
    let urlSubmission = +this.route.snapshot.queryParams.submission;
    if (urlSubmission) {
      this.selectedSubmission = this.selectedOrganisation.submissionList.filter(s => s.submissionId === urlSubmission)[0];
    } else {
      this.selectedSubmission = this.selectedOrganisation.submissionList[0];
    }
    this.dates = this.collection.projectDates.reverse();
    let urlDate = +this.route.snapshot.queryParams.date;
    if (urlDate) {
      this.selectedProjectDate = this.projectDates.filter(d => d.dateId === urlDate)[0];
      if (this.selectedSubmission) {
        this.getCollection(this.projectId, this.selectedProjectDate, this.selectedSubmission.submissionId);
      } else {
        this.selectOrganisation(organisation);
      }
    } else {
      this.getCollection(this.projectId, this.selectedProjectDate, this.selectedSubmission.submissionId);
    }
  }

  setQuestions(groups, categories) {
    this.questions = this.formatQuestions(groups, categories);
    let questionGroup = {};    
    this.questions.forEach(q => {
      if (q.response !== null) {
        questionGroup[q.id] = new FormControl({ value: q.response, disabled: this.collectionClosed }, [
          q.decimalPlaces === null ? Validators.pattern("^[+-]?[0-9]*$") : Validators.pattern('^[+-]?[0-9]*(?:\.[0-9]{0,' + q.decimalPlaces + '})?$'),
          Validators.min(q.rangeMin),
          Validators.max(q.rangeMax)
        ]);
      } else {
        questionGroup[q.id] = new FormControl({ value: '', disabled: this.collectionClosed }, [
          q.decimalPlaces === null ? Validators.pattern("^[+-]?[0-9]*$") : Validators.pattern('^[+-]?[0-9]*(?:\.[0-9]{0,' + q.decimalPlaces + '})?$'),
          Validators.min(q.rangeMin),
          Validators.max(q.rangeMax)
        ]);
      }
    });
    this.collectionForm = new FormGroup(questionGroup);
    this.router.navigate([], { queryParams: { submission: this.selectedSubmission.submissionId, date: this.selectedProjectDate.dateId }, queryParamsHandling: 'merge' });
    this.loadingQuestions = false;
    this.onChanges();
  }

  onChanges(): void {
    this.collectionForm.valueChanges.subscribe(val => {
      Object.keys(val).forEach(key => {
        let question = this.questions.filter(q => q.id === +key)[0];
        let errors = this.collectionForm.controls[key].errors;
        question.errors = errors;
      });
    });
  }

  // UTILITIES

  selectOrganisation(organisation) {
    this.router.navigate([], { queryParams: { submission: null, date: null }, queryParamsHandling: 'merge' });
    this.selectedDate = null;
    this.questions = null;
    let findOrganisation = this.organisations.indexOf(organisation);
    this.selectedOrganisation = this.organisations[findOrganisation];
    this.submissions = organisation.submissionList;
    this.selectedSubmission = this.selectedOrganisation.submissionList[0];
    this.getCollection(this.projectId, this.selectedProjectDate, this.selectedSubmission.submissionId);
  }

  changeSubmission(submission) {
    this.selectedSubmission = submission;
    if (this.selectedProjectDate) {
      this.getCollection(this.projectId, this.selectedProjectDate, submission.submissionId);
    }
  }

  formatDates(dates) {
    let outputData = [],
        timeNow = new Date(Date.now());

    // Remove any dates without collection ends (hidden)
    dates = dates.filter(d => d.collectionEnd);

    // Set Active Admin state if data collection is
    // not yet open. collectionEnd date must be set
    dates.forEach(d => {
      if (d.collectionEnd) {
        let timeCollectionStart = new Date(d.collectionStart);
        let timeDiff = timeCollectionStart.getTime() - timeNow.getTime();
        if (timeDiff < 0) {
          d.activeAdmin = false;
          outputData.push(d);
        } else if (this.activeAdmin) {
          d.activeAdmin = true;
          outputData.push(d);
        }
      }
    });

    return outputData.reverse();
  }

  formatQuestions(groups, categories) {
    let output = [];

    groups.forEach(g => {
      if (g.dataItemList.length > 0) {
        g.dataItemList.forEach(dil => {
          let response;
          let category = categories.filter(c => c.dataItemId === dil.dataItemId);
          // Set response
          if (dil.responseList.length > 0) { 
            response = dil.responseList[0];
          }
          // Set category name
          if (category.length > 0) {
            category = category[0].categoryName;
          } else {
            category = null;
          }
          output.push({
            groupId: g.dataItemGroupId,
            groupName: g.dataItemGroupName,
            categoryName: category,
            id: dil.dataItemId,
            code: dil.dataItemCode,
            type: "number",
            decimalPlaces: dil.decimalPlaces,
            label: dil.dataItemName,
            help: dil.dataDefinition,
            formatModifier: dil.formatModifier,
            rangeMax: dil.rangeMax,
            rangeMin: dil.rangeMin,
            showHelp: false,
            response: response ? response.responseValue : null,
            responseId: response ? response.responseId : null,
            visible: true,
            errors: null
          });
        })
      }
    })

    return output;
  }

  submit(){
    let responses = [];
    this.saving = true;

    Object.keys(this.collectionForm.controls).forEach(key => {
      let currentResponse = this.collectionForm.controls[key];

      if (currentResponse.dirty) {
        responses.push({
          dataItemId: +key,
          responseValue: currentResponse.value
        });
      }
    });

    if (responses.length > 0) {
      this.saveResponses(this.projectId, this.selectedProjectDate.dateId, this.selectedSubmission.submissionId, responses);
    } else {
      console.log('No changes to save.');
      this.saving = false;
    }
  }

  saveResponses(projectId, dateId, submissionId, responses) {
    this.icsService.saveResponses(projectId, dateId, submissionId, responses).subscribe(
      r => {
        this.collectionForm.markAsPristine();
      },
      e => {
        console.log(e);
        this.errorSave = e.error.message;
      },
      () => {
        this.saving = false;
      }
    )

  }

  fromCSV(id) {

    // Save errors
    this.csvErrors = [];
    this.csvSuccess = false;
    let csvErrors = [];

    // Get input text and month to match
    let input = ((document.getElementById(id) as HTMLInputElement).value);

    let inputMonth = this.csvDate;

    // Split into lines
    let lines = input.split('\n');

    // Get header line and remove it
    const header = lines[0].split('\t');
    lines = lines.slice(1);

    // Define month column; error if not present
    let monthIndex = header.indexOf(inputMonth);
    if (monthIndex < 0) {
      csvErrors.push('The response column is not specified within the pasted data.');
    } else {

      // Define reference column
      let refIndex = header.indexOf('Ref');
      if (refIndex < 0) {
        csvErrors.push('There is no reference column specified in the pasted data.')
      }

      // Define individual responses per line
      let responses = [];
      lines.forEach(line => {
        let responseLine = line.split('\t');
        // Only add response if not blank
        if (responseLine[monthIndex] !== '') {
          responses.push({
            code: responseLine[refIndex],
            value: responseLine[monthIndex]
          });
        }
      });

      // Remove responses with no codes
      responses = responses.filter(r => r.code !== '');

      // TODO: Find differences between pasted responses
      // and form controls (.length?)

      // Add the question ID based on question code
      responses.forEach(r => {
        let question = this.questions.filter(q => q.code === r.code)[0];
        if (question) {
          r.id = question.id;
        } else {
          csvErrors.push('There is no question on this page with the code ' + r.code + '.')
        }
      });

      // Remove non-numeric characters (, %)
      responses.forEach(r => {
        r.value = r.value.replace(/,/g, '').replace(/%/g, '')
      });

      // Convert all strings to numbers
      responses.forEach(r => {
        r.value = +r.value
      });

      // Set the form values based on question ID
      responses.forEach(r => {
        let check = this.collectionForm.controls[r.id];
        if (check) {
          this.collectionForm.controls[r.id].setValue(r.value);
          this.collectionForm.controls[r.id].markAsDirty();
        } else {
          csvErrors.push('Error when trying to add ' + r.code + ' to the form.')
        }
      });

    }

    if (csvErrors.length > 0) {
      this.csvErrors = csvErrors;
    } else {
      this.csvSuccess = true;
    }

  }

  toggleMenu(index) {
    this.menuSelect[index].open = !this.menuSelect[index].open;
  }

  toggleGroup(group) {
    let questions = this.questions.filter(q => q.groupName === group[0]);
    if (questions[0].visible === true) {
      questions.forEach(q => q.visible = false);
    } else {
      questions.forEach(q => q.visible = true);
    }
  }
  
  toggleHelp(question) {
    let findQuestion = this.questions.indexOf(question),
        thisQuestion = this.questions[findQuestion];

    thisQuestion.showHelp = !thisQuestion.showHelp
  }

  unsubscribe() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  navHome() {
    if (this.collectionForm && this.collectionForm.dirty) {
      if(confirm('Are you sure you want to exit this page with unsaved changes? You can save changes by scrolling to the bottom of the data collection page. Click "OK" to continue or "Cancel" to remain on the page')) {
        this.router.navigate(['/home']);
        this.unsubscribe();
      }
    } else {
      this.router.navigate(['/home']);
      this.unsubscribe();
    }
  }

  closeMenu() {
    this.menuSelect.forEach(s => { s.open = false; });
  }

  autoSum(question) {
    let categoryName = question.categoryName,
        groupName = question.groupName,
        category = this.questions.filter(q => q.categoryName === categoryName && q.groupName === groupName && q.formatModifier !== 'S'),
        values = [];

    if (category.length > 0) {
      category.forEach(c => {
        let control = this.collectionForm.controls[c.id];
        values.push(+control.value);
      });
      let total = this.sum.transform(values);
      this.collectionForm.controls[question.id].setValue(total);
      this.collectionForm.controls[question.id].markAsDirty();
    }

  }

}