<!-- Info -->
<div *ngIf="!infoPanel.componentDescriptions" class="display-inline position-relative mr-2">
  <button class="btn btn-white mb-1" (click)="onInfoPanelClick('info')">
    Info
  </button>
</div>

<div *ngIf="infoPanel.componentDescriptions" (click)="onInfoPanelClick('info')">
  <i class="fas fa-info-circle mr-1"></i>Info
</div>

<!-- MODAL: INFO -->
<ng-container *ngIf="infoPanel.info">
  <div class="custom-modal">
    <h3>Report Information</h3>
    <table class="table table-sm table-bordered" *ngIf="infoPanel.reportId">
      <tr>
        <td class="bg-light font-weight-bold" style="width:40%">Report ID</td>
        <td>{{ infoPanel.reportId }}</td>
      </tr>
      <tr>
        <td class="bg-light font-weight-bold">Report Name</td>
        <td>{{ infoPanel.reportName }}</td>
      </tr>
      <tr *ngIf="infoPanel.organisationType">
        <td class="bg-light font-weight-bold">Organisation Type</td>
        <td>{{ infoPanel.organisationType }}</td>
      </tr>
      <tr *ngIf="infoPanel.tags && infoPanel.tags.length">
        <td class="bg-light font-weight-bold">Tags</td>
        <td>{{ infoPanel.tags?.join(', ') }}</td>
      </tr>
      <tr>
        <td class="bg-light font-weight-bold">Factor</td>
        <td>{{ infoPanel.factor }}</td>
      </tr>
      <ng-container *ngIf="infoPanel.admin && infoPanel.options">
        <tr>
          <td class="bg-active-admin font-weight-bold">Options</td>
          <td>{{ infoPanel.options.join(', ') }}</td>
        </tr>
        <tr>
          <td class="bg-active-admin font-weight-bold">Map URL</td>
          <td>{{ infoPanel.mapUrl }}</td>
        </tr>
        <tr>
          <td class="bg-active-admin font-weight-bold">Submissions/Map Count</td>
          <td *ngIf="infoPanel.noMapAreas || infoPanel.noResults">{{ infoPanel.noResults }}/{{ infoPanel.noMapAreas }}</td>
        </tr>
      </ng-container>
      <tr class="bg-light font-weight-bold">
        <td colspan="2">Components</td>
      </tr>
      <tr>
        <td *ngIf="!infoPanel.componentDescriptions" colspan="2">
          <div *ngFor="let type of infoPanel.components | groupBy: 'type' | pairs">
            <div class="font-weight-bold">{{ type[0] }}:</div>
            <div *ngFor="let c of type[1]" class="mt-1">
              <strong class="bg-active-admin" *ngIf="infoPanel.admin">{{ c.id }}:</strong> {{ c.name }}
              <div class="small mb-1 mt-1" *ngIf="c.source">
                Source: <a [href]="c.sourceUrl" target="_blank">{{ c.source }}</a>
              </div>
              <div class="text-muted small">{{ c.description }}</div>
            </div>
          </div>
        </td>
        <td *ngIf="infoPanel.componentDescriptions" colspan="2">
          <div *ngIf="infoPanel.componentDescriptions.N">
            <div class="font-weight-bold">Numerators:</div>
            <div class="mt-1" *ngFor="let n of infoPanel.componentDescriptions.N">
              <strong class="bg-active-admin" *ngIf="infoPanel.admin">{{ n.dataItemId }}:</strong> {{ n.dataItemName }}
              <div class="small mt-1" *ngIf="n.dataItemCode">
                <strong>Code:</strong> {{ n.dataItemCode }}
              </div>
              <div class="small mt-1" *ngIf="n.dataSource">
                Source: {{ n.dataSource }}
              </div>
              <div class="text-muted small">{{ n.dataItemDescription }}</div>
            </div>
          </div>
          <div *ngIf="infoPanel.componentDescriptions.D">
            <div class="font-weight-bold mt-3">Denominators:</div>
            <div class="mt-1" *ngFor="let n of infoPanel.componentDescriptions.D">
              <strong class="bg-active-admin" *ngIf="infoPanel.admin">{{ n.dataItemId }}:</strong> {{ n.dataItemName }}
              <div class="small mt-1" *ngIf="n.dataItemCode">
                <strong>Code:</strong> {{ n.dataItemCode }}
              </div>
              <div class="small mt-1" *ngIf="n.dataSource">
                Source: {{ n.dataSource }}
              </div>
              <div class="text-muted small">{{ n.dataItemDescription }}</div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</ng-container>