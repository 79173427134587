export class QuestionBase<T>{
  questionId: number;
  questionText: string;
  rawQuestionText: string;
  questionHelp: string;
  questionType: string;
  questionResponseType: string;
  questionHeading: Array<{ headingId: number, headingGroupName: string, questionPart: number, headingText: string, headingHelp: string }>;
  displaySequence: number;
  formatModifier: string;
  rangeMin: number;
  rangeMax: number;
  decimalPlaces: number;
  showHeadings: string;
  noColumns: number;
  totalResponses: number;
  options: any;  
  popupQuestions: Array<{ 
    displaySequence: number, 
    questionId: number, 
    questionText: string,
    rawQuestionText: string;
    questionHelp: string, 
    questionType: string, 
    decimalPlaces: number, 
    questionPart: number,
    formatModifier: string, 
    headings: Array<T>,
    isAutoSum: string,
    itemGroup: number,
    noColumns: number,
    options: Array<T>,
    rangeMin: number,
    rangeMax: number,
    showHeadings: string,
    parentQuestionId: number,
    parentQuestionText: string,
    parentQuestionHelp: string,
    dataDefinition: string
  }>;
  popupDescriptionText: string;
  conditionQuestions: Array<{ conditionMin: number, conditionMax: number, conditionType: number, conditionQuestionId: number, conditionQuestionValue, parentConditionMin: number, parentConditionMax: number }>;
  isParent: boolean;
  modifierType: string;
  questionPrefix: string;
  parentQuestionId: number;
  parentQuestionText: string;
  parentQuestionHelp: string;
  dataDefinition: string;
  responses: Array<{ 
    uniqueId: string, 
    questionPart: number, 
    value: T, 
    questionType: string, 
    popupItemQuestionPart: number,
    updatedBy: number,
    createdBy: number
  }>;

  constructor(options: {
      questionId?: number,
      questionText?: string,
      rawQuestionText?: string;
      questionHelp?: string,
      questionType?: string,
      questionResponseType?: string;
      questionHeading?: Array<{ headingId: number, headingGroupName: string, questionPart: number, headingText: string, headingHelp: string }>,
      displaySequence?: number,
      formatModifier?: string,
      rangeMin?: number,
      rangeMax?: number,
      decimalPlaces?: number,
      showHeadings?: string,
      noColumns?: number,
      totalResponses?: number,
      options?: any,
      popupQuestions?: Array<{ 
        displaySequence: number, 
        questionId: number, 
        questionText: string,
        rawQuestionText: string;
        questionHelp: string, 
        questionType: string, 
        decimalPlaces: number, 
        questionPart: number,
        formatModifier: string, 
        headings: Array<T>,
        isAutoSum: string,
        itemGroup: number,
        noColumns: number,
        options: Array<T>,
        rangeMin: number,
        rangeMax: number,
        showHeadings: string
        parentQuestionId: number,
        parentQuestionText: string,
        parentQuestionHelp: string,
        dataDefinition: string
      }>,
      popupDescriptionText?: string,
      conditionQuestions?: Array<{ conditionMin: number, conditionMax: number, conditionType: number, conditionQuestionId: number, conditionQuestionValue: number, parentConditionMin: number, parentConditionMax: number }>;
      isParent?: boolean,
      modifierType?: string,
      questionPrefix?: string;
      parentQuestionId?: number;
      parentQuestionText?: string;
      parentQuestionHelp?: string;
      dataDefinition?: string;
      responses?: Array<{ 
        uniqueId: string, 
        questionPart: number, 
        value: T, 
        questionType: string, 
        popupItemQuestionPart: number,
        updatedBy: number,
        createdBy: number
      }>
    } = {}) {
    this.questionId = options.questionId;
    this.questionText = options.questionText;
    this.rawQuestionText = options.rawQuestionText;
    this.questionHelp = options.questionHelp || '';
    this.questionType = options.questionType;
    this.questionResponseType = options.questionResponseType || null;
    this.questionHeading = options.questionHeading || [];
    this.displaySequence = options.displaySequence === undefined ? 1 : options.displaySequence;
    this.formatModifier = options.formatModifier || '';
    this.rangeMin = options.rangeMin;
    this.rangeMax = options.rangeMax;
    this.decimalPlaces = options.decimalPlaces;
    this.showHeadings = options.showHeadings || 'N';
    this.noColumns = options.noColumns || 0;
    this.totalResponses = options.totalResponses || 0;
    this.options = options.options || [];
    this.popupQuestions = options.popupQuestions;
    this.popupDescriptionText = options.popupDescriptionText || '';
    this.conditionQuestions = options.conditionQuestions;
    this.isParent = options.isParent;
    this.modifierType = options.modifierType;
    this.questionPrefix = options.questionPrefix;
    this.responses = options.responses;
    this.parentQuestionId = options.parentQuestionId;
    this.parentQuestionText = options.parentQuestionText;
    this.parentQuestionHelp = options.parentQuestionHelp;
    this.dataDefinition = options.dataDefinition;
  }
}