import { Component, Input, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Project } from "app/utilities/classes/project/project";
import { LoaderService } from "app/utilities/services/loader.service";
import { MemberService } from "app/utilities/services/member.service";


interface Section {
  title: string;
  projects: Array<Project>;
}

type ProjectApiResponse = {
  [key: string]: Array<Project>
}

@Component({
  selector: "app-old-project-table",
  templateUrl: "./old-project-table.component.html",
  styleUrls: ["./old-project-table.component.scss"],
})
export class OldProjectTableComponent implements OnChanges {
  @Input() organisationSelection: number;

  sections: Array<Section> = [];
  archived: Section;
  errorRegistrationNA: string =
    "Registration is not currently available for this project"; // Error codes: 2101, 2103
  errorDataCollectionNA: string =
    "Data collection is not currently available for this project."; // 2102
  errorOrganisationAccess: string =
    "Your organisation does not currently have access to this project. Please contact support."; // 2104, 2105, 2106
  errorUserAccess: string =
    "You do not currently have access to this project. Please contact support."; // 2107, 2108, 2109

  hideShow: boolean = true;

  constructor(
    private memberService: MemberService,
    private router: Router,
    public loaderService: LoaderService
  ) {}

  ngOnChanges() {
    if (this.organisationSelection) {
      this.getProjectAccess();
    }
  }

  getProjectAccess() {
    this.memberService
      .ProjectAccess(this.organisationSelection)
      .subscribe((p) => {
        if (p.data) {
          this.sections = Object.entries(p.data as ProjectApiResponse).map(([sectionTitle, projects]) => {
            return {
              title: sectionTitle,
              projects: projects
            }
          })

          let i = this.sections.findIndex(
            (item) => item.title === "Archived"
          );

          if (i > -1) {
            this.archived = this.sections[i];
            this.sections.splice(i, 1);
          }
        }
      });
  }

  getRegistrationClosedTooltip(p: Project) {
    if (p.signUpErrorCode == 2101 || p.signUpErrorCode == 2103) {
      return this.errorRegistrationNA;
    }

    if (
      (p.signUpErrorCode > 2103 && p.signUpErrorCode < 2107) ||
      p.signUpErrorCode == 2110
    ) {
      return this.errorOrganisationAccess;
    }

    if (p.signUpErrorCode == 2107 || p.signUpErrorCode == 2108) {
      return this.errorUserAccess;
    }

    return null;
  }

  getDataCollectionClosedTooltip(p: Project) {
    if (p.accessProjectErrorCode == 2102) {
      return this.errorDataCollectionNA;
    }

    if (p.accessProjectErrorCode > 2103 && p.accessProjectErrorCode < 2107) {
      return this.errorOrganisationAccess;
    }

    if (p.accessProjectErrorCode == 2107 || p.accessProjectErrorCode == 2109) {
      return this.errorUserAccess;
    }

    return null;
  }

  setHideShow() {
    this.hideShow = !this.hideShow;
  }

  navProjectDetail(projectId: number) {
    this.router.navigate(["/project-detail", projectId]);
  }

  navProjectOverview(projectId: number) {
    this.router.navigate(["/project-overview", projectId]);
  }

  navDashboard(projectId: number) {
    if (projectId == 39) {
      this.router.navigate(["/ics"]);
    } else {
      this.router.navigate(["/dashboard", projectId]);
    }
  }

  navProjectRegistration(projectId: number, organisationId: number) {
    this.router.navigate(["/project-registration", projectId, organisationId]);
  }
}

