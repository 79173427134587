<!-- <div class="loading-spinner loading-above loading-overview">
  <i class="fa fa-spinner fa-spin"></i>
  <p>The data collection page is loading. Please wait...</p> 
</div> -->

<!-- SELECTOR BAR-->
<div id="selectorBar">
  <div class="container-fluid">
    <div class="form-vertical">
      <div class="row">
        <div class="col-md-6 col-sm-4 hidden-xs">
          <span class="btn-back" (click)="navForum()">
            <i class="fa fa-caret-left"></i> Back to Forum
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" id="containerRequest">

  <!-- CONTENT -->
  <div class="col-lg-6 col-lg-offset-3 col-md-10 col-md-offset-1 col-sm-12">

    <div class="loading-overview" *ngIf="!request">
      <p><i class="fas fa-spinner fa-spin"></i> Loading request. Please wait...</p>
    </div>

    <div *ngIf="request">
      <div class="request -master">
        <div class="request-header">
          <h3>{{ request.requestTitle }}</h3>
          <p class="request-meta">
            <span *ngIf="request.projectName">{{ request.projectName }} &middot; </span>{{ request.requestDate | date: 'd MMMM y' }}
          </p>
        </div>
        <div innerHTML="{{ request.requestBody}}" class="request-body"></div>
      </div>

      <div id="response">
        <button class="btn btn-primary btn-lg" (click)="setResponse(true)" *ngIf="!response">Submit a Response</button>
        <div *ngIf="response">
          <div *ngIf="parentId" class="parent-container">
            <p class="parent-intro">
              You are replying to the following comment from {{ parentComment.commentorFullName }}:
            </p>
            <p class="parent-body">
              {{ parentComment.commentBody }}
            </p>
          </div>
          <form [formGroup]="responseForm">
            <div class="alert alert-success" *ngIf="currentUserNotifications === 'N'" (click)="notificationsOptIn()">
              <h4 class="alert-heading">
                <i class="fas fa-bell"></i> Email Preferences
              </h4>
              <p>
                You are not currently opted-in to receive email notifications. Click here to opt-in and receive a daily digest of notifications, including updates on Forum requests that you have initiated or commented on.
              </p>
            </div>
            <div class="form-group">
              <textarea rows="5" class="form-control" formControlName="response" placeholder="Type your response here..."></textarea>
            </div>
            <div class="form-actions">
              <button type="button" class="btn btn-success" (click)="submitResponseForm(requestId, parentId)" [disabled]="!responseForm.value.response">Submit Response</button>
              <button type="button" class="btn btn-default" (click)="setResponse(false)">Cancel</button>
            </div>
            <div class="submit-warning text-muted">
              Please note that when you click 'Submit Response', your comment will be displayed publicly below, along with your name and email address.
            </div>
          </form>
        </div>
      </div>

      <div *ngIf="!comments?.length">
        <p class="no-comments text-muted">
          There are no responses to this request. Click 'Submit a Response' above to be the first.
        </p>
      </div>

      <div *ngIf="comments?.length > 0">
        <p class="comments text-muted">
            {{ comments.length }}<span>{{' public response' | makePluralString: comments.length}}</span>
        </p> 

        <div *ngFor="let comment of comments | orderBy: '-'">
          <div [class]="comment.commentorLogonName == currentUserLogonName ? 'request -comment -my-comment' : 'request -comment'">
            <div class="request-body" innerHTML="{{ comment.commentBody}}"></div>
            <div class="row meta-footer">
              <div class="col-xs-6">
                <div>
                  <span *ngIf="comment.commentorLogonName != currentUserLogonName"><a href="mailto:{{ comment.commentorLogonName }}">{{ comment.commentorFullName }}</a> &middot; </span>
                  <span *ngIf="comment.commentorLogonName == currentUserLogonName"><strong>{{ comment.commentorFullName }} (me)</strong> &middot; </span>
                  {{ comment.commentDate | date: 'd MMMM y' }}
                </div>
              </div>

              <div class="col-xs-6 actions">
                <span (click)="replyToResponse(comment.kenCommentId)" class="btn-action btn-reply">
                  Reply
                </span>

                <span *ngIf="comment.commentorLogonName != currentUserLogonName" (click)="reportResponse(comment.kenCommentId)" class="btn-action btn-report">
                  <i class="far fa-flag fa-sm"></i>Report
                </span>

                <span *ngIf="comment.commentorLogonName == currentUserLogonName" (click)="hideResponse(comment.kenCommentId)" class="btn-action btn-remove">
                  <i class="far fa-ban fa-sm"></i>Remove
                </span>
              </div>
            </div>

            <div *ngFor="let child of comment.children">
              <div [class]="child.commentorLogonName == currentUserLogonName ? 'request -comment -my-comment' : 'request -comment'">
                <div class="request-body" innerHTML="{{ child.commentBody}}"></div>
                <div class="row meta-footer">
                  <div class="col-xs-6">
                    <div>
                      <span *ngIf="child.commentorLogonName != currentUserLogonName"><a href="mailto:{{ child.commentorLogonName }}">{{ child.commentorFullName }}</a> &middot; </span>
                      <span *ngIf="child.commentorLogonName == currentUserLogonName"><strong>{{ child.commentorFullName }} (me)</strong> &middot; </span>
                      {{ child.commentDate | date: 'd MMMM y' }}
                    </div>
                  </div>

                  <div class="col-xs-6 actions">
                    <span *ngIf="child.commentorLogonName != currentUserLogonName" (click)="reportResponse(child.kenCommentId)" class="btn-action btn-report">
                      <i class="far fa-flag fa-sm"></i>Report
                    </span>

                    <span *ngIf="child.commentorLogonName == currentUserLogonName"> &middot; 
                      <span (click)="hideResponse(child.kenCommentId)" class="btn-action btn-remove">
                        <i class="far fa-ban fa-sm"></i>Remove
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- end *ngIf request -->

  </div> <!-- end row -->
</div> <!-- end container -->