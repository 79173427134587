<!-- BACKGROUND CLOSE -->
<div class="menu-background" *ngIf="tierOpen || (searchText && searchText.length > 1) || dynamicDocumentsDropdown" (click)="closeMenu()"></div>

<!-- SELECTOR -->
<div class="container-fluid no-print" *ngIf="searchableTiers">
    <div class="row" id="selectorBar">
        <div class="col-sm-4 col-xs-4">
            <div class="navigation">
                <div>
                    <button *ngIf="projectView === 'dashboard'" class="button" (click)="navBack()">
                        <i class="fas fa-caret-left"></i>Back   
                    </button>
                    <button *ngIf="projectView !== 'dashboard'" class="button" (click)="changeView('dashboard')">
                        <i class="fas fa-caret-left"></i>Dashboard   
                    </button>
                </div>
                <div class="project-name">
                    {{ projectDetails?.projectName }}
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-xs-8 text-right">
            <button class="button" (click)="selectedTiers[8].open ? selectedTiers[8].open = false : toggleTier(8)" [disabled]="organisations < 2">
                {{ selectedOrganisation ? selectedOrganisation.organisationName : 'Select organisation...' }}
            </button>
            <div class="menu-container">
                <div class="menu-select" *ngIf="selectedTiers[8].open">
                    <div *ngFor="let o of organisations" 
                        [class]="selectedOrganisation && selectedOrganisation.organisationId === o.organisationId ? 'tier -selected' : 'tier'"
                        (click)="getSubmissions(projectId, o); selectedTiers[8].open = !selectedTiers[8].open; tierOpen = false">
                            {{ o.organisationName }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-xs-12">
            <div class="search-toggle">
                <input type="text" [(ngModel)]="searchText" class="search-input" placeholder="Search all {{ searchableTiers.length }} metrics...">
            </div>
            <div class="menu-container">
                <div class="menu-select" *ngIf="searchText && searchText.length > 1">
                    <div *ngFor="let t of searchableTiers | filterBy: ['aName', 'bName', 'cName', 'dName', 'reportId']: searchText" class="tier" (click)="selectSearchTier(t); searchText = null">
                        <div *ngIf="t.eName">
                            {{ t.cName }} ({{ t.dName }} - {{ t.eName }})
                            <div class="route">
                                {{ t.aName }}
                                <i class="fas fa-caret-right"></i>
                                {{ t.bName }}
                            </div>  
                        </div>
                        <div *ngIf="!t.eName && t.dName">
                            {{ t.cName }} ({{ t.dName }})
                            <div class="route">
                                {{ t.aName }}
                                <i class="fas fa-caret-right"></i>
                                {{ t.bName }}
                            </div>  
                        </div>
                        <div *ngIf="!t.eName && !t.dName && t.cName">
                            {{ t.cName }}
                            <div class="route">
                                {{ t.aName }}
                                <i class="fas fa-caret-right"></i>
                                {{ t.bName }}
                            </div>  
                        </div>
                        <div *ngIf="!t.eName && !t.dName && !t.cName">
                            {{ t.bName }}
                            <div class="route">
                                {{ t.aName }}
                            </div>
                        </div>
                    </div>
                    <div class="no-results" *ngIf="searchableTiers && (searchableTiers | filterBy: ['aName', 'bName', 'cName', 'dName', 'reportId']: searchText).length === 0">
                        There are no results for this search
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Loading -->
<div class="loading-data" *ngIf="!searchableTiers && !projectDetails && !error && !promo">
    <p><i class="far fa-spinner fa-spin"></i> Loading Dashboard. Please wait...</p>
</div>

<!-- Loading Error -->
<div class="container-fluid" *ngIf="error && !promo">
    <div class="row">
        <div class="col-md-8 col-md-offset-2">
            <div class="loading-data error">
                <p>There has been an error loading the Dashboard</p>
                <p>{{ error }} If the problem persists, please contact the support team.</p>
            </div>
        </div>
    </div>
</div>

<!-- Loading Error -->
<div class="container-fluid" *ngIf="promo">
    <div class="row">
        <div class="col-md-8 col-md-offset-2">
            <div class="loading-data error">
                <p>Participation Required</p>
                <p>Thank you for your interest in the Community Indicators project. In order to view the outputs, your organisation must first register for, and take part in, the project.</p>
                <p><a [routerLink]="['/home']">Click here to return to the home page and register for the project.</a></p>
            </div>
        </div>
    </div>
</div>

<!-- DASHBOARD -->
<div class="container-fluid" id="dashboard" *ngIf="projectView === 'dashboard' && searchableTiers && !error && projectDetails">
    <div class="row background">
        <div class="col-md-8 col-md-offset-2">
            <div id="intro">
                <h1>Outputs</h1>
                <h2>{{ projectName }}</h2>
                <div *ngIf="projectDetails.projectOutputsIntro" [innerHTML]="projectDetails.projectOutputsIntro"></div>
            </div>
            <!-- Open full toolkit -->
            <div class="row mt-2">
                <div class="col-sm-12" [style.display]="(activeAdmin && projectId == 52) ? 'flex' : '' ">
                    <div class="open-full-toolkit mr-2" (click)="changeView('toolkit')">
                        <i class="fas fa-chart-bar mr-2"></i><strong>Click here to view the full toolkit</strong>, or click on a dashboard metric below to enter toolkit view and see all the data behind the selected metric.
                    </div>
                    <div *ngIf="projectId == 52">
                        <div class="start-mass-download" (click)="selectReportModal.show(); massDownloadTiers(true, false); showReportModal ? showReportModal = false : showReportModal = true">
                            <i class="far fa-file-download mr-2"></i><strong>Mass Download</strong>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Dates, submission, dashboard type and options drop-downs -->
            <div class="row" id="ribbonBar">
                <div class="col-sm-3 col-xs-12" *ngIf="projectDates">
                    <h5>Select period</h5>
                    <div [class]="selectedProjectDate ? 'tier-toggle' : 'tier-toggle -select'" (click)="selectedTiers[9].open ? selectedTiers[9].open = false : toggleTier(9)">
                        <div>{{ selectedProjectDate.dateName }}</div>
                        <i [class]="selectedTiers[9].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                    </div>
                    <div class="menu-container">
                        <div class="menu-select" *ngIf="selectedTiers[9].open">
                            <div *ngFor="let date of projectDates" [class]="date.dateId === selectedProjectDate.dateId ? 'tier -selected' : 'tier'" (click)="selectProjectDate(date); selectedTiers[9].open = !selectedTiers[9].open; tierOpen = false">
                                <div [class]="date.activeAdmin ? 'highlight-admin' : ''">{{ date.dateName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-xs-12" *ngIf="submissions">
                    <h5>Select submission</h5>
                    <div [class]="selectedSubmission ? 'tier-toggle' : 'tier-toggle -select'" (click)="selectedTiers[10].open ? selectedTiers[10].open = false : toggleTier(10)">
                        <div>{{ selectedSubmission.submissionCode}}: {{ selectedSubmission.submissionName }}</div>
                        <i [class]="selectedTiers[10].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                    </div>
                    <div class="menu-container">
                        <div class="menu-select" *ngIf="selectedTiers[10].open">
                            <div *ngFor="let sub of submissions" [class]="sub.submissionId === selectedSubmission.submissionId ? 'tier -selected' : 'tier'" (click)="selectSubmission(sub); selectedTiers[10].open = !selectedTiers[10].open; tierOpen = false">
                                {{ sub.submissionCode }}: {{ sub.submissionName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-xs-12" *ngIf="reportGroups && reportGroups.length > 1">
                    <h5>Select dashboard type</h5>
                    <div [class]="selectedReportGroup ? 'tier-toggle' : 'tier-toggle -select'" (click)="selectedTiers[15].open ? selectedTiers[15].open = false : toggleTier(15)">
                        <div>{{ selectedReportGroup.groupDisplayName }}</div>
                        <i [class]="selectedTiers[15].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                    </div>
                    <div class="menu-container">
                        <div class="menu-select" *ngIf="selectedTiers[15].open">
                            <div *ngFor="let group of reportGroups" [class]="group.reportGroupId === selectedReportGroup.reportGroupId ? 'tier -selected' : 'tier'" (click)="selectReportGroup(group); selectedTiers[15].open = !selectedTiers[15].open; tierOpen = false">
                                {{ group.groupDisplayName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-xs-12" *ngIf="dynamicDocuments != undefined && dynamicDocuments.length > 0 && (projectId == 52 || projectId == 42)">
                    <h5>Report Download</h5>
                    <div [class]="dynamicDocuments ? 'tier-toggle' : 'tier-toggle -select'" (click)="dynamicDocumentsDropdown = true">
                        <div>View reports...</div>
                        <i [class]="dynamicDocumentsDropdown ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                    </div>
                    <div class="menu-container">
                        <div class="menu-select" *ngIf="dynamicDocumentsDropdown">
                            <div *ngFor="let document of dynamicDocuments" class="tier" 
                                (click)="getSelectedDocument(document.documentId); dynamicDocumentsDropdown = false">
                                <i class="far fa-file-download mr-2"></i>{{ document.documentName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-xs-12" *ngIf="projectDates">
                    <h5>More options</h5>
                    <div [class]="selectedReportGroup ? 'tier-toggle' : 'tier-toggle -select'" (click)="selectedTiers[14].open ? selectedTiers[14].open = false : toggleTier(14)">
                        <div>View options...</div>
                        <i [class]="selectedTiers[14].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                    </div>
                    <div class="menu-container">
                        <div class="menu-select" *ngIf="selectedTiers[14].open">
                            <!-- Full screen + submission toggle (SE MH) -->
                            <ng-container *ngIf="projectId == 52">
                                <div class="tier" (click)="projectView = 'fullscreen'; selectedTiers[14].open = !selectedTiers[14].open; tierOpen = false">
                                    <i class="fas fa-fw fa-expand-alt mr-2"></i>View full screen
                                </div>
                                <div class="tier" (click)="showSubmission = !showSubmission; selectedTiers[14].open = !selectedTiers[14].open; tierOpen = false">
                                    <i class="far fa-fw fa-building mr-2"></i>{{ showSubmission ? 'Hide' : 'Show' }} submission values
                                </div>
                            </ng-container>
                            <!-- Report View -->
                            <ng-container *ngIf="projectDetails.enablePdfReport === 'Y' && selectedProjectDate.currentFY">
                                <div class="tier" (click)="changeView('report'); selectedTiers[14].open = !selectedTiers[14].open; tierOpen = false">
                                    <i class="far fa-fw fa-file-alt mr-2"></i>View <ng-container *ngIf="!submissions">Example </ng-container>Report
                                </div>
                            </ng-container>
                            <!-- Key Statistics (CI only) -->
                            <ng-container *ngIf="projectId == 29">
                                <div class="tier" [routerLink]="['/outputs', 29]" (click)="selectedTiers[14].open = !selectedTiers[14].open; tierOpen = false">
                                    <i class="fas fa-fw fa-chart-bar mr-2"></i>Key Statistics
                                </div>
                                <div class="tier">
                                    <i class="far fa-fw fa-file-csv mr-2"></i>
                                    <angular2csv [data]="metrics" filename="ci_extract" [options]="extractOptions"></angular2csv>
                                </div>
                            </ng-container>
                            <!-- Colourblindness correction -->
                            <div class="tier" (click)="enableColorBlindness(); selectedTiers[14].open = !selectedTiers[14].open; tierOpen = false">
                                <i [class]="colorBlindnessEnabled ? 'fas fa-fw fa-check-circle mr-2' : 'fas fa-fw fa-low-vision mr-2'"></i>Colour Blindness Correction
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="metrics">

                <!-- Loading metrics-->
                <div class="loading-report" *ngIf="metricsLoading && !errorMetrics">
                    <i class="far fa-spinner fa-spin"></i> Loading metrics...
                </div>

                <!-- Metrics Error -->
                <div class="error-report" *ngIf="errorMetrics">
                    {{ errorMetrics }}
                </div>

                <!-- Metrics list -->
                <div id="metrics" *ngIf="!errorMetrics">
                    <div *ngFor="let group of metrics | groupBy: 'aName' | pairs" class="group">
                        <div class="row group-title" (click)="toggleGroup(group)">
                            <div class="col-xs-10">
                              <h3>{{ group[0] }}</h3>
                            </div>
                            <div class="col-xs-2 text-right">
                              <h3>
                                <i [class]="group[1][0].visible ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
                              </h3>
                            </div>
                        </div>
                        <div *ngFor="let metric of group[1]">
                            <app-block *ngIf="metric.visible"
                                [colorBlindnessEnabled]="colorBlindnessEnabled"
                                [metric]="metric"
                                [projectId]="projectId"
                                [showSubmission]="showSubmission"
                                [projectView]="projectView"
                                (selectMetric)="selectSearchTier($event)">
                            </app-block>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- FULL SCREEN -->
<div class="container-fluid" *ngIf="projectView == 'fullscreen'">
    <div class="row mt-2">
        <div class="col-sm-12 text-right c-pointer">
            <span class="text-muted mr-4" (click)="downloadImage()">
                <i class="fas fa-download mr-2"></i>Download (.png)
            </span>
            <span class="text-muted" (click)="projectView = 'dashboard'">
                <i class="fas fa-times mr-2"></i>Close full screen
            </span>
        </div>
    </div>
    <div class="row" #fullscreen>
        <ng-container *ngFor="let metric of metrics">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <app-block *ngIf="metric.visible"
                    [colorBlindnessEnabled]="colorBlindnessEnabled"
                    [metric]="metric"
                    [projectId]="projectId"
                    [showSubmission]="showSubmission"
                    [projectView]="projectView"
                    (selectMetric)="selectSearchTier($event)">
                </app-block>
            </div>
        </ng-container>
    </div>

    <div id="download">
        <img #canvas>
        <a #downloadLink></a>
    </div>
</div>

<!-- TOOLKIT DOWNLOAD-->
<div *ngIf="projectView === 'toolkit' && !error && massDownloadView">
    <div class="row mt-2">
        <div class="col-sm-12 text-right c-pointer">
            <span class="text-muted mr-4" [routerLink]="['/project/52/dashboard']">
                <i class="far fa-window-close mr-2"></i>Close Download
            </span>
        </div>
    </div>
    <div #reportDownloadView>
    <!-- RIBBON -->
   

    <!-- WELCOME -->
    <div class="container-fluid" *ngIf="projectName && searchableTiers && !reportDetails && !error && !reportLoading">
        <div class="row">
            <div class="col-md-12">
                <h3>Mass Report Download has started...</h3>
                <p>
                    The selected reports will begin to download any moment.
                </p>
            </div>
        </div>
    </div>

    <!-- REPORT -->
    <div class="container-fluid" id="toolkit">

        <!-- Loading (report) -->
        <div class="loading-report" *ngIf="reportLoading && !errorReport">
            <i class="far fa-spinner fa-spin"></i> Loading report...
        </div>

        <!-- Loading (Colour Blind report) -->
        <div class="loading-report" *ngIf="colourCorrectReportLoading && !errorReport">
            <i class="far fa-spinner fa-spin"></i> Applying Colour Blind Correction...
        </div>

        <!-- Loading (new report) -->
        <div class="loading-report" *ngIf="selectingTier" (click)="reloadReport(reportDetails.reportId)">
            You are making another selection. Click here to return to the loaded report.
        </div>

        <!-- Report Error -->
        <div class="error-report" *ngIf="errorReport">
            {{ errorReport }}
        </div>

        <!-- TOOLKIT REPORT -->
        <div *ngIf="reportDetails && primaryChartOptions && !errorReport">

            <!-- Title -->
            <div class="row">
                <div class="col-sm-8 heading-title">
                    <h2 class="report-title">
                        {{ reportDetails.reportName }}
                        <span *ngIf="reportCategoryTypes && reportType === 'stacked'">by {{ selectedCategoryType }}</span>
                        <span *ngIf="reportDetails.postponed" class="badge badge-pill badge-secondary">POSTPONED</span>
                    </h2>
                    <h3 style="margin-top:0.25em">{{ selectedProjectDate.dateName }}</h3>
                </div>
            </div>

            <!-- Charts -->
            <div class="row">
                <div class="col-sm-6">
                    <div class="chart" [ng2-highcharts]="primaryChartOptions"></div>
                    <div class="row" style="display: flex; justify-content: space-between;">
                        <div class="p-2">
                            <i class="fas fa-square" [style.color]="nationalMeanColour"></i>
                            <b class="ml-2 mr-2">Mean:</b><span>{{ averagesTable[0].y | number: reportSettings.maxDecPlaces }}</span> <span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                        </div>
                        <div class="p-2">
                            <i class="fas fa-square" [style.color]="nationalMedianColour"></i>
                            <b class="ml-2 mr-2">Median:</b><span>{{ averagesTable[0].median | number: reportSettings.maxDecPlaces }}</span><span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                        </div>                        
                        <div class="p-2" *ngIf="calculatedNationalMeansAvailable">
                            <i class="fas fa-square" [style.color]="'#768692'"></i>
                            <b class="ml-2 mr-2">{{ projectId == 52 ? 'Regional' : 'Sample' }} Average:</b><span>{{ averagesTable[0].nationalCalculatedMean | number: reportSettings.maxDecPlaces }}</span><span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>    
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="chart" style="margin-top: 2em;" [ng2-highcharts]="secondaryChartOptions"></div>
                </div>
                 <!-- Tables -->
                <div class="col-sm-12" style="margin-top: 2em;" *ngIf="tableData">
                    <div [class]="timeseriesAvailable ? 'col-sm-12' : 'col-sm-12'">
                    <div class="col-sm-6">
                    
                    <!-- Month Data -->
                    <h4>Data for {{ selectedProjectDate.dateName }}:</h4>

                    <table class="col-6 table table-bordered" *ngIf="reportType === 'bar'">
                        <thead>
                            <tr>
                                <th style="width:30%">Submission</th>
                                <th>Result<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of tableData" [style.background-color]="d.color ? '#F3F3F3' : '#FFFFFF'">
                                <td>
                                    <i class="fas fa-square" [style.color]="d.color ? d.color : '#F3F3F3'"></i>&nbsp;{{ d.name }}
                                </td>
                                <td>
                                    {{ d.y | number: reportSettings.maxDecPlaces }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-sm-6">

                    <!-- Numerator / Denominator Values -->
                    <ng-container class="col-6" *ngIf="projectId == 52">
                        <h4 *ngIf="tableData[0].denominatorValue || tableData[0].numeratorValue">Numerator / Denominator Values:</h4>
                        <table class="table table-bordered" *ngIf="reportType === 'bar'">
                            <thead>
                                <tr>
                                    <th style="width:30%">Submission</th>
                                    <th *ngIf="tableData[0].numeratorValue">Numerator</th>
                                    <th *ngIf="tableData[0].denominatorValue">Denominator</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let d of tableData" [style.background-color]="d.color ? '#F3F3F3' : '#FFFFFF'">
                                    <td>
                                        <i class="fas fa-square" [style.color]="d.color ? d.color : '#F3F3F3'"></i>&nbsp;{{ d.name }}
                                    </td>
                                    <td *ngIf="d.numeratorValue">
                                        {{ d.numeratorValue | number: reportSettings.maxDecPlaces }}
                                    </td>
                                    <td *ngIf="d.denominatorValue">
                                        {{ d.denominatorValue | number: reportSettings.maxDecPlaces }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </div>

                    <table class="table table-bordered" *ngIf="reportType === 'stacked'">
                        <thead>
                            <tr>
                                <th style="width:30%">Submission</th>
                                <ng-container *ngFor="let col of tableHeader">
                                    <th>
                                        {{ col }}<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of tableData" [style.background-color]="d.color ? '#F3F3F3' : '#FFFFFF'">
                                <td><i class="fas fa-square" [style.color]="d.color ? d.color : '#F3F3F3'"></i>&nbsp;{{ d.submission }}</td>
                                <ng-container *ngFor="let col of d.responses">
                                    <td *ngIf="!col.y">0</td>
                                    <td *ngIf="col.y">{{ col.y | number: reportSettings.maxDecPlaces }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table table-bordered" *ngIf="reportType === 'pie'">
                        <thead>
                            <tr>
                                <th style="width:30%">Submission</th>
                                <ng-container *ngFor="let col of reportCategories">
                                    <th *ngIf="col.categoryName">
                                        {{ col.categoryName }}<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of tableData" [style.background-color]="d.color ? '#F3F3F3' : '#FFFFFF'">
                                <td><i class="fas fa-square" [style.color]="d.color ? d.color : '#F3F3F3'"></i>&nbsp;{{ d.submission }}</td>
                                <ng-container *ngFor="let col of d.data">
                                    <td *ngIf="!col.y">0</td>
                                    <td *ngIf="col.y">{{ col.y | number: reportSettings.maxDecPlaces }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="col-sm-12" *ngIf="(reportType == 'stacked' || reportType == 'pie') && submissionName" [hidden]="hideTable">
                    <div *ngFor="let d of openSubmissionTables">
                        <h4>{{ d.name }} Values:</h4>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <ng-container *ngFor="let col of categoryAverages[0].data">
                                            <th *ngIf="!col.name">-</th>
                                            <th *ngIf="col.name">{{ col.name }}<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span></th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let group of d | groupBy: 'category' | pairs">
                                        <tr *ngFor="let av of group[1]">
                                            <td>{{ av.name }}</td>
                                            <ng-container *ngFor="let col of av.data">
                                                <td>{{ col.y | number: reportSettings.maxDecPlaces }}</td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
        
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <!-- Notification -->
            <div *ngIf="reportDetails.reportNotifications.length" class="row">
                <div class="col-sm-12">
                    <div *ngFor="let notification of reportDetails.reportNotifications" [class]="'notification -notification-' + notification.notificationType.toLowerCase()">
                        <strong>{{ notification.notificationTitle }}:</strong> <span [innerHTML]="notification.notificationText"></span>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </div>
    <div id="download">
        <img #reportCanvas>
        <a #downloadReportLink></a>
    </div>
</div>

<!-- TOOLKIT -->
<div *ngIf="projectView === 'toolkit' && !error && !massDownloadView">

    <!-- RIBBON -->
    <div class="container-fluid" *ngIf="searchableTiers">
        <div class="row" id="ribbonBar">
            <!-- NEW Tiers -->
            <div class="col-sm-5 col-xs-6" *ngIf="tiers" id="allTiers">
                <h5>Select a metric</h5>
                <div [class]="selectedTierName ? 'tier-toggle' : 'tier-toggle -select'" (click)="selectedTiers[12].open ? selectedTiers[12].open = false : toggleTier(12)">
                    <div>{{ selectedTierName ? selectedTierName : 'Select a metric...' }}</div>
                    <i [class]="selectedTiers[12].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </div>
                <div class="menu-container">
                    <div class="menu-select" *ngIf="selectedTiers[12].open">
                        <div id="tierSystem">
                            <div *ngFor="let t1 of tiers" class="new-tier">
                                <!-- Tier 1-->
                                <div *ngIf="t1.tierTypeId !== 3">
                                    <span *ngIf="t1.isVisible !== 'N'" (click)="newSelectTier(t1, null, null, null); t1.childrenOpen = !t1.childrenOpen" [style.font-weight]="t1.selected ? 'bold' : 'normal'" [style.color]="reportDetails && t1.reportId === reportDetails.reportId ? '#005EB8' : 'black'">
                                        <ng-container *ngIf="t1.tierTypeId === 1">
                                            <i [class]="t1.childrenOpen ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                        </ng-container>
                                        {{ t1.tierName}}
                                    </span>
                                </div>
                                <div *ngIf="t1.childrenOpen">
                                    <div style="margin-left:1em" class="new-tier" *ngFor="let t2 of t1.childrenTiers">
                                        <!-- Tier 2 -->
                                        <div *ngIf="t2.tierTypeId !== 3">
                                            <span *ngIf="t2.isVisible !== 'N'" (click)="newSelectTier(t1, t2, null, null); t2.childrenOpen = !t2.childrenOpen" [style.font-weight]="t2.selected ? 'bold' : 'normal'" [style.color]="reportDetails && t2.reportId === reportDetails.reportId ? '#005EB8' : 'black'">
                                                <ng-container *ngIf="t2.tierTypeId === 1">
                                                    <i [class]="t2.childrenOpen ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                </ng-container>
                                                {{ t2.tierName}}
                                            </span>
                                        </div>
                                        <div *ngIf="t2.childrenOpen">
                                            <div style="margin-left:2em" class="new-tier" *ngFor="let t3 of t2.childrenTiers">
                                                <!-- Tier 3 -->
                                                <div *ngIf="t3.tierTypeId !== 3">
                                                    <span *ngIf="t3.isVisible !== 'N'" (click)="newSelectTier(t1, t2, t3, null); t3.childrenOpen = !t3.childrenOpen" [style.font-weight]="t3.selected ? 'bold' : 'normal'" [style.color]="reportDetails && t3.reportId === reportDetails.reportId ? '#005EB8' : 'black'">
                                                        <ng-container *ngIf="t3.tierTypeId === 1">
                                                            <i [class]="t3.childrenOpen ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                        </ng-container>
                                                        {{ t3.tierName}}
                                                    </span>
                                                </div>
                                                <div *ngIf="t3.childrenOpen">
                                                    <div style="margin-left:3em" class="new-tier" *ngFor="let t4 of t3.childrenTiers">
                                                        <!-- Tier 4 -->
                                                        <div *ngIf="t4.tierTypeId !== 3">
                                                            <span (click)="newSelectTier(t1, t2, t3, t4); t4.childrenOpen = !t4.childrenOpen" [style.font-weight]="t4.selected ? 'bold' : 'normal'" [style.color]="reportDetails && t4.reportId === reportDetails.reportId ? '#005EB8' : 'black'">
                                                                <ng-container *ngIf="!t4.reportId">
                                                                    <i [class]="t4.childrenOpen ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                                </ng-container>
                                                                {{ t4.tierName}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Dates -->
            <div class="col-sm-2 col-xs-6" *ngIf="reportDetails && reportDetails?.dates">
                <h5>Select period</h5>
                <div [class]="selectedProjectDate ? 'tier-toggle' : 'tier-toggle -select'" (click)="selectedTiers[6].open ? selectedTiers[6].open = false : toggleTier(6)">
                    <div>{{ selectedProjectDate.dateName }}</div>
                    <i [class]="selectedTiers[6].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </div>
                <div class="menu-container">
                    <div class="menu-select" *ngIf="selectedTiers[6].open">
                        <ng-container *ngFor="let date of projectDates">
                            <div *ngIf="!date.hidden" [class]="date.dateId === selectedProjectDate.dateId ? 'tier -selected' : 'tier'" (click)="selectDate(date); selectedTiers[6].open = !selectedTiers[6].open; tierOpen = false">
                                <div [class]="date.activeAdmin ? 'highlight-admin' : ''">{{ date.dateName }}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- Fourth Tier ('Services')  -->
            <div class="col-sm-3 col-xs-6" *ngIf="options">
                <h5>Select service</h5>
                <div [class]="selectedTiers[13].tierName ? 'tier-toggle' : 'tier-toggle -select'" 
                    (click)="selectedTiers[13].open ? selectedTiers[13].open = false : toggleTier(13)">
                    <div>{{ selectedOption ? selectedOption : 'Make a selection...' }}</div>
                    <i [class]="selectedTiers[3].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </div>
                <div class="menu-container">
                    <div class="menu-select" *ngIf="selectedTiers[13].open">
                        <div *ngFor="let o of options" 
                            [class]="selectedOption === o.tierName ? 'tier -selected' : 'tier'"
                            (click)="selectOption(o)">
                                {{ o.tierName }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Category Types -->
            <div class="col-sm-2 col-xs-6" *ngIf="reportCategoryTypes && reportType === 'stacked'">
                <h5>Select category type</h5>
                <div [class]="selectedCategoryType ? 'tier-toggle' : 'tier-toggle -select'" (click)="selectedTiers[7].open ? selectedTiers[7].open = false : toggleTier(7)">
                    <div>{{ selectedCategoryType }}</div>
                    <i [class]="selectedTiers[7].open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </div>
                <div class="menu-container">
                    <div class="menu-select" *ngIf="selectedTiers[7].open">
                        <div *ngFor="let ct of reportCategoryTypes" [class]="selectedCategoryType === ct ? 'tier -selected' : 'tier'" (click)="selectCategoryType(ct); selectedTiers[7].open = !selectedTiers[7].open; tierOpen = false">
                            {{ ct }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- WELCOME -->
    <div class="container-fluid welcome-message" *ngIf="projectName && searchableTiers && !reportDetails && !error && !reportLoading">
        <div class="row">
            <div class="col-md-5 col-sm-12">
                <h3>Welcome to the {{ projectName }}</h3>
                <p>
                    Welcome to the full toolkit for the {{ projectName }}. This toolkit holds all the benchmarked results for the project. To navigate through the findings, please use the drop-down box above to select a metric, then month and service options will appear.
                </p>
                <p>
                    For metrics where your organisation has submitted data, your position will be highlighted on the chart.
                </p>
                <p *ngIf="projectId !== 52">
                    If you have any queries, please contact 
                    <a *ngIf="projectId === 29 || projectId === 41" href="mailto:k.biggs2@nhs.net">Kaitlyn Biggs</a>
                    <a *ngIf="projectId === 42" href="mailto:j.donnelly3@nhs.net">Joseph Donnelly</a> 
                    or 
                    <a *ngIf="projectId === 42" href="mailto:g.marsh4@nhs.net">George Marsh</a>
                    <a *ngIf="projectId === 29" href="mailto:n.mistry5@nhs.net">Nayan Mistry</a>
                    <a *ngIf="projectId != 29 && projectId != 41 && projectId != 42" href="mailto:chris.mcauley@nhs.net">Chris McAuley</a>.
                </p>
            </div>
        </div>
    </div>

    <!-- REPORT -->
    <div class="container-fluid" id="toolkit">

        <!-- Loading (report) -->
        <div class="loading-report" *ngIf="reportLoading && !errorReport">
            <i class="far fa-spinner fa-spin"></i> Loading report...
        </div>

        <!-- Loading (Colour Blind report) -->
        <div class="loading-report" *ngIf="colourCorrectReportLoading && !errorReport">
            <i class="far fa-spinner fa-spin"></i> Applying Colour Blind Correction...
        </div>

        <!-- Loading (new report) -->
        <div class="loading-report" *ngIf="selectingTier" (click)="reloadReport(reportDetails.reportId)">
            You are making another selection. Click here to return to the loaded report.
        </div>

        <!-- Report Error -->
        <div class="error-report" *ngIf="errorReport">
            {{ errorReport }}
        </div>

        <!-- TOOLKIT REPORT -->
        <div *ngIf="reportDetails && primaryChartOptions && !errorReport">

            <!-- Title -->
            <div class="row">
                <div class="col-sm-8 heading-title">
                    <h2 class="report-title">
                        {{ reportDetails.reportName }}
                        <span *ngIf="reportCategoryTypes && reportType === 'stacked'">by {{ selectedCategoryType }}</span>
                        <span *ngIf="reportDetails.postponed" class="badge badge-pill badge-secondary">POSTPONED</span>
                    </h2>
                    <h3 style="margin-top:0.25em">{{ selectedProjectDate.dateName }}</h3>
                </div>
            </div>

            <!-- Charts -->
            <div class="row">
                <div class="col-sm-6">
                    <div class="chart" [ng2-highcharts]="primaryChartOptions"></div>
                </div>
                <div class="col-sm-6">
                    <div class="chart" [ng2-highcharts]="secondaryChartOptions"></div>
                </div>
            </div>

            <!-- Legend -->
            <div class="row" id="legendBar">
                <div class="col-sm-12">
                    <div *ngIf="legend && legend.length" class="selectors">
                        <div *ngFor="let l of legend; let i = index" [class]="l.visible ? 'btn-legend -active' : 'btn-legend'" (click)="toggleSeriesVisibility(i)">
                            <i class="fa fa-square" [style.color]="l.visible ? l.color : gray"></i> {{ l.name }}
                        </div>
                    </div>
                        <h5 *ngIf="submissions && submissions.length > 0 && submissionVisible">Add submission to time series</h5>
                            <div class="selectors d-flex" *ngIf="submissions && submissions.length > 0 && submissionVisible">
                                
                                <div *ngFor="let sub of submissions" [class]="sub.visible ? 'btn-legend -active' : 'btn-legend'" (click)="sub.visible ? resetLineChart(sub) : addSubmissiontoLineChart(sub)">
                                    <i class="fas fa-square" [style.color]="sub.color"></i>&nbsp;
                                    {{ sub.submissionCode }}: {{ sub.submissionName }}
                                </div>
                            </div>
                            <div class="selectors" *ngIf="!legend || legend.length === 0 && !submissions">
                                <div *ngFor="let sub of submissions" class="btn-legend">
                                    <i class="fas fa-square" [style.color]="sub.color"></i>&nbsp;
                                    {{ sub.submissionCode }}: {{ sub.submissionName }}
                                </div>
                            </div>
                </div>
                <!-- OPTIONS -->
                <div class="options">
                    <!-- Download -->
                    <div class="tier-toggle" (click)="selectedTiers[11].open ? selectedTiers[11].open = false : toggleTier(11)">
                        <i class="fas fa-download"></i>Download
                    </div>
                    <div class="menu-container">
                        <div class="menu-select -dark" *ngIf="selectedTiers[11].open">
                            <div class="tier" (click)="export('main'); selectedTiers[11].open ? selectedTiers[11].open = false : toggleTier(4)">
                                <i class="fas fa-arrow-to-bottom"></i> Download Chart (.png)
                            </div>
                            <div class="tier" (click)="export('timeseries'); selectedTiers[11].open ? selectedTiers[11].open = false : toggleTier(11)">
                                <i class="fas fa-arrow-to-bottom"></i> Download Time Series Chart (.png)
                            </div>
                            <div class="tier" (click)="export('dataTable')">
                                <i class="fas fa-arrow-to-bottom"></i> Data for {{ selectedProjectDate.dateName }} Table (.csv)
                                <app-csv-download *ngIf="getTableData" [result]="tableData" [csvSettings]="csvSettings"></app-csv-download>
                            </div>
                            <div class="tier" (click)="export('averages')">
                                <i class="fas fa-arrow-to-bottom"></i> {{ projectId == 52 ? 'Regional' : 'Sample' }} Averages Table (.csv)
                                <app-csv-download *ngIf="getAverages" [result]="reportType === 'bar' ? averagesTable : categoryAverages" [csvSettings]="csvSettings"></app-csv-download>
                            </div>
                            <ng-container *ngIf="projectId == 52">
                                <div class="tier" *ngIf="tableData[0].denominatorValue || tableData[0].numeratorValue" (click)="export('denNumTable')">
                                    <i class="fas fa-arrow-to-bottom"></i> Numerator / Denominator Values Table (.csv)
                                    <app-csv-download *ngIf="getDenNumTable" [result]="tableData" [csvSettings]="csvSettings"></app-csv-download>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <!-- Info -->
                    <div class="tier-toggle">
                        <app-info-panel
                            [infoPanel]="infoPanel"
                            [onInfoPanelClick]="onInfoPanelClick">
                        </app-info-panel>
                    </div>

                    <!-- Colourblindness correction -->
                    <div class="tier-toggle" (click)="enableColorBlindness()">
                        <i [class]="colorBlindnessEnabled ? 'fas fa-check-circle' : 'fas fa-low-vision'"></i>Colour Blindness Correction
                    </div>
                    <!-- Anonymous -->
                    <div *ngIf="projectId === 52" class="tier-toggle" (click)="getReportData(reportId, selectedProjectDate, selectedOrganisation, anonymousEnabled ? false : true)">
                        <i [class]="anonymousEnabled ? 'fas fa-check-circle' : 'fas fa-low-vision'"></i>Anonymous View
                    </div>
                </div>
            </div>

            <!-- Notification -->
            <div *ngIf="reportDetails.reportNotifications.length" class="row">
                <div class="col-sm-12">
                    <div *ngFor="let notification of reportDetails.reportNotifications" [class]="'notification -notification-' + notification.notificationType.toLowerCase()">
                        <strong>{{ notification.notificationTitle }}:</strong> <span [innerHTML]="notification.notificationText"></span>
                    </div>
                </div>
            </div>

            <!-- Tables -->
            <div class="row" id="tables" *ngIf="tableData">
                <div [class]="timeseriesAvailable ? 'col-sm-6' : 'col-sm-12'">

                    <!-- Month Data -->
                    <h4>Data for {{ selectedProjectDate.dateName }}:</h4>

                    <table class="table table-bordered" *ngIf="reportType === 'bar'">
                        <thead>
                            <tr>
                                <th style="width:30%">Submission</th>
                                <th>Result<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of tableData" [style.background-color]="d.color ? '#F3F3F3' : '#FFFFFF'">
                                <td>
                                    <i class="fas fa-square" [style.color]="d.color ? d.color : '#F3F3F3'"></i>&nbsp;{{ d.name }}
                                </td>
                                <td>
                                    {{ d.y | number: reportSettings.maxDecPlaces }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Numerator / Denominator Values -->
                    <ng-container *ngIf="projectId == 52">
                        <h4 *ngIf="tableData[0].denominatorValue || tableData[0].numeratorValue">Numerator / Denominator Values:</h4>
                        <table class="table table-bordered" *ngIf="reportType === 'bar'">
                            <thead>
                                <tr>
                                    <th style="width:30%">Submission</th>
                                    <th *ngIf="tableData[0].numeratorValue">Numerator</th>
                                    <th *ngIf="tableData[0].denominatorValue">Denominator</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let d of tableData" [style.background-color]="d.color ? '#F3F3F3' : '#FFFFFF'">
                                    <td>
                                        <i class="fas fa-square" [style.color]="d.color ? d.color : '#F3F3F3'"></i>&nbsp;{{ d.name }}
                                    </td>
                                    <td *ngIf="d.numeratorValue">
                                        {{ d.numeratorValue | number: reportSettings.maxDecPlaces }}
                                    </td>
                                    <td *ngIf="d.denominatorValue">
                                        {{ d.denominatorValue | number: reportSettings.maxDecPlaces }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <table class="table table-bordered" *ngIf="reportType === 'stacked'">
                        <thead>
                            <tr>
                                <th style="width:30%">Submission</th>
                                <ng-container *ngFor="let col of tableHeader">
                                    <th>
                                        {{ col }}<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of tableData" [style.background-color]="d.color ? '#F3F3F3' : '#FFFFFF'">
                                <td><i class="fas fa-square" [style.color]="d.color ? d.color : '#F3F3F3'"></i>&nbsp;{{ d.submission }}</td>
                                <ng-container *ngFor="let col of d.responses">
                                    <td *ngIf="!col.y">0</td>
                                    <td *ngIf="col.y">{{ col.y | number: reportSettings.maxDecPlaces }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table table-bordered" *ngIf="reportType === 'pie'">
                        <thead>
                            <tr>
                                <th style="width:30%">Submission</th>
                                <ng-container *ngFor="let col of reportCategories">
                                    <th *ngIf="col.categoryName">
                                        {{ col.categoryName }}<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of tableData" [style.background-color]="d.color ? '#F3F3F3' : '#FFFFFF'">
                                <td><i class="fas fa-square" [style.color]="d.color ? d.color : '#F3F3F3'"></i>&nbsp;{{ d.submission }}</td>
                                <ng-container *ngFor="let col of d.data">
                                    <td *ngIf="!col.y">0</td>
                                    <td *ngIf="col.y">{{ col.y | number: reportSettings.maxDecPlaces }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-sm-6" *ngIf="timeseriesAvailable">

                    <h4>{{ projectId == 52 ? 'Regional' : 'Sample' }} Averages:</h4>
                    <table *ngIf="reportType === 'bar'" class="table table-bordered">
                        <thead>
                            <tr class="meadianMean">
                                <th style="width:40%">Month</th>
                                <th style="width:20%">
                                    <i class="fas fa-square" [style.color]="nationalMeanColour"></i>
                                    Mean<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                                </th>
                                <th style="width:20%">
                                    <i class="fas fa-square" [style.color]="nationalMedianColour"></i>
                                    Median<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                                </th>
                                <th style="width:20%" *ngIf="calculatedNationalMeansAvailable">
                                    <i class="fas fa-square" [style.color]="'#768692'"></i>
                                    {{ projectId == 52 ? 'National' : 'Sample' }} Average<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let av of averagesTable">
                                <td>{{ av.dateName }}</td>
                                <td>{{ av.y | number: reportSettings.maxDecPlaces }}</td>
                                <td>{{ av.median | number: reportSettings.maxDecPlaces }}</td>
                                <ng-container *ngIf="calculatedNationalMeansAvailable">
                                    <td>{{ av.nationalCalculatedMean | number: reportSettings.maxDecPlaces }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>

                    <div class="table-responsive" *ngIf="reportType == 'stacked' || reportType == 'pie'">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <ng-container *ngFor="let col of categoryAverages[0].data">
                                        <th *ngIf="!col.name">-</th>
                                        <th *ngIf="col.name">{{ col.name }}<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span></th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let group of categoryAverages | groupBy: 'category' | pairs">
                                    <tr>
                                        <td colspan="100%" style="background-color:#F3F3F3">
                                            <strong>{{ group[0]}}</strong>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let av of group[1]">
                                        <td>{{ av.name }}</td>
                                        <ng-container *ngFor="let col of av.data">
                                            <td>{{ col.y | number: reportSettings.maxDecPlaces }}</td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <!-- <tbody>
                                <tr *ngFor="let av of categoryAverages">
                                    <td>{{ av.name }}</td>
                                    <ng-container *ngFor="let col of av.data">
                                        <td *ngIf="!col.y">-</td>
                                        <td *ngIf="col.y">{{ col.y | number: reportSettings.maxDecPlaces }}<span *ngIf="reportSettings.formatModifier === 'P'">%</span></td>
                                    </ng-container>
                                </tr>
                            </tbody> -->
                        </table>
                    </div>

                </div>
                <div class="col-sm-12" *ngIf="(reportType == 'stacked' || reportType == 'pie') && submissionName" [hidden]="hideTable">
                    <div *ngFor="let d of openSubmissionTables">
                    <h4>{{ d.name }} Values:</h4>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <ng-container *ngFor="let col of categoryAverages[0].data">
                                        <th *ngIf="!col.name">-</th>
                                        <th *ngIf="col.name">{{ col.name }}<span *ngIf="reportSettings.formatModifier === 'P'"> (%)</span></th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let group of d | groupBy: 'category' | pairs">
                                    <tr *ngFor="let av of group[1]">
                                        <td>{{ av.name }}</td>
                                        <ng-container *ngFor="let col of av.data">
                                            <td>{{ col.y | number: reportSettings.maxDecPlaces }}</td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
    
                        </table>
                    </div>

                </div>
            </div>
            </div>

        </div>

    </div>

</div>

<!-- PDF REPORT -->
<div id="report" *ngIf="projectView === 'report' && !error">

    <!-- Loading (report metrics) -->
    <div class="loading-report" *ngIf="reportMetricsLoading || !reportSectionsLength">
        <i class="far fa-spinner fa-spin"></i> Please wait for the report to load...
    </div>

    <!-- Report Error -->
    <div class="error-report" *ngIf="errorReportMetrics">
        {{ errorReportMetrics }}
    </div>

    <div (click)="print()" class="btn-print no-print">
        <i class="far fa-print"></i>Print or Save
    </div>

    <!-- REPORT -->
    <div *ngIf="!errorReportMetrics && selectedProjectDate && reportSectionsLength">

        <!-- Cover page -->
        <div class="print-page">
            <div class="print-page-header"></div>
            <div class="print-page-section-full">
                <div class="print-page-section-mark ">
                    <img src="/assets/images/nhsbn-mark.png" alt="logo-mark">
                </div>
                <div class="print-page-document-title" *ngIf="selectedOrganisation">
                    <h1>{{ projectName }}</h1>
                    <h2>Report for {{ selectedProjectDate.dateName }}</h2>
                    <p>
                        <span *ngIf="projectId === 29">{{ selectedOrganisation.organisationCode }}: </span>
                        {{ selectedOrganisation.organisationName }}
                    </p>
                    <div *ngFor="let sub of selectedOrganisation.submissionList">
                        {{ sub.anonSubmissionCode ? sub.anonSubmissionCode + ':' : '' }}&nbsp;{{ sub.submissionName }}
                    </div>
                </div>
                <div class="print-page-document-title" *ngIf="!selectedOrganisation">
                    <h1>{{ projectName }}</h1>
                    <h2>Report for {{ selectedProjectDate.dateName }}</h2>
                    <p>Example Report</p>
                </div>
            </div>
            <div class="print-page-section-row -footer">
                <div class="print-page-section-cols">
                    <div class="print-page-footer-left"></div>
                    <div class="print-page-footer-right"></div>
                </div>
            </div>
        </div>

        <!-- Contents page -->
        <div class="print-page">
            <div class="print-page-header" style="margin-bottom:0.25em">
                <h3>Contents</h3>
            </div>
            <div class="print-page-section-full">
                <table class="table table-condensed table-bordered">
                    <thead>
                        <tr>
                            <th>Section</th>
                            <th>Page</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><strong>Information about the Project</strong></td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td><strong>Key Metrics:</strong></td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let r of reportMetrics" class="metric-lines">
                            <td style="padding-left:1em">{{ r.reportName }}</td>
                            <td>{{ r.pageNumber }}</td>
                        </tr>
                        <tr *ngIf="projectId !== 29">
                            <td><strong>Participants List for {{ selectedProjectDate.dateName }}</strong></td>
                            <td>{{ reportSectionsLength + 4 }}</td>
                        </tr>
                        <!-- TODO: Iterate over metrics (create titles) -->
                    </tbody>
                </table>
            </div>
            <div class="print-page-section-row -footer">
                <div class="print-page-section-cols">
                    <div class="print-page-footer-left">
                        &copy; NHS Benchmarking Network {{ copyrightYear }}
                    </div>
                    <div class="print-page-footer-right">
                        2
                    </div>
                </div>
            </div>
        </div>

        <!-- Introduction page -->
        <div class="print-page">
            <div class="print-page-header">
                <h3>Introduction</h3>
            </div>
            <div class="print-page-section-full" *ngIf="projectId === 41">
                <p>
                    The Community Services Covid-19 dashboard is a NEW project for all providers of community services, which commenced in June 2020, following the coronavirus pandemic. This monthly targeted data collection aims to track the impact of the Covid-19 pandemic on community health services provision across the UK, with the specification consisting of a limited number of key metrics to enable providers to track ongoing changes in the delivery of services and provide an impact assessment for both local providers and the NHS as a whole.
                </p>
                <p>
                    The project will help providers understand their relative positions on Covid-19 in terms of the disruption, recovery, and transformation of services that is being experienced across the NHS. The project is UK wide and will produce a monthly online comparative dashboard, an online toolkit together with the ability to download this PDF reports for all participating providers. It is open to members only. Members can join at any point in the cycle.
                </p>
                <p>
                    This report highlights the findings on 18 key metrics in dashboard format, although the monthly comparisons on all metrics can be found in the monthly online dashboard and toolkit <a href="https://members.nhsbenchmarking.nhs.uk/project/41" target="_blank">members.nhsbenchmarking.nhs.uk/project/41</a>. It is intended that this report can be used by members to provide comparative intelligence and insight to their Board on tracking the impact on community health services of the coronavirus locally. For each of the 18 metrics, 2 chart types are displayed: a column chart which details your organisation/submission response against other providers. There is also a monthly time series chart outlining the trend from the months for which data has been supplied to date. Your organisation/submissions’ position is marked in red on the column charts. All other participating organisation/submissions’ positions are anonymised, and codes will not be shared.
                </p>
                <p>
                    <strong>Please note:</strong> Data for the project is refreshed on a monthly basis. National positions included in this report may change, as new participants join the project and provide data for previous months.
                </p>
                <p>
                    Feedback is welcomed on this new project via <a href="mailto:joylin.brockett@nhs.net">joylin.brockett@nhs.net</a>.
                </p>
                <p>
                    For further information on the Community Services Covid-19 dashboard project, please contact <a href="mailto:joylin.brockett@nhs.net">joylin.brockett@nhs.net</a>.
                </p>
                <p>
                    There is a similar monthly dashboard project for providers of Mental Health and learning Disability care. Further information is available from <a href="mailto:e.fox4@nhs.net">e.fox4@nhs.net</a>. The data specification for the MH tracker is available on the Members' Area.
                </p>
            </div>
            <div class="print-page-section-full" *ngIf="projectId === 29">              
                <p>
                    The Community Indicators project helps community providers track their performance, across {{ chartCount }} metrics covering patient safety and quality, access, productivity, workforce, finance and Virtual Wards. The project is UK wide and will produce a monthly online comparative dashboard, an online toolkit, data extract together with the ability to download this PDF reports for participating providers. It is open to members only. Members can join at any point in the cycle.
                </p>
                <p>
                    Data collected within this project contributes to filling the gap in the lack of national data set, at present, on community-based provision. The submission data is benchmarked and displayed across a range of outputs, to help community providers easily review their position against the sample average, to identify areas of good practice and service improvement.
                </p>
                <p>
                    On average, 8 working days elapse from submission of data to output publication, enabling providers to have the timely evidence to inform the Trust Board on performance across the 6 domains. Results from the project can be used in your organisation’s internal performance management systems.
                </p>
                <p>
                    This report highlights the findings on {{ chartCount }} key metrics in dashboard format, although the monthly comparisons on all metrics can be found in the monthly online dashboard and toolkit <a href="www.members.nhsbenchmarking.nhs.uk/project/29" target="_blank">www.members.nhsbenchmarking.nhs.uk/project/29</a> . It is intended that this report can be used by members to provide comparative intelligence and insight to their Board on tracking the performance across community health services. 
                </p>                
                <p>
                    For each of the metrics, 2 chart types are displayed: a column chart which details your organisation/submission response against other providers. There is also a monthly time series chart outlining the trend from the months for which data has been supplied to date. Your organisation/submissions’ position is marked in red on the column charts. All other participating organisation/submissions’ positions are coded. The list of organisations submitting data to the project are highlighted at the back of the report.
                </p>              
                <p>
                    Outputs for the 2023/24 Community Indicators project are anonymous. Benchmarked results are displayed with unique identifiers known only to the individual participants themselves. However, a selection of organisations have chosen to share their identifiable code with other participants to support greater collaboration and shared learning. These organisations will have received a list of organisation codes and names of providers, that have likewise opted in to share their code. <br> The identifiable findings <strong>should not</strong> be shared outside of your organisation.
                </p>
                <p>
                    Please note: Data for the project is refreshed on a monthly basis. National positions included in this report may change, as new participants join the project and provide data for previous months.
                </p>
                <p>
                    Feedback is welcomed on this project via Kaitlyn Biggs (<a href="mailto:k.biggs2@nhs.net">k.biggs2@nhs.net</a>).
                </p>
                <p>
                    For further information on the Community Indicators project, please contact Kaitlyn Biggs (<a href="mailto:k.biggs2@nhs.net">k.biggs2@nhs.net</a>) or Nayan Mistry (<a href="n.mistry5@nhs.net">n.mistry5@nhs.net</a>). 
                </p>
            </div>
            <div class="print-page-section-row -footer">
                <div class="print-page-section-cols">
                    <div class="print-page-footer-left">
                        &copy; NHS Benchmarking Network {{ copyrightYear }}
                    </div>
                    <div class="print-page-footer-right">
                        Page 3
                    </div>
                </div>
            </div>
        </div>

        <!-- Metrics pages -->
        <!-- TODO: Iterate over reportMetrics -->
        <div class="print-page" *ngFor="let section of reportSections; let i = index">
            <app-section [section]="section" [index]="i" [selectedProjectDate]="selectedProjectDate" [projectId]="projectId"></app-section>
        </div>

        <!-- Participants page -->
        <div class="print-page" *ngIf="projectId !== 29">
            <div class="print-page-header">
                <h3>Participants List for {{ selectedProjectDate.dateName }}</h3>
            </div>
            <div class="print-page-section-full">
                <table class="table table-condensed table-bordered table-striped" *ngIf="projectId != 29">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Organisation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of participants; let i = index" class="metric-lines">
                            <td class="h5">{{ i + 1 }}</td>
                            <td class="h5">{{ p.OrganisationName }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-condensed table-bordered table-striped" *ngIf="projectId == 29">
                    <thead>
                        <tr>
                            <th>Submission</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of participants; let i = index" class="metric-lines">
                            <td class="h5">{{ p.submissionName }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="print-page-section-row -footer">
                <div class="print-page-section-cols">
                    <div class="print-page-footer-left">
                        &copy; NHS Benchmarking Network {{ copyrightYear }}
                    </div>
                    <div class="print-page-footer-right">
                        Page {{ reportSectionsLength + 4 }}
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

<div *ngIf="projectId == 52" id="feedbackButton">
    <button class="btn btn-success" (click)="linkToFeedbackForm()">
        <i class="fas fa-comment-alt"></i> Feedback
    </button>
</div>

<!-- Download Modal -->
<div bsModal #selectReportModal="bs-modal" class="modal" tabindex="-1" role="dialog" (onHide)="showReportModal ? showReportModal = false : showReportModal = true; selected = []">
    <div class="modal-dialog" role="document" style="width: 60em">
        <div class="modal-content modal-buttons">
            <nav class="navbar navbar-default">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-primary navbar-btn" (click)="selected = []; massDownloadTiers(true, openTiers)">Select All</button>
                            <button class="btn btn-warning navbar-btn" style="margin-left: 5px; margin-right: 5px;" (click)="selected = []; massDownloadTiers(false, openTiers)">Clear All</button>
                            <button class="btn btn-info navbar-btn" (click)="massDownloadTiers(null, openTiers ? false : true)">{{ openTiers ? 'Hide Tiers' : 'Show Tiers' }} </button>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-success navbar-btn" [disabled]="selected.length == 0" style="float: right;" (click)="startDownload(); selectReportModal.hide()">Begin Download</button>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="menu-container">
                <div class="menu-select">
                    <table class="table table-bordered" style="border-top: none; margin-bottom: 5px;">
                        <thead>
                            <tr >
                                <th scope="col" style="padding-left: 10px;">Report Tiers</th>
                                <th scope="col" style="padding-left: 10px;">Selected Reports</th>
                            </tr>
                        </thead>
                        <tbody style="height: 230px">
                            <tr>
                                <td>
                                    <div id="tierSystem">
                                        <div *ngFor="let t1 of tiers" class="new-tier">
                                            <!-- Tier 1-->
                                            <div *ngIf="t1.tierTypeId !== 3">
                                                <span *ngIf="t1.isVisible !== 'N'"
                                                    (click)="t1.childrenOpen = !t1.childrenOpen"
                                                    [style.font-weight]="t1.selected ? 'bold' : 'normal'"
                                                    [style.color]="reportDetails && t1.reportId === reportDetails.reportId ? '#005EB8' : 'black'">
                                                    <ng-container *ngIf="t1.tierTypeId === 1">
                                                        <i
                                                            [class]="t1.childrenOpen ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                    </ng-container>
                                                    {{ t1.tierName }}
                                                </span>
                                            </div>
                                            <div *ngIf="t1.childrenOpen">
                                                <div style="margin-left:1em" class="new-tier"
                                                    *ngFor="let t2 of t1.childrenTiers">
                                                    <!-- Tier 2 -->
                                                    <div *ngIf="t2.tierTypeId !== 3">
                                                        <span *ngIf="t2.isVisible !== 'N'"
                                                            (click)="t2.childrenOpen = !t2.childrenOpen"
                                                            [style.font-weight]="t2.selected ? 'bold' : 'normal'"
                                                            [style.color]="reportDetails && t2.reportId === reportDetails.reportId ? '#005EB8' : 'black'">
                                                            <ng-container *ngIf="t2.tierTypeId === 1">
                                                                <i
                                                                    [class]="t2.childrenOpen ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                            </ng-container>
                                                            {{ t2.tierName }}
                                                        </span>
                                                    </div>
                                                    <div *ngIf="t2.childrenOpen">
                                                        <div style="margin-left:2em" class="new-tier"
                                                            *ngFor="let t3 of t2.childrenTiers">
                                                            <!-- Tier 3 -->
                                                            <div *ngIf="t3.tierTypeId !== 3">
                                                                <span *ngIf="t3.isVisible !== 'N'"
                                                                    (click)="t3.childrenOpen = !t3.childrenOpen"
                                                                    [style.font-weight]="t3.selected ? 'bold' : 'normal'"
                                                                    [style.color]="reportDetails && t3.reportId === reportDetails.reportId ? '#005EB8' : 'black'">
                                                                    <ng-container *ngIf="t3.tierTypeId === 1">
                                                                        <i
                                                                            [class]="t3.childrenOpen ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                                    </ng-container>
                                                                    <div
                                                                        *ngIf="t3.childrenTiers.length > 0; then canRemove else cannotRemove">
                                                                    </div>
                                                                    <ng-template #cannotRemove>
                                                                        <h6 (click)="tierCheck(t3, t2.tierName)">
                                                                            <i class="far mr-2"
                                                                                style="margin-right: 5px;"
                                                                                [class.fa-square]="!t3.selected"
                                                                                [class.fa-check-square]="t3.selected"></i>
                                                                            {{ t3.tierName }}
                                                                        </h6>
                                                                    </ng-template>
                                                                    <ng-template #canRemove>
                                                                        {{ t3.tierName }}
                                                                    </ng-template>
                                                                </span>
                                                            </div>
                                                            <div *ngIf="t3.childrenOpen">
                                                                <div style="margin-left:3em" class="new-tier"
                                                                    *ngFor="let t4 of t3.childrenTiers">
                                                                    <!-- Tier 4 -->
                                                                    <div *ngIf="t4.tierTypeId !== 3">
                                                                        <span (click)="tierCheck(t4, t3.tierName)"
                                                                            [style.font-weight]="t4.selected ? 'bold' : 'normal'"
                                                                            [style.color]="reportDetails && t4.reportId === reportDetails.reportId ? '#005EB8' : 'black'">
                                                                            <ng-container *ngIf="!t4.reportId">
                                                                                <i
                                                                                    [class]="t4.childrenOpen ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                                            </ng-container>
                                                                            <h6>
                                                                                <i class="far mr-2"
                                                                                    style="margin-right: 5px;"
                                                                                    [class.fa-square]="!t4.selected"
                                                                                    [class.fa-check-square]="t4.selected"></i>
                                                                                {{ t4.tierName }}
                                                                            </h6>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div style="margin-top: 5px;">
                                        <div *ngIf="selected.length != 40">
                                            <table class="table table-striped">
                                                <tbody style="font-size: 11px;">
                                                    <tr *ngFor="let item of selected">
                                                        <td>{{ item.tier }}</td>
                                                        <td style="text-align: right;">{{ item.parent }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div *ngIf="selected.length == 40">
                                            <table class="table table-striped">
                                                <tbody style="font-size: 11px;">
                                                    <tr>
                                                        <td>All reports are selected</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        
                    </table>
                    <small class="text-muted" style="margin-left: 5px">The downloader will cycle through reports for <strong>{{ selectedPeriod }}</strong>, load each report to the screen and then download them as (.png)</small>
                </div>
            </div>
        </div>
    </div>
</div>