<div *ngIf="registration">

  <table class="table" *ngIf="deputyProjectLead.length > 0">
    <thead>
      <tr>
        <th class="col-md-3">Name</th>
        <th class="col-md-3">Job Title</th>
        <th class="col-md-4">Email</th>
        <th class="col-md-2"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let d of deputyProjectLead">
        <td>{{d.fullName}}</td>
        <td>{{d.jobTitle || '-'}}</td>
        <td><a href="mailto:{{d.logonName}}">{{d.logonName}}</a></td>
        <td class="text-right">
          <button
            type="button" 
            class="btn btn-xs btn-danger" 
            *ngIf="registration.registration.deputyProjectLead.allowChanges && !registration.registration.registrationLocked"
            (click)="deputyProjectLeadFormRemove(d.userId)">
              Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <form *ngIf="showDeputyProjectLeadForm" [formGroup]="deputyProjectLeadForm" novalidate>
    <hr>
    <div (click)="getUserDetails()" class="hint -link">
      <i class="fas fa-lightbulb-on"></i>Click here to add your own details to the form
    </div>
    <div class="form-group">
      <label>Name <span class="text-danger">*</span></label>
      <input type="text" class="form-control" formControlName="fullName">
    </div>

    <div *ngIf="formErrors.fullName" class="alert alert-danger">
      {{ formErrors.fullName }}
    </div>

    <div class="form-group">
      <label for="logonName">Email <span class="text-danger">*</span></label>
      <input type="email" formControlName="logonName" class="form-control">
    </div>

    <div *ngIf="formErrors.logonName" class="alert alert-danger">
      {{ formErrors.logonName }}
    </div>

    <div class="form-group">
      <label>Job Title <span class="text-danger">*</span></label>
      <input type="text" class="form-control" formControlName="jobTitle">
    </div>

    <div *ngIf="formErrors.jobTitle" class="alert alert-danger">
      {{ formErrors.jobTitle }}
    </div>

    <div class="form-group">
      <label>Contact Number</label>
      <input type="text" class="form-control" formControlName="phoneNo">
    </div>

    <div *ngIf="formErrors.phoneNo" class="alert alert-danger">
      {{ formErrors.phoneNo }}
    </div>

    <div>
      <button type="button" class="btn btn-success button-margin" [disabled]="!deputyProjectLeadForm.valid" (click)="deputyProjectLeadFormSubmit()">Save</button>
      <button type="button" class="btn btn-default button-margin" (click)="showDeputyProjectLeadForm = false; showDeputyProjectLeadFormAdd = true;">Cancel</button>
    </div>
    <p class="text-muted explainer" *ngIf="!deputyProjectLeadForm.valid">
      Name, email and job title are required are required before the Deputy Project Lead can be saved. Contact number is optional.
    </p>
  </form>

  <p *ngIf="!deputyProjectLead.length > 0 && registration.registration.registrationLocked" class="blank">
    No Deputy Project Leads added.
  </p>

  <button 
    type="button" 
    class="btn btn-primary button-margin" 
    [disabled]="!registration.registration.deputyProjectLead.allowChanges || !showDeputyProjectLeadFormAdd || registration.registration.registrationLocked"
    *ngIf="deputyProjectLead.length < maxDeputyProjectLeads && showDeputyProjectLeadFormAdd && registration.registration.deputyProjectLead.allowChanges && !registration.registration.registrationLocked"
    (click)="showDeputyProjectLeadForm = true; showDeputyProjectLeadFormAdd = false;">
      Add Deputy Project Lead
  </button>

</div>