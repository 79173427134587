import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from 'app/utilities/services/auth-guard.service';
import { DataValidationComponent } from './data-validation.component';

const dataValidationRoutes: Routes = [
  { path: 'validation/:projectId/:year', component: DataValidationComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(dataValidationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DataValidationRoutingModule { }