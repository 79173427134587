import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectOverviewComponent } from './project-overview.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const projectoverviewRoutes: Routes = [
  // Redirect Community Indicators and CS Covid-19 Dashboard
  { path: 'project-overview/41', redirectTo: 'project/41/collection', pathMatch: 'full' },
  { path: 'project-overview/43', redirectTo: 'project/43/collection', pathMatch: 'full' },
  // Standard path
  { path: 'project-overview/:projectId', component: ProjectOverviewComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(projectoverviewRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProjectOverviewRoutingModule { }