import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContactComponent } from "./contact.component";

import { AuthGuardService } from "../utilities/services/auth-guard.service";

const routes: Routes = [
  {
    path: "contact",
    component: ContactComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactRoutingModule {}
