import { Component, Input, OnChanges } from '@angular/core';
import { User } from 'app/utilities/classes/user/user';
import { Event } from '../../utilities/classes/event/event'
import { IResourcesNonProjectDocuments } from 'app/utilities/models/resources/resources-nonProjectDocuments.vm';
import { IProjectDocumentsProjectsDocuments } from 'app/utilities/models/resources/projectDocuments-projects-documents.vm';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnChanges {
  @Input() user: User;
  @Input() filteredEvents: Event;
  @Input() filteredRequests: any;
  @Input() resourceList: IResourcesNonProjectDocuments;
  @Input() yourReports: IProjectDocumentsProjectsDocuments;

  public sections: Array<Object>;
  
  constructor() { }

  ngOnChanges() { 
    this.sections = [
      {
        title: 'Events',
        icon: 'far fa-calendar-alt',
        description: 'page for our full programme.',
        link: '/events', 
        data: this.filteredEvents
      }, 
      // {
      //   title: 'Your Reports', 
      //   icon: 'far fa-file-alt', 
      //   description: 'page to see all available documents.',
      //   link: '/resources', 
      //   data: this.yourReports
      // }, 
      {
        title: 'Resources', 
        icon: 'far fa-file-search', 
        description: 'page to see all available resources.', 
        link: '/resources', 
        data: this.resourceList
      }, 
      {
        title: 'NHSFutures', 
        icon: 'far fa-comments',
      }
    ];
  }
}
