<div *ngIf="projectInformation">

    <!-- Selector bar -->
    <div id="selectorBar">
        <div class="container-fluid">
            <form class="form-vertical">
                <div class="row">
                    <div class="col-md-6 col-sm-4 hidden-xs">
                        <span class="btn-back" (click)="navHome()">
                            <i class="fa fa-caret-left"></i> Back to Home
                        </span>
                    </div>
                    <div class="col-sm-2 hidden-xs text-right">
                        <span class="btn-back" *ngIf="!dataError && !offline && !organisationError" (click)="toggleTour()">
                            {{ tourState ? 'Hide' : 'Show' }} Tour
                        </span>
                    </div>
                    <div class="col col-md-4 col-sm-6 col-xs-12" *ngIf="!organisationError && !offline">
                        <div class="form-group">
                            <select class="form-control" id="organisation" name="organisation" [ngModel]="selectedOrganisation" (ngModelChange)="changeOrganisation($event)" [disabled]="!loadFinished">
                                <option *ngFor="let o of availableOrganisations" [ngValue]="o.organisationId">{{o.organisationName}}</option>
                            </select>
                        </div>
                        <div *ngIf="tourState && tourStep == 1" class="tour-container" id="tourStep1">
                            <div class="arrow-up"></div>
                            <div class="tour-content">
                                <p>Select your organisation from the drop-down list to load submissions for the current year.</p>
                                <div class="text-right">
                                    <button class="btn btn-default" (click)="stepTour()">Next Step</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Header -->
    <div id="headerDashboard" *ngIf="projectInformation">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8 col-sm-12 vcenter">
                    <h1>{{ projectInformation.projectName === 'NACEL' ? 'National Audit of Care at the End of Life (NACEL)' : projectInformation.projectName }}</h1>
                    <p *ngIf="!isAtd">
                        This page provides links to a range of outputs for the {{ projectInformation.projectName }} project, and shows a number of metrics curated by the Network Support Team. <span *ngIf="!permissionsError && !offline">You can view the full toolkit by clicking on the green button, which will also show your organisation's position, if you participated.</span><span *ngIf="offline"> You can view the outputs of the project by downloading the infographics and reports below.</span><span class="link" (click)="navProjectDetail(projectInformation.projectId)"> Learn more...</span>
                    </p>

                    <p *ngIf="projectId === 60"><br>District Nursing services have previously been benchmarked as part of the Community Services project, to view the data in the Community Services project please<a href="https://members.nhsbenchmarking.nhs.uk/dashboard/1"> click here.</a></p>
                    
                    <p *ngIf="projectId === 80"><br>Adult therapy services were benchmarked as part of a wider Therapies project in 2023. To view the data in the 2023 Therapies project, please <a href="https://members.nhsbenchmarking.nhs.uk/dashboard/65">click here</a>.</p>

                    <p *ngIf="isAtd">
                        This page provides links to the individual dashboards within the {{ projectInformation.projectName }} project. The dashboards show monthly reporting of key activity and performance metrics across six acute projects. <span class="link" (click)="navProjectDetail(projectInformation.projectId)"> Learn more...</span>
                    </p>
                    <a href="https://data.nacel.nhs.uk" target="_blank" class="btn btn-lg btn-primary" style="margin-right: 1em;" *ngIf="projectId === 34">Access {{ currentCalendarYear }} Data & Improvement Tool</a>
                    <button class="btn btn-lg btn-success" (click)="navOutputs(projectInformation.projectId)" *ngIf="projectId === 34">Open NACEL 2018 - 2022 toolkit <i class="fa fa-caret-right"></i></button>
                    <button class="btn btn-lg btn-success" (click)="navOutputs(projectInformation.projectId)" 
                        *ngIf="dataError && projectInformation['outputsAvailable'] == 'Y' && projectInformation['outputsOnline'] == 'Y'"> 
                        Open the full toolkit <i class="fa fa-caret-right"></i>
                    </button>
                </div><!--
                --><div class="col-md-3 col-md-offset-1 col-sm-12 vcenter managers" *ngIf="projectInformation">
                    <ng-container *ngIf="projectInformation.projectPhoneNo">
                        <p style="margin: 0;">
                            Contact the project team on <br />
                            <strong>{{ projectInformation.projectPhoneNo }}</strong>
                        </p>
                            <a href="mailto:{{ projectInformation.projectEmail }}">{{ projectInformation.projectEmail }}</a>
                        <hr/>
                    </ng-container>
                        <p *ngFor="let p of projectInformation.projectRoles.slice(0, 2)">
                            <strong>{{ p.role }}</strong
                            ><br />
                            <a href="mailto:{{ p.email }}">{{ p.name }}</a>
                        </p>
                </div> 
            </div>
        </div>
    </div>

    <!-- Content -->
    <div id="contentDashboard">

        <!-- No content -->
        <div class="container-fluid" *ngIf="metricList?.length === 0 && (projectId === 43 || projectId === 44)">
            <div class="row">
                <div class="col-md-8 col-md-offset-2 col-sm-12">
                    <div class="no-outputs">
                        There are not currently any outputs for this project as the first round of data collection is still taking place. To take part in this project, please go back to the home page and click 'Register'.
                    </div>
                </div>
            </div>
        </div>

        <!-- Content without Dashboard -->
        <div class="container-fluid" *ngIf="dataError">
            <div class="row">
                <div class="col-md-3" *ngIf="awsDocuments?.Toolkit">
                    <div class="col-md-12 col-sm-6 col-xs-12" *ngIf="shortSection || longToolkit">
                        <div class="content-section">
                            <h2>Toolkit</h2>
                            <p>Click to download the available offline toolkits.</p>
                            <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.Toolkit).reverse()">
                                <h3>{{y}}</h3>
                                <p *ngFor="let a of awsDocuments.Toolkit[y]">
                                    <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                </p>
                            </ng-template>
                            <p><a type="button" (click)="toggleSection('toolkit')" *ngIf="longToolkit" class="view-more">View less...</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" *ngIf="awsDocuments?.Report">
                    <div class="col-md-12 col-sm-6 col-xs-12" *ngIf="shortSection || longReports">
                        <div class="content-section">
                            <h2>Summary Findings</h2>
                            <p>Click to download a selection of summary reports and infographics from the project.</p>
                            <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.Report).reverse()">
                                <h3>{{y}}</h3>
                                <p *ngFor="let a of awsDocuments.Report[y]">
                                    <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" *ngIf="awsDocuments?.Presentation">
                    <div class="col-md-12 col-sm-6 col-xs-12" *ngIf="shortSection || longPresentations">
                        <div class="content-section">
                            <h2>Presentations</h2>
                            <p>Click to download presentations from our previous events.</p>
                            <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.Presentation).reverse()">
                                <h3>{{y}}</h3>
                                <p *ngFor="let a of awsDocuments.Presentation[y]">
                                    <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                </p>
                            </ng-template>
                            <p><a type="button" (click)="toggleSection('presentations')" *ngIf="longPresentations" class="view-more">View less...</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" *ngIf="awsDocuments?.CaseStudy">
                    <div class="col-md-12 col-sm-6 col-xs-12" *ngIf="shortSection || longStudies">
                        <div class="content-section">
                            <h2>Case Studies</h2>
                            <p>Click to download a selection of case studies from the membership.</p>
                            <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.CaseStudy).reverse()">
                                <h3>{{y}}</h3>
                                <p *ngFor="let a of awsDocuments.CaseStudy[y]">
                                    <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                </p>
                            </ng-template>
                            <p><a type="button" (click)="toggleSection('studies')" *ngIf="longStudies" class="view-more">View less...</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ATD Dashboard -->
        <div class="container-fluid" *ngIf="isAtd && subProjectsList">
            <div class="row">     
                <div class="col-md-4" *ngFor="let s of subProjectsList">
                    <div class="dashboard-tile-container">
                        <a [routerLink]="s.lastUpdated ? '/project/' + s.projectId : null">
                        <div class="dashboard-tile online">
                            <div class="dashboard-tile-icon">
                                <i class="{{ s.projectFa }}"></i>
                            </div>
                            <div class="dashboard-tile-content">
                                <h5>
                                    {{ s.projectName }}
                                </h5>
                                <p *ngIf="!s.lastUpdated"><i>Coming soon</i></p>
                                <p *ngIf="s.lastUpdated">Latest data available: {{ s.lastUpdated }}</p>
                            </div>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content with Dashboard -->
        <div class="container-fluid">
            <div class="row">
                <!-- Toolkit and Dashboard -->
                <div class="col-md-8 col-md-offset-1 col-md-push-3 col-sm-12" *ngIf="!dataError">
                    <div class="row toolkit-bar" *ngIf="metricList">
                        <div *ngIf="offline || projectId === 34" [class]="projectId === 34 ? 'col-sm-4' : 'col-sm-12'">
                            <h2>
                                Toolkit <span *ngIf="projectId == 29">for February 2020</span>
                                <span *ngIf="projectId == 34">
                                    <br><small>Round 4: 2022</small>
                                </span>
                            </h2>
                            <p *ngIf="projectId !== 34">
                                The icons below summarise some of the latest findings of the project.
                            </p>
                            <p *ngIf="projectId == 34">
                                The icons below summarise some of the latest findings of NACEL round four for Acute and Community inpatient providers in England and Wales.
                            </p>
                        </div>
                        <div class="col-sm-8" *ngIf="projectId === 34">
                            <div class="row" id="nacelSummary">
                                <div class="col-sm-4">
                                    <i class="fas fa-hospital-alt"></i>
                                    <h4>214</h4>
                                    <p>Hospital/Site Overviews</p>
                                </div>
                                <div class="col-sm-4">
                                    <i class="fas fa-folders"></i>
                                    <h4>7,620</h4>
                                    <p>Case Note Reviews</p>
                                </div>
                                <div class="col-sm-4">
                                    <i class="fas fa-ballot-check"></i>
                                    <h4>3,600</h4>
                                    <p>Quality Surveys</p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!offline && projectId !== 34">
                            <div class="col-sm-8 vcenter">
                                <h2>Toolkit ({{ currentYear }})</h2>
                                <p>
                                    The icons below summarise some of the latest findings of the project. If you participated in the project in the toolkit's year, you can select a submission from the drop-down list and then compare your response to the sample average.
                                </p>
                            </div><!--
                            --><div class="col-sm-4 vcenter text-right">
                                <button class="btn btn-lg btn-success" 
                                    (click)="navOutputs(projectInformation.projectId)"
                                    *ngIf="!permissionsError">
                                    Open the full toolkit <i class="fa fa-caret-right"></i>
                                </button>
                                <div *ngIf="tourState && tourStep == 2" class="tour-container" id="tourStep2">
                                    <div class="arrow-up"></div>
                                    <div class="tour-content">
                                        <p>Click here to open the full toolkit and view all key metrics for this project.</p>
                                        <div class="text-right">
                                            <button class="btn btn-default" (click)="stepTour()">Next Step</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row actions-bar" *ngIf="metricList && projectId !== 34 && !offline && !acuteError">
                        <div class="col-sm-12">
                            <!-- Sample/submission toggle -->
                            <ng-container *ngIf="availableSubmissions.length > 0">
                                <div class="form-group col-action">
                                    <label>Switch View</label>
                                    <div class="btn-group">
                                        <button [class]="currentNatSub === 'National' ? 'btn-toggle active' : 'btn-toggle'" (click)="natSubToggle('National')" [disabled]="!loadFinished">
                                            Sample
                                        </button>
                                        <button [class]="currentNatSub === 'Submission' ? 'btn-toggle active' : 'btn-toggle'"(click)="natSubToggle('Submission')" [disabled]="!loadFinished">
                                            Submission
                                        </button>
                                    </div>
                                    <div *ngIf="tourState && tourStep == 3" class="tour-container" id="tourStep3">
                                        <div class="arrow-up"></div>
                                        <div class="tour-content">
                                            <p>Use this toggle to switch between the sample averages and the submission response.</p>
                                            <div class="text-right">
                                                <button class="btn btn-default" (click)="stepTour()">Next Step</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-action">
                                    <label>Select a Submission</label>
                                    <select class="form-control" id="submission" name="submission" [ngModel]="selectedSubmission" (ngModelChange)="changeSubmission($event)" [disabled]="!loadFinished">
                                            <option *ngFor="let s of availableSubmissions" [ngValue]="s.submissionId">{{s.submissionName}}</option>
                                    </select>
                                </div>
                            </ng-container>
                            <!-- Service toggle -->
                            <ng-container *ngIf="availableServices.length > 0 && availableServices[0].serviceItemId != null">
                                <div class="form-group col-action">
                                    <label style="margin-top:3px;">Select a Service</label>
                                    <select class="form-control" id="service" name="service" [ngModel]="selectedService" (ngModelChange)="changeService($event)" [disabled]="!loadFinished">
                                        <option *ngFor="let s of availableServices" [ngValue]="s.serviceItemId">{{s.serviceItemName}}</option>
                                    </select>
                                </div>
                            </ng-container>
                            <span class="text-muted" style="cursor: pointer; display: table;" (click)="downloadDashboard()">
                                <i class="fas fa-file-download" style="margin-right: 5px;"></i>Download Dashboard (.png)
                            </span>
                        </div>
                    </div>

                    <div class="row" *ngIf="!metricList && !permissionsError">
                        <div class="tile-loading">
                            <i class="fa fa-spinner fa-spin"></i>
                            <p>The dashboard is loading. Please wait...</p>
                        </div>
                    </div>

                    <div class="row" *ngIf="permissionsError">
                        <div class="no-dashboard">
                            <h3>You don't have the necessary permissions.</h3>
                            <p>You do not currently have access to these outputs. Please contact the support team to request access.</p>
                        </div>
                    </div>

                    <div class="row" *ngIf="combinedTotal == 0 && currentNatSub == 'Submission'">
                        <div class="col-md-12">
                            <div class="alert alert-warning text-center">
                                <strong>There are no responses for this submission<span *ngIf="selectedService"> and service</span>.</strong>
                            </div>
                        </div>
                    </div>

                    <div class="row" id="pdf" [style.width.px]="setWidth" #dashboard>
                        <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let m of metricList">
                            <div *ngIf="m.id == 0 && tourState && tourStep == 5" class="tour-container" id="tourStep5">
                                <div class="tour-content">
                                    <p>Click on any of the metrics to go to the full chart in the toolkit for more details analysis.</p>
                                    <div class="text-right">
                                        <button class="btn btn-default" (click)="toggleTour()">Exit Tour</button>
                                    </div>
                                </div>
                                <div class="arrow-down"></div>
                            </div>
                            <div class="dashboard-tile-container">
                                <a (click)="navChart(projectInformation.projectId, m.tierId, m.optionId)" [class]="m.tierId ? '' : 'disable-link'">
                                <div [class]="m.tierId ? 'dashboard-tile online' : 'dashboard-tile offline'">
                                    <div class="dashboard-tile-icon">
                                        <i class="{{ m.metricFa }}"></i>
                                    </div>
                                    <div class="dashboard-tile-content">
                                        <span [class]="m.finalValue ? 'final-value' : 'final-value-loading'">
                                            <i *ngIf="!m.finalValue" class="far fa-spinner fa-spin"></i>
                                            <i *ngIf="m.finalValue">{{ m.finalValue }}</i>
                                        </span>
                                      <p>{{ m.metricText }}</p>
                                    </div>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="download" style="display: none;">
                        <img #canvas>
                        <a #downloadLink></a>
                    </div>
                </div>
                <!-- Sidebar and Documents -->
                <div [class]="(dataError && !isAtd) ? 'col-md-3' : 'col-md-3 col-md-pull-9 col-sm-12'">
                    <div class="row" *ngIf="accessError">
                        <div class="col-xs-12" id="accessError">
                            <h2>Join the Network</h2>
                            <p>
                                Subscribing members of the NHS Benchmarking Network can access:
                            </p>
                            <ul>
                                <li>An interactive online toolkit, allowing members to benchmark their service across hundreds of metrics.</li>
                                <li>A bespoke dashboard report outlining key metrics, and highlighting member reported positions.</li>
                                <li>A high-level report outlining key messages nationally.</li>
                                <li>A good practice compendium of information supplied by member participants.</li>
                                <li>Free attendance at annual conferences, accessing presentations on project key findings, from national policy leads and members.</li>
                                <li>Good practice case studies on how members use the benchmarking to improve service delivery.</li>
                            </ul>
                            <p>
                                Member organisations can also access a full library of historic project content covering the most material NHS activities delivered by commissioners and providers.
                            </p>
                            <p>
                                For further information about membership subscription, please email <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries@nhsbenchmarking.nhs.uk</a>. 
                            </p>
                        </div>
                    </div>
                    <div class="row" *ngIf="!accessError">
                        <div class="col-xs-12" *ngIf="isSubProject && projectId != 6 && projectId != 35 && projectId != 31">
                            <div id="atdLink" [routerLink]="'/project/' + projectId">
                                <h2>Acute Transformation Dashboard</h2>
                                <p>
                                    Click here to view the monthly outputs for this project, part of the Acute Transformation Dashboard.
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12" *ngIf="projectId == 48">
                            <div id="atdLink" [routerLink]="'/project/48'">
                                <h2>SDEC Transformation Dashboard</h2>
                                <p>
                                    Click here to access monthly time series outputs, part of the SDEC Transformation Dashboard.
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12" *ngIf="forumRequests.length > 0">
                            <div id="forum">
                                <h2><i class="far fa-comments"></i>Forum</h2>
                                <p>
                                    See the latest related posts on our Forum:
                                </p>
                                <p class="forum-link" *ngFor="let f of forumRequests" (click)="navRequest(f.kenRequestId[0])">
                                    {{ f.requestTitle }}
                                </p>
                                <p class="forum-link" (click)="navForum(projectInformation.projectName)">
                                    View more...
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12" *ngIf="shortSection || longBespoke">
                            <div class="content-section" *ngIf="awsDocuments?.Bespoke">
                                <a type="button" (click)="toggleSection('bespokes')" *ngIf="longBespoke" class="close"><i class="fa fa-close"></i></a>
                                <h2>Member Project Reports</h2>
                                <p>Click to download reports for your organisation.</p>
                                <div *ngIf="shortSection">
                                    <p *ngFor="let r of bespokesInitial">
                                        <a role="button" class="document-link" (click)="getSelectedDocument(r.documentId)">{{r.documentName}}</a>
                                    </p>
                                    <app-document-panel [documents]="awsDocuments.Bespoke" [projectName]="projectInformation.projectName" [openDocumentModal]="openDocumentModal"></app-document-panel>
                                </div>
                                <div class="full-list" *ngIf="longBespoke">
                                    <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.Bespoke).reverse()">
                                        <h3>{{y}}</h3>
                                        <p *ngFor="let a of awsDocuments.Bespoke[y]">
                                            <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                        </p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <h2>Event Presentations</h2>
                            <p>
                                You can now find presentations in the Events section of the Members' Area.
                            </p>
                            <p class="forum-link">
                                <a [routerLink]="['/events']">Go to events...</a>
                            </p>
                        </div>
                        <div class="col-xs-12" *ngIf="shortSection || longToolkit">
                            <div class="content-section" *ngIf="awsDocuments?.Toolkit">
                                <a type="button" (click)="toggleSection('toolkit')" *ngIf="longToolkit" class="close"><i class="fa fa-close"></i></a>
                                <h2>Offline Toolkits</h2>
                                <p>Click to download the available offline toolkits.</p>
                                <div *ngIf="shortSection">
                                    <p *ngFor="let r of toolkitInitial">
                                        <a role="button" class="document-link" (click)="getSelectedDocument(r.documentId)">{{r.documentName}}</a>
                                    </p>
                                    <p><a type="button" (click)="toggleSection('toolkit')" class="view-more">View more...</a></p>
                                    <div *ngIf="tourState && tourStep == 4" class="tour-container" id="tourStep4">
                                        <div class="arrow-up"></div>
                                        <div class="tour-content">
                                            <p>Download project-specific findings documents, presentations and case studies. Click 'View more...' to see previous years' documents.</p>
                                            <div class="text-right">
                                                <button class="btn btn-default" (click)="stepTour()">Next Step</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="full-list" *ngIf="longToolkit">
                                    <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.Toolkit).reverse()">
                                        <h3>{{y}}</h3>
                                        <p *ngFor="let a of awsDocuments.Toolkit[y]">
                                            <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                        </p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12" *ngIf="shortSection || longReports">
                            <div class="content-section" *ngIf="awsDocuments?.Report">
                                <a type="button" (click)="toggleSection('reports')" *ngIf="longReports" class="close"><i class="fa fa-close"></i></a>
                                <h2>
                                    Summary Findings
                                </h2>
                                <p>
                                    Click to download a selection of summary reports and infographics from the project.
                                </p>
                                <div *ngIf="shortSection">
                                    <p *ngFor="let r of reportsInitial">
                                        <a role="button" class="document-link" (click)="getSelectedDocument(r.documentId)">{{r.documentName}}</a>
                                    </p>
                                    <p><a type="button" (click)="toggleSection('reports')" class="view-more">View more...</a></p>
                                    <div *ngIf="tourState && tourStep == 4 && !longToolkit" class="tour-container" id="tourStep4">
                                        <div class="arrow-up"></div>
                                        <div class="tour-content">
                                            <p>Download project-specific findings documents, presentations and case studies. Click 'View more...' to see previous years' documents.</p>
                                            <div class="text-right">
                                                <button class="btn btn-default" (click)="stepTour()">Next Step</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="full-list" *ngIf="longReports">
                                    <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.Report).reverse()">
                                        <h3>{{y}}</h3>
                                        <p *ngFor="let a of awsDocuments.Report[y]">
                                            <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                        </p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12" *ngIf="shortSection || longPresentations">
                            <div class="content-section" *ngIf="awsDocuments?.Presentation">
                                <a type="button" (click)="toggleSection('presentations')" *ngIf="longPresentations" class="close"><i class="fa fa-close"></i></a>
                                <h2>Presentations</h2>
                                <p>Click to download presentations from our previous events.</p>
                                <div *ngIf="shortSection">
                                    <p *ngFor="let p of presentationsInitial">
                                        <a role="button" class="document-link" (click)="getSelectedDocument(p.documentId)">{{p.documentName}}</a>
                                    </p>
                                    <p><a type="button" (click)="toggleSection('presentations')" class="view-more">View more...</a></p>
                                </div>
                                <div class="full-list" *ngIf="longPresentations">
                                    <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.Presentation).reverse()">
                                        <h3>{{y}}</h3>
                                        <p *ngFor="let a of awsDocuments.Presentation[y]">
                                            <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                        </p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12" *ngIf="shortSection || longStudies">
                            <div class="content-section" *ngIf="awsDocuments?.CaseStudy">
                                <a type="button" (click)="toggleSection('studies')" *ngIf="longStudies" class="close"><i class="fa fa-close"></i></a>
                                <h2>Case Studies</h2>
                                <p>Click to download a selection of case studies from the membership.</p>
                                <div *ngIf="shortSection">
                                    <p *ngFor="let s of studiesInitial">
                                        <a role="button" class="document-link" (click)="getSelectedDocument(s.documentId)">{{s.documentName}}</a>
                                    </p>
                                    <p><a type="button" (click)="toggleSection('studies')" class="view-more">View more...</a></p>
                                </div>
                                <div class="full-list" *ngIf="longStudies">
                                    <ng-template ngFor let-y [ngForOf]="htmlService.keys(awsDocuments.CaseStudy).reverse()">
                                        <h3>{{y}}</h3>
                                        <p *ngFor="let a of awsDocuments.CaseStudy[y]">
                                            <a role="button" class="document-link" (click)="getSelectedDocument(a.documentId)">{{a.documentName}}</a>
                                        </p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->
        </div>

        <div>
            <hr>
        </div>

    </div> <!-- end Content -->

</div>