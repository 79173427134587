import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BenchmarkerComponent } from './benchmarker.component';
import { BenchmarkerRoutingModule } from './benchmarker-routing.module';

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule, OrderByPipe, GroupByPipe } from 'ngx-pipes';
import { Ng2HighchartsModule } from 'ng2-highcharts';

// Services
import { DataService } from 'app/utilities/ics/data.service';
import { FormatService } from 'app/utilities/ics/format.service';
import { InfoPanelModule } from 'app/shared/info-panel/info-panel.module';

// Thousands separator
window['Highcharts'].setOptions({
  lang: {
      thousandsSep: ','
  }
});

@NgModule({
  imports: [
    CommonModule,
    InfoPanelModule,
    BenchmarkerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    Ng2HighchartsModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiZGh1Z2hlc2JtYyIsImEiOiJjazQ5bXAxYW8wNmM2M2ZwY3N2aTR4emU3In0.7rhqvNQKGZ_ciuuxVAdV3A'
    })
  ],
  declarations: [
    BenchmarkerComponent
  ],
  providers: [
    DataService,
    FormatService,
    GroupByPipe,
    OrderByPipe
  ]
})
export class BenchmarkerModule {}