import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorService } from '../utilities/services/error.service';
import { EventRegistrationNonMember } from 'app/utilities/classes/event/event-registration-non-member';
import { NonMemberService } from 'app/utilities/services/nonMember.service';
import { EventNonMember } from 'app/utilities/classes/event/event-non-member';

@Component({
  selector: 'app-non-members-event-registration-form',
  templateUrl: './non-members-event-registration-form.component.html',
  styleUrls: ['./non-members-event-registration-form.component.scss'],
})

export class NonMembersEventRegistrationFormComponent implements OnInit {

  public eventRegistration: FormGroup;
  public eventsList: EventNonMember []
  public submitted: boolean = false;
  public registered: boolean = false;
  public registrationError: boolean = false;
  public isDelegateAlreadyMember: boolean = false
  public selectedEvent: EventNonMember;
  public isOnline: boolean = true;

  constructor(
    private NonMemberService: NonMemberService,
    public fb: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {    
    this.getEventsList()
    this.buildEventForm()
  }

  navigateToMemberEventsPage(): void {
    this.router.navigate(['/events'])
  }

  getEventsList() {
    this.NonMemberService.getEventsList().subscribe(
      s => { 
        this.eventsList = s.data
        this.eventsList.forEach(e => e['event_name_date'] = e.event_name + ' ' + this.formatDate(e.event_dt))
      },
      e => {
        console.log(e)      
      }
    );
  }

  formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const year = String(date.getUTCFullYear()).slice(-2);     
    const hours = date.getUTCHours() % 12 || 12;     
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');     
    const ampm = date.getUTCHours() >= 12 ? 'PM' : 'AM';     
    const formattedDate = `(${day}/${month}/${year} - ${hours}:${minutes}${ampm})`;     
    return formattedDate;   
  }

  registerEvent() { 
    this.submitted = true;
    let data = this.eventRegistration.value;
    let eventDetails: EventRegistrationNonMember = {
      eventId: data.eventId,
      delegateName: data.delegateName,
      delegateEmail: data.delegateEmail,
      jobTitle: data.delegateJob,
      organisation: data.organisation,
      delegateList: data.delegateList,
      comment: data.delegateComments,
      twitterHandle: '',
      dietaryRequirements:data.delegateDiet,
      dietaryDetails:data.delegateAllergies,
      delegateBadge: '',
      accessRequirements:data.delegateAccess
    }
    this.NonMemberService.RegisterEvent(eventDetails).subscribe(
      s => {
        this.registered = true;        
      },
      e => {
        console.log(e)
        this.isDelegateAlreadyMember = e.error.error.message == 'User is a member' ? true : false
        this.registrationError = true;        
      }
    );
  }

  hideModal() {
    this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
  }

  buildEventForm() {
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;    
    this.eventRegistration = this.fb.group({
      "eventId":["", [Validators.required]],
      "organisation":["",[Validators.required, Validators.maxLength(60)]],
      "delegateName":["", [Validators.required, Validators.maxLength(60)]],
      "delegateEmail":["", [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(60)]],
      "delegateJob":["", [Validators.required, Validators.maxLength(60)]],
      "delegateComments":[""],
      "delegateDiet":[""],
      "delegateAllergies":["", Validators.maxLength(100)],
      "delegateAccess":["", [Validators.maxLength(100)]],
      "delegateList":[""]
    })
    this.eventRegistration.controls['delegateDiet'].setValue('N');
    this.eventRegistration.controls['delegateList'].setValue('Y');
    this.eventRegistration.controls['eventId'].setValue('none');
    this.eventRegistration.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); 
  }

  onValueChanged(data?: any) {
    if (!this.eventRegistration) { return; }
    const form = this.eventRegistration;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  selectEvent(eventString: string) {
    const eventId = +eventString.split(':')[1].trim() 
    this.selectedEvent = this.eventsList.find(e => e.event_id == eventId)
    this.isOnline = this.selectedEvent.is_online === 'Y' ? true : false
  }

  formErrors = {
    'organisation': '',
    'delegateName': '',
    'delegateEmail': '',
    'delegateJob': '',
    'delegateDiet': '',
    'delegateAllergies': '',
    'delegateAccess': '',
  }

  validationMessages = {
    'organisation': {
      'required': 'Organisation Name is required.',
      'maxlength': 'Organisation Name cannot be longer than 60 characters.'
    },
    'delegateName': {
      'required': 'Delegate Name is required.',
      'maxlength': 'Delegate Name cannot be longer than 60 characters.'
    },
    'delegateEmail': {
      'required': 'Delegate Email Address is required.',
      'maxlength': 'Delegate Email Address cannot be longer than 60 characters.',
      'pattern': 'Delegate Email Address is not valid.'
    },
    'delegateJob': {
      'required': 'Job Title is required.',
      'maxlength': 'Job Title cannot be longer than 60 characters.'
    },
    'delegateDiet': {
      'required': 'Diet selection required.'
    },
    'delegateAllergies': {
      'maxlength': 'Allergy Information cannot be longer than 100 characters.'
    },
    'delegateAccess': {
      'maxlength': 'Access Information cannot be longer than 100 characters.'
    }   
  }

}