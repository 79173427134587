import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class OutputValidationService {
  private nhsApiUrl: string = environment.apiURL;
  private token: string = localStorage.getItem('id_token');

  constructor(private http: HttpClient) { }

  public getProject(projectId: number, year: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'projects/list/' + projectId.toString(), {
        headers: new HttpHeaders().set('Authorization', this.token),
        params: new HttpParams().set('year', year.toString())
      }
    );
  }

  public getProjectTiers(projectId: number, year: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'outputs/tiers', {
        headers: new HttpHeaders().set('Authorization', this.token),
        params: new HttpParams().set('projectId', projectId.toString()).set('year', year.toString())
      }
    )
  }

  public getValidations(projectId: number, year: number, organisationId?: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'validation/getValidations', {
        headers: new HttpHeaders().set('Authorization', this.token),
        params: new HttpParams().set('projectId', projectId.toString()).set('year', year.toString()).set('organisationId', organisationId.toString())
      }
    )
  }

  public getValidationsOld(projectId: number, year: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'validation/getValidations', {
        headers: new HttpHeaders().set('Authorization', this.token),
        params: new HttpParams().set('projectId', projectId.toString()).set('year', year.toString())
      }
    )
  }

  public getTier(
    reportId: number,
    organisationId: number,
    peerGroup: number,
    year: number,
    submissionLevel?: string,
    serviceItemId?: number,
    optionId?: number,
  ): Observable<any> {
    let params: HttpParams = new HttpParams();

    // Mandatory params
    params = params
      .set("organisationId", organisationId.toString())
      .set("peerGroup", peerGroup.toString())
      .set("year", year.toString());

    // Optional params
    if (submissionLevel) { params = params.append("submissionLevel", submissionLevel.toString()) }
    if (serviceItemId) { params = params.append("serviceItemId", serviceItemId.toString()) }
    if (optionId) { params = params.append("denominatorOptionId", optionId.toString()); }

    return this.http.get(this.nhsApiUrl + "outputs/" + reportId + "/data", {
      headers: new HttpHeaders().set("Authorization", this.token),
      params: params
    });
  }

  public postValidation(validation): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/addValidation', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', this.token).set('Content-Type', 'application/json')
      }
    );
  }

  public getValidationResponses(reportId, year, submissionId, serviceItemId): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'validation/getValidationResponses', {
        headers: new HttpHeaders().set('Authorization', this.token),
        params: new HttpParams()
          .set('reportId', reportId.toString())
          .set('year', year.toString())
          .set('submissionId', submissionId.toString())
          .set('serviceItemId', serviceItemId.toString())
      }
    );
  }

  public addValidationResponse(validationResponse): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/addValidationResponse', JSON.stringify(validationResponse), {
        headers: new HttpHeaders().set('Authorization', this.token).set('Content-Type', 'application/json')
      }
    );
  }

  public deleteValidation(validationId: number): Observable<any> {
    return this.http.delete(
      this.nhsApiUrl + 'validation/deleteValidation', {
        headers: new HttpHeaders().set('Authorization', this.token),
        params: new HttpParams().set('validationId', validationId.toString())
      }
    );
  }  

  public editUserValidationNote(note: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + '/validation/editUserValidationNote', JSON.stringify(note), {
        headers: new HttpHeaders().set('Authorization', this.token).set('Content-Type', 'application/json')
      }
    );
  }

  public editValidationStatus(validation: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/changeValidationStatus', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', this.token).set('Content-Type', 'application/json')
      }
    );
  }

  public editValidationNote(validation: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/editValidationNote', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', this.token).set('Content-Type', 'application/json')
      }
    );
  }

  public editValidationDescription(validation: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/editValidationDescription', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', this.token).set('Content-Type', 'application/json')
      }
    );
  }

}