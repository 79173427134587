import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OutputsComponent } from './outputs.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const outputsRoutes: Routes = [
  { path: 'outputs/:projectId', component: OutputsComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [
    RouterModule.forChild(outputsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class OutputsRoutingModule { }