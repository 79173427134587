import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";

import { ModalModule } from "ngx-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { ResponsiveModule } from "ngx-responsive";

import { LoginComponent } from "./login.component";
import { LoginRoutingModule } from "./login-routing.module";

import { OrgSearchPipe } from "../utilities/pipes/org.search.pipe";
import { Angular2PromiseButtonModule } from "angular2-promise-buttons/dist";

import { FieldErrorDisplayComponent } from "./login-errors/field-error-display.component";
import { NgPipesModule } from "ngx-pipes";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    LoginRoutingModule,
    ResponsiveModule.forRoot(),
    HttpModule,
    HttpClientModule,
    NgPipesModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fa fa-spinner fa-spin"></span>',
      disableBtn: true,
      btnLoadingClass: "is-loading",
      handleCurrentBtnOnly: false,
    }),
  ],
  declarations: [OrgSearchPipe, LoginComponent, FieldErrorDisplayComponent],
})
export class LoginModule {}
