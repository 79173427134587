import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PopupModalComponent } from "./popup-modal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [PopupModalComponent],
  exports: [PopupModalComponent]
})
export class PopupModalModule {}