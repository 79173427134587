import { Component, OnInit, ViewEncapsulation, HostListener, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';

import { QuestionService } from '../utilities/services/question.service';
import { QuestionControlService } from '../utilities/services/question-control.service';
import { MemberService } from '../utilities/services/member.service';
import { LoaderService } from '../utilities/services/loader.service';

import { UniquePipe } from 'ngx-pipes';
import { Constants } from 'app/utilities/constants/constants';

@Component({
  templateUrl: './data-collection.component.html',
  styleUrls: ['./data-collection.component.scss'],
  encapsulation: ViewEncapsulation.None, // Button spinner (child-elements)
})
export class DataCollectionComponent implements OnInit {
  questions; contextQuestions;
  submissionDetails; currentQuestionGroup;
  outerForm: FormGroup;
  windowHeight: number = window.innerHeight - 300;

  type: string; submissionId: number; projectId: number; year: number; serviceItemId: number; 
  caseNoteReviewCode: string; selectedCaseCodeId: number; caseNoteParam: boolean;
  cnr = []; page; year_half;
  questionGroups: any;
  questionTotal = []; questionTotalCount: number;

  dataReliabilityCode: boolean;
  nacelError: boolean = false;
  nacelErrors = [];

  qualificationAnswered: boolean = false;
  peerGroupId; conditionTypeId;
  parentQuestions = [];

  currentQuestionGroupSequence;
  upperQuestionGroups;
  lowerQuestionGroups;
  questionsOpen: boolean;
  noQuestions: boolean = false;

  linkedQuestion;

  // Question edit (Active Admin)
  adminOnly: boolean = false;
  isAdmin: boolean;
  questionForm: FormGroup;
  editingQuestionSelection: any;
  editingQuestion: boolean = false;
  modifierType: string;
  placeholderHelp: boolean = false;
  placeholders = Constants.placeholders;
  shortYear: number;
  copiedMessage: string;
  questionsEdited: number = 0;
  updatedOuterForm: boolean = false;

  metaTitle: string = 'Data Collection | NHS Benchmarking Network';

  processLoading: boolean = false;

  constructor(
    private memberService: MemberService,
    private route: ActivatedRoute,
    private router: Router,
    private questionService: QuestionService,
    private questionControlService: QuestionControlService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public unique: UniquePipe,
    private newSpinner: Angular2PromiseButtonModule, // Button spinner
    public loaderService: LoaderService, // Loader
    private httpService: HttpClient, // Loader
    private titleService: Title, // Set page titles
    private meta: Meta,
  ) {
    this.type = this.route.snapshot.paramMap.get('type');
    this.year = +this.route.snapshot.paramMap.get('year');
    this.submissionId = +this.route.snapshot.paramMap.get('submissionId');
    this.page = this.route.snapshot.queryParams['page'];
    this.year_half = this.route.snapshot.queryParams['year_half'];

    if (this.route.snapshot.paramMap.get('serviceItemId')) {
      this.serviceItemId = +this.route.snapshot.paramMap.get('serviceItemId');
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.getSubmissionDetails();
  }

  getSubmissionDetails() {
    this.memberService.SubmissionDetails(this.submissionId, this.serviceItemId).subscribe(
      d => {
        this.submissionDetails = d.data;
        this.projectId = d.data.projectId;

        if (this.type == 'd') {
          this.type = 'c'
          this.dataReliabilityCode = true;
        }
        
        this.getQuestionGroups(d.data.projectId, this.year, this.type, this.submissionId, this.serviceItemId);
      },
      e => {
        if (e.error.error.message.includes('does not have sufficient roles')) {
          this.toastr.error('You do not have sufficient permissions to respond to this data collection.');
          this.router.navigate(['/home']);
        };
      }
    );
  }

  getQuestionGroups(projectId: number, year: number, type: string, submissionId?: number, serviceItemId?: number) {
    this.memberService.QuestionGroups(projectId, year, type, submissionId, serviceItemId).subscribe(
      d => {
        let peerGroupId = this.submissionDetails.registrationPeerGroupId ? this.submissionDetails.registrationPeerGroupId : null;
        let allGroups = d.data;
        let questionGroups = [];
        allGroups.forEach(g => {
          // Condition Type 7: Remove question groups based on peer group ID
          if (g.conditionType !== 7 || (g.conditionType === 7 && peerGroupId >= g.conditionMin && peerGroupId <= g.conditionMax)) {
            questionGroups.push(g)
          }
          // Condition Type 8: Remove question groups based on organisation type ID
          let organisationTypeId = this.submissionDetails.organisationTypeId || null;
          if (g.conditionType == 8 && organisationTypeId !== g.conditionMin) {
            questionGroups = questionGroups.filter(grp => grp.conditionType !== 8);
          }
        });

        // Set question groups and remove any that should not show
        this.questionGroups = questionGroups.filter(q => q.conditionValue !== 'N');

        if (this.questionGroups.length) {
          this.currentQuestionGroup = this.questionGroups[0].groupId;
          this.currentQuestionGroupSequence = this.questionGroups[0].displaySequence;
          this.questionsOpen = true;
  
          if (type == 'cac' || type == 'cmh') {
            this.getCNR(projectId, submissionId, type);
          }
          else {
            // Set group if in param
            if (this.route.snapshot.queryParams['group']) {
              let currentGroup = +this.route.snapshot.queryParams['group'];
              this.route.fragment.subscribe((fragment: string) => {
                this.linkedQuestion = fragment;
              })
              // Checks group exists in questionGroups
              if (this.questionGroups.map(q => q.groupId).indexOf(currentGroup) > -1) {
                this.currentQuestionGroup = currentGroup;
                this.currentQuestionGroupSequence = this.questionGroups.filter(g => g.groupId === currentGroup)[0].displaySequence;
                this.getQuestions(projectId, submissionId, currentGroup, serviceItemId);
              } else {
                this.toastr.error('This question group does not exist.');
                this.router.navigate(['/project-overview', projectId], { queryParams: { submission: this.submissionId, service: this.serviceItemId, page: this.page } });
              }
            } else {
              this.currentQuestionGroupSequence = this.questionGroups[0].displaySequence;
              this.getQuestions(projectId, submissionId, this.questionGroups[0].groupId, serviceItemId);
            }
          }
  
          // Set upper and lower groups for ordering
          this.upperQuestionGroups = this.questionGroups.filter(g => g.displaySequence <= this.currentQuestionGroupSequence);
          this.lowerQuestionGroups = this.questionGroups.filter(g => g.displaySequence > this.currentQuestionGroupSequence);
        } else {
          this.noQuestions = true;
        }

      },
      error => {
        console.log(error);
      }
    );
  }

  getCNR(projectId: number, submissionId: number, type: string) {
    this.cnr = [];
    this.memberService.CNRCodes(submissionId).subscribe(
      d => {
        if (!this.dataReliabilityCode) {
          this.cnr = d.data.caseNoteCodes;
        }
        else {
          this.cnr = d.data.reliabilityCaseCodes;
        }

        if (this.route.snapshot.queryParams['case']) {
          this.caseNoteReviewCode = this.route.snapshot.queryParams['case'];
          this.caseNoteParam = true;
        } else {
          this.caseNoteReviewCode = this.cnr[0].caseCode;
        }

        this.selectedCaseCodeId = this.cnr.filter(c => c.caseCode === this.caseNoteReviewCode)[0].caseCodeId;

        // Set group if in param
        if (this.route.snapshot.queryParams['group']) {
          let currentGroup = this.route.snapshot.queryParams['group'];
          this.currentQuestionGroup = currentGroup;
          this.getQuestions(projectId, submissionId, currentGroup, null, this.caseNoteReviewCode);
        } else {
          this.getQuestions(projectId, submissionId, this.questionGroups[0].groupId, this.serviceItemId, this.caseNoteReviewCode);
        }
      }
    )
  }

  getQuestions(projectId: number, submissionId: number, groupId: number, serviceItemId?: number, caseNoteReviewCode?: string) {
    this.memberService.Questions(projectId, submissionId, groupId, serviceItemId, caseNoteReviewCode).subscribe(
      d => {
        this.questions = this.questionService.getQuestionDetails(d.data);
        this.contextQuestions = d.data.questions.map(q => { return { questionId: q.questionId, questionText: q.questionText } });

        // Active Admin overrides
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        this.shortYear = +this.year.toString().slice(-2);

        this.memberService.User().subscribe(
          success => {
            let user = success.data;

            // Question editing (current year)
            if (user) {
              if ((this.year === currentYear || this.year + 1 === currentYear) && user.isAdmin === 'Y') {
                this.isAdmin = true;
              } else {
                this.isAdmin = false
              }
            }

            // let calculatedMonth = new Date().getMonth();

            // Read-only override
            let readOnly
            // if(this.year_half == 1 && calculatedMonth <= 5 && this.projectId == 34) {
            //   readOnly = 'N';
            //   d.data.readOnly = readOnly;
            // } else if(this.year_half == 2 && calculatedMonth > 5 && this.projectId == 34) {
            //   readOnly = 'N';
            //   d.data.readOnly = readOnly;
            // } else if(this.projectId == 34 && this.year_half) {
            //   readOnly = 'Y';
            //   d.data.readOnly = readOnly;
            // } else {
            //   readOnly = d.data.readOnly;
            // }

            readOnly = d.data.readOnly;


            if (readOnly === 'Y' && user.isAdmin === 'Y') {
              this.adminOnly = true;
            } else {
              this.adminOnly = false
            }

          },
          error => {
            console.log(error);
          },
          () => {
            if (this.type === 'h') {
              let qualification = this.questions.question.filter(q => q.questionResponseType);
              let noQuestions = this.questions.questionCount;
              let noResponses = qualification.filter(q => q.responses[0].value).length;
    
              if (noQuestions === noResponses) {
                // All qualifications questions ('H') answered? If so, disable
                this.qualificationAnswered = true;
                this.outerForm = this.questionControlService.toFormGroup(this.questions.question, 'Y');
              } 
              else {
                this.outerForm = this.questionControlService.toFormGroup(this.questions.question, d.data.readOnly);
              }
            }
            else {
              this.outerForm = this.questionControlService.toFormGroup(this.questions.question, d.data.readOnly);
              this.questionsOpen = true;
              // this.scrollToElement(this.currentQuestionGroup);
            }
    
            // NACEL date/time validation
            let currentQuestionGroup = this.questionGroups.filter(g => g.groupId == this.currentQuestionGroup)[0];
            if (currentQuestionGroup && currentQuestionGroup.conditionType) {
              this.conditionTypeId = currentQuestionGroup.conditionType;
            } else {
              this.conditionTypeId = 0;
            }
            if (this.conditionTypeId === 6) {
              this.peerGroupId = this.submissionDetails.registrationPeerGroupId;
            }
            this.updatedOuterForm = true;
          }
        )
      },
      error => {
        console.log(error);
      }
    );
  }

  saveQuestionData(questionData, submissionId: number, serviceItemId: number, caseNoteReviewCode?: string) {
    this.memberService.SaveQuestionData(questionData, submissionId, serviceItemId, caseNoteReviewCode).subscribe(
      s => {
        if (s["success"]) {
          //this.toastr.success('Data has saved successfully', 'Data Collection');
          this.outerForm.markAsPristine();
        }
        this.processLoading = false;
      }, 
      e => {
        this.toastr.error('There has been an issue trying to save the data, please contact the project co-ordinator. (In the help button at the top left of the screen.)', 'Data Collection');
        this.processLoading = false;
      }
    );
  }

  changeGroup(groupId: number, groupName: string) {
    this.nacelErrors = [];
    this.parentQuestions = [];
    this.updatedOuterForm = false;
    if (this.outerForm.dirty) {
      if (confirm("You may have unsaved changes. Click 'OK' if you are happy to continue without saving.")) {
        this.loadQuestions(groupId)
      }
    } else {
      this.loadQuestions(groupId)
    }
    // Set upper and lower groups for ordering
    this.questionsOpen = true;
    this.currentQuestionGroupSequence = this.questionGroups.filter(g => g.groupId === groupId)[0].displaySequence;
    this.upperQuestionGroups = this.questionGroups.filter(g => g.displaySequence <= this.currentQuestionGroupSequence);
    this.lowerQuestionGroups = this.questionGroups.filter(g => g.displaySequence > this.currentQuestionGroupSequence);
  }

  loadQuestions(groupId: number) {
    this.currentQuestionGroup = groupId;
    this.questions = [];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { group: groupId }, queryParamsHandling: 'merge'
    });
    this.getQuestions(this.projectId, this.submissionId, groupId, this.serviceItemId, this.caseNoteReviewCode);
  }

  cnrSelectionChange(caseCodeId: number) {
    this.selectedCaseCodeId = caseCodeId;
    this.caseNoteReviewCode = this.cnr.find(c => c.caseCodeId == caseCodeId).caseCode;
    this.currentQuestionGroup = this.questionGroups[0].groupName;
    this.questions = [];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { case: this.caseNoteReviewCode }, queryParamsHandling: 'merge'
    });
    this.getQuestions(this.projectId, this.submissionId, this.questionGroups[0].groupId, null, this.caseNoteReviewCode);
  }

  navProjectOverview(projectId) {
    if (this.outerForm && this.outerForm.dirty) {
      if (confirm("You may have unsaved changes. Click 'OK' if you are happy to continue without saving.")) {
        this.router.navigate(['/project-overview', projectId], { queryParams: { submission: this.submissionId, service: this.serviceItemId, page: this.page } });
      }
    }
    else {
      this.router.navigate(['/project-overview', projectId], { queryParams: { submission: this.submissionId, service: this.serviceItemId, page: this.page } });
    }
  }
  
  questionCounts(question: string) {
    let questionParts = question.split('.');

    if (questionParts[1] == 'add' && this.questionTotal.indexOf(questionParts[0]) == -1) {
      this.questionTotal.push(questionParts[0]);
    }
    else if (questionParts[1] == 'remove' && this.questionTotal.indexOf(questionParts[0]) != -1) {
      this.questionTotal.splice(this.questionTotal.indexOf(questionParts[0]), 1);
    }
    else if (questionParts[1] == 'reset') {
      this.questionTotal = [];
    }

    this.questionCount();
  }

  questionCount() {
    let uniqueList: Set<string> = new Set(this.questionTotal.map(a => a.split('-')[0]));

    this.questionTotalCount = uniqueList.size;

    // Counts how many changes have been made to the inputs
    if(this.outerForm.dirty) {
      this.questionsEdited++;
    }   

    //  Auto-save when the User has made 100 changes to the questions.
    if(this.questionsEdited == 100 && this.outerForm.dirty) {
      this.onSubmit();
      this.questionsEdited = 0;
      this.toastr.success("Progress has been saved.");
    }
  }

  questionTotals() {
    this.questions.questionCount--;
  }

  datesTimesError(event, message) {
    this.nacelError = event;
  }

  nacelValidations(event) {
    this.nacelErrors = event;
  }

  parentChange(event) {
    let questionId = event.question.questionId,
        questionText = event.question.questionText,
        serviceItemId = this.serviceItemId || 0,
        caseCodeId;
        
    if (this.selectedCaseCodeId) {
      caseCodeId = this.selectedCaseCodeId
    }

    if (event.existingValue !== event.changedValue) {
      this.memberService.childResponseCount(questionId, this.submissionId, serviceItemId, caseCodeId).subscribe(
        r => { 
          if (r.data.parentQuestions[0] && r.data.parentQuestions[0].responseCount > 0) {
            this.parentQuestions.push({
              questionId: questionId,
              questionText: questionText
            });
            this.parentQuestions = this.unique.transform(this.parentQuestions);
          }
        },
        e => { 
          console.log(e) 
        }
      )
    } else if (event.existingValue === event.changedValue) {
     this.parentQuestions = this.parentQuestions.filter(p => p.questionText !== questionText); 
    }
  }

  onSubmit() {
    if(this.processLoading) return;

    this.processLoading = true;
    let saveData = [];

    Object.keys(this.outerForm.controls).forEach(key => {
      let currentControl = this.outerForm.controls[key];

      if (currentControl.dirty) {
        let detail = key.split('-');

        //Remove time from date to prevent issues with locality
        if (detail[2] == 'date') {
          let date = currentControl.value;
          let dateArray = date ? date.toString().split(" ") : null;
          let dateValue = date ? (dateArray[2] + " " + dateArray[1] + " " + dateArray[3]) : null;       

          saveData.push({ questionId: detail[0], questionPart: detail[1], questionType: detail[2], value: dateValue })
        }
        else {
          saveData.push({ questionId: detail[0], questionPart: detail[1], questionType: detail[2], value: currentControl.value })
        }        
      }
    });

    if (saveData.length > 0) { 
      this.saveQuestionData(saveData, this.submissionId, this.serviceItemId, this.caseNoteReviewCode);
    }

    // Purge condition type 2 responses
    let questionData = {
      parentIds: this.parentQuestions.map(p => p.questionId),
      submissionId: this.submissionId,
      serviceItemId: this.serviceItemId || 0
    }

    if (this.selectedCaseCodeId) {
      questionData['caseCodeId'] = this.selectedCaseCodeId;
    }

    if (this.parentQuestions.length) {
      this.memberService.purgeResponsesForParent(questionData).subscribe(
        r => { console.log('Responses to conditional questions have been removed.') },
        e => { console.log(e) }
      )
    }

    this.parentQuestions = [];
    
    // New spinner
    this.newSpinner = new Promise((resolve, reject) => {
      setTimeout(resolve, 2000);
    });
  }

  scrollToElement(element) {
    let group = document.getElementById(this.currentQuestionGroup);
    group.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
  }

  closeGroup() {
    this.questionsOpen = false;
    this.currentQuestionGroup = null;
    this.currentQuestionGroupSequence = null;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { group: null }, queryParamsHandling: 'merge'
    });
  }

  // Question editing (Active Admin)

  editQuestion(event) {
    this.modifierType = event.modifierType;
    this.buildQuestionEditForm(event.modifierType);
    
    if (event.modifierType.includes('popup')) {
      this.questionForm.setValue({
        questionId: event.questionId,
        questionText: event.rawQuestionText,
        questionHelp: event.questionHelp,
        rangeMin: event.rangeMin || null,
        rangeMax: event.rangeMax || null,
        decPlaces: event.decimalPlaces || null,
        formatModifier: event.formatModifier,
        parentQuestionId: event.parentQuestionId || "",
        dataDefinition: event.dataDefinition || ""
      });
    } else {
      this.questionForm.setValue({
        questionId: event.questionId,
        questionPrefix: event.questionPrefix,
        questionText: event.rawQuestionText,
        questionHelp: event.questionHelp,
        rangeMin: event.rangeMin || null,
        rangeMax: event.rangeMax || null,
        decPlaces: event.decimalPlaces || null,
        formatModifier: event.formatModifier,
        parentQuestionId: event.parentQuestionId || "",
        dataDefinition: event.dataDefinition || ""
      });
    }

    this.editingQuestionSelection = event;
    this.editingQuestion = true;
  }

  buildQuestionEditForm(type) {
    if (type.includes('popup')) {
      this.questionForm = this.fb.group({    
        questionId: ['', []],
        questionText: ['', []],
        questionHelp: ['', []],
        rangeMin: ['', []],
        rangeMax: ['', []],
        decPlaces: ['', []],
        formatModifier: ['', []],
        parentQuestionId: ['', []],
        dataDefinition: ['', []]
      });
    } else {
      this.questionForm = this.fb.group({    
        questionId: ['', []],
        questionPrefix: ['', []],
        questionText: ['', []],
        questionHelp: ['', []],
        rangeMin: ['', []],
        rangeMax: ['', []],
        decPlaces: ['', []],
        formatModifier: ['', []],
        parentQuestionId: ['', []],
        dataDefinition: ['', []]
        // showHeadings: ['', []],
      });
    }
  }

  submitForm() {
    
    let parentId;
    if (this.questionForm.value.parentQuestionId === "") {
      parentId = null;
    } else {
      parentId = this.questionForm.value.parentQuestionId;
    }

    let newQuestion = {
      questionId: this.questionForm.value.questionId,
      questionPrefix: this.questionForm.value.questionPrefix,
      questionText: this.questionForm.value.questionText,
      questionHelp: this.questionForm.value.questionHelp,
      rangeMin: this.questionForm.value.rangeMin,
      rangeMax: this.questionForm.value.rangeMax,
      decPlaces: this.questionForm.value.decPlaces,
      formatModifier: this.questionForm.value.formatModifier,
      parentQuestionId: parentId,
      dataDefinition: this.questionForm.value.dataDefinition
    };
    this.memberService.modifyQuestion(newQuestion).subscribe(
      r => {
        this.editingQuestion = false;
        this.loadQuestions(this.currentQuestionGroup);
        this.toastr.success("The question details have been successfully updated.");
      },
      e => {
        console.log(e);
        this.toastr.error("There has been an error whilst trying to edit the question details. Please discuss with IT Team.");
      }
    );
  }

  copyToClipboard(id: string) {
    var range = document.createRange();
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.copiedMessage = 'Copied to clipboard'
  }

}