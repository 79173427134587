import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ResponsiveModule } from "ngx-responsive";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { MembershipComponent } from "./membership.component";
import { MembershipRoutingModule } from "./membership-routing.module";

import { AuthService } from "../utilities/services/auth.service";
import { AuthGuardService } from "../utilities/services/auth-guard.service";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    ResponsiveModule,
    MembershipRoutingModule,
    FormsModule,
    TabsModule,
    TooltipModule,
  ],
  declarations: [MembershipComponent],
  providers: [AuthService, AuthGuardService],
})
export class MembershipModule {}
