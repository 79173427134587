import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MembershipComponent } from './membership.component'

import { AuthGuardService } from '../utilities/services/auth-guard.service'

const membershipRoutes: Routes = [
  { path: 'membership', component: MembershipComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    RouterModule.forChild(membershipRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class MembershipRoutingModule { }