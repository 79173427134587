import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title, DOCUMENT } from '@angular/platform-browser';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { UniquePipe, FlattenPipe } from 'ngx-pipes';

import { WINDOW } from "../utilities/services/window.service";
import { KenService } from '../utilities/services/ken.service';
import { DataService } from '../utilities/services/data.service';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {

  requests; filteredRequests;
  projects; allProjects; sectors; selectedProjects = [];
  tags;
  submitEmail = "mailto:a.wright31@nhs.net?subject=Knowledge%20Exchange%20Forum&body=Name:%0AOrganisation:%0AJob title:%0AQuery:"
  filter;
  hideShowProject: boolean = false;
  hideShowSector: boolean = false;
  hideShowTag: boolean = false;
  expanded: boolean = true;

  allCategories = [
    { id: 1, name: "Community" },
    { id: 2, name: "Acute" },
    { id: 3, name: "Mental Health" },
    { id: 4, name: "Commissioning" },
    { id: 5, name: "All Sectors" },
    { id: 6, name: "NAIC" },
    { id: 7, name: "NACEL" },
    { id: 8, name: "NHSE & NHSI - Learning Disabilities" }
  ]

  // Admin
  approvedUsers = [18546, 18572, 12335, 16676, 17986, 23115, 32175, 35407];
  currentUserApproved: boolean = false;
  request: boolean = false;
  selectedRequest;
  editingRequest: boolean = false;
  requestForm: FormGroup;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '10rem',
    translate: 'no'
  };

  metaTitle: string = 'Knowledge Exchange Forum | NHS Benchmarking Network';

  public navIsFixed: boolean = false;
  public width: number;

  constructor(
    public unique: UniquePipe,
    public flatten: FlattenPipe,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private kenService: KenService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});

    this.getRequests();
    this.checkUser();
  }

  checkUser() {
    let currentUser = this.dataService.getUser().userId;
    if (this.approvedUsers.indexOf(currentUser) !== -1) {
      this.currentUserApproved = true;
    }
  }

  getProjects() {
    this.kenService.getProjects().subscribe(
      r => {
        let allLiveProjects = r.data.filter(d => d.isVisible === 'Y' || d.projectId == 50);
        this.allProjects = allLiveProjects.map(d => { return { id: d.projectId, name: d.projectName }});
      }
    )
  }

  getRequests() {
    this.kenService.getRequests().subscribe(
      r => {

        this.requests = this.cleanRequests(r.data);

        this.projects = this.setProjects(this.requests);
        this.tags = this.setTags(this.requests);
        this.sectors = this.unique.transform(this.requests.map(r => r.sectorName));

        if (this.route.snapshot.queryParams['project']) {
          this.filter = this.route.snapshot.queryParams['project'];
          this.filteredRequests = this.requests.filter(r => r.projectName.includes(this.filter))
        } 
        else if (this.route.snapshot.queryParams['sector']) {
          this.filter = this.route.snapshot.queryParams['sector'];
          this.filteredRequests = this.requests.filter(r => r.sectorName === this.filter);
        } 
        else if (this.route.snapshot.queryParams['tag']) {
          this.filter = this.route.snapshot.queryParams['tag'];
          this.filteredRequests = this.requests.filter(r => r.requestTags.includes(this.filter));
        } 
        else {
          this.filteredRequests = this.requests;
        }

        if (this.filteredRequests.length == 0 && this.route.snapshot.queryParams['ref'] === 'dashboard') {
          this.clearFilter()
        }

      },
      e => { 
        console.log(e) 
      }
    );
  }

  setProjects(requests) {
    let allProjects = requests.map(r => r.projectName);
    allProjects = this.unique.transform(allProjects);
    allProjects = this.flatten.transform(allProjects);
    return allProjects;
  }

  setTags(requests) {
    let allTags = requests.map(r => r.requestTags);
    allTags = allTags.filter(t => t !== '');
    allTags = this.unique.transform(allTags);
    allTags = this.flatten.transform(allTags);
    return allTags;
  }

  selectProject(index, projectId) {
    if (this.selectedProjects.map(p => p.index).includes(index)) {
      this.selectedProjects = this.selectedProjects.filter(p => p.index !== index);
    }
    this.selectedProjects.push({ index: index, projectId: projectId });
    this.selectedProjects = this.unique.transform(this.selectedProjects.filter(p => p.projectId !== "0"), 'projectId');
  }

  cleanRequests(data) {
    // Retain only one serviceItemId
    let cleanedRequests = [];


    data.forEach((e) => {

      let requestTags;
      if (e.requestTags === null) {
        requestTags = '';
      } else {
        requestTags = e.requestTags.split(', ');
      }

      if (e.isVisible === 'Y') {
        cleanedRequests.push({
          kenRequestId: e.kenRequestId,
          projectId: e.projects.map(p => p.projectId),
          projectName: e.projects.map(p => p.projectName),
          allProjects: e.linkAllProjects,
          requestBody: e.requestBody,
          requestDate: e.requestDate,
          requestMonth: this.datePipe.transform(e.requestDate, 'MMMM y'),
          requestTitle: e.requestTitle,
          commentCount: e.commentCount,
          sectorName: e.sectorName,
          requestTags: requestTags,
          categoryId: e.sectorId,
          submittedBy: e.submittedBy
        });
      }
    });

    // If allProjects true, add 'All Projects' to projectName
    cleanedRequests.forEach(c => {
      if (c.allProjects === 'Y') {
        c.projectName.push('All Projects');
      }
    })

    return cleanedRequests
  }

  setRequest(state) {
    window.scrollTo(0,0);
    this.request = state;
    if (state == true) {
      this.getProjects();
      this.buildRequestForm();
    }
  }

  editRequest(requestId) {
    window.scrollTo(0,0);
    this.editingRequest = true;
    this.getProjects();
    this.buildRequestForm();
    this.selectedRequest = this.requests.filter(r => r.kenRequestId === requestId)[0];
    this.requestForm.setValue({
      requestTitle: this.selectedRequest.requestTitle,
      requestBody: this.selectedRequest.requestBody,
      requestDate: new Date(this.selectedRequest.requestDate).toISOString().slice(0,10),
      submittedBy: this.selectedRequest.submittedBy,
      categoryId: this.selectedRequest.categoryId,
      linkAllProjects: this.selectedRequest.allProjects === 'Y' ? 1 : 0, 
      requestTags: this.selectedRequest.requestTags.length ? this.selectedRequest.requestTags.join(', ') : '',
      projectId1: this.selectedRequest.projectId[0] || 0,
      projectId2: this.selectedRequest.projectId[1] || 0,
      projectId3: this.selectedRequest.projectId[2] || 0
    });
  }

  buildRequestForm() {
    this.requestForm = this.fb.group({    
      //projectId: ['', [Validators.required, Validators.maxLength(5)]],
      requestTitle: ['', [Validators.required, Validators.maxLength(200)]],
      requestBody: ['', [Validators.required]],
      requestDate: ['', [Validators.required]],
      submittedBy: ['', [Validators.required]],
      categoryId: ['', []],
      linkAllProjects: ['', []],
      requestTags: ['', []],
      projectId1: ['', []],
      projectId2: ['', []],
      projectId3: ['', []]
    });
  }

  submitRequestForm(requestId?) {
    if (this.requestForm.value.requestTitle) {
      let request = { 
        //projectId: this.requestForm.value.projectId,
        requestTitle: this.requestForm.value.requestTitle,
        requestBody: this.requestForm.value.requestBody,
        requestDate: this.requestForm.value.requestDate,
        submittedBy: this.requestForm.value.submittedBy,
        sectorId: this.requestForm.value.categoryId,
        linkAllProjects: this.requestForm.value.linkAllProjects ? 'Y' : 'N',
        requestTags: this.requestForm.value.requestTags
      };
      if (requestId) {
        this.submitEditedRequestForm(requestId, request);
      } else {
        this.addRequest(request);
      }
    }
  }

  submitEditedRequestForm(requestId, requestDetails) {
    // Remove projectIds, if exist
    if (this.selectedRequest.projectId.length > 0) {
      this.removeProjects(this.selectedRequest.kenRequestId, this.selectedRequest.projectId);
    }
    this.kenService.editRequest(requestId, requestDetails).subscribe(
      r => {
        this.submitProjects(this.selectedRequest.kenRequestId, this.selectedProjects.map(p => p.projectId));
        this.toastr.success("Your request has been successfully edited. Thank you.");
        this.editingRequest = false;
        this.getRequests();
        this.selectedRequest = [];
      },
      e => {
        this.toastr.error("There has been an error whilst trying to edit the request. Please try again.");
      }
    );
  }

  addRequest(request) {
    this.kenService.addRequest(request).subscribe(
      r => {
        let newRequestId = r.data.newRequestId;
        this.submitProjects(newRequestId, this.selectedProjects.map(p => p.projectId));
        this.toastr.success("Your request has been successfully submitted. Thank you.");
      },
      e => {
        this.toastr.error("There has been an error whilst trying to submit your request. Please try again.");
      }
    );
  }

  submitProjects(requestId, projectIds) {
    this.kenService.submitRequestProjects(requestId, projectIds).subscribe(
      r => {
        this.request = false;
        this.getRequests();
      },
      e => {
        this.toastr.error("There has been an error whilst trying to add projects to your request. Please discuss with IT Team.");
      }
    );
  }

  removeProjects(requestId, projectIds) {
    this.kenService.removeRequestProjects(requestId, projectIds).subscribe(
      r => { },
      e => { console.log(e) }
    );
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'MMMM y');
  }

  filterByProject(filter) {
    this.filter = filter;
    this.filteredRequests = this.requests.filter(r => r.projectName.includes(filter));
    this.router.navigate([], { relativeTo: this.route, queryParams: { project: filter }, queryParamsHandling: 'merge' });
  }

  filterBySector(filter) {
    this.filter = filter;
    this.filteredRequests = this.requests.filter(r => r.sectorName === filter);
    this.router.navigate([], { relativeTo: this.route, queryParams: { sector: filter }, queryParamsHandling: 'merge' });
  }

  filterByTag(filter) {
    this.filter = filter;
    this.filteredRequests = this.requests.filter(r => r.requestTags.includes(filter));
    this.router.navigate([], { relativeTo: this.route, queryParams: { tag: filter }, queryParamsHandling: 'merge' });
  }

  clearFilter() {
    this.filter = null;
    this.filteredRequests = this.requests;
    this.hideShowSector = false;
    this.hideShowProject = false;
    this.hideShowTag = false;
    this.router.navigate([], { relativeTo: this.route, queryParams: { project: null, sector: null, tag: null }, queryParamsHandling: 'merge' });
  }

  setExpandCollapse() {
    if (this.expanded == false) {
      this.expanded = true;
    } else {
      this.expanded = false;
    }
  }

  navHome() {
    this.router.navigate(['/home']);
  }

  navRequest(request) {
    this.router.navigate(['/request/' + request]);
  }

}
