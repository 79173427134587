import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from "environments/environment";

import { JwtModule } from "@auth0/angular-jwt";
import { ResponsiveModule } from "ngx-responsive";
import { NgPipesModule } from "ngx-pipes";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { LandingModule } from "./landing/landing.module";
import { LoginModule } from "./login/login.module";
import { HomeModule } from "./home/home.module";
import { EventsModule } from "./events/events.module";
import { MembershipModule } from "./membership/membership.module";
import { ProjectOverviewModule } from "./project-overview/project-overview.module";
import { DataCollectionModule } from "./data-collection/data-collection.module";
import { OutputsModule } from "./outputs/outputs.module";
import { UserAdministrationModule } from "./user-administration/user-administration.module";
import { ProfileModule } from "./profile/profile.module";
import { ResetPasswordModule } from "./reset-password/reset-password.module";
import { ProjectRegistrationModule } from "./project-registration/project-registration.module";
import { HelpModule } from "./help/help.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { NotFoundModule } from "./not-found/not-found.module";
import { ForumModule } from "./forum/forum.module";
import { RequestModule } from "./request/request.module";
import { BenchmarkerModule } from "./benchmarker/benchmarker.module";
import { ContactModule } from "./contact/contact.module";

import { MemberService } from "./utilities/services/member.service";
import { AuthService } from "./utilities/services/auth.service";
import { AuthGuardService } from "./utilities/services/auth-guard.service";
import { ErrorService } from "./utilities/services/error.service";
import { HtmlService } from "./utilities/services/html.service";
import { DataService } from "./utilities/services/data.service";
import { SafePipeModule } from "./utilities/pipes/safe-pipe.module";
import { WINDOW_PROVIDERS } from "./utilities/services/window.service";
import { LoaderService } from "./utilities/services/loader.service";
import { LoaderInterceptor } from "./utilities/services/loader.interceptor";
import { NgcCookieConsentModule, NgcCookieConsentConfig } from "ngx-cookieconsent";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { Angular2CsvModule } from "angular2-csv";
import { CollectionModule } from "./collection/collection.module";
import { ProjectModule } from "./project/project.module";
import { CsvDownloadModule } from "./shared/csv-download/csv-download.module";
import { UserCheckModule } from "./user-check/user-check.module";
import { OutputsValidationModule } from "./outputs-validation/outputs-validation.module";
import { InfoPanelModule } from "./shared/info-panel/info-panel.module";
import { DataValidationModule } from "./data-validation/data-validation.module";
import { OldHomeModule } from "./home/old-home/old-home.module";
import { ResourcesModule } from "./resources/resources.module";
import { NonMemberService } from "./utilities/services/nonMember.service";
import { NonMembersEventRegistrationFormModule } from './non-members-event-registration-form/non-members-event-registration-form.module';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain,
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#005eb8",
      text: "#ffffff",
      link: "#ffffff",
    },
    button: {
      background: "#005eb8",
      text: "#ffffff",
      border: "#ffffff",
    },
  },
  type: "info",
  content: {
    message:
      "We use cookies to help us improve, promote, and protect our services. By continuing to use the site, you agree to our",
    dismiss: "Continue",
    deny: "Block Cookies",
    link: "Privacy Policy",
    href: "https://www.nhsbenchmarking.nhs.uk/terms-and-privacy",
  },
};

export function tokenGetter() {
  return localStorage.getItem("id_token");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgPipesModule,
    LandingModule,
    LoginModule,
    OldHomeModule,
    HomeModule,
    EventsModule,
    MembershipModule,
    ProjectOverviewModule,
    DataCollectionModule,
    OutputsModule,
    OutputsValidationModule,
    ProfileModule,
    UserAdministrationModule,
    ResetPasswordModule,
    ProjectRegistrationModule,
    HelpModule,
    ResourcesModule,
    UserCheckModule,
    DashboardModule,
    ForumModule,
    RequestModule,
    NotFoundModule,
    BenchmarkerModule,
    CollectionModule,
    ProjectModule,
    ContactModule,
    DataValidationModule,
    NonMembersEventRegistrationFormModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    SafePipeModule,
    ResponsiveModule.forRoot(),
    AppRoutingModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    Angular2CsvModule,
    CsvDownloadModule,
    InfoPanelModule,
  ],
  providers: [
    MemberService,
    NonMemberService,
    AuthService,
    AuthGuardService,
    ErrorService,
    HtmlService,
    DataService,
    WINDOW_PROVIDERS,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
