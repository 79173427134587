import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap';

import { EventsComponent } from './events.component';
import { EventsRoutingModule } from './events-routing.module';

import { SafePipeModule } from '../utilities/pipes/safe-pipe.module'
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
  imports: [ 
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    SafePipeModule,
    EventsRoutingModule,
    NgPipesModule
  ],
  declarations: [ 
    EventsComponent 
  ]
})
export class EventsModule {}