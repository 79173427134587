<!-- SELECTOR BAR-->
<div class="container-fluid" id="selectorBar">
  <div class="row hidden-xs">
    <div class="col-sm-6">
      <span class="btn-back" (click)="navHome()">
        <i class="fa fa-caret-left"></i> Back to Home
      </span>
    </div>
    <div class="col-sm-6">
      <div class="row" *ngIf="organisationsLength > 1">
        <div class="col-lg-6 col-md-4 col-sm-2">
          <i
            class="far fa-lg fa-info-circle pull-right"
            tooltip="Your account allows you to view the details of multiple
          organisations"
          ></i>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-10">
          <div class="form-group">
            <select
              id="organisations"
              class="form-control"
              [ngModel]="currentOrganisation"
              (ngModelChange)="onOrgChange($event)"
            >
              <option
                *ngFor="let o of organisation"
                [ngValue]="o.organisationId"
              >
                {{ o.organisationName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="organisationsLength == 1">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <p *ngFor="let o of organisation; let i = index">
            <span class="one-organisation pull-right" *ngIf="i == 1">{{
              o.organisationName
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" id="containerUserAdmin">
  <div class="row">
    <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2">
      <!-- Header -->
      <div class="row">
        <div class="col-md-12">
          <h1>User Administration</h1>
          <p *ngIf="userHasProductId2; else noPermission">
            This page allows you to see and manage the users within your
            organisation.
          </p>
          <ng-template #noPermission>
            <p>
              This page allows you to see the users within your organisation.
              You do not have the necessary permissions to add users or
              deactivate/activate user accounts. Please contact the Membership
              Lead for these changes.
            </p>
          </ng-template>
        </div>

        <div class="col-md-12" *ngIf="productLead">
          <p>
            <strong>Membership Lead: </strong>
            <a href="mailto:{{ productLead.logonName }}">{{
              productLead.fullName
            }}</a
            >, {{ productLead.jobTitle }}
          </p>
        </div>
      </div>
      <p class="projects-loading" *ngIf="!users">
        <span class="fa fa-spinner fa-spin"></span> Loading list of users...
      </p>

      <div class="breaker"></div>
      <div id="search">
        <ng-container
          *ngIf="
            users | filterBy: ['fullName', 'logonName']:searchText as result
          "
        >
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search users..."
                  [(ngModel)]="searchText"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="add-user text-justify">
                <div class="form-group pull-left text-justify">
                  <button
                    type="button"
                    name="add_user"
                    class="btn btn-primary text-justify"
                    [class.button-clicked]="showDeactivatedAccounts"
                    (click)="showDeactivatedAccounts = !showDeactivatedAccounts"
                  >
                    {{
                      showDeactivatedAccounts
                        ? "Hide Deactivated Users"
                        : "Show Deactivated Users"
                    }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="add-user">
                <div class="form-group pull-right">
                  <button
                    type="button"
                    name="add_user"
                    [disabled]="!userHasProductId2"
                    class="btn btn-success"
                    (click)="showAddUser()">
                    Add User
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Content -->
          <div class="user-card">
            <div *ngFor="let u of result; let i = index; let last = last">
              <div>
                <ng-container *ngIf="last">
                  <div class="search-message" style="display: flex; justify-content: space-between" *ngIf="i + 1 > 1; else oneUser">
                    <p>
                      <i class="fal fa-users"></i>&nbsp; {{ i + 1 }} users found
                      (some may be deactivated)
                    </p>
                    <app-csv-download [result]="result" [csvSettings]="csvSettings"></app-csv-download>
                  </div>
                  <ng-template #oneUser>
                    <div class="search-message" style="display: flex; justify-content: space-between">
                      <p>
                        <i class="fal fa-md fa-user"></i>&nbsp; {{ i + 1 }} users
                        found (may be deactivated)
                      </p>
                      <app-csv-download [result]="result" [csvSettings]="csvSettings"></app-csv-download>
                    </div>
                  </ng-template>
                  <hr />
                </ng-container>
              </div>
            </div>
            <div *ngFor="let u of result">
              <div class="users" *ngIf="u.isActive == 'Y'">
                <div class="row user-row">
                  <div class="col-sm-5 vcenter">
                    <h4>
                      <b>{{ u.fullName }}</b
                      ><br /><small>{{ u.logonName }}</small>
                    </h4>
                  </div> 
                  <div class="col-sm-3 vcenter">
                    <div *ngIf="u.marketingSubscriber == 'Y'">
                      <strong>-</strong>
                      <em> Receiving communications </em>
                      <strong>-</strong>
                    </div>
                  </div>               
                  <div class="col-sm-4 vcenter actions">                                
                    <button
                      type="button"
                      class="btn btn-sm btn-default view-rolesbutton"
                      (click)="viewRoles(u.userId)"
                    >
                      View Roles
                    </button>
                    <button
                      type="button"
                      [disabled]="!userHasProductId2"
                      class="btn btn-sm btn-default disable-accbutton"
                      (click)="accountState(u.userId, u.isActive)"
                    >
                      Deactivate User
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="showDeactivatedAccounts">
                <div *ngIf="u.isActive == 'N'" class="users disabled">
                  <div class="row user-row">
                    <div class="col-sm-6 vcenter">
                      <h4>
                        {{ u.fullName }} (deactivated)<br /><small>{{
                          u.logonName
                        }}</small>
                      </h4>
                    </div>

                    <div class="col-sm-6 vcenter actions">
                      <button
                        type="button"
                        [disabled]="!userHasProductId2"
                        class="btn btn-sm btn-default enable-accbutton"
                        (click)="accountState(u.userId, u.isActive)"
                      >
                        Activate User
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="search-empty" *ngIf="!result.length">
              <i class="fad fa-user-slash"></i>&nbsp; No users found. If you
              need any support, please contact the
              <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">Support Team</a
              >.
            </p>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- end Column -->
  </div>
  <!-- end Row -->
</div>
<!-- end Container -->

<!-- View Roles Modal -->
<div
  class="modal fade"
  bsModal
  #rolesModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="viewRolesModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">User Roles</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="rolesModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <table *ngIf="userRoles" class="table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Project</th>
              <th>Year</th>
              <th>Role</th>
              <th>Service</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let u of userRoles">
              <td>{{ u.productName || "N/A" }}</td>
              <td>{{ u.projectName || "N/A" }}</td>
              <td>{{ u.roleYear || "N/A" }}</td>
              <td>{{ u.roleName || "N/A" }}</td>
              <td>{{ u.serviceItemName || "N/A" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Reset Password Modal -->
<div
  class="modal fade"
  bsModal
  #passwordModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="passwordModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Password Reset</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="passwordModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <!-- <p>
                    If you need to reset a user's password, please enter a new password twice below. The password must be eight 
                    or more characters in length, and should contain a mixture of letters and numbers. Once you have changed 
                    the password, the user will not be able to log on using their old password.
                </p>

                <p>
                    Need to add functionality to reset password.
                </p> -->

        <p>
          To reset the password of this user, please contact
          <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries</a> or 0161 521 0818.
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Add User Modal -->
<div
  class="modal fade"
  bsModal
  #addUserModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addUserModal"
  aria-hidden="true"
  (onHidden)="registerUser.reset()"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Create a New User Account</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="addUserModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <form [formGroup]="registerUser">
          <p>
            Please complete the fields below, in order to create a new user
            account. An account will be created with read-only access, and the
            logon details emailed to the new user.
          </p>

          <div class="form-group">
            <label for="logonName">Email Address</label>
            <input
              type="email"
              formControlName="logonName"
              class="form-control"
            />
          </div>

          <div *ngIf="formErrors.logonName" class="alert alert-danger">
            {{ formErrors.logonName }}
          </div>

          <div class="form-group">
            <label for="fullName">Full Name</label>
            <input
              type="text"
              formControlName="fullName"
              class="form-control"
            />
          </div>

          <div *ngIf="formErrors.fullName" class="alert alert-danger">
            {{ formErrors.fullName }}
          </div>

          <div class="form-group">
            <label for="jobTitle">Job Title</label>
            <input
              type="text"
              formControlName="jobTitle"
              class="form-control"
            />
          </div>

          <div *ngIf="formErrors.jobTitle" class="alert alert-danger">
            {{ formErrors.jobTitle }}
          </div>

          <div class="form-group">
            <label for="phoneNo">Phone Number</label>
            <input type="text" formControlName="phoneNo" class="form-control" />
          </div>

          <div *ngIf="formErrors.phoneNo" class="alert alert-danger">
            {{ formErrors.phoneNo }}
          </div>

          <div class="form-group" [class.hidden]="uniqueProductList.length == 1"> <!--  -->
            <label for="productId">Product</label>
            <select formControlName="productId" class="form-control">
              <option *ngFor="let product of uniqueProductList" [value]="product.productId">{{ product.productName }}</option>
            </select>
          </div>

          <div *ngIf="errorMessage" class="alert alert-danger">
            <p>Unable to add user</p>
            <p>{{ errorMessage }}</p>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          (click)="addUser()"
          [disabled]="!registerUser.valid"
          class="btn btn-primary"
        >
          Add User
        </button>
        <button
          type="button"
          (click)="addUserModal.hide()"
          class="btn btn-default"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
