<!-- Selector bar -->
<div id="selectorBar">
  <div class="container-fluid">
    <form class="form-vertical">
      <div class="row">
        <div class="col-md-9 col-sm-4 hidden-xs">
            <a [routerLink]="['/classicView']" routerLinkActive="active">
              <span class="btn-back" (click)="classicView()">
                <i class="fa fa-caret-left"></i> Classic View
              </span>
            </a>

            <a href="https://airtable.com/app6tg6ceZYWgkhtX/shro8tKYNltABl5BK" target="_blank">
              <span class="btn-feedback">
                <i class="far fa-comment"></i> Feedback
              </span>
            </a>
        </div>
        <div class="col col-md-2 col-sm-6 col-xs-12 text-right">
          <div class="menu-container">
              <div class="menu-select" >
                  <div *ngFor="let o of organisations" 
                      [class]="selectedOrganisation && selectedOrganisation.organisationId === o.organisationId ? 'tier -selected' : 'tier'"
                      (click)="getSubmissions(projectId, o); selectedTiers[8].open = !selectedTiers[8].open; tierOpen = false">
                          {{ o.organisationName }}
                  </div>
              </div>
          </div>
      </div>
        <div class="col col-md-3 col-sm-6 col-xs-12" style="float: right;">
          <div class="form-group">
            <select class="form-control" id="organisation" name="organisation" [ngModel]="currentOrganisation"
              (ngModelChange)="onOrgChange($event)">
              <option *ngFor="let o of organisation" [ngValue]="o.organisationId">{{o.organisationName}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="container-fluid" id="containerHome">
  <app-sidebar [user]="user" [filteredEvents]="filteredEvents" [filteredRequests]="filteredRequests" [resourceList]="resourceList" [yourReports]="yourReports"></app-sidebar>

  <div class="col-sm-8">
    <div *ngIf="welcomeMessage" class="alert alert-success welcome-message">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismissWelcomeMessage()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4>Welcome to the Network Members' Area.</h4>
      <p>
        You can find all the Network's projects listed on the home page. For
        your chosen Project, click on 'Project' to find tabs for Information,
        Online Toolkit, Offline Toolkit, Reports, Presentations and Case
        Studies.
      </p>
      <p>
        If you need any help, or have any comments or suggestions, please email
        <a href="mailto:enquiries@nhsbenchmarking.nhs.uk">enquiries</a>.
      </p>
    </div>

    <div class="info-block">
      <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Other/2024/NHS%20Benchmarking%20Network%20Member%20Work%20Programme%202024-25.pdf" target="_blank">
        <strong>Click here to download the 2024/25 member work programme<i class="fas fa-caret-right"></i></strong>
      </a>
    </div>

    <app-project-table [organisationSelection]="currentOrganisation"></app-project-table>
  </div>
</div>