<!-- SELECTOR BAR -->
<div id="selectorBar">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-sm-4 hidden-xs">
                <span class="btn-back" (click)="navProjectOverview(projectId)">
                    <i class="fa fa-caret-left"></i> Back to Project Overview
                </span>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" id="containerCollection">
    <div class="row" *ngIf="noQuestions">
        <div class="col-md-12 text-center">
            <h3 class="text-muted">There are no questions set for this part of data collection.</h3>
        </div>
    </div>
    <div class="row" *ngIf="!noQuestions">        
        <div class="col-lg-10 col-lg-offset-1">
            <div class="row">
                <!-- SIDEBAR -->
                <div class="col-lg-3 col-md-3">
                    <div class="nav nav-pills">
                        <tabset type="pills">
                            <div class="tab-content">
                                <tab class="tab-pane">
                                    <ng-template tabHeading>Questions</ng-template>
        
                                    <div *ngIf="cnr.length > 0 && projectId !== 34">
                                        <div class="form-group">
                                            <label>Case Note Review Codes:</label>
        
                                            <select class="form-control" (change)="cnrSelectionChange($event.target.value)" [ngModel]="selectedCaseCodeId">
                                                <option *ngFor="let c of cnr" [value]="c.caseCodeId">
                                                    {{c.caseCode.substring(5).slice(1,-5)}}
                                                    <ng-container *ngIf="c.caseCodeNotes">
                                                        [Reviewer: {{ c.caseCodeNotes | slice:0:30 }}{{ c.caseCodeNotes.length > 30 ? '...' : '' }}]
                                                    </ng-container>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
        
                                    <div class="list-group" *ngIf="questionGroups">
                                        <ng-template ngFor let-g [ngForOf]="questionGroups">
                                            <a class="list-group-item" (click)="changeGroup(g.groupId, g.groupName)" [ngClass]="{'active': currentQuestionGroup == g.groupId}">{{g.groupName}}</a>
                                        </ng-template>
                                    </div>
                                </tab>
        
                                <tab class="tab-pane">
                                    <ng-template tabHeading>Help</ng-template>
        
                                    <p>
                                        <b>Do not forget to save the section before moving on.</b> You can complete the sections in any order.
                                        Partially completed questions can be returned to as necessary. 
                                    </p>
        
                                    <p>If you require any assistance please contact any of the people below.</p>
        
                                    <div *ngIf="submissionDetails?.projectRoles.length > 0">
                                        <div *ngFor="let p of submissionDetails.projectRoles">
                                            <hr>
                                            
                                            <h4>{{p.role}}</h4>
                            
                                            <p>
                                                {{p.name}}<br>
                                                <a href="mailto:{{p.email}}">{{p.email}}</a><br>
                                                {{p.phoneNo}}
                                            </p>
                                        </div>
                                    </div>
                                </tab>
                            </div>
                        </tabset>
                    </div>
                </div>
                
                <!-- CONTENT -->
                <div class="col-lg-8 col-lg-offset-1 col-md-9">
                <!-- <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-12"> -->

                    <!-- <div class="page-header" *ngIf="submissionDetails">
                        <h1>{{submissionDetails.projectName}}</h1>
                        <h2>{{submissionDetails.submissionName}}</h2>
                    </div> -->

                    <div *ngIf="submissionDetails" class="questions-heading">
                        <h4>
                            {{submissionDetails.projectName}}
                            <i class="fa fa-angle-right"></i>
                            {{submissionDetails.submissionName}}
                            <span *ngIf="submissionDetails?.serviceName">
                                <i class="fa fa-angle-right"></i>
                                {{submissionDetails.serviceName}}
                            </span>
                            <span *ngIf="caseNoteReviewCode">
                                <i class="fa fa-angle-right"></i>
                                {{caseNoteReviewCode | slice:5:15}}
                            </span>
                        </h4>
                    </div>

                    <div *ngIf="adminOnly" id="readOnly">
                        <strong><i class="fas fa-exclamation-triangle"></i> This data collection page is read-only to users.</strong><br>Only amend and save responses if absolutely necessary.
                    </div>

                    <!-- <div 
                        *ngFor="let g of upperQuestionGroups"
                        [id]="g.groupId"
                        [class]="currentQuestionGroup === g.groupId ? 'question-group-header active' : 'question-group-header'" 
                        (click)="currentQuestionGroup === g.groupId ? closeGroup() : changeGroup(g.groupId, g.groupName)">
                            {{ g.groupName }}
                    </div> -->
                    <div *ngIf="!updatedOuterForm">
                        <p class="loading" style="margin-top: 10vh;">
                          <span class="fa fa-spinner fa-spin"></span> Loading questions...
                        </p>
                    </div>
                    <form (ngSubmit)="onSubmit()" [formGroup]="outerForm" id="questionForm" *ngIf="questions && outerForm && updatedOuterForm" class="form-horizontal">
                        <div *showIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']" class="wrong-browser">
                            <h4>Your browser is not supported.</h4>
                            <a href="/help">Click here for more information and links to alternative browsers.</a>
                        </div>
                        <div *hideIEVersion="['ie 7', 'ie 8', 'ie 9','ie 10']">
        
                            <div id="questions" *ngIf="questionsOpen">
    
                                <div *ngIf="loaderService.isLoading | async" class="loading-spinner">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>

                                <div *ngFor="let q of questions.question">
                                    <app-dynamic-survey [projectId]="projectId"
                                        [question]="q" 
                                        [innerForm]="outerForm"
                                        [peerGroupId]="peerGroupId"
                                        [isAdmin]="isAdmin"
                                        [linkedQuestion]="linkedQuestion"
                                        (questionCountEmitter)="questionCounts($event)" 
                                        (questionTotalEmitter)="questionTotals()"
                                        (datesTimesEmitter)="datesTimesError($event)"
                                        (parentChangeEmitter)="parentChange($event)"
                                        (nacelValidations)="nacelValidations($event)"
                                        (editQuestion)="editQuestion($event)">
                                    </app-dynamic-survey>
                                </div>

                                <div *ngIf="conditionTypeId === 6">
                                    <div *ngFor="let e of nacelErrors" class="hint -error">
                                        <i class="fas fa-exclamation-triangle"></i>{{ e }}
                                    </div>
                                </div>

                                <div *ngIf="parentQuestions.length > 0" class="hint -warn">
                                    <ul>
                                        <li *ngFor="let q of parentQuestions" (click)="childResponseCount()">
                                            <i class="fas fa-exclamation-triangle"></i> {{ q.questionText }}
                                        </li>
                                    </ul>
                                    <div>
                                        The change made to the question above will delete responses already provided in the data collection. Only click 'Save' if you are certain this change is required.
                                    </div>
                                </div>

                            </div>

                            <div class="question-actions" *ngIf="questionsOpen">
                                <button type="submit"
                                    [disabled]="(!outerForm.valid || !outerForm.dirty) || (type === 'h' && questionTotalCount < questions.questionCount) || (nacelErrors.length > 0 && !isAdmin)"
                                    class="btn btn-lg btn-success">
                                    Save
                                </button>
                                <button type="button" class="btn btn-lg btn-default" (click)="navProjectOverview(projectId)">
                                    Cancel
                                </button>
                                <!-- <div class="question-count-label">
                                    {{questionTotalCount || 0}} of {{questions.questionCount}} questions answered in this section
                                </div> -->
                            </div>

                            <div class="question-status" *ngIf="questionsOpen">
                                <!-- General Save/Cancel -->
                                <div class="progress-saved" *ngIf="type !== 'h' && (!outerForm.valid || !outerForm.dirty)">
                                    Your progress has been saved.
                                </div>
                                <div class="progress-not-saved" *ngIf="type !== 'h' && outerForm.dirty">
                                    Click 'Save' before continuing.
                                </div>
                                <!-- All questions must be answered -->
                                <div class="progress-not-saved" *ngIf="(type === 'h') && questionTotalCount < questions.questionCount">
                                    You must complete all questions in this section before you can save the data.
                                </div>
                                <div class="progress-not-saved" *ngIf="(type === 'h') && outerForm.dirty && questionTotalCount === questions.questionCount">
                                    Click 'Save' before continuing.
                                </div>
                            </div>

                        </div>
                    </form>

                    <!-- <div 
                        *ngFor="let g of lowerQuestionGroups" 
                        [id]="g.groupId"                    
                        [class]="currentQuestionGroup == g.groupId ? 'question-group-header active' : 'question-group-header'" 
                        (click)="currentQuestionGroup == g.groupId ? closeGroup() : changeGroup(g.groupId, g.groupName)">
                            {{ g.groupName }}
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Active Admin -->
<div *ngIf="editingQuestion && modifierType">
    <form [formGroup]="questionForm" id="questionEditForm">
        <div class="row" *ngIf="modifierType.includes('popup')">
            <div class="col-sm-12">
                <p class="text-muted">Nested Question ID (<strong>for tech team use only</strong>): {{ editingQuestionSelection.questionId }}</p>
            </div>
        </div>
        <div class="row" *ngIf="!modifierType.includes('popup')">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="questionId">Question ID</label>
                    <input formControlName="questionId" type="number" class="form-control" id="questionId" disabled>
                    <p class="help-block">This is not editable</p>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="questionPrefix">Prefix</label>
                    <input formControlName="questionPrefix" type="text" class="form-control" id="questionPrefix" maxlength="10">
                    <p class="help-block">10 characters max.</p>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="questionText">Question Text</label>
            <textarea formControlName="questionText" type="text" class="form-control" id="questionText" rows="3" maxlength="1500"></textarea>
            <p class="help-block">1500 characters max.</p>
        </div>
        <div class="form-group">
            <label for="questionHelp">Help Text</label>
            <textarea formControlName="questionHelp" type="text" class="form-control" id="questionHelp" rows="3" maxlength="2048"></textarea>
            <p class="help-block">2048 characters max.</p>
        </div>
        <div class="form-group" *ngIf="modifierType.includes('numeric') || modifierType === 'text' || modifierType === 'narrative'">
            <label for="formatModifier">Format Modifier</label>
            <select formControlName="formatModifier" type="select" id="formatModifier" class="form-control">
                <option value="">No format modifier</option>
                <option value="P" *ngIf="modifierType.includes('numeric')">Percentage (%)</option>
                <option value="C" *ngIf="modifierType.includes('numeric')">Currency (£)</option>
                <option value="S" *ngIf="modifierType === 'text'">Small text box</option>
                <option value="M" *ngIf="modifierType === 'text'">Medium text box</option>
                <option value="L" *ngIf="modifierType === 'text'">Large text box</option>
                <option value="T" *ngIf="modifierType === 'narrative'">Title (blue)</option>
                <option value="B" *ngIf="modifierType === 'narrative'">Bold text</option>
                <option value="I" *ngIf="modifierType === 'narrative'">Italic text</option>
            </select>
        </div>
        <div class="row" *ngIf="modifierType.includes('numeric')">
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="rangeMin">Min. Value</label>
                    <input formControlName="rangeMin" type="number" class="form-control" id="rangeMin">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="rangeMax">Max. Value</label>
                    <input formControlName="rangeMax" type="number" class="form-control" id="rangeMax">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="decPlaces">Decimal Places</label>
                    <input formControlName="decPlaces" type="number" class="form-control" id="decPlaces">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="parentQuestionId">Data Definition</label>
                    <select formControlName="parentQuestionId" type="select" id="parentQuestionId" class="form-control" style="margin-bottom:0.5em">
                        <option value="">Select a defining question...</option>
                        <option *ngFor="let q of contextQuestions" value="{{ q.questionId }}">{{ q.questionText }}</option>
                    </select>
                    <input formControlName="dataDefinition" type="text" class="form-control" id="dataDefinition" placeholder="Provide a definition..." maxLength="50">
                    <p class="help-block">
                        Link this question to another question to provide a data definition or provide separate data definition wording (limited to 50 characters).
                    </p>
                </div>
            </div>
        </div>
        <p *ngIf="modifierType.includes('datetime')" class="datetime-warning">
            If you need to change the minimum/maximum values for date/time questions, please contact the IT Team.
        </p>
        <div class="form-actions">
            <button type="button" class="btn btn-admin -primary" (click)="submitForm()">Save</button>
            <button type="button" class="btn btn-admin" (click)="editingQuestion = false">Cancel</button>
            <!-- Placeholders -->
            <div id="placeholderHelp" *ngIf="placeholderHelp">
                <p>
                    <strong>Available Placeholders</strong><br>
                    Click on a placeholder below to copy to clipboard
                </p>
                <div *ngFor="let p of placeholders" (click)="copyToClipboard(p.id)">
                    <p class="placeholder"><span>{{ p.example }}</span><strong [id]="p.id"><i>*|</i>{{ p.name }}<i>|*</i></strong></p>
                </div>
                <div class="placeholder-copied" *ngIf="copiedMessage">
                    <i class="fas fa-check-circle"></i> {{ copiedMessage }}
                </div>
            </div>
            <span type="button" [class]="placeholderHelp ? 'btn btn-admin -help -active' : 'btn btn-admin -help'" (click)="placeholderHelp = !placeholderHelp">
                <i class="fas fa-question-circle"></i> Placeholders
            </span>
        </div>
    </form>
</div>