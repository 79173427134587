import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { MemberService } from '../../utilities/services/member.service';

@Component({
  selector: 'app-pr-address',
  templateUrl: './pr-address.component.html',
  styleUrls: ['./pr-address.component.scss']
})
export class PrAddressComponent implements OnChanges {
  @Input() registration;
  @Input() projectBasicDetails;
  @Input() addressClosed;
  @Output() addressAdd: EventEmitter<any> = new EventEmitter<any>();

  address;
  addressForm: FormGroup; 
  showAddressForm: boolean = false;
  showAddressFormAdd: boolean = true;

  addressError: boolean = false;

  constructor(
    private memberService: MemberService, 
    private fb: FormBuilder, 
    private toastr: ToastrService
  ) { }

  ngOnChanges() {
    if (this.registration.registration.address) {
      this.address = this.registration.registration.address.addressList[0];
    }
  }

  addressFormAdd(address?) {
    this.addressError = false;
    this.buildAddressForm(address);

    this.showAddressForm = true; 
    this.showAddressFormAdd = false;    
  }

  addressFormEdit() {
    this.addressFormAdd();

    this.addressForm.setValue({
      contact: this.address.addressContact,
      phone: this.address.addressPhone,
      address1: this.address.address1,
      address2: this.address.address2,
      address3: this.address.address3,
      address4: this.address.address4,
      postcode: this.address.addressPostcode
    });

  }

  addressFormSubmit() {
    if (this.address) {
      let address = {
        registrationAddressId: this.address.addressId,
        addressContact: this.addressForm.value.contact,
        addressPhone: this.addressForm.value.phone,
        address1: this.addressForm.value.address1,
        address2: this.addressForm.value.address2,
        address3: this.addressForm.value.address3,
        address4: this.addressForm.value.address4,
        addressPostcode: this.addressForm.value.postcode
      }
      this.editAddress(this.registration.registrationId, address);
    } else {
      this.addAddress(this.registration.registrationId, this.addressForm.value);
    }
  }

  addAddress(registrationId: number, addressDetails) {
    addressDetails.addressTypeId = 1;
    this.memberService.AddRegistrationAddress(registrationId, addressDetails).subscribe(
      r => {
        this.addressAdd.emit(true);
        this.toastr.success('The address has been added successfully.');
        this.showAddressForm = !this.showAddressForm;
        this.showAddressFormAdd = !this.showAddressFormAdd;
      },
      e => {
        this.toastr.error('There has been an error when trying to add the address. Please try again.');
        console.log(e.error.error.message);
      }
    )
  }

  // TODO: Test this following API update
  editAddress(registrationId: number, addressDetails) {
    this.memberService.EditRegistrationAddress(registrationId, addressDetails).subscribe(
      r => {
        this.toastr.success('The address has been edited successfully.');
        this.showAddressForm = !this.showAddressForm;
        this.showAddressFormAdd = !this.showAddressFormAdd;
        this.addressAdd.emit(true);
      },
      e => {
        this.toastr.error('There has been an error when trying to edit the address. Please try again.');
        console.log(e.error.error.message);
      }
    )
  }

  removeAddress(registrationAddressId) {
    if (confirm("Are you sure you want to remove this address?")) {
      this.memberService.RemoveRegistrationAddress(this.registration.registrationId, registrationAddressId).subscribe(
        r => {
          this.addressAdd.emit(false);
          this.address = null;
          this.toastr.success('The address has been removed.');
        },
        e => {
          this.toastr.error('There has been an error when trying to remove the address. Please try again.');
          console.log(e.error.error.message);
        }
      )
    }
  }

  buildAddressForm(address?) {

    if (address) {
      this.addressForm = this.fb.group({
        contact: [address.addressContact, [Validators.required, Validators.maxLength(200)]],
        phone: [address.addressPhone, [Validators.required, Validators.maxLength(40)]],
        address1: [address.address1, [Validators.required, Validators.maxLength(200)]],
        address2: [address.address2, [Validators.required, Validators.maxLength(200)]],
        address3: [address.address3, [Validators.maxLength(200)]],
        address4: [address.address4, [Validators.maxLength(200)]],
        postcode: [address.addressPostcode, [Validators.required, Validators.maxLength(10)]]
      });
    } else {
      this.addressForm = this.fb.group({
        contact: ['', [Validators.required, Validators.maxLength(200)]],
        phone: ['', [Validators.required, Validators.maxLength(40)]],
        address1: ['', [Validators.required, Validators.maxLength(200)]],
        address2: ['', [Validators.required, Validators.maxLength(200)]],
        address3: ['', [Validators.maxLength(200)]],
        address4: ['', [Validators.maxLength(200)]],
        postcode: ['', [Validators.required, Validators.maxLength(10)]]
      });
    }

    this.addressForm.valueChanges.subscribe(
      data => this.onValueChanged(data)
    );

    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.addressForm) { return; }
    const form = this.addressForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  getLastAddress() {
    this.memberService.ProjectRegistration(
      this.registration.productId, 
      this.registration.projectId, 
      this.registration.organisationId, 
      this.registration.registration.registrationYear - 1 // Last year
    ).subscribe(
      r => {
        let registrationAddress = r.data.registration.address;
        let addressList = registrationAddress ? registrationAddress.addressList : null;
        let address = addressList && addressList.length ? addressList[0] : null;
        if (address) {
          this.addressFormAdd(address);
        } else {
          this.addressError = true;
        }
      }, 
      e => {
        console.log(e);
        this.addressError = true;
      }
    );
  }

  formErrors = {
    'contact': '',
    'phone': '',
    'address1': '',
    'address2': '',    
    'address3': '',
    'address4': '',
    'postcode': ''
  }

  validationMessages = {
    'contact': {
      'required': 'Contact Name is required',
      'maxlength': 'Contact Name cannot be longer than 200 characters'
    },
    'phone': {
      'required': 'Phone number is required.',
      'maxlength': 'Phone number cannot be longer than 40 characters.'
    },
    'address1': {
      'required': 'Address 1 is required',
      'maxlength': 'Address 1 cannot be longer than 200 characters'
    },
    'address2': {
      'required': 'Address 2 is required',
      'maxlength': 'Address 2 cannot be longer than 200 characters'
    },
    'address3': {
      'required': 'Address 3 is required',
      'maxlength': 'Address 3 cannot be longer than 200 characters'
    },
    'address4': {
      'required': 'Address 4 is required',
      'maxlength': 'Address 4 cannot be longer than 200 characters'
    },
    'postcode': {
      'required': 'Postcode is required',
      'maxlength': 'Postcode cannot be longer than 10 characters'
    }
  }
}