import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { MemberService } from '../../utilities/services/member.service';

@Component({
  selector: 'app-pr-submission-group',
  templateUrl: './pr-submission-group.component.html',
  styleUrls: ['./pr-submission-group.component.scss']
})
export class PrSubmissionGroupComponent implements OnChanges {
  @Input() organisation;
  @Input() organisationGroupList;
  @Input() commissioningPartners;
  @Input() registration;
  @Output() submissionGroupAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() submissionGroupDelete: EventEmitter<any> = new EventEmitter<any>();

  submissionGroupForm: FormGroup; submissionGroupFormVisible: boolean = false;

  constructor(private memberService: MemberService, private fb: FormBuilder) {
  }

  ngOnChanges() {
    if (this.registration) {
    }
  }

  submissionGroupFormSubmit() {
    this.addOrganisationSubmissionGroup(this.registration.registrationId, this.submissionGroupForm.value.organisation, this.registration.organisation.groupHeaderId);
  }

  addOrganisationSubmissionGroup(registrationId: number, organisationId: number, groupHeaderId: number) {
    let group = {
      organisationId: organisationId,
      groupHeaderId: groupHeaderId,
    };

    this.memberService.AddOrganisationSubmissionGroup(registrationId, group).subscribe(
      s => {
        let organisation = {
          organisationId: organisationId,
          organisationName: this.organisationGroupList.filter(o => o.organisationId == organisationId)[0].organisationName
        };

        this.submissionGroupAdd.emit(organisation);
      },
      e => {},
      () => {
        this.submissionGroupFormVisible = false;
      }
    );
  }

  submissionGroupFormRemove(organisationId: number) {
    if (confirm("Are you sure you want to remove this Submission Group?")) {
      this.removeOrganisationSubmissionGroup(this.registration.registrationId, organisationId, this.registration.organisation.groupHeaderId)
    }
  }

  removeOrganisationSubmissionGroup(registrationId: number, organisationId: number, groupHeaderId: number) {
    this.memberService.RemoveOrganisationSubmissionGroup(registrationId, organisationId, groupHeaderId).subscribe(
      s => {
        this.submissionGroupDelete.emit(organisationId);
      }
    );
  }

  commissionerCheck(organisationId) {
    if (this.commissioningPartners.findIndex(c => c.organisationId == organisationId.split(' ')[1]) != -1) {
      this.submissionGroupForm.controls['organisation'].setErrors({'used': true});
    }
    else {
      this.submissionGroupForm.controls['organisation'].setErrors(null);
    }
  }

  submissionGroupFormShow() {
    this.buildSubmissionGroupForm();

    this.submissionGroupFormVisible = true;
  }

  submissionGroupFormHide() {
    this.submissionGroupFormVisible = false;
  }

  buildSubmissionGroupForm() {
    this.submissionGroupForm = this.fb.group({
      organisation: [this.organisation.organisationId, [Validators.required]]
    });
  }
}