import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResponsiveModule } from 'ngx-responsive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RequestComponent } from './request.component';
import { RequestRoutingModule } from './request-routing.module';

import { AuthService } from '../utilities/services/auth.service';
import { AuthGuardService } from '../utilities/services/auth-guard.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { KenService } from 'app/utilities/services/ken.service';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
  imports: [
    CommonModule,
    ResponsiveModule,
    RequestRoutingModule,
    CollapseModule,
    ReactiveFormsModule,
    NgPipesModule
  ],
  declarations: [
    RequestComponent
  ],
  providers: [
    AuthService,
    AuthGuardService,
    KenService
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule
  ]
})
export class RequestModule {}